import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import AllocatedParking from './screens/allocated_parking/allocatedParking';
import FindMyCar from './screens/find_my_car/findMyCar';
import { RESET_STATE } from './store/findacarslice';

type Props = {};

const FindvehiclesIndex = (props: Props) => {
	const dispatch = useDispatch()
  	useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
	const redirectedPath = useRedirect([
		{
			path: 'find-my-car',
			submoduleID: subModuleList['Find A Car'],
		},
		{
			path: 'allocated-parking',
			submoduleID: subModuleList['Allocated Parking'],
		},
	]);

	return (
		<div className='navtabs__container'>
			<NavTabs>
				<PermissionWrapper subModuleID={subModuleList['Find A Car']}>
					<LinkTab label='Find Vehicle' href='find-my-car' />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Allocated Parking']}>
					<LinkTab label='Allocated Parking' href='allocated-parking' />
				</PermissionWrapper>
			</NavTabs>
			<Routes>
				<Route
					path={'find-my-car'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Find A Car']}>
							<FindMyCar />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'allocated-parking'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Allocated Parking']}>
							<AllocatedParking />
						</PermissionWrapper>
					}
				/>
				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default FindvehiclesIndex;
