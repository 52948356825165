import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_entered_vehicle_count } from '../../store/action';
type Props = {};

const EnteredVehicleCount = (props: Props) => {
    const childRef: any = useRef(null);
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [date, setdate] = useState<string>(dayjs().format('YYYY-MM-DD'));
	const [data, setdata] = useState<any>();

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			date,
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
		};

		handleClose();
	
		fetch_entered_vehicle_count(postData, signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ?? [];
				setdata(data);
			}
		});

		return () => {
			controller.abort();
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, date]);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
        }
	};
	return (
		<div className='dflex gap-10 ' style={{ height :"100%" , margin:0}}>
			<div className={'icon-box large-icon-box white-bg w-100'}>
				<div className='icon-wrap' style={{ fontSize :"1rem" , width:"50px" ,height:"50px"}}>
					<i className='fa-solid fa-car' style={{ fontSize:"1.5rem"}}></i>
				</div>
				<h3 className='text-primary' style={{ fontSize:"14px"}}>
					Employee <span>{data?.userCount}</span>
				</h3>
			</div>
			<div className={'icon-box large-icon-box white-bg w-100'}>
				<div className='icon-wrap' style={{ fontSize :"1rem" , width:"50px" ,height:"50px"}}>
					<i className='fa-solid fa-car' style={{ fontSize:"1.5rem"}}></i>
				</div>
				<h3 className='text-primary' style={{ fontSize:"14px"}}>
					Visitor <span>{data?.visitorCount}</span>
				</h3>
			</div>
			<div className={'icon-box large-icon-box white-bg w-100'}>
				<div className='icon-wrap' style={{ fontSize :"1rem" , width:"50px" ,height:"50px"}}>
					<i className='fa-solid fa-car' style={{ fontSize:"1.5rem"}}></i>
				</div>
				<h3 className='text-primary' style={{ fontSize:"14px"}}>
					Unregistered <span>{data?.totalCount}</span>
				</h3>
			</div>
	
		</div>
	);
};

export default EnteredVehicleCount;
