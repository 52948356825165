import { useEffect, useState } from 'react'
import { DatePicker, Tooltip } from 'antd';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import AsyncSelect from 'react-select/async';
import { fetch_all_usersSearch } from '../../../../parking_request/store/action';
// import { fetch_visitor_detail } from '../../../../visitor/store/action';
// import { search_user } from '../../../../security/store/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { commonSearchQuery, ExtendedField, FilterProps } from '../../../../../config/filterSearch';
import { fetch_user_report } from '../../../store/action';
// import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useKeyboard from '../../../../../utils/hooks/useKeyboard';
import { UPDATE_USER_REPORT_DATE_UPDATE, UPDATE_USER_REPORT_FILTER_UPDATE } from '../../../store/reportSlice';
import { SwapOutlined } from '@ant-design/icons'
import QueryBuilderModal from '../../../../../common/components/query_builder';
import { fetch_violationTypeDDL } from '../../../../../common/general/action';
import moment from 'moment';

const { RangePicker } = DatePicker;


type Props = {
	tabVal:any;
	resetFilter:any;
	setResetFilter:any;
 }
const FilterBoxBar = (props: Props) => {
    // const [expanded, setExpanded] = useState<boolean>(true);
    const [dateRange, setDateRange] = useState<any>([]);
    const [timeRange, setTimeRange] = useState<any>([]);

    const [usersArr, setUsersArr] = useState<any>();
	const [usersList, setUsersList] = useState<any>();
	const [selectedUser, setselectedUser] = useState<any>();

	const [filterActive, setFilterActive] = useState(false)

	const [advanceModal, setAdvanceModal] = useState(false);
	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);
	const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);
	const VIOLATION_TYPE_LIST = useAppSelector((state) => state?.general);

	const [queryTxt, setQueryTxt] = useState('')
	// const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const convTimeFn = (val:any) =>{
		return moment.duration(val).asMinutes()
	}

	useEffect(() => {
		const controller = new AbortController();
		fetch_violationTypeDDL(dispatch, controller.signal);
	  if(props?.resetFilter){
		setTimeRange([]);
		setselectedUser(null)
		setDateRange([]);
		if(dateRange && dateRange?.length>0){
			dispatch(UPDATE_USER_REPORT_DATE_UPDATE({
				dates : []
			}))	
		}
		dispatch(UPDATE_USER_REPORT_FILTER_UPDATE())
		props?.setResetFilter(false)
	  }
	}, [props?.resetFilter])
		
	const fields: any[] = 
		props?.tabVal===1 ?
		[
			{ name: 'CAST (startdate AS date)', label: 'Start Date', inputType: 'date' },
			{ name: 'CAST (enddate AS date)', label: 'End Date', inputType: 'date' },
			{ name: 'Fullname', label: 'Requested For Name' },
			{ name: 'PlateNumber', label: 'Plate Number' },
			{ name: 'vehicleCategory', label: 'Vehicle Category' },
			{ name: 'placeOfRegistration', label: 'Place of Registration' },
			{ name: 'zoneName', label: 'Zone Name' },
			{ name: 'bayName', label: 'Bay Name' },
			{ name: 'FK_RequestTypeID', label: 'Request Type' , inputType:"select", values:[
				{ name: "1", label: 'Employee Parking ' },
				{ name: "2", label: 'Visitor Parking ' },
				{ name: "5", label: 'Employee Overtime Parking ' },
				{ name: "6", label: 'Visitor Overtime Parking ' },
			]},
			// { name: 'CurrentStatus', label: 'Status' , inputType:"select", values:[ 
			// 	{ name : 1, label : 'Approved'},
			// 	{ name : 11, label : 'Expired' }, 
			// 	{ name : 4, label : 'Revoked' }, 
			// 	{ name : 3, label : 'Cancelled' },
			// 	{ name : 6, label : 'Blacklisted'} 
			// ]},
			{ name: 'vuav.FK_PriorityLevel', label: 'Personal Grade', inputType:'number' },
			// { name: 'TypeName', label: 'Permenant Parking' , inputType:"select", values:[ 
			// 	{ name : 'Permanent', label : 'Yes'},
			// 	{ name : 'Temporary', label : 'No' }
			// ]},
			{ name: 'isMedicalPass', label: 'Medical Pass' , inputType:"select", values:[ 
				{ name : 'true', label : 'Yes'},
				{ name : 'false', label : 'No' }
			]}
		]
		:	props?.tabVal===2 ?
		[
			{ name: 'fullName', label: 'Full Name'},
			{ name: 'emailID', label: 'Email ID'},
			{ name: 'contactNo', label: 'Contact No'},
			{ name: 'depName', label: 'Department'},
			{ name: 'desName', label: 'Designation'},
			{ name: 'plateNumber', label: 'Plate Number'},
			{ name: 'placeOfRegistration', label: 'Place of Registration'},
			// { name: 'brand', label: 'Brand'},
			// { name: 'color', label: 'Color'},
			{ name:'vehicleCategory', label: 'Vehicle Category'},
			{ name: 'CAST (parkingDate AS date)', label: 'Parking Date', inputType: 'date'},
			// { name : "visitID", label: "Visit ID", inputType: 'number'},
			{ name : "FORMAT (inTime , 'HH:mm')",	label : "In-Time",	inputType: 'time' },
			{ name : "FORMAT (outTime , 'HH:mm')",	label : "Out-Time",	inputType: 'time' },
			{ name : "duration",		label : "Duration",			inputType: 'time',	valueFn: convTimeFn },
			{ name : "zoneName",		label : "Zone Name" },
			{ name : "bayName",			label : "Bay Name" },
			{ name : "bTypeName",		label : "Bay Type Name" },
			// { name : "siteName",		label : 'Site Name'}
		]
		: props?.tabVal===3 ?
		[
		{ name: 'pRequestID', label: 'Request ID', inputType:'number' },
		{ name: 'pReferanceID', label: 'ASKGS Request ID' },
		{ name: 'RequestedFUName', label: 'Requested For Name' },
		{ name: 'RequestedBUName', label: 'Requested By Name' },
		{ name: 'RequstedFUEmailID', label: 'Requested For Email' },

		{ name: 'PlateNumber', label: 'Plate  Number' },
		{ name: 'Category', label: 'Vehicle Category' },
		{ name: 'PlaceOfRegistration', label: 'Place Of Registration' },

		{ name: 'rF_PriorityLevelID', label: 'Personal Grade', inputType:'number' },
		// { name: 'isPermenantParking', label: 'Permenant Parking' , inputType:"select", values:[ 
		// 		{ name : 'true', label : 'Yes'},
		// 		{ name : 'false', label : 'No' }
		// ]},
		// { name: 'isMedicalPass', label: 'Medical Pass' , inputType:"select", values:[ 
		// 		{ name : 'true', label : 'Yes'},
		// 		{ name : 'false', label : 'No' }
		// ]},
		{ name: `CAST (RequestedOn as date)`, label: 'Requested On', inputType: 'date' },
		{ name: 'StartDate', label: 'Start Date', inputType: 'date' },
		{ name: 'EndDate', label: 'End Date', inputType: 'date' },
		{ name: 'StartTime', label: 'Start Time', inputType: 'time' },
		{ name: 'EndTime', label: 'End Time', inputType: 'time' },
		// {
		// 	name: 'RFUserType',
		// 	label: 'User Type',
		// 	inputType: 'select',
		// 	values: [
		// 		{ name: "1", label: "Employee" },
		// 		{ name: "2", label: "Visitor" }
		// 	  ]
		// },
		// { name: 'tsp.Fk_SiteID', label: 'SITE ID', inputType: 'number' },
		{ name: 'siteName', label: 'Site Name' },
		{ name: 'RequestStatus', label: 'Request Status', inputType: 'select',
		values: [
			{ name: '0', label: 'Pending' },
			{ name: '1', label: 'Approved' },
			{ name: '2', label: 'Rejected' },
			// { name: '3', label: 'Cancelled' },
			// { name: '4', label: 'Revoked' },
			// { name: '5', label: 'Deleted' },
			{ name: '6', label: 'Blacklisted' },
			// { name: '7', label: 'Auto Rejected' }
		]
	},
		{ name: 'FK_RequestType', label: 'Request Type ', inputType: 'select', values:[
			{ name: '1', label: 'Employee Parking' },
			{ name: '2', label: 'Visitor Parking' },
			{ name: '3', label: 'Employee Vehicle Update' },
			{ name: '4', label: 'Visitor Vehicle Update' },
			{ name: '5', label: 'Employee Overtime Parking' },
			{ name: '6', label: 'Visitor Overtime Parking' },
			{ name: '7', label: 'Employee Parking Cancellation' },
			{ name: '8', label: 'Visitor Parking Cancellation' },

		] },

		// { name: 'ApprovedZoneID', label: 'ZONE ID', inputType: 'number' },
		// { name: 'zoneName', label: 'Zone Name' },
		// // { name: 'FK_ApprovedBayID', label: 'BAY ID', inputType: 'number' },
		// { name: 'bayName', label: 'Bay Name' },
		]
	:
	[
		// { name: 'violationID', label: 'ID' },
		{ name: 'CAST (VDate AS date)', label: 'Date', inputType: 'date' },
		{ name: 'VTime', label: 'Time', inputType: 'time' },
		{ name: 'platenumber', label: 'Plate Number' },
		{ name: 'vehicleCategory', label: 'Vehicle Category' },
		{ name: 'placeOfRegistration', label: 'Place of Registration' },
		{ name: 'FK_ViolationType', label: 'Violation Type' , inputType: 'select', values:VIOLATION_TYPE_LIST.VIOLATIONTYPE_DDL.map((item)=>({"name":item?.vType , "label" : item?.label})) },
		{ name: 'tsp.FK_UserType', label: 'User Type', inputType:'select' ,	values: [
			{ name: "1", label: "Employee" },
			{ name: "2", label: "Visitor" }
		  ] },
		// { name: 'FK_ReportedBay', label: 'Reported Bay ID'  , inputType:"number"},
		{ name: 'PenaltyLevelID', label: 'Penalty Level', inputType:'select' ,	values: [
			{ name: "1", label: "Level 1" },
			{ name: "2", label: "Level 2" },
			{ name: "3", label: "Level 3" }
		  ] },
		// { name: 'reportedBay', label: 'Reported Bay Name' , },

		// { name: 'violatedUser', label: 'Violated User'},
		// { name: 'emailID', label: 'Violated User Email'},
		// { name: 'depName', label: 'Violated User Department'},
		// { name: 'desName', label: 'Violated User Designation'},
		// { name: 'phoneNumber', label: 'Violated User Contact Number'},
		{ name: 'vud2.FullName', label: 'Violated User'},
		{ name: 'vud2.EmailID', label: 'Violated User Email'},
		{ name: 'vud2.DepName', label: 'Violated User Department'},
		{ name: 'vud2.DesName', label: 'Violated User Designation'},
		{ name: 'vud2.ContactNo', label: 'Violated User Contact Number'},
		{ name: 'FK_PriorityLevel', label: 'Violated User Personal Grade'},

		// { name: 'isManualEntry', label: 'Is Manual Entry' , inputType:"select", values:[ 
		// 	{ name : 'true', label : 'Yes'},
		// 	{ name : 'false', label : 'No' }
		// ]},
		// { name: 'emailSentStatus', label: 'Email Sent' , inputType:"select", values:[ 
		// 	{ name : 'true', label : 'Yes'},
		// 	{ name : 'false', label : 'No' }
		// ]},
		// { name: 'needSensorCheck', label: 'Need Sensor Check' , inputType:"select", values:[ 
		// 	{ name : 'true', label : 'Yes'},
		// 	{ name : 'false', label : 'No' }
		// ]},
		{ name: 'ZoneName', label: 'Violated Zone' },
	]

	const deployQueryFilter = (query: string) => {
		setFilterActive(true);

		let QueryInput: FilterProps[] = [
			{
				field: `CAST (${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===2 ? `ParkingDate` :
					props?.tabVal===4 ? 'VDate'	: `StartDate`} AS date)`,
				value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: `CAST (${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===2 ? `ParkingDate` :
					props?.tabVal===4 ? 'VDate'	: `EndDate`} AS date)`,
				value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: `${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===3 ? `StartTime` :
					props?.tabVal===2 ? `FORMAT (InTime , 'HH:mm')` :
					props?.tabVal===4 ? 'VTime'	: `StratTime`}`,
				value: timeRange && timeRange?.length > 0 ? `'${dayjs(timeRange[0]).format(props?.tabVal===2 ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss')}'` : null,
				operator: '>=',
			},
			{
				field: `${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${ props?.tabVal===4 ? 'VTime' :
					props?.tabVal===2 ? `FORMAT (OutTime , 'HH:mm')` : `EndTime`}`,
				value: timeRange && timeRange?.length > 1 ? `'${dayjs(timeRange[1]).format(props?.tabVal===2 ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss')}'` : null,
				operator: '<=',
			},
			{
				field: `${props?.tabVal===2 ? `tvm.` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===3 ? `RequestedFUID` : `FK_UserUniqueID`}`,
				value: selectedUser ? selectedUser?.userUniqueID : null,
				operator: '=',
			},
			{
				field: `${props?.tabVal===2 ? `tvm.` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===3 ? `RFUserType` : props?.tabVal===4 ? `FK_UserType` : `IsVisitor`}`,
				value: selectedUser ? props?.tabVal===3 || props?.tabVal===4 ? 
					selectedUser?.isVisitor ? 2 : 1 : selectedUser?.isVisitor : null,
				operator: '=',
			},
		];
		setQueryTxt(query)
		let postdata = {
			'query': `${query} ${query!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}${
				props?.tabVal===3 && selectedUser ? `${
					queryTxt!=='' || commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''
				} tsp.RequestedFUID like ${selectedUser?.userUniqueID}` : ''
			}${
				props?.tabVal===4 && selectedUser ? `${
					queryTxt!=='' || commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''
				} tsp.FK_UserUniqueID like ${selectedUser?.userUniqueID}` : ''
			}`
		};
		
		fetch_user_report('', dispatch, {
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION.limit,
			},
		}, props?.tabVal);
		advancemodalClose()
	};

    // const handleChangeExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
	// 	setExpanded(!expanded);
	// };

    const promiseUserOptions = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleUserInputChange(inputValue));
		});

	const handleUserInputChange = async (key?: string) => {
		let Response = await fetch_all_usersSearch(key);
		console.log('RESPONSE', Response);
		let data = Response?.response?.data ?? [];

		let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: `${item?.plateNumber} (${item?.fullName})`, object: item }));
		// let Options = data

		console.log('options', Options);
		setUsersList(data);
		setUsersArr(Options || []);
		// setUserVehicleList([])

		return Options;
	};

	const handleUserChange = (val: any) => {
		console.log('val=>',val)
		let data: any = usersList.find((item: any) => item===val?.object);
		let forData = {
			'filters': {
				'query': `userUniqueID = ${data?.userUniqueID}`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': 1,
			},
		};
		if (data) {
			setselectedUser(data);
			// if (data?.isVisitor) {
			// 	fetch_visitor_detail(data?.userUniqueID)?.then((res: any) => {
					
			// 	});
			// } else {
			// 	search_user(forData)?.then((res: any) => {
					
			// 	});
			// }
			
		} else {}
	};

	const clearSearch = () => {
		fetch_user_report('', dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
			},
		}, 
		props?.tabVal);

		setTimeRange([]);
		setselectedUser(null)
		setDateRange([]);
		setFilterActive(false)
		if(dateRange && dateRange?.length>0){
			dispatch(UPDATE_USER_REPORT_DATE_UPDATE({
				dates : []
			}))	
		}
		setQueryTxt('')
		advancemodalClose()
	};

	const filterSearch = () => {
		let QueryInput: FilterProps[] = [
			{
				field: `${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===2 ? `ParkingDate` :
					props?.tabVal===4 ? 'VDate'	: `StartDate`}`,
				value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: `${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===2 ? `ParkingDate` :
					props?.tabVal===4 ? 'VDate'	: `EndDate`}`,
				value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: `${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${props?.tabVal===3 ? `StartTime` :
					props?.tabVal===2 ? `FORMAT (InTime , 'HH:mm')` :
					props?.tabVal===4 ? 'VTime'	: `StratTime`}`,
				value: timeRange && timeRange?.length > 0 ? `'${dayjs(timeRange[0]).format(props?.tabVal===2 ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss')}'` : null,
				operator: '>=',
			},
			{
				field: `${props?.tabVal===2 ? `` : props?.tabVal===4 ? '' : 'tsp.'}${ props?.tabVal===4 ? 'VTime' :
					props?.tabVal===2 ? `FORMAT (OutTime , 'HH:mm')` : `EndTime`}`,
				value: timeRange && timeRange?.length > 1 ? `'${dayjs(timeRange[1]).format(props?.tabVal===2 ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss')}'` : null,
				operator: '<=',
			},
			{
				field: `${props?.tabVal===2 ? `tvm.` : props?.tabVal===4 ? 'tsp.' : 'tsp.'}${props?.tabVal===3 ? `RequestedFUID` : `FK_UserUniqueID`}`,
				value: selectedUser ? selectedUser?.userUniqueID : null,
				operator: '=',
			},
			{
				field: `${props?.tabVal===2 ? `tvm.` : props?.tabVal===4 ? 'tsp.' : 'tsp.'}${props?.tabVal===3 ? `RFUserType` : props?.tabVal===4 ? `FK_UserType` : `IsVisitor`}`,
				value: selectedUser ? props?.tabVal===3 || props?.tabVal===4 ? 
					selectedUser?.isVisitor ? 2 : 1 : selectedUser?.isVisitor : null,
				operator: '=',
			},
		];
		let postdata = {
			'query': `${queryTxt} ${queryTxt!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}${
				props?.tabVal===3 && selectedUser ? `${
					queryTxt!=='' || commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''
				} tsp.RequestedFUID = ${selectedUser?.userUniqueID}` : ''
			}${
				props?.tabVal===4 && selectedUser ? `${
					queryTxt!=='' || commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''
				} tsp.FK_UserUniqueID like ${selectedUser?.userUniqueID}` : ''
			}`
		};
		fetch_user_report('', dispatch, {
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION.limit,
			},
		}, props?.tabVal);
		if(dateRange && dateRange?.length>0){
			dispatch(UPDATE_USER_REPORT_DATE_UPDATE({
				dates : [dayjs(dateRange[0]).format('YYYY-MM-DD'),dayjs(dateRange[1]).format('YYYY-MM-DD')]
			}))	
		}
	};

	useKeyboard('Enter', filterSearch);

	useKeyboard('Escape', clearSearch);

  return (
	<div className="white-card data-clean mb-30">
		<div className="search-filter-row mb-0">
			<ul>
				<li>
					<a  className="active pointerDefaultSet"><i className="fa-solid fa-magnifying-glass"></i>  Quick Search</a>
				</li>
				<li>
					<a onClick={() => advancemodalOpen()}>
						<i className='fa-solid fa-filter'></i>Advanced Filter
					</a>
				</li>
					{(VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query !== '' || filterActive) && (
				<li>
					<a onClick={clearSearch}>
						<i className='fa-solid fa-times'></i>Clear All Filters
					</a>
				</li>
			)}
			</ul>
			<div className="filter-block" id="quick-search">
				<div className='row'>
				<Tooltip title={'Filter by User or Visitor'}>
				<div className="col-3">
    
                    <AsyncSelect
						className={'form-control'}
						styles={{
							control: (styles) => ({
								...styles,
								
								background: 'transparent',
								border: 'none',
								boxShadow:'none',
								paddingTop:'4px',
								':hover':{
									...styles[':hover'],
									border:'none',
									outline:'none',
									boxShadow:'none'
								}
							}),
							
							placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
						}}
						placeholder='Find User or Visitor'
						cacheOptions={usersArr}
						defaultOptions={usersArr}
						value={selectedUser ?
							usersArr?.find((el:any)=>el.userUniqueID===selectedUser.userUniqueID && el.object===selectedUser) : 
							 null}
						getOptionLabel={(o) => o.label}
						getOptionValue={(o) => o.object}
						formatOptionLabel={(o) => (
							<div className='dflex justify-space-between align-items-center'>
								<div className='dflex col-dir gap-5'>
									<div>
										<span
											style={{
												fontSize: '.85rem',
												fontWeight: 600,
												textTransform: 'capitalize',
											}}>
											{o?.object.fullName} -{' '}
										</span>
										<small style={{ fontStyle: 'italic' }}>{o?.object.emailID}</small>
									</div>
									{/* <div
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '100%',
											// justifyContent: 'space-between',
										}}>
										<span style={{ fontSize: '.85rem', fontWeight: 600 }}>
											{o?.object.plateNumber} -{' '}
										</span>
										<small style={{ fontStyle: 'italic' }}>
											{o?.object.placeOfRegistration}-
										</small>
										<small style={{ fontStyle: 'italic' }}>
											{o?.object.vehicleCategory}
										</small>
									</div> */}
								</div>

								<div>
									<StatusChipComponent text={o?.object.isVisitor ? 'Visitor' : 'Employee'} />
								</div>
							</div>
						)}
						
						loadOptions={promiseUserOptions}
						onChange={handleUserChange}
						
						isSearchable={true}
						isClearable={true}
						isOptionDisabled={(o)=> o.object.isBlackListedVehicle}
					/>
										
                </div>
				</Tooltip>
                <div className="col-3">
					<div className='form-group'>
					<Tooltip title={ props?.tabVal===2 ? 'Filter visit reports between visited dates only' :
						props?.tabVal===1 ? 'Filter parking allocation reports between allocated dates only ' :
						props?.tabVal===3 ? 'Filter parking request reports between allocation requested dates only' :
						'Filter violation reports between violated dates only'}  className='col-6 dflex flex-justify-between'>
					<RangePicker
						picker='date'
						allowClear={false}
						allowEmpty={[false, false]}
						className='form-control'
						placeholder={['From Date', 'To Date']}
						inputReadOnly
						value={dateRange}
						format={'DD-MMM-YYYY'}
						onChange={(dates: any) => {
							setDateRange(dates);
						}}
						separator={<SwapOutlined style={{ color : 'GrayText' }}/>}
					/>
					</Tooltip>
					
					</div>                    
                </div>

                <div className="col-4">
					<div className='form-group'>
					<Tooltip title={ props?.tabVal===2 ? 'Filter visit reports between specific visited date-timestamp' :
						props?.tabVal===1 ? 'Filter parking allocation reports between allocated time only ' :
						props?.tabVal===3 ? 'Filter parking request reports between allocation requested times only' :
						'Filter violation reports between violated times only'}  className='col-6 dflex flex-justify-between'>
					<RangePicker
						picker={props?.tabVal===2 ? 'date' : 'time'}
						showTime={props?.tabVal===2}
						allowClear={false}
						allowEmpty={[false, false]}
						className='form-control'
						placeholder={props?.tabVal===2 ? ['From In-Time', 'To Out-Time'] : ['From Time', 'To Time']}
						inputReadOnly
						value={timeRange}
						format={props?.tabVal===2 ? 'DD-MMM-YYYY HH:mm:ss' : 'hh:mm a'}
						onChange={(dates: any) => {
							setTimeRange(dates);
						}}
						separator={<SwapOutlined style={{ color : 'GrayText' }}/>}
					/>
					</Tooltip>
					</div>                    
                </div>
				<div className='col-3'>
					<div className="form-group">
                    	<button
						onClick={() => {
							filterSearch();
						}} className="btn btn-secondary">Generate Report</button>
                	</div>
				</div>
                
				
				</div>
			</div>
		</div>

		<QueryBuilderModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
	</div>
    
  )
}

export default FilterBoxBar