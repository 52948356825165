import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { definiton_add_edit, fetch_definitionList } from '../../../store/action';

type Props = {
	open: boolean;
	handleClose: () => void;
	label: string;
    path:string;
	Data?: any
};

const AddModal = (props: Props) => {

	const [DefId, setDefId] = useState('-')
	const [DefName, setDefName] = useState('')
	const [DefDescp, setDefDescp] = useState('')
	const [DefSite, setDefSite] = useState('')
	const [loading, setLoading] = useState(false)

	let navigate = useNavigate()
	const dispatch = useAppDispatch()
	const SITELIST = useAppSelector((state) => state?.general)
	const DEFINITION_DATA = useAppSelector((state) => state?.definition)

	const [error, setError] = useState({
		name : false,
		descriptions : false,
		site : false
	})
	useEffect(() => {
	  if(props.Data){
		setDefId(props.Data?.id??'-')
		setDefName(props.Data?.name??'')
		setDefDescp(props.Data && props.Data.descriptions? props.Data.descriptions : '')
		setDefSite(props.Data && typeof props.Data.fK_SiteID!=='undefined' ? props.Data.fK_SiteID : '')

	  }
	}, [props.Data])
	

	const DefinitonActionFx = () =>{
		setLoading(true)

        let postdata:any = props.Data ? {
			ID : DefId,
			FK_ID : DefSite,
			PLevel : DefName,
            descriptions : DefDescp,
			IsEnable : props.Data.isEnable
		} :
		{
			FK_ID : DefSite,
			PLevel : DefName,
            descriptions : DefDescp,
			IsEnable : true
		}			
		
		const Success = (res:any) => {
			setLoading(false)

			SuccesToast(res?.response?.data)
			setDefId('')
			setDefName('')
			setDefDescp('')
			setDefSite('')
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": ""
					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                props.path
			);
			props.handleClose()
		}
		const Failed = (err:any) => {
			setLoading(false)

			FailedToast(err?.response)		
		}

		if(props.Data){
			definiton_add_edit('put',postdata,props.path,Success,Failed)
		}
		else{
			definiton_add_edit('post',postdata,props.path,Success,Failed)
		}

	}

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title={`${props.Data ? 'Edit' : 'Add'} New ${props.label}`} subtitle={DefId!=='-' ? `#${DefId}` : 'Definitions'}>
				<div className="row">
					{/* {DefId!=='-' &&
					<div className="col-3">
						<div className="form-group inline-input full-label">
							<label>ID</label>
							<input type="text" name='' value={DefId} disabled className="form-control grey-input" />
						</div>
					</div>
					} */}
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Personal Grade</label>
							<input type="number" name='' value={DefName} onChange={(e) => setDefName(e.target.value)}
								className="form-control grey-input" />
							{	error.name &&
									<p className='hookform-error-message'>A valid Level is required</p>}
						</div>
					</div>
					<div className="col-9">
						<div className="form-group inline-input full-label">
							<label>Site</label>
							<select className="form-control grey-input" value={DefSite} onChange={(e)=> setDefSite(e.target.value)}>
								{
									SITELIST.SITE_DDL.map((el:any) => {
										return(
											<option value={el.value}>{el.label}</option>
										)
									})
								}
								<option value={''}>Select a Site</option>
							</select>
							{	error.site &&
									<p className='hookform-error-message'>A Site is mandatory</p>}
						</div>
					</div>
					
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>{props.label} Description</label>
							<textarea className="form-control grey-input"
								value={DefDescp} onChange={(e)=> setDefDescp(e.target.value) }
							></textarea>
							{	error.descriptions &&
									<p className='hookform-error-message'>A valid Description is required</p>}
						</div>
					</div>					
					<div className="col-12">
						<div className="modal-footer">
							<button type="button" className="btn btn-outline-blue close-modal-button" onClick={props.handleClose}>Cancel</button>
							<button type="submit" className="btn btn-secondary"
							 onClick={()=>{
								if(
									DefDescp!=='' && DefName!=='' && DefSite!==''
								){
									setError({
										name : false, descriptions : false, site : false
									})	
									DefinitonActionFx()
								}else{
									setError({
										name : DefName==='',
										descriptions : DefDescp==='',
										site : DefSite===''
									})
								}
							 }}>
								<ButtonLoader text={`${props.Data ? 'Edit' : 'Add'} Personal Grade`} loading={loading}/>
							</button>

						</div>	
					</div>
				</div>
		</ModalLayout>
	);
};

export default AddModal;
