import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { fetch_zoneDDL } from '../../../../../common/general/action';
import { commonSearchQuery, FilterProps } from '../../../../../config/filterSearch';
// import CommonInput from '../../../../../common/components/commonInput';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { exportTransaction_as_excel, exportTransaction_as_pdf, fetch_parking_transaction } from '../../../store/action';
// import AddRole from './addRole';
// import ReactDatePicker from 'react-datepicker';
import { DatePicker, Tooltip } from 'antd';

import PopUp from './../../../../../common/components/popUp';
import NestedSelect from '../../../../../common/components/nested_select/NestedSelect';
import { fetch_treeMap_withPermission } from '../../../../security/store/action';
import moment from 'moment';

const { RangePicker } = DatePicker;

type Props = {};
type T_Zone = {
	zoneID: number;
	zoneName: string;
};

let tType = [
	{
		label: 'Plate Found',
		value: 0,
	},
	{
		label: 'Entry',
		value: 1,
	},
	{
		label: 'Exit',
		value: 2,
	},
];

const FilterBar = (props: Props) => {
	const [modalOpen, setmodalOpen] = useState(false);
	const handleOpen = () => setmodalOpen(true);
	const handleClose = () => setmodalOpen(false);

	const [date, setdate] = useState<any>([moment().subtract('7','d'),moment()]);
	const [plate, setplate] = useState<any>('');
	// const [zone, setzone] = useState<any>('');
	const [ttype, setttype] = useState<any>('');

	const [selectedZone, setselectedZone] = useState<T_Zone>();
	const [selectedFloor, setselectedFloor] = useState<any>();
	const [selectedSite, setselectedSite] = useState<any>();
	const [tree, setTree] = useState<any>([]);
	const [treeLoading, settreeLoading] = useState<any>(false);
	const [expTp, setExpTp] = useState(0)
	const [printLoading, setPrintLoading] = useState(false)
	const [filterActive, setFilterActive] = useState(false)
	const [queryTxt, setQueryTxt] = useState('')

	const clearRef = useRef<any>();

	const dispatch = useAppDispatch();
	useEffect(() => {
		const controller = new AbortController();
		fetch_zoneDDL(dispatch, controller.signal);
		// fetch_priorityLevelDDL(dispatch, controller.signal);
		// fetch_designationDDL(dispatch, controller.signal);
		// fetch_userTypeDDL(dispatch, controller.signal);
		return () => controller.abort();
	}, [dispatch]);

	useEffect(() => {
		settreeLoading(true);
		const success = (res: any) => {
			setTree(res?.response ?? []);

			setTimeout(() => {
				settreeLoading(false);
			}, 1500);
		};
		const failed = (err: any) => {
			setTimeout(() => {
				settreeLoading(false);
			}, 1500);
		};
		fetch_treeMap_withPermission(success, failed);
	}, []);

	const PARKING_DATA = useAppSelector((state) => state?.realtime);
	console.log('TRASACTION=>',PARKING_DATA?.TRANSACTION_FILTER)
	// const GENERAL_DDL = useAppSelector((state) => state?.general);
	const updateLoaderFn = () =>{
		setPrintLoading(false)
	}
	const newLocal = (
		<div className='input-like-div pos-rel' style={{ overflow: 'hidden' }}>
			{selectedZone?.zoneName ? (
				selectedZone?.zoneName
			) : (
				<span style={{ fontSize: '14px', color: 'var(--disabledText)' }}> Select Zone</span>
			)}
			<div
				style={{
					position: 'absolute',
					right: '10px',
					borderLeft: '1px solid var(--lightgrey-second)',
					height: '30px',
					padding: '8px 0px 10px 10px',
				}}>
				<i className='fa fa-chevron-down' style={{ color: 'var(--lightgrey-second)' }} />
			</div>
		</div>
	);

	// const [search, setsearch] = useState('')

	const filterSearch = () => {
		setFilterActive(true)

		let QueryInput: FilterProps[] = [
			// {
			// 	field: `CAST (transactiondatetme AS date)`,
			// 	value: date ? `'${dayjs(date).format('YYYY-MM-DD')}'` :"",
			// 	operator: '=',
			// },
			{
				field: 'transactiondatetime',
				value: date && date[0] ? `'${date[0].format('YYYY-MM-DD HH:mm:ss')}'` : null,
				operator: '>=',
			},
			{
				field: 'transactiondatetime',
				value: date && date[1] ? `'${date[1].format('YYYY-MM-DD HH:mm:ss')}'` : null,
				operator: '<=',
			},
			{
				field: 'plateno',
				value: plate,
				operator: 'contains',
			},
			{
				field: 'fk_zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'FK_LevelID',
				value: selectedFloor?.levelID,
				operator: '=',
			},
			{
				field: 'ttype',
				value: ttype,
				operator: '=',
			},
		];

		let postdata = {
			'query': `${queryTxt} ${queryTxt!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.TRANSACTION_FILTER?.orderBy??'transactionDateTime desc'
		};

		fetch_parking_transaction(dispatch, {
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.TRANSACTION_PAGINATION?.limit,
			},
		});

		// fetch_rolelist(navigate, dispatch, posdData)
	};

	const clearSearch = () => {
		setFilterActive(false)

		fetch_parking_transaction(dispatch, {
			'filters': {
				'query': ` transactiondatetime >= '${moment().subtract(7,'days').format('YYYY-MM-DD')} 09:00:00' AND transactiondatetime <= '${moment().format('YYYY-MM-DD')} 19:00:00'`,
				'orderBy' : PARKING_DATA?.TRANSACTION_FILTER?.orderBy??'transactionDateTime desc'
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.TRANSACTION_PAGINATION?.limit,
			},
		});
		setselectedZone(undefined);
		setdate([moment().subtract('7','d'),moment()])
		setplate('')
		setttype('')
		setQueryTxt('')
	};

	const exportAsPdf = () => {
		setPrintLoading(true)
		let QueryInput: FilterProps[] = [
			// {
			// 	field: `CAST (transactiondatetme AS date)`,
			// 	value: date ? `'${dayjs(date).format('YYYY-MM-DD')}'` :"",
			// 	operator: '=',
			// },
			{
				field: 'transactiondatetime',
				value: date && date[0] ? `'${date[0].format('YYYY-MM-DD HH:mm:ss')}'` : null,
				operator: '>=',
			},
			{
				field: 'transactiondatetime',
				value: date && date[1] ? `'${date[1].format('YYYY-MM-DD HH:mm:ss')}'` : null,
				operator: '<=',
			},
			{
				field: 'plateno',
				value: plate,
				operator: 'contains',
			},
			{
				field: 'fk_zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'FK_LevelID',
				value: selectedFloor?.levelID,
				operator: '=',
			},
			{
				field: 'ttype',
				value: ttype,
				operator: '=',
			},
		];
		let postdata = {
			'query': `${queryTxt} ${queryTxt!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.TRANSACTION_FILTER?.orderBy??'transactionDateTime desc'
		};
		exportTransaction_as_pdf({
			'ToDate': date && date[1] ? `${date[1].format('YYYY-MM-DD')}` : null,
			'FromDate': date && date[0] ? `${date[0].format('YYYY-MM-DD')}` : null,
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.TRANSACTION_PAGINATION?.totalCount,
			},
		},updateLoaderFn)
	};

	const exportAsExcel = () => {
		setPrintLoading(true)

		let QueryInput: FilterProps[] = [
			// {
			// 	field: `CAST (transactiondatetme AS date)`,
			// 	value: date ? `'${dayjs(date).format('YYYY-MM-DD')}'` :"",
			// 	operator: '=',
			// },
			{
				field: 'transactiondatetime',
				value: date && date[0] ? `'${date[0].format('YYYY-MM-DD HH:mm:ss')}'` : null,
				operator: '>=',
			},
			{
				field: 'transactiondatetime',
				value: date && date[1] ? `'${date[1].format('YYYY-MM-DD HH:mm:ss')}'` : null,
				operator: '<=',
			},
			{
				field: 'plateno',
				value: plate,
				operator: 'contains',
			},
			{
				field: 'fk_zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'FK_LevelID',
				value: selectedFloor?.levelID,
				operator: '=',
			},
			{
				field: 'ttype',
				value: ttype,
				operator: '=',
			},
		];
		let postdata = {
			'query': `${queryTxt} ${queryTxt!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.TRANSACTION_FILTER?.orderBy??'transactionDateTime desc'
		};
		exportTransaction_as_excel({
			'ToDate': date && date[1] ? `${date[1].format('YYYY-MM-DD')}` : null,
			'FromDate': date && date[0] ? `${date[0].format('YYYY-MM-DD')}` : null,
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.TRANSACTION_PAGINATION?.totalCount,
			},
		},updateLoaderFn);
	};

	const parentRef: any = useRef(null);

	const childRef: any = useRef(null);

	const handleCloseModal = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	React.useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu then close the menu
			let targt: any = parentRef.current;
			if (targt && !targt.contains(e.target)) {
				handleCloseModal();
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, []);

	return (
		<div className='search-filter-row mb-30'>
			<div className='row'>
				<div className='col-8'>
					<ul>
						<li>
							<a  className='active pointerDefaultSet'>
								<i className='fa-solid fa-magnifying-glass'></i> Quick Search
							</a>
						</li>

						{filterActive && (
							<li>
								<a onClick={clearSearch}>
									<i className='fa-solid fa-times'></i>Clear All Filters
								</a>
							</li>
						)}

						{PARKING_DATA?.TRANSACTION_FILTER?.query?.includes('transactiondatetime') ? (
							<>
								<li
									className={
										// date &&
										// date?.length === 2 &&
										PARKING_DATA?.TRANSACTION_FILTER?.query?.includes('transactiondatetime')
											? ''
											: 'disableAndOpacity2'
									}>
									{/* <a onClick={exportAsPdf}>
										<i className='fa-solid fa-download'></i>Export as PDF
									</a> */}
									<ReactSelect
										options={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]}
										className={'common-select common-select-grey fontSize13Px common-select-exporter'}
										placeholder={'Export Report'}
										isMulti={false}
										isClearable={false}
										isSearchable={false}
										isLoading={printLoading}
										value={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]?.find((c) => c.value === expTp) ?? ''}
										onChange={(val: any) => {
											setExpTp(val?.value);
											if(val?.value===0){
												exportAsPdf()
											}else{
												exportAsExcel()
											}
										}}
										
									/>
								</li>
							</>
						) : (
							<Tooltip title={'Due to large amount of data, data need to be  filtered with startdate and endate to be exportable.'}>
								<li>
									<ReactSelect
										options={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]}
										className={'common-select disableAndOpacity fontSize13Px common-select-exporter'}
										placeholder={'Export Report'}
										isMulti={false}
										isClearable={false}
										isSearchable={false}
										
										value={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]?.find((c) => c.value === expTp) ?? ''}
										onChange={(val: any) => {
											setExpTp(val?.value);
										}}
									/>
								</li>
							</Tooltip>
						)}
					</ul>
				</div>
			</div>

			<div className='filter-block mb-30' id='quick-search'>
				<>
					<div className='row'>
						<div className='col'>
							<div className='form-group'>
								{/* <input type="Date" name="" placeholder="Date" className="form-control" /> */}

								{/* <ReactDatePicker
                            selected={date}
                            onChange={(date: any) => {
                                setdate(date);
                            }}
                            selectsStart
                            dateFormat={'dd-MMM-yyyy'}
                            className='form-control'
                            placeholderText='mm/dd/yyyy'
                        /> */}
								<RangePicker
									size='large'
									onChange={(date: any) => setdate(date)}
									value={date}
									allowClear={false}
									showTime
									inputReadOnly
									format={'DD-MMM-YYYY hh:mm a'}
									className='form-control'
									defaultValue={[moment().subtract('7','d'),moment()]}
								/>
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<input
									type='text'
									name=''
									placeholder='Plate Number'
									className='form-control'
									value={plate}
									onChange={(e) => setplate(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-2'>
							{/* <ReactSelect
                            ref={clearRef}
                            options={GENERAL_DDL?.ZONE_DDL}
                            className={'common-select'}
                            placeholder={'zone'}
                            isMulti={false}
                            isClearable
                            value={GENERAL_DDL?.ZONE_DDL?.find((c) => c.value === zone) ?? ''}
                            onChange={(val: any) => {
                                setzone(val?.value);
                            }}
                        /> */}
							<PopUp icon={newLocal} placement='bottom-start' style={{ paddingTop: '5px' }} ref={childRef}>
								<NestedSelect
									
									selectedZone={selectedZone}
									setselectedZone={setselectedZone}
									tree={tree}
									ref={parentRef}
									setselectedFloor={setselectedFloor}
									setselectedSite={setselectedSite}
									style={{ width: '18vw' }}
								/>
							</PopUp>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<ReactSelect
									ref={clearRef}
									options={tType}
									className={'common-select'}
									placeholder={'Transaction type'}
									isMulti={false}
									isClearable
									value={tType?.find((c) => c.value === ttype) ?? ''}
									onChange={(val: any) => {
										setttype(val?.value);
									}}
								/>
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<button className='form-control blue-btn' onClick={filterSearch}>
									Search
								</button>
							</div>
						</div>
					</div>
				</>
			</div>
		</div>
	);
};

export default FilterBar;
