import React, { useEffect } from 'react';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import { Route, Routes, Navigate } from 'react-router-dom';
import SecurityRoles from './screens/Roles/securityRoles';
import WebUsers from './screens/Web users/webUsers';
import PasswordPolicy from './screens/Password Policy/passwordPolicy';
import WebUserRequest from './screens/webUserRequest/WebUserRequest';
import PermissionWrapper from './../../common/components/PermissionWrapper';
import { useRedirect } from '../../utils/hooks/useRedirect';
import DashboardCustomization from './screens/DashboardCustomization/DashboardCustomization';
import { subModuleList } from './../../config/subModuleList';
import { useDispatch } from 'react-redux';
import { RESET_SECURITY_FILTERS_ONLY, RESET_STATE } from './store/securitySlice';

const SecurityIndex = () => {
	const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
	const redirectedPath = useRedirect([
		{
			path: 'security-roles',
			submoduleID: subModuleList.Roles,
		},
		{
			path: 'web-users',
			submoduleID: subModuleList['Web User'],
		},
		{
			path: 'web-users-requests',
			submoduleID: subModuleList['Web Users Request'],
		},
		{
			path: 'password-policy',
			submoduleID: subModuleList['Password Policy'],
		},
		{
			path: 'dashboard-customization',
			submoduleID: subModuleList.Roles,
		},
	]);

	return (
		<div className='navtabs__container' 
	
		>
			<NavTabs>
				<PermissionWrapper subModuleID={subModuleList.Roles}>
					<LinkTab label='Roles' href='security-roles' navLinkPropsOnclick={()=>{ dispatch(RESET_SECURITY_FILTERS_ONLY()) }} />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Web User']}>
					<LinkTab label='Web Users' href='web-users'  navLinkPropsOnclick={()=>{ dispatch(RESET_SECURITY_FILTERS_ONLY()) }}  />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Web Users Request']}>
					<LinkTab label='Web Users Requests' href='web-users-requests'  navLinkPropsOnclick={()=>{ dispatch(RESET_SECURITY_FILTERS_ONLY()) }} />
				</PermissionWrapper>

				<PermissionWrapper subModuleID={subModuleList['Password Policy']}>
					<LinkTab label='Password Policy' href='password-policy'  navLinkPropsOnclick={()=>{ dispatch(RESET_SECURITY_FILTERS_ONLY()) }}  />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList.Roles}>
					<LinkTab label='Dashboard Customization' href='dashboard-customization'  navLinkPropsOnclick={()=>{ dispatch(RESET_SECURITY_FILTERS_ONLY()) }}  />
				</PermissionWrapper>
			</NavTabs>
			<Routes>
				<Route
					path={'security-roles'}
					element={
						<PermissionWrapper subModuleID={subModuleList.Roles}>
							<SecurityRoles />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'web-users'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Web User']}>
							<WebUsers />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'web-users-requests'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Web Users Request']}>
							<WebUserRequest />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'password-policy'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Password Policy']}>
							<PasswordPolicy />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'dashboard-customization'}
					element={
						<PermissionWrapper subModuleID={subModuleList.Roles}>
							<DashboardCustomization />
						</PermissionWrapper>
					}
				/>
				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default SecurityIndex;
