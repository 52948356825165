import React, { Fragment } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

type Props = {
    name: string;
    control: Control<FieldValues, any>;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, onFormChange: (r: any) => void) => void;
    onInput?: (e:any) => void;
    className?: string;
    type?: string;
    min?: number;
    max?: number;
    placeholder?:string
    disabled?:boolean
};

const FormInput = (props: Props) => {
    return (
        <Fragment>
            <Controller
                name={props.name}
                control={props.control}
                render={({ field: { value, onChange, onBlur } }) => (
                    <>
                        <input
                            type={props.type ?? 'text'}
                            className={props.className ?? 'form-control'}
                            id=''
                            disabled={props.disabled}
                            min={props.min}
                            max={props.max}
                            value={value ?? ''}
                            onBlur={onBlur}
                            placeholder={props.placeholder}
                            onInput={(e)=>{
                                if(props?.onInput){
                                    props?.onInput(e)
                                }
                            }}
                            onChange={(e) => {
                                let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
                                if (props?.onChange) {
                                    props?.onChange(e, onChange);
                                } else {
                                    onChange(v);
                                }
                            }}
                        />
                    </>
                )}
            />
        </Fragment>
    );
};

export default FormInput;
