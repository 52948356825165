import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_parking_policy, parking_policy_action } from '../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import { TimePicker } from 'antd';
import moment from 'moment';
import FormInput from '../../../../../common/components/FormInput';
import FormTextarea from '../../../../../common/components/FormTextarea';
import ActionConfirmBox from '../../../../../common/components/ActionConfirmBox';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

let Weeks = [
	{ label: 'Monday', value: 1 },
	{ label: 'Tuesday', value: 2 },
	{ label: 'Wednesday', value: 3 },
	{ label: 'Thursday', value: 4 },
	{ label: 'Friday', value: 5 },
	{ label: 'Saturday', value: 6 },
	{ label: 'Sunday', value: 7 },
];
const weekDay = yup.object().shape({
	policyName: yup.string(),
	dayID     : yup.number(),
	fromTime  : yup.string().nullable().when('isChecked', { is : true, then : yup.string().nullable().required() }),
	// .test(
	// 	'to_time_test',
	// 	'From time must be before end time',
	// 	function(value) {
	// 		const { toTime }:any = this.parent;
	// 	  console.log('FROMTIME',toTime)
	// 	  return isSameOrBefore(toTime,value);
	// 	}
	//   )
	//   .required(),
	toTime    : yup.string().nullable().when('isChecked', { is : true, then : yup.string().nullable().test(
		"from_time_test",
		"End time must be after from time",
		function(value) {
		  const { fromTime } = this.parent;
		  console.log('TOTIME',fromTime)
		  return isSameOrBefore(value, fromTime);
		}
	  ).required() }),
	graceIn   : yup.number(),
	graceOut  : yup.number(),
	isChecked : yup.boolean(),
})
const schema = yup.object().shape({
	policyName           : yup.string().required(),
	pDescriptions        : yup.string(),
	allowGraceTime       : yup.boolean(),
	maxParkingOccupancy  : yup.number().min(1),
	// isDefault            : yup.boolean(),
	parkingPolicySchedule: yup.array().of(weekDay)
							// .compact(v => !v.isChecked)
							.test('atleast_one_is_checked', 'Minimum one day is required',
								function(value){
									let v:any = value?.filter(el => el?.isChecked)?.length
									console.log('TESTFN',value,'V=>',v)
									return v > 0 ? true : false
								}
							)
							.required("Minimum one day is required")
							.min(1, "Minimum one day is required"),
});

const isSameOrBefore = (val:any,end:any) =>{
	let d1 = moment(val,'HH:mm:ss',true).isValid() ? new Date(`01-01-2001 ${val}`) : moment(new Date(val)).format('HH:mm:ss')
	let d2 = moment(end,'HH:mm:ss',true).isValid() ? new Date(`01-01-2001 ${end}`) : moment(new Date(end)).format('HH:mm:ss')
	//console.log('DIFFERENCES',moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'minute'),
	//  moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'hours'),d1,d2)
	return moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'minute') > 0
}

const AddModal = (props: Props) => {
	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmData, setConfirmData] = useState<any>()
	
	const [loading, setLoading] = useState(false)
	const [graceCheck, setGraceCheck] = useState(true)
	const [schdObj, setSchdObj] = useState(
		[
			{ v : true, id : 1}, { v : true, id : 2}, { v : true, id : 3}, { v : true, id : 4},
			{ v : true, id : 5}, { v : true, id : 6}, { v : true, id : 7}, 
		]
	)

	const cancelConfirm = () =>{ setConfirmModal(false); setLoading(false); 
		setGraceCheck(true)
		setSchdObj([
			{ v : true, id : 1}, { v : true, id : 2}, { v : true, id : 3}, { v : true, id : 4},
			{ v : true, id : 5}, { v : true, id : 6}, { v : true, id : 7}, 
		])
	}

	const { register, handleSubmit, formState, 
		setValue, 
		clearErrors,
		reset, 
		control,
		getValues,  getFieldState, 
		watch, trigger 
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	

	let dispatch = useAppDispatch();
	const navigate = useNavigate();

	const PARKING_POLICY_DATA = useAppSelector((state) => state?.policy)

	const extractDataForPolicySchd = (d:any) =>{
		let schd = []
		for(let i=0; i<7; ++i){
			let v = d?.find((el:any) => el?.dayID===i+1)
			if(v){
				schd.push({...v,
					isChecked: true,  
					fromTime : v?.fromTime && moment(v?.fromTime,'HH:mm:ss').isValid() ? v?.fromTime : null,
					toTime : v?.toTime && moment(v?.toTime,'HH:mm:ss').isValid() ? v?.toTime : null,
					graceIn : v?.graceIn ? v?.graceIn : 0, graceOut : v?.graceOut ? v?.graceOut : 0
				})
			}else{
				schd.push({
					policyName : '',
					dayID : i+1,
					fromTime : null, toTime : null, graceIn : 0, graceOut : 0, isChecked : false
				})
			}
		}
		console.log('SCHD',schd)
		return schd
	}

	useEffect(() => {
		if (props.Data) {

			clearErrors()
			reset({
				policyName           : props?.Data?.policyName,
				pDescriptions        : props?.Data?.pDescriptions,
				allowGraceTime       : props?.Data?.allowGraceTime,
				maxParkingOccupancy  : props?.Data?.maxParkingOccupancy,
				// isDefault            : props?.Data?.isDefault,
				policyID           	 : props?.Data?.policyID,
				parkingPolicySchedule: extractDataForPolicySchd(props?.Data?.parkingPolicySchedule),
			});
			setGraceCheck(props?.Data?.allowGraceTime)
			let obj = props?.Data?.parkingPolicySchedule
				? props?.Data?.parkingPolicySchedule.map((item: any) => {
						return { v : true, id : item.dayID }
					  })
					:
					[
						{ v : false, id : 1}, { v : false, id : 2}, { v : false, id : 3}, { v : false, id : 4},
						{ v : false, id : 5}, { v : false, id : 6}, { v : false, id : 7}, 
					]
			if(obj.length < 7){
				let objTemp = obj.map((x:any)=>x.id)

				for(let i=0 ; i<7; ++i){
					if(!objTemp?.includes(i+1))
						obj.push({ f : '', t : '', v : false, id : i+1})
				}
			}
			console.log('OBJ',obj)
			setSchdObj(Object.assign([],obj?.sort((a:any, b:any) => a?.id - b?.id)))
		}
	}, [props.Data, reset]);
	console.log('formState error', formState?.errors);

	const confirmApprove = () =>{
		setConfirmModal(false)
		let parkingPolicySchedule = confirmData.parkingPolicySchedule.filter((item: any) => item?.isChecked === true);
		let convertedTIme:any = [];
		for (let i = 0; i < parkingPolicySchedule.length; i++) {
			convertedTIme.push({
				dayID   : parkingPolicySchedule[i].dayID,
				graceIn : parkingPolicySchedule[i].graceIn ? parkingPolicySchedule[i].graceIn  : 0,
				graceOut: parkingPolicySchedule[i].graceOut ? parkingPolicySchedule[i].graceOut : 0,
				fromTime: parkingPolicySchedule[i].fromTime ? dayjs(parkingPolicySchedule[i].fromTime, 'HH:mm').format('HH:mm:ss'): null,
				toTime  : parkingPolicySchedule[i].toTime ? dayjs(parkingPolicySchedule[i].toTime, 'HH:mm').format('HH:mm:ss')    : null,
			});
		}

		let postData : any= {
			policyName           : confirmData?.policyName,
			pDescriptions        : confirmData?.pDescriptions,
			allowGraceTime       : confirmData?.allowGraceTime,
			maxParkingOccupancy  : confirmData?.maxParkingOccupancy,
			// isDefault            : data?.isDefault,
			parkingPolicySchedule: convertedTIme,


		};
			if(props?.Data?.policyID){
				postData["policyID"] = props?.Data?.policyID
			}
		const Success = (res: any) => {
			SuccesToast(res?.data);
			props.handleClose();
			setConfirmModal(false)
			setLoading(false)
			
			setConfirmData(null)
			fetch_parking_policy(navigate, dispatch, {
				'filters': {
					'query': PARKING_POLICY_DATA?.PARKING_POLICY_FILTER?.query || '',

				},
				'pagination': {
					'pageNo': PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.pageNo,
					'limit': PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.limit,
				},
			}, undefined)
			setTimeout(() => {
				reset()
				clearErrors()
				cancelConfirm()	
			}, 200);
		};
		const Failed = (err: any) => {
			setLoading(false)
			setConfirmModal(false)
			setConfirmData(null)
			FailedToast(err?.response)		;
		};
		if(props.Data){
			parking_policy_action('put', postData, Success, Failed);
		}else{
			parking_policy_action('post', postData, Success, Failed);
		}
	}


	const onSubmitHandler = (data: any) => {
		//console.log('CURRENT DATA', data);
		//console.log('formState error', formState?.errors);
		setLoading(true)
		setConfirmData(data)
		setConfirmModal(true)
	};

	console.log('ACTIONS',formState,'\nErr',formState.errors,'\nval',watch('parkingPolicySchedule'),getFieldState('parkingPolicySchedule'))
	const getDisabledTime = (curr:any, from:any) => {
		//console.log('FROM',from,'CUR',curr)
		return {
			disabledHours: () => getDisabledHours(from),
			disabledMinutes: () => getDisabledMinutes(curr.get('hour'),from)
		}
	}

	const getDisabledHours = (from:any) => {
		var hours = [];
		let h = moment(from,'HH:mm:ss').get('hour') > 12 ? moment(from,'HH:mm:ss').get('hour')-12 : moment(from,'HH:mm:ss').get('hour')
		for(var i =0; i < h; i++){
			hours.push(i);
		}
		//console.log('HRS',hours,'HH=>',h,'MOMHRS',moment(from,'HH:mm:ss').get('hour'))
		return hours;
	}
	
	const getDisabledMinutes = (selectedHour:any, from:any) => {
		var minutes= [];
		// let h = moment(from,'HH:mm:ss').get('hour') > 12 ? moment(from,'HH:mm:ss').get('hour')-12 : moment(from,'HH:mm:ss').get('hour')
		if (selectedHour === moment(from,'HH:mm:ss').get('hour')){
			for(var i =0; i < moment(from,'HH:mm:ss').get('minute'); i++){
				minutes.push(i);
			}
		}
		return minutes;
	}
	return (
		<>
		<ModalLayout open={props.open} handleClose={()=>{ reset()
			clearErrors()
			cancelConfirm();
			 props.handleClose();}} title={`${ props.Data ?
				(props?.Data?.isAssigned || props?.Data?.isSystem) ? '' :
			 'Edit' : 'Add New' } Policy`} subtitle={props?.Data ? props?.Data?.isAssigned ? 'Assigned Policy' : props?.Data?.isSystem ? 'System Policy' :'Policies':'Policies'} big>
			<form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
				<div className="modal-body-content row p-10" style={{ maxHeight:"60vh"}}>

				{	props.Data &&
					<div className='col-3'>
					<div className='form-group inline-input full-label'>
						<label>Policy ID</label>
						{/* <input className='form-control grey-input' disabled  {...register('policyID')}/> */}

						<FormInput type='text' name="policyID" className='form-control grey-input' control={control} disabled={true} />



					</div>
				</div>}
				<div className='col-9'>
					<div className='form-group inline-input full-label'>
						<label>Policy Name</label>
						{/* <input className='form-control grey-input' {...register('policyName')} disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}/> */}
						<FormInput type='text' name="policyName" className='form-control grey-input' control={control} 
						disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
						/>
					
						<ErrorComponent errors={formState.errors} name={`policyName`} title={'Policy Name'} />
					</div>
				</div>
				<div className='col-3'>
					<div className='form-group inline-input full-label'>
						<label>Max Parking Occupancy</label>
						{/* <input className='form-control grey-input' disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)} {...register('maxParkingOccupancy', { pattern : /^[1-9]\d*$/ })}/> */}
						
						<FormInput type='text' name="maxParkingOccupancy" className='form-control grey-input' control={control} 
					      onChange={(e, formOnChange) => {
							let v = e.target.value.replace(/[^0-9+]/g, '');
							if(v!=='0')
								formOnChange(v)
						  }}
					
						disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
						/>
						<ErrorComponent errors={formState.errors} name={`maxParkingOccupancy`} title={'Max Parking Occupancy'} customMessage={'Maximum Parking Occupancy must be a number'}/>
					</div>
				</div>
				<div className='col-12'>
					<div className='form-group inline-input full-label'>
						<label>Policy Description</label>
						{/* <textarea className='form-control grey-input' disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)} {...register('pDescriptions')}></textarea> */}
					
						<FormTextarea name="pDescriptions"  className='form-control grey-input' control={control} disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}  />
						<ErrorComponent errors={formState.errors} name={`pDescriptions`} title={'Description'}/>
					</div>
				</div>
				{/* <div className='col-12 inline-input'>
					<div className='checkbox'>
						<input type='checkbox' {...register('isDefault')} />
						<label>
							<span>Default</span>
						</label>
					</div>
				</div> */}
				
				<div className='col-12 inline-input'>
					<div className='checkbox'>
						<input type='checkbox'  disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
						defaultChecked={props.Data ? false : true}
						onClick={()=>{ 
							setGraceCheck(!graceCheck)
						if(graceCheck){ 
							clearErrors(Weeks.map((_, k) => { return ([ `parkingPolicySchedule[${k}].graceIn`, `parkingPolicySchedule[${k}].graceOut`])}).flat(1)) 
						}else{
							trigger(Weeks.map((_, k) => { return ([ `parkingPolicySchedule[${k}].graceIn`, `parkingPolicySchedule[${k}].graceOut`])}).flat(1))
						} }}
						{...register('allowGraceTime')} />
						<label>
							<span>Allow Grace Time</span>
						</label>
					</div>
				</div>
				<div className='reponsive-table timing-table'>
					<div className='modal-header'>
						<h3>Allowed Schedule</h3>
						<ErrorComponent errors={formState.errors} name={`parkingPolicySchedule`} title={'Parking Schedule'}/>
					</div>
					
					<table className='style-table mobile-responsive permission-table'>
						<tbody>
							<tr>
								<td colSpan={6}>
									<table className='grey-table'>
										<thead>
											<tr>
												<th></th>
												<th>From Time</th>
												<th>To Time</th>
												<th>Grace In (Minutes)</th>
												<th>Grace Out (Minutes)</th>
											</tr>
										</thead>
										<tbody>
											{Weeks.map((item, key:number) => {
												console.log(watch(`parkingPolicySchedule[${key}]`))
												return (
													<>
														<tr key={key}>														
														<td className='col-2'>
															<div className='checkbox'>
																<input
																	type='checkbox' disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
																	defaultChecked={props.Data ? false : true}
																	{...register(`parkingPolicySchedule[${key}].isChecked`)}
																	onClick={()=>{
																		let obj:any = schdObj;
																		
																		if(schdObj.find((el:any)=> el.id===(key+1))?.v){
																			clearErrors(`parkingPolicySchedule[${key}].fromTime`)
																			clearErrors(`parkingPolicySchedule[${key}].toTime`)
																		}else{ 
																			trigger(`parkingPolicySchedule[${key}].fromTime`)
																			trigger(`parkingPolicySchedule[${key}].toTime`)
																		}
																		obj.find((el:any)=> el.id===(key+1)).v = !schdObj.find((el:any)=> el.id===(key+1))?.v  
																		setSchdObj(Object.assign([], obj)) 

																	}}
																/>
																<input disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
																	className='none'
																	type='number'
																	{...register(`parkingPolicySchedule[${key}].dayID`)}
																	defaultValue={item.value}
																/>
																<label>
																	<span>{item?.label}</span>
																</label>
															</div>
														</td>
														<td data-label='From Time' className={`col-2`}>
															
															<Controller
																name={`parkingPolicySchedule[${key}].fromTime`}
																control={control}
																render={(
																	{ 
																		field: { value, onChange, onBlur },
																		fieldState : { error }
																	}) => (
																<TimePicker
																		getPopupContainer={trigger => trigger.parentElement as HTMLDivElement}
																		className={`form-control grey-input ${!schdObj?.find(el => el.id===(key+1))?.v ? 'disableAndOpacity2' : ''}`}
																		onChange={(e)=>{
																			if(isSameOrBefore(watch(`parkingPolicySchedule[${key}].toTime`), moment(e).format('HH:mm:ss'))){
																				setValue(`parkingPolicySchedule[${key}].toTime`,0)
																			}
																			
																			onChange(moment(e).isValid() ? moment(e).format('HH:mm:ss') : null)
																		}}
																		allowClear
																		disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
																		value={value ? moment(value,"HH:mm:ss"): null}
																		format={'hh:mm a'}
																	/>								
																
																)}
															/>
															
															{/* <input
																type='time'
																className={`form-control grey-input ${!schdObj?.find(el => el.id===(key+1))?.v ? 'disableAndOpacity2' : ''}`}
																{...register(`parkingPolicySchedule[${key}].fromTime`)}
																onChange={(e)=>{
																	if(isSameOrBefore(watch(`parkingPolicySchedule[${key}].toTime`), watch(`parkingPolicySchedule[${key}].fromTime`))){
																		setValue(`parkingPolicySchedule[${key}].toTime`,0)
																	}
																}}
																placeholder='00:00'
																
															/> */}
															
															<ErrorComponent errors={formState.errors}
															 name={`parkingPolicySchedule[${key}].fromTime`} title={'Parking Schedule From Time'}/>
														</td>
														<td data-label='To Time' className={`col-2`}>
															
															<Controller
																name={`parkingPolicySchedule[${key}].toTime`}
																control={control}
																render={({ field: { value, onChange, onBlur } }) => (
																<TimePicker
																		getPopupContainer={trigger => trigger.parentElement as HTMLDivElement}
																		className={`form-control grey-input ${!schdObj?.find(el => el.id===(key+1))?.v ? 'disableAndOpacity2' : ''}`}
																		onChange={(e)=>{
																			onChange(moment(e).isValid() ? moment(e).format('HH:mm:ss') : null)
																		}}
																		allowClear
																		disabledTime={(date)=>getDisabledTime(date, watch(`parkingPolicySchedule[${key}].fromTime`))}
																		disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}

																		value={value ? moment(value,"HH:mm:ss"): null}
																		format={'hh:mm a'}
																	/>								
																
																)}
															/>
															{/* <input
																type='time'
																className={`form-control grey-input ${!schdObj?.find(el => el.id===(key+1))?.v ? 'disableAndOpacity2' : ''}`}
																{...register(`parkingPolicySchedule[${key}].toTime`)}
																placeholder='00:00'
															/> */}
															<ErrorComponent errors={formState.errors}
															 name={`parkingPolicySchedule[${key}].toTime`} title={'Parking Schedule To Time'}/>
														</td>
														<td data-label='Grace In (minutes)' className={`col-2`}>
															<input
																type='text' disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
																defaultValue={0}
																className={`form-control grey-input  ${!graceCheck || !schdObj?.find(el => el.id===(key+1))?.v ? 'disableAndOpacity2' : ''}`}
																{...register(`parkingPolicySchedule[${key}].graceIn`)}
																placeholder='00'
															/>
															<ErrorComponent errors={formState.errors} customMessage={'Enter Number'}
															 name={`parkingPolicySchedule[${key}].graceIn`} title={'Parking Schedule Grace In'}/>
														</td>
														<td data-label='Grace Out (minutes)' className={`col-2`}>
															<input
																type='text' 
																disabled={props?.Data && (props?.Data?.isAssigned || props?.Data?.isSystem)}
																defaultValue={0}
																className={`form-control grey-input  ${!graceCheck || !schdObj?.find(el => el.id===(key+1))?.v ? 'disableAndOpacity2' : ''}`}
																{...register(`parkingPolicySchedule[${key}].graceOut`)}
																placeholder='00'
															/>
															<ErrorComponent errors={formState.errors} customMessage={'Enter Number'}
															 name={`parkingPolicySchedule[${key}].graceOut`} title={'Parking Schedule Grace Out'}/>
														</td>
														
													</tr>
														<ErrorComponent errors={formState.errors} name={`parkingPolicySchedule[${key}].isChecked`} title={'Parking Schedule'}/>
													</>
													
												);
											})}
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				</div>
				<div className='col-12'>
					<div className='modal-footer'>
						{
							props?.Data?.isAssigned || props?.Data?.isSystem ? null 
							:
							<button type='submit' className='btn btn-secondary' disabled={loading}>
								<ButtonLoader loading={loading} text='Submit' />
							</button>
						}
						
						<button type='reset' onClick={()=>{ cancelConfirm(); reset(); clearErrors(); props.handleClose()}} className='btn btn-outline-blue close-modal-button'>
							Close
						</button>
					</div>
				</div>

			</form>
		</ModalLayout>
		{confirmModal &&
			<ActionConfirmBox
				description={`Are you sure provided details are correct ?`}
				handleClose={cancelConfirm}
				open={true}
				ConfirmFx={confirmApprove}
			/>
		}
		</>
	);
};

export default AddModal;
