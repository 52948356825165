import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import BlacklistedVehicles from './screens/blacklist_vehicles/blacklistedVehicles';
import ParkingViolations from './screens/parking_violations/parkingViolations';
import ReportViolation from './screens/report_violation/reportViolation';
import { RESET_STATE } from './store/violationSlice';

type Props = {};

const ViolationIndex = (props: Props) => {
	const dispatch = useDispatch()
	useEffect(() => {
		  return () => {
			  dispatch(RESET_STATE())
		  }
	  }, [dispatch])
	
	const redirectedPath = useRedirect([
		{
			path: 'parking-violations',
			submoduleID: subModuleList['Parking Violations'],
		},
		{
			path: 'blacklisted-vehicles',
			submoduleID: subModuleList['Blacklisted Vehicles'],
		},
		{
			path: 'report-violations',
			submoduleID: subModuleList['Report Violation'],
		},
	]);

	return (
		<div className='navtabs__container'>
			<NavTabs>
				<PermissionWrapper subModuleID={subModuleList['Parking Violations']}>
					<LinkTab label='Parking Violations' href='parking-violations' />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Blacklisted Vehicles']}>
					<LinkTab label='Black Listed Vehicles' href='blacklisted-vehicles' />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Report Violation']}>
					<LinkTab label='Report Violation' href='report-violations' />
				</PermissionWrapper>
			</NavTabs>
			<Routes>
				<Route
					path={'parking-violations'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Parking Violations']}>
							<ParkingViolations />
						</PermissionWrapper>
					}
				/>

				<Route
					path={'blacklisted-vehicles'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Blacklisted Vehicles']}>
							<BlacklistedVehicles />
						</PermissionWrapper>
					}
				/>

				<Route
					path={'report-violations'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Report Violation']}>
							<ReportViolation />
						</PermissionWrapper>
					}
				/>

				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default ViolationIndex;
