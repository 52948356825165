import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';


type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	USER_LIST_DATA: [],
	USER_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	USER_LOADER: false,
	USER_FILTER: { query: "" } as FilterObject,
	USER_ERROR: '',
};

export const USERSLICE = createSlice({
	name: 'USER',
	initialState: initialState,
	reducers: {
		UPDATE_USERS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.USER_LIST_DATA = data;
			state.USER_PAGINATION = pagination;
			state.USER_FILTER = filters;
			state.USER_LOADER = false;
			state.USER_ERROR = '';
		},

		UPDATE_USERS_FILTER: (state, action) => {
			const { filters } = action.payload;
			let pagination = { pageNo: 1, limit: state.USER_PAGINATION.limit, totalCount: 0 }
			state.USER_PAGINATION = pagination
			state.USER_FILTER = filters;
		},
		UPDATE_USERS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.USER_LOADER = loader;
		},

		UPDATE_USERS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.USER_ERROR = error;
			state.USER_LOADER = false;

		},

		RESET_STATE: () => {
			return {...initialState}
		},





	},
});

export const { UPDATE_USERS_DATA, UPDATE_USERS_LOADER, UPDATE_USERS_ERROR, UPDATE_USERS_FILTER ,RESET_STATE } = USERSLICE.actions;
export const USERData = (state: RootState) => state;
export default USERSLICE.reducer;
