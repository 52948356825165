import { useLocation } from 'react-router-dom';

export default function useCurrentPath() {
	const { pathname } = useLocation();
	let current_tab: any = pathname.split('/');
	if (current_tab.length > 0) {
		current_tab = '/' + current_tab[1];
	} else {
		current_tab = '/';
	}

	return { current_tab };
}
