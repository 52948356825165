import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import SimpleBox from '../../../../common/components/graph_components/SimpleBox';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_moreThan_24hr, fetch_no_of_visits, fetch_parking_availability } from '../../store/action';
import { DatePicker } from 'antd';
import BoxWithBgClr from './../../../../common/components/graph_components/BoxWithBgClr';
import moment from 'moment';

type Props = {}

const AvgDailyVisiit = (props: Props) => {
  const childRef: any = useRef(null);
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	// const [date, setdate] = useState<string>(dayjs().subtract(1,"days").format('YYYY-MM-DD'));
	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);

	const [data, setdata] = useState<any>();

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,

			
		};

		handleClose();
	
		fetch_no_of_visits(postData, signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ?? [];
				setdata(data);
			}
		});

		let timer = setInterval(() => {
			fetch_no_of_visits(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					setdata(data);
				}
			});
		}, 120000);

		return () => {
			controller.abort();
			clearInterval(timer);
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, date]);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};
  return (
    <BoxWithBgClr title='Average Number of visits per day'   value={data?.totalCount}color="bg-leafcyan"/>
  )
}

export default AvgDailyVisiit