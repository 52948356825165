/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBox from '../../../../../common/components/checkBox';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { delete_parking_policy, fetch_parking_policy, parking_policy_detail } from '../../../store/actions';
import AddModal from './addModal';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';

type Props = {};

const ListTable = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const fetchIdRef = useRef(0);

	const [editModal, seteditModal] = useState(false);
	const [deleteModal, setdeleteModal] = useState(false);
	const [dataDetail, setdataDetail] = useState();
	const [ID, setID] = useState();
	const [loader, setLoader]	= useState(false)


	const editModalOpen = () => seteditModal(true);
	const editModalClose = () => seteditModal(false);
	const delModalClose = () => setdeleteModal(false);

	const PARKING_POLICY_DATA = useAppSelector((state) => state?.policy);

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'policyID', orderByKey: 'policyID' },
			{ Header: 'POLICY NAME', accessor: 'policyName', orderByKey: 'policyName' },
			{ Header: 'CREATED BY', accessor: 'createdUser', orderByKey: 'FullName' },
			{ Header: 'CREATED ON', accessor: 'createdOn', DateFormat: true, FormatType: 'DD-MMM-YYYY hh:mm A', orderByKey: 'createdOn' },
			{ Header: 'Number OF ASSIGNED ZONES', accessor: 'zoneCount', orderByKey: 'zoneCount',
			Cell: ({ row }: any) => {
				return <div style={{ textAlign:'center', width:'100%' }}>{row?.original?.isSystem ? '-' : row?.original?.zoneCount}</div>
			} 
		},
			{
				Header: 'DEFAULT',
				accessor: 'isDefault',
				Cell: ({ row }: any) => {
					if(row?.original?.isSystem)
						return <StatusChipComponent chipType='pending' text='System Default' />
					else 
						return null
				},
			},
			{ Header: 'Policy Status', accessor: 'isAssigned',
			Cell: ({ row }: any) => {
				if(!row?.original?.isSystem && row?.original?.isAssigned)
						return (
							<div className='button-group' style={{ placeItems:'end', justifyContent: 'space-between' }}>
								<StatusChipComponent text='Parking Active' />
							</div>
							
						)
					else
						return null
				},
				
			},
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
				if(!row?.original?.isSystem)
					if(row?.original?.zoneCount > 0)
						return (
								<PermissionWrapper subModuleID={subModuleList['Parking Policies']} type='isView'>
									<div className='button-group'>
									<a onClick={() => handleOpen(row?.values?.policyID)} className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-eye'></i>
								</a>
									</div>
								</PermissionWrapper>
							
						)
					else
						return (
							<Fragment>
								<div className='button-group'>
									<PermissionWrapper subModuleID={subModuleList['Parking Policies']} type='isEdit'>
										<a onClick={() => handleOpen(row?.values?.policyID)} className='btn btn-sqaure btn-white modal-button'>
											<i className='fa-solid fa-pen'></i>
										</a>
									</PermissionWrapper>
									<PermissionWrapper subModuleID={subModuleList['Parking Policies']} type='isDelete'>
										<a
											className='btn btn-sqaure btn-red modal-button'
											onClick={() => handleDeletModalOpen(row?.values?.policyID)}>
											<i className='fa-solid fa-trash'></i>
										</a>
									</PermissionWrapper>
								
								</div>
							</Fragment>
						);
				else 
				return (
					<PermissionWrapper subModuleID={subModuleList['Parking Policies']} type='isView'>
						<div className='button-group'>
						<a onClick={() => handleOpen(row?.values?.policyID)} className='btn btn-sqaure btn-white modal-button'>
							<i className='fa-solid fa-eye'></i>
						</a>
					</div>
					</PermissionWrapper>
					
					
				)
				},
				show: true,
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string };
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parking_policy(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	const handleOpen = (id: number) => {
		const sucess = (res: any) => {
			setdataDetail(res?.response?.data);
			editModalOpen();
		};
		const failed = (err: any) => {};

		parking_policy_detail(id, sucess, failed);
	};

	const handleDeletModalOpen = (id: any) => {
		setID(id);
		setdeleteModal(true);
	};
	const handleDelete = (id: any) => {
		setLoader(true)
		const sucess = (res: any) => {
			setLoader(false)
			console.log(res);
			SuccesToast(res?.response?.data);
			setdeleteModal(false);

			fetch_parking_policy(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 15,
				},
			});
		};
		const failed = (err: any) => {
			setLoader(false)
			FailedToast(err?.response)		;
			setdeleteModal(false);
		};

		delete_parking_policy(id, sucess, failed);
	};

	return (
		<>
			<CommonTable
				data={PARKING_POLICY_DATA?.PARKING_POLICY_LIST ?? []}
				columns={columns}
				pageCount={PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.totalCount ?? 0}
				fetchData={fetchData}
				limit={PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.limit ?? 1}
				page_no={PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.pageNo ?? 1}
				filters={PARKING_POLICY_DATA?.PARKING_POLICY_FILTER}
				loading={PARKING_POLICY_DATA?.PARKING_POLICY_LOADER}
			/>
			{dataDetail && <AddModal open={editModal} handleClose={editModalClose} Data={dataDetail} />}
			<CustomConfirm
				open={deleteModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						handleDelete(ID);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
		</>
	);
};

export default ListTable;
