/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { commonSearchQuery, FilterProps } from '../../../../../config/filterSearch';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_blacklisted_list } from '../../../store/action';
import AddModal from './addModal';

import { DatePicker } from "antd"
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../../config/subModuleList';
import useKeyboard from '../../../../../utils/hooks/useKeyboard';
const { RangePicker } = DatePicker;

type Props = {};

const FilterBar = (props: Props) => {
	const [addModal, setaddModal] = useState(false);

	const [plateSearch, setPlateSearch] = useState('')
	const [dateRange, setDateRange] = useState<any>([])
	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const PARKING_VIOLATION = useAppSelector((state) => state?.violations)

	const filterSearch = () => {

		let QueryInput: FilterProps[] = [
			{
				field: 'CAST (BlackListedAddedOn AS date)',
				value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>='
			},
			{
				field: 'CAST (BlackListedAddedOn AS date)',
				value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<='
			},
			{
				field: 'PlateNo',
				value: plateSearch !== '' ? plateSearch : null,
				operator: 'contains'
			}
		]

		fetch_blacklisted_list(navigate, dispatch, {
			'filters': {
				'query': commonSearchQuery(QueryInput, 'AND'),
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.BLACKLISTED_PAGINATION.limit,
			},
		})
	}

	const clearSearch = () => {
		fetch_blacklisted_list(navigate, dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.BLACKLISTED_PAGINATION.limit,
			},
		})
		setDateRange([])
		setPlateSearch('')
	}

	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	return (
		<div className='search-filter-row' ref={ref}>

			<div className='float-btn text-right'>
				<PermissionWrapper subModuleID={subModuleList['Blacklisted Vehicles']} type='isCreate'>

 

					<a onClick={addModalOpen} className='btn btn-secondary modal-button'>
						Add To Blacklist
					</a>
				</PermissionWrapper>

			</div>
			<div className='search-filter-row'>
				<ul>
					<li>
						<a className='active pointerDefaultSet'>
							<i className='fa-solid fa-magnifying-glass'></i> Quick Search
						</a>
					</li>
					{PARKING_VIOLATION.BLACKLISTED_FILTER?.query !== '' &&
						<li>
							<a onClick={clearSearch}>
								<i className='fa-solid fa-times'></i>Clear All Filters
							</a>
						</li>
					}
				</ul>

				<div className='filter-block' id='quick-search'>
					<div className='row'>
						<div className='col'>
							<div className='form-group'>
								<div className="pos-rel">
									<i className='fa-solid fa-magnifying-glass'></i>

									<input type='text' name='' placeholder='Plate Number' className='form-control' value={plateSearch} onChange={(e) => {
										let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
										setPlateSearch(v)
									}} />

								</div>
							</div>
						</div>
						<div className='col'>
							<div className={`form-group`}>

								<RangePicker
									picker="date"
									allowClear
									allowEmpty={[true, true]}
									className='form-control'
									placeholder={['Date From', ' Date To']}
									separator
									value={dateRange}
									format={'DD-MMM-YYYY'}
									onChange={(dates: any) => {
										setDateRange(dates)
									}}
									inputReadOnly
								/>
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<button onClick={() => { filterSearch() }} className='form-control blue-btn'>Search</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddModal open={addModal} handleClose={addModalClose} />
		</div>
	);
};

export default FilterBar;
