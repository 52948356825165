import React from 'react';
import './style.css';
import { toast } from 'react-toastify';
import violations from '../../../assets/images/icons/violations.svg';


export const showToast = (data: any) => {
	toast(customMsg(data), {
		position: toast.POSITION.TOP_CENTER,
		className: "toast-notification",
		autoClose: 5000,
		// delay: 5000,

		hideProgressBar: true
	});
};

const customMsg = (data: any) => (
	<div className='dflex justify-space-between' style={{ alignItems: 'center', gap: "10px" }}>
		<div className='icon'>
			<img src={violations} alt='icon' />
		</div>
		<div className='dflex flex-col '>
			<strong>{data?.title}</strong>
			<p style={{ lineHeight: '1.5' }}>{data?.nMessages}</p>
		</div>
	</div>
);


export const customMsgError = (data: any) => (
	<>
		<div className='dflex flex-col '>
			<strong style={{ fontSize: "14px" }}>{data?.appData || "Something went wrong"}</strong>
			<p style={{ lineHeight: '1.5', fontSize: "14px" }}>
				{data?.devData || "An unexpected error has occured"}
			</p>
		</div>
	</>
);

