import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import AvilabilityCheck from './screens/avilability_check/avilabilityCheck';
import ParkingHistory from './screens/parking_history/parkingHistory';
import RealtimeMonitor from './screens/realtime_monitor/realtimeMonitor';
import Transaction from './screens/transactions/transaction';
import Unrecognized from './screens/unrecognized/Unrecognized'
import { RESET_STATE } from './store/realtimeSlice';

type Props = {};

const RealtimeMonitorIndex = (props: Props) => {
	const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
	const redirectedPath = useRedirect([
		{
			path: 'map',
			submoduleID: subModuleList['Real Time Monitoring'],
		},
		{
			path: 'availability-check',
			submoduleID: subModuleList['Real Time Monitoring'],
		},
		{
			path: 'parking-history',
			submoduleID: subModuleList['Parking History'],
		},
		{
			path: 'transactions',
			submoduleID: subModuleList.Transactions,
		},
		{
			path: 'unknown-vehicles',
			submoduleID: subModuleList.Transactions,
		},
	]);
	return (
		<div className='navtabs__container'>
			<NavTabs>
				<PermissionWrapper subModuleID={subModuleList['Real Time Monitoring']}>
					<LinkTab label='Real Time Monitoring' href='map' />
				</PermissionWrapper>

				<PermissionWrapper subModuleID={subModuleList['Real Time Monitoring']}>
					<LinkTab label='Availability Check' href='availability-check' />
				</PermissionWrapper>

				<PermissionWrapper subModuleID={subModuleList['Parking History']}>
					<LinkTab label='Parking History' href='parking-history' />
				</PermissionWrapper>

				<PermissionWrapper subModuleID={subModuleList.Transactions}>
					<LinkTab label='Transactions' href='transactions' />
				</PermissionWrapper>

				<PermissionWrapper subModuleID={subModuleList.Transactions}>
					<LinkTab label='Unregistered Vehicles' href='unknown-vehicles' />
				</PermissionWrapper>
			</NavTabs>
			<Routes>
				<Route
					path={'map'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Real Time Monitoring']}>
							<RealtimeMonitor />
						</PermissionWrapper>
					}
				/>

				<Route
					path={'availability-check'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Real Time Monitoring']}>
							<AvilabilityCheck />
						</PermissionWrapper>
					}
				/>

				<Route
					path={'parking-history'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Parking History']}>
							<ParkingHistory />
						</PermissionWrapper>
					}
				/>

				<Route
					path={'transactions'}
					element={
						<PermissionWrapper subModuleID={subModuleList.Transactions}>
							<Transaction />
						</PermissionWrapper>
					}
				/>

				<Route
					path={'unknown-vehicles'}
					element={
						<PermissionWrapper subModuleID={subModuleList.Transactions}>
							<Unrecognized />
						</PermissionWrapper>
					}
				/>

				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default RealtimeMonitorIndex;
