import React, { useRef, useState } from 'react'
import FilterBar from './components/FilterBoxBar'
import Reports from './components/reportTable'

type Props = {}

const UserReports = (props:Props) => {
  const [tabVal, setTabVal] = useState(1)
  const [resetFilter, setResetFilter] = useState(false)
  return (
    <div className="main-body-content license">
      <FilterBar tabVal={tabVal} resetFilter={resetFilter}  setResetFilter={setResetFilter}/>
      <Reports tabVal={tabVal} setTabVal={setTabVal} setResetFilter={setResetFilter}/>
    </div>
  )
}

export default UserReports
