import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import generalReducer from '../common/general/generalSlice';
import definitionReducer from '../modules/definition/store/definitionSlice';
import securityReducer from '../modules/security/store/securitySlice';
import usersReducer from '../modules/user/store/userSlice';
import visitorReducer from '../modules/visitor/store/visitorSlice';

import policyReducer from '../modules/policies/store/policyslice';
import logsReducer from '../modules/logs/store/logsSlice'
import violationsReducer from '../modules/violations/store/violationSlice';
import notificationReducer from '../modules/notifications/store/notificationSlice';
import loginReducer  from '../modules/auth/store/loginSlice';
import parkingRequestReducer from '../modules/parking_request/store/parkingRequestSlice'
import applicationSettingsReducer  from '../modules/application_settings/store/applicationSlice';
import findacarReducer from '../modules/findvehicles/store/findacarslice';
import realtimeReducer from './../modules/realtime_monitior/store/realtimeSlice';
import dashboardReducer from './../modules/dashboard/store/dashboardSlice';

import reportReducer from './../modules/reports/store/reportSlice'
import exportReportReducer from '../modules/exportingReports/exportReportSlice' 

export const store = configureStore({
  reducer: {
    login              : loginReducer,
    dashboard          : dashboardReducer,
    user               : usersReducer,
    visitor            : visitorReducer,
    security           : securityReducer,
    definition         : definitionReducer,
    policy             : policyReducer,
    logs               : logsReducer,
    violations         : violationsReducer,
    general            : generalReducer,
    notifications      : notificationReducer,
    parkingrequest     : parkingRequestReducer,
    applicationSettings: applicationSettingsReducer,
    findacar           : findacarReducer,
    realtime           : realtimeReducer,

    reportData         : reportReducer,
    exportReportData   : exportReportReducer

  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
