import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ParkingZone from './screen/parkingZone';
import NavTabs from './../../common/components/NavTabs';
import LinkTab from '../../common/components/LinkTab';
import MainLayout from '../../common/layouts/MainLayout';
import ListTable from './screen/ListTable';
import { useDispatch } from 'react-redux';
import { RESET_STATE } from './store/parkingZoneSlice';

type Props = {};

const ParkingZonesIndex = (props: Props) => {
	const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
	return (
		<div className='navtabs__container'>
			<NavTabs>
				<LinkTab label='Parking Zones' href='zones' />
				<LinkTab label='Access Requests' href='requests' />
			</NavTabs>
			<Routes>
				<Route path={'zones'} element={<ParkingZone />} />
				<Route
					path={'requests'}
					element={
						<MainLayout>
							<ListTable />
						</MainLayout>
					}
				/>

				<Route path={'/*'} element={<Navigate to='zones' />} />
			</Routes>
		</div>
	);
};

export default ParkingZonesIndex;
