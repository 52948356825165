import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { formats } from "./EditorToolbar";
import "./textEditor.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { save_image_texteditor } from "../../../modules/notifications/store/action";
import { WarnToast } from "../../../utils/utilFunctions";
// import ImageResize from 'quill-image-resize';
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import htmlEditButton from "quill-html-edit-button";
Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

// Quill.register('modules/imageResize', ImageResize);

let MentionStyle = Quill.import("formats/mention");
Quill.register(MentionStyle, true);

const ImageFormatAttributesList = ["alt", "height", "width", "style"];

var BaseImageFormat = Quill.import("formats/image");
class ImageFormat extends BaseImageFormat {
  static formats(domNode: any) {
    return ImageFormatAttributesList.reduce(function (formats: any, attribute: any) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name: any, value: any) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

// Quill.register(ImageFormat, true);
// Quill.register(new Parchment.Attributor.Style('display', 'display', {
// 	whitelist: ['inline']
// }));
// Quill.register(new Parchment.Attributor.Style('float', 'float', {
// 	whitelist: ['left', 'right', 'center']
// }));
// Quill.register(new Parchment.Attributor.Style('margin', 'margin', {}));
// to get inline style for email support

let DirectionAttribute = Quill.import("attributors/attribute/direction");
Quill.register(DirectionAttribute, true);

let AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);

let BackgroundClass = Quill.import("attributors/class/background");
Quill.register(BackgroundClass, true);

let ColorClass = Quill.import("attributors/class/color");
Quill.register(ColorClass, true);

let DirectionClass = Quill.import("attributors/class/direction");
Quill.register(DirectionClass, true);

let FontClass = Quill.import("attributors/class/font");
Quill.register(FontClass, true);

let SizeClass = Quill.import("attributors/class/size");
Quill.register(SizeClass, true);

let AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

let BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);

let ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);

let DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);

let FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);

let SizeStyle = Quill.import("attributors/style/size");
Quill.register(SizeStyle, true);

Quill.register({
	"modules/htmlEditButton": htmlEditButton
  } , true)

type Props = {
  data: any;
  atValues?: any[];
  noMention?: boolean;
  value: any;
  setValue: any;
};

const TextEditor = ({ data, atValues, noMention, setValue, value }: Props) => {
  const quillRef: any = useRef(null);

  const handleChange = (value: any, delta: any, source: any, editor: any) => {
    setValue(value);
  };

  useEffect(() => {
    setValue(data ?? "");
  }, [data]);

  const imageHandler = (a: any) => {
    const input: any = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        //console.log('dgfdfgdgf');
        save_image_fx(file);
      } else {
        WarnToast("You could only upload images.");
      }
    };
  };

  function insertToEditor(url: any) {
    // let range = quillRef.current.getSelection();
    quillRef.current.getEditor().insertEmbed(null, "image", url);
  }

  const modules = React.useMemo(() => {
    const mentionConfig = {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      showDenotationChar: false,
      source: function (searchTerm: any, renderList: any) {
        renderList(atValues, searchTerm);
      },
    };

    return {
      imageActions: {},
      imageFormats: {},
      toolbar: {
        container: "#toolbar",
        handlers: {
          image: imageHandler,
        },
      },

      clipboard: {
        matchVisual: true,
      },

      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: false,
      },
      mention: mentionConfig,
	  htmlEditButton: {}
      // imageResize: {
      // 	parchment: Quill.import('parchment'),
      // 	modules: ['Resize', 'DisplaySize', 'Toolbar'],
      // 	handleStyles: {
      // 		padding: '7px',
      // 		color: 'white',
      // 	},
      // 	displaySize: true,
      // 	imageDrop: true,
      // },
    };
  }, [atValues]);

  const save_image_fx = (file: any) => {
    let d = new FormData();
    d.append("image", file);
    save_image_texteditor(d)?.then((res) => {
      console.log(res);
      insertToEditor(res?.response?.data);
    });
  };

  const [expand, setexpand] = useState(false);

  const [codeEdit, setcodeEdit] = useState(false);

  const expandFx = () => {
    setexpand(!expand);
  };

  return (
    <>
      <div className="col-12">
        <div className={expand ? "editor-wrapper expand" : "editor-wrapper"}>
          <EditorToolbar
            expand={expand}
            expandFx={expandFx}
 
          />

          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={value}
            className="pms_quill_wrapper"
            formats={formats}
            modules={modules}
            onChange={handleChange}
          />
        </div>

      </div>

    </>
  );
};

export default TextEditor;
