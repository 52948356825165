import React, { useEffect } from 'react';
import MainLayout from '../../../../common/layouts/MainLayout';
import FilterBar from './components/FilterBar';
import ListTable from './components/ListTable';

type Props = {};

const Unrecognized = (props: Props) => {
	return (
		

		<MainLayout>
			<FilterBar />
			<ListTable />
		</MainLayout>
	);
};

export default Unrecognized;
