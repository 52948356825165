import dayjs from 'dayjs';
import React from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { findTheEvent } from '../../../../../utils/moduleEvent';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data: any;
};
 
const AddModal = (props: Props) => {
	let userTypes = [ 'System User',  'Api User']
	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title='Application Log Viewer' subtitle='Logs' big>
				<div className="modal-body">
				<div className="table-details">
					<ul>
						<li>Transaction ID <span>{props.Data?.transactionID}</span></li>
						<li>User Name <span  style={{ textTransform : 'capitalize' }}>{props.Data?.createdUser}</span></li>
						<li>Event ID <span>{props.Data?.applicationEventID}</span></li>
						<li>Date Time <span>{dayjs(props.Data?.createdOn).format('DD-MMM-YYYY hh:mm:ss a')}</span></li>
					</ul>
					<div className="seperator"></div>
					<ul>
						<li>Event Description <span>{props.Data?.descriptions}</span></li>
						<li>Event Type <span style={{ textTransform : 'capitalize' }}>{`${props.Data?.eventKey} (${findTheEvent(props.Data?.eventKey)})`}</span></li>
						<li>Status <span>{props.Data?.status}</span></li>
						<li>Source IP <span>{props.Data?.ip}</span></li>
						<li>Interface <span>{userTypes[parseInt(props.Data?.source)]}</span></li>
						
					</ul>
				</div>
			</div>

			<div className="modal-footer">
				<a onClick={props.handleClose} className="btn btn-outline-blue close-modal-button">Close</a>
			</div>
		</ModalLayout>
	);
};

export default AddModal;
