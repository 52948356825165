import React, { useEffect, useState } from 'react'
import FilterBar from './components/FilterBar'
import Reports from './components/reportDisplay'
import { fetch_siteDDL } from '../../../../common/general/action'
import { useAppDispatch } from '../../../../config/hooks'

type Props = {}

const EntryAndExit = (props:Props) => {
  const dispatch  = useAppDispatch();
  const [isUnRecVeh, setIsUnRecVeh] = useState('0')
  useEffect(() => {
    const controller = new AbortController();

    fetch_siteDDL(dispatch,controller?.signal)    
  }, [])
  
  return (
    <div className="main-body-content license">
        <FilterBar  isUnRecVeh={isUnRecVeh} setIsUnRecVeh={setIsUnRecVeh}/>
        <Reports  isUnRecVeh={isUnRecVeh} setIsUnRecVeh={setIsUnRecVeh}/>
    </div>
  )
}

export default EntryAndExit
