import React, { useState } from 'react';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import ModalLayout from '../../../../common/layouts/ModalLayout';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { test_email } from '../../store/action';
import * as yup from 'yup';

type Props = {
	open: boolean;
	handleClose: () => void;
};

let schema = yup.object().shape({
    ToEmail: yup.string().email().required(),

});

const TestConnectionModal = (props: Props) => {
	const [email, setemail] = useState('');
	const [loader, setloader] = useState(false);
	const [valid, setvalid] = useState(true);



    

	const testConeectionFx = () => {
		setloader(true);

		let postData = {
			'ToEmail': email,
		};

		const Success = (res: any) => {
			setloader(false);

            if(res?.response?.data === true){
			SuccesToast("Mail sent sucessfully");

            }else if(res?.response?.data === false){
                FailedToast("Could not sent mail");

            }
			props.handleClose()
		};
		const Failed = (err: any) => {
			FailedToast();
			setloader(false);
			props.handleClose()

		};
		test_email(postData, Success, Failed);
	};
  
	return (
		<ModalLayout
			open={props.open}
			handleClose={() => {
				props.handleClose();
			}}
			title='Test Email Connection'
			// subtitle='Check if smtp connection is working'
		>
			<div className='col-12'>
				<div className='form-group inline-input full-label '>
					<label>Test Email Address</label>
					<input className='form-control grey-input' type='email' value={email}  name="ToEmail" onChange={(e) => setemail(e.target.value)} />
				</div>
				{!valid && <p className='hookform-error-message'>please enter a valid email</p>}
			</div>
			<div className='modal-footer'>
				<button className='btn btn-secondary border-blue' onClick={testConeectionFx} disabled={loader}>
					<ButtonLoader text='Send' loading={loader} />
				</button>
				<button
					type='reset'
					className='btn btn-outline-blue close-modal-button'
					onClick={() => {
						props?.handleClose();
					}}>
					Close
				</button>
			</div>
		</ModalLayout>
	);
};

export default TestConnectionModal;
