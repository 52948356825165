import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import SimpleBox from '../../../../common/components/graph_components/SimpleBox';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_moreThan_24hr, fetch_parking_availability } from '../../store/action';
import { DatePicker } from 'antd';
import BoxWithBgClr from './../../../../common/components/graph_components/BoxWithBgClr';
import moment from 'moment';

type Props = {};

const Total24hrPark = (props: Props) => {
	const childRef: any = useRef(null);
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);

	const [data, setdata] = useState<any>();

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
		};

		handleClose();
	
		fetch_moreThan_24hr(postData, signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ?? [];
				setdata(data);
			}
		});

		return () => {
			controller.abort();
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, date]);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};
	return (
		<>
			{/* <DashboardFilterWrapper ref={childRef}>
				<div className='form-group select-input full-label'>
					<label className='label-dnd-menuitem'>Date Range</label>
					<DatePicker  size='large' onChange={(dates: any) => console.log(dates[0]?.format('DD-MM-YYYY'))} />
				</div>
        
			</DashboardFilterWrapper> */}
			<BoxWithBgClr title='Parked for more than 24 hours in last week' value={data?.totalCount} color='bg-darkcyan' />

		</>
	);
};

export default Total24hrPark;
