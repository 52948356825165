import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../../../common/components/FormSelect';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_violation_policy, violation_policy_action } from '../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { useNavigate } from 'react-router';
import { fetch_zoneDDL } from '../../../../../common/general/action';
import FormSelectMultiple from '../../../../../common/components/FormSelectMultiple';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom';
import FormInput from '../../../../../common/components/FormInput';
import ActionConfirmBox from '../../../../../common/components/ActionConfirmBox';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};
const schema = yup.object().shape({

    violationName : yup.string().required(),
	vDescriptions : yup.string().required(),
	penalty1 : yup.number().required(),
	penalty2 : yup.number().required(),
	penalty3 : yup.number().required(),
	vCategory : yup.number().required(),
	isEnable : yup.boolean().default(false),
	isEnableVException : yup.boolean().default(false),
	clearPenaltyOlderThan : yup.number().default(null).when('isEnable', { is : true, then : yup.number().min(1).required() }),
	exceptionLevelFrom : yup.number().when('isEnableVException', { is : true, then : yup.number().min(1).required() }),
	exceptionLevelTo : yup.number().when('isEnableVException', { is : true, then : yup.number().min(1).required() }),
	isAvailbleAllZones : yup.boolean(),
	fK_ZoneIDs : yup.mixed().when('isAvailbleAllZones',{ is: false, then : yup.mixed().required('Select Zones') }),
	
});

const AddModal = (props: Props) => {
	const {
		register,
		control,
		handleSubmit,
		watch,
		formState,
		reset, setValue, clearErrors
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		const controller = new AbortController();
		fetch_zoneDDL(dispatch, controller.signal)
	clearErrors()
	},[])


	const VIOLATION_POLICY_DATA = useAppSelector((state) => state?.policy);


	useEffect(() => {
		
		if(props.Data){
			if(props.Data?.fK_ZoneIDs!==''){
		let newArr = props.Data?.fK_ZoneIDs?.split(',')?.map((el:any)=> el? parseInt(el):'')
		setZoneArr(newArr.map((el:any) => { return { value : el } }))
		}
		//console.log('ZONES=>',props.Data?.fK_ZoneIDs?.split(',')?.map((el:any)=> el?parseInt(el):''))
		reset({
			violationName        : props.Data.violationName,
			vDescriptions        : props.Data.vDescriptions,
			penalty1             : props.Data.penalty1,
			penalty2             : props.Data.penalty2,
			penalty3             : props.Data.penalty3,
			isEnable             : props.Data.isEnable,
			isEnableVException   : props.Data.isEnableVException,
			clearPenaltyOlderThan: props.Data.clearPenaltyOlderThan,
			exceptionLevelFrom   : props.Data.exceptionLevelFrom,
			exceptionLevelTo     : props.Data.exceptionLevelTo,
			vTypeID              : props.Data.vTypeID,
			vCategory            : props.Data.vCategory,
			// fK_ZoneIDs 				: props.Data.fK_ZoneIDs.split(',').map((el:any)=> Number(el))
			fK_ZoneIDs        : props.Data.fK_ZoneIDs!=='' ? props.Data.fK_ZoneIDs.split(',').map((el:any)=> el?parseInt(el):'') : [],
			isAvailbleAllZones: props.Data.isAvailbleAllZones,
		})
	}
	else{
		setValue('vCategory',1)
	}
	}, [props?.Data])
	

	const dispatch	= useAppDispatch();
	const navigate  = useNavigate();

	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmData, setConfirmData] = useState<any>()

	const [loading, setLoading] = useState(false)
	// const ZONELIST = useAppSelector((state) => state?.general)
	const [zoneArr, setZoneArr] = useState<any>([])
	const cancelConfirm = () =>{ setConfirmModal(false); setLoading(false); }

	// const [zoneArr, setZoneArr] = useState<any>(props.Data ? props.Data.fK_ZoneIDs && props.Data.fK_ZoneIDs.length!==0 ? props.Data.fK_ZoneIDs.split(',').map((el:any)=> Number(el)) : [] : [])
console.log(formState.errors,'ZONESARR',zoneArr)

	const confirmApprove = () =>{
		setConfirmModal(false)
		setLoading(true)
		let postData = {
			...confirmData,
			fK_ZoneIDs : zoneArr.map((el:any) => el.value).toString(),
		}
		// let data = {...e, fk_ZoneIDs : zoneArr.map((el:any) => el.value).toString()}
		console.log('DATAA',postData)
		const Success = (res: any) => {
			setLoading(false)
			fetch_violation_policy(navigate,dispatch, {
				'filters': {
					'query': VIOLATION_POLICY_DATA?.VIOLATION_POLICY_FILTER?.query || '',

				},
				'pagination': {
					'pageNo': VIOLATION_POLICY_DATA?.VIOLATION_POLICY_PAGINATION?.pageNo,
					'limit': VIOLATION_POLICY_DATA?.VIOLATION_POLICY_PAGINATION?.limit,
				}})
			SuccesToast(res?.data)
			setConfirmModal(false)
			setConfirmData(null)
			props.handleClose()
		}
		const Failed = (err: any) => {
			setLoading(false)
			FailedToast(err?.response)	
			setConfirmModal(false)
			setConfirmData(null)	
		}
		// violation_policy_action('post',e,Success,Failed)
		if(props.Data)
			violation_policy_action('put',postData,Success,Failed)
		else
			violation_policy_action('post',postData,Success,Failed)
	}

	const onSubmitHandler = (e: any) => {
		console.log('DAta',e,zoneArr)
		setConfirmData(e)
		setConfirmModal(true)

	};

	const penaltyArr = [ 
		{	label : 'NONE', value : 0 }, 
		{	label : 'SELF', value : 1 }, 
		{	label : 'ESCALATION 1', value : 2 }, 
		{	label : 'ESCALATION 2', value : 3 }
	]
	const vcatArr = [
		{ label : 'Vehicle', value : 1 },
		{ label : 'User', value : 2}
	]
	
	return (
		<>
		<ModalLayout open={props.open} handleClose={()=>{ reset()
			clearErrors()
			 props.handleClose();}} title={`${props.Data ? props.Data?.isSystem ? 'System' : 'Edit' : 'Add New'} Violation`} 
			 subtitle={props.Data ? `${props?.Data?.isSystem ? `System ` : ''}PolicyID #${props.Data?.vTypeID}` : 'Policies'}>
					<form className="row" onSubmit={handleSubmit(onSubmitHandler)}>
					
					{/* {	props.Data ?
					<div className="col-3">
						<div className="form-group inline-input full-label">
							<label>Violation Polcy ID</label>
							<input className="form-control grey-input"  {...register('vTypeID')} disabled />
						</div>
					</div>
					:	null
					} */}
					
					<div className="col-4">
						<div className="form-group inline-input full-label">
							<label>Violation Name</label>
							{/* <input className="form-control grey-input" {...register('violationName')}  /> */}

							<FormInput type='text' name="violationName"  className="form-control grey-input"  control={control} />

							<ErrorComponent errors={formState.errors} name={'violationName'} title={'Violation Name'} />
						</div>
					</div>
					<div className="col-8">
						<div className="form-group inline-input full-label">
							<label>Violation Description</label>
							{/* <input className="form-control grey-input" {...register('vDescriptions')} /> */}
							<FormInput type='text' name="vDescriptions"  className="form-control grey-input"  control={control} />

							<ErrorComponent errors={formState.errors} name={'vDescriptions'} title={'Descriptions'} />
						</div>
					</div>
					<div className="col-4">
						<div className={`form-group select-input full-label `}>
							<label>Penalty 1</label>
							<FormSelect
								control={control}
								name='penalty1'
								options={penaltyArr}
								grey={true}
								isMulti={false}
								placeholder='Penalty 1'							
							/>
							<ErrorComponent errors={formState.errors} name={'penalty1'} title={'1st Penalty'} />
						</div>
					</div>
					<div className="col-4">
						<div className={`form-group select-input full-label `}>
							<label>Penalty 2</label>
							<FormSelect
								control={control}
								name='penalty2'
								options={penaltyArr}
								grey={true}
								isMulti={false}
								placeholder='Penalty 2'							
							/>
							<ErrorComponent errors={formState.errors} name={'penalty2'} title={'2nd Penalty'} />
						</div>
					</div>
					<div className="col-4">
						<div className={`form-group select-input full-label `}>
							<label>Penalty 3</label>
							<FormSelect
								control={control}
								name='penalty3'
								options={penaltyArr}
								grey={true}
								isMulti={false}
								placeholder='Penalty 3'							
							/>
							<ErrorComponent errors={formState.errors} name={'penalty3'} title={'3rd Penalty'} />
						</div>
					</div>
					<div className="col-12" style={{ margin:'10px 0' }}>
						<div className={`form-group select-input full-label${props.Data?.isSystem ? 'disableAndOpacity' : ''}`}>
							<label>Violation Category</label>
							<FormSelect
								control={control}
								name='vCategory'
								options={vcatArr}
								grey={true}
								isMulti={false}
								placeholder='Vioalation Category'							
							/>
							<ErrorComponent errors={formState.errors} name={'vCategory'} title={'Violation Category'} />
						</div>
					</div>
					
					<div className="col-4 inline-input" style={{ marginBottom : 0 }}>
						<div className="checkbox zindexCorrection">
							<input defaultChecked={props.Data?false:true} type="checkbox" {...register('isEnable')}  />
							<label><span>Enable</span></label>
						</div> 
					</div>
					<div className={`col-5 inline-input ${ watch('isEnable') ? '' : 'disableAndOpacity'}`} style={{ marginBottom : 0 }}>
						<div className="form-group inline-input">
							<label>Clear Penalty Older Than</label> 
							<input type="text" defaultValue={0} className="form-control grey-input" {...register('clearPenaltyOlderThan')}/>
						</div>
						
					</div>
					
					<div className={`col-3 inline-input ${ watch('isEnable') ? '' : 'disableAndOpacity'}`} style={{ marginBottom : 0 }}>
						<div className="form-group inline-input">
							<label>Days</label> 
						</div>

					</div>
					<div className='col-12'>
					<ErrorComponent errors={formState.errors} name={'clearPenaltyOlderThan'} title={'Penalty limit'} customMessage={'Penalty limit must be number of Days'} />
					</div>
					<div className="col-7 inline-input" style={{ marginBottom : 0 }}>
						<div className="checkbox zindexCorrection">
							<input defaultChecked={props.Data?false:true} type="checkbox" {...register('isEnableVException')}/>
							<label><span>Violation Exception</span></label>
						</div> 
					</div>
					<div className={`col-6 inline-input  ${ watch('isEnableVException') ? '' : 'disableAndOpacity'}`} style={{ marginBottom : 0 }}>
						<div className="form-group inline-input">
							<label>Applicable to Personal Grades between</label>
							<input type="text" defaultValue={0} className="form-control grey-input" {...register('exceptionLevelFrom')}/>
							{/* <ErrorComponent errors={formState.errors} name={'exceptionLevelFrom'} title={'Exception Level'} customMessage={'Exception level must be a valid number'}/> */}
						</div>
					</div>
					<div className={`col-4 inline-input  ${ watch('isEnableVException') ? '' : 'disableAndOpacity'} `} style={{ marginBottom : 0 }}>
						<div className="form-group inline-input">
							<label>and</label>
							<input type="text" defaultValue={0} className="form-control grey-input" {...register('exceptionLevelTo')}/>
							{/* <ErrorComponent errors={formState.errors} name={'exceptionLevelTo'} title={'Exception Level'} customMessage={'Exception level must be a valid number'}/> */}
						</div>
					</div>
		
					<div className='col-12'>
						<ErrorComponent errors={formState.errors} name={'exceptionLevelFrom'} title={'Exception Level'} customMessage={'Exception level must start from a valid number'}/>
						<ErrorComponent errors={formState.errors} name={'exceptionLevelTo'} title={'Exception Level'} customMessage={'Exception level must be between valid numbers'}/>
					</div>
					<div className={`col-4 inline-input ${props.Data?.isSystem ? 'disableAndOpacity' : ''}`}>
						<div className={`checkbox zindexCorrection`}>
							<input defaultChecked={props.Data?false:true} type="checkbox" {...register('isAvailbleAllZones')}  disabled={props.Data?.isSystem}/>
							<label><span>Apply to all zones</span></label>
						</div> 
					</div>
					<div className={`col-12  ${watch('isAvailbleAllZones')||props.Data?.isSystem ? 'disableAndOpacity' : ''}`}>
						<div className="select-input">
							<label>Zones</label>
							<TreeSelectCustom
								grey classnames={'col-12'}
								selectedZones={zoneArr}	setselectedZones={setZoneArr}
								onchangeFx={(e:any,type:any)=>{
									console.log('ARR',e)
									
									let arr = []
									if(type){
										arr = [...zoneArr, { value : e.value}]
									}
									else{
										arr = zoneArr?.filter((el:any)=>el?.value!==e?.value)
									}
									setValue('fK_ZoneIDs', arr.map((el:any) => el.value).toString())
									console.log('ZONEARR',zoneArr)
									console.log(watch('fK_ZoneIDs'))
								}}
								isMulti={true}
							/>
							{/* <FormSelectMultiple
								control={control}
								name='fK_ZoneIDs'
								options={ZONELIST.ZONE_DDL}
								grey={true}
								onchangeFx={(e)=>{
									let arr = e
									console.log('ARR',e,arr)
									setZoneArr(arr.map((el:any) => el))
									setValue('fK_ZoneIDs', arr.map((el:any) => el).toString())
								}}
								isMulti={true}
								placeholder='Select Zones'							
							/> */}
							<ErrorComponent errors={formState.errors} name={'fK_ZoneIDs'} title={'Zones'} />
						</div>
					</div>
					<div className="col-12">
						<div className="modal-footer">
							<button type="submit" className="btn btn-secondary">
								<ButtonLoader loading={loading} text={'Save'} />
							</button>
							
							<button type="reset" onClick={()=>{ reset()
		clearErrors()
		 props.handleClose(); }} className="btn btn-outline-blue close-modal-button">Close</button>

						</div>	
					</div>
					</form>
					
		</ModalLayout>
		{confirmModal &&
			<ActionConfirmBox
				description={` Are you sure provided details are correct ?`}
				handleClose={cancelConfirm}
				open={true}
				ConfirmFx={confirmApprove}
			/>
		}
		</>
	);
};

export default AddModal;
