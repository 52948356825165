import Request from '../../../config/api';
import { BAYTYPE_DDL_URL, DEFINITION_URL, REALTIME_MONITOR, REALTIME_MONITOR_AVAILABILITY_CHECK, REALTIME_MONITOR_BAYSEARCH, REALTIME_MONITOR_HISTORY_EXPORT, REALTIME_MONITOR_HISTORY_EXPORTEXCEL, REALTIME_MONITOR_IMAGE, REALTIME_MONITOR_TRANSACTION, REALTIME_MONITOR_TRANSACTION_EXPORT, REALTIME_MONITOR_TRANSACTION_EXPORTEXCEL, REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED, REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED_EXPORT, REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED_EXPORTEXCEL, REALTIME_MONITOR_VEHICLE_DETAILS, ZONE_DDL_URL } from '../../../config/endpoint';
import { UPDATE_HISTORY_DATA, UPDATE_HISTORY_ERROR, UPDATE_HISTORY_LOADER, UPDATE_PARKING_AVAILABILITY_DATA, UPDATE_PARKING_AVAILABILITY_LOADER, UPDATE_REALTIME_DATA, UPDATE_REALTIME_LOADER, UPDATE_SELECTED_BAY, UPDATE_TRANSACTION_DATA, UPDATE_TRANSACTION_ERROR, UPDATE_TRANSACTION_LOADER, UPDATE_UNRECOGNIZED_DATA, UPDATE_UNRECOGNIZED_ERROR, UPDATE_UNRECOGNIZED_LOADER } from './realtimeSlice';
import { REALTIME_MONITOR_TRANSACTION_HISTORY } from './../../../config/endpoint';
import { FailedToast, FileDownloader } from '../../../utils/utilFunctions';

export const updateSelectedBay = (dispatch: any, data: any) => {
	dispatch(
		UPDATE_SELECTED_BAY({
			data,
		})
	);
};



 export const FetchRealTimeData = (dispatch: any, data: any  ,success?: ((res: any) => void) | undefined , failed?: ((err: any) => void) | undefined  , signal?: AbortSignal) => { 
	let api_url = REALTIME_MONITOR;
	dispatch(UPDATE_REALTIME_LOADER({loader : true}))
	 Request('post', api_url, data, signal ,success , failed)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_REALTIME_DATA({
					data: res?.response ?? [],
					filters : data
				})
			);
		}

	});
 }

 export const FetchRealTimeDataBayDetails = (dispatch: any, data: any  ,success?: ((res: any) => void) | undefined , failed?: ((err: any) => void) | undefined  , signal?: AbortSignal) => { 
	let api_url = REALTIME_MONITOR;
	dispatch(UPDATE_REALTIME_LOADER({loader : true}))
	 Request('post', api_url, data, signal ,success , failed)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_REALTIME_DATA({
					data: res?.response ?? [],
					filters : data
				})
			);
		}

	});
 }




 

export const FetchFloors = async ( siteid: any ,signal?:AbortSignal) => { 
	let api_url = DEFINITION_URL + 'floorlevel';

	let postData = {
		"filters": {
			"query":`fk_siteid = ${siteid}`,
			"OrderBy":"levelName asc"
		},
		"pagination": {
			"pageNo": 1,
			"limit": 1000
		}
	}
	return await Request('post', api_url, postData ,signal)

 }

 export const FetchBayDetails = async ( siteid: number , RefBayID :number ,signal?:AbortSignal) => { 
	let api_url = REALTIME_MONITOR_VEHICLE_DETAILS;

	let postData = {
	    "RefBayID":RefBayID,
    "SiteID":siteid
	}
	return await Request('post', api_url, postData ,signal)

 }




 export const  FetchSites = async (signal?:AbortSignal) => { 
	let api_url = DEFINITION_URL + 'site/ddl';
	return await Request('get', api_url,'' ,signal)
 }


 export const Fetch_BaySearch = async ( postData?: object, signal?: AbortSignal) => {
	let api_url = REALTIME_MONITOR_BAYSEARCH;
	let data = typeof postData === 'undefined' ? { 'query': '' } : postData;

	return await Request('post', api_url, data, signal)
};

 export const  FetchZones = async (siteid?: any ,signal?:AbortSignal) => { 
    let api_url = ZONE_DDL_URL;
    let postData  = {
        "query":`fk_siteid = ${siteid}`,
        "OrderBy":"zonename asc"
    }
	return await Request('post', api_url,postData ,signal)
 }

 export const  FetchZonesByLevel = async (siteid?: any, floorid?: any ,signal?:AbortSignal) => { 
    let api_url = ZONE_DDL_URL;
    let postData  = {
        "query":`fK_FLevelID = ${floorid} AND fk_siteid = ${siteid}`,
        "OrderBy":"zonename asc"
    }
	return await Request('post', api_url,postData ,signal)
 }

 export const  FetchBayType = async (signal?:AbortSignal) => { 
    let api_url = BAYTYPE_DDL_URL;
	return await Request('get', api_url,'' ,signal)
 }

 export const  FetchAvailabilityCheck = async (dispatch: any, postData:any ,signal?:AbortSignal) => { 
    let api_url = REALTIME_MONITOR_AVAILABILITY_CHECK;
	dispatch(UPDATE_PARKING_AVAILABILITY_LOADER({loader : true}))

	 Request('post', api_url,postData ,signal)?.then((res) => {
        const { response, statusCode } = res;
        if (statusCode === 200) {
            dispatch(
                UPDATE_PARKING_AVAILABILITY_DATA({
                    data : response,
                    filters : postData,
                })
            );
        }
    });
};
 


 export const fetch_parking_transaction = ( dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = REALTIME_MONITOR_TRANSACTION;

    dispatch(UPDATE_TRANSACTION_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_TRANSACTION_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {

            dispatch(UPDATE_TRANSACTION_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const exportTransaction_as_pdf = (postData:any, loaderFn:any) => {
    let api_url = REALTIME_MONITOR_TRANSACTION_EXPORT;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()
        if(res?.statusCode===200){
            FileDownloader(res?.response,`Parking_Transaction`,'pdf')
        }else{
            FailedToast(res?.response)
        }

    })
}


export const exportHistory_as_pdf = (postData:any, loaderFn:any) => {
    let api_url = REALTIME_MONITOR_HISTORY_EXPORT;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()

        if(res?.statusCode===200)
            FileDownloader(res?.response,`Parking_History`,'pdf')
        else{
            FailedToast(res?.response)
        }

    })
}

export const exportTransaction_as_excel = (postData:any, loaderFn:any) => {
    let api_url = REALTIME_MONITOR_TRANSACTION_EXPORTEXCEL;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()

        if(res?.statusCode===200)
            FileDownloader(res?.response,`Parking_Transaction`,'xlsx')
        else{
            FailedToast(res?.response)
        }

    })
}

export const exportHistory_as_excel = (postData:any, loaderFn:any) => {
    let api_url = REALTIME_MONITOR_HISTORY_EXPORTEXCEL;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()

        if(res?.statusCode===200)
            FileDownloader(res?.response,`Parking_History`,'xlsx')
        else{
            FailedToast(res?.response)
        }

    })
}


export const fetch_parking_transaction_history = ( dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = REALTIME_MONITOR_TRANSACTION_HISTORY;

    dispatch(UPDATE_HISTORY_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_HISTORY_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {

            dispatch(UPDATE_HISTORY_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const fetch_parking_unrecognized = ( dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED;

    dispatch(UPDATE_UNRECOGNIZED_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_UNRECOGNIZED_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {

            dispatch(UPDATE_UNRECOGNIZED_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const exportUnrecognized_as_pdf = (postData:any, loaderFn:any) => {
    let api_url = REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED_EXPORT;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()

        if(res?.statusCode===200)
            FileDownloader(res?.response,`Parking_Unrecognized`,'pdf')
        else{
            FailedToast(res?.response)
        }

    })
}

export const exportUnrecognized_as_excel = (postData:any, loaderFn:any) => {
    let api_url = REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED_EXPORTEXCEL;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()

        if(res?.statusCode===200)
            FileDownloader(res?.response,`Parking_Unrecognized`,'xlsx')
        else{
            FailedToast(res?.response)
        }

    })
}

export const  FetchImage = async (data :any ,signal?:AbortSignal) => { 
    let api_url = REALTIME_MONITOR_IMAGE;
	return await Request('post', api_url,data ,signal)
 }