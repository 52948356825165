import React from 'react'
import MainLayout from '../../../../common/layouts/MainLayout'

import FilterBar from './components/FilterBar'
import ListTable from './components/ListTable'
// import AccessPrivilage from './accessPrivilage';

const WebUsers = () => {

  return (
    <MainLayout>
      <FilterBar />
      <ListTable />
    </MainLayout>
  )
}

export default WebUsers


