/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBox from '../../../../common/components/checkBox';
import DeleteCustomConfirm from '../../../../common/components/DeleteCustomConfirm';
import CommonTable from '../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { definiton_delete, fetch_definitionList } from '../../store/action';
import { RESET_STATE } from '../../store/definitionSlice';

import AddModal from './AddModalComponents/addModalSimple';
import AddModalDrop from './AddModalComponents/addModalZone';
import PriorityAddModal from './AddModalComponents/priorityLevelAdd';
import AddModalSite from './AddModalComponents/addModalSite';

import { definition_routes } from '../../config/definitionRoutes';
import { fetch_siteDDL, fetch_zoneDDL } from '../../../../common/general/action';
import CustomConfirm from '../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../common/components/ButtonLoader';

function ListTable({ label, path, defname, defId, modalType }: { label: string; path: string; defname: string; defId: string; modalType: Number }) {
	const [addModal, setaddModal] = useState(false);
	const [delModal, setdelModal] = useState(false);
	const [loader, setLoader]	= useState(false)

	const delModalClose = () => setdelModal(false);
	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);

	// const [isDefault, setIsDefault] = useState(false)
	const [id, setId] = useState(null);
	const [selectedData, setSelectedData] = useState({});

	let navigate = useNavigate();
	const dispatch = useAppDispatch();

	const DEFINITION_DATA = useAppSelector((state) => state?.definition);
	const fetchIdRef = useRef(0);

	useEffect(() => {
		const controller = new AbortController();
		fetch_siteDDL(dispatch, controller.signal);
		fetch_zoneDDL(dispatch, controller.signal);

		//   return () => {
		dispatch(RESET_STATE());
		//   }
	}, [dispatch, label]);

	const handleDeletModalOpen = (id: any) => {
		setId(id);
		setdelModal(true);
	};

	const ConfirmFx = (val: any) => {
		setLoader(true)
		const Success = (res: any) => {
			setLoader(false)
			SuccesToast(res?.response?.data);
			fetch_definitionList(
				navigate,
				dispatch,
				{
					'filters': {
						'query': DEFINITION_DATA?.DEFINITION_FILTER?.query,
					},
					'pagination': {
						'pageNo': DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						'limit': DEFINITION_DATA.DEFINITION_PAGINATION.limit,
					},
				},
				path === 'floorlevel' ? 'floorlevel-without-Permission' : path
			);
			delModalClose();
		};
		const Failed = (err: any) => {
			setLoader(false)
			FailedToast(err?.response);
		};

		definiton_delete(val, path === 'floorlevel' ? 'floorlevel-without-Permission' : path, Success, Failed);
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: any) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_definitionList(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					path === 'floorlevel' ? 'floorlevel-without-Permission' : path,
					signal
				);
			}
		},
		[dispatch, navigate, path]
	);

	const columns = React.useMemo(
		() => [
			{ Header: 'ID ', accessor: defId, orderByKey: defId },

			{ Header: path === 'prioritylevel' ? 'LEVEL' : 'NAME ', accessor: defname, hideBody: defname === '', hideHeader: defname === '', orderByKey: defname },

			{
				Header: 'SITE',
				accessor: 'siteName',
				hideBody: !definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'siteName'),
				hideHeader: !definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'siteName'),
				orderByKey: path === 'floorlevel' ?  'siteName' : 'fK_SiteID'
			},

			{ Header: 'ADDED BY ', accessor: 'createdUser'},

			{
				Header: 'DESCRIPTION ',
				accessor: 'descriptions',
				hideBody: !definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'descriptions'),
				hideHeader: !definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'descriptions'),
				// orderByKey: 'description'
			},

			{
				Header: 'DEFAULT ',
				accessor: 'isDefault',
				hideBody: !definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'isDefault'),
				hideHeader: !definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'isDefault'),
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<CheckBox checked={row?.values?.isDefault ?? false} disabled={true} />
						</Fragment>
					);
				},
			},
			{
				Header: 'ACTION',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					//console.log(row)
					return (
						<Fragment>
							<div className='button-group'>
								<a
									onClick={() => {
										// row?.values && console.log(row)
										let refIdStr = definition_routes.find((el) => el.path === path)?.referenceId ?? null;
										setSelectedData({
											id: row?.original[defId],
											name: row?.original[defname],
											referenceId: refIdStr ? row?.original[refIdStr] : 0,
											...row?.original,
										});
										addModalOpen();
									}}
									className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-pen'></i>
								</a>
								{
									path==="floorlevel" ? null :
									<a className='btn btn-sqaure btn-red modal-button' onClick={() => handleDeletModalOpen(row?.values[defId])}>
										<i className='fa-solid fa-trash'></i>
									</a>
								}
								
							</div>
						</Fragment>
					);
				},
			},
		],
		[defId, defname, path]
	);

	console.log('THIS IS COLUMS ++++++ ', columns);

	return (
		<>
			<CommonTable
				columns={columns}
				data={DEFINITION_DATA?.DEFINITION_TABLE ?? []}
				fetchData={fetchData}
				pageCount={DEFINITION_DATA?.DEFINITION_PAGINATION?.totalCount ?? 0}
				loading={DEFINITION_DATA?.DEFINITION_LOADER}
				page_no={DEFINITION_DATA?.DEFINITION_PAGINATION?.pageNo ?? 1}
				limit={DEFINITION_DATA?.DEFINITION_PAGINATION?.limit ?? 1}
				filters={DEFINITION_DATA?.DEFINITION_FILTER}
			/>
			{addModal &&
				(path === 'prioritylevel' ? (
					<PriorityAddModal open={addModal} handleClose={addModalClose} label={label} path={path} Data={selectedData} />
				) : definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'fK_ZoneID') ? (
					<AddModalDrop open={addModal} handleClose={addModalClose} label={label} path={path} Data={selectedData} />
				) : definition_routes.find((el) => el.path === path)?.componentsNeed?.some((el) => el === 'fK_SiteID') ? (
					<AddModalSite open={addModal} handleClose={addModalClose} label={label} path={path} Data={selectedData} />
				) : (
					<AddModal open={addModal} handleClose={addModalClose} label={label} path={path} Data={selectedData} />
				))}
			<CustomConfirm
				open={delModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						if (id) ConfirmFx(id);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
		</>
	);
}

export default ListTable;
