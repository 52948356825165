import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import { DataObject, GeneralSettingObject, LogObject, T_ComapnyProfile, T_SyncsystemItem } from './appSettings.interfcae';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	LOG_CLEAN_UP_OBJECT_DATA: {} as LogObject,
	LOG_CLEAN_UP_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	LOG_CLEAN_UP_LOADER: false,
	LOG_CLEAN_UP_FILTER: { query: '' } as FilterObject,
	LOG_CLEAN_UP_ERROR: '',

	DATA_CLEAN_UP_OBJECT_DATA: {} as DataObject,
	DATA_CLEAN_UP_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	DATA_CLEAN_UP_LOADER: false,
	DATA_CLEAN_UP_FILTER: { query: '' } as FilterObject,
	DATA_CLEAN_UP_ERROR: '',

	GENERAL_SETTINGS_OBJECT_DATA: {} as GeneralSettingObject,
	GENERAL_SETTINGS_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	GENERAL_SETTINGS_LOADER: false,
	GENERAL_SETTINGS_FILTER: { query: '' } as FilterObject,
	GENERAL_SETTINGS_ERROR: '',


	SYNC_LIST_DATA: [] as T_SyncsystemItem[],
	SYNC_LIST_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	SYNC_LIST_LOADER: false,
	SYNC_LIST_FILTER: { query: '' } as FilterObject,
	SYNC_LIST_ERROR: '',


	COMPANY_PROFILE: {} as T_ComapnyProfile,

};

export const APPLICATION_SETTING_SLICE = createSlice({
	name: 'APPLICATION_SETTINGS',
	initialState: initialState,
	reducers: {
		UPDATE_LOG_CLEAN_UP_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;

			state.LOG_CLEAN_UP_OBJECT_DATA = data;
			state.LOG_CLEAN_UP_PAGINATION = pagination;
			state.LOG_CLEAN_UP_FILTER = filters;
			state.LOG_CLEAN_UP_LOADER = false;
			state.LOG_CLEAN_UP_ERROR = '';
			state.LOG_CLEAN_UP_ERROR = '';
		},
		UPDATE_LOG_CLEAN_UP_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.LOG_CLEAN_UP_LOADER = loader;
		},

		UPDATE_LOG_CLEAN_UP_ERROR: (state, action) => {
			const { error } = action.payload;
			state.LOG_CLEAN_UP_ERROR = error;
		},

		UPDATE_DATA_CLEAN_UP_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;

			state.DATA_CLEAN_UP_OBJECT_DATA = data;
			state.DATA_CLEAN_UP_PAGINATION = pagination;
			state.DATA_CLEAN_UP_FILTER = filters;
			state.DATA_CLEAN_UP_LOADER = false;
			state.DATA_CLEAN_UP_ERROR = '';
		},
		UPDATE_DATA_CLEAN_UP_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.DATA_CLEAN_UP_LOADER = loader;
		},

		UPDATE_DATA_CLEAN_UP_ERROR: (state, action) => {
			const { error } = action.payload;
			state.DATA_CLEAN_UP_ERROR = error;
		},

		UPDATE_GENERAL_SETTINGS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.GENERAL_SETTINGS_OBJECT_DATA = data;
			state.GENERAL_SETTINGS_PAGINATION = pagination;
			state.GENERAL_SETTINGS_FILTER = filters;
			state.GENERAL_SETTINGS_LOADER = false;
			state.GENERAL_SETTINGS_ERROR = '';
			state.GENERAL_SETTINGS_ERROR = '';
		},
		UPDATE_GENERAL_SETTINGS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.GENERAL_SETTINGS_LOADER = loader;
		},

		UPDATE_GENERAL_SETTINGS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.GENERAL_SETTINGS_ERROR = error;
		},

		UPDATE_COMPANY_PROFILE: (state, action) => {
			const { data } = action.payload;
			state.COMPANY_PROFILE = data;
		},

		UPDATE_SYNC_LIST_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.SYNC_LIST_DATA = data;
			state.SYNC_LIST_PAGINATION = pagination;
			state.SYNC_LIST_FILTER = filters;
			state.SYNC_LIST_LOADER = false;
			state.SYNC_LIST_ERROR = '';
			state.SYNC_LIST_ERROR = '';
		},
		UPDATE_SYNC_LIST_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.SYNC_LIST_LOADER = loader;
		},

		UPDATE_SYNC_LIST_ERROR: (state, action) => {
			const { error } = action.payload;
			state.SYNC_LIST_ERROR = error;
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
	UPDATE_LOG_CLEAN_UP_DATA,
	UPDATE_LOG_CLEAN_UP_LOADER,
	UPDATE_LOG_CLEAN_UP_ERROR,

	UPDATE_DATA_CLEAN_UP_DATA,
	UPDATE_DATA_CLEAN_UP_LOADER,
	UPDATE_DATA_CLEAN_UP_ERROR,

	UPDATE_GENERAL_SETTINGS_DATA,
	UPDATE_GENERAL_SETTINGS_LOADER,
	UPDATE_GENERAL_SETTINGS_ERROR,

	UPDATE_COMPANY_PROFILE,

	UPDATE_SYNC_LIST_DATA,
	UPDATE_SYNC_LIST_LOADER,
	UPDATE_SYNC_LIST_ERROR,

	RESET_STATE

} = APPLICATION_SETTING_SLICE.actions;
export const APPLICATION_SETTINGSData = (state: RootState) => state;
export default APPLICATION_SETTING_SLICE.reducer;
