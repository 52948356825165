import { useState, useRef } from 'react';
import { defaultValueProcessor, Field, formatQuery, QueryBuilder, RuleGroupType, RuleType  } from 'react-querybuilder';
import ModalLayout from '../../layouts/ModalLayout';
import './queryBStyle.css'
import { ValueEditor, CombinatorSelector, FieldSelector, OperatorSelector, AddGroupAction, AddRuleAction, RemoveGroupAction, RemoveRuleAction } from "./customValueEditor";
import { ExtendedField } from '../../../config/filterSearch';

type Props = {
	open: boolean;
  setQuery: (query:string)=>void;
	handleClose: () => void;
  tableFields:ExtendedField[];
  queryType?:  "json" | "sql" | "json_without_ids" | "mongodb" | "cel" | "spel";
  ref?: any;
};

// const fields :Field[] = [
//   { name: 'name', label: 'Name' },
//   { name: 'lastName', label: 'Last Name' },
//   { name: 'age', label: 'Age', inputType: 'number' },
//   { name: 'address', label: 'Address' },
//   { name: 'phone', label: 'Phone' },
//   { name: 'email', label: 'Email', validator: ({ value }:{value:string}) => /^[^@]+@[^@]+/.test(value) },
//   { name: 'twitter', label: 'Twitter' },
//   { name: 'isDev', label: 'Is a Developer?', valueEditorType: 'checkbox', defaultValue: false },
//   { name: 'lister', label: 'Lists', valueEditorType:'select', values: [{ name : 'sadf', label:'ASDF' }, { name : 'sadfWQER', label:'ASDFQWER' }, { name : 'sadfZXVC', label:'ASDFZXCV' }] }
// ];

const QueryBuilderModal = (props: Props) => {
  const [queryObj, setQueryObj] = useState<RuleGroupType>({
    id:"root",
    combinator: 'and',
    rules: [],
  });
console.log('querybuilder',props)
  const changeValueProcess = (field:any, operator:any, value:any, valueSource?: any) => {
    console.log('VALSource=>',valueSource, field, operator, value)
    if (!isNaN(parseInt(value)) && getInputType(field,operator)) {
      // Assuming `value` is an array, such as from a multi-select
      return `${parseInt(value)}`;
    }
    // Fall back to the default value processor for other operators
    return defaultValueProcessor(field, operator, value);
  };

  // const ruleProcessor = (r: RuleType): RuleType & { inputType?: string } => ({
  //   ...r,
  //   inputType: props?.tableFields?.find(f => f.name === r.field)?.inputType ?? 'text',
  // });

  const getInputType = (field: string, operator: string) =>{
    const fieldName = props?.tableFields?.find(fld => fld.name === field);
    if(fieldName?.inputType==='number')
      return true
    else 
      return false
  }


  return (
    <ModalLayout open={props.open} handleClose={props.handleClose} title='Advanced Filter' big
     subtitle={`Give your conditions for fields in 'Rule', group a set of rules in a 'Group'.`}>
      <div className="modal-body" style={{ height:'60vh' }}>
        <div className='modal-body-content' style={{ minHeight:'unset', maxHeight:'unset', height:'60vh', overflowY:'scroll' }}>
      <QueryBuilder
        resetOnFieldChange
        fields={ props?.tableFields ?? []} 
        query={queryObj} 
        controlElements={{
          combinatorSelector  : CombinatorSelector,
          fieldSelector       : FieldSelector,
          operatorSelector    : OperatorSelector,
          addGroupAction      : AddGroupAction,
          addRuleAction       : AddRuleAction,
          removeGroupAction   : RemoveGroupAction,
          removeRuleAction    : RemoveRuleAction,
          valueEditor         : ValueEditor 
        }}
        // getInputType={getInputType}
        // controlElements={{
        //   valueEditor: ValueEditor
        // }}
        onQueryChange={(q)=>setQueryObj(q)} />
      
      </div>
      
      </div>
      <div className="col-12">
				<div className="modal-footer">
					
          <button type="button" onClick={()=>{ 
            setQueryObj({ combinator: 'and',  rules: [] }); props.handleClose(); }} className="btn btn-outline-blue close-modal-button">Close</button>
          <button type="submit" className="btn btn-secondary" 
            onClick={()=> { 
              console.log(queryObj,'format=>',formatQuery(queryObj,{ format : props.queryType??'sql', valueProcessor : changeValueProcess}))
              props.setQuery(formatQuery(queryObj,{ format : props.queryType??'sql', valueProcessor : changeValueProcess})); 
              }} >Filter</button>
          
				</div>	
			</div>
    </ModalLayout>
  )
};

export default QueryBuilderModal;