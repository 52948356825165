/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_webusers, webusers_delete, webusers_detail } from '../../../store/action';
import AddModal from './addModal';
import { subModuleList } from './../../../../../config/subModuleList';

type Props = {};

const ListTable = (props: Props) => {
	const SECURITY_DATA = useAppSelector((state) => state?.security);
	const fetchIdRef = useRef(0);
	const dispatch = useAppDispatch();

	const [editData, seteditData] = useState();
	const [editModal, setEditModal] = useState(false);
	const [ID, setID] = useState();
	const [deleteModal, setdeleteModal] = useState(false);

	const editModalOpen = (data: any) => {
		webusers_detail(data?.sysUserID)?.then((res) => {
			if (res?.statusCode === 200) {
				seteditData(res?.response?.data);
				setEditModal(true);
			}
		});
	};
	const editModalClose = () => setEditModal(false);

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: any) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_webusers(
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch]
	);

	const handleDeletModalOpen = (id: any) => {
		setID(id);
		setdeleteModal(true);
	};
	const delModalClose = () => setdeleteModal(false);

	const handleDelete = (id: any) => {
		const sucess = (res: any) => {
			console.log(res);
			SuccesToast(res?.response?.data);
			setdeleteModal(false);

			fetch_webusers(dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				},
			});
		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
			setdeleteModal(false);
		};

		webusers_delete(id, sucess, failed);
	};

	const columns = React.useMemo(
		() => [
			{ Header: '#No ', accessor: 'sysUserID', orderByKey: 'sysUserID' },

			{ Header: 'Name ', accessor: 'userName', orderByKey: 'vud.firstname' },

			{ Header: 'Email	 ', accessor: 'emailID', orderByKey: 'tsp.emailID' },

			{ Header: 'Created date ', accessor: 'createdOn', orderByKey: 'tsp.CreatedOn', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A' },

			{ Header: 'Created By ', accessor: 'createdUser' ,orderByKey: 'createdUser', },

			{ Header: 'Updated By ', accessor: 'updatedUser',orderByKey: 'updatedUser',  },

			{ Header: 'Role	 ', accessor: 'roleName' ,orderByKey: 'roleName', },

			{ Header: 'User Type ', accessor: 'typeName' ,orderByKey: 'typeName', },

			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<PermissionWrapper subModuleID={subModuleList['Web User']} type='isEdit'>
									<a onClick={() => editModalOpen(row?.original)} className='btn btn-sqaure btn-white modal-button'>
										<i className='fa-solid fa-pen'></i>
									</a>
								</PermissionWrapper>

								<PermissionWrapper subModuleID={subModuleList['Web User']} type='isDelete'>
									<a
										className='btn btn-sqaure btn-red modal-button'
										onClick={() => handleDeletModalOpen(row?.original?.sysUserID)}>
										<i className='fa-solid fa-trash'></i>
									</a>
								</PermissionWrapper>

								{/* <a className="btn btn-sqaure btn-white modal-button"><i className="fa-solid fa-eye"></i></a> */}
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);

	return (
		<>
			<CommonTable
				columns={columns}
				data={SECURITY_DATA?.WEBUSERS_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={SECURITY_DATA?.WEBUSERS_PAGINATION?.totalCount ?? 0}
				loading={SECURITY_DATA?.WEBUSERS_LOADER}
				page_no={SECURITY_DATA?.WEBUSERS_PAGINATION?.pageNo ?? 1}
				limit={SECURITY_DATA?.WEBUSERS_PAGINATION?.limit ?? 1}
				error={SECURITY_DATA?.WEBUSERS_ERROR ?? ''}
				filters={SECURITY_DATA?.WEBUSERS_FILTER}
			/>

			{editModal && <AddModal open={editModal} handleClose={editModalClose} Data={editData} />}
			<CustomConfirm open={deleteModal} handleClose={delModalClose} 
			title='Are you sure?'
			description='This action is irreversible. Can you confirm?'>
				<button
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						handleDelete(ID);
					}}>
					Confirm
				</button>
			</CustomConfirm>
		</>
	);
};

export default ListTable;
