import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	USER_ALLOCATED_PARKING_LIST_DATA: [],
	USER_ALLOCATED_PARKING_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	USER_ALLOCATED_PARKING_LOADER: true,
	USER_ALLOCATED_PARKING_FILTER: { query: '' } as FilterObject,
	USER_ALLOCATED_PARKING_ERROR: '',

	USER_ALLOCATED_PARKINGS_COUNT: {
		pending: 0,
		approved: 0,
		rejected: 0,
		cancelled: 0,
		revoked: 0,
		permenantParking: 0,
		temporaryParking: 0,
		totalCount: 0,
	},
	VISITOR_ALLOCATED_PARKING_LIST_DATA: [],
	VISITOR_ALLOCATED_PARKING_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	VISITOR_ALLOCATED_PARKING_LOADER: true,
	VISITOR_ALLOCATED_PARKING_FILTER: { query: '' } as FilterObject,
	VISITOR_ALLOCATED_PARKING_ERROR: '',

	FIND_A_VEHICLE_LIST_DATA: [],
	FIND_A_VEHICLE_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	FIND_A_VEHICLE_LOADER: true,
	FIND_A_VEHICLE_FILTER: {} as any,
	FIND_A_VEHICLE_ERROR: '',

	VISITOR_ALLOCATED_PARKINGS_COUNT: { pending: 0, approved: 0, rejected: 0, revoked: 0, totalCount: 0 },
};

export const FINDACARSLICE = createSlice({
	name: 'FIND_A_CAR',
	initialState: initialState,
	reducers: {
		UPDATE_USER_ALLOCATED_PARKING_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.USER_ALLOCATED_PARKING_LIST_DATA = data;
			state.USER_ALLOCATED_PARKING_PAGINATION = pagination;
			state.USER_ALLOCATED_PARKING_FILTER = filters;
			state.USER_ALLOCATED_PARKING_LOADER = false;
			state.USER_ALLOCATED_PARKING_ERROR = '';
		},
		UPDATE_USER_ALLOCATED_PARKING_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.USER_ALLOCATED_PARKING_LOADER = loader;
		},
		UPDATE_USER_ALLOCATED_PARKING_ERROR: (state, action) => {
			const { error } = action.payload;
			state.USER_ALLOCATED_PARKING_ERROR = error;
			state.USER_ALLOCATED_PARKING_LOADER = false;
		},
		UPDATE_USER_ALLOCATED_PARKING_COUNT: (state, action) => {
			const { count } = action.payload;
			state.USER_ALLOCATED_PARKINGS_COUNT = count;
		},

		UPDATE_VISITOR_ALLOCATED_PARKING_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.VISITOR_ALLOCATED_PARKING_LIST_DATA = data;
			state.VISITOR_ALLOCATED_PARKING_PAGINATION = pagination;
			state.VISITOR_ALLOCATED_PARKING_FILTER = filters;
			state.VISITOR_ALLOCATED_PARKING_LOADER = false;
			state.VISITOR_ALLOCATED_PARKING_ERROR = '';
		},
		UPDATE_VISITOR_ALLOCATED_PARKING_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.VISITOR_ALLOCATED_PARKING_LOADER = loader;
		},
		UPDATE_VISITOR_ALLOCATED_PARKING_ERROR: (state, action) => {
			const { error } = action.payload;
			state.VISITOR_ALLOCATED_PARKING_ERROR = error;
			state.VISITOR_ALLOCATED_PARKING_LOADER = false;
		},
		UPDATE_VISITOR_ALLOCATED_PARKING_COUNT: (state, action) => {
			const { count } = action.payload;
			state.VISITOR_ALLOCATED_PARKINGS_COUNT = count;
		},

		UPDATE_FIND_A_VEHICLE_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.FIND_A_VEHICLE_LOADER = loader;
		},
		UPDATE_FIND_A_VEHICLE_ERROR: (state, action) => {
			const { error } = action.payload;
			state.FIND_A_VEHICLE_ERROR = error;
			state.FIND_A_VEHICLE_LOADER = false;
		},
		UPDATE_FIND_A_VEHICLE_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.FIND_A_VEHICLE_LIST_DATA = data;
			state.FIND_A_VEHICLE_PAGINATION = pagination;
			state.FIND_A_VEHICLE_FILTER = filters;
			state.FIND_A_VEHICLE_LOADER = false;
			state.FIND_A_VEHICLE_ERROR = '';
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
	UPDATE_USER_ALLOCATED_PARKING_DATA,
	UPDATE_USER_ALLOCATED_PARKING_LOADER,
	UPDATE_USER_ALLOCATED_PARKING_ERROR,
	UPDATE_USER_ALLOCATED_PARKING_COUNT,

	UPDATE_VISITOR_ALLOCATED_PARKING_DATA,
	UPDATE_VISITOR_ALLOCATED_PARKING_LOADER,
	UPDATE_VISITOR_ALLOCATED_PARKING_ERROR,
	UPDATE_VISITOR_ALLOCATED_PARKING_COUNT,

	UPDATE_FIND_A_VEHICLE_DATA,
	UPDATE_FIND_A_VEHICLE_ERROR,
	UPDATE_FIND_A_VEHICLE_LOADER,
	RESET_STATE


} = FINDACARSLICE.actions;
export const FINDACARData = (state: RootState) => state;
export default FINDACARSLICE.reducer;
