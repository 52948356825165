import React from 'react'
import './commonStyle.css'

type Props = {}

const FullLoader = (props: Props) => {
  return (
    <div className='boxes-wrapper'>

    <div className="boxes">
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
</div>

  )
}

export default FullLoader