import React, { Fragment, useEffect, useState } from 'react';
import log, { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { fetch_notification_settings, fetch_notification_settings_configuration, update_general_notification_settings, update_smtp_notification_settings } from '../../store/action';
import { useAppDispatch, useAppSelector } from './../../../../config/hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import ErrorComponent from '../../../../common/components/ErrorComponent';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import TestConnectionModal from './testConnectionModal';
type Props = {};

const schema = yup.object().shape({
	nSettingsID: yup.number(),
	senderName: yup.string().required(),
	senderEmail: yup.string().email().required(),
	emailHost: yup.string().required(),
	smtPort: yup.string().required(),
	emailPassword: yup.string(),
	eTimeOut: yup.string().required(),
	enableSSL: yup.boolean().required(),
	enableNotification: yup.boolean().required(),
	emailUserName : yup.string().email().required()
});

type IGen_cong = {
	"templateId": number,
	"templateCode": string,
	"isClearNotificationHistory": boolean,
	"clearNotificationHistoryOlder": number

}



const NotificationSettings = (props: Props) => {
	let dispatch = useAppDispatch();
	useEffect(() => {
		fetch_notification_settings(dispatch);
		fetch_notification_settings_configuration(dispatch);
	}, [dispatch]);

	const [buttonLoading, setbuttonLoading] = useState(false);

	const notificationSettings = useAppSelector((state) => state?.notifications?.NOTIFICATION_SETTINGS);

	const notificationSettingsConfig = useAppSelector((state) => state?.notifications?.NOTIFICATION_SETTINGS_CONFIGURATION) ?? [];


	const [GenralConfiguration, setGenralConfiguration] = useState<IGen_cong[]>([])
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	});

	console.log("ERRPR", errors)

	// log(errors, 'Error', 'red');

	const onSubmitSMTPhandler = (smptData: any) => {
		console.log('smptData', smptData);
		setbuttonLoading(true);
		let data: any = {
			'nSettingsID': smptData?.nSettingsID ?? 0,
			'senderName': smptData?.senderName,
			'senderEmail': smptData?.senderEmail,
			'emailHost': smptData?.emailHost,
			'smtPort': smptData?.smtPort,
			'eTimeOut': smptData?.eTimeOut,
			'enableSSL': smptData?.enableSSL,
			'enableNotification': smptData?.enableNotification,
			"emailUserName" : smptData?.emailUserName
		};

		if (smptData?.emailPassword) {
			data["emailPassword"] = smptData?.emailPassword

		}

		console.log('data', data);

		const Success = (res: any) => {
			fetch_notification_settings(dispatch);
			SuccesToast(res?.data);
			setbuttonLoading(false);
			setValue('emailPassword', '');

		};
		const Failed = (err: any) => {
			FailedToast(err?.response);
			setbuttonLoading(false);
		};
		update_smtp_notification_settings(data, Success, Failed);
	};

	useEffect(() => {
		if (notificationSettings) {
			setValue('nSettingsID', notificationSettings?.nSettingsID);
			setValue('senderName', notificationSettings?.senderName);
			setValue('senderEmail', notificationSettings?.senderEmail);
			setValue('emailHost', notificationSettings?.emailHost);
			setValue('smtPort', notificationSettings?.smtPort);
			// setValue('emailPassword', notificationSettings?.emailPassword);
			setValue('eTimeOut', notificationSettings?.eTimeOut);
			setValue('enableSSL', notificationSettings?.enableSSL);
			setValue('enableNotification', notificationSettings?.enableNotification);
			setValue('emailUserName', notificationSettings?.emailUserName);

		}
	}, [notificationSettings, setValue]);

	useEffect(() => {
		if (notificationSettingsConfig) {
			setGenralConfiguration(notificationSettingsConfig ?? [])
		}
	}, [notificationSettingsConfig])


	const updateGeneralConfig = (id: number, val: string) => {
		let tempGeneralConfiguration = [...GenralConfiguration]
		let tempGeneralConfigurationIndex = tempGeneralConfiguration.findIndex((item) => item.templateId === id)

		let tempObject = { ...tempGeneralConfiguration[tempGeneralConfigurationIndex] }
		tempObject['clearNotificationHistoryOlder'] = Number(val)
		tempGeneralConfiguration[tempGeneralConfigurationIndex] = tempObject
		setGenralConfiguration(tempGeneralConfiguration)
		//console.log("GETT" ,tempGeneralConfiguration[tempGeneralConfigurationIndex] )

	}

	const updateGeneralConfigStatus = (id: number) => {
		let tempGeneralConfiguration = [...GenralConfiguration]
		let tempGeneralConfigurationIndex = tempGeneralConfiguration.findIndex((item) => item.templateId === id)

		let tempObject = { ...tempGeneralConfiguration[tempGeneralConfigurationIndex] }
		tempObject['isClearNotificationHistory'] = !tempObject.isClearNotificationHistory
		tempGeneralConfiguration[tempGeneralConfigurationIndex] = tempObject
		setGenralConfiguration(tempGeneralConfiguration)
		//console.log("GETT" ,tempGeneralConfiguration[tempGeneralConfigurationIndex] )

	}

	const [loading2, setloading2] = useState(false)

	const updateGeneralConfigSettings = () => {
		if (loading2) return
		setloading2(true)
		const Success = (res: any) => {
			console.log("SD", res)
			fetch_notification_settings_configuration(dispatch);
			SuccesToast(res?.response?.data);
			setloading2(false);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response);
			setloading2(false);
		};
		let postData = {
			ClearSetting: GenralConfiguration
		}
		update_general_notification_settings(postData, Success, Failed)
	}


	const [testModal, settestModal] = useState(false)

	return (
		<>

			<div className='main-body-content'>
				<div className='white-card mb-30'>
					<div className='heading'>
						<h2 className='heading-main mb-0'>SMTP Settings</h2>
					</div>
					<div className='row'>
						<div className='col-12'>
							<form onSubmit={handleSubmit(onSubmitSMTPhandler)}>
								<div className='w-100'>
									<div className='row'>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>From Name</label>
														</div>
														<div className='col-7'>
															<input
																className='form-control grey-input'
																type='text'
																{...register('senderName')}
																placeholder=''
															/>
															<ErrorComponent errors={errors} name='senderName' title='From Name' />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>From Email</label>
														</div>
														<div className='col-7'>
															<input
																className='form-control grey-input'
																type='text'
																{...register('senderEmail')}
																placeholder=''
															/>
															<ErrorComponent errors={errors} name='senderEmail' title='From Email' />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Host</label>
														</div>
														<div className='col-7'>
															<input
																className='form-control grey-input'
																type='text'
																{...register('emailHost')}
																placeholder=''
															/>
															<ErrorComponent errors={errors} name='emailHost' title='Host' />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>SMTP Server Port</label>
														</div>
														<div className='col-7'>
															<input
																className='form-control grey-input'
																type='text'
																{...register('smtPort')}
																placeholder=''
															/>
															<ErrorComponent errors={errors} name='smtPort' title='SMTP Server Port' />
														</div>
													</div>
												</div>
											</div>
										</div>
										{/* <div className="col-6">
										<div className="form-group inline-input">
											<div className="w-100">
												<div className="row align-items-center">
													<div className="col-5">
														<label>Email Address</label>
													</div>
													<div className="col-7">
														<input className="form-control grey-input" type="text" {...register("emailUserName")} placeholder="" />
														<ErrorComponent  errors={errors} name="emailUserName" title='' />
													
													</div>
												</div>
											</div>
										</div>
									</div> */}
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Password</label>
														</div>
														<div className='col-7'>
															<input
																className='form-control grey-input'
																type='password'
																{...register('emailPassword')}
																// placeholder='***********'
															/>
															<ErrorComponent errors={errors} name='emailPassword' title='Password' />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Timeout ( in minutes )</label>
														</div>
														<div className='col-7'>
															{/* <input
																className='form-control grey-input'
																type='text'
																// {...register('eTimeOut')}
																placeholder=''
																onChange={(e: any) => {
																	const value = e.target.value.replace(/\D/g, '');

																}}



															/> */}
															<Controller
																name='eTimeOut'
																control={control}
																render={({ field: { value, onChange, onBlur } }) => (
																	<>
																		<input
																			type='text'
																			className='form-control grey-input'

																			id=''
																			value={value}
																			onChange={(e) => {
																				// let v = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9s]/g, '');
																				const value = e.target.value.replace(/\D/g, '');
																				onChange(value);
																			}}
																		/>
																	</>
																)}
															/>
															<ErrorComponent errors={errors} name='eTimeOut' title='Timeout' />
														</div>
													</div>
												</div>
											</div>
										</div>
											<div className="col-6">
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Email UserName</label>
														</div>
														<div className='col-7'>
															<input
																className='form-control grey-input'
																type='text'
																{...register('emailUserName')}
															/>
															<ErrorComponent errors={errors} name='emailUserName' title='Email UserName' />
														</div>
													</div>
												</div>
											</div>
											</div>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>USE TLS</label>
														</div>
														<div className='col-7'>
															<label className='switch'>
																<input type='checkbox' {...register('enableSSL')} />
																<span className='slider'></span>
																<span className='enable-text'>Enable</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Enable Notifications</label>
														</div>
														<div className='col-7'>
															<label className='switch'>
																<input type='checkbox' {...register('enableNotification')} />
																<span className='slider'></span>
																<span className='enable-text'>Enable</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />
								<div className='text-right'>
									<div className={`btn btn-outline-blue ${errors && (errors?.emailUserName || errors?.senderEmail) ? 'disableAndOpacity' : ''}`} onClick={() => settestModal(true)}>
										Test Connection
									</div>
									<button type='submit' className='btn btn-secondary ml-5'>
										<ButtonLoader loading={buttonLoading} text='Update' />
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className='white-card'>
					<div className='heading'>
						<h2 className='heading-main mb-0'>General Configuration</h2>
					</div>
					<form action="
				">
						{GenralConfiguration.map((item: any, key: any) => {
							return (
								<Fragment key={key}>
									<div className='row'>
										<div className='col-12'>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Template Code</label>
														</div>
														<div className='col-7 pos-rel'>

															<label>{item?.templateCode}</label>
														</div>
													</div>
												</div>
											</div>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Template Subject</label>
														</div>
														<div className='col-7 pos-rel'>

															<label>{item?.subject}</label>
														</div>
													</div>
												</div>
											</div>

											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Clear Notification</label>
														</div>
														<div className='col-7 pos-rel'>
															<label className='switch'>
																<input type='checkbox' checked={item?.isClearNotificationHistory} onChange={() => updateGeneralConfigStatus(item?.templateId,)} />
																<span className='slider'></span>
																<span className='enable-text'>Enable</span>
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className='form-group inline-input'>
												<div className='w-100'>
													<div className='row align-items-center'>
														<div className='col-5'>
															<label>Clear notification history older than</label>
														</div>
														<div className='dflex align-items-center col-7 pos-rel'>
															<input
																className='form-control grey-input'
																type='text'
																placeholder=''
																// defaultValue={item?.clearNotificationHistoryOlder}
																value={item?.clearNotificationHistoryOlder}
																onChange={(e) => {
																	const value = e.target.value.replace(/\D/g, '');

																	updateGeneralConfig(item?.templateId, value)
																}
																}
															/>
															<label className='ml-5'>Days</label>
														</div>
													</div>
												</div>
											</div>
										</div>

										<hr className='w-100' />

									</div>

								</Fragment>
							);
						})}
					</form>
					<div className='text-right'>

						<button type='submit' className='btn btn-secondary ml-5' onClick={updateGeneralConfigSettings}>
							<ButtonLoader loading={loading2} text='Update' />
						</button>
					</div>
				</div>
			</div>
			{
				<TestConnectionModal handleClose={() => settestModal(false)} open={testModal} />
			}
		</>
	);
};

export default NotificationSettings;
