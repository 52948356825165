import { DatePicker, TimePicker } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import StatusChipComponent from '../../../../../common/components/statusChipComponent'
import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom'
import MainLayout from '../../../../../common/layouts/MainLayout'
import { FailedToast, FormatDate, FormatTime, SuccesToast } from '../../../../../utils/utilFunctions'
import { fetch_all_usersSearch, get_allocationDetails_byUser } from '../../../../parking_request/store/action'
import { search_user } from '../../../../security/store/action'
import { fetch_visitor_detail } from '../../../../visitor/store/action'
import FilterBar from './FilterBar'
import ListTable from './ListTable'
import dayjs from 'dayjs'
import Request from '../../../../../config/api'
import { DEFINITION_URL, REALTIME_MONITOR_TRANSACTION, RUNVEHICLE_SERVICE, VACANT_BAYLIST } from '../../../../../config/endpoint'
import { fetch_siteDDL, fetch_zoneDDL } from '../../../../../common/general/action'
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks'
import ButtonLoader from '../../../../../common/components/ButtonLoader'
import { find_cars_search } from '../../../../findvehicles/store/action'

const UserType = () => {
	const dispatch = useAppDispatch()

  useEffect(() => {
    const controller = new AbortController();

    fetch_zoneDDL(dispatch)
    fetch_siteDDL(dispatch,controller?.signal)
  }, [])
  
	const GENERAL_DDL = useAppSelector((state) => state?.general);

  const [usersArr, setUsersArr] = useState<any>();
	const [usersList, setUsersList] = useState<any>();
	const [selectedUser, setselectedUser] = useState<any>();
  const [dateVal, setDateVal] = useState<any>()
  const [zoneVal, setZoneVal] = useState([])
  const [allocatedData, setAllocatedData] = useState<any>([])
  // const [avalAllocates, setAvailAllocates] = useState<any>([])
  const [availedBays, setAvailedBays] = useState<any>([])
  const [siteID, setSiteID] = useState(0)
  const [zoneID, setZoneID] = useState(0)
  const [bayID, setBayID] = useState(0)
  const [bayType, setBayType] = useState(0)
  // const [visitID, setVisitID] = useState(new Date())
  // const [transactionID, setTransactionID] = useState(new Date())
  const [selectedAllocation, setSelectedAllocation] = useState<any>()
  const [selectedVehicle, setSelectedVehicle] = useState<any>()
  const [vehArr, setVehArr] = useState<any>();
	const [vehList, setVehList] = useState<any>();
	const [exitTime, setExitTime] = useState<any>()
	const [exitDur, setExitDur] = useState<any>()

  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [vehExist, setVehExist] = useState(false)
  // return (
  //   <MainLayout>
  //     <FilterBar/>
  //     <ListTable/>
  //   </MainLayout>
  // )


  const promiseUserOptions = (inputValue: string) =>
  new Promise<any[]>((resolve) => {
    resolve(handleUserInputChange(inputValue));
  });

const handleUserInputChange = async (key?: string) => {
  let Response = await fetch_all_usersSearch(key);
  console.log('RESPONSE', Response);
  let data = Response?.response?.data ?? [];

  let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: `${item?.plateNumber} (${item?.fullName})`, object: item }));
  // let Options = data

  console.log('options', Options);
  setUsersList(data);
  setUsersArr(Options || []);
  // setUserVehicleList([])

  return Options;
};

const checkIfVehExist = async (key?: string) => {
  let postData = {
"SearchText": key ? key : '',
'filters': {
  'query': ''
},
"pagination": {
  "pageNo": 1,
  "limit": 1000
}
}
  let Response = await find_cars_search(postData)
console.log('RESPONSE',Response)
  let data = Response?.response?.data ?? []

  // let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: item?.firstName }))
  let Options = data
console.log("options", Options)
  setVehExist(data&&data?.length>0 ? true : false)
  
}

const handleUserChange = (val: any) => {
  checkIfVehExist(val?.object?.plateNumber)
  console.log('val=>',val)
  
  let data: any = usersList.find((item: any) => item===val?.object);
  let forData = {
    'filters': {
      'query': `userUniqueID = ${data?.userUniqueID}`,
    },
    'pagination': {
      'pageNo': 1,
      'limit': 1,
    },
  };
  if (data) {
    console.log(data)
    setselectedUser(data);
    setSelectedAllocation(null)
    setDateVal(null)
    if (data?.isVisitor) {
      fetch_visitor_detail(data?.userUniqueID)?.then((res: any) => {
        let resp = res?.response?.data;
        
      });
    } else {
      search_user(forData)?.then((res: any) => {
        let resp = res?.response?.data[0];
        
      });
    }
    // setVisitID(new Date())

  } else {}
};


const promiseUserOptionsEXIT = (inputValue: string) =>
  new Promise<any[]>((resolve) => {
    resolve(handleUserInputChangeEXIT(inputValue));
  });

const handleUserInputChangeEXIT = async (key?: string) => {
  const controller = new AbortController();

  let Response = await Request('post',`${DEFINITION_URL}vehicle-entry-details${key==='' ? '' : `?PlatNo=${key}`}`,undefined,controller?.signal)
  
  console.log('RESPONSE', Response);
  let data = Response?.response?.data ?? [];

  let Options = data.map((item: any) => ({ value: item?.vtid, label: `${item?.plateNo} (${dayjs(item?.transactionDateTime).format('YYYY-MM-DD HH:mm:ss')})`, object: item }));
  // let Options = data

  console.log('options', Options);
  setVehList(data);
  setVehArr(Options || []);
  // setUserVehicleList([])

  return Options;
};

const handleUserChangeExit = (val:any) =>{
  let data: any = vehList.find((item: any) => item===val?.object);
  setSelectedVehicle(data)
  setSiteID(data?.fK_SiteID)
  setBayID(data?.refBay_Id)
  setBayType(data?.refBay_type)
}


const getAllocation = (data:any, dateVal:any) =>{
  let formData =  {
                    "IsVisitor": data?.isVisitor ? true : false,
                    "UserUniqueID": data?.userUniqueID
                  }
  const dateCalc = (el:any) =>{
    let d = moment(dateVal)

    if(el){
      if((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2)){
        let strD = moment(el?.startDate).format('YYYY-MM-DD')
        let str = moment(`${strD}  ${el?.stratTime ? el?.stratTime : ''}`)
        let endD = moment(el?.endDate).format('YYYY-MM-DD')
        let end = moment(`${endD} ${el?.endTime ? el?.endTime : ''}`)
        return d?.diff(str) > 0 && d?.diff(end) < 0
      } 
      else 
        return false
      
    }
    else 
      return false
  }

  const SuccesFx = (res:any) =>{
    console.log(res)
    setAllocatedData(res?.response)
    if(dateVal && res?.response && res?.response?.length>0){
      let d = moment(dateVal)
      console.log(res?.response?.map((el:any)=>{
        let strD = moment(el?.startDate).format('YYYY-MM-DD')
        let str = moment(strD+" "+el?.stratTime)
        let endD = moment(el?.endDate).format('YYYY-MM-DD')
        let end = moment(endD+" "+el?.endTime)
        return `${d?.diff(str)}=${d?.diff(end,'day')}`
      }))

      setAllocatedData(res?.response?.filter((el:any)=> dateCalc(el)
      ))

    }
      // let startArr = res?.response?.map((el:any) => 
      // ((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2) && el?.startDate) ? new Date(dayjs(el?.startDate).format('YYYY-MM-DD')) : '')
      // let endArr = res?.response?.map((el:any) => 
      // ((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2) && el?.endDate) ? new Date(dayjs(el?.endDate).format('YYYY-MM-DD')) : '')

      // var tempMaxDate = dayjs(Math.max.apply(null,endArr)).add(1,'d').format('YYYY-MM-DD') 
      // var tempMinDate = dayjs(Math.min.apply(null,startArr)).subtract(1,'d').format('YYYY-MM-DD')
      // var currD = dayjs().subtract(1,'day').format('YYYY-MM-DD')

      // var maxDate = dayjs(tempMaxDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : tempMaxDate;
      // var minDate = dayjs(currD).diff(dayjs(tempMinDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : tempMinDate;

      // if((props?.selectAllocation || selectedData) && allocatedData && allocatedData?.length>0 ){
      //     let allocateObj:any = selectedData ? selectedData : allocatedData?.find((el:any)=>el.parkingID===props?.selectAllocation)
      //     let maxDate1 = dayjs(allocateObj?.endDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : dayjs(allocateObj?.endDate).format('YYYY-MM-DD');
      //     let minDate2 = dayjs(currD).diff(dayjs(allocateObj?.startDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : dayjs(allocateObj?.startDate).format('YYYY-MM-DD')
      //console.log('Max2',maxDate1,'min2',minDate2)
      //     props?.setIsAllocationAvail([minDate2, maxDate1])
      // }else if(props?.setIsAllocationAvail)
      //     props?.setIsAllocationAvail([minDate, maxDate])
      //console.log('MaxDate',maxDate,'minDAte',minDate)
      //console.log('StrArr',startArr,'EndArr',endArr)
      //console.log('PROH',prohDate)
  }
  const FailedFx = (res:any) =>{
    
  }
  get_allocationDetails_byUser(formData, SuccesFx, FailedFx)

}

const selectLevel = (e:any) =>{
  if(e?.site){
    setSiteID(e?.site)
  // if(e?.site)
  //   setSiteID(GENERAL_DDL?.SITE_DDL?.find((el:any)=> el?.value===e?.site)?.stRefrenceID??0)
  
  }
}

const getAvailedBayList = (v:any, bay?:any) =>{
  if(v)
    setZoneID(GENERAL_DDL?.ZONE_DDL?.find((el:any)=> el?.value===v)?.stRefrenceID??0)
  setBayID(0)
  setBayType(0)
  
  Request('post',`${VACANT_BAYLIST}${v}`,undefined)?.then(res => {
    setAvailedBays(res?.response?.data)
    if(bay){
      setBayID(res?.response?.data?.find((el:any)=> el?.bayID===bay )?.refbayid)
      setBayType(res?.response?.data?.find((el:any)=> el?.bayID===bay )?.refbaytypeid)
    }
  })
  
              
}

const onSubmitEntry = () =>{
  setLoading1(true)
  const success = (res:any) =>{
    SuccesToast(res?.response?.data)
    setLoading1(false)
  }
  const failed = (err:any) =>{
    FailedToast(err?.response?.appData)
    setLoading1(false)
  }

  let vID = Math.round(((Date.now()+Number.EPSILON)/10000)+Math.random()) 
  let postdata = {
    "ID": Date.now(),
    "timestamp": moment(dateVal).format('YYYY-MM-DDTHH:mm:ss'),
    "type":"entry",
    "visit_id":vID,
    "SiteID":siteID,
    "bay_id":bayID,
    "bay_type":bayType,
    "dwell":"00:00:00",
    "plate_text":'',
    "plate_confidence":95    
}

Request('post',`${REALTIME_MONITOR_TRANSACTION}action`,postdata,undefined,success,failed)
setTimeout(() => {  
let postdata2 = {
  "ID": Date.now(),
  "timestamp": moment(dateVal).add(1,'minute').format('YYYY-MM-DDTHH:mm:ss'),
  "type":"plate_found",
  "visit_id":vID,
  "SiteID":siteID,
  "bay_id":bayID,
  "bay_ref_id":bayID,
  "bay_type":bayType,
  "dwell":"00:01:00",
  "plate_text":`${selectedUser?.vehicleCategory}${selectedUser?.plateNumber}`,
  "plate_confidence":95    
}
  Request('post',`${REALTIME_MONITOR_TRANSACTION}action`,postdata2,undefined,success,failed)

}, 10050);


}

const onRunService = () =>{
  const success = (res:any) =>{
    SuccesToast(res?.response?.data)
  }
  const failed = (err:any) =>{
    FailedToast(err?.response?.appData)
  }
  
  Request('get',RUNVEHICLE_SERVICE,undefined,undefined,success,failed)
}

const onSubmitExit = () =>{
  setLoading2(true)
  const success = (res:any) =>{
    SuccesToast(res?.response?.data)
    setLoading2(false)
  }
  const failed = (err:any) =>{
    setLoading2(false)
    FailedToast(err?.response?.appData)
  }
  // let vID = Math.round(((parseInt(moment(visitID).format('YYYYMMDDHHmmss'))+Number.EPSILON)/10000)+Math.random()) 
  let h = moment(exitDur).get('hours')
  let m = moment(exitDur).get('minutes')
  let s = moment(exitDur).get('second')

  // let durD = moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime),'days')
  // let durM = moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime),'months')
  // let durY = moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime),'years')
  // let durH = moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime),'hours')
  // let durm = moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime),'minutes')
  // let durs = moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime),'seconds')
  // let dur = moment.duration({
  //   'days' : durD, 'months' : durM, 'years' : durY, 'hours' : durH, 'minutes' : durm, 'seconds' : durs
  // })

  let newTime = moment(selectedVehicle?.transactionDateTime).add(h,'hours').add(m,'minutes').add(s,'seconds')

  let postdata = {
    "ID"        : Date.now(),
    "timestamp" : exitDur && exitDur!=='' ? moment(newTime).format('YYYY-MM-DDTHH:mm:ss') : moment(exitTime).format('YYYY-MM-DDTHH:mm:ss'),
    "type"      : "exit",
    "visit_id"  : selectedVehicle?.visit_Id,
    "SiteID"    : selectedVehicle?.fK_SiteID,
    "bay_id"    : selectedVehicle?.refBay_Id,
    "bay_type"  : selectedVehicle?.refBay_type,
    "dwell"     : exitDur && exitDur!=='' ? moment(exitDur).format('HH:mm:ss') : 
    moment(moment(exitTime).diff(moment(selectedVehicle?.transactionDateTime))).format('HH:mm:ss'),
    "plate_text": selectedVehicle?.plateNo,
    "plate_confidence":95    
}
console.log(postdata)

Request('post',`${REALTIME_MONITOR_TRANSACTION}action`,postdata,undefined,success,failed)

}



  return(
    <div className='main-body-content'>
      <div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main mb-0'>Vehicle Entry</h2>
				</div>
				<div className='row'>
						<div className='form-group inline-input col-12'>
							<label>Select Vehicle</label>
                      <AsyncSelect
												className={'common-select common-select-grey col-9'}
												styles={{
													control: (styles) => ({
														...styles,
														padding: '8px 0px',
														background: 'var(--grey-primary)',
														border: 'none',
													}),
													placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
												}}
												placeholder='Find User or Vehicle'
												cacheOptions={usersArr}
												defaultOptions={usersArr}
												value={selectedUser ?
													usersArr?.find((el:any)=>el.userUniqueID===selectedUser.userUniqueID && el.object===selectedUser) : 
													 null}
												getOptionLabel={(o) => o.label}
												getOptionValue={(o) => o.object}
												formatOptionLabel={(o) => (
													<div className='dflex justify-space-between align-items-center'>
														<div className='dflex col-dir gap-5'>
															<div>
																<span
																	style={{
																		fontSize: '.85rem',
																		fontWeight: 600,
																		textTransform: 'capitalize',
																	}}>
																	{o?.object.fullName} -{' '}
																</span>
																<small style={{ fontStyle: 'italic' }}>{o?.object.emailID}</small>
															</div>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																	width: '100%',
																	// justifyContent: 'space-between',
																}}>
																<span style={{ fontSize: '.85rem', fontWeight: 600 }}>
																	{o?.object.plateNumber} -{' '}
																</span>
																<small style={{ fontStyle: 'italic' }}>
																	{o?.object.placeOfRegistration}-
																</small>
																<small style={{ fontStyle: 'italic' }}>
																	{o?.object.vehicleCategory}
																</small>
															</div>
														</div>

														<div>
															<StatusChipComponent text={o?.object.isVisitor ? 'Visitor' : 'Employee'} />
														</div>
													</div>
												)}
												
												loadOptions={promiseUserOptions}
												onChange={handleUserChange}
												
												isSearchable={true}
												isClearable={true}
											/>                
						</div>
            <div className='form-group inline-input col-12'>
              <label className='col-2'>Entry Date Time</label>
              <DatePicker
                className='form-control grey-input col-9'
                showTime
                onChange={(date)=>{ setDateVal(date)
                  getAllocation(selectedUser, date);

                }}
                value={dateVal ? moment(dateVal) : null}
                // disabledDate={(current)=>(current > moment()) }
                allowClear={false}
                format={'DD-MMM-YYYY - hh:mm:A'}
              />
            </div>
            <div className='form-group inline-input col-12'>
              <label>Allocations</label>
              <ReactSelect
              isDisabled={vehExist}
            options={allocatedData}
            styles={{
              control: (styles) => ({
                ...styles,
                padding: '8px 0px',
                marginBottom: '10px',
                background: 'var(--grey-primary)',
                border: 'none',
              }),
              placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
            }}
            className={'common-select common-select-grey col-9'}
            placeholder={'Previous Allocations'}
            getOptionValue={(o:any)=> o?.parkingID}
            formatOptionLabel={(o) => (
              <div className='dflex justify-space-between align-items-center'>
                <div className='dflex col-dir gap-5'>
                  <div style={{ display:'flex', flexDirection:'column' }}>
                    <span
                      style={{
                        fontSize: '.85rem',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                      }}>
                      {`${FormatDate(o?.startDate, 'DD-MMM-YYYY')} (${FormatTime(o?.stratTime, 'hh:mm a')}) - 
                      ${FormatDate(o?.endDate, 'DD-MMM-YYYY')} (${FormatTime(o?.endTime, 'hh:mm a')})`}{' '}
                    </span>
                    <small style={{ fontStyle: 'italic' }}>{o?.zoneName}{o?.bayName?`/ Bay : ${o?.bayName}` : ''}</small>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      // justifyContent: 'space-between',
                    }}>
                    <span style={{ fontSize: '.85rem', fontWeight: 600 }}>
                      {`${o?.plateNumber} - ${o?.vehicleCategory} - ${o?.placeOfRegistration}`} {'  '}
                    </span>
                    <small style={{ fontStyle: 'italic' }}>
                      Approved On : {FormatDate(o?.approvedOn, 'DD-MMM-YYYY hh:mm  a')}
                    </small>
                    
                  </div>
                </div>

                <div>
                  <StatusChipComponent text={`Parking ID : ${o?.parkingID}`} />
                </div>
              </div>
            )}
            isMulti={false}
            isClearable
            value={selectedAllocation ? allocatedData?.find((el:any) => el.parkingID===selectedAllocation?.parkingID) : null}
            onChange={(v:any)=>{
              console.log('ALLOCATED=>',v)
              setAvailedBays([])
              if(v?.fK_SiteID)
                setSiteID(v?.fK_SiteID)
              if(v?.fK_ZoneID)
                getAvailedBayList(v?.fK_ZoneID,v?.fK_BayID)
                setSelectedAllocation(v)
            }}
              />
            </div>	
            <div className={`row col-12 ${vehExist ? 'disableAndOpacity2' : ''}`}>
              <div className='col-6'>
                  <TreeSelectCustom
                    selectedZones={zoneVal} setselectedZones={setZoneVal}
                    styleSx={{ maxWidth:'unset' }} grey 
                    showAll={selectLevel}
                    onchangeFx={(e:any)=>{
                      setSelectedAllocation(null)

                      getAvailedBayList(e?.value)
                    }}
                  />
              </div>
              <div className='col-6'>
              <ReactSelect
            options={availedBays}
            isDisabled={vehExist}
            className={'common-select common-select-grey'}
            placeholder={'Select Bay'}
            getOptionValue={(o:any)=> o?.bayID}
            getOptionLabel={(o:any)=> o?.bayName}
            isMulti={false}
            value={bayID ? availedBays?.find((el:any) => el?.refbayid===bayID) : null}
            isClearable
            onChange={(v)=>{
              setBayID(v?.refbayid)
              setBayType(v?.refbaytypeid)
            }}
              />
              </div>
            </div>					
					</div>
          { vehExist && <p className='hookform-error-message'>Vehicle Exist in parking</p>}
          <hr/>
          <div>
            <button className='btn btn-secondary ml-5' onClick={onSubmitEntry}>
							<ButtonLoader
                loading={loading1} text={'Save'}
              />
						</button>
            <button className='btn  btn-outline-blue close-modal-button ml-5' onClick={()=>{
              setAllocatedData(null)
              setAvailedBays([])
              setDateVal(null)
              setSelectedAllocation(null)
  setUsersArr([])
	setUsersList([])
	setselectedUser(null)
  setZoneVal([])
  setSiteID(0)
  setZoneID(0)
  setBayID(0)
  setBayType(0)
  setSelectedVehicle(null)
            }}>
							<ButtonLoader
                loading={loading1} text={'Clear'}
              />
						</button>		
            <button onClick={onRunService} className='btn btn-primary ml-5'>
            <ButtonLoader
                loading={loading2} text={'Run Service'}
              />
						</button>					
          </div>
			</div>

      <div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main mb-0'>Vehicle Exit</h2>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='form-group inline-input'>
							<label>Select Vehicle</label>
              <AsyncSelect
												className={'common-select common-select-grey col-9'}
												styles={{
													control: (styles) => ({
														...styles,
														padding: '8px 0px',
														background: 'var(--grey-primary)',
														border: 'none',
													}),
													placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
												}}
												placeholder='Find User or Vehicle'
												cacheOptions={vehArr}
												defaultOptions={vehArr}
												value={selectedVehicle ?
													vehArr?.find((el:any)=>el.vtid===selectedVehicle.vtid && el.object===selectedVehicle) : 
													 null}
												getOptionLabel={(o) => o.label}
												getOptionValue={(o) => o.object}
																								
												loadOptions={promiseUserOptionsEXIT}
												onChange={handleUserChangeExit}
                        isOptionDisabled={(o) => o.object?.plateNo ? false : true}

												isSearchable={true}
												isClearable={true}
                        menuPlacement={'top'}
											/>
						</div>						
					</div>
          <div className={`col-12 ${selectedVehicle ? '' :'disableAndOpacity2'}`}>
            <div className='form-group inline-input'>
              <label>Exit Timestamp (date-time vehicle exited)</label>
              <DatePicker
              className='form-control grey-input col-9'
              showTime
                onChange={(time)=>{
                  setExitTime(time)
                }}
                value={exitTime ? moment(exitTime) : null}
                disabledDate={(curr:any)=> { return (curr < dayjs(selectedVehicle?.transactionDateTime).subtract(1,'d').endOf('d')) }}
              />
            </div>
          </div>
          <div className={`col-12`}>
            <div className='form-group inline-input'>
              <label>Dwell duration (optional, instead of exit timestamp)</label>
              <TimePicker
              className='form-control grey-input col-9'
              format={'HH:mm:ss'}
                onChange={(time)=>{
                  setExitDur(time)
                }}
                value={exitDur ? moment(exitDur) : null}
              />
            </div>
          </div>
          
				</div>
        <hr/>
          <div>
            <button onClick={onSubmitExit} className='btn btn-secondary ml-5'>
            <ButtonLoader
                loading={loading2} text={'Save'}
              />
						</button>	
            <button className='btn  btn-outline-blue close-modal-button ml-5' onClick={()=>{
              setAllocatedData(null)
              setAvailedBays([])
              setDateVal(null)
              setSelectedAllocation(null)
  setUsersArr([])
	setUsersList([])
	setselectedUser(null)
  setZoneVal([])
  setSiteID(0)
  setZoneID(0)
  setBayID(0)
  setBayType(0)
  setSelectedVehicle(null)
  setExitTime(null)
  setVehArr([])
  setVehList([])
            }}>
							<ButtonLoader
                loading={loading1} text={'Clear'}
              />
						</button>
            <button onClick={onRunService} className='btn btn-primary ml-5'>
            <ButtonLoader
                loading={loading2} text={'Run Service'}
              />
						</button>	
            							
          </div>
			</div>
    </div>
  )
}

export default UserType