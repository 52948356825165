import React, { Fragment, useEffect, useState } from 'react';

import ModalLayout from '../../../../common/layouts/ModalLayout';
import { FormatDate, FormatTime } from '../../../../utils/utilFunctions';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import FormInput from '../../../../common/components/FormInput';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

const ViewModal = (props: Props) => {
	const [tabVal, setTablVal] = useState(1);

	const [vehicles, setVehicles] = useState([]);
	const [allocatedVehicles, setAllocatedVehicles] = useState([]);

	useEffect(() => {
		if (props?.Data) {
			let allocatedvehicles: any = [];
			let vehicles: any = [];

			props.Data?.vehicles?.forEach((item: any) => {
				if (item?.allocationID) {
					allocatedvehicles.push(item);
				} else {
					vehicles.push(item);
				}
			});
			setAllocatedVehicles(allocatedvehicles);
			setVehicles(vehicles);
			
		}
	}, [props?.Data]);

	return (
		<ModalLayout
			open={props.open}
			handleClose={props.handleClose}
			subtitle={tabVal === 1 ? 'User Details ' : 'Vehicle Details'}
			title={`${props?.Data?.firstName}`}
			big>
			<form action=''>
				<div className='form-tabs'>
					<ul>
						<li>
							<a  className={`${!tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(1)}>
								User Info
							</a>
						</li>
						<li>
							<a  className={`${tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(0)}>
								Vehicle Info
							</a>
						</li>
					</ul>
				</div>
				<div className='modal-body-content' style={{ maxHeight: '55vh', padding: '0px 5px' }}>
					<div className='modal-body '>
						<div className='form-w-label modal-form'>
							{tabVal ? (
								<>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>User Name</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.firstName}
													/>
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group'>
													<label>Email</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.emailID}
													/>
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Contact Number</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.contactNo}
													/>
												</div>
											</div>

											{/* <div className='col-6'>
												<div className='form-group'>
													<label> Date of Birth</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={dayjs(props?.Data?.dob).format('YYYY-MM-DD')}
													/>
												</div>
											</div> */}
										</div>

										<div className='row'>

											<div className='col-6'>
												<div className='form-group'>
													<label>Department </label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.depName}
													/>
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group'>
													<label>Designation </label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.desName}
													/>
												</div>
											</div>

										</div>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Priority Level</label>

													<input
														type='number'
														min="1"
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.fK_PriorityLevel}
													/>
												</div>
											</div>

											<div className='col-6'>
												<div className='form-group'>
													<label>Site</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.siteName}
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Gender</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.gender==='1' || props?.Data?.gender===1 ? 'Male' : 
														props?.Data?.gender==='2' || props?.Data?.gender===2 ? 'Female' : ''}
													/>
												</div>
											</div>

											<div className='col-6'>
												<div className='form-group'>
													<label>User Type</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.userType}
													/>
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Escalation 1</label>
													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={`${props?.Data?.escalationUser1} - ${props?.Data?.escalationUser1_emailID}`}
													/>
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group'>
													<label>Group Company </label>

													<input
														type='text'
														placeholder=''
														className='form-control'
														disabled
														value={props?.Data?.gCompanyName}
													/>
												</div>
											</div>

											<Fragment>
												{/**@hardcoded  hidden hardcoded input fields with default value */}
												<input
													type='number'
													value={props?.Data?.fK_UserStatus}
													placeholder=''
													disabled
													className='none'
													defaultValue={1}
												/>
											</Fragment>
										</div>
								</>
							) : (
								<Fragment>
									<AllocatedVehicle allocatedVehicles={allocatedVehicles} />
									<UnAllocatedVehicle vehicles={vehicles} />
								</Fragment>
							)}
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					<button className='btn btn-outline-blue close-modal-button' onClick={props.handleClose}>
						Close
					</button>
				</div>
			</form>
		</ModalLayout>
	);
};

export default ViewModal;

function AllocatedVehicle({ allocatedVehicles = [] }: any) {
	console.log('ALLOCATEDVEHICLE=>',allocatedVehicles)
	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '2%' }}>
			{allocatedVehicles && allocatedVehicles?.map((el: any, k: number) => {
				console.log('ALLocationVEh',el)
				return (
					<Fragment key={k}>
						<Accordion
							defaultExpanded={k === 0 ? true : false}
							sx={{
								boxShadow: 'none',
								background: 'transparent',
								border: '1px solid var(--lightgrey)',
							}}>
							<AccordionSummary
								expandIcon={<i className='fa fa-chevron-down' />}
								aria-controls='panel1bh-content'
								sx={{
									margin: 0,
									height: '20px',
									borderBottom: '1px solid var(--lightgrey)',
									borderTop: 'none',
								}}>
								<div className='row w-100'>
									<div className='dflex justify-space-between w-100 align-items-center '>
										<div className='col-12'>
											<h2 className='border-title ml-5'>
												{`${el?.plateNumber} | `}
												<i>
													{`${FormatDate(el?.startDate, 'DD-MMM-YYYY')} -
																	${FormatDate(el?.endDate, 'DD-MMM-YYYY')}`}
												</i>
											</h2>
										</div>
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group inline-input'>
											<label>Allocation No </label>
											<input className='form-control ' type='text' id='' value={el?.allocationID} disabled />
										</div>
										<div className='form-group inline-input'>
											<label>Allocation Starts </label>
											<input
												className='form-control'
												type='text'
												id=''
												value={`${FormatDate(el?.startDate, 'DD-MMM-YYYY')} ${FormatTime(
													el?.startTime,
													'HH:mm a'
												)}`}
												disabled
											/>
										</div>
										{
											el?.allocationDetails &&
											<div className='form-group inline-input'>
												<label>Approved On </label>
												<input
													className='form-control'
													type='text'
													id=''
													disabled
													value={`${
														FormatDate(el?.allocationDetails[0]?.approvedOn, 
															'DD-MMM-YYYY HH:mm a')}`}
												/>
											</div>
										}
										{
											el?.allocationDetails &&
											<div className='form-group inline-input'>
												<label>Approved By </label>
												<input className='form-control' type='text' id='' disabled value={el?.allocationDetails[0]?.approvedBy} />
											</div>
										}
										<div className='form-group inline-input opacity-7'>
											<label>Request Type</label>

											<input className='form-control' type='text' id='' disabled value={el?.requestName} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Site</label>

											<input className='form-control' type='text' id='' disabled value={el?.allocatedSiteName} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Zone</label>

											<input className='form-control' type='text' id='' disabled value={el?.allocatedzoneName} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Bay</label>

											<input
												className='form-control'
												type='text'
												id=''
												disabled
												value={el?.allocatedBayName ?? '-'}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Allocation Expiry</label>

											<input
												className='form-control'
												type='text'
												id=''
												disabled
												value={`${FormatDate(el?.endDate, 'DD-MMM-YYYY')} ${FormatTime(el?.endTime, 'HH:mm a')}`}
											/>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group inline-input'>
											<label>Plate Number </label>
											<input className='form-control' type='text' id='' value={el?.plateNumber} disabled />
										</div>
										<div className='form-group inline-input'>
											<label>Vehicle Category </label>
											<input className='form-control' type='text' id='' disabled value={el?.vehicleCategory} />
										</div>
										<div className='form-group inline-input'>
											<label>Place of Reg</label>
											<input className='form-control' type='text' id='' disabled value={el?.placeOfRegistration} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Brand</label>

											<input className='form-control' type='text' id='' disabled value={el?.brand} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Color</label>

											<input className='form-control' type='text' id='' disabled value={el?.colour} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Description</label>

											<input className='form-control' type='text' id='' disabled value={el?.descriptions} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Plate Sticker</label>

											<input className='form-control' type='text' id='' disabled value={el?.plateSticker??""} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Last Parked On</label>

											<input
												className='form-control'
												type='text'
												id=''
												disabled
												value={FormatDate(el?.lastTransactionDate, 'DD-MMM-YYYY HH:mm a')}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Last Parked Zone</label>

											<input
												className='form-control'
												type='text'
												id=''
												disabled
												value={el?.lastTransactionZoneName}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Last Parked Bay</label>

											<input
												className='form-control'
												type='text'
												id=''
												disabled
												value={el?.lastTransactionBayName}
											/>
										</div>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</Fragment>
				);
			})
			}
		</div>
	);
}

function UnAllocatedVehicle({ vehicles = [] }: any) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				marginBottom: '2%',
			}}>
			{vehicles&&vehicles?.map((el: any, k: number) => {
				return (
					<Fragment key={k}>
						<Accordion
							defaultExpanded={k === 0 ? true : false}
							sx={{
								boxShadow: 'none',
								background: 'transparent',
								border: '1px solid var(--lightgrey)',
							}}>
							<AccordionSummary
								expandIcon={<i className='fa fa-chevron-down' />}
								aria-controls='panel1bh-content'
								sx={{
									margin: 0,
									height: '20px',
									borderBottom: '1px solid var(--lightgrey)',
									borderTop: 'none',
								}}>
								<div className='row w-100'>
									<div className='dflex justify-space-between w-100 align-items-center '>
										<div className='col-12'>
											<h2 className='border-title ml-5'>
												{`${el?.plateNumber}  `}
												<i>
													{`${FormatDate(el?.startDate, 'DD-MMM-YYYY')} 
																	${FormatDate(el?.endDate, 'DD-MMM-YYYY')}`}
												</i>
											</h2>
										</div>
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group inline-input'>
											<label>Plate Number </label>
											<input className='form-control' type='text' id='' value={el?.plateNumber} disabled />
										</div>
										<div className='form-group inline-input'>
											<label>Vehicle Category </label>
											<input className='form-control' type='text' id='' disabled value={el?.vehicleCategory} />
										</div>
										<div className='form-group inline-input'>
											<label>Place of Reg</label>
											<input className='form-control' type='text' id='' disabled value={el?.placeOfRegistration} />
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group inline-input opacity-7'>
											<label>Brand</label>

											<input className='form-control' type='text' id='' disabled value={el?.brand} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Color</label>

											<input className='form-control' type='text' id='' disabled value={el?.colour} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Description</label>

											<input className='form-control' type='text' id='' disabled value={el?.descriptions} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Plate Sticker</label>

											<input className='form-control' type='text' id='' disabled value={el?.plateSticker??''} />
										</div>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</Fragment>
				);
			})}
		</div>
	);
}
