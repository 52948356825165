import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../common/layouts/ModalLayout';

import { useAppDispatch } from './../../../../config/hooks';
import { fetch_departmentDDL, fetch_designationDDL, fetch_groupCompanyDDL, fetch_placeOfRegList, fetch_priorityLevelDDL, fetch_siteDDL } from '../../../../common/general/action';
import AddUser from './AddUser';
import AddVehicle from './AddVehicle';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

const AddModal = (props: Props) => {
	const [tabVal, setTablVal] = useState(1);

	let dispatch = useAppDispatch();

	useEffect(() => {
		const controller = new AbortController();
		// fetch_departmentDDL(dispatch, controller.signal);
		fetch_priorityLevelDDL(dispatch, controller.signal);
		fetch_siteDDL(dispatch, controller.signal);
		fetch_designationDDL(dispatch, controller.signal);
		fetch_groupCompanyDDL(dispatch, controller.signal);
		
		fetch_placeOfRegList(dispatch, controller.signal)

		return () => controller.abort();
	}, [dispatch]);

	return (
		<ModalLayout
			open={props.open}
			handleClose={props.handleClose}
			title={props.Data ? 'Edit User ' : 'Add User'}
			subtitle={tabVal ===1 ? "Edit user details" :"Edit user vehicle details"}
			big>
			<div className='form-tabs'>
				<ul>
					<li>
						<a  className={`${!tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(1)}>
							User Info
						</a>
					</li>
					{props?.Data && (
						<li>
							<a  className={`${tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(0)}>
								Vehicle Info
							</a>
						</li>
					)}
				</ul>
			</div>

			<div className='modal-body'>
				{tabVal === 1 ? (
					<AddUser Data={props?.Data} handleClose={props?.handleClose} />
				) : (
					<AddVehicle Data={props?.Data} handleClose={props?.handleClose} />
				)}
			</div>
		</ModalLayout>
	);
};

export default AddModal;


