import React from 'react';
import Select, { Options } from 'react-select';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface Iprops {
	name: string;
	options: any[];
	control: Control<FieldValues, any>;
	grey?: boolean;
	isMulti?: boolean;
	placeholder: string;
	onchangeFx?:(e:any)=>void;
	isOptionDisabled?: ((option: any, selectValue: Options<any>) => boolean) | undefined

}

const FormSelectMultiple = ({ name, control, options = [], grey, isMulti, placeholder, onchangeFx,isOptionDisabled }: Iprops) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field: { value, onChange, onBlur } }) => {
					return (
						<Select
							options={options}
							className={grey ? 'common-select common-select-grey' : 'common-select'}
							placeholder={placeholder}
							isMulti={isMulti}
                            value={options.filter((c) => value?.includes(c.value))??''}
                            onChange={(val) =>{ onChange(val.map((opt:any)=>opt.value)); onchangeFx && onchangeFx(val.map((opt:any)=>opt.value)) }}
							onBlur={onBlur}
							defaultValue={options.filter((c) => value?.includes(c.value))}
							// menuPortalTarget={document.body}
							// styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
							isOptionDisabled={isOptionDisabled}
						/>
					);
				}}
			/>
		</>
	);
};

export default FormSelectMultiple;
