import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import Users from './screens/users';
import { useAppDispatch } from './../../config/hooks';
import { RESET_STATE } from './store/userSlice';

const UserIndex = () => {

	let dispatch = useAppDispatch()
	const redirectedPath = useRedirect([
		{
			path: '',
			submoduleID: subModuleList.Users,
		},
	]);


	useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])

	return (
		<div className='navtabs__container'>
			<Routes>
				<Route
					path={''}
					element={
						<PermissionWrapper subModuleID={subModuleList.Users}>
							<Users />
						</PermissionWrapper>
					}
				/>
				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default UserIndex;
