import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../common/layouts/ModalLayout';
// import noimage from '../../../../assets/images/common/noimage.jpg';	
import { FailedToast, FormatDate, FormatTime, SuccesToast } from '../../../../utils/utilFunctions';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { fetch_Users_AllocatedParkingslist, revoke_approved_allocation, update_approved_allocations } from '../../store/action';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { useNavigate } from 'react-router-dom';
import ErrorComponent from '../../../../common/components/ErrorComponent';
import TreeSelectCustom from '../../../../common/components/tree_select/NewTreeSelectCustom';
import { fetch_BayDDL, fetch_BayTypeDDL } from '../../../../common/general/action';
import { get_reservedBayList } from '../../../parking_request/store/action';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import ReserveABay from '../../../parking_request/screens/NewParkingRequestModal/ReserveABay';
import { RangePickerProps, RangeType } from 'rc-picker/lib/RangePicker';
import { EventValue } from 'rc-picker/lib/interface';
import { DatePicker } from 'antd';
import AllocationChart from '../../../parking_request/screens/NewParkingRequestModal/AllocationChart';
import moment from 'moment';
import ImageView from '../../../../common/components/ImageView/ImageView';
import { subModuleList } from './../../../../config/subModuleList';
import StatusChipComponent from '../../../../common/components/statusChipComponent';
import { APPLICATION_SETTINGS_GENERAL } from '../../../../config/endpoint';
import Request from '../../../../config/api';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import FailedMessagePop from '../../../../common/components/FailedMessage';
import ActionConfirmBox from '../../../../common/components/ActionConfirmBox';
import CustomDatePicker from '../../../../common/components/CustomDatePicker';

const { RangePicker } = DatePicker;

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

type ddl = {
	label: string;
	value: any;
};
const yesterday = new Date(Date.now() - 86400000);

const schema = yup.object().shape({
	status: yup.number().default(0),
	StartDate: yup.date(),
	EndDate: yup
		.date()
		.when('status', { is: 1, then: yup.date() })
		.min(yesterday, 'Date cannot be in the past')
		.when('StartDate', (StartDate, yup) => StartDate && yup.min(StartDate, 'End date cannot be before start date')),
	StratTime: yup
		.string()
		.nullable()
		.when(['status', 'isPermenantParking'], {
			is: (status: number, isPermenantParking:boolean) => status===1 && !isPermenantParking,
			then: yup.string().test('to_time_test', 'Start time must be before End time', function (value) {
				console.log('VALUE=>', value, 'THISP', this.parent);
				const { EndTime }: any = this.parent;
				console.log('FROMTIME', EndTime);
				return isSameOrBefore(EndTime, value);
			}),
		}),
	EndTime: yup
		.string()
		.nullable()
		.when(['status', 'isPermenantParking'], {
			is: (status: number, isPermenantParking:boolean) => status===1 && !isPermenantParking,
			then: yup.string().test('from_time_test', 'End time must be after Start time', function (value) {
				const { StratTime }: any = this.parent;
				console.log('TOTIME', StratTime);
				return isSameOrBefore(value, StratTime);
			}),
		}),
	fK_ZoneID: yup.number().nullable().when('status', { is: 1, then: yup.number().required() }),
	fK_BayID: yup.number().nullable(),
	remarks: yup.string().required(),
	isPermenantParking: yup.boolean().nullable(),
});

const isSameOrBefore = (val: any, end: any) => {
	let d1 = moment(val,'HH:mm:ss',true).isValid() ? new Date(`01-01-2001 ${val}`) : moment(new Date(val)).format('HH:mm:ss')
	let d2 = moment(end,'HH:mm:ss',true).isValid() ? new Date(`01-01-2001 ${end}`) : moment(new Date(end)).format('HH:mm:ss')
	//console.log('DIFFERENCES',moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'minute'),
	//  moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'hours'),d1,d2)
	return moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'minute') > 0
};

const ViewEditModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [selectedZones, setselectedZones] = useState<any[]>([]);
	// const [reservedBaysOpen, setReservedBaysOpen] = useState(false)
	// const [reservedBayList, setReservedBayList] = useState([])
	// const [bayLoading, setBayLoading] = useState(true)
	const [date, setdate] = useState<any>([]);
	const [endOnlyDate, setEndOnlyDate] = useState<any>()
	const [rangeTime, setRangeTime] = useState<any>([]);
	const [selectedBay, setselectedBay] = useState<any>(null);
	const [selIsVisitor, setSelIsVisitor] = useState(false);
	const [selUserId, setSelUserId] = useState(null);
	const [isAllocationAvail, setIsAllocationAvail] = useState<any>([]);
	const [reqTp, setReqTp] = useState(0);
	const [prohDate, setProhDate] = useState<any>();

	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmData, setConfirmData] = useState<any>()
	
	const [openErrData, setOpenErrData] = useState<any>()
	const [openErr, setOpenErr] = useState(false)
	const cancelConfirm = () =>{ setConfirmModal(false); setLoading(false); }


	const statusArr:any = 
		[ 
			{ value : 'success', id : 1,	label : 'Approved'},
			{ value : 'success', id : 11,	label : 'Expired' }, 
			{ value : 'error', id : 4,	label : 'Revoked' }, 
			{ value : 'pending', id : 3,	label : 'Cancelled' },
			{ value : 'error', id : 6,	label : 'Blacklisted'},
			{ value : 'warning', id : 0, label : 'Pending' } ] 
	// [
	// 	{ value : 'warning', text : 'Pending' }, 
	// { value : 'success', text : 'Approved' }, 
	// { value : 'error', text : 'Rejected' }, 
	// { value : 'pending', text : 'Cancelled' },
	//  { value : 'error', text : 'Revoked' },
	//  { value : 'error', text : 'Blacklisted' } ]

	useEffect(() => {
		// const controller = new AbortController();
		getProhibittedDate();

		// fetch_BayDDL(dispatch, undefined, controller.signal)
		
		//console.log(props.Data,[moment(props.Data?.stratTime,['HH:mm:ss','HH:mm'],true).format('HH:mm:ss'), moment(props.Data?.endTime,['HH:mm:ss','HH:mm'],true).format('HH:mm:ss')])
		if (props.Data) {
			fetch_BayTypeDDL(dispatch,props.Data?.fK_SiteID);
			setValue('StartDate', dayjs(props.Data?.startDate).format('YYYY-MM-DD'));
			setValue('EndDate', dayjs(props.Data?.endDate).format('YYYY-MM-DD'));
			setValue('StratTime', props.Data?.isPermenantParking ? null : props.Data?.stratTime);
			setValue('EndTime', props.Data?.isPermenantParking ? null : props.Data?.endTime);
			setValue('fK_ZoneID', props.Data?.fK_ZoneID);
			setValue('fK_BayID', props.Data?.fK_BayID);
			setValue('isPermenantParking', props.Data?.isPermenantParking)
			if (props?.Data?.fK_BayID) setselectedBay({ value: props?.Data?.fK_BayID, label: props?.Data?.bayName });
			setSelIsVisitor(props.Data?.isVisitor);
			setSelUserId(props.Data?.fK_UserUniqueID);
			setdate([moment(props.Data?.startDate, 'YYYY-MM-DD'), moment(props.Data?.endDate, 'YYYY-MM-DD')]);
			setEndOnlyDate(moment(props.Data?.endDate, 'YYYY-MM-DD'))
			if(!props.Data?.isPermenantParking)
			if (dayjs(props.Data?.stratTime,['HH:mm','HH:mm:ss','hh:mm a']).isValid() && dayjs(props.Data?.endTime,['HH:mm','HH:mm:ss','hh:mm a']).isValid())
				setRangeTime([
					moment(props.Data?.stratTime, ['HH:mm:ss', 'HH:mm'], true),
					moment(props.Data?.endTime, ['HH:mm:ss', 'HH:mm'], true),
				]);
			setReqTp(props?.Data?.fK_RequestTypeID);
			setselectedZones([{ value: props.Data?.fK_ZoneID, label: props.Data?.zoneName }]);
		} else {
			clearErrors();
			reset();
		}
	}, [props]);

	const GENERAL_DDL = useAppSelector((state) => state?.general);

	const FINDVEHICLES = useAppSelector((state) => state?.findacar);
	const [status, setStatus] = useState(0);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
		watch,
		clearErrors,
	} = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema),
		// reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	const FailMsgConfirm = () =>{
		setConfirmModal(false)
		setConfirmData(null)
		setOpenErrData(null)
		setOpenErr(false);
	}

	const confirmApprove = () =>{
		setLoading(true)
		let postData = status ?
		{
			ParkingID : props?.Data?.parkingID,
			StartDate: !status ? props.Data?.StartDate : moment(confirmData?.StartDate).format('YYYY-MM-DD'),
			EndDate: !status ? props.Data?.EndDate : moment(confirmData?.EndDate).format('YYYY-MM-DD'),
			StratTime: 
				props?.Data?.isPermenantParking ? null :
					!status ? 
						moment(props.Data?.stratTime,'HH:mm:ss').isValid() ? props.Data?.stratTime : 
						moment(new Date(`01 Jan 2001 ${props.Data?.stratTime}`)).format('HH:mm:ss') :
						moment(confirmData?.StratTime,'HH:mm:ss').isValid() ? confirmData?.StratTime : 
						moment(new Date(`01 Jan 2001 ${confirmData?.StratTime}`)).format('HH:mm:ss'),
			EndTime: 
				props?.Data?.isPermenantParking ? null :
					!status ? 
						moment(props.Data?.endTime,'HH:mm:ss').isValid() ? props.Data?.endTime : 
						moment(new Date(`01 Jan 2001 ${props.Data?.endTime}`)).format('HH:mm:ss') :
						moment(confirmData?.EndTime,'HH:mm:ss').isValid() ? confirmData?.EndTime : 
						moment(new Date(`01 Jan 2001 ${confirmData?.EndTime}`)).format('HH:mm:ss'),
			remarks: confirmData?.remarks,
			fK_ZoneID: !status ? props.Data?.fk_ZoneID : confirmData?.fK_ZoneID,
			fK_BayID : !status ? props.Data?.fK_BayID ? props.Data?.fK_BayID : 0 : confirmData?.fK_BayID ? confirmData?.fK_BayID : 0,
			reqestType : props.Data?.fK_RequestTypeID
		}
		:
		{
			ParkingID : props?.Data?.parkingID,
			remarks: confirmData?.remarks,
			reqestType : props.Data?.fK_RequestTypeID
		}
		
		const Success = (res: any) => {
			setLoading(false)
			reset()
			SuccesToast(res?.response?.data);
			fetch_Users_AllocatedParkingslist(navigate, dispatch, {
				'filters': {
					'query': FINDVEHICLES.USER_ALLOCATED_PARKING_FILTER.query
				},
				'pagination': {
					'pageNo': FINDVEHICLES.USER_ALLOCATED_PARKING_PAGINATION.pageNo,
					'limit': FINDVEHICLES.USER_ALLOCATED_PARKING_PAGINATION.limit,
				},
			});
			reset();
			props.handleClose();
		};
		const Failed = (err: any) => {
		setConfirmModal(false)
			setLoading(false)
			// FailedToast(err?.response)		;
			setOpenErrData(err)
			setOpenErr(true);
			
		};

		if (!status)
			revoke_approved_allocation(postData, Success, Failed)
		else
			update_approved_allocations('put', postData, Success, Failed);
	}

	const onSubmitHandler = (data: any) => {
		console.log('data', data);
		setConfirmData(data)
		setConfirmModal(true)
		
	}



	const getProhibittedDate = () =>{
		let postData = {
			'AppKey': '',
		};
		let api_url = APPLICATION_SETTINGS_GENERAL;
		Request('post', api_url, postData, undefined)?.then((res) => {
			const { statusCode } = res;
			if (statusCode === 200) {
				setProhDate(dayjs().add(res?.response?.data?.future_parking_possible_limit?.settingsValue,'months').format('YYYY-MM-DD'))
			}
		});
		
	}
	const getDisabledDates = (curr: any) => {
		if (isAllocationAvail && isAllocationAvail?.length > 0 && reqTp !== 1 && reqTp !== 2) {
			let startCheck = true;
			let endCheck = true;
			startCheck = curr < dayjs(isAllocationAvail[0], 'YYYY-MM-DD');
			endCheck = curr > dayjs(isAllocationAvail[1], 'YYYY-MM-DD');
			return startCheck || endCheck || curr > dayjs(prohDate).endOf('day');
		} else return curr < dayjs().subtract(1, 'day').endOf('day') || curr > dayjs(prohDate).endOf('day');
	};

	const getDisabledTime = (curr: any, type: any) => {
		if (date && date?.length > 0 && dayjs().diff(date[0], 'hour') === 0) {
			return {
				disabledHours: () => getDisabledHours(),
				disabledMinutes: () => getDisabledMinutes(curr.get('hour')),
			};
		} else {
			return {};
		}
	};

	const getDisabledHours = () => {
		var hours = [];
		for (var i = 0; i < dayjs().get('hour'); i++) {
			hours.push(i);
		}
		return hours;
	};

	const getDisabledMinutes = (selectedHour: any) => {
		var minutes = [];
		if (selectedHour === dayjs().get('hour')) {
			for (var i = 0; i < dayjs().get('minute'); i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	console.log('ERRORS',errors)

	return (
		<ModalLayout
			open={props.open}
			handleClose={() => {
				reset();
				clearErrors();
				props.handleClose();
			}}
			big={true}
			title='Vehicle Parking Allocation'
			subtitle={props.Data?.requestTypeName}>
											<form onSubmit={handleSubmit(onSubmitHandler)} action='' method='post'>

				{/* <div className="modal-body-conten">
					
				</div> */}
			<div className="modal-body-content" style={{ maxHeight:"69vh"}}>

			<div className='row'>
				<div className='col-7'>
					<div className='table-details'>
						<div className='pos-rel'>
							<div className='border-title-row flex-1'>
								<h2 className='border-title'>Details</h2>
								<div style={{ display: 'flex', flexWrap: 'wrap' }}>
								{	props?.Data?.currentStatus===1 ? null :
										<StatusChipComponent
											chipType={statusArr?.find((el:any) => el?.id === parseInt(props?.Data?.currentStatus) )?.value??'warning'} 
											text={statusArr?.find((el:any) => el?.id === parseInt(props?.Data?.currentStatus) )?.label??'Pending'} 
										/>
								}
									<h2 className='border-title'>
										<label className='badge green'>{props.Data?.isVisitor ? 'Visitor' : 'Employee'}</label>
									</h2>
									<h2 className='border-title'>
										<label className='badge dark-blue'>
											{props.Data?.isPermenantParking ? 'Permanent' : 'Temporary'}
										</label>
									</h2>
									{props.Data?.isMedicalPass ? (
										<h2 className='border-title'>
											<label className='badge red'>Medical Pass</label>
										</h2>
									) : null}
								</div>
							</div>
							<div className='dflex'>
								<ul>
									<li>
										Employee Name <span>{props.Data?.fullName}</span>
									</li>

									<li>
										Plate Number <span>{props.Data?.plateNumber}</span>
									</li>
									<li>
										Place of reg. <span>{props.Data?.placeOfRegistration}</span>
									</li>

									<li>
										Vehicle Category <span>{props.Data?.vehicleCategory}</span>
									</li>
									<li>
										Brand <span>{props.Data?.brand}</span>
									</li>
									<li>
										Color <span>{props.Data?.colour}</span>
									</li>
									<li>
										Description <span>{props.Data?.rF_Descriptions}</span>
									</li>
								</ul>
								<div className='seperator'></div>
								<ul>
									<li>
										Approved By <span>{props.Data?.approverName}</span>
									</li>

									<li>
										Approved On <span>{FormatDate(props.Data?.approvedOn, 'DD-MMM-YYYY hh:mm a')}</span>
									</li>
									<li>
										Site <span>{props.Data?.siteName ?? '-'}</span>
									</li>
									<li>
										Zone <span>{props.Data?.zoneName}</span>
									</li>
									<li>
										Bay <span>{props.Data?.bayName ?? '-'}</span>
									</li>

									<li>
										Allocated Date Period{' '}
										<span>{`${FormatDate(props.Data?.startDate, 'DD-MMM-YYYY')} `}<br/>{` ${FormatDate(props.Data?.endDate, 'DD-MMM-YYYY')}`}</span>
									</li>
									<li>
										Allocated Time Period{' '}
										<span>{`${FormatTime(
											props.Data?.stratTime,
											'hh:mm a'
										)}	`}<br/>{` ${FormatTime(
											props.Data?.endTime,
											'hh:mm a'
										)}`}</span>
									</li>
									<li>
										Medical Pass Required<span>{props.Data?.isMedicalPass ? 'Yes' : 'No'}</span>
									</li>
									<li>
										Permanent Parking <span>{props.Data?.isPermenantParking ? 'Yes' : 'No'}</span>
									</li>
								</ul>
							</div>
							{/* {	props?.Data?.currentStatus===1 ? null :
							<div
								style={{
									position : 'absolute',
									width : '100%',
									display: 'flex',
									justifyContent: 'flex-end',
									bottom: 0
								}}
							>
								<StatusChipComponent
									chipType={statusArr[parseInt(props?.Data?.currentStatus)]?.value} 
									text={statusArr[parseInt(props?.Data?.currentStatus)]?.text} 
								/>
							</div>
							} */}
						</div>
						
					</div>
					
				</div>

				<div className='col-5 '>
					<div className='parking-lot-image dflex col-dir justify-space-between'>
						{/* <img src={props?.Data?.lastVisitImage ?? noimage} alt='' title='' /> */}
						<ImageView src={props?.Data?.lastVisitImage} />
						{props.Data?.lastParkTime && <p>Last Parked On : {FormatTime(props.Data?.lastParkTime, 'DD-MMM-YYYY hh:mm a',true)}</p>}
					</div>
				</div>
			</div>
		{	props?.Data?.currentStatus===1 
		//&& 
		//(reqTp===1 || reqTp===2) &&
			//props?.Data && moment().diff(moment(props?.Data?.endDate),'day') < 0 
			?
			<PermissionWrapper subModuleID={subModuleList['Allocated Parking']} type='isEdit'>
				<div className='row'>
					<div className='col-12'>
						<div className='border-form'>
								<div className='row'>
									<div className='col-5'>
										<div className='form-group inline-input'>
											<div className='w-100'>
												<div className='row align-items-center'>
													<div className='col-3'>
														<label>Status</label>
													</div>
													<div className='col-9'>
														<ReactSelect
															options={[
																{ value: 0, label: 'Revoke Allocation' },
																{ value: 1, label: 'Extend Allocation' },
															]}
															className={'common-select common-select-grey'}
															placeholder={'Request Type'}
															isMulti={false}
															value={[
																{ value: 0, label: 'Revoke Allocation' },
																{ value: 1, label: 'Extend Allocation' },
															]?.find((c: any) => c.value === status)}
															onChange={(val: any) => {
																setValue('status', val?.value);
																setStatus(parseInt(val?.value));
															}}
														/>
													</div>
												
												</div>
											</div>
										</div>
									
										<AllocationChart
											pRequestID={props?.Data?.pRequestID}
											hideAllocation={false}
											setIsAllocationAvail={setIsAllocationAvail}
											// datePeriod={date&&date.length>0 ? [date[0],date[1]] : null}
											rfUserType={props.Data?.rfUserType ?? null}
											isVisitor={selIsVisitor}
											reqTp={reqTp}
											userId={selUserId}
										/>
										<div className={`form-group inline-input  ${status === 1 
										|| moment().diff(moment(props?.Data?.startDate),'day') < 0 ? '' : 'opacity-7'}`}>
											<div className='w-100'>
												<div className='row align-items-center'>
													<div className='col-3'>
														<label>Zone * </label>
													</div>
													<div className={`col-9`}>
														<TreeSelectCustom
															isMulti={false}
															selectedZones={selectedZones}
															setselectedZones={setselectedZones}
															grey={true}
															fixSite={props.Data?.fK_SiteID}
															onchangeFx={(e: any) => {
																if(e===null){
																	setValue('fK_ZoneID', null);
																setValue('fK_BayID',0)
																setselectedBay(null)
																}
																else{
																setValue('fK_ZoneID', e?.value);
																setValue('fK_BayID',0)
																setselectedBay(null)
															}															}}
														/>
														{/* <input type="text" name="" className="form-control grey-input" /> */}
													</div>
												</div>
												<ErrorComponent title={'Zone'} errors={errors} name={'fK_ZoneID'} />
											</div>
										</div>
										<div className={`form-group inline-input  ${status === 1
											|| moment().diff(moment(props?.Data?.startDate),'day') < 0 ? '' : 'opacity-7'}`}>
											<div className='w-100'>
												<div className='row align-items-center'>
													<div className='col-3'>
														<label>Bay(optional)</label>
													</div>
													<div className={`col-9`}>
														<input
															type='text'
															name=''
															value={
																GENERAL_DDL.BAY_DDL?.find(
																	(el: any) => el.value === selectedBay?.value
																)?.label
															}
															className='form-control grey-input'
															disabled
														/>
														<ReserveABay
															selectedZone={selectedZones[0]}
															date={date}
															time={rangeTime}
															selectedBay={selectedBay}
															setselectedBay={(v: any) => {
																setselectedBay(v);
																setValue('fK_BayID', v?.value);
															}}
															siteID={props.Data?.fK_SiteID}
														/>
													</div>
												</div>

												<ErrorComponent title={'Bay'} errors={errors} name={'fK_BayID'} />
											</div>
										</div>
									</div>
									<div className='col-7'>


										{	props?.Data 
										&& moment().diff(moment(props?.Data?.startDate).endOf('day'),'day') < 0 
										?
										<div className={`form-group inline-input`}>
											<div className={`w-100 ${status === 1 ? '' : 'opacity-7'} `}>
												<div className='row align-items-center'>
													<div className='col-3'>
														<label>Select Date *</label>
													</div>
													<div className='col-9'>
														<>
															<RangePicker
																getPopupContainer={(trigger) =>
																	trigger.parentElement as HTMLDivElement
																}
																className='form-control grey-input'
																showTime={false}
																format={'DD-MMM-YYYY'}
																onChange={(date: any) => {
																	setdate(date);
																	if (date && date?.length > 0) {
																		if(moment().diff(moment(props?.Data?.startDate).endOf('day'),'day') < 0)
																			setValue('StartDate', date[0]);
																		setValue('EndDate', date[1]);
																	}
																}}
																value={date}
																
																hideDisabledOptions  inputReadOnly
																disabledDate={(curr) => getDisabledDates(curr)}
																disabled={status !== 1}
																allowClear={true}
															/>
														</>
													</div>
													<ErrorComponent title={'Start Date'} errors={errors} name={'StartDate'} />
													<ErrorComponent title={'End Date'} errors={errors} name={'EndDate'} />
												</div>
											</div>
										</div>
										:	
										<div className={`form-group inline-input`}>
											<div className={`w-100 ${status === 1 ? '' : 'opacity-7'} `}>
												<div className='row align-items-center'>
													<div className='col-3'>
														<label>Extend Date *</label>
													</div>
													<div className='col-9'>
														<>
															<CustomDatePicker
																getPopupContainer={(trigger:any) =>
																	trigger.parentElement as HTMLDivElement
																}
																className='form-control grey-input'
																showTime={false}
																format={'DD-MMM-YYYY'}
																onChange={(dateVal: any) => {
																	setEndOnlyDate(dateVal)
																	let d = date
																	d[1] = dateVal
																	setdate(d);
																	
																		setValue('EndDate', dateVal);
																	
																}}
																value={endOnlyDate ? moment(endOnlyDate) : null}
																
																hideDisabledOptions
																disabledDate={(curr:any) => getDisabledDates(curr)}
																disabled={status !== 1}
																allowClear={true}
															/>
														</>
													</div>
													<ErrorComponent title={'Start Date'} errors={errors} name={'StartDate'} />
													<ErrorComponent title={'End Date'} errors={errors} name={'EndDate'} />
												</div>
											</div>
										</div>
										
										}
										{
											props?.Data 
											&& moment().diff(moment(props?.Data?.startDate).endOf('day'),'day') < 0
											&& !props?.Data?.isPermenantParking ?
										
										<div className={`form-group inline-input`}>
											<div className={`w-100 ${status === 1 ? '' : 'opacity-7'} `}>
												<div className='row align-items-center'>
													<div className='col-3'>
														<label>Select Time </label>
													</div>
													<div className='col-9'>
														<>
															<RangePicker
																getPopupContainer={(trigger) =>
																	trigger.parentElement as HTMLDivElement
																}
																picker='time'
																size='large'
																className='form-control grey-input'
																format={'hh:mm A'}
																onChange={(time: any) => {
																	setRangeTime(time);
																	console.log('OUTtime', time);
																	if (time && time?.length > 0) {
																		console.log('TIME=>', time);
																		setValue('StratTime', dayjs(time[0]).format('HH:mm:ss'));
																		setValue('EndTime', dayjs(time[1]).format('HH:mm:ss'));
																	}
																}}
																value={rangeTime}
																defaultValue={[
																	moment(props.Data?.stratTime, ['HH:mm:ss', 'HH:mm'], true),
																	moment(props.Data?.endTime, ['HH:mm:ss', 'HH:mm'], true),
																]}
																allowClear={true}
																hideDisabledOptions
																disabledTime={(date: EventValue<moment.Moment>, type: RangeType) =>
																	getDisabledTime(date, type)
																}
																inputReadOnly
																disabled={status !== 1}
															/>
														</>
													</div>
												</div>
												<ErrorComponent title={'Start Time'} errors={errors} name={'StratTime'} />
												<ErrorComponent title={'End Time'} errors={errors} name={'EndTime'} />
											</div>
										</div>
										:
										null
															}
										<div className='form-group inline-input'>
										<div className={`w-100`}>


											<div className="row align-items-center">

										<div className='col-3'>
														<label>Remarks *</label>
													</div>
													<div className='col-9'>

													
											<input
												placeholder='Remarks'
												className='form-control grey-input h-158'
												{...register('remarks')}
											/>
													</div>

										</div>
										</div>

										</div>

										<ErrorComponent title={'Remarks'} errors={errors} name={'remarks'} />
									</div>
								</div>
						</div>
					</div>
				</div>
			</PermissionWrapper>
			:	null
		}		
			</div>
			{	
			props?.Data?.currentStatus === 1 && 
			//(reqTp===1 || reqTp===2)&&
			props?.Data && moment().diff(moment(props?.Data?.endDate).endOf('day'),'day') < 0  ?
			<PermissionWrapper subModuleID={subModuleList['Allocated Parking']} type='isEdit'>

				<div className='modal-footer'>
					<div
						className='col-7 w-100'
						style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>
						{
							status === 0 ? (
								<button type='submit' className='btn btn-danger ml-5' disabled={loading}>
									<ButtonLoader loading={loading} text={'Save & Revoke Access'} />
								</button>
							) : (
								
								<button type='submit' className='btn btn-secondary ml-5' disabled={loading}>
									<ButtonLoader loading={loading} text={'Save'} />
								</button>
							)
						}
						<button
							onClick={() => {
								clearErrors();
								reset();
								props.handleClose();
							}}
							type='reset'
							className='btn btn-outline-blue close-modal-button'>
							Cancel
						</button>
					</div>
				</div>
			</PermissionWrapper>
			:	null
			}
			{
			openErr &&
			<FailedMessagePop data={openErrData} handleClose={()=>setOpenErr(false)} ConfirmFx={FailMsgConfirm} />
			}
		
			{confirmModal &&
			<ActionConfirmBox
				description={`Do you want to ${status===1 ? `save this change in the request` : `revoke this request`} ?`}
				handleClose={cancelConfirm}
				open={true}
				ConfirmFx={confirmApprove}
			/>
			}
			</form>
		</ModalLayout>
	);
};

export default ViewEditModal;
