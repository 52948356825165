import { NavLink } from 'react-router-dom';

interface LinkTabProps {
	label?: string;
	href: string;
	navLinkPropsOnclick?: any;
}

export default function LinkTab(props: LinkTabProps) {
	return (
		<NavLink to={props?.href}  className={({ isActive }) => (isActive ? 'navlink__active' : 'navlink__inactive')} 
			onClick={props?.navLinkPropsOnclick}>
			{props.label} <div className='navlink__underline' />
		</NavLink>
	);
}
