import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../../../../common/components/Table/CommonTable";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { FormatDate, FormatTime } from "../../../../../utils/utilFunctions";
import { fetch_user_report } from "../../../store/action";

type Props = {};

const ViolationsReports = (props: Props) => {
  const fetchIdRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);

  const columns = React.useMemo(
    () => [
      { Header: "Violation ID", accessor: "violationID" },
      {
        Header: "Violation Date",
        accessor: "date",
        Cell: ({ row }: any) => {
          return (
            <p>
              {`${FormatDate(row?.original?.date, "DD-MM-YYYY")} 
						 ${FormatTime(row?.original?.time, "hh:mm a")}`}
            </p>
          );
        },
      },
      { Header: "Plate Number", accessor: "plateNo" },
      { Header: "Place of Registration", accessor: "placeOfRegistration" },
      { Header: "Vehicle Category", accessor: "vehicleCategory" },
      { Header: "Zone", accessor: "zoneName" },
      { Header: "Bay", accessor: "reporedBa" },
      {
        Header: "Name",
        accessor: "violatedUser",
        Cell: ({ row }: any) => {
          return <>{row?.original?.violatedUser || row?.original?.gustName}</>;
        },
      },
      { Header: "Violated User Email", accessor: "emailID" },
      { Header: "Violated User Contact", accessor: "phoneNumber" },
      { Header: "Violated User Designation", accessor: "desName" },
      { Header: "Violated User Department", accessor: "depName" },
      { Header: "Personal Grade", accessor: "fK_PriorityLevel" },
      { Header: "Penalty Level", accessor: "penaltyLevel" },
      { Header: "User Type", accessor: "userTypeName" },
      { Header: "Violation Type", accessor: "violationName" },

      { Header: "Violation Category", accessor: "vCategoryName" },
      { Header: "Site", accessor: "siteName" },
      { Header: "Reported On", accessor: "createdOn", DateFormat: true, FormatType: "DD-MMM-YYYY hh:mm A" },
      {
        Header: "Reported By",
        accessor: "reportedUser",
        Cell: ({ row }: any) => {
          return <>{row?.original?.reportedUser ?? "System"}</>;
        },
      },

      {
        Header: "Violation Status",
        accessor: "vStatus",
        Cell: ({ row }: any) => {
          return (
            <>
              {row?.original?.vStatus === 0 ? (
                "- "
              ) : row?.original?.vStatus === 2 ? (
                "Inactive"
              ) : (
                <label className="badge red">Active</label>
              )}
            </>
          );
        },
      },
      { Header: "Validated By", accessor: "validateByName" },
      {
        Header: "Validated Status",
        accessor: "validateStatus",

        Cell: ({ row }: any) => {
          return (
            <>{row?.original?.validateStatus === 1 ? <label>Valid</label> : <label className="">Invalid</label>}</>
          );
        },
      },

      // { Header: 'Manual Entry', accessor: 'isManualEntry',
      // 	Cell: ({ row }: any) => {
      // 		return <>{row?.original?.isManualEntry ? 'Yes' : 'No'}</>
      // 	}
      // },
      // { Header: 'Email Sent', accessor: 'emailSentStatus',
      // 	Cell: ({ row }:any) => {
      // 		return <>{row?.original?.emailSentStatus ? 'Yes' : 'No'}</>
      // 	}
      // },
      // { Header: 'Need Sensor Check', accessor: 'needSensorCheck',
      // 	Cell: ({ row }:any) => {
      // 		return <>{row?.original?.needSensorCheck ? 'Yes' : 'No'}</>
      // 	}
      // },
      {
        Header: "",
        accessor: "actionBar",
        hideHeader: true,
        show: false,
      },
    ],
    []
  );

  type IfetchData = {
    pageSize: number;
    pageIndex: number;
    signal: AbortSignal;
    filters: { query: string };
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, signal, filters }: IfetchData) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        fetch_user_report(
          navigate,
          dispatch,
          {
            filters: {
              query: filters.query || "",
            },
            pagination: {
              pageNo: pageIndex,
              limit: pageSize,
            },
          },
          4,
          signal
        );
      }
    },
    [dispatch, navigate]
  );

  return (
    <CommonTable
      data={VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA ?? []}
      columns={columns}
      pageCount={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount ?? 0}
      fetchData={fetchData}
      loading={VISITS_REPORTS_DATA?.USER_REPORTS_LOADER}
      error={VISITS_REPORTS_DATA?.USER_REPORTS_ERROR}
      limit={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit ?? 1}
      page_no={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.pageNo}
      filters={VISITS_REPORTS_DATA?.USER_REPORTS_FILTER}
    />
  );
};

export default ViolationsReports;
