import React, { useEffect } from 'react';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import { Navigate, Route, Routes } from 'react-router-dom';
import Notifcation from './screens/notification/notifcation';
import NotificationSettings from './screens/settings/notificationSettings';
import NotificationHistory from './screens/history/notificationHistory';
import NotificationException from './screens/exception/notificationException';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { useRedirect } from '../../utils/hooks/useRedirect';
import { subModuleList } from '../../config/subModuleList';
import { useDispatch } from 'react-redux';
import { RESET_STATE } from './store/notificationSlice';

type Props = {};

const NotificationIndex = (props: Props) => {
	const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
	const redirectedPath = useRedirect([
		{
			path: 'notification',
			submoduleID: subModuleList.Notifications,
		},
		{
			path: 'exceptions',
			submoduleID: subModuleList['Notification Exceptions'],
		},
		{
			path: 'settings',
			submoduleID: subModuleList['Notification Settings'],
		},
		{
			path: 'history',
			submoduleID: subModuleList['Notification History'],
		},
	]);

	return (
		<div className='navtabs__container'>
			<NavTabs>
				<PermissionWrapper subModuleID={subModuleList.Notifications}>
					<LinkTab label='Notifications' href='notification' />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Notification Exceptions']}>
					<LinkTab label='Notifications Exceptions' href='exceptions' />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Notification Settings']}>
					<LinkTab label='Notifications Settings' href='settings' />
				</PermissionWrapper>
				<PermissionWrapper subModuleID={subModuleList['Notification History']}>
					<LinkTab label='Notifications History' href='history' />
				</PermissionWrapper>
			</NavTabs>
			<Routes>
				<Route
					path={'notification'}
					element={
						<PermissionWrapper subModuleID={subModuleList.Notifications}>
							<Notifcation />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'exceptions'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Notification Exceptions']}>
							<NotificationException />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'settings'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Notification Settings']}>
							<NotificationSettings />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'history'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Notification History']}>
							<NotificationHistory />
						</PermissionWrapper>
					}
				/>

				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default NotificationIndex;
