import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import auth from '../../config/auth';
import { useNavigate } from 'react-router-dom';
import Request from '../../config/api';
import { FailedToast2 } from '../../utils/utilFunctions';

type Props = {};

const SSOLoder = (props: Props) => {
	let { tk } = useParams();
	let navigate = useNavigate();

	console.log("url",tk)

	useEffect(() => {
		if (tk) {
			let at = tk?.split('$Bze=e');

			let er = tk?.split('error-status=');
			if (at && at[0] && at[1]) {
				// auth.login(at[0], at[1], () => navigate('/', { replace: true }));

				if (at[1]) {
					let url = 'auth/renew-access/';
					console.log(at[1]);
					let postData = {
						'refresh_token': at[1],
					};
					Request('post', url, postData)?.then((res) => {
						if (res?.statusCode === 200) {
							auth.login(res?.response?.accessToken, at[1], () => navigate('/', { replace: true }));
						} else {
							auth.logout(() => {
								navigate('/login', { replace: true });
							});
						}
					});
				}
			} else if (er[1]) {
				FailedToast2(er[1]);
			}
		}
	}, [navigate, tk]);

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<CircularProgress style={{ color: 'var(--primary)', width: '100px', height: '100px' }} />
		</div>
	);
};

export default SSOLoder;
