/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import ParkingLog from '../../../../../assets/images/common/parking-log.jpg';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_InvalidReasonDDL } from '../../../../../common/general/action';
import { FailedToast, FormatTime, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_report_violations_list, update_violation_status } from '../../../store/action';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useNavigate } from 'react-router';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import dayjs from 'dayjs';
import ImageCarousel from '../../../../../common/components/ImageCarousel';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ReactSelect from 'react-select';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../../config/subModuleList';
import noimage from '../../../../../assets/images/common/noimage.jpg'

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

const ViewModal = (props: Props) => {
	const [invalidReason, setInvalidReason] = useState('');
	const [remarks, setRemarks] = useState('');
	const navigate = useNavigate();
	const fetchIdRef = useRef(0);
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState<boolean>(true);
	const [invalidModal, setInvalidModal] = useState(false);
	const confirmClose = () => setInvalidModal(false);

	const dispatch = useAppDispatch();
	useEffect(() => {
		const controller = new AbortController();
		fetch_InvalidReasonDDL(dispatch, undefined, controller.signal);
	}, []);
	const INVALIDREASONLIST = useAppSelector((state) => state?.general);
	const VIOLATION_HISTORY = useAppSelector((state) => state?.violations);

	const InvalidReasonUpdate = () => {
		setLoading(true);

		let postData = {
			'ViolationID': props.Data?.violationID,
			'ValidateStatus': 2,
			'FK_ReasonID': invalidReason,
			'ValidateRemarks': remarks,
		};

		const Success = (res: any) => {
			setLoading(false);
			setInvalidModal(false);
			SuccesToast(res?.response?.data);
			const controller = new AbortController();
			fetch_report_violations_list(
				navigate,
				dispatch,
				{
					'filters': {
						'query': VIOLATION_HISTORY?.REPORT_VIOLATION_FILTER?.query || '',
					},
					'pagination': {
						'pageNo': VIOLATION_HISTORY?.REPORT_VIOLATION_PAGINATION?.pageNo,
						'limit': VIOLATION_HISTORY?.REPORT_VIOLATION_PAGINATION?.limit,
					},
				},
				controller.signal
			);

			props.handleClose();
			setRemarks('');
		};
		const Failed = (err: any) => {
			setLoading(false);
			setInvalidModal(false);
			FailedToast(err?.response)		;
		};
		update_violation_status(postData, Success, Failed);
	};

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'violationID' },
			{ Header: 'DATE', accessor: 'vDate', DateFormat: true, FormatType: 'DD-MMM-YYYY' },
			{ Header: 'TIME', accessor: 'vTime' },
			{ Header: 'PLATE NUMBER', accessor: 'plateNo' },
			{ Header: 'VIOLATION TYPE', accessor: 'violationName' },
			{ Header: 'REPORTED BY', accessor: 'addedBy' },
			{ Header: '', accessor: 'actionBar' },
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
	};

	// const fetchData = useCallback(({ pageSize, pageIndex ,signal}:IfetchData) => {
	// 	const fetchId = ++fetchIdRef.current;
	// 	if (fetchId === fetchIdRef.current) {
	// 		fetch_report_violations_History_list( dispatch, {
	// 			'filters': {
	// 				'query':
	//                 ` ViolationID NOT IN (${props.Data?.violationID}) AND ${props.Data?.fK_UserUniqueID ? ` FK_UserUniqueID=${props.Data?.fK_UserUniqueID} ` : ''}`
	// 			},
	// 			'pagination': {
	// 				'pageNo': pageIndex,
	// 				'limit': pageSize,
	// 			},
	// 		}, signal);
	// 	}
	// }, [dispatch, props.Data?.fK_UserUniqueID, props.Data?.violationID]);
	const handleChangeExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(!expanded);
	};

	console.log(
		'IMAGES',
		props.Data?.imagesList,
		props.Data?.imagesList?.map((el: any) => {
			return { src: el.images };
		})
	);

	console.log('VIEW DATA', props.Data);

	return (
		<>
			<ModalLayout
				open={props.open}
				handleClose={() => {
					props.handleClose();
					setRemarks('');
				}}
				big={true}
				title='Report Violations'
				subtitle={props.Data?.remarks}>
				<div className='row'>
					<div className='col-7'>
						<div className='table-details'>
							<div>
								<div className='border-title-row flex-1'>
									<h2 className='border-title'>Violation Type</h2>
									<h2 className='border-title'>
										<label className='badge darkblue'>{props.Data?.violationName}</label>
									</h2>
									{props.Data?.validateStatus && props.Data?.validateStatus !== 1 && (
										<h2 className='border-title'>
											<label className='badge red'>Invalid Reason : {props.Data?.vReason}</label>
										</h2>
									)}
								</div>
								<div className='dflex'>
									<ul>
										<li>
											Violated User <span>{props.Data?.fullName}</span>
										</li>
										<li>
											Violated User Type <span>{props.Data?.userTypeName}</span>
										</li>
										<li>
											Violation Date{' '}
											<span>{props.Data?.vDate ? dayjs(props.Data?.vDate).format('DD-MMM-YYYY') : ''}</span>
										</li>
										<li>
											Violation Time <span>{FormatTime(props.Data?.vTime, 'hh:mm:ss a')}</span>
										</li>
										<li>
											Plate Number <span>{props.Data?.plateNo}</span>
										</li>
									</ul>
									<div className='seperator'></div>
									<ul>
										<li>
											Reported By <span>{props.Data?.addedBy}</span>
										</li>
										<li>
											Violation Category <span>{props.Data?.vCategoryName}</span>
										</li>
										<li>
											Penalty Level<span>{props.Data?.penaltyLevel}</span>
										</li>
										<li>
											Zone <span>{props.Data?.reportedZone}</span>
										</li>
										<li>
											Bay <span>{props.Data?.reporedBa ? props.Data?.reporedBa : props.Data?.reportedBay}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className='col-5'>
						<div className='parking-lot-image carouselUlAdjust'>
							{props.Data?.imagesList && props.Data?.imagesList.length !== 0 ? (
								<ImageCarousel
									showThumbs={false}
									imageArr={props.Data?.imagesList?.map((el: any) => {
										return { src: el.images };
									})}
									showIndicators={false}
								/>
							) : <>
							<div className='parking-lot-image'>
							<img src={noimage}alt="" title="" />

							</div>
							</>
						}
						</div>
					</div>
				</div>
				{/* <div className="row">
                <div className="col-12">
                    <div className="accordion-box">
                    <Accordion expanded={expanded}
						onChange={handleChangeExpand('panel1')} 
						sx={{ boxShadow: 'none', background:'transparent' }}>
                        <AccordionSummary expandIcon={<i className='fa fa-chevron-down' />}
							aria-controls='panel1bh-content'
							id='panel1bh-header'>
                            <div className="accordion-box-heading">
                                <h3>Violation History</h3>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordion-box-content reponsive-table'>
                                <CommonTable 
				                	data={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_DATA??[]} 
				                	columns={columns} 
				                	pageCount={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_PAGINATION?.totalCount ?? 0} 
				                	fetchData={fetchData} 
		  			                limit={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_PAGINATION?.limit ?? 1} 
				                	page_no={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_PAGINATION?.pageNo ?? 1}			
				                filters={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_FILTER}
                                />
                        </AccordionDetails>
                    </Accordion>
                    
                    </div>
                    
                </div>
            </div> */}
				{	props?.Data?.validateStatus===2 ?	null :
				<PermissionWrapper subModuleID={subModuleList['Report Violation']} type='isEdit'>
					<div className='row modal-footer'>
						<div className='col-12'>
							<div className='border-form'>
								<div>
									<div className='row'>
										<div className='col-6'>
											<div className='form-group inline-input modal-footer-tx-align-left'>
												<label>Select Invalid Violation Reason</label>
												<ReactSelect
													options={INVALIDREASONLIST.INVALIDREASON_DDL}
													className={'common-select  common-select-grey'}
													placeholder={'Reasons for invalid violations'}
													isMulti={false}
													isClearable
													menuPlacement='top'
													value={INVALIDREASONLIST.INVALIDREASON_DDL?.find((c) => c?.value === invalidReason)}
													onChange={(val) => {
														setInvalidReason(val?.value);
													}}
												/>
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group inline-input modal-footer-tx-align-left'>
												<label>Remarks</label>
												<input
													type='text'
													className='form-control grey-input'
													name=''
													placeholder='Remarks'
													value={remarks}
													onChange={(e) => setRemarks(e.target.value)}
												/>
											</div>
										</div>
										<p className='ml-5' style={{ color: 'orange', fontSize: '12px' }}>
											You can mark this violation as invalid with a predefined invalid reason.{' '}
											<b style={{ color: 'orange' }}>This is an irreversible action.</b>
										</p>
									</div>
									<div className={'row button-group '}>
										<button onClick={()=>setInvalidModal(true)}
										 className={remarks !== '' && invalidReason ? 'btn btn-secondary border-blue' : 'btn btn-secondary border-blue opacity-half'}>
											<ButtonLoader loading={loading} text={'Save'} />
										</button>
										<button
											onClick={() => {
												props.handleClose();
												setRemarks('');
											}}
											className='btn btn-outline-blue close-modal-button ml-5'>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</PermissionWrapper>
				}
			</ModalLayout>
			{invalidModal && (
				<CustomConfirm handleClose={confirmClose} open={invalidModal} title='Are you sure about Invalidating this Violation ?'>
					<button className='btn btn-secondary' onClick={InvalidReasonUpdate}>
						{' '}
						Invalidate this Violation
					</button>
				</CustomConfirm>
			)}
		</>
	);
};

export default ViewModal;
