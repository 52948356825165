import React, {useState} from 'react'
import ModalLayout from '../../../common/layouts/ModalLayout'
import headerImage from '../../../assets/images/common/adnoc-logo.jpg'
import ReactToPrint from "react-to-print";
import { ComposedChart, ResponsiveContainer } from 'recharts';
import ReactSelect from 'react-select';
import moment from 'moment';

type Props = {
    handleClose:any;
    children:any;
    data?:any;
    extraFunc?:any;
    title?:any;
    titleArr?:any;
}

const PdfExporterModal = (props: Props) => {

    let pageMargin = [{ value : '2', label : 'Normal' }, { value : '0.7', label : 'Narrow' }, { value : '2.5', label : 'Wide' }]
    let pageAlign = [{ value : 'l', label : 'Left' }, { value : 'r', label : 'Right' }]

    const [pgTopMrg, setPgTopMrg] = useState('2')
    const [pgLeftMrg, setPgLeftMrg] = useState('2')
    const [pgAln, setPgAln] = useState('l')

    const componentRef = React.useRef(null);

//react-to-print functions
    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("Preparing Page");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");
        props?.handleClose();
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Generating Preview Page...");

        return new Promise((resolve:any) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
            setLoading(false);
            setText("Page Loaded to Print");
            resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
    if (
        text === "Page Loaded to Print" &&
        typeof onBeforeGetContentResolve.current === "function"
    )   {
            let valFn:any = onBeforeGetContentResolve.current;
            valFn()
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

const reactToPrintTrigger = React.useCallback(() => {
  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
  // to the root node of the returned component as it will be overwritten.

  // Bad: the `onClick` here will be overwritten by `react-to-print`
  // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

  // Good
  return <button className='form-control blue-btn'>Save</button>;
}, []);

    const PrintablePage = () =>{
        return(
        <div ref={componentRef} className='printablePageLayout' 
            style={{ paddingTop : `${pgTopMrg}cm`, paddingBottom : `${pgTopMrg}cm`, 
            paddingLeft : `${pgLeftMrg}cm`, paddingRight : `${pgLeftMrg}cm` }}>
            <div className='printablePageInfoSection'>
                <div className='printablePageHeader'>
                    <div className='printablePageHeaderImage'>
                        <img src={headerImage} alt="headerImage" style={{ height:'100%' }} />
                    </div>
                    <div className='col-12 printablePageHeaderTitle' style={{ textAlign:'center' }}>Secured Car Parking Management System</div>
                </div>
                <div className={`printablePageSubtitle ${pgAln==='r' ? 'printablePageSubtitleRight' : ''}`}>
                    {
                        props?.titleArr && props?.titleArr?.length>0 ?
                            props?.titleArr?.map((el:any)=>{
                                return(
                                    <p>{el}</p>
                                )
                            })
                        :
                        <p>Report : <span>{props?.title}</span></p>
                    }
                </div>
            </div>
            <div className='printablePageGraphSection'>
                <ResponsiveContainer height="100%">
                <ComposedChart
                    data={props.data}
                    width={500}
                    height={300}
                    margin={{
                        top: 5,
                        right: 20,
                        left: props?.extraFunc? 20 : -10,
                        bottom: 5,
                      }}
                >
                    {props?.children()}
                </ComposedChart>
                </ResponsiveContainer>
            </div>
            
        </div>
        )
    }

  return (
    <ModalLayout big open={true} handleClose={()=>{ props.handleClose()}} title='Exporting Report in PDF'>
        <div className='modal-body-content'>
            <div className="row" style={{ alignItems:'center' }}>
			    <div className="col-3">
			    	<div className="form-group">
                        <label style={{ fontSize:'12px' }}>Top and Bottom Margin :</label>
			    		<ReactSelect
							options={pageMargin}
							className={'common-select common-select-grey '}
							placeholder={'Page Top and Bottom Margin'}
							defaultValue={pageMargin?.find((c: any) => c.value === pgTopMrg)}
							isMulti={false}
							isClearable={false}
                        	value={pageMargin?.find((c: any) => c.value === pgTopMrg)}
                            onChange={(val:any) =>{ setPgTopMrg(val?.value) }}
						/>
			    	</div>
			    </div>
                <div className="col-3">
			    	<div className="form-group">
                        <label style={{ fontSize:'12px' }}>Left and Right Margin :</label>
                        <ReactSelect
							options={pageMargin}
							className={'common-select common-select-grey '}
							placeholder={'Page Left and Right Margin'}
							defaultValue={pageMargin?.find((c: any) => c.value === pgLeftMrg)}
							isMulti={false}
							isClearable={false}
                        	value={pageMargin?.find((c: any) => c.value === pgLeftMrg)}
                            onChange={(val:any) =>{ setPgLeftMrg(val?.value) }}
						/>
			    	</div>
			    </div>
                <div className="col-3">
			    	<div className="form-group">
                        <label style={{ fontSize:'12px' }}>Page Text Align :</label>
			    		<ReactSelect
							options={pageAlign}
							className={'common-select common-select-grey '}
							placeholder={'Page Text Align'}
							defaultValue={pageAlign.find((c:any) => c.value === pgAln)}
							isMulti={false}
							isClearable={false}
                        	value={pageAlign.find((c:any) => c.value === pgAln)}
                            onChange={(val:any) =>{ setPgAln(val?.value) }}
						/>
			    	</div>
			    </div>  
                <div className="col-3">
                <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={`${props?.title}_${moment().format('YYYY-MMM-DD hh:mm a')}`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={reactToPrintTrigger}
                />    
                </div>              
                
            </div>
            {
                loading && <p>{text}</p>
            }
            {
                props?.data?
                    <PrintablePage/>
                :
                <div ref={componentRef} className='printablePageLayout' 
                    style={{ paddingTop : `${pgTopMrg}cm`, paddingBottom : `${pgTopMrg}cm`, 
                    paddingLeft : `${pgLeftMrg}cm`, paddingRight : `${pgLeftMrg}cm` }}>
                <div className='printablePageInfoSection'>
                    <div className='printablePageHeader'>
                        <div className='printablePageHeaderImage'>
                            <img src={headerImage} alt="headerImage" style={{ height:'100%' }} />
                        </div>
                        <div className='col-12 printablePageHeaderTitle' style={{ textAlign:'center' }}>Secured Car Parking Management System</div>
                    </div>
                    <div className={`printablePageSubtitle ${pgAln==='r' ? 'printablePageSubtitleRight' : ''}`}>
                        {
                            props?.titleArr && props?.titleArr?.length>0 ?
                                props?.titleArr?.map((el:any)=>{
                                    return(
                                        <p>{el}</p>
                                    )
                                })
                            :
                            <p>Report : <span>{props?.title}</span></p>
                        }
                    </div>
                </div>
                <div className='printablePageGraphSection'>
                    {props?.children()}
                </div>
                
            </div> 
            }
            
        </div>
    </ModalLayout>
  )
}

export default PdfExporterModal