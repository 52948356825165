import React, { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/config/store';
import App from './app/App';
// import App from './app/App2';

import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { defaultConfig, globalConfig, globalConfigUrl } from './config/config';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const container = document.getElementById('root')!;
const root = createRoot(container);

if (process.env.NODE_ENV === 'production') {
	console.log = () => {}
	console.error = () => {}
	console.debug = () => {}
  }
  



axios.get(globalConfigUrl)
	.then((response) => {
		globalConfig.set(response.data);
		console.log('Global config fetched: ', response.data);

		try{
			const msalInstance = new PublicClientApplication(response.data?.msalConfig);
			return (
				<React.StrictMode>
					<MsalProvider instance={msalInstance}>
						<Provider store={store}>
							<BrowserRouter>
								<ToastContainer style={{ zIndex: 999999, width: '450px' }} pauseOnFocusLoss={false} pauseOnHover={false} />
								<App />
							</BrowserRouter>
						</Provider>
					</MsalProvider>
				 </React.StrictMode>
			);
		}catch(e){

			const errorMessage = 'Error in redirection configurations !';
			console.error(errorMessage, `Have you provided the msal configurations / check proxy configurations ?`, e);
			return <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>;
		
	
		}

	
	})
	.catch((e) => {

		if (process.env.NODE_ENV === 'development') {
			console.warn(`Failed to load global configuration from '${globalConfigUrl}', using the default configuration instead:`, defaultConfig);
			globalConfig.set(defaultConfig);
			const msalInstance = new PublicClientApplication(defaultConfig?.msalConfig);

			return (
				// <React.StrictMode>
					<MsalProvider instance={msalInstance}>
						<Provider store={store}>
							<BrowserRouter>
								<ToastContainer style={{ zIndex: 999999, width: '450px' }} pauseOnFocusLoss={false} pauseOnHover={false} />
								<App />
							</BrowserRouter>
						</Provider>
					</MsalProvider>
				//  </React.StrictMode>
			);
		} else {
			const errorMessage = 'Error while fetching config file!';
			console.error(errorMessage, `Have you provided the config file '${globalConfigUrl}'?`, e);
			return <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>;
		}
	})
	.then((reactElement: ReactElement) => {
		root.render(reactElement);
	});

reportWebVitals();
