import React, { useEffect, useState } from 'react';
import SimpleBox from '../../../../common/components/graph_components/SimpleBox';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_average_approve_requests } from '../../store/action';

type Props = {};

const AverageTimeToApproveRequest = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);
	const [data, setdata] = useState<any>();
	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

	

		if (DASHBOARD_DATA?.SELECTED_SITE?.value) {
			fetch_average_approve_requests( signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data;
					setdata(data);
				}
			});
		}

		return () => {
			controller.abort();
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value]);

	return <SimpleBox title='Average days to approve Web User requests' value={data?.avgDays ?? 0} />;
};

export default AverageTimeToApproveRequest;
