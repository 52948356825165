import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';

import ImmediateXPorter from './ImmediateXPorter';
import { GraphRoutes } from './graphRoute';
import { useAppDispatch } from '../../config/hooks';
import dayjs from 'dayjs';

export default function Index() {
  const urlParams = useLocation()
  const routePath = useParams()
  const dispatch  = useAppDispatch();
  console.log('EXPORTINGREPORTPAGEurlParams',urlParams,'ROUTEPARAMS',routePath)
  console.log('EXPORTINGREPORTPAGE')

  useEffect(()=>{
    if(urlParams){
      let titleArr = []
      let paramSearch = new URLSearchParams(urlParams?.search)
      //entryexit has no floor
      let postData  = {
          SiteID      :   paramSearch?.get('site')??0,
          Todate      :   paramSearch?.get('todate') ? 
            dayjs(paramSearch?.get('todate')).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
          FromDate    :   paramSearch?.get('fromdate') ? 
            dayjs(paramSearch?.get('fromdate')).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),
          FloorID     :   paramSearch?.get('floor')??0, 
          FromTime    :   paramSearch?.get('fromtime') ? dayjs(paramSearch?.get('fromtime')).format('HH:mm:ss') : '00:00:00',
          ToTime      :   paramSearch?.get('totime') ? dayjs(paramSearch?.get('totime')).format('HH:mm:ss') :  '23:59:00',
          tk          :   paramSearch?.get('tk')??''
      }
      
      let graphObj = GraphRoutes?.find(el => el?.path===routePath['*'])
      console.log('GRAPGOBJ',graphObj)
      if(graphObj){
        titleArr.push(graphObj?.title)

        if(paramSearch?.get('todate')){
          titleArr.push(`Date : ${dayjs(paramSearch?.get('fromdate')).format('DD/MMM/YYYY')} - ${dayjs(paramSearch?.get('todate')).format('DD/MMM/YYYY')}}`)
        }
        if(paramSearch?.get('totime')){
          titleArr.push(`Time : ${dayjs(paramSearch?.get('fromtime')).format('HH:mm a')} - ${dayjs(paramSearch?.get('totime')).format('HH:mm a')}}`)
        }
        if(paramSearch?.get('siteName')){
          titleArr.push(`Site : ${paramSearch?.get('siteName')}`)
        }
        if(paramSearch?.get('floorName')){
          titleArr.push(`Floor : ${paramSearch?.get('floorName')}`)
        }

        graphObj?.actionFn(postData, 
          paramSearch?.get('measure')??'avg', 
          paramSearch?.get('charttype')??'',
          dispatch,
          {
            tk        : postData?.tk,
            title     : graphObj?.title,
            titleArr  :  [...titleArr],
            graphNo   : GraphRoutes?.indexOf(graphObj)
          }
        )
      }
    }
        
  },[urlParams])

  return <ImmediateXPorter/>
}