/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react'
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import CommonTable from '../../../../../common/components/Table/CommonTable'
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { SuccesToast, WarnToast } from '../../../../../utils/utilFunctions';
import { fetch_webusers_request, webusers_request_status } from '../../../store/action';
import RequestModal from './RequestModal';
import { FailedToast } from './../../../../../utils/utilFunctions';
import ViewModal from './viewModal';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../../config/subModuleList';

type Props = {}

const ListTable = (props: Props) => {

    const fetchIdRef = useRef(0);
    const dispatch = useAppDispatch()
    const SECURITY_DATA = useAppSelector((state) => state?.security);


    const [ViewData, setViewData] = useState();
    const [viewModal, setviewModal] = useState(false);
    const [requestModal, setrequestModal] = useState(false);
    const [requestData, setrequestData] = useState();




    const [confirm, setconfirm] = useState(false);


    const viewModalOpen = (data: any) => {
 
        setViewData(data);
        setviewModal(true);
   
    };

    const viewModalClose = () => setviewModal(false);

    const requestModalOpen = (data: any) => {
 
        setrequestData(data);
        setrequestModal(true);
   
    };

    const requestModalClose = () => setrequestModal(false);

    const confirmOpen = (data: any) => {

        setViewData(data);
        setconfirm(true);

    };
    const confirmClose = () => setconfirm(false);






    const fetchData = useCallback(
        ({ pageSize, pageIndex, signal ,OrderBy ,filters}: any) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                fetch_webusers_request(dispatch,
                    {
                        "filters": {
							'query':filters.query || '',
                            OrderBy
                        },
                        "pagination": {
                            "pageNo": pageIndex,
                            "limit": pageSize
                        }
                    }, signal
                );
            }
        },
        [dispatch]
    );

    const onDelete = (data: any) => {
        console.log('SUBMITTED DATA', data);


        let postData = {
            "sRequestID": data?.sRequestID,
            "Status": 2,
            "ApproverRemarks": "Rejected",
            "RoleID": 0
        };

        const Success = (res: any) => {

            SuccesToast(res?.response?.data);
            fetch_webusers_request(dispatch, {
                'filters': {
                    'query': '',
                },
                'pagination': {
                    'pageNo': 1,
                    'limit': 10,
                },
            });
            confirmClose();
        };
        const Failed = (err: any) => {
            FailedToast(err?.response)		;
        };

        webusers_request_status('post', postData, Success, Failed);
    };

    const columns = React.useMemo(
        () => [
            { Header: 'Request ID ', accessor: 'sRequestID' ,orderByKey :"sRequestID"},

            { Header: 'Requested Date ', accessor: 'requestedOn', DateFormat: true   ,orderByKey :"requestedOn"},

            { Header: 'Requested By ', accessor: 'requestedByName'  },

            { Header: 'Requested For ', accessor: 'requestedForName' },

            {
                Header: 'status ', accessor: 'requestStatus', Cell: ({ row }: any) => {

                    return (
                        <Fragment>

                            <label className={
                                `${row?.original?.requestStatus === 1 ? "badge green"
                                    : row?.original?.requestStatus === 2 ? "badge red"
                                        : row?.original?.requestStatus === 3 ? "badge dark-blue"
                                            : row?.original?.requestStatus === 0 ? "badge yellow" :
                                                "badge"} `}>
                                {
                                    row?.original?.requestStatus === 1 ? "Approved"
                                        : row?.original?.requestStatus === 2 ? "Rejected"
                                            : row?.original?.requestStatus === 3 ? "Cancelled"
                                                : row?.original?.requestStatus === 0 ? "Pending" : null
                                }
                            </label>
                        </Fragment>
                    );
                },
            },




            {
                Header: '',
                accessor: 'actionBar',
                hideHeader: true,

                Cell: ({ row }: any) => {
                    return (
                        <Fragment>
                            <div className='button-group'>
                                {
                                    row?.original?.requestStatus === 0 ? <>
                                    <PermissionWrapper subModuleID={subModuleList['Web Users Request']} type="isEdit">


                                        <a className="btn btn-sqaure btn-red" onClick={() => confirmOpen(row?.original)}><i className="fa-solid fa-xmark"></i></a>
                                        <a className="btn btn-sqaure btn-green" onClick={() => requestModalOpen(row?.original)} ><i className="fa-solid fa-check"></i></a>
                                        </PermissionWrapper>

                                    </> :
                                    <PermissionWrapper subModuleID={subModuleList['Web Users Request']} type="isDelete">
                               <a className="btn btn-sqaure btn-white modal-button" onClick={() => viewModalOpen(row?.original)} ><i className="fa-solid fa-eye"></i></a>
                                    </PermissionWrapper>
                                }

                            </div>
                        </Fragment>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <CommonTable
                columns={columns}
                data={SECURITY_DATA?.WEBUSER_REQUEST_LIST_DATA ?? []}
                fetchData={fetchData}
                pageCount={SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.totalCount ?? 0}
                loading={SECURITY_DATA?.WEBUSER_REQUEST_LOADER}
                page_no={SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.pageNo ?? 1}
                limit={SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.limit ?? 1} 
                filters={SECURITY_DATA?.WEBUSER_REQUEST_FILTER}
                />

            {viewModal && <ViewModal open={viewModal} handleClose={viewModalClose} Data={ViewData} />}

            {requestModal && <RequestModal open={requestModal} handleClose={requestModalClose} Data={requestData} />}




            {confirm && <CustomConfirm handleClose={confirmClose} open={confirm} title="Are you sure about rejecting this request ?">
                <button className='btn btn-secondary' onClick={() => onDelete(ViewData)}>  Reject</button>
            </CustomConfirm>}

        </>

    )
}

export default ListTable