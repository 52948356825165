/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import {useCallback, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, FormatTime } from '../../../../../utils/utilFunctions';
import { fetch_report_violations_list, fetch_report_violation_detail } from '../../../store/action';
import ReportModal from './viewModal';

type Props = {};

const ListTable = (props: Props) => {
	const [reportModal, setreportModal] = useState(false);

	const reportModalOpen = () => setreportModal(true);
	const reportModalClose = () => setreportModal(false);

	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();
  	const fetchIdRef                    = useRef(0);
	const [data, setData]				= useState<any>()
	const REPORTVIOLATION_DATA              = useAppSelector((state) => state?.violations);

	const handleModalOpen = (id:number) =>{
		const success = (res:any) =>{
			console.log(res)
			setData(res?.response?.data)
			reportModalOpen()
		}
		const failed = (err:any) => {
			FailedToast(err?.response)		;
		}
		fetch_report_violation_detail(id, success, failed)
	}

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'violationID' ,orderByKey :"violationID"},
			
			{
				Header: 'Name',
				accessor: 'violatedUser',
				orderByKey: 'fullName',
				Cell: ({ row }: any) => {
					return <>{row?.original?.fullName??row?.original?.gustName}</>;
				},
			},

			
			{ Header: 'Date', accessor: 'vDate', DateFormat : true ,orderByKey :"vDate", tdClass : 'resetWhiteSpaceWrap'},
			{ Header: 'Time', accessor: 'vTime' ,orderByKey:"vTime", tdClass : 'resetWhiteSpaceWrap',
				Cell:({ row }:any) => {
					return <>{FormatTime(row?.original?.vTime, 'hh:mm a')}</>
				}
			},
			{ Header: 'Plate Number', accessor: 'plateNo' , orderByKey:"plateNo"},
			{ Header: 'Violation Type', accessor: 'violationName' },
			{ Header: 'User Type', accessor: 'userTypeName'},
			{ Header: 'Zone', accessor: 'reportedZone'},
			{
				Header: 'Validation Status',
				accessor: 'validateStatus',
				Cell: ({ row }: any) => {
					return (
						// <StatusChipComponent
						// 	text={}
						// 	chipType={row?.values?.validateStatus === 2 ? 'error' : 'success'}
						// />
						<>
							{row?.values?.validateStatus === 2 ? (
								<label htmlFor='' className='badge yellow'>
									Invalid
								</label>
							) : (
								'Valid '
							)}
						</>
					);
				},
			},
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a className='btn btn-sqaure btn-white modal-button'
								 onClick={() => handleModalOpen(row?.values?.violationID)}>
									<i className='fa-solid fa-eye'></i>
								</a>
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);


	type IfetchData = {
		pageSize :number,
		 pageIndex :number,
		 signal :AbortSignal,
		 OrderBy:string,
		filters: {query: string;}

	}

	const fetchData = useCallback(({ pageSize, pageIndex ,signal ,OrderBy ,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_report_violations_list(navigate, dispatch, {
				'filters': {
					'query':filters.query || '',
					OrderBy
				},
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			},signal);
		}
	}, [dispatch, navigate]);

	return (
		<>
			<CommonTable
				columns={columns}
				data={REPORTVIOLATION_DATA?.REPORT_VIOLATION_DATA ?? []}
				fetchData={fetchData}
				pageCount={REPORTVIOLATION_DATA?.REPORT_VIOLATION_PAGINATION?.totalCount ?? 0}
				loading={REPORTVIOLATION_DATA?.REPORT_VIOLATION_LOADER}
				page_no={REPORTVIOLATION_DATA?.REPORT_VIOLATION_PAGINATION?.pageNo ?? 1}
				limit={REPORTVIOLATION_DATA?.REPORT_VIOLATION_PAGINATION?.limit ?? 1}
			filters={REPORTVIOLATION_DATA?.REPORT_VIOLATION_FILTER}
			/>
			{	reportModal ?
				<ReportModal open={reportModal} handleClose={reportModalClose} Data={data}/>	: null}
		</>
	);

};

export default ListTable;
