import React, { Fragment, useEffect, useState } from 'react';
import { fetch_floorLevel, fetch_site, fetch_zone } from '../../general/action';
import { MenuItem } from '@mui/material';

import 'antd/dist/antd.min.css';
import { Input } from 'antd';

const { Search } = Input;

type Props = {
	isMulti?: boolean;
	grey?: boolean;
	selectedZones: ddl[];
	setselectedZones: React.Dispatch<React.SetStateAction<ddl[]>>;
	onchangeFx?: any;
	showAll?: any;
	classnames?: string;
	disableArray?: ddl[];
};

type ddl = {
	label: string;
	value: any;
};
const TreeSelectCustom = ({
	isMulti = false,
	selectedZones,
	setselectedZones,
	onchangeFx = null,
	grey = false,
	showAll = null,
	classnames = '',
	disableArray,
}: Props) => {
	const dropdownRef = React.useRef(null);

	const [siteData, setsiteData] = useState<ddl[]>([]);
	const [levelData, setlevelData] = useState<ddl[]>([]);
	const [zoneData, setzoneData] = useState<ddl[]>([]);
	const [zoneDataList, setzoneDataList] = useState<ddl[]>([]);
	const [selectedSite, setselectedSite] = useState<any>();
	const [selectedLevel, setselectedLevel] = useState<any>();

	const [dropDown, setdropDown] = useState<boolean>(false);

	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			let targt: any = dropdownRef.current;
			if (dropDown && targt && !targt.contains(e.target)) {
				setdropDown(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [dropDown, setdropDown]);

	useEffect(() => {
		fetch_site()?.then((res) => {
			let data = res?.response ?? [];
			setsiteData(data);
			setselectedSite(data[0]?.value);
			let siteId = data[0]?.value;
			fetch_floorLevel(data[0]?.value)?.then((res) => {
				let data = res?.response ?? [];
				setlevelData(data);
				setzoneData([]);
				setselectedLevel(data[0]?.value);
				if (showAll) {
					showAll({ site: siteId, level: data[0]?.value });
				}
				fetch_zone(data[0]?.value)?.then((res) => {
					let data = res?.response ?? [];
					setzoneData(data);
					setzoneDataList(data);
				});
			});
		});
	}, [showAll]);

	const fetchLevelFx = (id: number) => {
		setselectedSite(id);
		fetch_floorLevel(id)?.then((res) => {
			let data = res?.response ?? [];
			setlevelData(data);
			setzoneData([]);
			setzoneDataList([]);
		});
	};
	const fetchZoneFx = (id: number) => {
		setselectedLevel(id);
		if (showAll) {
			showAll({ site: selectedSite, level: id });
		}
		fetch_zone(id)?.then((res) => {
			let data = res?.response ?? [];
			setzoneData(data);
			setzoneDataList(data);
		});
	};

	const search_zones = (label: any) => {
		if (!label) {
			setzoneDataList(zoneData);
		} else {
			let matches = zoneData.filter((item) => {
				const regex = new RegExp(`${label}`, 'gi');
				return item.label.match(regex);
			});
			let final_list_data: any = [];
			if (matches.length === 0) return;
			matches.map((item_val, length) => {
				final_list_data.push(item_val);
				return null;
			});
			setzoneDataList(final_list_data);
		}
	};

	const removeFx = (id: number) => {
		setselectedZones((prev) => prev.filter((item) => item.value !== id));
	};

	//console.log("disableArray",disableArray) 

	//console.log("selectedZones",selectedZones)


	//console.log("SELECTED ZONES DISABLED::",disableArray ? selectedZones
	// .filter((item: any) => disableArray.find((zone: any) => zone?.value === item?.value)) : "dndh")


	const filterByReference = (arr1: ddl[], arr2: ddl[]) => {
		let res :ddl[]= [];
		res = arr1.filter((el: { value: any; }) => {
		   return !arr2.find((element: { value: any; }) => {
			  return element.value === el.value;
		   });
		});
		return res;
	 }


	return (
		<div className={`pos-rel ${classnames}`} ref={dropdownRef}>
			<div className={`new-scrollbar input-like-div${grey ? '-grey' : ''}`} onClick={() => setdropDown(true)}>
				{/* {isMulti ? (
					disableArray ? (
						<>
							{selectedZones &&
								selectedZones
									.filter((item: any) => disableArray.some((zone: any) => zone?.value === item?.value))
									.map((zones, key) => {
										return (
											<div className={`selected-item${grey ? '-grey' : ''}`} key={key}>
												<label>{zones?.label}</label>
												<div className='close-icon' onClick={() => removeFx(zones?.value)}>
													<i className='fa-solid fa-xmark'></i>
												</div>
											</div>
										);
									})}
						</>
					) : (
						selectedZones.map((item, key) => {
							return (
								<div className={`selected-item${grey ? '-grey' : ''}`} key={key}>
									<label>{item?.label}</label>
									<div className='close-icon' onClick={() => removeFx(item?.value)}>
										<i className='fa-solid fa-xmark'></i>
									</div>
								</div>
							);
						})
					)
				) : (
					<label style={{ fontSize: '14px' }}>{selectedZones && selectedZones?.length !== 0 ? selectedZones[0]?.label : ''}</label>
				)} */}
				{disableArray ? (
					<>
						{filterByReference(selectedZones ,disableArray)
								.map((zones, key) => {
									return (
										<div className={`selected-item`} key={key}>
											<span>{zones?.label}</span>
									
											{/* <span  onClick={() => removeFx(zones?.value)}>

											</span> */}
										</div>
									);
								})}
					
					</>
				) : (
					<>
						{selectedZones.map((item, key) => {
							return (
								<div className={`selected-item`} key={key}>
									<span>{item?.label}</span>
									{/* <span  onClick={() => removeFx(item?.value)}>

											</span> */}
	
								</div>
							);
						})}
			
					</>
				)}
			</div>
			<div className={dropDown ? 'dropdown-wrapper' : 'none'}>
				<div>
					{
						siteData && siteData?.length>0 ?
						siteData?.map((site, key: number) => {
						return (
							<MenuItem
								selected={selectedSite === site?.value}
								key={key}
								sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
								onClick={() => fetchLevelFx(site?.value)}
								className={selectedSite === site?.value ? 'pointer pointer-active' : ''}>
								<label className='label-dnd-menuitem'>
									<span>{site?.label}</span>
								</label>
							</MenuItem>
						);
					})
					:
						<p>No Sites</p>
					}
				</div>
				<div>
					{
					levelData && levelData?.length>0 ?
					levelData?.map((level, key: number) => {
						return (
							<MenuItem
								selected={selectedLevel === level?.value}
								key={key}
								sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
								onClick={() => fetchZoneFx(level?.value)}>
								<label className='label-dnd-menuitem'>
									<span>{level?.label}</span>
									{selectedLevel === level?.value && <i className='fa-solid fa-arrow-right'></i>}
								</label>
							</MenuItem>
						);
					})
					:
					<p>No Floors</p>
				}
				</div>

				<div>
					<Search placeholder='search zones' onChange={(e) => search_zones(e.target?.value)} style={{ width: 200 }} />
					{
						zoneDataList && zoneDataList?.length>0 ?
						<div style={{ maxHeight: '200px', overflow: 'auto' }}>
						{zoneDataList?.map((zone, key: number) => {
							return (
								<Fragment key={key}>
									{isMulti ? (
										<MenuItem
											disabled={disableArray && disableArray.some((item) => item?.value === zone?.value)}
											sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
											selected={selectedZones.some((item) => item?.value === Number(zone?.value))}>
											<label className='label-dnd-menuitem'>
												<span>{zone?.label}</span>
												<input
													type='checkbox'
													checked={selectedZones.some((item) => item?.value === Number(zone?.value))}
													onChange={(e) => {
														if (e.target?.checked) {
															setselectedZones((prev) => [...prev, zone]);
															if (onchangeFx) onchangeFx(zone);
														} else {
															setselectedZones((prev) =>
																prev.filter((item) => Number(item?.value) !== Number(zone?.value))
															);
														}
													}}
												/>
											</label>
										</MenuItem>
									) : (
										<MenuItem
											sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
											disabled={disableArray && disableArray.some((item) => item?.value === zone?.value)}
											selected={selectedZones.some((item) => item?.value === Number(zone?.value))}>
											<label className='label-dnd-menuitem'>
												<span>{zone?.label}</span>
												<input
													type='checkbox'
													checked={selectedZones.some((item) => item?.value === Number(zone?.value))}
													onChange={(e) => {
														if (e.target?.checked) {
															setselectedZones([zone]);
															if (onchangeFx) onchangeFx(zone);
														} else {
															setselectedZones((prev) =>
																prev.filter((item) => Number(item?.value) !== Number(zone?.value))
															);
														}
													}}
												/>
											</label>
										</MenuItem>
									)}
								</Fragment>
							);
						})}
					</div>
						:
							<div style={{ maxHeight: '200px', overflow: 'auto', textAlign:'center' }}>
								<p>No Zones</p>
							</div>
					}
					
				</div>
			</div>
		</div>
	);
};

export default TreeSelectCustom;
