import React, { Fragment } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

type Props = {
    name: string;
    control: Control<FieldValues, any>;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>, onFormChange: (r: any) => void) => void;
    className?: string;
    placeholder?:string
    disabled?:boolean;
    disableValidation?:boolean;
};

const FormTextarea = (props: Props) => {
    return (
        <Fragment>
            <Controller
                name={props.name}
                control={props.control}
                render={({ field: { value, onChange, onBlur } }) => (
                    <>
                        <textarea
                            className={props.className ?? 'form-control'}
                            id=''
                            disabled={props.disabled}
                            value={value ?? ''}
                            onBlur={onBlur}
                            placeholder={props.placeholder}
                            onChange={(e) => {
                                let v = props.disableValidation ? e.target.value : e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
                                if (props?.onChange) {
                                    props?.onChange(e, onChange);
                                } else {
                                    onChange(v);
                                }
                            }}
                        />
                    </>
                )}
            />
        </Fragment>
    );
};

export default FormTextarea;
