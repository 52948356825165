/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import { FailedToast, FileDownloader, SuccesToast, WarnToast } from '../../../../utils/utilFunctions';
import { downloadTemplate, uploadTemplate } from '../../store/action';
import ListTable from './components/ListTable';

type Props = {};

const DataSync = (props: Props) => {
	const [loader, setloader] = useState(false);
	const [loader2, setloader2] = useState(false);

    const fileRef : any = useRef()

    const uploadRef : any = useRef()



    const triggerFileSelectPopup = () => fileRef.current.click();

   

	const ImportTemplateFx = (e:any) => {


        let file = e.target.files[0] 

        console.log("run a",file)

        if(!file.name?.endsWith('.xlsx')) return WarnToast("only xlsx format file can be uplodaed")
     
		setloader(true);

		let d: any = new FormData();
		d.append('model', file);
		const sucess = (res:any) => {
			SuccesToast(res?.response?.data);
			setloader(false);
            fileRef.current.value = ""
		};
		const failed = (err: { response: { app_data: string | undefined } }) => {
			FailedToast(err?.response);
            fileRef.current.value = ''

			setloader(false);
		};

		uploadTemplate(d, sucess, failed ,uploadRef);
	};

	const DownloadTemplateFx = () => {
		setloader2(true);
		const sucess = (res: { response: { data: string | undefined } }) => {
            FileDownloader(res?.response ,"AllocationTemplate","xlsx")
			setloader2(false);
		};
		const failed = (err: { response: { app_data: string | undefined } }) => {
			FailedToast(err?.response?.app_data);
			setloader2(false);
		};
		downloadTemplate(sucess, failed);
	};

	return (
		<div className='main-body-content'>
			<div className='white-card data-clean mb-30'>
				<p className='col-8 light-gray'>
					Data synchronization is scheduled daily once in the morning between 5:00AM to 5:30AM you can click on the 'Sync Now' to get
					the recent updates.
				</p>
				<ListTable />
			</div>
			<div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main mb-0'>Import parking allocations</h2>
				</div>
				<p>
					To perform the bulk operation please use the provided template file to maintain the format. Bulk operation can only be succesfull for allocations of existing users.
				</p>
				<div className='text-left mb-0'>
					<button type='submit' className='btn btn-outline-blue'  onClick={triggerFileSelectPopup}>
						<ButtonLoader text='Import Parking Allocation' loading={loader} />
					</button>
					<button type='submit' className='btn btn-secondary'  onClick={DownloadTemplateFx}>
						<ButtonLoader text='Download Template' loading={loader2} />
					</button>
				</div>

                <input type="file" onChange={ImportTemplateFx} accept="*" ref={fileRef} style={{ display: "none" }} />
			</div>
		</div>
	);
};

export default DataSync;
