/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { fetch_zoneDDL } from '../../../../../common/general/action';
import { commonSearchQuery, ExtendedField, FilterProps } from '../../../../../config/filterSearch';
// import CommonInput from '../../../../../common/components/commonInput';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { exportHistory_as_excel, exportHistory_as_pdf, fetch_parking_transaction_history } from '../../../store/action';
// import AddRole from './addRole';
import dayjs from 'dayjs';
// import { Field } from 'react-querybuilder';
import QueryBuilderModal from '../../../../../common/components/query_builder';
import { DatePicker, Tooltip } from 'antd';
import { fetch_treeMap_withPermission } from '../../../../security/store/action';
import NestedSelect from '../../../../../common/components/nested_select/NestedSelect';
import PopUp from './../../../../../common/components/popUp';
import moment from 'moment';

type Props = {};
const { RangePicker } = DatePicker;

type T_Zone = {
	zoneID: number;
	zoneName: string;
};


const FilterBar = (props: Props) => {
	const [modalOpen, setmodalOpen] = useState(false);
	const handleOpen = () => setmodalOpen(true);
	const handleClose = () => setmodalOpen(false);
	const [advanceModal, setAdvanceModal] = useState(false);

	const [date, setdate] = useState<any>([moment().subtract('7','d'),moment()]);
	const [plate, setplate] = useState<any>('');
	const [ttype, setttype] = useState<any>('');

	const [selectedZone, setselectedZone] = useState<T_Zone>();
	const [selectedFloor, setselectedFloor] = useState<any>();
	const [selectedSite, setselectedSite] = useState<any>();
	const [tree, setTree] = useState<any>([]);
	const [treeLoading, settreeLoading] = useState<any>(false);
	const [expTp, setExpTp] = useState(0)

	const [queryText, setQueryText] = useState('')
	const [queryExtractDate, setQueryExtractDate] = useState<any>([])
	const [printLoading, setPrintLoading] = useState(false)

	const [filterActive, setFilterActive] = useState(false)

	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);

	const clearRef = useRef<any>();

	const dispatch = useAppDispatch();
	useEffect(() => {
		const controller = new AbortController();
		fetch_zoneDDL(dispatch, controller.signal);
		// fetch_priorityLevelDDL(dispatch, controller.signal);
		// fetch_designationDDL(dispatch, controller.signal);
		// fetch_userTypeDDL(dispatch, controller.signal);
		return () => controller.abort();
	}, [dispatch]);

	useEffect(() => {
		settreeLoading(true);
		const success = (res: any) => {
			setTree(res?.response ?? []);

			setTimeout(() => {
				settreeLoading(false);
			}, 1500);
		};
		const failed = (err: any) => {
			setTimeout(() => {
				settreeLoading(false);
			}, 1500);
		};
		fetch_treeMap_withPermission(success, failed);
	}, []);

	const PARKING_DATA = useAppSelector((state) => state?.realtime);
	const GENERAL_DDL = useAppSelector((state) => state?.general);
	const updateLoaderFn = () =>{
		setPrintLoading(false)
	}
	// const [search, setsearch] = useState('')

	const fields: ExtendedField[] = [
		// { name: 'tvm.VMSummaryID', label: 'Summary ID' },
		// { name: 'tvm.FK_UserUniqueID', label: 'User Unique ID' },
		// { name: 'FK_VehicleID', label: 'Vehicle ID' },
		{ name: 'CAST (ParkingDate AS date)', label: 'Parking Date', inputType: 'date' },

		// { name: 'FK_BayID', label: 'Bay ID' , inputType:"number"},
		{ name: 'bayName', label: 'Bay Name' },

		// { name: 'zoneID', label: 'Zone ID',inputType:"number"},
		{ name: 'zoneName', label: 'Zone Name' },

		// { name: 'tvm.FK_SiteID', label: 'Site ID',inputType:"number" },
		{ name: 'SiteName', label: 'Site Name' },

		// { name: 'tvm.FK_TransVisitID', label: 'Trans Visit ID' },
		{ name: 'tvm.IsVisitor', label: 'Visitor', 
			inputType: 'select',
			values: [
				{ name: "true", label: "YES" },
				{ name: "false", label: "NO" }
		  	]
		},
		{ name: 'IsViolation', label: 'Violation', 
			inputType: 'select',
			values: [
				{ name: "true", label: "YES" },
				{ name: "false", label: "NO" }
		  	]
	 	},

		// { name: 'CAST (tvm.CreatedOn as smalldatetime)', label: 'Create On', inputType: 'datetime' },
		// { name: 'CAST (tvm.UpdatedOn as smalldatetime)', label: 'Updated On', inputType: 'datetime' },
		// { name: 'CAST (tvm.InTime as smalldatetime)', label: 'In Time', inputType: 'datetime' },
		// { name: 'CAST (tvm.OutTime as smalldatetime)', label: 'Out Time', inputType: 'datetime' },
		// { name: 'CAST (tvm.CreatedOn as date)', label: 'Create On', inputType: 'datetime' },
		// { name: 'CAST (tvm.UpdatedOn as date)', label: 'Updated On', inputType: 'datetime' },
		{ name: "FORMAT (InTime, 'HH:mm:ss')", label: 'In Time', inputType: 'time', miscProps : { inputShowHour : true, inputShowMinute : true, inputShowSecond : true } },
		{ name: "FORMAT (OutTime, 'HH:mm:ss')", label: 'Out Time', inputType: 'time', miscProps : { inputShowHour : true, inputShowMinute : true, inputShowSecond : true } },

		// { name: 'DATEPART(HOUR, InTime)', label: 'In Time (Hour)', inputType: 'time', miscProps : { inputShowHour : true } },
		// { name: 'DATEPART(HOUR, OutTime)', label: 'Out Time (Hour)', inputType: 'time', miscProps : { inputShowHour : true } },
		// { name: 'DATEPART(MINUTE, InTime)', label: 'In Time (Minutes)', inputType: 'time', miscProps : { inputShowMinute : true } },
		// { name: 'DATEPART(MINUTE, OutTime)', label: 'Out Time (Minutes)', inputType: 'time', miscProps : { inputShowMinute : true } },



		// { name: 'Duration', label: 'Duration', inputType: 'number' },
		// { name: 'EarlyIn', label: 'Early In', inputType: 'number' },
		// { name: 'LateOut', label: 'Late Out', inputType: 'number' },
		// { name: 'tvm.UpdatedBy', label: 'Updated By' },
		// { name: 'FK_TransVisit_ID', label: 'Visit ID' },
		{ name: 'plateNumber', label: 'Plate Number'},
		{ name: 'placeOfRegistration', label: 'Place of Registration'},
		{ name: 'vehicleCategory', label: 'Vehicle Category'}
	];

	const deployQueryFilter = (query: string) => {
		setQueryText(query)
		let QueryInput: FilterProps[] = [
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[0]) ? `'${date[0].format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[1]) ? `'${date[1].format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'plateNumber',
				value: plate,
				operator: 'contains',
			},
			{
				field: 'zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'FK_LevelID',
				value: selectedFloor?.levelID,
				operator: '=',
			},
			{
				field: 'PType',
				value: ttype,
				operator: '=',
			},
		];
		let postdata = {
			'query': `${query} ${query!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.HISTORY_FILTER?.orderBy??''
		};
		setFilterActive(true)
		fetch_parking_transaction_history(dispatch, {
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.HISTORY_PAGINATION?.limit,
			},
		});
		if(query !== '' && query?.includes('CAST (ParkingDate AS date)') && 
			(query?.includes('CAST (ParkingDate AS date) >') || query?.includes('CAST (ParkingDate AS date) >=')) &&
			(query?.includes('CAST (ParkingDate AS date) <') || query?.includes('CAST (ParkingDate AS date) <='))){
			let lf = null
			let rf = null
			if(query?.indexOf('CAST (ParkingDate AS date) >')!==-1)
				lf = query?.indexOf('CAST (ParkingDate AS date) >')
			else lf = null
			if(query?.indexOf('CAST (ParkingDate AS date) <')!==-1)
				rf = query?.indexOf('CAST (ParkingDate AS date) <')
			else rf = null

			if(rf!==null && lf!==null){
				let dtLen = ` 'YYYY-MM-DD'`.length
				let lfTextLength = query?.indexOf('CAST (ParkingDate AS date) >=')===lf && query?.indexOf('CAST (ParkingDate AS date) >=')!==-1 ? 'CAST (ParkingDate AS date) >='.length : 'CAST (ParkingDate AS date) >'.length
				let lfText = query.substring(lf+lfTextLength,lf+lfTextLength+dtLen)

				let rfTextLength = query?.indexOf('CAST (ParkingDate AS date) <=')===rf && query?.indexOf('CAST (ParkingDate AS date) <=')!==-1 ? 'CAST (ParkingDate AS date) <='.length : 'CAST (ParkingDate AS date) <'.length
				let rfText = query.substring(rf+rfTextLength,rf+rfTextLength+dtLen)

				lfText = lfText.replaceAll(/'|=/gi,'')
				rfText = rfText.replaceAll(/'|=/gi,'')
				//console.log([lfText,rfText],query,rf,lf)
				setQueryExtractDate([lfText.trim(),rfText.trim()])
			}else{
				setQueryExtractDate([])
			}

		}else{
			setQueryExtractDate([])
		}
		advancemodalClose();
		setdate('')
		setplate('')
		setselectedZone(undefined)
		setselectedFloor(undefined)
		setttype('')
	};


	const filterSearch = () => {
		setQueryText('')
		setFilterActive(true)
		let QueryInput: FilterProps[] = [
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[0]) ? `'${date[0].format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[1]) ? `'${date[1].format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'plateNumber',
				value: plate,
				operator: 'contains',
			},
			{
				field: 'zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'FK_LevelID',
				value: selectedFloor?.levelID,
				operator: '=',
			},
			{
				field: 'PType',
				value: ttype,
				operator: '=',
			},
		];
		let postdata = {
			'query': `${queryText} ${queryText!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.HISTORY_FILTER?.orderBy??''
		};
		fetch_parking_transaction_history(dispatch, {
			'filters': postdata,
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.HISTORY_PAGINATION?.limit,
			},
		});

		// fetch_rolelist(navigate, dispatch, posdData)
	};

	const clearSearch = () => {
		setFilterActive(false)
		fetch_parking_transaction_history(dispatch, {
			'filters': {
				'query': `CAST (ParkingDate AS date) >= '${moment().subtract(7,'days').format('YYYY-MM-DD')}' AND CAST (ParkingDate AS date) <= '${moment().format('YYYY-MM-DD')}' `,
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_DATA?.HISTORY_PAGINATION?.limit,
			},
		});
		setdate([moment().subtract('7','d'),moment()])
		setQueryText('')
		setplate('')
		setselectedZone(undefined)
		setselectedFloor(undefined)
		setttype('')
	};

	const exportAsPdf = () =>{
		setPrintLoading(true)

        let QueryInput: FilterProps[] = [
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[0]) ? `'${dayjs(date[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[1]) ? `'${dayjs(date[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'Plate_No',
				value: plate,
				operator: 'like',
			},
			{
				field: 'zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'PType',
				value: ttype,
				operator: '=',
			},
		];
		let queryTextVal = PARKING_DATA?.HISTORY_FILTER?.query
		let dateArr:any = []

		if(queryTextVal !== '' && queryTextVal?.includes('CAST (ParkingDate AS date)') && 
			(queryTextVal?.includes('CAST (ParkingDate AS date) >') || queryTextVal?.includes('CAST (ParkingDate AS date) >=')) &&
			(queryTextVal?.includes('CAST (ParkingDate AS date) <') || queryTextVal?.includes('CAST (ParkingDate AS date) <='))){
			let lf = null
			let rf = null
			if(queryTextVal?.indexOf('CAST (ParkingDate AS date) >')!==-1)
				lf = queryTextVal?.indexOf('CAST (ParkingDate AS date) >')
			else lf = null
			if(queryTextVal?.indexOf('CAST (ParkingDate AS date) <')!==-1)
				rf = queryTextVal?.indexOf('CAST (ParkingDate AS date) <')
			else rf = null

			if(rf!==null && lf!==null){
				let dtLen = ` 'YYYY-MM-DD'`.length
				let lfTextLength = queryTextVal?.indexOf('CAST (ParkingDate AS date) >=')===lf && queryTextVal?.indexOf('CAST (ParkingDate AS date) >=')!==-1 ? 'CAST (ParkingDate AS date) >='.length : 'CAST (ParkingDate AS date) >'.length
				let lfText = queryTextVal.substring(lf+lfTextLength,lf+lfTextLength+dtLen)

				let rfTextLength = queryTextVal?.indexOf('CAST (ParkingDate AS date) <=')===rf && queryTextVal?.indexOf('CAST (ParkingDate AS date) <=')!==-1 ? 'CAST (ParkingDate AS date) <='.length : 'CAST (ParkingDate AS date) <'.length
				let rfText = queryTextVal.substring(rf+rfTextLength,rf+rfTextLength+dtLen)

				lfText = lfText.replaceAll(/'|=/gi,'')
				rfText = rfText.replaceAll(/'|=/gi,'')
				//console.log([lfText,rfText],query,rf,lf)
				dateArr = [lfText.trim(),rfText.trim()]
			}else{
				dateArr = []
			}
		}
		let postdata = {
			'query': `${queryText} ${queryText!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.TRANSACTION_FILTER?.orderBy??''
		};
        exportHistory_as_pdf(
            {
				'ToDate'	:	queryExtractDate&&queryExtractDate?.length>0 ?
					moment(queryExtractDate[1]).format('YYYY-MM-DD') : (dateArr && dateArr[1]) ? `${moment(dateArr[1]).format('YYYY-MM-DD')}` : null,
    			'FromDate'	:	queryExtractDate&&queryExtractDate?.length>0 ?
					moment(queryExtractDate[0]).format('YYYY-MM-DD') :( dateArr && dateArr[0]) ? `${moment(dateArr[0]).format('YYYY-MM-DD')}` : null,
                'filters'	: postdata,
                'pagination': {
                    'pageNo': 1,
                    'limit': PARKING_DATA?.HISTORY_PAGINATION?.totalCount,
                },
            },updateLoaderFn
        )
    }

	const exportAsExcel = () =>{
		setPrintLoading(true)

        let QueryInput: FilterProps[] = [
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[0]) ? `'${dayjs(date[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: `CAST (ParkingDate AS date)`,
				value: ( date && date[1]) ? `'${dayjs(date[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'Plate_No',
				value: plate,
				operator: 'like',
			},
			{
				field: 'zoneID',
				value: selectedZone?.zoneID,
				operator: '=',
			},
			{
				field: 'PType',
				value: ttype,
				operator: '=',
			},
		];
		let queryTextVal = PARKING_DATA?.HISTORY_FILTER?.query
		let dateArr:any = []

		if(queryTextVal !== '' && queryTextVal?.includes('CAST (ParkingDate AS date)') && 
			(queryTextVal?.includes('CAST (ParkingDate AS date) >') || queryTextVal?.includes('CAST (ParkingDate AS date) >=')) &&
			(queryTextVal?.includes('CAST (ParkingDate AS date) <') || queryTextVal?.includes('CAST (ParkingDate AS date) <='))){
			let lf = null
			let rf = null
			if(queryTextVal?.indexOf('CAST (ParkingDate AS date) >')!==-1)
				lf = queryTextVal?.indexOf('CAST (ParkingDate AS date) >')
			else lf = null
			if(queryTextVal?.indexOf('CAST (ParkingDate AS date) <')!==-1)
				rf = queryTextVal?.indexOf('CAST (ParkingDate AS date) <')
			else rf = null

			if(rf!==null && lf!==null){
				let dtLen = ` 'YYYY-MM-DD'`.length
				let lfTextLength = queryTextVal?.indexOf('CAST (ParkingDate AS date) >=')===lf && queryTextVal?.indexOf('CAST (ParkingDate AS date) >=')!==-1 ? 'CAST (ParkingDate AS date) >='.length : 'CAST (ParkingDate AS date) >'.length
				let lfText = queryTextVal.substring(lf+lfTextLength,lf+lfTextLength+dtLen)

				let rfTextLength = queryTextVal?.indexOf('CAST (ParkingDate AS date) <=')===rf && queryTextVal?.indexOf('CAST (ParkingDate AS date) <=')!==-1 ? 'CAST (ParkingDate AS date) <='.length : 'CAST (ParkingDate AS date) <'.length
				let rfText = queryTextVal.substring(rf+rfTextLength,rf+rfTextLength+dtLen)

				lfText = lfText.replaceAll(/'|=/gi,'')
				rfText = rfText.replaceAll(/'|=/gi,'')
				//console.log([lfText,rfText],query,rf,lf)
				dateArr = [lfText.trim(),rfText.trim()]
			}else{
				dateArr = []
			}
		}
		let postdata = {
			'query': `${queryText} ${queryText!=='' && commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND ` : ''} ${commonSearchQuery(QueryInput, 'AND')}`,
			'orderBy' : PARKING_DATA?.TRANSACTION_FILTER?.orderBy??''
		};
        exportHistory_as_excel(
            {
				// 'ToDate'	:	queryText==='' && (dateArr && dateArr[1]) ? `${moment(dateArr[1]).format('YYYY-MM-DD')}` : null,
    			// 'FromDate'	:	queryText==='' && (dateArr && dateArr[1]) ? `${moment(dateArr[0]).format('YYYY-MM-DD')}` : null,
				
				'ToDate'	:	queryExtractDate&&queryExtractDate?.length>0 ?
					moment(queryExtractDate[1]).format('YYYY-MM-DD') : (dateArr && dateArr[1]) ? `${moment(dateArr[1]).format('YYYY-MM-DD')}` : null,
    			'FromDate'	:	queryExtractDate&&queryExtractDate?.length>0 ?
					moment(queryExtractDate[0]).format('YYYY-MM-DD') :( dateArr && dateArr[0]) ? `${moment(dateArr[0]).format('YYYY-MM-DD')}` : null,
                
					'filters'	: postdata,
                'pagination': {
                    'pageNo': 1,
                    'limit': PARKING_DATA?.HISTORY_PAGINATION?.totalCount,
                },
            },updateLoaderFn
        )
    }


	const newLocal = (
		<div className='input-like-div pos-rel' style={{ overflow: 'hidden' }}>
			{selectedZone?.zoneName ? selectedZone?.zoneName : <span style={{ fontSize: '14px', color: "var(--disabledText)" }}> Select Zone</span>}
			<div
				style={{
					position: 'absolute',
					right: '10px',
					borderLeft: '1px solid var(--lightgrey-second)',
					height: '30px',
					padding: '8px 0px 10px 10px',
				}}>
				<i className='fa fa-chevron-down' style={{ color: 'var(--lightgrey-second)' }} />
			</div>
		</div>
	);

	const parentRef: any = useRef(null);

	const childRef: any = useRef(null);

	const handleCloseModal = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	React.useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu then close the menu
			let targt: any = parentRef.current;
			if (targt && !targt.contains(e.target)) {
				handleCloseModal();
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, []);

	const doesFilterHasKey = () =>{
		console.log('PARKING_DATA?.HISTORY_FILTER?.query',PARKING_DATA?.HISTORY_FILTER?.query)
		if (PARKING_DATA?.HISTORY_FILTER?.query !== '' && PARKING_DATA?.HISTORY_FILTER?.query?.includes('CAST (ParkingDate AS date)')){
			let lF = false
			let rF = false
			if(PARKING_DATA?.HISTORY_FILTER?.query?.includes('CAST (ParkingDate AS date) >') || PARKING_DATA?.HISTORY_FILTER?.query?.includes('CAST (ParkingDate AS date) >='))
				lF = true 
			else lF = false

			if(PARKING_DATA?.HISTORY_FILTER?.query?.includes('CAST (ParkingDate AS date) <') || PARKING_DATA?.HISTORY_FILTER?.query?.includes('CAST (ParkingDate AS date) <='))
				rF = true 
			else rF = false

			return lF && rF
		}else 
		if (PARKING_DATA?.HISTORY_FILTER?.query !== ''){
			return true
		}
		else return false
	}


	return (
		<>
			<div className='search-filter-row mb-30'>
				<div className='row'>
					<div className='col-8'>
						<ul>
							<li>
								<a className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							<li>
								<a onClick={() => advancemodalOpen()}>
									<i className='fa-solid fa-filter'></i>Advanced Filter
								</a>
							</li>
							{filterActive && (
								<li>
									<a onClick={clearSearch}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
							{
								doesFilterHasKey() ? (
									<li
									className={
										doesFilterHasKey()
											? ''
											: 'disableAndOpacity'
									}
									>
									{/* <a onClick={exportAsPdf}>
										<i className='fa-solid fa-download'></i>Export as PDF
									</a> */}
									
									<ReactSelect
										options={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]}
										className={'common-select common-select-grey fontSize13Px common-select-exporter'}
										
										placeholder={'Export Report'}
										isMulti={false}
										isClearable={false}
										isSearchable={false}
										isLoading={printLoading}
										value={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]?.find((c) => c.value === expTp) ?? ''}
										onChange={(val: any) => {
											setExpTp(val?.value);
											if(val?.value===0){
												exportAsPdf()
											}else{
												exportAsExcel()
											}
										}}
									/>
								</li>
								)
								:
								(
									<Tooltip title={'Please Select a date range to export.'}>
								<li>
									<ReactSelect
										options={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]}
										className={'common-select disableAndOpacity fontSize13Px common-select-exporter'}
										placeholder={'Export Report'}
										isMulti={false}
										isClearable={false}
										isSearchable={false}
										
										value={[
											{ value : 0, label : 'Export as PDF'},
											{ value : 1, label : 'Export as Excel'}
										]?.find((c) => c.value === expTp) ?? ''}
										onChange={(val: any) => {
											setExpTp(val?.value);
										}}
									/>
								</li>
							</Tooltip>
								)
							}
							{/* <li className={
								date&&date?.length===2&&
									PARKING_DATA?.HISTORY_FILTER?.query?.includes('ParkingDate') ? '' : 'disableAndOpacity2'}>
                        				<a onClick={exportAsPdf}>
                        				    <i className='fa-solid fa-download'></i>Export as PDF
                        				</a>
                    		</li> */}
						</ul>
					</div>
				</div>

				<div className='filter-block mb-30' id='quick-search'>
					<>
						<div className='row'>
							<div className='col'>
								<div className='form-group'>
									{/* <input type="Date" name="" placeholder="Date" className="form-control" /> */}
									{/* <DatePicker
										picker='date'
										onChange={(date) => {
											setdate(date)
										}}
										format={'DD-MMM-YYYY'}
										className="form-control"
										placeholder="Parking Date"
									/> */}
									{/* <ReactDatePicker
										selected={date}
										onChange={(date: any) => {
											setdate(date);
										}}
										selectsStart
										dateFormat={'dd-MMM-yyyy'}
										className='form-control'
										placeholderText='mm/dd/yyyy'
									/> */}
									<RangePicker
									size='large'
									placeholder={['Parking Date','']}
									onChange={(date: any) => setdate(date)}
									value={date}
									allowClear={false}
									inputReadOnly
									format={'DD-MMM-YYYY'}
									defaultValue={[moment().subtract('7','d'),moment()]}

                            className='form-control'

								/>
								</div>
							</div>

							<div className='col'>
								<div className='form-group'>
									<input
										type='text'
										name=''
										placeholder='Plate Number'
										className='form-control'
										value={plate}
										onChange={(e) => setplate(e.target.value)}
									/>
								</div>
							</div>
							<div className='col'>
								{/* <div className='form-group'>
									<ReactSelect
										ref={clearRef}
										options={GENERAL_DDL?.ZONE_DDL}
										className={'common-select'}
										placeholder={'zone'}
										isMulti={false}
										isClearable
										value={zone ? GENERAL_DDL?.ZONE_DDL?.find((c) => c.value === zone) : null}
										onChange={(val: any) => {
											setzone(val?.value);
										}}
									/>
								</div> */}


								<PopUp icon={newLocal} placement='bottom-start' style={{ paddingTop: '5px' }} ref={childRef}>
									<NestedSelect
										selectedZone={selectedZone}
										setselectedZone={setselectedZone}
										tree={tree}
										ref={parentRef}
										selectedFloor={selectedFloor}
										setselectedFloor={setselectedFloor}
										setselectedSite={setselectedSite}
										style={{ width: "18vw" }}

									/>
								</PopUp>
							</div>

							{/* <div className='col'>
								<div className='form-group'>
									<ReactSelect
										ref={clearRef}
										options={tType}
										className={'common-select'}
										placeholder={'Transaction type'}
										isMulti={false}
										isClearable
										value={tType?.find((c) => c.value === ttype) ?? ''}
										onChange={(val: any) => {
											setttype(val?.value);
										}}
									/>
								</div>
							</div> */}

							<div className='col-2'>
								<div className='form-group'>
									<button className='form-control blue-btn' onClick={filterSearch}>
										Search
									</button>
								</div>
							</div>
						</div>
					</>
				</div>
				<QueryBuilderModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
			</div>
		</>
	);
};

export default FilterBar;
