import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { FormatDate } from '../../../../../utils/utilFunctions';
import { search_user } from '../../../store/action';
import { T_UserResponse, T_WebuserRequest } from '../../../store/security.interface';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: T_WebuserRequest;
};

const ViewModal = (props: Props) => {

	const [RequestedForDetail, setRequestedForDetail] = useState<T_UserResponse>()
	const [RequestedByDetail, setRequestedByDetail] = useState<T_UserResponse>()


	useEffect(() => {
		let forData =   {
			"filters": {
				"query": `userUniqueID = ${props?.Data?.requestedFor}`
			},
			"pagination": {
				"pageNo": 1,
				"limit": 1
			}
		}
		let byData =   {
			"filters": {
				"query": `userUniqueID = ${props?.Data?.requestedBy}`
			},
			"pagination": {
				"pageNo": 1,
				"limit": 1
			}
		}
		search_user(forData)?.then(res=>{
			setRequestedForDetail(res?.response?.data[0] ?? {})
		})

		search_user(byData)?.then(res=>{
			setRequestedByDetail(res?.response?.data[0] ?? {})
		})
	}, [props?.Data?.requestedBy, props?.Data?.requestedFor])
	

	console.log("PROPS DATA", props?.Data)
	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title='Requested Details' subtitle='' big>
				<div className='modal-body'>

				<div className='row'>
						<div className='col-12'>
							<div className='modal-header border-none'>
								<h2 className='border-title'>Requested By</h2>
							</div>
						</div>
					</div>
				
					<div className='row'>
						<div className='col-3'>
							<div className='icon-box'>
								<div className='icon-wrap'>
									<i className='fa-solid fa-user'></i>
								</div>
								<h3>
									Username <span>{RequestedByDetail?.firstName}</span>
								</h3>
							</div>
						</div>

						<div className='col-3'>
							<div className='icon-box'>
								<div className='icon-wrap'>
									<i className='fa-solid fa-heading'></i>
								</div>
								<h3>
									User ID <span>{RequestedByDetail?.userID && RequestedByDetail?.userID!=='' ? RequestedByDetail?.userID : `EMP_${ RequestedByDetail?.userUniqueID}`}</span>
								</h3>
							</div>
						</div>

						<div className='col-3'>
							<div className='icon-box'>
								<div className='icon-wrap'>
									<i className='fa-solid fa-envelope'></i>
								</div>
								<h3 style={{ wordBreak : 'break-all' }}>
									User Email <span>{RequestedByDetail?.emailID}</span>
								</h3>
							</div>
						</div>

						<div className='col-3'>
							<div className='icon-box'>
								<div className='icon-wrap'>
									<i className='fa-solid fa-phone'></i>
								</div>
								<h3>
									User Contact <span>123 {RequestedByDetail?.contactNo}</span>
								</h3>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-12'>
							<div className='modal-header border-none mt-10'>
								<h2 className='border-title'>User Responsibility</h2>
								<p>
									{props?.Data?.userResponsibilities}
								</p>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-7'>
							<div className='table-details'>
								<div>
									<div className='border-title-row flex-1'>
										<h2 className='border-title'>Request For</h2>
										{/* <h2 className='border-title'>
											Role <label className='badge green'>Operator</label>
										</h2> */}
									</div>
									<div className='dflex'>
										<ul>
											<li>
												Username <span>{RequestedForDetail?.firstName}</span>
											</li>
											{/* <li>
												User ID <span>{RequestedForDetail?.userID}</span>
											</li> */}
											<li>
												User Email <span>{RequestedForDetail?.emailID}</span>
											</li>
											<li>
												Designation <span>{RequestedForDetail?.desName}</span>
											</li>
											<li>
												Start Date <span>{FormatDate(props?.Data?.startDate)}</span>
											</li>
										</ul>
										<div className='seperator'></div>
										<ul>
											<li>
												Company <span>{RequestedForDetail?.companyName}</span>
											</li>
											<li>
												Contact No <span>{RequestedForDetail?.contactNo}</span>
											</li>
											<li>
												Personal Grade <span>{RequestedForDetail?.fK_PriorityLevel}</span>
											</li>
											<li>
												Site <span>{RequestedForDetail?.siteName}</span>
											</li>
											<li>
												End Date <span>{FormatDate(props?.Data?.endDate)}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div className='col-5'>
							<div className='table-details'>
								<div>
									<div className='border-title-row flex-1'>
										<h2 className='border-title'>Zone Info</h2>
									</div>

									<div className='big-badge'>
										{/* <button className='big-badge-button'>Zone 1</button>
										<button className='big-badge-button'>Zone 2</button> */}
										{
											props?.Data?.zones && props?.Data?.zones.map((item)=>{
												return(
													<button className='big-badge-button' style={{ margin:'5px'}}>{item?.zoneName}</button>
												)
											})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</ModalLayout>
	);
};

export default ViewModal;
