import Request from "../../../config/api";
import { PARKING_POLICY, POLICIES_ASSIGNED_ZONES, POLICY_ASSIGNMENT, VIOLATION_POLICY } from "../../../config/endpoint"
import { UPDATE_PARKING_POLICY_DATA, UPDATE_PARKING_POLICY_ERROR, UPDATE_PARKING_POLICY_LOADER, UPDATE_POLICY_ASSIGNMENT_DATA, UPDATE_POLICY_ASSIGNMENT_ERROR, UPDATE_POLICY_ASSIGNMENT_LOADER, UPDATE_POLICY_ASSIGNMENT_ZONESDATA, UPDATE_VIOLATION_POLICY_DATA, UPDATE_VIOLATION_POLICY_ERROR, UPDATE_VIOLATION_POLICY_LOADER } from "./policyslice";
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';


export const fetch_parking_policy = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = PARKING_POLICY
    dispatch(UPDATE_PARKING_POLICY_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_PARKING_POLICY_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
                UPDATE_PARKING_POLICY_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}


export const parking_policy_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = PARKING_POLICY+'action/';
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const parking_policy_detail = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = PARKING_POLICY+ id + '/';
    Request('get', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const delete_parking_policy = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = PARKING_POLICY+'action/' + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};



export const fetch_violation_policy = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = VIOLATION_POLICY
    dispatch(UPDATE_VIOLATION_POLICY_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_VIOLATION_POLICY_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
                UPDATE_VIOLATION_POLICY_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}


export const violation_policy_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = VIOLATION_POLICY+'action/';
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const violation_policy_detail = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = VIOLATION_POLICY+ id + '/';
    Request('get', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const delete_violation_policy = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = VIOLATION_POLICY+'action/' + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const fetch_policy_assignment = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = POLICY_ASSIGNMENT
    dispatch(UPDATE_POLICY_ASSIGNMENT_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_POLICY_ASSIGNMENT_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
                UPDATE_POLICY_ASSIGNMENT_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}

export const policy_assignment_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = POLICY_ASSIGNMENT+'action/';
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const fetch_policy_assigned_Zones = (dispatch: any, postData:Object, signal?: AbortSignal) =>{
    let api_url = POLICY_ASSIGNMENT
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_POLICY_ASSIGNMENT_ZONESDATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
        }
    })
}

export const fetch_zones_assigned_toDates = (postData:Object, Sucess: (res: any) => void) =>{
    let api_url = POLICIES_ASSIGNED_ZONES
    Request('post',api_url,postData,undefined,Sucess)
}

export const delete_assigned_zone = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = POLICY_ASSIGNMENT+'action/' + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};