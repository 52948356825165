/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../assets/images/common/adnoc-logo.jpg';
import notifications from '../../assets/images/icons/notifications.svg';
import { routes } from '../../config/routes';
import { get_keyword } from '../../utils/getKeyword';
import useCurrentPath from '../../utils/hooks/useCurrentPath';
import NotificationPopup from './Notification/NotificationPopup';
import { useAppSelector } from '../../config/hooks';

const Navbar = ({ toggle }: { toggle: () => void }) => {
	const { current_tab } = useCurrentPath();

	const Route1: any = routes.find((item) => item.path === current_tab + '/*');
	const Route2: any = routes.find((item) => item.path === current_tab);

	const [viewModal, setviewModal] = useState(false);
	const pendingNotify: any = useAppSelector((state) => state?.general?.NOTIFICATIONS_UNREAD);

	const viewModalOpen = () => {
		setviewModal(!viewModal);
	};

	const dropdownRef = React.useRef(null);

	React.useEffect(() => {
	  const checkIfClickedOutside = (e: any) => {
		let targt: any = dropdownRef.current;
		if (viewModal && targt && !targt.contains(e.target)) {
		  setviewModal(false);
		}
	  };
	  document.addEventListener('mousedown', checkIfClickedOutside);
	  return () => {
		// Cleanup the event listener
		document.removeEventListener('mousedown', checkIfClickedOutside);
	  };
	}, [viewModal, setviewModal]);

	const NotifyBell = React.useMemo(()=>{
		return(
			<a  onClick={viewModalOpen}>
				<img src={notifications} alt='icon' title='Notification' />
			{
				pendingNotify > 0 ?
				<div className='notify-drop'>
					<p style={{ color:'white' }}>
						{pendingNotify > 9 ? '+9' : pendingNotify}
					</p>
				</div>
				: null
			}
			
			</a>
		)
	},[pendingNotify])

	return (
		<>
			<div className='navigation-topbar'>
				<div className='dflex align-items-center justify-space-between w-100'>
					<a className='toggle-button m-0' onClick={toggle} >
						<i className='fa-solid fa-bars'></i>
					</a>
					<div className='dflex col-dir mr-auto ' style={{ textTransform:"uppercase" , marginTop:"10px" ,gap:"5px"}}>
						<h1 className='p-0 m-0' style={{ fontSize:'1rem' ,}}>{Route1 ? get_keyword(Route1?.name) : get_keyword(Route2?.name)} </h1>
						<p style={{ fontSize:'.7rem'  }}>Parking Management System</p>
					</div>
					<div className='navigation-row'>
						<div className='navigation-bar'>
							{/* {<a  onClick={viewModalOpen}>
								<img src={notifications} alt='icon' title='Notification' />
								{
									pendingNotify > 0 ?
									<p className='notify-drop'>
										<span style={{ color:'white' }}>{pendingNotify > 9 ? '+9' : pendingNotify}</span>
									</p>
									: null
								}
								
							</a>} */}
							{NotifyBell}
							{
								<div className={viewModal ? 'navigation-wrapper' : ''}>
									<NotificationPopup dropdownRef={dropdownRef}/>
								</div>
							}
						</div>
						<div className='adnoc-logo' style={{ cursor : "pointer"}}><img src={logo} alt="logo" title="ADNOC"  onClick={()=>{ window.location.href = '/'}}/></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
