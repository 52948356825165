import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { UserPermssion } from '../../../../../config/permissionLocal';
import { BackendToFrontendPermission, FailedToast, FrontendTobackend, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_general_dashboard_settings, fetch_rolelist, role_action } from '../../../store/action';
import { FrontendPermision, IroleItem, submodule, TypePostData, Data } from './types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../../common/components/FormInput';
import ErrorComponent from '../../../../../common/components/ErrorComponent';

// import {  FrontendPermision }

type Props = {
	handleClose: () => void;
	open: boolean;
	Data?: Data;
};


const schema = yup.object().shape({
	roleName : yup.string().typeError('Please enter a valid name').required('Please enter a role name').matches(/^[a-zA-Z_\s]+$/,'Name cannot have any characters other than alphabets or underscore').max(70,'Name cannot exceed more than 70 characters'),
	moduleList : yup.boolean().oneOf([true], 'At least one module must be checked').required('At least one module must be checked')
});

const AddRole = ({ handleClose, open, Data }: Props) => {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { trigger, handleSubmit, formState : {errors}, reset, control, setValue, clearErrors, watch} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		reValidateMode: 'onBlur',
		shouldFocusError: true,
	});

	let Dummy: FrontendPermision[] = JSON.parse(JSON.stringify(UserPermssion));

	const SECURITY_DATA = useAppSelector((state) => state?.security);

	const [PERMISSION_DATA, SETPERMISSION_DATA] = useState([...Dummy]);
	const [RoleID, setRoleID] = useState<string | number | null>(null);

	const [RoleName, setRoleName] = useState('');
	const [Remarks, setRemarks] = useState('');
	const [DashboardType, setDashboardType] = useState(1);

	const [loading, setloading] = useState(false);

	const handleCloseReset = () => {
		SETPERMISSION_DATA([...Dummy]);
		setDashboardType(1);
		setRemarks('');
		setRoleName('');
		reset();	clearErrors();
		handleClose();
	};

	// const FrontendToBackendPermission = (new_F_Permission: FrontendPermision[], current_B_Permission: BackendPermision[]) => {

	// 	return null
	// }

	const SelectRole = (CurrentPermissionModule: FrontendPermision[], ModuleId: number, SubModleId: number, newPermissionObject: submodule) => {
		let SelectedPermissionData = [...CurrentPermissionModule];
		let ModuleIndex = SelectedPermissionData.findIndex((item) => item?.module_id === ModuleId);
		let SelectedModule = SelectedPermissionData[ModuleIndex];
		let SelectedSubModuleIndex = SelectedModule.sub_modules.findIndex((item) => item?.fK_SubModuleID === SubModleId);
		SelectedModule.sub_modules[SelectedSubModuleIndex] = newPermissionObject;
		SelectedPermissionData[ModuleIndex] = SelectedModule;
		setValue('moduleList', 
			SelectedPermissionData?.some(el =>
				el?.sub_modules?.some(em => 
					em?.isCreate || em?.isDelete || em?.isEdit || em?.isView || el?.full_control ) ) ? true : false)
		trigger('moduleList')
		
		SETPERMISSION_DATA(SelectedPermissionData);
	};
	console.log('WAtch',watch('moduleList'),errors)
	useEffect(() => {
		if (Data) {

			console.log("privileges", Data?.privileges)
			let newData = BackendToFrontendPermission(PERMISSION_DATA, Data?.privileges);
			console.log('new permission', newData);
			SETPERMISSION_DATA(newData);
			setRoleName(Data?.roleName);
			setRoleID(Data?.roleID || null);
			setRemarks(Data?.remarks);
			setDashboardType(Data?.dashboardType);
			reset({
				roleName 	: Data?.roleName,
				moduleList 	: newData?.some(el =>
					el?.sub_modules?.some(em => 
						em?.isCreate || em?.isDelete || em?.isEdit || em?.isView || el?.full_control ) ) ? true : false 
			});
			trigger()
		}else{
			setValue('moduleList', false)
		}
		clearErrors()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Data]);

	const RoleActionFx = () => {
		trigger('roleName')
		trigger('moduleList')
		console.log('ERROR',errors)
		console.log('WAtch',watch('moduleList'))
		let privileges = FrontendTobackend(PERMISSION_DATA);

		let postData: TypePostData = {
			RoleID: RoleID ? RoleID : null,
			RoleName,
			Remarks,
			DashboardType,
			privileges,
			IsEnable: true,
		};
		setloading(true);
		//console.log("sdsad", FrontendTobackend(PERMISSION_DATA))
		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			SETPERMISSION_DATA(UserPermssion);
			setloading(false);

			setRoleName('');
			setRoleID('');
			setRemarks('');
			setDashboardType(1);
			handleCloseReset();
			reset()
			fetch_rolelist(navigate, dispatch, {
				'filters': {
					'query': SECURITY_DATA?.ROLES_FILTER?.query || '',
				},
				'pagination': {
					'pageNo': SECURITY_DATA?.ROLES_PAGINATION?.pageNo,
					'limit': SECURITY_DATA?.ROLES_PAGINATION?.limit,
				},
			});
		};
		const Failed = (err: any) => {
			setloading(false);

			FailedToast(err?.response);
		};

		if (Data) {
			role_action('put', postData, Success, Failed);
		} else {
			role_action('post', postData, Success, Failed);
		}
	};

	const GeneralDashboards: any = useAppSelector((state) => state?.security?.GENERAL_DASHBOARD_LIST_DATA);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		fetch_general_dashboard_settings(
			dispatch,
			{
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 1000,
				},
			},
			signal
		);
		return () => {
			controller.abort();
		};
	}, [dispatch]);

	// log(PERMISSION_DATA, 'PERMISSION DATA');

	return (
		<ModalLayout title={Data ? 'Update Role' : 'Add New Role'} subtitle='User Management' handleClose={handleCloseReset} open={open} big={true}>
			<form onSubmit={handleSubmit(RoleActionFx)}>
				<div className='modal-body-content'>


					<div className='row'>
						<div className='col-5'>
							{/* <div className='form-group inline-input'>
							<label>ID</label>
							<input type='text' name='' className='form-control grey-input' defaultValue={RoleID || ''} disabled={true} />
						</div> */}

							<div className='form-group '>
								{/* <label className='ml-5' style={{ fontSize:"14px"}}>Role Name</label> */}
								<FormInput type='text' name="roleName" 
									placeholder='Role Name'
									// value={RoleName}
									onChange={(e) => {
										const value = e.target.value.replace(/[^a-zA-Z0-9_\s]/g, '');

										setRoleName(value);
										setValue('roleName',value)
									}}
									className='form-control grey-input' 
									control={control} />
									<ErrorComponent name='roleName' errors={errors} title="Role Name" />
								{/* <input
									type='text'
									name='roleName'
									placeholder='Role Name'
									value={RoleName}
									onChange={(e) => {
										const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');

										setRoleName(value);
									}}
									className='form-control grey-input'
								/> */}
							</div>
						</div>
						<div className='col-7'>

							<div className='form-group '>
								{/* <label className='ml-5' style={{ fontSize:"14px"}}>Description</label> */}

								<input
									placeholder='Description'
									value={Remarks}
									onChange={(e) => {
										const v = e.target.value.replace(/[^a-zA-Z0-9_\s]/g, '');

										setRemarks(v)}}
									className='form-control grey-input'></input>

							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-12'>
							<div className='reponsive-table scroll-height show-scrollbar '>
								<table className='style-table mobile-responsive permission-table'>
									<thead className='sticky-top'>
										<tr>
											<th className='col-2'>Module Name</th>
											<th className='col-2'>View</th>
											<th className='col-2'>Create</th>
											<th className='col-2'>Edit</th>
											<th className='col-2'>Delete</th>
											<th className='col-2'>Full Control</th>
										</tr>
									</thead>

									<tbody>
										{PERMISSION_DATA.map((item, key) => {
											return (
												<Fragment key={key}>
													<tr>
														<td colSpan={6}>
															<table className='grey-table'>
																<tbody>
																	<tr>
																		<td colSpan={6}>
																			<h3>{item?.module_name}</h3>
																		</td>
																	</tr>
																	{item?.sub_modules.map((i, key) => {
																		return (
																			<Fragment key={key}>
																				<RoleItem
																					sub_module={i}
																					moduleId={item?.module_id}
																					currentPermission={PERMISSION_DATA}
																					SelectRole={SelectRole}
																				/>
																			</Fragment>
																		);
																	})}
																</tbody>
															</table>
														</td>
													</tr>
												</Fragment>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<ErrorComponent name='moduleList' errors={errors} title="Module" />
					<div className='row'>
						<div className='col-12'>
							<table className='style-table'>
								<thead>
									<tr>
										<th>Other Configration</th>
									</tr>
								</thead>
							</table>
						</div>
					</div>

					<div className='row'>
						<div className='col-12'>
							<div className='row'>
								{GeneralDashboards.map((item: any) => {
									return (
										<>
											<div className='col-4'>
												<div className='borderbox'>
													<div className='radio'>
														<input
															type='radio'
															checked={DashboardType === item?.dashboardID ? true : false}
															onChange={() => setDashboardType(item?.dashboardID)}
															name='dashboard'
															value={item?.name}
														/>
														<label>
															<span className="capitalize" style={{ fontSize: "14px" }}>{item?.name}</span>
														</label>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</div>

							{/* <div className='row'>
							<div className='col-4'>
								<div className='borderbox'>
									<div className='radio'>
										<input type='radio' checked={DashboardType === 1 ? true : false} onChange={() => setDashboardType(1)} name='dashboard' value='Dashboard 1' />
										<label>
											<span>Dashboard 1</span>
										</label>
									</div>
								</div>
							</div>
							<div className='col-4'>
								<div className='borderbox'>
									<div className='radio'>
										<input type='radio' checked={DashboardType === 2 ? true : false} onChange={() => setDashboardType(2)} name='dashboard' value='Dashboard 2' />
										<label>
											<span>Dashboard 2</span>
										</label>
									</div>
								</div>
							</div>
							<div className='col-4'>
								<div className='borderbox'>
									<div className='radio'>
										<input type='radio' checked={DashboardType === 3 ? true : false} onChange={() => setDashboardType(3)} name='dashboard' value='Dashboard 3' />
										<label>
											<span>Dashboard 3</span>
										</label>
									</div>
								</div>
							</div>
						</div> */}
						</div>
					</div>


				</div>
				<div className='modal-footer'>
					<button
						type='submit'
						className='btn btn-secondary'
						disabled={loading}
						// onClick={(e) => {
						// 	e.preventDefault();
						// 	handleSubmit(RoleActionFx);
						// }}
						>
						<ButtonLoader text={Data ? 'Update Role' : 'Add Role'} loading={loading} />
					</button>
				</div>

			</form>
		</ModalLayout>
	);
};

export default AddRole;

function RoleItem({ sub_module, moduleId, currentPermission, SelectRole }: IroleItem) {
	return (
		<tr>
			<td className='col-2'>{sub_module?.sub_module_name}</td>
			<td data-label='View' className='col-2'>
				<div className='checkbox'>
					<input
						type='checkbox'
						name=''
						// checked={isview}
						// onChange={()=>{}}
						checked={sub_module?.isView ?? false}
						onChange={() =>
							SelectRole(currentPermission, moduleId, sub_module?.fK_SubModuleID, {
								fK_SubModuleID: sub_module?.fK_SubModuleID,
								sub_module_name: sub_module?.sub_module_name,
								isView: !sub_module?.isView,
								isCreate: sub_module?.isCreate,
								isEdit: sub_module?.isEdit,
								isDelete: sub_module?.isDelete,
								prevID: sub_module?.prevID,
								fK_RoleID: sub_module?.fK_RoleID,
							})
						}
					/>
					<label>&nbsp;</label>
				</div>
			</td>
			<td data-label='Create' className='col-2'>
				<div className='checkbox'>
					<input
						type='checkbox'
						name=''
						// checked={iscreate}

						checked={sub_module?.isCreate ?? false}
						onChange={() =>
							SelectRole(currentPermission, moduleId, sub_module?.fK_SubModuleID, {
								fK_SubModuleID: sub_module?.fK_SubModuleID,
								sub_module_name: sub_module?.sub_module_name,
								isView: sub_module?.isView,
								isCreate: !sub_module?.isCreate,
								isEdit: sub_module?.isEdit,
								isDelete: sub_module?.isDelete,
								prevID: sub_module?.prevID,
								fK_RoleID: sub_module?.fK_RoleID,
							})
						}

					// onChange={() => setiscreate(!iscreate)}
					/>
					<label>&nbsp;</label>
				</div>
			</td>
			<td data-label='Edit' className='col-2'>
				<div className='checkbox'>
					<input
						type='checkbox'
						name=''
						// checked={isedit}
						checked={sub_module?.isEdit ?? false}
						onChange={() =>
							SelectRole(currentPermission, moduleId, sub_module?.fK_SubModuleID, {
								fK_SubModuleID: sub_module?.fK_SubModuleID,
								sub_module_name: sub_module?.sub_module_name,
								isView: sub_module?.isView,
								isCreate: sub_module?.isCreate,
								isEdit: !sub_module?.isEdit,
								isDelete: sub_module?.isDelete,
								prevID: sub_module?.prevID,
								fK_RoleID: sub_module?.fK_RoleID,
							})
						}

					// onChange={() => setisedit(!isedit)}
					/>
					<label>&nbsp;</label>
				</div>
			</td>
			<td data-label='Delete' className='col-2'>
				<div className='checkbox'>
					<input
						type='checkbox'
						name=''
						// checked={isdelete}
						checked={sub_module?.isDelete ?? false}
						onChange={() =>
							SelectRole(currentPermission, moduleId, sub_module?.fK_SubModuleID, {
								fK_SubModuleID: sub_module?.fK_SubModuleID,
								sub_module_name: sub_module?.sub_module_name,
								isView: sub_module?.isView,
								isCreate: sub_module?.isCreate,
								isEdit: sub_module?.isEdit,
								isDelete: !sub_module?.isDelete,
								prevID: sub_module?.prevID,
								fK_RoleID: sub_module?.fK_RoleID,
							})
						}

					// onChange={() => setisdelete(!isdelete)}
					/>
					<label>&nbsp;</label>
				</div>
			</td>
			<td data-label='Full Control' className='col-2'>
				<div className='checkbox'>
					<input
						type='checkbox'
						name=''
						// checked={isfullcontrol}
						checked={(sub_module?.isView && sub_module?.isCreate && sub_module?.isEdit && sub_module?.isDelete) || false}
						onChange={(e) =>
							SelectRole(currentPermission, moduleId, sub_module?.fK_SubModuleID, {
								fK_SubModuleID: sub_module?.fK_SubModuleID,
								sub_module_name: sub_module?.sub_module_name,
								isView: e.target.checked ? true : false,
								isCreate: e.target.checked ? true : false,
								isEdit: e.target.checked ? true : false,
								isDelete: e.target.checked ? true : false,
								prevID: sub_module?.prevID,
								fK_RoleID: sub_module?.fK_RoleID,
							})
						}

					// onChange={() => setisfullcontrol(!isfullcontrol)}
					/>
					<label>&nbsp;</label>
				</div>
			</td>
		</tr>
	);
}
