import React from 'react'
import MainLayout from '../../../../../common/layouts/MainLayout'
import FilterBar from './FilterBar'
import ListTable from './ListTable'

const UserType = () => {
  return (
    <MainLayout>
      <FilterBar/>
      <ListTable/>
    </MainLayout>
  )
}

export default UserType