import React, { Fragment, useEffect, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import CheckboxTreeMap from './CheckboxTreeMap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { fetch_rolelist_ddl, fetch_sys_userType, fetch_treeMapWithoutPermission, password_policy, password_reset, search_user, webusers_action } from '../../../store/action';
import AsyncSelect from 'react-select/async';
import { FailedToast, SuccesToast, WarnToast } from '../../../../../utils/utilFunctions';
import FormSelect from '../../../../../common/components/FormSelect';
import PasswordValidation from '../../../../../common/components/PasswordValidation';
import { useAppDispatch, useAppSelector } from './../../../../../config/hooks';
import { fetch_webusers } from './../../../store/action';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import ButtonLoader from './../../../../../common/components/ButtonLoader';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

// const userTypes = [
// 	{
// 		label: 'Web user',
// 		value: 1,
// 	},
// 	{
// 		label: 'App user',
// 		value: 2,
// 	},
// ];
const schema = yup.object().shape({
	fK_RoleID: yup.string().required(),
	fK_UserUniqueID: yup.number().required(),
	fK_WebUserType: yup.number().required(),
	emailID: yup.string().email().required(),
	authenticationType: yup.number().required(),
	loginPassword: yup.string(),

	webUserPrivileges: yup.array().of(
		yup.object().shape({
			fK_Site: yup.string().required('No Site detected.'),
			fk_FLevel: yup.string().required('No Level detected.'),
			fK_Zone: yup.string().required('No Zone detected.'),
		})
	).required(),
});

const AddModal = (props: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
		watch,
		clearErrors, 
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	let dispatch = useAppDispatch();
	const SECURITY_DATA = useAppSelector((state) => state?.security);

	const [usersList, setusersList] = useState([]);
	const [userOptions, setuserOptions] = useState<any>([]);
	const [usersData, setusersData] = useState<any>();
	const [tree, setTree] = useState([]);
	const [accessPreviledges, setAccessPreviledges] = useState<any>([]);
	const [passwordSettings, setpasswordSettings] = useState<any>();
	const [rolesList, setrolesList] = useState<any>([]);
	const [password, setPassword] = useState<any>('');
	const [valid, setvalid] = useState(false);
	const [newpassword, setnewPassword] = useState<any>(true);

	const [userTypes, setuserTypes] = useState([])

	const [loading, setloading] = useState<any>(false);


	useEffect(() => {
		if (props.Data) {
			console.log("PROPS",props?.Data)
			setValue('fK_RoleID', props?.Data?.fK_RoleID);
			setValue('fK_UserUniqueID', props?.Data?.fK_UserUniqueID);
			setValue('fK_WebUserType', props?.Data?.fK_WebUserType);
			setValue('emailID', props?.Data?.emailID);
			setValue('authenticationType', props?.Data?.authenticationType);
			setValue('webUserPrivileges',props?.Data?.webUserPrivileges) 

			setAccessPreviledges(props?.Data?.webUserPrivileges);
			setusersData(props?.Data);
			setuserOptions([{ value: props?.Data?.fK_UserUniqueID, label: props?.Data?.userName + ' - ' + props?.Data?.emailID }]);
			setnewPassword(false);
		}else{
			setValue('authenticationType', 2);

		}
		clearErrors();
	}, [clearErrors, props.Data, setValue]);

	useEffect(() => {
		fetch_sys_userType()?.then((res)=>{
			let data = res?.response?.data ?? []
			let formattedData = data.map((item:any)=>({label :item?.typeName ,value : item?.sUserTypeID}))
			setuserTypes(formattedData)
		})
	
	}, [])
	

	const FetchRolesList = () => {
		let postData = {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 200,
			},
		};
		fetch_rolelist_ddl(postData).then((res) => {
			let data = res?.response?.data ?? [];
			let role_list = data.map((item: any) => ({ label: item?.roleName, value: item?.roleID }));
			setrolesList(role_list);
		});
	};

	useEffect(() => {
		const success = (res: any) => {
			setTree(res?.response || []);
		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
		};
		fetch_treeMapWithoutPermission(success, failed);

		const getDefaultVal = (res: any) => {
			let Data = res?.response?.data ? res?.response?.data[0] : null;
			setpasswordSettings(Data);
		};
		password_policy('get', null, getDefaultVal, () => {});
		FetchRolesList();

		handleInputChange();
	}, []);

	const handleInputChange = async (key?: string) => {
		let postData = {
			'filters': {
				'query': key ? `(firstName like '%${key}%')` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		let Response = await search_user(postData);

		let data = Response?.response?.data ?? [];

		let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: item?.firstName + ' - ' + item?.emailID }));
		setusersList(data);
		setuserOptions(Options || []);
		return Options;
	};

	const promiseOptions = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleInputChange(inputValue));
		});

	const handleChange = (val: any) => {
		let data: any = usersList.find((item: any) => item?.userUniqueID === val?.value);

		setusersData(data);
		setValue('fK_WebUserType', 1);
		setValue('emailID', data?.emailID);
		setValue('fK_UserUniqueID', data?.userUniqueID);
	};

	const onSubmitHandler = (data: any) => {
		let postData: any = {
			...data,
			webUserPrivileges: accessPreviledges,
			loginPassword: password,
		};


		if (props.Data) {
			postData = {
				fK_UserUniqueID: props?.Data?.fK_UserUniqueID,
				authenticationType: data?.authenticationType,
				webUserPrivileges: accessPreviledges,
				fK_WebUserType: data?.fK_WebUserType,
				emailID: props?.Data?.emailID,
				fK_RoleID: data?.fK_RoleID,
				sysUserID: props?.Data?.sysUserID,
			};
		}

		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			fetch_webusers(dispatch, {
				'filters': {
					'query': SECURITY_DATA?.WEBUSERS_FILTER?.query,
				},
				'pagination': {
					'pageNo': SECURITY_DATA?.WEBUSERS_PAGINATION?.pageNo,
					'limit': SECURITY_DATA?.WEBUSERS_PAGINATION?.limit,
				},
			});
			reset();
			setloading(false);

			props.handleClose();
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setloading(false);
		};

		if (props?.Data) {
			setloading(true);

			console.log("UPDATE DARA",postData)

			webusers_action('put', postData, Success, Failed);
			if (newpassword) {
			if (isCred === 1 && !valid) return WarnToast('Password not valid');
			setloading(true);

				const suc = () => {
					setloading(false);
				};
				const failed = (err: any) => {
					FailedToast(err?.response)		;
					setloading(false);
				};
				password_reset(
					'post',
					{
						'UserUniqueID': props?.Data?.fK_UserUniqueID,
						'NewPassword': password,
					},
					suc,
					failed
				);
			}
		} else {
			if (isCred === 1 && !valid) return WarnToast('Password not valid');
			setloading(true);

			webusers_action('post', postData, Success, Failed);
		}
	};

	let isCred = watch('authenticationType');


	console.log("fK_WebUserType" ,watch('fK_WebUserType') )
	console.log("userTypes" ,userTypes)
	console.log("ERRORS=>",errors)


	return (
		<ModalLayout
			open={props.open}
			handleClose={() => {
				reset();
				clearErrors();
				props.handleClose();
			}}
			title={props?.Data ? 'Update WebUser' : 'Add Webuser'}
			subtitle='User Management'>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className="modal-body-content">

				<div className='row'>
					<div className='col-6'>
						<div className={'form-group select-input full-label'}>
							<label>App User ID</label>
							<div className={props?.Data ? 'opacity-half' : ''}>
								<AsyncSelect
									className={'common-select common-select-grey'}
									cacheOptions
									defaultInputValue={props?.Data ? props?.Data?.emailID : ''}
									loadOptions={promiseOptions}
									onChange={handleChange}
									defaultOptions={userOptions}
								/>
							</div>
							<ErrorComponent errors={errors} name='fK_UserUniqueID' title='User ID' />
						</div>
					</div>
					<div className='col-6'>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group inline-input full-label'>
									<label>Name</label>
									<input className='form-control' readOnly defaultValue={usersData?.firstName || usersData?.userName} />
								</div>
							</div>
						</div>
					</div>

					<div className='col-12'>
						<div className='form-group select-input'>
							<label>User Roles</label>
							<FormSelect
								control={control}
								name='fK_RoleID'
								options={rolesList}
								grey={true}
								isMulti={false}
								placeholder='select role'
							/>
							
						<ErrorComponent errors={errors} name='fK_RoleID' title='Role' />
						</div>
					</div>

					<div className='col-12'>
						<div className='form-group select-input'>
							<label>User Types</label>

							<FormSelect
								control={control}
								name='fK_WebUserType'
								options={userTypes}
								grey={true}
								isMulti={false}
								placeholder='user type'
							/>
							<ErrorComponent errors={errors} name='fK_WebUserType' title='User type' />
						</div>
					</div>

					<div className='col-12 mt-10'>
						<div className='form-group inline-input w-100'>
							<div className='card-default new-scrollbar' style={{ overflowY: 'auto', maxHeight: '29vh' }}>
								<div className='title'>
									<h3>Access Previlage</h3>
									<ErrorComponent errors={errors} name='webUserPrivileges' title='Access Previlage' />
								</div>
								<CheckboxTreeMap
									tree={tree}
									accessPreviledges={accessPreviledges ? accessPreviledges : []}
									setAccessPreviledges={(val:any) =>{
										setValue('webUserPrivileges',val) 
										setAccessPreviledges(val)
									}}
								/>
							</div>
						</div>
					</div>

					<div className='col-12'>
						<div className='form-group inline-input' style={{ marginBottom : '5px' }}>
							<label>Email Address</label>
							<div className={props?.Data ? 'opacity-half w-100' : 'w-100'}>
								<input
									type=''
									className='form-control grey-input'
									defaultValue={usersData?.emailID}
									disabled
									{...register('emailID')}
								/>
							</div>
						</div>
						<div className='form-group inline-input'>
							<ErrorComponent errors={errors} name='emailID' title='Email' />
						</div>
					</div>

					<div className='col-12'>
						<div className='form-group inline-input'>
							<label>Login Method</label>

							<Controller
								name='authenticationType'
								control={control}
								render={({ field: { value, onChange, onBlur } }) => (
									<>
										<div className='w-100'>
											<div className='row'>
												<div className='col-6'>
													<div className='borderbox bg-grey'>
														<div className='radio'>
															<input
																type='radio'
																value={2}
																checked={value === 2}
																onChange={() => onChange(2)}
																
															/>
															<label>
																<span>SSO</span>
															</label>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='borderbox bg-grey'>
														<div className='radio'>
															<input
																type='radio'
																value={1}
																checked={value === 1}
																onChange={() => onChange(1)}
															/>
															<label>
																<span>User Credential</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							/>
						</div>
						<div className='form-group inline-input'>
						<ErrorComponent errors={errors} name='authenticationType' title='Authentication Type' />
						</div>
					</div>
					<Fragment>
						{isCred === 1 && (
							<>
								<div className='col-12'>
									<div className='form-group inline-input'>
										<label>Password</label>
										<div className={!newpassword ? 'opacity-half w-100' : 'w-100'}>
											<PasswordValidation
												type='text'
												value={password}
												setValue={setPassword}
												valid={valid}
												setvalid={setvalid}
												req_length={passwordSettings?.minimumPWdLength}
												grey={true}
												islowercase={passwordSettings?.requiredLowercaseLetter}
												isnumber={passwordSettings?.requeirdOneNumber}
												isuppercase={passwordSettings?.requirdUppercaseLetter}
												issymbol={passwordSettings?.requiredSymbolCharacter}
												placeholder={newpassword ? '' : '***************'}
											/>
										</div>
									</div>
								</div>

								<div className='w-100'>
									{!newpassword && (
										<p className='label-style text-right text-hover' onClick={() => setnewPassword(true)}>
											Change password
										</p>
									)}
								</div>
							</>
						)}
					</Fragment>

					
				</div>
				</div>
				<div className='col-12'>
						<div className='modal-footer'>
							<button
								type='reset'
								onClick={() => {
									reset();
									clearErrors();
									props?.handleClose();
								}}
								disabled={loading}
								className='btn btn-outline-blue close-modal-button'>
								Cancel
							</button>
							<button type='submit' className='btn btn-secondary'>
								<ButtonLoader text={props?.Data ? 'Update user' : 'Add User'} loading={loading} />
							</button>
						</div>
					</div>
			</form>
		</ModalLayout>
	);
};

export default AddModal;
