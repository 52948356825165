/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import CommonTable from '../../../../common/components/Table/CommonTable';
import ViewModal from './ViewModal';
import AddModal from './AddModal';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { useNavigate } from 'react-router-dom';
import { delete_user, fetch_userslist, fetch_user_detail } from '../../store/action';
import CustomConfirm from '../../../../common/components/CustomConfrmBox';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { CircularProgress } from '@mui/material';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import { subModuleList } from './../../../../config/subModuleList';

const ListTable = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const USERS_DATA = useAppSelector((state) => state?.user);

	const fetchIdRef = useRef(0);

	const [editModal, setEditModal] = useState(false);
	const [viewModal, setViewModal] = useState(false);
	const [loader, setloader] = useState(false);


	const [editData, seteditData] = useState();

	const [userDetail, setuserDetail] = useState();

	const [id, setid] = useState();

	const editModalOpen = (data: any) => {
		seteditData(data);
		setEditModal(true);
	};
	const editModalClose = () => setEditModal(false);

	const viewModalClose = () => setViewModal(false);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string };
	};

	const [delModal, setdelModal] = useState(false);

	const delModalClose = () => setdelModal(false);

	const DeleteUser = (id: number) => {
		setloader(true)

		const success = (res: any) => {
		setloader(false)

			SuccesToast(res?.response?.data);
			fetch_userslist(navigate, dispatch, {
				'filters': {
					'query': USERS_DATA?.USER_FILTER?.query || '',
				},
				'pagination': {
					'pageNo': USERS_DATA?.USER_PAGINATION?.pageNo,
					'limit': USERS_DATA?.USER_PAGINATION?.limit,
				},
			});
			delModalClose();
		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
		setloader(false)

		};

		delete_user(id, success, failed);
	};

	
const ViewAction = ({ row }: any) => {
	const viewModalOpen = (id: number) => {
		setviewLoader(true);
		const sucess = (res: any) => {
			setuserDetail(res?.response?.data);
			setViewModal(true);
			setviewLoader(false);
		};
		const failed = (err: any) => {
			FailedToast(err?.response?.app_data);
			setviewLoader(false);
		};

		fetch_user_detail(id, sucess, failed);
	};

	const [viewLoader, setviewLoader] = useState(false);

	return (
		<a onClick={() => viewModalOpen(row?.original?.userUniqueID)} className='btn btn-sqaure btn-white modal-button'>
			{viewLoader ? (
				<CircularProgress
					color='inherit'
					style={{
						width: '12px',
						height: '12px',
					}}
				/>
			) : (
				<i className='fa-solid fa-eye'></i>
			)}
		</a>
	);
}

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_userslist(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	const columns = React.useMemo(
		() => [
			{ Header: 'User Name', accessor: 'firstName', orderByKey: 'firstName' },
			{ Header: 'Email', accessor: 'emailID'
			, orderByKey: 'emailID'
		 },
			{ Header: 'Priority Level', accessor: 'fK_PriorityLevel', orderByKey: 'fK_PriorityLevel' },
			{ Header: 'Designation', accessor: 'desName'
			, orderByKey: 'desName' 
		},
			{ Header: 'Department', accessor: 'depName'
			, orderByKey: 'depName' 
		},
			{ Header: 'Type', accessor: 'userType', orderByKey: 'userType' },
			{ Header: 'Escalation 1', accessor: 'escalationUser1'
			, orderByKey: 'escalationUser1' 
		},
			{ Header: 'Site', accessor: 'siteName'
			, orderByKey: 'siteName' 
		},
			{ Header: 'Group', accessor: 'gCompanyName'
			, orderByKey: 'gCompanyName' 
		},
			// { Header: 'Status', accessor: 'userStatus' ,orderByKey:"userStatus" ,},
			{
				Header: 'Status ',
				accessor: 'fK_UserStatus',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<label
								className={`${
									row?.original?.fK_UserStatus === 1
										? 'badge green'
										: row?.original?.fK_UserStatus === 0
										? 'badge red'
										: 'badge'
								} `}>
								{row?.original?.fK_UserStatus === 1 ? 'Active' : row?.original?.fK_UserStatus === 0 ? 'Inactive' : null}
							</label>
						</Fragment>
					);
				},
			},

			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								
								<PermissionWrapper subModuleID={subModuleList.Users} type='isView'>
									
									<ViewAction row={row} />

								</PermissionWrapper>
								<PermissionWrapper subModuleID={subModuleList.Users} type='isView'>
									
									<a onClick={() => editModalOpen(row?.original)} className='btn btn-sqaure btn-white modal-button'>
										<i className='fa-solid fa-pen'></i>
									</a>
								</PermissionWrapper>

								<PermissionWrapper subModuleID={subModuleList.Users} type='isDelete'>
									<div className=''>
										<a
											className='btn btn-sqaure btn-red modal-button'
											onClick={() => {
												setid(row?.original?.userUniqueID);
												setdelModal(true);
											}}>
											<i className='fa-solid fa-trash'></i>
										</a>
									</div>
								</PermissionWrapper>
							</div>
						</Fragment>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<CommonTable
				columns={columns}
				data={USERS_DATA?.USER_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={USERS_DATA?.USER_PAGINATION?.totalCount ?? 0}
				loading={USERS_DATA?.USER_LOADER}
				page_no={USERS_DATA?.USER_PAGINATION?.pageNo ?? 1}
				limit={USERS_DATA?.USER_PAGINATION?.limit ?? 1}
				filters={USERS_DATA?.USER_FILTER}
				error={USERS_DATA?.USER_ERROR ?? ''}
			/>
			{viewModal && <ViewModal open={viewModal} handleClose={viewModalClose} Data={userDetail} />}

			{editModal && <AddModal open={editModal} handleClose={editModalClose} Data={editData} />}

			<CustomConfirm
				open={delModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						if (id) DeleteUser(id);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
		</>
	);
};

export default ListTable;

