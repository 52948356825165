import React, { useEffect, useRef, useState } from 'react';
import TextEditor from '../../../../common/components/text_editor/TextEditor';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { fetch_emailParameters, fetch_notificationTemplate_list, update_notification_template } from '../../store/action';
import { useAppDispatch, useAppSelector } from './../../../../config/hooks';
import ButtonLoader from './../../../../common/components/ButtonLoader';
import curlyBracket from './curly-brackets.svg'
import JoditTextEditor from '../../../../common/components/joditReact/JoditTextEditor';
import './style.css'
import TextEditorSkelton from './TextEditorSkelton';
import SunEditorWrapper from '../../../../common/components/text_editor/SunEditor';
import { TextField } from '@mui/material';

type Props = {};

const Notifcation = (props: Props) => {
    let dispatch = useAppDispatch();

    useEffect(() => {
        fetch_notificationTemplate_list(dispatch, {
            'filters': {
                'QUERY': '',
            },
            'pagination': {
                'pageNo': 1,
                'limit': 100,
            },
        });
    }, [dispatch]);
    const notifications = useAppSelector((state) => state?.notifications);
    const [activeID, setactiveID] = useState(1);
    const [value, setValue] = useState<any>('');

    const [body, setbody] = useState('');

    const [subject, setsubject] = useState<string>('');
    const [loading, setloading] = useState(false);

    const [parameters, setparameters] = useState<any>(null);

    const fetchAPi = (id: any) => {
        const suc = (res: any) => {
            let data = res?.response?.data.map((item: any) => ({ value: `{{${item}}}` }));

            console.log(data)

            setparameters(data);
        };
        fetch_emailParameters(dispatch, id, suc);
    };

    useEffect(() => {
        fetchAPi(1);
    }, []);

    const save_template = () => {
        let postData = {
            'templateId': activeID,
            'isEnable': true,
            'templateCode': value?.templateCode,
            'subject': subject,
            'body': body,
            'descriptions': value?.descriptions
        };
        setloading(true);

        const sucess = (res: any) => {
            SuccesToast(res?.data);
            fetch_notificationTemplate_list(dispatch, {
                'filters': {
                    'QUERY': '',
                },
                'pagination': {
                    'pageNo': 1,
                    'limit': 100,
                },
            });

            setloading(false);
        };
        const failed = (err: any) => {
            FailedToast(err?.response)		;
            setloading(false);
        };
        update_notification_template(postData, sucess, failed);
    };
    useEffect(() => {

        if(body === ""){
            if (notifications?.NOTIFICATION_TEMPLATE_LIST_DATA) {
                let item: any = notifications?.NOTIFICATION_TEMPLATE_LIST_DATA[0] ??
                    setparameters(null);
                setactiveID(item?.templateId);
                setsubject(item?.subject);
                setValue(item);
            }
        }

    



    }, [notifications?.NOTIFICATION_TEMPLATE_LIST_DATA])

 


    return (
        <div className='main-body-content '>
            <div className='white-card data-clean overflow-visible'>
                <div className='col-12 blue-box mb-20'>
                    <h2 className='border-title text-white' style={{ padding: '12px 8px' }}>Notification Templates</h2>
                </div>

                <div className="col-12 dflex">
                    <div className="">
                        <div className='inline-input-template new-scrollbar' style={{ maxHeight :"70vh" , overflow:"auto"}}>
                            {/* <div className='new-template-header'>
                                <h2 className='border-title'>Notification Templates</h2>
                            </div> */}

                            <div className=''>
                                {notifications?.NOTIFICATION_TEMPLATE_LIST_DATA.map((item: any, key) => {
                                    return (
                                        <div
                                            key={key}
                                            className={
                                                activeID === item?.templateId ? 'new-template-active' : 'new-template-list-item'
                                            }
                                            onClick={() => {
                                                setparameters(null);
                                                setactiveID(item?.templateId);
                                                setsubject(item?.subject);
                                                setValue(item);
                                                fetchAPi(item?.templateId);
                                            }}>

                                             {item?.subject}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                    <div className="w-100">
                        <div className="row">
                            {/* <div className='form-group inline-input'>
                                <label>Notification Status</label>
                                <label className='switch'>
                                    <input type='checkbox' />
                                    <span className='slider'></span>
                                    <span className='enable-text'>Enable</span>
                                </label>
                            </div> */}
                            <div className='form-group inline-input'>
                                {/* <label htmlFor="" style={{ fontSize:"14px" ,paddingLeft:"5px"}}> Subject</label> */}

                                <div className='col-12 input-half pos-rel'>
                                {/* <label style={{ position :"absolute" ,left :"14px"  , top:"-8px",width :"70px" , backgroundColor:""}}>Email Subject</label> */}

                                    <input
                                        type='text'
                                        name=''
                                        className='form-control grey-input'
                                        // style={{ border:"1px solid var(--lightgrey-second)" ,}}
                                        placeholder='Smart Bay User Account Activated'
                                        onChange={(e: any) => setsubject(e.target.value)}
                                        value={subject ?? ''}
                                    />






                                </div>
                            </div>

                        </div>

                        <div className='row w-100 '>
                            {parameters ? <TextEditor data={value?.body} atValues={parameters} value={body} setValue={setbody} />
                                : <>
                                    <TextEditorSkelton />

                                </>
                            }

                            {/* <SunEditorWrapper /> */}


                            {/* <TextEditor data={value?.body} atValues={parameters} value={body} setValue={setbody} /> */}
                            {/* <JoditTextEditor  data={value?.body}  value={body} setValue={setbody}  atValues={parameters} /> */}
                        </div>
                        <div className='dflex row w-100 p-10' style={{ justifyContent: "flex-end" }}>
                            <div className='btn btn-secondary' onClick={save_template}>
                                <ButtonLoader loading={loading} text='Update' />
                            </div>
                        </div>


                    </div>
                </div>
                {/* <div className='form-group inline-input algin-start'> */}




                {/* <div className='template-parmeter-list pos-rel'>
                            <div style={{ position:"absolute" , top:"-30px" , width:"40px", right:"-10px" ,border: '1px solid #f2f2f2'}} className="btn btn-sqaure">
                                <img src={curlyBracket} alt="" />
                            </div>
                            {notifications?.NOTIFICATION_TEMPLATE_PARAMETERS.map((item: any, key: any) => {
                                return (
                                    <p className='label-style mb-20' key={key} onClick={()=>parameterSelect(item)}>
                                        {item?.value}
                                    </p>
                                );
                            })}
                        </div> */}
                {/* </div> */}
                {/* </form> */}
            </div>
        </div>
    );
};

export default Notifcation;
