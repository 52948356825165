/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { useState } from 'react';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { delete_role, fetch_rolelist, role_detail } from '../../../store/action';
import AddRole from './addRole';
import DeleteConfirm from '../../../../../common/components/DeleteConfirm';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { useNavigate } from 'react-router-dom';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../../config/subModuleList';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import dayjs from 'dayjs';

type Props = {};

const ListTable = (props: Props) => {
	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();
	const SECURITY_DATA                 = useAppSelector((state) => state?.security);

	const fetchIdRef                    = useRef(0);
	
	const [Open, setOpen]               = useState(false);
	const [deleteModal, setdeleteModal] = useState(false);
	const [dataDetail, setdataDetail]   = useState();
	const [ID, setID]                   = useState();
	const [deleteLoader, setDeleteLoader] = useState(false)

	const columns = React.useMemo(
		() => [
			{ Header: 'Role ID', accessor: 'roleID'  ,orderByKey:"roleId"},
			{ Header: 'Role Name', accessor: 'roleName' ,  orderByKey:"roleName", 
				Cell: ({row}:any) => {
					let s = row?.values?.roleName?.split(' ')
					console.log('ROLENAME S',row?.values?.roleName,s)
					console.log('IF=>',s?.includes((el:any) => el?.length > 30),row?.values?.roleName?.length, s?.length===1)
					if(s?.includes((el:any) => el?.length > 10) || row?.values?.roleName?.length > 30 ){
						return <p>{`${row?.values?.roleName?.substring(0,30)}...`}</p>
					}else 
						return row?.values?.roleName
				}
			},
			{ Header: 'Created By', accessor: 'createdUser' 
			,orderByKey:"createdby"
		},
			{ Header: 'created Date', accessor: 'createdOn' ,orderByKey:"createdOn", DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A',
		},

			{ Header: 'Updated Date', accessor: 'updatedOn' ,orderByKey:"updatedOn", DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'Updated By', accessor: 'updatedUser' 
			,orderByKey:"updatedUser"
		},
			{ Header: 'No of User', accessor: 'userCount' ,orderByKey:"userCount" },
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
							<PermissionWrapper subModuleID={subModuleList.Roles} type='isEdit'>

							<a onClick={() => handleOpen(row?.values?.roleID)} className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-pen'></i>
								</a>
							</PermissionWrapper>

							<PermissionWrapper subModuleID={subModuleList.Roles} type='isDelete'>

							<a className='btn btn-sqaure btn-red modal-button' onClick={() => handleDeletModalOpen(row?.values?.roleID)}>
									<i className='fa-solid fa-trash'></i>
								</a>

							</PermissionWrapper>


							
							
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);


	type IfetchData = { 
		pageSize :number,
		 pageIndex :number,
		 signal :AbortSignal,
		 OrderBy :any,
		filters: {query: string;}

	}

	const fetchData = useCallback(({ pageSize, pageIndex ,signal , OrderBy ,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_rolelist(navigate, dispatch, {
				'filters': {
					'query':filters.query || '',
					OrderBy
				},
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			},signal);
		}
	}, [dispatch, navigate]);

	const handleOpen = (id: number) => {
		const sucess = (res: any) => {
			setdataDetail(res?.response?.data);
			setOpen(true);
		};
		const failed = (err: any) => {};

		role_detail(id, sucess, failed);
	};

	const handleDeletModalOpen = (id: any) => {
		setID(id);
		setdeleteModal(true);
	};
	const handleDelete = (id: any) => {
		setDeleteLoader(true)
		const sucess = (res: any) => {
			console.log(res);
			SuccesToast(res?.response?.data);
			setdeleteModal(false);
			setDeleteLoader(false)
			fetch_rolelist(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				},
			});
		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
			setDeleteLoader(false)
			setdeleteModal(false);
		};

		delete_role(id, sucess, failed);
	};

	return (
		<>
			<CommonTable
				columns={columns}
				data={SECURITY_DATA?.ROLES_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={SECURITY_DATA?.ROLES_PAGINATION?.totalCount ?? 0}
				loading={SECURITY_DATA?.ROLES_LOADER}
				page_no={SECURITY_DATA?.ROLES_PAGINATION?.pageNo ?? 1}
				limit={SECURITY_DATA?.ROLES_PAGINATION?.limit ?? 1}
				error={SECURITY_DATA?.ROLES_ERROR ?? ''}
				filters={SECURITY_DATA?.ROLES_FILTER}
			/>
			{Open && <AddRole open={Open} handleClose={() => setOpen(false)} Data={dataDetail} />}
			{
			deleteModal && 
			<CustomConfirm
			open={deleteModal}
			handleClose={() => setdeleteModal(false)}
			title='Are you sure?'
			description='This action is irreversible. Can you confirm?'>

			<button
			disabled={deleteLoader}
				type='submit'
				className='btn btn-secondary'
				onClick={(e) => {
					e.preventDefault();
					if (ID) handleDelete(ID);
				}}>
			<ButtonLoader loading={deleteLoader} text="Confirm" />
				
			</button>

			</CustomConfirm>
			}
		</>
	);
};

export default ListTable;
