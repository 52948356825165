/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteCustomConfirm from '../../../../../common/components/DeleteCustomConfirm';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, FormatDate, SuccesToast } from '../../../../../utils/utilFunctions';
import { delete_assigned_zone, fetch_parking_policy, fetch_policy_assigned_Zones } from '../../../store/actions';
import { subModuleList } from './../../../../../config/subModuleList';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import moment from 'moment';
 
type Props = {
	open: boolean;
	handleClose: () => void;
	Data: any;
};

const AddModal = (props: Props) => {
	// let actionArr = [ 'Create' , 'Update', 'Delete' ]
	const navigate                      = useNavigate();
	const dispatch	= useAppDispatch();
	const [ID, setID]                   = useState();
	const [deleteModal, setdeleteModal] = useState(false);
	const fetchIdRef                    = useRef(0);
	const delModalClose = () => setdeleteModal(false);
	const [loader, setLoader]	= useState(false)


	const PARKING_POLICY_DATA = useAppSelector((state) => state?.policy)

	const handleDeletModalOpen = (id: any) => {
		setID(id);
		setdeleteModal(true);
	};

	const handleDelete = (id: any) => {
		setLoader(true)
		const sucess = (res: any) => {
			setLoader(false)
			console.log(res);
			SuccesToast(res?.response?.data);
			setdeleteModal(false);
			const controller = new AbortController();

			fetch_policy_assigned_Zones( dispatch, {
				"PolicyID":props.Data.policyID,
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 15,
				},
			});
			fetch_parking_policy(navigate, dispatch, {
				'filters': {
					'query': PARKING_POLICY_DATA?.PARKING_POLICY_FILTER?.query || '',
				},
				'pagination': {
					'pageNo': PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION.pageNo,
					'limit': PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION.limit,
				},
			}, controller.signal);
		};
		const failed = (err: any) => {
			setLoader(false)
			FailedToast(err?.response)		;
			setdeleteModal(false);
		};

		delete_assigned_zone(id, sucess, failed);
	};

	const columns = React.useMemo(
		()	=>	[
			{ Header: 'ID', accessor:'ppaid'},
			{ Header: 'ZONE NAME', accessor:'zoneName'},
			{ Header: 'SITE NAME', accessor:'siteName'},
			{ Header: 'START DATE', accessor: 'startDate' , DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'END DATE', accessor: 'endDate' , DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'STATUS', 
				Cell: ({ row }: any) => {
					let val = moment(row?.values?.endDate).diff(moment()) > 0 ? true : false
					return (
						<Fragment>
							<StatusChipComponent
								chipType={val ? "success" : "error"}
								text={val ? "Active" : "Expired"}
							/>
						</Fragment>
					);
				},
			},
			{
				Header: 'actionBar', accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<PermissionWrapper subModuleID={subModuleList['Policy Assignment']} type="isDelete">

								<a onClick={() => handleDeletModalOpen(row?.values?.ppaid)} className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-trash'></i>
								</a>
								</PermissionWrapper>

							</div>


						</Fragment>
					)
				},
				show: true
			},
		],
		[]
	);

	type IfetchData = {
		pageSize :number,
		pageIndex :number,
		signal :AbortSignal
		filters: {query: string;}

	}

	const fetchData = useCallback(({ pageSize, pageIndex ,signal,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_policy_assigned_Zones( dispatch, {
				"PolicyID":props.Data.policyID,
				'filters': {
					'query': '',
					"OrderBy":"ppaid desc"
				},
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			}, signal);
			fetch_parking_policy(navigate, dispatch, {
				'filters': {
					'query':filters.query || '',				},
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			}, signal);
		}
	}, [dispatch, navigate, props.Data.policyID]);


	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title='Policy Assignment Details' subtitle='Policy' big>
				<div className="modal-body">
					<div className="modal-body-content">

				<div className="table-details mb-30">
					<ul>
						<li>Policy Name<span>{props.Data?.policyName}</span></li>
						<li>Description<span>{props.Data?.pDescriptions}</span></li>
					</ul>
					<div className="seperator"></div>
					<ul>
						<li>Created By<span>{props.Data?.createdUser}</span></li>
						<li>Created On<span>{FormatDate(props.Data?.createdOn)}</span></li>
					</ul>
				</div>

				<CommonTable 
					data={PARKING_POLICY_DATA?.POLICY_ASSIGNMENT_ZONES_LIST??[]} 
					columns={columns} 
					pageCount={PARKING_POLICY_DATA?.POLICY_ASSIGNMENT_ZONES_PAGINATION?.totalCount ?? 0} 
					fetchData={fetchData} 
		  			limit={PARKING_POLICY_DATA?.POLICY_ASSIGNMENT_ZONES_PAGINATION?.limit ?? 1} 
					page_no={PARKING_POLICY_DATA?.POLICY_ASSIGNMENT_ZONES_PAGINATION?.pageNo ?? 1}			
					filters={PARKING_POLICY_DATA?.PARKING_POLICY_FILTER}
				/>
				{/* <table className="style-table minimalTableDiv">
					<thead>
						<tr>
							<th>Parameter</th>
							<th>Before Value</th>
							<th>After Value</th>
						</tr>
					</thead>
					<tbody>
						{/* {
							props.Data.logDetails && props.Data.logDetails.map((item:any,k:number) => {
								return(
									<tr>
										<td>
											<label className="badge blue">{item.columnNew}</label>
										</td>
										<td>{item.valueOld}</td>
										<td>{item.valueNew}</td>
									</tr>
								)
							})
						} 
					</tbody>
				</table> */}
			</div>

			<div className="modal-footer">
				<a onClick={props.handleClose} className="btn btn-outline-blue close-modal-button">Close</a>
			</div>
			<CustomConfirm
				open={deleteModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						handleDelete(ID);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
		</div>

		</ModalLayout>
	);
};

export default AddModal;
