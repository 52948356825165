/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// import PasswordValidation from '../../../../../common/components/PasswordValidation';
// import CommonSelect from '../../../../../common/components/Select';
import QueryBModal from '../../../../../common/components/query_builder'
import { Field } from 'react-querybuilder';
import { fetch_application_logs } from '../../../store/action';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import dayjs from 'dayjs';
import { getAllEventsList, getAllEventsOfModule, getAllEventsOfSubModule, getAllModules, getAllSubModules } from '../../../../../utils/moduleEvent';
import ReactSelect from 'react-select';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import { DatePicker } from "antd"
import moment from 'moment';
const { RangePicker } = DatePicker;

const FilterBar = () => {
	const [dateRange, setDateRange] = useState<any>([moment(new Date(),'YYYY-MM-DD').subtract(3,'day'),moment(new Date(),'YYYY-MM-DD')])

	const [moduleId, setModuleId] = useState<any>(null)
	const [subModuleId, setSubModuleId] = useState<any>(null)
	const [subModuleArr, setSubModuleArr] = useState<any>([])
	const [eventK, setEventK] = useState<any>('')

	const [advanceModal, setAdvanceModal] = useState(false)

	const advancemodalOpen = () => setAdvanceModal(true)
	const advancemodalClose = () => setAdvanceModal(false)
	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();
	const [filterActive, setFilterActive] = useState(false)
	
	const [advanceActive, setAdvanceActive] = useState(false)
	const LOGS_LIST		= useAppSelector((state)=>state?.logs)

	const deployQueryFilter = (query:string) => {
		setAdvanceActive(true)
		console.log('Query',query)
		fetch_application_logs(navigate, dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': LOGS_LIST.APPLICATION_LOGS_PAGINATION.limit,
			},
		})
		advancemodalClose()
	}

	const filterSearch = () =>{
		setFilterActive(true)
		let dateFilter = ''
		if(dateRange  && dateRange?.length>0)
			dateFilter = `CAST (CreatedOn AS date) >= '${dayjs(dateRange[0]).format('YYYY-MM-DD')}' `
		else	dateFilter = '' 
		if(dateRange && dateRange?.length>1)
			dateFilter = `CAST (CreatedOn AS date) >= '${dayjs(dateRange[0]).format('YYYY-MM-DD')}' AND CAST (CreatedOn AS date) <= '${dayjs(dateRange[1]).format('YYYY-MM-DD')}'`
		else 	dateFilter = ''

		let module = ''
		if(moduleId && subModuleArr && subModuleArr.length!==0 && subModuleId===null)
			module = `FK_SubModule in (${subModuleArr.map((el:any) => el)})`
		else if(subModuleId)
			module = `FK_SubModule = ${subModuleId}`
		else module = ''

		let events = ''
		if(eventK!=='')
			events = `EventKey = '${eventK}'`
		else events = ''
		
		fetch_application_logs(navigate, dispatch, {
			'filters': {
				'query': `${dateFilter!=='' ? dateFilter:''}${module!=='' ? `${dateFilter!=='' ? ` AND ` : ''}${module}` : ''}${events!=='' ? `${dateFilter!=='' || module!=='' ? ` AND ` : ''}${events}` : ''}`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': LOGS_LIST.APPLICATION_LOGS_PAGINATION.limit,
			},
		})
	}
	const fields: Field[] = [

			// { label: 'TRANSACTION ID', name:'TransactionID'},
			{ label: 'CREATED ON', name:'CAST (CreatedOn AS date)', inputType:'date'},
			{ label: 'EMAIL ID', name:'EmailID'},
			{ label: 'CREATED BY', name:'CreatedBy'},
			// { label: 'EVENT ID', name:'ApplicationEventID'},
			// { label: 'EVENT TYPE', name:'EventKey'},
			{ label: 'EVENT DESCRIPTION', name:'Descriptions'},
			{ label: 'STATUS', name:'Status'},
			{ label: 'SOURCE IP',name:'IP'},
			{ label: 'INTERFACE (Source)', name:'Source'},
			{ label: 'CREATED USER', name: 'CreatedUser'}
	];
	const clearSearch = () =>{
		fetch_application_logs(navigate, dispatch, {
			'filters': {
				'query': `CAST (CreatedOn AS date) >= '${dayjs().subtract(3,'day').format('YYYY-MM-DD')}' AND CAST (CreatedOn AS date) <= '${dayjs().format('YYYY-MM-DD')}'`,
			},
			'pagination': {
				'pageNo': 1,
				'limit' : LOGS_LIST.APPLICATION_LOGS_PAGINATION.limit,
			},
		})
		setDateRange([moment(new Date(),'YYYY-MM-DD').subtract(3,'day'),moment(new Date(),'YYYY-MM-DD')])
		setModuleId('')
		setSubModuleId('')
		setSubModuleArr([])
		setFilterActive(false)
		setAdvanceActive(false)
		setEventK('')
	}
	return (
		<div className='search-filter-row'>
			<ul>
				<li>
					<a className='active pointerDefaultSet'>
						<i className='fa-solid fa-magnifying-glass'></i> Quick Search
					</a>
				</li>
				<li>
					<a onClick={() => advancemodalOpen()} >
						<i className='fa-solid fa-filter'></i>Advanced Filter
					</a>
				</li>
				{	(advanceActive || filterActive) &&
					<li>
						<a onClick={clearSearch}>
							<i className='fa-solid fa-times'></i>Clear All Filters
						</a>
					</li>
				}
			</ul>

			<div className='filter-block'>
					<div className='row'>
						<div className='col'>
							<div className={`form-group`}>
								<RangePicker
									picker="date"
									className='form-control'
									placeholder={['Created',' Between']}
									separator
									inputReadOnly
									value={dateRange}
									format={'DD-MMM-YYYY'}
									allowClear={false}
									allowEmpty={[false,false]}
									onChange={(dates:any) =>{
										setDateRange(dates)
									}}
								/>
								{/* <DatePicker
        							selected={startDate}
        							onChange={(dates:any) =>{
										const [start, end] = dates;
										setStartDate(start)
										setEndDate(end)
									}}
        							selectsStart
									dateFormat={'dd-MMM-yyyy'}
									className='form-control'
        							startDate={startDate}
        							endDate={endDate}
									selectsRange
									placeholderText="Created Between"
									monthsShown={2}
									isClearable={startDate!==''}
      							/> */}
							</div>
						</div>							
					
						<div className='col'>
							<div className='form-group'>
							<ReactSelect
								options={getAllModules()}
								className={'common-select'}
								placeholder={'Modules'}
								isMulti={false}
								isClearable
                        	    value={moduleId ? getAllModules().find((c: { value: number; }) => c.value === parseInt(moduleId)) : null}
                            	onChange={(val) =>{ console.log('MOdukeId',val); setModuleId(val?.value); setSubModuleId(null);
								setSubModuleArr(getAllSubModules(val?.value).map((el:any)=>{ return el.value }))
							}}
							/>
							</div>
						</div>
						<div className={`col`}>
							<div className='form-group'>
							<ReactSelect
								options={getAllSubModules(moduleId===null ? '' : moduleId)}
								className={'common-select'}
								placeholder={'Sub Module'}
								isMulti={false}
								isClearable
                        	    value={subModuleId ? getAllSubModules(moduleId===null ? '' : moduleId).find((c: { value: any; }) => c.value === parseInt(subModuleId)) : null}
                            	onChange={(val) =>{ console.log('MOd',moduleId,'sub',val); setSubModuleId(val?.value) }}
							/> 
							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
							<ReactSelect
								options={
									subModuleId && subModuleId ? getAllEventsOfSubModule(subModuleId)
									: moduleId ? getAllEventsOfModule(moduleId)
									: getAllEventsList()
								}
								className={'common-select'}
								placeholder={'Module Actions'}
								isMulti={false}
								isClearable
								isDisabled={!(subModuleId && subModuleId)}
                        	    value={	eventK ?
									subModuleId && subModuleId ? getAllEventsOfSubModule(subModuleId)?.find((c:any) => c.value === eventK)
									:	moduleId ? getAllEventsOfModule(moduleId)?.find((c: { value: any; }) => c.value === eventK)
									: getAllEventsList()?.find((c: { value: any; }) => c.value === eventK)
									: null
								}
                            	onChange={(val) =>{	 console.log('event',val,'mod',moduleId,'sub',subModuleId); setEventK(val?.value); }}
							/>
							</div>
						</div>
{/* 						
						<div className='col'>
							<div className='form-group'>
								<select title='actionSelect' className='form-control'>
									<option>Action</option>
								</select>
							</div>
						</div> */}
						<div className='col-2'>
							<div className='form-group'>
								<button onClick={()=>{ filterSearch() }} className='form-control blue-btn'>Search</button>
							</div>
						</div>
					</div>
			</div>
			<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
		</div>
	);
};

export default FilterBar;
