import { Tooltip } from 'antd';

type Props = {
	value: number;
	label?: string;
	className?: string;
	endLabel?: string;
	lineEndLabel?: string;
	tootTipText?: string;
	color : string
};

const capitalize = (sen: string) => {

	if (!sen) return;

	let words = sen.split(' ');

	let label =  words
		.map((word) => {
			return  word[0]?.toUpperCase()  + word?.substring(1) ;
		})
		.join(' ')
		
		return label.replaceAll("undefined",'');
};

const ProgressBar = ({ value = 0, label = '', endLabel = '', lineEndLabel = '', tootTipText = '', className = 'primary'  , color=""}: Props) => {
	if (label === '')
		return (
			<div className='fullline'>
				<div className={`progress ${color!=='' ? 'zone-progress' : ''}`} style={{ width: `${value}%`, backgroundColor : `${color!=='' ? `${color}` : 'var(--primary)'}` }}></div>
			</div>
		);
	else
		return (
			<div className='progressbar'>
				{label !== '' || endLabel !== '' ? (
					<label className={`dflex align-items-center justify-space-between `} style={{ color :color}} >
						{label !== '' ? <span>{label}</span> : null}
						{endLabel !== '' ? <span className={`progress-count `} >{endLabel}</span> : null}
					</label>
				) : null}
				<Tooltip title={capitalize(tootTipText)}>
					<div className='progress fullline'>
						<div className={`progress-line `} style={{ width: `${value}%` , backgroundColor : color}} ></div>
						{lineEndLabel !== '' ? <span className={`progress-count `}>{lineEndLabel}</span> : null}
					</div>
				</Tooltip>
			</div>
		);
};

export default ProgressBar;
