import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select } from 'antd';
// import Select from '@mui/material/Select';
interface Iprops {
	count: number;
	TotalCount?: number;
	pageSize?: number;
	page?: number;
	onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
	setPageSize: (pageSize: number) => void;
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#0971f1',
		},
	},
});

const PaginationItem = (props: Iprops) => {
	let totalCount = props?.TotalCount ?? 0;
	return (
		<div className='table-pagination'>
			<div className='row'>
				<div className='col-6  dflex flex-align-center'>
					<div className='pagination-count'>
						Showing{' '}
						{Number(props.page) * Number(props.pageSize) > totalCount ? totalCount : Number(props.page) * Number(props.pageSize)}{' '}
						out of {totalCount}
					</div>
					<div className='form-grop select-input dflex align-items-center ml-5'>
						<label> Rows per page :</label>

						{/* <InputLabel id="demo-select-small">Rows</InputLabel> */}
						<Select
							id='demo-select-small'
							value={props?.pageSize}
							onChange={(e) => {
								props.setPageSize(Number(e));
							}}
							options={[
							
								{
									label: '10',
									value: 10,
								},
								{
									label: '20',
									value: 20,
								},
								{
									label: '50',
									value: 50,
								},
								{
									label: '100',
									value: 100,
								},
								{
									label: '200',
									value: 200,
								},
							]}
						/>
					</div>
				</div>

				<div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Stack spacing={2}>
						<ThemeProvider theme={theme}>
							<Pagination color='primary' count={props.count} onChange={props.onChange} page={props.page} />
						</ThemeProvider>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default PaginationItem;
