/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTable, useSortBy, useColumnOrder, ColumnInstance, usePagination } from 'react-table';
import DraggableComponent from '../dnd-kit/dndKit';
import PaginationItem from '../pagination';
import { ErrorBoundary } from 'react-error-boundary';
// import log from '../../../utils/utilFunctions';
import PopUp from '../popUp';
import { useRef } from 'react';
import PageNotFound from '../PageNotFound';
import { Fragment } from 'react';
import { MenuItem } from '@mui/material';
import { FormatDate } from '../../../utils/utilFunctions';
import dayjs from 'dayjs';
import CheckBox from '../checkBox';
import nodatafound from '../../../assets/images/common/nodatafound.png';

type Props = {
	data: any[];
	columns: {
		Header: string | any;
		accessor?: string;
		autoCapitalization?: boolean;
	}[];
	pageCount: number;
	fetchData: (item: any) => void;
	loading?: boolean;
	limit: number;
	page_no: number;
	filters: { query: string } | any;
	error?: string;
	rowsSelected?: any[];
	selectRowsFx?: any;
	isPagination?: boolean;
	disableCheckBox?: any;
	extraFilter?: any;
	adjustSpacing?: boolean;
};

const CommonTable = ({
	data,
	columns,
	pageCount: controlledPageCount,
	fetchData,
	limit,
	page_no,
	loading,
	error,
	rowsSelected,
	selectRowsFx,
	filters,
	disableCheckBox = null,
	isPagination = true,
	extraFilter = null,
	adjustSpacing = false,
}: Props) => {
	const effectRun = useRef(false);
	const defaultColumn = React.useMemo(() => ({}), []);
	// @ts-ignore
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		allColumns,
		setColumnOrder,
		// page,
		// canPreviousPage,
		// canNextPage,
		// pageOptions,
		pageCount,
		gotoPage,
		// nextPage,
		// previousPage,
		setPageSize,
		// setHiddenColumns,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: page_no,
				pageSize: limit,
			},
			autoResetPage: false,
			manualPagination: true,
			pageCount: controlledPageCount,
			defaultColumn,
		},
		useSortBy,
		useColumnOrder,
		usePagination
	);

	const [items, setItems] = useState<ColumnInstance<object>[]>(allColumns);
	

React.useEffect(() => {
  

	if(pageIndex !== page_no){
		console.log("CHANGING PAGE +++++++++++++++")
		gotoPage(page_no)
	}

  return () => {
  }
}, [page_no])


	const [OrderBy, setOrderBy] = useState<any>('');

	const newColumnOrder = (neworder: ColumnInstance<object>[]) => {
		setColumnOrder(neworder.map((d) => d.id));
	};

	const onChange = (e: any, value: number) => {
		console.log('onchangePAgeEvent', value);

		gotoPage(value);
	};
	console.log('pageIndex', pageIndex);
	console.log('page_no', page_no);


	const calculatePagesCount = (pageCount: number, pageSize: number) => {
		return pageCount < pageSize ? 1 : Math.ceil(pageCount / pageSize);
	};

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		console.log('am i running ');

		if (limit !== pageSize) {
			fetchData && fetchData({ pageIndex: 1, pageSize, signal, OrderBy, filters, extraFilter });
			gotoPage(1);
		} else {
			fetchData && fetchData({ pageIndex, pageSize, signal, OrderBy, filters, extraFilter });
		}
		return () => {
			effectRun.current = true;
			// setItems([]);
			controller.abort();
		};
		//! Note: adding limit into the dep array will lead to one unnecssary api call when changing page limit
		// todo : remove dep array warnings
	}, [fetchData, pageIndex, pageSize, OrderBy]);

	const isAllRowsSelected = () => {
		if (rowsSelected && rowsSelected?.length > 0) {
			let arr = disableCheckBox !== null ? rows?.filter((el) => !disableCheckBox(el.original)) : rows;
			let arr2 = arr?.map((rw) => rw.original);
			return arr2?.filter((el) => !rowsSelected?.includes(el))?.length === 0;
		} else return false;
	};

	const selectAllRows = () => {
		let arr = disableCheckBox !== null ? rows?.filter((el) => !disableCheckBox(el.original)) : rows;
		let arr2 = arr?.map((rw) => rw.original);
		if (!isAllRowsSelected()) {
			if (rowsSelected && rowsSelected.length > 0) {
				let arr3 = arr2?.filter((el) => !rowsSelected?.includes(el));
				console.log('ARR', arr, '\nARR2', arr2, '\nARR3', arr3);
				selectRowsFx([...rowsSelected, ...arr3]);
			} else {
				selectRowsFx([...arr2]);
			}
		} else {
			selectRowsFx([]);
		}
	};

	//console.log("%c===========================" ,"color:blue")

	//console.log("TABLE PAGE NO ==>",pageIndex)
	//console.log("TABLE PAGE LIMIT ==>",pageSize)

	//console.log("===========================")
	//console.log("%c.............................","color:red")
	//console.log("%c===========================","color:green")

	//console.log("REDUX PAGE NO ==>",page_no)
	//console.log("REDUX PAGE limit ==>",limit)

	//console.log("===========================")

	return (
		<>
			<ErrorBoundary
				FallbackComponent={PageNotFound}
				onReset={() => {
					// reset the state of your app so the error doesn't happen again
				}}>
				<div className='reponsive-table'>
					<table className={`style-table mobile-responsive ${adjustSpacing ? 'unsetWhiteSpaceWrap' : ''}`} {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup: any) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{rowsSelected && selectRowsFx && (
										<th>
											<CheckBox
												checked={isAllRowsSelected()}
												onClick={() => {
													selectAllRows();
												}}
											/>
										</th>
									)}
									{headerGroup.headers.map((column: any, key: any) => {
										if (column?.hideHeader) return null;
										return (
											<th
												key={key + 'f'}
												{...column.getHeaderProps()}
												className={column?.orderByKey ? 'tableHeadHighlighter' : ''}
												style={{ cursor: column?.orderByKey ? 'pointer' : '' }}
												onClick={() => {
													if (column?.orderByKey) {
														if (OrderBy.includes('asc')) {
															setOrderBy(`${column?.orderByKey} desc`);
														} else if (OrderBy.includes('desc')) {
															setOrderBy('');
														} else {
															setOrderBy(`${column?.orderByKey} asc`);
														}
													}
												}}>
												<div className='dflex justify-space-between align-items-center'>
													<div style={{ paddingRight: '.8rem' }}>{column.render('Header')}</div>

													{column?.orderByKey && OrderBy.includes(column?.orderByKey) && (
														// <PopUp iconClass={'fa-solid fa-ellipsis-vertical'} closeOutside={true}>
														// 	<MenuItem
														// 		sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
														// 		onClick={() => setOrderBy(`${column?.orderByKey} asc`)}>
														// 		<label className='label-dnd-menuitem'>Sort by Ascending</label>
														// 	</MenuItem>
														// 	<MenuItem
														// 		sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
														// 		onClick={() => setOrderBy(`${column?.orderByKey} desc`)}>
														// 		<label className='label-dnd-menuitem'>Sort by Descending</label>
														// 	</MenuItem>
														// 	<MenuItem
														// 		sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
														// 		onClick={() => setOrderBy('')}>
														// 		<label className='label-dnd-menuitem'>Clear Sort</label>
														// 	</MenuItem>
														// </PopUp>
														<>
															{OrderBy.includes('asc') ? (
																<i className='fa-solid fa-sort-up'></i>
															) : OrderBy.includes('desc') ? (
																<i className='fa-solid fa-sort-down'></i>
															) : null}
														</>
													)}
												</div>
											</th>
										);
									})}
									<th style={{ textAlign: 'end' }} className={'tableHeadHighlighter'}>
										<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<div style={{ width: '30px' }}>
												<PopUp>
													<DraggableComponent
														items={items}
														setItems={setItems}
														newColumnOrder={newColumnOrder}
													/>
												</PopUp>
											</div>
										</div>
									</th>
								</tr>
							))}
						</thead>

						<tbody {...getTableBodyProps()}>
							{loading ? (
								<>
									{[...Array(4)].map((item, key) => {
										return (
											<Fragment key={key}>
												{headerGroups.map((headerGroup: any) => (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map((column: any, key: any) => {
															if (column?.hideHeader) return null;
															return (
																<td
																	key={key + 'f'}
																	{...column.getHeaderProps(column.getSortByToggleProps())}>
																	<div className='skeleton'> </div>
																</td>
															);
														})}
														<td style={{ textAlign: 'end' }}>
															<div className='skeleton'> </div>
														</td>
													</tr>
												))}
											</Fragment>
										);
									})}
								</>
							) : error || data.length === 0 ? (
								<>
									{error ? (
										<>
											<tr>
												<td colSpan={columns.length} className='text-center w-full full-height uppercase'>
													<div className='dflex align-items-center justify-content-center'>
														<img src={nodatafound} alt='' style={{ width: '40%', objectFit: 'contain' }} />
													</div>
												</td>
											</tr>
										</>
									) : (
										<>
											<tr>
												<td colSpan={columns.length} className='text-center w-full full-height uppercase'>
													<div className='dflex align-items-center justify-content-center'>
														<img src={nodatafound} alt='' style={{ width: '40%', objectFit: 'contain' }} />
													</div>
												</td>
											</tr>
										</>
									)}
								</>
							) : (
								<>
									{rows.map((row: any, i: any) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()} key={i + 's'}>
												{rowsSelected && selectRowsFx && (
													<td
														className={
															disableCheckBox !== null && disableCheckBox(row.original)
																? 'disableAndOpacity2'
																: ''
														}>
														<CheckBox
															checked={rowsSelected?.includes(row.original)}
															disabled={disableCheckBox === null ? false : disableCheckBox(row.original)}
															onClick={() => {
																if (rowsSelected?.includes(row.original)) {
																	let nwArr = rowsSelected?.filter((el) => el !== row.original);
																	selectRowsFx(nwArr);
																} else {
																	selectRowsFx([...rowsSelected, row.original]);
																}
															}}
														/>
													</td>
												)}
												{row.cells.map((cell: any, keys: any) => {
													if (cell?.column?.hideBody) return null;
													if (cell?.column?.DateFormat) {
														return (
															<td
																data-label={`${cell?.column?.Header} ${
																	disableCheckBox !== null && disableCheckBox(row.original)
																		? ' disableAndOpacity2'
																		: null
																}`}
																key={keys}
																{...cell.getCellProps()}
																className={cell?.column?.tdClass}>
																{cell?.column?.FormatType && cell?.column?.FormatType !== ''
																	? dayjs(cell?.value, [
																			'YYYY-MM-DDTHH:mm:ss',
																			'YYYY-MM-DD',
																			'DD-MM-YYYY',
																			'DD-MMM-YYYY',
																	  ]).isValid()
																		? dayjs(cell?.value).format(cell?.column?.FormatType)
																		: ''
																	: FormatDate(cell?.value)}
															</td>
														);
													} else {
														return (
															<td
																style={{
																	textTransform: `${cell?.column?.Header}`
																		.toLowerCase()
																		.includes('email')
																		? 'none'
																		: 'none',
																}}
																data-label={cell?.column?.Header}
																key={keys}
																{...cell.getCellProps()}
																className={`${cell?.column?.tdClass} ${
																	disableCheckBox !== null && disableCheckBox(row.original)
																		? ' disableAndOpacity2'
																		: ''
																}${cell?.column?.autoCapitalization===false ? 'caseOverride' : ''}`}>
																{cell.render('Cell')}
															</td>
														);
													}
												})}
											</tr>
										);
									})}
								</>
							)}
						</tbody>
					</table>
				</div>
				{isPagination && (
					<PaginationItem
						count={calculatePagesCount(pageCount, pageSize)}
						onChange={onChange}
						TotalCount={pageCount}
						pageSize={pageSize}
						setPageSize={setPageSize}
						page={page_no}
					/>
				)}
			</ErrorBoundary>
		</>
	);
};

export default CommonTable;
