import React from 'react'
import MainLayout from '../../../../common/layouts/MainLayout'
import FilterBar from './FilterBar'
import ListTable from './ListTable'


export default function ViolationReason({label, path, defId, defname, modalType} : { label:string, path:string, defId:string, defname:string, modalType:Number }) {
    return (
        <MainLayout>
          <FilterBar val={label} path={path} modalType={modalType}/>
          <ListTable label={label} path={path} defId={defId} defname={defname} modalType={modalType}/>
        </MainLayout>
      )
}
