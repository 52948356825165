import L from "leaflet";
import red from "../assets/icons/red.png";
import blue from "../assets/icons/blue.png";
import darkblue from "../assets/icons/darkblue.png";
import orange from "../assets/icons/orange.png";
import purple from "../assets/icons/purple.png";
import yellow from "../assets/icons/yellow.png";

import darkgreen from "../assets/icons/darkgreen.png";
import noimage from "../../../../../assets/images/common/noimage.jpg";
import dayjs from "dayjs";
import { FetchImage } from "../../../store/action";

export const PointsToLatLng = (item: any) => {
  let xy: any = [-1 * item.y, item.x];
  return xy;
};

export let violationIcon = L.icon({
  iconUrl: red,
  iconSize: [15, 20],
});

export let unknownIcon = L.icon({
  iconUrl: yellow,
  className: "spl-yellow-icon",
  iconSize: [15, 20],
});

export let plateUnkown = L.icon({
  iconUrl: yellow,
  className: "spl-plate-unkown-icon",
  iconSize: [15, 20],
});

export let vacantIcon = L.icon({
  iconUrl: darkgreen,
  iconSize: [15, 20],
});

export let reservedIcon = L.icon({
  iconUrl: blue,
  iconSize: [15, 20],
});

export let outofserviceIcon = L.icon({
  iconUrl: orange,
  iconSize: [15, 20],
});

export let occupiedIcon = L.icon({
  iconUrl: darkblue,
  iconSize: [15, 20],
});

export const popupOptions = {
  className: "customPopup",
  keepInView: true,
  maxWidth: 500,
};

export function scaleIconForMarker(marker: { options: { icon: { options: any } } }) {
  const iconOptions = marker.options.icon.options;

  const newIcon = L.icon({
    iconUrl: iconOptions.iconUrl,
    className: iconOptions.className,
    iconSize: [25, 30],
  });

  return newIcon;
}

// <li>
// Plate Number
// <span class='text-right'>
// ${
//     data?.parkingDetails?.plateNumber
//         ? data?.parkingDetails?.vehicleCategory + data?.parkingDetails?.plateNumber + data?.parkingDetails?.placeOfRegistration
//         : data?.visit?.plate?.text ?? '-'
// }
// </span>
// </li>

export const popUpTemplate = (data: any, site: any, type: string) => {
  return `
    <div class="live-monitor-wrapper">
    <div class="live-monitor-wrapper-img" >
    <a id="bayimgid" target="blank">

    <img src=${data?.parkingDetails?.images ?? noimage} style="object-fit :contain;"/> />

    </a>
    </div>
    <div class="live-monitor-wrapper-content">
        <ul>
            <li> 
                <h2 class='border-title'> ${data?.parkingDetails?.bayName ?? "-"} (${type})</h2>			
            </li>
            <li>
                Plate Number 
                <span class='text-right'>
                ${
                  data?.is_occupied
                    ? data?.parkingDetails?.plateNumber
                      ? data?.parkingDetails?.plateNumber
                      : data?.visit?.plate?.text ?? "-"
                    : "-"
                }
                </span>
            </li>
            <li>
            Vehicle Category
            <span class='text-right'>
            ${data?.is_occupied ? data?.parkingDetails?.vehicleCategory ?? "-" : "-"}
            </span>
        </li>
        <li>
        Place of Reg
        <span class='text-right'>
        ${data?.is_occupied ? data?.parkingDetails?.placeOfRegistration ?? "-" : "-"}
        </span>
    </li>
 
            <li>
                Name 
                <span class='text-right'>
                ${data?.parkingDetails?.fullName ?? "-"}
                </span>
            </li>
        
            <li>
                Bay Type 
                <span class='text-right'>
                ${data?.type?.name ?? "-"}
                </span>
            </li>
            <li>
                Zone 
                <span class='text-right'>
                ${data?.zone?.name ?? "-"}
                </span>
            </li>
            <li>
                Sensor MAC address 
                <span class='text-right'>
                ${data?.sensor?.extended_properties?.mac_address ?? "-"}
                </span>
            </li>
            <li>
                Sensor last contact  
                <span class='text-right'>
                ${dayjs(data?.sensor?.last_contact).format("DD-MMM-YYYY hh:mm a") ?? "-"}
                </span>
            </li>
            <li>
                Entry time  
                <span class='text-right'>
                ${data?.is_occupied ? dayjs(data?.visit?.entry_timestamp).format("DD-MMM-YYYY hh:mm a") ?? "-" : "-"}
                </span>
            </li>
            <li>
            Dwell
            <span class='text-right'>
            ${
              data?.is_occupied
                ? data?.parkingDetails?.currentDwellTime
                  ? data?.parkingDetails?.currentDwellTime
                  : data?.visit?.dwell ?? "-"
                : "-"
            }
            </span>
        </li>

        </ul>
    </div>
</div>
        `;
};

export const popUpTemplateUpdate = (data: any, site: any, type: string) => {
  let image;
  FetchImage({
    SiteID: site,
    BayRefID: data?.id,
  })?.then((res) => {
    let imageTag: any = document.getElementById("bayImg");
    let bayimgid: any = document.getElementById("bayimgid");

    console.log("imageTag", imageTag);
    if (imageTag && bayimgid) {
      if (res?.response) {
        imageTag.src = res?.response;
        bayimgid.href = res?.response;
      } else {
        imageTag.src = noimage;
      }
    }
  });

  console.log("logged image", image);

  return `
            <div class="live-monitor-wrapper">
                <div class="live-monitor-wrapper-img" >
                <a id="bayimgid" target="blank">
                <img id="bayImg"  style="object-fit :contain;"/>

                </a>
                </div>
                <div class="live-monitor-wrapper-content">
                    <ul>
                        <li> 
                            <h2 class='border-title'> ${data?.parkingDetails?.bayName ?? "-"} (${type})</h2>			
                        </li>
                        <li>
                            Plate Number 
                            <span class='text-right'>
                            ${
                              data?.is_occupied
                                ? data?.parkingDetails?.plateNumber
                                  ? data?.parkingDetails?.plateNumber
                                  : data?.visit?.plate?.text ?? "-"
                                : "-"
                            }
                            </span>
                        </li>
                        <li>
                        Vehicle Category
                        <span class='text-right'>
                        ${data?.is_occupied ? data?.parkingDetails?.vehicleCategory ?? "-" : "-"}
                        </span>
                    </li>
                    <li>
                    Place of Reg
                    <span class='text-right'>
                    ${data?.is_occupied ? data?.parkingDetails?.placeOfRegistration ?? "-" : "-"}
                    </span>
                </li>
             
                        <li>
                            Name 
                            <span class='text-right'>
                            ${data?.is_occupied ? data?.parkingDetails?.fullName ?? "-" : "-"}
                            </span>
                        </li>
                    
                        <li>
                            Bay Type 
                            <span class='text-right'>
                            ${data?.type?.name ?? "-"}
                            </span>
                        </li>
                        <li>
                            Zone 
                            <span class='text-right'>
                            ${data?.zone?.name ?? "-"}
                            </span>
                        </li>
                        <li>
                            Sensor MAC address 
                            <span class='text-right'>
                            ${data?.sensor?.extended_properties?.mac_address ?? "-"}
                            </span>
                        </li>
                        <li>
                            Sensor last contact  
                            <span class='text-right'>
                            ${dayjs(data?.sensor?.last_contact).format("DD-MMM-YYYY hh:mm a") ?? "-"}
                            </span>
                        </li>
                        <li>
                            Entry time  
                            <span class='text-right'>
                            ${
                              data?.is_occupied
                                ? dayjs(data?.visit?.entry_timestamp).format("DD-MMM-YYYY hh:mm a") ?? "-"
                                : "-"
                            }
                            </span>
                        </li>
                        <li>
                        Dwell
                        <span class='text-right'>
                        ${
                          data?.is_occupied
                            ? data?.parkingDetails?.currentDwellTime
                              ? data?.parkingDetails?.currentDwellTime
                              : data?.visit?.dwell ?? "-"
                            : "-"
                        }
                        </span>
                    </li>
    
                    </ul>
                </div>
            </div>
            `;
};

// export const calcBayTypeCount = (bayResponseData: any[]) => {
//   return bayResponseData.reduce((acc: any, val: any) => {
//     acc["total_bay"] = acc["total_bay"] === undefined ? 1 : (acc["total_bay"] += 1);

//     if (val?.is_occupied) {
//       acc["total_occupied"] = acc["total_occupied"] === undefined ? 1 : (acc["total_occupied"] += 1);
//     } else if (!val?.is_occupied) {
//       acc["total_vacant"] = acc["total_vacant"] === undefined ? 1 : (acc["total_vacant"] += 1);
//     }

//     if (val?.is_occupied && !val?.visit?.plate?.text) {
//       acc["plate_failed"] = acc["plate_failed"] === undefined ? 1 : (acc["plate_failed"] += 1);
//     }

//     if (val?.is_occupied && val?.visit?.plate === null) {
//       acc["plate_null"] = acc["plate_null"] === undefined ? 1 : (acc["plate_null"] += 1);
//     }

//     if (val?.is_occupied && val?.visit?.plate !== null && val?.parkingDetails?.plateNumber === null) {
//       acc["unknown_vehicle"] = acc["unknown_vehicle"] === undefined ? 1 : (acc["unknown_vehicle"] += 1);
//     } else if (val?.is_out_of_service) {
//       acc["is_out_of_service"] = acc["is_out_of_service"] === undefined ? 1 : (acc["is_out_of_service"] += 1);
//     } else if (val?.is_in_violation) {
//       acc["is_in_violation"] = acc["is_in_violation"] === undefined ? 1 : (acc["is_in_violation"] += 1);
//     } else if (val?.is_occupied && val?.parkingDetails?.plateNumber) {
//       acc["is_occupied"] = acc["is_occupied"] === undefined ? 1 : (acc["is_occupied"] += 1);
//     } else if (val?.is_reserved) {
//       acc["is_reserved"] = acc["is_reserved"] === undefined ? 1 : (acc["is_reserved"] += 1);
//     } else {
//       if (val?.is_occupied === false && val?.is_reserved === false) {
//         acc["is_vacant"] = acc["is_vacant"] === undefined ? 1 : (acc["is_vacant"] += 1);
//       }
//     }

//     return acc;
//   }, {});

// };


export const calcBayTypeCount = (bayResponseData: any[]) => {
  return bayResponseData.reduce((acc: any, val: any) => {
    acc["total_bay"] = acc["total_bay"] === undefined ? 1 : (acc["total_bay"] += 1);

    if (val?.is_out_of_service) {
      acc["is_out_of_service"] = acc["is_out_of_service"] === undefined ? 1 : (acc["is_out_of_service"] += 1);

    } else if (val?.is_occupied) {
      if (val?.visit?.plate === null) {
        acc["plate_null"] = acc["plate_null"] === undefined ? 1 : (acc["plate_null"] += 1);

      } else if (val?.is_in_violation) {
        acc["is_in_violation"] = acc["is_in_violation"] === undefined ? 1 : (acc["is_in_violation"] += 1);
        ;
      } else if (val?.parkingDetails?.plateNumber) {
        acc["is_occupied"] = acc["is_occupied"] === undefined ? 1 : (acc["is_occupied"] += 1);

      } else {
        acc["unknown_vehicle"] = acc["unknown_vehicle"] === undefined ? 1 : (acc["unknown_vehicle"] += 1);
        ;
      }
    } else {
      if (val?.is_reserved) {
        acc["is_reserved"] = acc["is_reserved"] === undefined ? 1 : (acc["is_reserved"] += 1);

      }  else {
        acc["is_vacant"] = acc["is_vacant"] === undefined ? 1 : (acc["is_vacant"] += 1);

      }
    }

    return acc;
  }, {});
};





export const calcIconType = (item: any) => {
  if (item?.is_out_of_service) {
    return outofserviceIcon;
  } else if (item?.is_occupied) {
    if (item?.visit?.plate === null) {
      return plateUnkown;
    } else if (item?.is_in_violation) {
      return violationIcon;
    } else if (item?.parkingDetails?.plateNumber) {
      return occupiedIcon;
    } else {
      return unknownIcon;
    }
  } else {
    if (item?.is_reserved) {
      return reservedIcon;
    }  else {
      return vacantIcon;
    }
  }
};

export const calcType = (item: any) => {
  if (item?.is_out_of_service) {
    return "Out of service";
  } else if (item?.is_occupied) {
    if (item?.visit?.plate === null) {
      return "No Plate";
    } else if (item?.is_in_violation) {
      return "In violation";
    } else if (item?.parkingDetails?.plateNumber) {
      return "Occupied";
    } else {
      return "Unknown vehicle";
    }
  } else {
    if (item?.is_reserved) {
      return "Reserved";
    }  else {
      return "Vacant";
    }
  }
};
