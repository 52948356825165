/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import ModalLayout from '../../../../common/layouts/ModalLayout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { FailedToast, FormatDate, FormatTime, SuccesToast } from '../../../../utils/utilFunctions';
import {
	add_parking_request,
	checkAlreadyAllocationExists,
	checkAlreadyAllocationExistsFilterApi,
	checkVehicleHasRequests,
	fetch_all_usersSearch,
	fetch_parkingRequestslist,
	get_available_zonesListGraph,
	get_reservedBayList,
	parkingRequestStatus_action,
} from '../../store/action';
import StatusChipComponent from '../../../../common/components/statusChipComponent';
import { fetch_visitor_detail } from '../../../visitor/store/action';
import { search_user } from '../../../security/store/action';
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'antd';
import FormSelect from '../../../../common/components/FormSelect';
import ErrorComponent from '../../../../common/components/ErrorComponent';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { useNavigate } from 'react-router-dom';
import { fetch_BayDDL, fetch_RejectionReasonDDL } from '../../../../common/general/action';
import TreeSelectCustom from '../../../../common/components/tree_select/NewTreeSelectCustom';
import CheckAvilability from './CheckAvilability';
import ReserveABay from './ReserveABay';
import AllocationChart from './AllocationChart';
import nodatafound from '../../../../assets/images/common/nodatafound.png';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import { RangePickerProps, RangeType } from 'rc-picker/lib/RangePicker';
import { EventValue } from 'rc-picker/lib/interface';
import noImage from '../../../../assets/images/common/noimage.jpg';
import usePermission from '../../../../utils/hooks/usePermission';
import moment from 'moment';
import { subModuleList } from '../../../../config/subModuleList';
import { APPLICATION_SETTINGS_GENERAL } from '../../../../config/endpoint';
import Request from '../../../../config/api';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import FormInput from '../../../../common/components/FormInput';
import FormTextarea from '../../../../common/components/FormTextarea';
import FailedMessagePop from '../../../../common/components/FailedMessage';
import ActionConfirm from '../../../../common/components/ActionConfirmBox';
import { FilterProps, commonSearchQuery } from '../../../../config/filterSearch';

const { RangePicker } = DatePicker;
type ddl = {
	label: string;
	value: any;
};
type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
	reqType?: any;
};
const reqTypeArr = [
	{ value: 1, label: 'Parking Request' },
	// { value: 5, label: 'Overtime Parking' }, //Temporarily revoked
	//  , { value:2, label:'Update Vehicle'}, { value:3, label:'Parking Cancellation'}
];
const reqTypeArr2 = [
	{ value: 1, label: 'Employee Parking Request' },
	{ value: 2, label: 'Visitors Parking Request' },
	{ value: 3, label: 'Update Employee Vehicle' },
	{ value: 4, label: 'Update Visitor Vehicle' },
	{ value: 5, label: 'Employee Overtime Parking Request' },
	{ value: 6, label: 'Visitors Overtime Parking Request' },
	{ value: 7, label: 'Employee Request Cancellation' },
	{ value: 8, label: ' Visitor Request Cancellation' },
];
const yesterday = new Date(Date.now() - 86400000);

const isSameOrBefore = (val: any, end: any) => {
	let d1 = moment(val, 'HH:mm:ss', true).isValid() ? new Date(`01-01-2001 ${val}`) : moment(new Date(val)).format('HH:mm:ss');
	let d2 = moment(end, 'HH:mm:ss', true).isValid() ? new Date(`01-01-2001 ${end}`) : moment(new Date(end)).format('HH:mm:ss');
	//console.log('DIFFERENCES',moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'minute'),
	//  moment(d1,'HH:mm:ss').diff(moment(d2,'HH:mm:ss'),'hours'),d1,d2)
	return moment(d1, 'HH:mm:ss').diff(moment(d2, 'HH:mm:ss'), 'minute') > 0;
};

const schema = yup.object().shape({
	reqestType: yup.number().default(1),

	requestedFUName: yup.string().typeError('Please enter a valid value').required(),
	requstedFUEmailID: yup.string().typeError('Please enter a valid value'),
	requestedFUID: yup.string().typeError('Please enter a valid value').nullable(),

	RequestedFUMobileNo: yup.string().typeError('Please enter a valid value').nullable(),
	RequestedFUCompany: yup.string().typeError('Please enter a valid value').nullable(),
	age: yup.string().typeError('Please enter a valid value').nullable(),
	gender: yup.string().typeError('Please enter a valid value').nullable(),
	priority: yup.string().typeError('Please enter a valid value').nullable(),
	contact: yup.string().typeError('Please enter a valid value').nullable().notRequired()
	// .when('contactNo', {
	// 	is: (value: any) => (value==='') || value?.length,
	// 	then: rule => rule.matches(
	// 		///^[0-9]+$/,
	// 		/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
	// 	 'Enter valid contact'),
	// }).test('len','Minimum 7 and maximum 12 digits can be in contact',(value:any)=>(value?.length > 0 && value?.match(/\d/g)?.length > 7 && value?.match(/\d/g)?.length < 13 )),
	.when('contactNo',{
		is : (contact:any) => contact!=='' && typeof contact!=='undefined',
		then : yup.string().typeError('Please enter a valid value').
		matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Must be only digits').min(5, 'mininmum 5 digits needed').max(16, 'maximum  of 16 digits possible')
	}),
	// .when('contact', {
	// 	is: (value: any) => value && value?.length>0,
	// 	then: rule => rule.matches(/^[0-9]+$/, 'Must be only digits').min(5, 'mininmum 5 digits needed').max(16, 'maximum  of 16 digits possible'),
	// })
	
	PlateNo: yup.string().typeError('Please enter a valid value').required(),
	PlaceOfRegistration: yup.string().typeError('Please enter a valid value').nullable().required(),
	Brand: yup.string().typeError('Please enter a valid value').nullable(),
	Category: yup.string().typeError('Please enter a valid value').nullable().required(),
	Color: yup.string().typeError('Please enter a valid value').nullable(),
	// FK_VPCategoryID		: yup.number(),
	Descriptions: yup.string().typeError('Please enter a valid value').nullable(),

	startDate: yup
		.date().typeError('Please enter a valid value')
		.nullable()
		.when('reqestType', {
			is: (reqestType: any) => reqestType === 1 || reqestType === 2 || reqestType === 5 || reqestType === 6,
			//then: yup.date().typeError('Please enter a valid value').min(yesterday, 'Date cannot be in the past').required(),
			then: yup.date().typeError('Please enter a valid value').required('Date is required'),
		}),
	endDate: yup
		.date().typeError('Please enter a valid value')
		.nullable()
		.when('reqestType', {
			is: (reqestType: any) => reqestType === 1 || reqestType === 2 || reqestType === 5 || reqestType === 6,
			then: yup
				.date().typeError('Please enter a valid value')
				.min(yesterday, 'Date cannot be in the past')
				.required()
				.when('startDate', (startDate, yup) => startDate && yup.min(startDate, 'End date cannot be before start date').required()),
		}), //permanent parking enddate not req

	startTime: yup
		.string().typeError('Please enter a valid value')
		.nullable()
		.when(['reqestType', 'takePolicyTime', 'IsPermenantParking'], {
			is: (reqestType: any, takePolicyTime: boolean, IsPermenantParking: boolean) => (reqestType === 1 || reqestType === 2) && !takePolicyTime && !IsPermenantParking,
			then: yup.string().typeError('Please enter a valid value').test('to_time_test', 'Start time must be before End time', function (value) {
				console.log('VALUE=>', value, 'THISP', this.parent);
				const { endTime }: any = this.parent;
				console.log('FROMTIME', endTime);
				return isSameOrBefore(endTime, value);
			}),
		}),
	endTime: yup
		.string().typeError('Please enter a valid value')
		.nullable()
		.when(['reqestType', 'takePolicyTime','IsPermenantParking'], {
			is: (reqestType: any, takePolicyTime: boolean, IsPermenantParking: boolean) => (reqestType === 1 || reqestType === 2) && !takePolicyTime && !IsPermenantParking,
			then: yup.string().typeError('Please enter a valid value').test('from_time_test', 'End time must be after Start time', function (value) {
				const { startTime }: any = this.parent;
				console.log('TOTIME', startTime);
				return isSameOrBefore(value, startTime);
			}),
		}),
	fK_SiteID: yup.number().typeError('Please enter a valid value').when('reqestType', { is: (reqestType: any) => reqestType === 1 || reqestType === 2, then: yup.number().typeError('Please enter a valid value').required() }),
	ApprovedZoneID: yup
		.mixed()
		.when('reqestType', { is: (reqestType: any, Status: any) => ((reqestType === 1 || reqestType === 2) && Status !==2), then: yup.number().typeError('Please enter a valid value').required() })
		.nullable(),
	ApprovedBayID: yup.number().nullable().default(0),
	ApproverComments: yup.string().typeError('Please enter a valid value').required(),
	IsMedicalPass: yup.boolean().default(false).nullable(),
	IsPermenantParking: yup.boolean().nullable(),
	isVisitor: yup.boolean(),
	isWarningAlreadyAllocated: yup.boolean().default(false),
		//.oneOf([false], 'Vehicle is already allocated at these date-time intervals'),
	isAlreadyAllocated: yup.boolean().oneOf([false], 'Vehicle is already allocated at these date-time intervals and at this selected Bay in this Zone'),
	isVehicleHasPendingRequests: yup.boolean().oneOf([false], 'Pending requests exists binding current vehicle.'),
	takePolicyTime: yup.boolean(),
	isTimeValid: yup.boolean().oneOf([false], 'Time cannot be past.'),

	Status: yup.number().default(1).required(),
	fK_RejectReasonID: yup.number().nullable().when('Status', { is: 2, then: yup.number().required() }),
});
const ParkingRequestAddModal = ({ open, handleClose, Data, reqType = false }: Props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
		clearErrors,
		watch,
		trigger,
		getValues,
	} = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema),
		// reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	useEffect(() => {
		clearErrors();
		setselectedUser(null);
		getProhibittedDate();
		if (Data) {
			fetch_RejectionReasonDDL(dispatch);
			if ((Data?.fK_RequestType === 3 || Data?.fK_RequestType === 4) && Data?.pRequestID) {
				isVehicleWithPendingRequests(Data?.pRequestID);
			} else if (Data?.fK_RequestType !== 7 && Data?.fK_RequestType !== 8) {
				fetch_BayDDL(dispatch);
			}
			setValue('reqestType', Data?.fK_RequestType);
			setReqTp(Data?.fK_RequestType);
			setValue('Status', 1);

			setValue('requestedFUName', Data?.requestedFUName);
			setValue('requstedFUEmailID', Data?.requstedFUEmailID);
			setValue('requestedFUID', Data?.requestedFUID);

			setValue('contact', Data?.rF_UserContactNo);
			setValue('company', Data?.requestedFUCompany);
			setValue('age', Data?.rF_Age ? Data?.rF_Age.toString() : '');
			setValue('gender', Data?.rF_Gender === 1 ? 'Male' : Data?.rF_Gender === 2 ? 'Female' : '');
			setValue('priority', Data?.rF_PriorityLevelID);

			setValue('PlateNo', Data?.rF_PlateNumber ?? '');
			setValue('PlaceOfRegistration', Data?.rF_PlaceOfRegistration ?? '');
			setValue('Brand', Data?.rF_Brand ?? '');
			setValue('Category', Data?.rF_VehicleCategory ?? '');
			setValue('Color', Data?.rF_Colour ?? '');
			setValue('Descriptions', Data?.rF_Descriptions ?? '');

			setValue('fK_SiteID', Data?.fK_SiteID);
			setValue('ApprovedZoneID', Data?.approvedZoneID ? Data?.approvedZoneID : null);
			setSelZone(Data?.approvedZoneID ? [{ value: Data?.approvedZoneID, label: Data?.zoneName }] : []);
			setValue('ApprovedBayID', Data?.fK_ApprovedBayID);
			if (Data?.fK_ApprovedBayID) setselectedBay({ value: Data?.fK_ApprovedBayID, label: Data?.bayName });
			setValue('ApproverComments', Data?.approverComments ? Data?.approverComments : '');

			setValue('IsMedicalPass', Data?.isMedicalPass??false);
			setValue('IsPermenantParking', Data?.isPermenantParking);
			setValue('isVisitor', Data?.rfUserType === 1 ? false : true);
			setValue('fK_RejectReasonID', Data?.fK_RejectReasonID ?? 0);
			setSelIsVisitor(Data?.rfUserType === 1 ? false : true);
			setSelUserId(Data?.requestedFUID);
			setdate([dayjs(Data?.startDate, 'YYYY-MM-DD'), dayjs(Data?.endDate, 'YYYY-MM-DD')]);
			if (
				dayjs(Data?.startTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid() &&
				dayjs(Data?.endTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid()
			) {
				setRangeTime([dayjs(Data?.startTime, 'HH:mm:ss'), dayjs(Data?.endTime, 'HH:mm:ss')]);
				setValue('takePolicyTime', false);
			} else setValue('takePolicyTime', true);
			setValue(
				'startDate',
				moment(Data?.startDate, ['YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss'], true).isValid()
					? moment(Data?.startDate).format('YYYY-MM-DD')
					: null
			);
			setValue(
				'endDate',
				moment(Data?.endDate, ['YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss'], true).isValid() ? moment(Data?.endDate).format('YYYY-MM-DD') : null
			);
			setValue('startTime', Data?.startTime);
			setValue('endTime', Data?.endTime);

			setAction(1);
		} else {
			setReqTp(1);
			setAction(1);
			reset();
			setSelIsVisitor(false);
			setSelUserId(null);
			setValue('takePolicyTime', false);
			setdate([moment(new Date(),'YYYY-MM-DD'),moment(new Date(),'YYYY-MM-DD').add(5,'days')])
			setRangeTime([dayjs(), dayjs().add(1,'hours')]);
			setValue(
				'startDate',
				moment(new Date()).format('YYYY-MM-DD')
			);
			setValue(
				'endDate',
				moment(new Date()).add(5,'days').format('YYYY-MM-DD')
			);
			setValue('startTime', dayjs().format('HH:mm:ss'));
			setValue('endTime', dayjs().add(1,'hours').format('HH:mm:ss'));
			setValue('IsMedicalPass', false);
		}
	}, [Data]);

	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmData, setConfirmData] = useState<any>()

	const [tabVal, setTablVal] = useState(1);
	const [tabData, setTabData] = useState<any>([]);
	const [listLoading, setListLoading] = useState(false)

	const [selZone, setSelZone] = useState<ddl[]>([]);

	const [usersArr, setUsersArr] = useState<any>();
	const [usersList, setUsersList] = useState<any>();
	const [selectedUser, setselectedUser] = useState<any>();
	const [reqTp, setReqTp] = useState<any>(0);
	const [action, setAction] = useState(1);
	const [date, setdate] = useState<any>([]);
	const [rangeTime, setRangeTime] = useState<any>([]);
	const [selectAllocation, setSelectAllocation] = useState<any>();
	const PARKINGREQUESTS = useAppSelector((state) => state?.parkingrequest);
	const GENERAL_DDL = useAppSelector((state) => state?.general);

	const [graphLoading, setGraphLoading] = useState(true);

	const [selectedBay, setselectedBay] = useState<any>(null);
	const [selIsVisitor, setSelIsVisitor] = useState(false);
	const [selUserId, setSelUserId] = useState(null);
	const [isAllocationAvail, setIsAllocationAvail] = useState<any>([]);
	const [expanded, handleChangeExpand] = useState<any>(0);
	const [prohDate, setProhDate] = useState<any>();

	const [openErrData, setOpenErrData] = useState<any>()
	const [openErr, setOpenErr] = useState(false)
	
	const [loading, setLoading] = useState(false);
	// const createPerm = usePermission({ subModuleID: subModuleList['Parking Requests'], type: 'isCreate' });
	// const editPerm = usePermission({ subModuleID: subModuleList['Parking Requests'], type: 'isEdit' });

	const cancelConfirm = () =>{ setConfirmModal(false); setLoading(false); }

	console.log('isWarningAlreadyAllocated==>',watch('isWarningAlreadyAllocated'),watch('IsMedicalPass'),'DATA=>',Data,'GETVAL=>',getValues())

	const isAllocationExist = ({ plate, startD, endD, startT, endT, zoneVal, bayVal = null }: any) => {
		const successFx = (res: any) => {
			console.log(res);

			if(reqTp === 5 ) return
			if(res && res?.response && res?.response?.data && res?.response?.data?.length > 0){
				if(res?.response?.data && res?.response?.data?.filter((el:any) => el?.currentStatus===1)?.length > 0){
					if(zoneVal && zoneVal.length>0 && bayVal){
						setValue('isAlreadyAllocated', true);
						trigger('isAlreadyAllocated');
						setValue('isWarningAlreadyAllocated', false);
						trigger('isWarningAlreadyAllocated');
					}
					else if(zoneVal && zoneVal.length>0){
						setValue('isAlreadyAllocated', false);
						trigger('isAlreadyAllocated');
						setValue('isWarningAlreadyAllocated', true);
						trigger('isWarningAlreadyAllocated');
					}else{
						setValue('isWarningAlreadyAllocated', false);
						trigger('isWarningAlreadyAllocated');
						setValue('isAlreadyAllocated', false);
						trigger('isAlreadyAllocated');
					}
			
				}
				else{
					setValue('isWarningAlreadyAllocated', false);
					trigger('isWarningAlreadyAllocated');
					setValue('isAlreadyAllocated', false);
					trigger('isAlreadyAllocated');	
				}
			}else{
				setValue('isWarningAlreadyAllocated', false);
				trigger('isWarningAlreadyAllocated');
				setValue('isAlreadyAllocated', false);
				trigger('isAlreadyAllocated');
			}
			
		};
		const failedFx = (err: any) => {
			console.log(err);
		};
		console.log('isALLOCAtEd', plate, startD, endD, startT, endT);
		// if (plate && startD) {
		// 	let postData:any = {
		// 		'PlateNumber': plate,
		// 		'StartDate': FormatDate(startD, 'YYYY-MM-DDTHH:mm:ss'),
		// 		'EndDate': FormatDate(endD ? endD : '', 'YYYY-MM-DDTHH:mm:ss'),
		// 		'StartTime': startT ? startT?.format('HH:mm:ss') : moment().format('HH:mm:ss'),
		// 		'EndTime': endT ? endT?.format('HH:mm:ss') : moment().format('HH:mm:ss'),
		// 	};
		// 	if(zoneVal && zoneVal.length>0 && bayVal){
		// 		let oldObj = postData;
		// 		postData = { ...oldObj, 'ZoneId' : zoneVal[0]?.value, 'BayId' : bayVal?.value }
		// 	}
		// 	checkAlreadyAllocationExists(postData, successFx, failedFx);
		// }

		if(plate && startD && zoneVal){

			let QueryInput: FilterProps[] = [
				{
					field: 'CAST (EndDate AS date)',
					value: `'${FormatDate(startD, 'YYYY-MM-DD')}'`,
					//dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
					operator: '>=',
				},
				{
					field: 'CAST (StartDate AS date)',
					value: `'${FormatDate(endD ? endD : '', 'YYYY-MM-DD')}'`,
					//dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
					operator: '<=',
				},
				{
					field: 'EndTime',
					value: `'${startT ? startT?.format('HH:mm:ss') : moment().format('HH:mm:ss')}'`,
					//dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
					operator: '>=',
				},
				{
					field: 'StratTime',
					value: `'${endT ? endT?.format('HH:mm:ss') : moment().format('HH:mm:ss')}'`,
					//dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
					operator: '<=',
				},
				{
					field: 'PlateNumber',
					value: `'${plate}'`,
					operator: '=',
				},
				{
					field: 'FK_ZoneID',
					value: zoneVal ? zoneVal[0]?.value : null,
					operator: '=',
				},
				{
					field: 'FK_BayID',
					value: bayVal ? bayVal?.value : null,
					operator: '=',
				}
			];

			let postData:any = {
				'filters': {
					'query': commonSearchQuery(QueryInput, 'AND'),
				},
				'pagination': {
					'pageNo': 1,
					'limit': 5,
				},
			} 

			checkAlreadyAllocationExistsFilterApi(postData, successFx, failedFx)
		}
		

	};

	const isVehicleWithPendingRequests = (id: number) => {
		const successFx = (res: any) => {
			console.log(res);
			setValue('isVehicleHasPendingRequests', res?.response?.data && res?.response?.data?.length > 0);
			trigger('isVehicleHasPendingRequests');
		};
		const failedFx = (err: any) => {
			console.log(err);
		};
		checkVehicleHasRequests(id, successFx, failedFx);
	};

	const promiseUserOptions = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleUserInputChange(inputValue));
		});

	const handleUserInputChange = async (key?: string) => {
		let Response = await fetch_all_usersSearch(key);
		console.log('RESPONSE', Response);
		let data = Response?.response?.data ?? [];

		let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: `${item?.plateNumber} (${item?.fullName})`, object: item }));
		// let Options = data

		console.log('options', Options);
		setUsersList(data);
		setUsersArr(Options || []);
		// setUserVehicleList([])

		return Options;
	};

	const handleUserChange = (val: any) => {
		console.log('val=>', val);
		let data: any = usersList.find((item: any) => item === val?.object);
		let forData = {
			'filters': {
				'query': `userUniqueID = ${data?.userUniqueID}`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': 1,
			},
		};
		if (data) {
			setselectedUser(data);

			setValue('requestedFUName', data?.fullName);
			setValue('requstedFUEmailID', data?.emailID);
			setValue('requestedFUID', data?.userUniqueID);
			if (data?.isVisitor) {
				fetch_visitor_detail(data?.userUniqueID)?.then((res: any) => {
					let resp = res?.response?.data;
					//console.log(resp, dayjs(resp?.dob),dayjs(resp?.dob).diff(dayjs(),"years"))

					setValue('contact', resp?.contactNo);
					setValue('company', resp?.companyName);
					setValue('age', resp?.dob ? dayjs().diff(dayjs(resp?.dob), 'years')?.toString() : '');
					setValue('gender', resp?.gender === 1 ? 'Male' : resp?.gender === 2 ? 'Female' : '');
					setValue('priority', resp?.fK_PriorityLevel);
					setValue('designation', resp?.desName);
					setValue('department', resp?.depName);
					if (reqTp === 1 || reqTp === 2) {
						setValue('reqestType', 2);
					} else {
						setValue('reqestType', 6);
					}
				});
			} else {
				search_user(forData)?.then((res: any) => {
					let resp = res?.response?.data[0];
					console.log(res, resp, dayjs(resp?.dob), dayjs().diff(dayjs(resp?.dob), 'years'));

					setValue('contact', resp?.contactNo);
					setValue('company', resp?.companyName);
					setValue('age', resp?.dob ? dayjs().diff(dayjs(resp?.dob), 'years')?.toString() : '');
					setValue('gender', resp?.gender === 1 ? 'Male' : resp?.gender === 2 ? 'Female' : '');
					setValue('priority', resp?.fK_PriorityLevel);
					setValue('designation', resp?.desName);
					setValue('department', resp?.depName);
					if (reqTp === 1 || reqTp === 2) {
						setValue('reqestType', 1);
					} else {
						setValue('reqestType', 5);
					}
				});
			}
			setValue('PlateNo', data?.plateNumber);
			setValue('Category', data?.vehicleCategory);
			setValue('PlaceOfRegistration', data?.placeOfRegistration);
			setValue('Color', data?.color ? data?.color : data?.colour);
			setValue('Brand', data?.brand);
			setValue('isVisitor', data?.isVisitor ? true : false);
			setSelIsVisitor(data?.isVisitor ? true : false);
			setSelUserId(data?.userUniqueID);
			console.log('RANGE',rangeTime)
			isAllocationExist({
				plate: data?.plateNumber,
				startD: date && date?.length > 0 ? date[0] : '',
				endD: date && date?.length > 0 ? date[1] : '',
				startT: rangeTime && rangeTime?.length > 0 ? rangeTime[0] : '',
				endT: rangeTime && rangeTime?.length > 0 ? rangeTime[1] : '',
				zoneVal : selZone && selZone?.length > 0 ? selZone : [],
				bayVal : selectedBay
			});
		} else {
			setValue('requestedFUName', '');
			setValue('requstedFUEmailID', '');
			setValue('requestedFUID', '');
			setValue('contact', '');
			setValue('company', '');
			setValue('age', '');
			setValue('gender', '');
			setValue('priority', '');
			setValue('designation', '');
			setValue('department', '');
			setValue('PlateNo', '');
			setValue('Category', '');
			setValue('PlaceOfRegistration', '');
			setValue('Color', '');
			setValue('Brand', '');
			setValue('isVisitor', true);
			setSelIsVisitor(false);
			setSelUserId(null);
			setValue('isAlreadyAllocated', false);
			trigger('isAlreadyAllocated');
			setValue('isWarningAlreadyAllocated', false);
			trigger('isWarningAlreadyAllocated');
		}
	};

	const FailMsgConfirm = () =>{
		fetch_parkingRequestslist(navigate, dispatch, {
			'filters': {
				'query': PARKINGREQUESTS?.PARKING_REQUEST_FILTER?.query ?? '',
			},
			'pagination': {
				'pageNo': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
				'limit': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
			},
		});
		setConfirmModal(false)
		setConfirmData(null)
		handleClose();
		setOpenErrData(null)
		setOpenErr(false);
	}

	const confirmApprove = () =>{
		
		setConfirmModal(false)
		const Success = (res: any) => {
			setLoading(false);
			SuccesToast(res?.response?.data);
			fetch_parkingRequestslist(navigate, dispatch, {
				'filters': {
					'query': PARKINGREQUESTS?.PARKING_REQUEST_FILTER?.query ?? '',
				},
				'pagination': {
					'pageNo': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
			setConfirmModal(false)
			setConfirmData(null)
			reset();
			handleClose();
		};
		const Failed = (err: any) => {
			setLoading(false);
			setOpenErrData(err)
			setOpenErr(true);
			// FailedToast(err?.response);
			
		};

		let dtObj = {}

		let postData = Data
			? {
				RequestID: Data?.pRequestID,
				...confirmData,
			}
			: {
				...confirmData,
				reqestType: confirmData?.reqestType,
				requestedFUID: confirmData.requestedFUID === '' || confirmData.requestedFUID === 0 ? null : confirmData.requestedFUID,
				// startDate: FormatDate(confirmData.startDate, 'YYYY-MM-DD'),
				// endDate: FormatDate(confirmData.endDate, 'YYYY-MM-DD'),
				// startTime: FormatTime(confirmData.startTime, 'HH:mm:ss'),
				// endTime: FormatTime(confirmData.endTime, 'HH:mm:ss'),
				requesterComments: confirmData?.ApproverComments,
			};

			if(confirmData.startDate || confirmData.startDate===null){
				dtObj = { startDate: 
					confirmData.startDate && confirmData.startDate!=='' ?
					FormatDate(confirmData.startDate, 'YYYY-MM-DD') : confirmData.startDate }
			}
			if(confirmData.endDate || confirmData.endDate===null){
				dtObj = { ...dtObj, endDate: 
					confirmData.endDate && confirmData.endDate!=='' ?
					FormatDate(confirmData.endDate, 'YYYY-MM-DD') : confirmData.endDate }
			}
			if(confirmData.startTime || confirmData.startTime===null){
				dtObj = { ...dtObj, startTime: 
					confirmData.startTime && confirmData.startTime!=='' ?
					FormatTime(confirmData.startTime, 'HH:mm:ss') : confirmData.startTime }
			}
			if(confirmData.endTime || confirmData.endTime===null){
				dtObj = { ...dtObj, endTime: 
					confirmData.endTime && confirmData.endTime!=='' ?
					FormatTime(confirmData.endTime, 'HH:mm:ss') : confirmData.endTime }
			}

			postData = { ...postData, ...dtObj }

			console.log('DATA=>',postData)
			console.log('CNFRIM',confirmData)
		if (Data) {
			let arrVal = [];
			arrVal.push(postData);
			postData = { UpdateParkingRequest: [...arrVal] };
			if(Data?.fK_RequestType===5 || Data?.fK_RequestType===6){
				postData = { ...postData, AllocationRefID : selectAllocation }
			}
			parkingRequestStatus_action(postData, Success, Failed);
		} else {
			if(reqTp===5 || reqTp===6){
				postData = { ...postData, AllocationRefID : selectAllocation }
			}
			add_parking_request(confirmData?.reqestType, postData, Success, Failed);
		}
	}

	const onSubmitHandler = (data: any) => {
		setLoading(true);
		setConfirmData(data)
		setConfirmModal(true)
		console.log('DATA=>', data);
		
	};
	const getProhibittedDate = () => {
		let postData = {
			'AppKey': '',
		};
		let api_url = APPLICATION_SETTINGS_GENERAL;
		Request('post', api_url, postData, undefined)?.then((res) => {
			const { statusCode } = res;
			if (statusCode === 200) {
				setProhDate(dayjs().add(res?.response?.data?.future_parking_possible_limit?.settingsValue, 'months').format('YYYY-MM-DD'));
			}else{
				setProhDate(dayjs().add(6, 'months').format('YYYY-MM-DD'))	
			}
		});
	};
	console.log('disabkeDates=>',isAllocationAvail,'DATE=>',date)
	const getDisabledDates = (curr: any) => {
		
		if (isAllocationAvail && isAllocationAvail?.length > 0 && reqTp !== 1 && reqTp !== 2) {
			let startCheck = true;
			let endCheck = true;
			startCheck = curr < dayjs(isAllocationAvail[0], 'YYYY-MM-DD');
			endCheck = curr > dayjs(isAllocationAvail[1], 'YYYY-MM-DD').endOf('day');
			return curr < dayjs().subtract(1, 'day').endOf('day') || startCheck || endCheck;

			// if(startDate)
			// 	return curr && isAllocationAvail?.map( (el:any) => curr > dayjs(el).endOf('day'))
		} else return curr < dayjs().subtract(1, 'day').endOf('day') || curr > dayjs(prohDate).endOf('day');
	};

	const getDisabledTime = (curr: any, type: any) => {
		//console.log('CURR=>',curr,type,dayjs(new Date()).diff(date[0],'hour'),dayjs(date[0]).diff(dayjs(new Date()),'hour'),dayjs(date[0]).format('DD-MM-YYYY'))
		// return {
		// 	disabledHours: () => [4,7],
		// 	disabledMinutes: () => [30,50],
		// 	disabledSeconds: () => [55, 56],
		// }
		if (date && date?.length > 0 && dayjs().diff(date[0], 'hour') === 0) {
			return {
				disabledHours: () => getDisabledHours(),
			};
		} else {
			return {};
		}
	};

	const getDisabledHours = () => {
		var hours = [];
		for (var i = 0; i < dayjs().get('hour'); i++) {
			hours.push(i);
		}
		return hours;
	};

	const getDisabledMinutes = (selectedHour: any) => {
		var minutes = [];
		console.log('MINUTES', selectedHour, dayjs().get('hour'));
		if (selectedHour === dayjs().get('hour')) {
			for (var i = 0; i < dayjs().get('minute'); i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	const setLevelSite = (val: any) => {
		console.log('VALlevel=>', val);
		if (val?.site) setValue('fK_SiteID', val?.site);
	};

	const selectionFn = (val: any) => {
		if (val) {
			setValue('fK_SiteID', val?.fK_SiteID);
			setValue('ApprovedZoneID', val?.fK_ZoneID);
			setValue('ApprovedBayID', val?.fK_BayID);
			trigger('ApprovedZoneID')
		}
	};
	const setDateFieldsValues = (val:boolean) =>{
		console.log('DATE',date, date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'))
		let d = date 
		let arr:any = []
		let postData = {
		  'AppKey': '',
		}
		let api_url = APPLICATION_SETTINGS_GENERAL
		if(val){
			Request('post', api_url, postData, undefined)?.then((res) => {
			  const { statusCode } = res;
			  if (statusCode === 200) {
				console.log('B4',d[0],d[0].format('YYYY-MM-DD'))
				let y = moment.isMoment(d[0]) ?
							moment(d[0]).add(res?.response?.data?.number_of_years_for_permanent_parking?.settingsValue,'years')
						:	dayjs(d[0],'YYYY-MM-DD').add(res?.response?.data?.number_of_years_for_permanent_parking?.settingsValue,'years')
				arr = [d[0], y]

				console.log('AF',d[0],d[0].format('YYYY-MM-DD'))
				setdate(arr)
		console.log('RESP',res,'ARRRESP',arr)				
		console.log('ARR=>', arr, arr[0].format('DD-MM-YYYY'), arr[1].format('DD-MM-YYYY'))

		setValue(
			'startDate',
			moment.isMoment(arr[0]) ?
					arr[0].format('YYYY-MM-DD') : arr[0].format('YYYY-MM-DD')
		);
		setValue(
			'endDate',
			moment.isMoment(arr[1]) ?
					arr[1].format('YYYY-MM-DD') : arr[1].format('YYYY-MM-DD')
		);
			  }
			});
		}else{

			arr = [d[0], 
			moment.isMoment(d[0]) ? moment(d[0]).add(5,'days') :
			dayjs(d[0],'YYYY-MM-DD').add(5,'days')]
			setdate(arr)
			setValue(
				'startDate',
				moment.isMoment(arr[0]) ?
						arr[0].format('YYYY-MM-DD') : arr[0].format('YYYY-MM-DD')
			);
			setValue(
				'endDate',
				moment.isMoment(arr[1]) ?
						arr[1].format('YYYY-MM-DD') : arr[1].format('YYYY-MM-DD')
			);	
		console.log('ARR=>', arr)
		}
		// setValue(
		// 	'startDate',
		// 	moment.isMoment(arr[0]) ?
		// 			arr[0].format('YYYY-MM-DD') : arr[0].format('YYYY-MM-DD')
		// );
		// setValue(
		// 	'endDate',
		// 	moment.isMoment(arr[1]) ?
		// 			arr[1].format('YYYY-MM-DD') : arr[1].format('YYYY-MM-DD')
		// );
		
	  }
	console.log('EERORS', errors);

	return (
		<>
		<ModalLayout open={open} handleClose={handleClose} big title={`${Data ? '' : 'Add '}Parking Request`}>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className='modal-body-content'>
					<div className='modal-body '>
						<div className='form-w-label modal-form'>
							<div className='col-12 pos-rel'>
								<div className='row w-100 modal-sticky-header'>
									<div
										className='dflex w-100 justify-space-between '
										// style={{ borderBottom: '1px solid var(--lightgrey)' }}
										>
										<div className='form-tabs'>
											<ul>
												<li>
													<a className={`${!tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(1)}
													style={{ cursor: (((reqTp === 1 || reqTp === 2 || reqTp === 5 || reqTp === 6) && selUserId) ||
														(Data && Data.pRequestID)) ?
														 'pointer' : 'default' }}
													>
														Request Details
													</a>
												</li>
												{(((reqTp === 1 || reqTp === 2 || reqTp === 5 || reqTp === 6) && selUserId) ||
													(Data && Data.pRequestID)) &&
													(tabData && tabData?.length > 0) &&
													(
														<li>
															<a
																className={` ${tabVal ? '' : 'active'} tab-link`}
																onClick={() => setTablVal(0)}>
																Allocated Parking {'  '}&nbsp;
																{tabData && (selUserId || Data) ? <b>( {tabData?.length} )</b> : null}
															</a>
														</li>
													)}
											</ul>
										</div>
										<div className='col-4'>
											<ReactSelect
												options={Data ? GENERAL_DDL?.REQUESTTYPE_DDL : reqTypeArr}
												isDisabled={Data ? true : false}
												// styles={{ control: (styles) => ({ ...styles, marginRight : '5%'}) }}
												className={'common-select common-select-grey'}
												placeholder={'Request Type'}
												onChange={(e) => {
													clearErrors();
													reset();
													setValue('PlateNo', '');
													setdate([]);
													setRangeTime([]);
													setselectedUser(null);
													setValue('reqestType', e?.value);
													setReqTp(e?.value);
													setValue('isAlreadyAllocated', false);
													trigger('isAlreadyAllocated');
													setValue('isWarningAlreadyAllocated', false);
													trigger('isWarningAlreadyAllocated');
												}}
												value={
													Data
														? GENERAL_DDL?.REQUESTTYPE_DDL?.find((el) => el.value === reqTp)
														: reqTypeArr?.find((el) => el.value === reqTp || el.value + 1 === reqTp)
												}
												isMulti={false}
											/>
										</div>
									</div>
								</div>

								{Data && tabVal ? (
									<div className='borderbox'>
										<div className='border-title-row flex-1' style={{ borderBottom: '1px solid var(--lightgrey)' }}>
											<h2 className='border-title'>Requested By</h2>
										</div>

										<div className='row'>
											{
												Data?.pReferanceID &&
												<div className='col mt-10'>
													<div className='icon-box'>
														<div className='icon-wrap'>
															<i className='fa-solid fa-hashtag'></i>
														</div>
														<h3>
															ASKGS ID <span>{Data?.pReferanceID}</span>
														</h3>
													</div>
												</div>
											}
											
											<div className='col mt-10'>
												<div className='icon-box'>
													<div className='icon-wrap'>
														<i className='fa-solid fa-user'></i>
													</div>
													<h3>
														Name <span>{Data?.requestedBUName}</span>
													</h3>
												</div>
											</div>

											<div className='col mt-10'>
												<div className='icon-box'>
													<div className='icon-wrap'>
														<i className='fa-solid fa-envelope'></i>
													</div>
													<h3>
														Email <span>{Data?.requstedBUEmailID}</span>
													</h3>
												</div>
											</div>

											<div className='col mt-10'>
												<div className='icon-box'>
													<div className='icon-wrap'>
														<i className='fa-solid fa-phone'></i>
													</div>
													<h3>
														Contact <span>{Data?.rBy_UserContactNo}</span>
													</h3>
												</div>
											</div>

											<div className='col mt-10'>
												<div className='icon-box'>
													<div className='icon-wrap'>
														<i className='fa-solid fa-building'></i>
													</div>
													<h3>
														Group Company <span>{Data?.rBy_GroupCompanyName}</span>
													</h3>
												</div>
											</div>
										</div>
									</div>
								) : null}

								{/* {tabVal ? ( */}
									<div className='borderbox' style={{ display: tabVal ? '' : 'none' }}>
										<div className='row'>
											<div
												className='dflex justify-space-between w-100 align-items-center pb-10'
												style={{ borderBottom: '1px solid var(--lightgrey)' }}>
												<div className='col-4'>
													<h2 className='border-title ml-5'>Requested For</h2>
													<div className='dflex w-100 align-items-center'>
													{selIsVisitor ? (
														<div className='badge yellow'>Visitor</div>
													) : selectedUser ? (
														<div className='badge green'>User</div>
													) : null}
													{Data ? (
														<div className='badge blue ml-5'>
															{Data?.isPermenantParking ? 'Permanent Parking' : 'Temporary Parking'}
														</div>
													) : null}
													{Data && Data?.createdOn ?
														<div className='badge blue ml-5'>
															Created On {FormatDate(Data?.createdOn)}
														</div> 
														: null}
													</div>
													
												</div>												
												
												{Data ? null : (
													<div className='col-6'>
														<AsyncSelect
															className={'common-select common-select-grey'}
															styles={{
																control: (styles) => ({
																	...styles,
																	padding: '8px 0px',
																	background: 'var(--grey-primary)',
																	border: 'none',
																}),
																placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
															}}
															placeholder='Find User or Vehicle'
															cacheOptions={usersArr}
															
															defaultOptions={usersArr}
															value={
																selectedUser
																	? usersArr?.find(
																		(el: any) =>
																			el.userUniqueID === selectedUser.userUniqueID &&
																			el.object === selectedUser
																	)
																	: null
															}
															getOptionLabel={(o) => o.label}
															getOptionValue={(o) => o.object}
															formatOptionLabel={(o) => (
																<div className='dflex justify-space-between align-items-center'>
																	<div className='dflex col-dir gap-5'>
																		<div>
																			<span
																				style={{
																					fontSize: '.85rem',
																					fontWeight: 600,
																					textTransform: 'capitalize',
																				}}>
																				{o?.object.fullName} -{' '}
																			</span>
																			<small style={{ fontStyle: 'italic' }}>
																				{o?.object.emailID}
																			</small>
																		</div>
																		<div
																			style={{
																				display: 'flex',
																				flexDirection: 'row',
																				width: '100%',
																				// justifyContent: 'space-between',
																			}}>
																			<span style={{ fontSize: '.85rem', fontWeight: 600 }}>
																				{o?.object.plateNumber} -{' '}
																			</span>
																			<small style={{ fontStyle: 'italic' }}>
																				{o?.object.placeOfRegistration}-
																			</small>
																			<small style={{ fontStyle: 'italic' }}>
																				{o?.object.vehicleCategory}
																			</small>
																		</div>
																	</div>

																	<div>
																		<StatusChipComponent
																			text={o?.object.isVisitor ? 'Visitor' : 'Employee'}
																		/>
																	</div>
																</div>
															)}
															loadOptions={promiseUserOptions}
															onChange={handleUserChange}
															isSearchable={true}
															isClearable={true}
															isOptionDisabled={(o) => o.object.isBlackListedVehicle}
														/>
													</div>
												)}
											</div>
										</div>

										<div className='row'>
											<div className='col-6'>
												<div className='form-group inline-input'>
													<label>Name *</label>
													{/* <input
														className='form-control '
														type='text'
														disabled={Data || reqTp === 5 || reqTp === 6}
														id=''
														{...register('requestedFUName')}
														onChange={(e) => {
															setselectedUser(null);
															setValue('requstedFUEmailID', '');
															setValue('requestedFUID', '');
															setValue('contact', '');
															setValue('company', '');
															setValue('age', '');
															setValue('isVisitor', true);
															setValue('gender', '');
															setValue('priority', '');
															setValue('department', '');
															setValue('designation', '');
															setValue('PlateNo', '');
															setValue('Category', '');
															setValue('PlaceOfRegistration', '');
															setValue('Color', '');
															setValue('Brand', '');
															setValue('Descriptions', '');
															setValue('reqestType', 2);
															setReqTp(2);
															setSelIsVisitor(true);
															setSelUserId(null);
														}}
													/> */}

													<FormInput type='text' name="requestedFUName" className='form-control' control={control}
														disabled={Data || reqTp === 5 || reqTp === 6}
														onChange={(e, formOnChange) => {
															let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
															formOnChange(v)
															setselectedUser(null);
															setValue('requstedFUEmailID', '');
															setValue('requestedFUID', '');
															setValue('contact', '');
															setValue('company', '');
															setValue('age', '');
															setValue('isVisitor', true);
															setValue('gender', '');
															setValue('priority', '');
															setValue('department', '');
															setValue('designation', '');
															setValue('PlateNo', '');
															setValue('Category', '');
															setValue('PlaceOfRegistration', '');
															setValue('Color', '');
															setValue('Brand', '');
															setValue('Descriptions', '');
															setValue('reqestType', 2);
															setReqTp(2);
															setSelIsVisitor(true);
															setSelUserId(null);

															setValue('isAlreadyAllocated', false);
															trigger('isAlreadyAllocated');
															setValue('isWarningAlreadyAllocated', false);
															trigger('isWarningAlreadyAllocated');
														}} />

												</div>
												<ErrorComponent name='requestedFUName' title='Name' errors={errors} />
												<div className='form-group inline-input'>
													<label>Email *</label>
													{/* <input
														className='form-control'
														disabled={selectedUser || Data || reqTp === 5 || reqTp === 6}
														type='text'
														id=''
														{...register('requstedFUEmailID')}
													/> */}
													<FormInput type='text' name="requstedFUEmailID" className='form-control' control={control}
														disabled={selectedUser || Data || reqTp === 5 || reqTp === 6}
													/>

												</div>
												<ErrorComponent name='requstedFUEmailID' title='Email' errors={errors} />
												<div className='form-group inline-input'>
													<label>Contact No. *</label>
													{/* <input
														className='form-control'
														disabled={selectedUser || Data || reqTp === 5 || reqTp === 6}
														type='text'
														id=''
														{...register('contact')}
													/> */}
													<FormInput type='text' name="contact" className='form-control' control={control}
														disabled={selectedUser || Data || reqTp === 5 || reqTp === 6}
													/>
												</div>
												<ErrorComponent name='contact' title='Contact' errors={errors} />
												<div className='form-group inline-input'>
													<label>Company *</label>
													{/* <input
														className='form-control'
														disabled={selectedUser || Data || reqTp === 5 || reqTp === 6}
														type='text'
														id=''
														{...register('company')}
													/> */}
													<FormInput type='text' name="company" className='form-control' control={control}
														disabled={selectedUser || Data || reqTp === 5 || reqTp === 6}
													/>
												</div>
												<ErrorComponent name='company' title='Company' errors={errors} />
												{/* <div className='form-group inline-input opacity-7'>
													<label>Age</label>

													<FormInput type='text' name="age" className='form-control' control={control}
														disabled={true}
													/>

												</div> */}
												<div className='form-group inline-input opacity-7'>
													<label>Gender</label>



													{/* <input className='form-control' type='text' id='' {...register('gender')} disabled /> */}
													<FormInput type='text' name="gender" className='form-control' control={control}
														disabled={true}
													/>

												</div>
												<div className='form-group inline-input opacity-7'>
													<label>Personal Grade</label>



													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('priority')}
														disabled
													/> */}
													<FormInput type='text' name="priority" className='form-control' control={control}
														disabled={true}
													/>
												</div>
												<div className='form-group inline-input opacity-7'>
													<label>Department</label>

													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('department')}
														disabled
													/> */}
													<FormInput type='text' name="department" className='form-control' control={control}
														disabled={true}
													/>
												</div>
												<div className='form-group inline-input opacity-7'>
													<label>Designation</label>

													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('designation')}
														disabled
													/> */}
													<FormInput type='text' name="designation" className='form-control' control={control}
														disabled={true}
													/>
												</div>
											</div>
											<div className='col-6'>
												{reqTp === 3 || reqTp === 4 ? (
													<div className='form-group inline-input' style={{ marginBottom: '10%' }}>
														<label>Existing Plate Number </label>
														<input
															type='text'
															className='form-control'
															id=''
															disabled={Data ? true : reqTp === 5 && watch('isVisitor') ? true : false}
															value={Data?.existingPlatNo}
														/>
													</div>
												) : null}
												<div className='form-group inline-input'>
													<label>Plate Number *</label>

													<Controller
														name='PlateNo'
														control={control}
														render={({ field: { value, onChange, onBlur } }) => (
															<>
																<input
																	type='text'
																	className='form-control'
																	id=''
																	{...register('PlateNo')}
																	value={value}
																	disabled={
																		Data
																			? true
																			: (selectedUser && !selIsVisitor) ||
																				reqTp === 5 ||
																				reqTp === 6
																				? true
																				: false
																	}
																	onChange={(e) => {
																		let v = e.target.value
																			.toUpperCase()
																			.replace(/[^a-zA-Z0-9s]/g, '');
																		onChange(v);
																		isAllocationExist({
																			plate: v,
																			startD: date && date?.length > 0 ? date[0] : '',
																			endD: date && date?.length > 0 ? date[1] : '',
																			startT:
																				rangeTime && rangeTime?.length > 0
																					? rangeTime[0]
																					: '',
																			endT:
																				rangeTime && rangeTime?.length > 0
																					? rangeTime[1]
																					: '',
																			zoneVal : selZone && selZone?.length > 0 ? selZone : [],
																			bayVal : selectedBay
																		});
																	}}
																/>
															</>
														)}
													/>

													{/* <input
												type='text'
												className='form-control'
												id=''
												{...register('PlateNo')}
												disabled={Data ? true : 
													selectedUser ||  reqTp===5 || reqTp===6 ? true : false }
												onChange={(e) => {
													let v = e.target.value;
													isAllocationExist({
														plate: v,
														startD: date && date?.length>0 ? date[0] : '',
														endD: date && date?.length>0 ? date[1] : '',
														startT: rangeTime && rangeTime?.length>0 ? rangeTime[0] : '',
														endT: rangeTime && rangeTime?.length>0 ? rangeTime[1] : '',
													});
												}}
											/> */}
												</div>
												<ErrorComponent name='PlateNo' title='Plate Number' errors={errors} />
												<div className='form-group inline-input'>
													<label>Vehicle Category *</label>
													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('Category')}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/> */}
														<FormInput type='text' name="Category" className='form-control' control={control}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/>
												</div>
												<ErrorComponent name='Category' title='Category' errors={errors} />
												<div className='form-group inline-input'>
													<label>Place of Reg *</label>
													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('PlaceOfRegistration')}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/> */}
													{/* <FormInput type='text' name="PlaceOfRegistration" className='form-control' control={control}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/> */}
													<FormSelect
														control={control}
														name='PlaceOfRegistration'
														options={GENERAL_DDL?.PLACEOFREG_DDL}
														classFx={`form-control ${Data ? 'disableAndOpacity' :
														(selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6 ? 'disableAndOpacity'
														: ''
														}`}
														grey={true}
														isMulti={false}
														placeholder='Select Place of Registration'
														onchangeFx={(v) => {
															setValue('PlaceOfRegistration', v.value);
														}}
													/>
												</div>
												<ErrorComponent
													name='PlaceOfRegistration'
													title='Place Of Registration'
													errors={errors}
												/>
												<div className='form-group inline-input'>
													<label>Color</label>
													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('Color')}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/> */}
													<FormInput type='text' name="Color" className='form-control' control={control}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/>

												</div>
												<div className='form-group inline-input'>
													<label>Brand</label>

													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('Brand')}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/> */}

													<FormInput type='text' name="Brand" className='form-control' control={control}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/>
												</div>
												<div className='form-group inline-input'>
													<label>Description</label>

													{/* <input
														className='form-control'
														type='text'
														id=''
														{...register('Descriptions')}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/> */}

													<FormInput type='text' name="Descriptions" className='form-control' control={control}
														disabled={
															Data
																? true
																: (selectedUser && !selIsVisitor) || reqTp === 5 || reqTp === 6
																	? true
																	: false
														}
													/>

												</div>
												<div className='form-group inline-input'>
													<label>
														Medical Pass Required
													</label>
													<div className='form-group inline-input medical-slider' 
													style={{ width : '85%', justifyContent : 'flex-start' }}>
													<label className="switch" style={{ marginRight : 0, width : '70%' }}>
														<input type="checkbox" checked={watch('IsMedicalPass')}  
														disabled={
															Data || reqTp === 5 || reqTp === 6
														}
														onClick={()=>{ 
															setValue('IsMedicalPass',!watch('IsMedicalPass'));								 
														}}/>
														<span className="slider" style={{ width : '100%' }}></span>
														<span className="enable-text">Medical Pass</span>
													</label>
													</div>
												</div>
												
											</div>
										</div>
									</div>
								{/* ) : ( */}
									<div
										style={{ display: tabVal ? 'none' : 'flex', flexDirection: 'column', marginBottom: '2%' }}
										className='scrollNotVisible'>
										{tabData && tabData?.length > 0 ? (
											tabData &&
											tabData?.map((el: any, k: number) => {
												return (
													<div className='borderbox'>
														<Accordion
															expanded={expanded === k}
															onChange={() => {
																handleChangeExpand(k);
															}}
															sx={{ boxShadow: 'none', border: 'none', background: 'transparent' }}>
															<AccordionSummary
																expandIcon={<i className='fa fa-chevron-down' />}
																aria-controls='panel1bh-content'

															// id='panel1bh-header'
															// sx={{ margin: 0, padding: '15px', minHeight: "20px !important", maxHeight: "20px !important", }}
															>

																<div className='row w-100'>
																	<div
																		className='dflex justify-space-between w-100 align-items-center pb-10'
																		style={{ borderBottom: '1px solid var(--lightgrey)' }}>
																		<div className='col-12'>
																			<h2 className='border-title ml-5'>
																				{`${el?.plateNumber} | `}
																				<i>
																					{`${FormatDate(
																						el?.startDate,
																						'DD-MMM-YYYY'
																					)} -
																	${FormatDate(el?.endDate, 'DD-MMM-YYYY')}`}
																				</i>
																			</h2>
																		</div>
																	</div>
																</div>
															</AccordionSummary>
															<AccordionDetails>
																<div className='row'>
																	<div className='col'>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Allocation No </label>
																			<input
																				className='form-control '
																				type='text'
																				id=''
																				value={el?.parkingID}
																				disabled
																			/>
																		</div>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Allocation Starts </label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				value={`${FormatDate(
																					el?.startDate,
																					'DD-MMM-YYYY'
																				)} ${FormatTime(el?.stratTime, 'hh:mm a')}`}
																				disabled
																			/>
																		</div>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Approved On </label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={`${FormatDate(
																					el?.approvedOn,
																					'DD-MMM-YYYY hh:mm a'
																				)}`}
																			/>
																		</div>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Approved By </label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.approvedBy}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Request Type</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.requestName}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Allocation Expiry</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={`${FormatDate(
																					el?.endDate,
																					'DD-MMM-YYYY'
																				)} ${FormatTime(el?.endTime, 'hh:mm a')}`}
																			/>
																		</div>
																	</div>
																	<div className='col'>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Plate Number </label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				value={el?.plateNumber}
																				disabled
																			/>
																		</div>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Vehicle Category </label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.vehicleCategory}
																			/>
																		</div>
																		<div className='form-group inline-input'>
																			<label className='col-3'>Place Of Reg</label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.placeOfRegistration}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Brand</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.brand}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Color</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.color}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Description</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.description}
																			/>
																		</div>
																	</div>
																	<div className='col'>
																		<div className='parking-lot-image dflex col-dir justify-space-between'>
																			<img
																				src={el?.lastVisitImage ?? noImage}
																				alt=''
																				title=''
																			/>
																		</div>
																	</div>
																</div>
																<div className='row'>
																	<div className='col'>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Site</label>
																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.siteName}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Last Parked On</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={FormatDate(
																					el?.lastParkedOn,
																					'DD-MMM-YYYY hh:mm a'
																				)}
																			/>
																		</div>
																	</div>

																	<div className='col'>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Zone</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.zoneName}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Last Parked Zone</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.lastParkedZoneName}
																			/>
																		</div>
																	</div>
																	<div className='col'>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Bay</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.bayName}
																			/>
																		</div>
																		<div className='form-group inline-input opacity-7'>
																			<label className='col-3'>Last Parked Bay</label>

																			<input
																				className='form-control'
																				type='text'
																				id=''
																				disabled
																				value={el?.lastParkedBayName}
																			/>
																		</div>
																	</div>
																</div>
															</AccordionDetails>
														</Accordion>
													</div>
												);
											})
										) : (
											<div className='dflex align-items-center justify-content-center'>
												<img src={nodatafound} alt='' style={{ width: '40%', objectFit: 'contain' }} />
											</div>
										)}
									</div>
								{/* )} */}
								{/*======================== Action ===================== */}
								{!tabVal ? null : (
									<div className='borderbox'>
										{Data && (Data?.requestStatus!==0) ? null : (
											<div className='row'>
												<div
													className='dflex justify-space-between w-100 align-items-center pb-10'
													style={{ borderBottom: '1px solid var(--lightgrey)' }}>
													<div className='col-3'>
														<h2 className='border-title ml-5'>Action</h2>
													</div>
													{Data && (
														<PermissionWrapper
															subModuleID={subModuleList['Parking Requests']}
															type='isEdit'>
															<div className='col-6'>
																<FormSelect
																	control={control}
																	name='Status'
																	defaultValue={{ value: 1, label: 'Approve Request' }}
																	options={[
																		{ value: 1, label: 'Approve Request' },
																		// { value: 2, label: 'Reject Request' },
																	]}
																	grey={true}
																	isMulti={false}
																	placeholder='Select Action'
																	onchangeFx={(v) => {
																		setValue('Status', v.value);
																		setAction(v?.value);
																		if(v?.value===2){
																			setValue('ApprovedZoneID',0)
																		}
																	}}
																/>
															</div>
														</PermissionWrapper>
													)}
												</div>
											</div>
										)}
										{action === 1 ? (
											<div className='row'>
												{reqTp !== 0 ? (
													<div className='col-12'>
														<AllocationChart
															pRequestID={Data?.pRequestID}
															tabData={setTabData}
															setAllocation={setSelectAllocation}
															hideAllocation={
																((Data ? Data?.requestStatus===0 : ( Data || selectedUser) )) &&
																reqTp === 5 || reqTp === 6 ? false : true}
															selectAllocation={selectAllocation}
															setIsAllocationAvail={setIsAllocationAvail}
															// datePeriod={date&&date.length>0 ? [date[0],date[1]] : null}
															rfUserType={Data?.rfUserType ?? null}
															selectionFn={selectionFn}
															isVisitor={selIsVisitor}
															reqTp={reqTp}
															userId={selUserId}
															setListLoading={setListLoading}
														/>
													</div>
												) : null}
												{
													(reqTp !==1 ) || selIsVisitor ? null : 
													(
														<div className='col-12'>
															<div className='form-group inline-input' style={{ position: 'relative' }}>
																<label>Type of Parking</label>
																
																<FormSelect
																	control={control}
																	name='IsPermenantParking'
																	classFx={`w-100 ${Data ? 'disableAndOpacity' : ''}`}
																	defaultValue={{ value: true, label: 'Permanent Parking' }}
																	options={[
																		{ value: true, label: 'Permanent Parking' },
																		{ value: false, label: 'Temporary Parking' },
																	]}
																	
																	grey={true}
																	isMulti={false}
																	
																	placeholder='Select Type of Parking'
																	onchangeFx={(v) => {
																		setValue('IsPermenantParking',v.value)
																		if(v.value){
																			setValue('endTime', null);
																			setValue('startTime', null);
																		}else{
																			setValue('endTime', dayjs().add(1,'hours').format('HH:mm:ss'));
																			setValue('startTime', dayjs().format('HH:mm:ss'));
																		}
																		setDateFieldsValues(v.value)
																		
																	}}
																/>
															</div>
														</div>
													)
												}
												{reqTp === 3 || reqTp === 4 || reqTp === 7 || reqTp === 8 ? null : (
													<div className='col-12'>
														<div className='form-group inline-input' style={{ position: 'relative' }}>
															<label>{Data ? 'Requested' : 'Select'} Date *</label>
															{	watch('IsPermenantParking')===true && !Data ?
															<DatePicker
																getPopupContainer={(trigger) =>
																	trigger.parentElement as HTMLDivElement
																}
																className='form-control grey-input'
																showTime={false}
																inputReadOnly
																
																format={'DD-MMM-YYYY'}
																onChange={(dateVal: any) => {
																	let strDate = dateVal

																	let oldDate = date
																	setdate([dateVal, oldDate[1]]);
																	if (date && date?.length > 0 && dateVal) {
																		setValue('startDate', dateVal);
																		if(watch('PlateNo') && rangeTime){
																			if(reqTp!==5 && reqTp!==6){
																			setSelZone([])
																			setValue('ApprovedZoneID', null)																			
																			setselectedBay(null);
																			setValue('ApprovedBayID', null);
																		}
																			// isAllocationExist({
																			// 	plate 	: watch('PlateNo'),
																			// 	startD 	: date[0],
																			// 	endD 	: date[1],
																			// 	startT 	: rangeTime[0],
																			// 	endT 	: rangeTime[1]})
																		}
																	}
																}}
																value={date[0]}
																hideDisabledOptions
																disabledDate={(curr) => getDisabledDates(curr)}
																disabled={
																	Data
																		? true
																		: (reqTp === 5 || reqTp === 6) && !selectAllocation
																			? true
																			: 
																			false
																}
																allowClear={true}
															/>
															:
															<RangePicker
																getPopupContainer={(trigger) =>
																	trigger.parentElement as HTMLDivElement
																}
																className='form-control grey-input'
																showTime={false}
																inputReadOnly
																
																format={'DD-MMM-YYYY'}
																onChange={(date: any) => {
																	setdate(date);
																	if (date && date?.length > 0) {
																		setValue('startDate', date[0]);
																		setValue('endDate', date[1]);
																		if(watch('PlateNo') && rangeTime){
																			if(reqTp!==5 && reqTp!==6){
																			setSelZone([])
																			setValue('ApprovedZoneID', null)																			
																			setselectedBay(null);
																			setValue('ApprovedBayID', null);
																		}
																			// isAllocationExist({
																			// 	plate 	: watch('PlateNo'),
																			// 	startD 	: date[0],
																			// 	endD 	: date[1],
																			// 	startT 	: rangeTime[0],
																			// 	endT 	: rangeTime[1]})
																		}
																	}
																}}
																value={date}
																hideDisabledOptions
																disabledDate={(curr) => getDisabledDates(curr)}
																disabled={
																	Data
																		? true
																		: (reqTp === 5 || reqTp === 6) && !selectAllocation
																			? true
																			: 
																			false
																}
																allowClear={true}
															/>
														}
														</div>
														<ErrorComponent name='startDate' title='Start Date' errors={errors} />
														<ErrorComponent name='endDate' title='End Date' errors={errors} />
														
														<ErrorComponent
															name='isAlreadyAllocated'
															title='Is Already Allocated'
															errors={errors}
														/>
														{/* <ErrorComponent
															name='isWarningAlreadyAllocated'
															title='Is Already Allocated Warning'
															errors={errors}
														/> */}

														{/* {
											isAllocationAvail && isAllocationAvail?.length>0 && (reqTp===5 || reqTp===6) &&
											!(tabData && tabData?.length===0) &&
											<p className='hookform-info-message text-yellow' style={{ padding:'3px 6px' }}>Note : Overtime Allocations will be approved according to previously approved allocations.</p>
										} */}
														{(Data ? Data?.requestStatus===0 : ( Data || selectedUser) ) &&
															tabData &&
															tabData?.length === 0 &&
															!listLoading && 
															(reqTp === 5 || reqTp === 6) && (
																<p className='hookform-error-message' style={{ padding: '3px 6px' }}>
																	Overtime Allocations can ONLY BE APPROVED if there are any
																	previously approved allocations exists.
																</p>
															)}
													</div>
												)}
												{(reqTp === 1 || reqTp === 2) &&
												!watch('IsPermenantParking') &&
													((Data &&
														dayjs(Data?.startTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid() &&
														dayjs(Data?.endTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid()) ||
														!Data) ? (
													<div className='col-12'>
														<div className='form-group inline-input' style={{ position: 'relative' }}>
															<label>{Data ? 'Requested' : 'Select'} Time *</label>
															<RangePicker
																getPopupContainer={(trigger) =>
																	trigger.parentElement as HTMLDivElement
																}
																inputReadOnly
																picker='time'
																className='form-control grey-input'
																format={'hh:mm A'}
																onChange={(time: any) => {
																	setRangeTime(time);
																	console.log('OUTtime', time);
																	if (time && time?.length > 0) {
																		setValue('startTime', dayjs(time[0]).format('HH:mm:ss'));
																		setValue('endTime', dayjs(time[1]).format('HH:mm:ss'));
																		if(watch('PlateNo') && date){
																			if(reqTp!==5 && reqTp!==6){
																				setSelZone([])
																				setValue('ApprovedZoneID', null)																			
																				setselectedBay(null);
																				setValue('ApprovedBayID', null);
																			}
																			// isAllocationExist({
																			// 	plate : watch('PlateNo'),
																			// 	startD : date[0],
																			// 	endD : date[1],
																			// 	startT : time[0],
																			// 	endT : time[1]})
																		}
																	}
																	//console.log('SELECTEDVAL',date && date?.length > 0 && dayjs().diff(date[0],'hour')===0 &&
																	// dayjs(time[0]).get('hours') <= dayjs().get('hours') &&
																	// dayjs(time[0]).get('minutes')<= dayjs().get('minutes'))

																	//console.log('TIME',time[0])
																	setValue(
																		'isTimeValid',
																		date &&
																		date?.length > 0 &&
																		dayjs().diff(date[0], 'hour') === 0 &&
																		dayjs(time[0]).get('hours') <= dayjs().get('hours') &&
																		dayjs(time[0]).get('minutes') <= dayjs().get('minutes')
																	);
																	trigger('isTimeValid');
																}}
																value={rangeTime}
																allowClear={true}
																// hideDisabledOptions
																disabledTime={(now: EventValue<moment.Moment>, type: RangeType) =>
																	getDisabledTime(now, type)
																}
																disabled={Data ? true : date && date?.length === 0 ? true : false}
															/>
														</div>
														<ErrorComponent name='startTime' title='Start Time' errors={errors} />
														<ErrorComponent name='endTime' title='End Time' errors={errors} />
														<ErrorComponent name='isTimeValid' title='Time' errors={errors} />
														{
															watch('isWarningAlreadyAllocated') 
															&&
															<p className='hookform-info-message text-yellow' style={{ padding:'3px 6px', fontWeight : 600 }}>
																Vehicle is already allocated at these date-time intervals in this selected Zone*
															</p>
														}
														
													</div>
												) : (
													false
												)}
												{reqTp === 1 || reqTp === 2 ? (
													<div className={`col-12`}>
														{Data && Data?.siteName && (
															<div className='form-group inline-input'>
																<label>Site</label>

																<input
																	placeholder='Select Site'
																	disabled={true}
																	className='form-control grey-input'
																	value={Data?.siteName}></input>
															</div>
														)}

														<div className='form-group inline-input'>
															<label>Zone *</label>
															<TreeSelectCustom
																grey
																showAll={setLevelSite}
																selectedZones={selZone}
																setselectedZones={(e) =>{
																	setSelZone(e)
																	isAllocationExist({
																		plate: watch('PlateNo'),
																		startD: date && date?.length > 0 ? date[0] : '',
																		endD: date && date?.length > 0 ? date[1] : '',
																		startT: rangeTime && rangeTime?.length > 0 ? rangeTime[0] : '',
																		endT: rangeTime && rangeTime?.length > 0 ? rangeTime[1] : '',
																		zoneVal : e
																	});
																}}
																classnames={`w-100  ${Data &&
																	// !(createPerm || editPerm) &&
																	(Data?.requestStatus!==0)
																	? 'disableAndNoPointer'
																	: ''
																	}`}
																styleSx={{ maxWidth: 'unset' }}
																onchangeFx={(e: any) => {
																	setGraphLoading(true);
																	setValue('ApprovedZoneID', e?.value);
																	trigger('ApprovedZoneID')
																	
																}}
																fixSite={Data?.fK_SiteID}
																isMulti={false}
															/>
														</div>

														{selZone &&
															selZone?.length !== 0 &&
															date &&
															date?.length !== 0 &&
															!(Data && (Data?.requestStatus!==0)) ? (
															<PermissionWrapper
																subModuleID={subModuleList['Parking Requests']}
																type='isCreateOrEdit'>
																<div
																	className='dflex align-items-center gap-5 w-100 p-10'
																	style={{ justifyContent: 'flex-end' }}>
																	<CheckAvilability
																		selectedZone={selZone[0]}
																		date={Data ? [moment(Data?.startDate),moment(Data?.endDate)] : 
																			date}
																		time={Data ? [Data?.startTime, Data?.endTime] : 
																			rangeTime}
																	/>
																	<ReserveABay
																		selectedZone={selZone[0]}
																		date={date}
																		time={ watch('IsPermenantParking') ? null : rangeTime}
																		selectedBay={selectedBay}
																		siteID={Data?.siteID ?? watch('fK_SiteID')}
																		setselectedBay={(v: any) => {
																			setselectedBay(v);
																			setValue('ApprovedBayID', v?.value);
																			isAllocationExist({
																				plate: watch('PlateNo'),
																				startD: date && date?.length > 0 ? date[0] : '',
																				endD: date && date?.length > 0 ? date[1] : '',
																				startT: rangeTime && rangeTime?.length > 0 ? rangeTime[0] : '',
																				endT: rangeTime && rangeTime?.length > 0 ? rangeTime[1] : '',
																				zoneVal : selZone,
																				bayVal : v
																			});
																		}}
																	/>
																</div>
															</PermissionWrapper>
														) : null}
														<ErrorComponent errors={errors} name={'ApprovedZoneID'} title='Zone' />
													</div>
												) : null}
												{reqTp === 1 || reqTp === 2
													? selectedBay && (
														<div className='col-12'>
															<div className='form-group inline-input'>
																<label>Bay (optional)</label>

																<input
																	placeholder='Select Bay'
																	disabled={
																		Data &&
																		(Data?.requestStatus === 1 ||
																			Data?.requestStatus === 2)
																	}
																	className='form-control grey-input'
																	value={
																		Data && Data?.fK_ApprovedBayID
																			? GENERAL_DDL?.BAY_DDL?.find(
																				(el: any) =>
																					el.value === Data?.fK_ApprovedBayID
																			)?.label
																			: GENERAL_DDL?.BAY_DDL?.find(
																				(el: any) =>
																					el.value === selectedBay?.value
																			)?.label
																	}></input>
															</div>

															<ErrorComponent errors={errors} name={'ApprovedBayID'} title='Bay' />
														</div>
													)
													: null}
												{/* {
											(reqTp!==0) &&
											(Data || selectedUser) ?
											<div className='col-12 algin-start dflex'>
											<AllocationChart pRequestID={Data?.pRequestID} 
												tabData={setTabData}
												setIsAllocationAvail={setIsAllocationAvail}
												datePeriod={date&&date.length>0 ? [date[0],date[1]] : null} rfUserType={Data?.rfUserType??null}
												isVisitor={selIsVisitor} reqTp={reqTp} userId={selUserId} /></div>
											:	
												null
										}
									 */}
												{Data &&
													(Data?.requestStatus!==0) &&
													Data.requesterComments !== '' && (
														<div className='col-12'>
															<div className='form-group inline-input'>
																<label>Requester Remarks </label>

																<textarea
																	placeholder='Remarks'
																	disabled={Data && Data.requesterComments !== ''}
																	className='form-control grey-input'
																	value={Data.requesterComments}></textarea>
															</div>
														</div>
													)}
												<div className='col-12'>
													<div className={'form-group inline-input'}>
														<label>Approver Remarks *</label>

														{/* <textarea
															placeholder='Remarks'
															disabled={
																Data &&
																!(createPerm || editPerm) &&
																(Data?.requestStatus!==0)
															}
															className='form-control grey-input'
															{...register('ApproverComments')}>

															</textarea> */}
														<FormTextarea name="ApproverComments" placeholder='Remarks'
															disableValidation={true}
														className='form-control grey-input' control={control}

															disabled={
																Data &&
																// !(createPerm || editPerm) &&
																(Data?.requestStatus!==0)
															}
														/>
													</div>
													<ErrorComponent errors={errors} name={'ApproverComments'} title='Remarks' />
													{reqTp === 3 || reqTp === 4 ? (
														<ErrorComponent
															name='isVehicleHasPendingRequests'
															title='Is Vehicle Allocated'
															errors={errors}
														/>
													) : null}
												</div>
											</div>
										) : (
											<div className='row'>
												<div className='col-12'>
													<div className='form-group inline-input'>
														<label>Reject Reason *</label>
														<FormSelect
															control={control}
															classFx={`w-100 ${Data &&
																// !(createPerm || editPerm) &&
																(Data?.requestStatus!==0)
																? 'disableAndOpacity'
																: ''
																}`}
															name='fK_RejectReasonID'
															options={GENERAL_DDL?.REJECTIONREASON_DDL}
															grey={true}
															isMulti={false}
															placeholder='Select Reason'
															onchangeFx={(v) => {
																setValue('fK_RejectReasonID', v.value);
															}}
														/>
													</div>

													<ErrorComponent errors={errors} name={'ApprovedBayID'} title='Bay' />
												</div>

												<div className='col-12'>
													<div className='form-group inline-input'>
														<label>Rejection Remarks *</label>

														{/* <textarea
															placeholder='Remarks'
															disabled={
																Data &&
																!(createPerm || editPerm) &&
																(Data?.requestStatus!==0)
															}
															className='form-control grey-input'
															{...register('ApproverComments')}></textarea> */}

														<FormTextarea name="ApproverComments" placeholder='Remarks' 
															disableValidation={true}
														className='form-control grey-input' control={control}

															disabled={
																Data &&
																// !(createPerm || editPerm) &&
																(Data?.requestStatus!==0)
															}
														/>
													</div>
													<ErrorComponent errors={errors} name={'ApproverComments'} title='Remarks' />
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='modal-footer'>
							<button
								type='reset'
								onClick={() => {
									handleClose();
								}}
								className='btn btn-outline-blue close-modal-button'>
								{Data && (Data?.requestStatus!==0) ? 'Close' : 'Cancel'}
							</button>
							<PermissionWrapper subModuleID={subModuleList['Parking Requests']} type='isCreateOrEdit'>
								{Data && (Data?.requestStatus!==0) ? null : action === 1 ? (
									<button
										type='submit'
										className='btn btn-secondary ml-5'
									//  disabled={(tabData && tabData?.length===0 && (reqTp===5 || reqTp===6)) || loading}
									>
										<ButtonLoader loading={loading} text={'Submit'} />
									</button>
								) : (
									<button type='submit' className='btn btn-danger ml-5' disabled={loading}>
										<ButtonLoader loading={loading} text={'Reject'} />
									</button>
								)}
							</PermissionWrapper>
						</div>
					</div>
				</div>
			</form>
		</ModalLayout>
		{
			openErr &&
			<FailedMessagePop data={openErrData} handleClose={()=>setOpenErr(false)} ConfirmFx={FailMsgConfirm} />
		}
		{confirmModal &&
			
			<ActionConfirm
				description={`Do you want to ${action===1 ? `approve this request` : `reject this request`} ?`}
				handleClose={cancelConfirm}
				open={true}
				ConfirmFx={confirmApprove}
			/>
		}
		</>
	);
};

export default ParkingRequestAddModal;
