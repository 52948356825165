import React, { Fragment, useEffect, useRef, useState } from 'react';
import ProgressBar from '../../../../common/components/graph_components/ProgressBar';
import { useAppSelector } from '../../../../config/hooks';
// import DashboardFilterWrapper from '../DashboardFilterWrapper';
// import Select from 'react-select';
import { fetch_no_of_operators } from './../../store/action';
import { 
	//COLORS, 
	COLOR_FX } from '../../../../utils/Data';
import { ZoneColors } from '../../../../utils/zoneColorCodes';

type Props = {
	name: string;
};

const TotalOperatorsByZone = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);

	const [total, settotal] = useState(0);

	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [loading, setloading] = useState(false);

	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			FloorID: floor?.levelID,
		};

		if (floor?.levelID) {
			setloading(true);

			fetch_no_of_operators(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					setdata(data);
					settotal(res?.response?.totalCount);
				}
				setTimeout(() => {
					setloading(false)
				}, 1500);
			});
		}

		return () => {
			controller.abort();
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value]);

	// const childRef: any = useRef(null);

	// const handleClose = () => {
	// 	if (childRef.current) {
	// 		childRef.current.childFunction1();
	// 	}
	// };

	return (
		<div className='ItemLayout__Wrapper'>
			<div className='ItemLayout__Title'>
				<div> Total Operators
					 {/* in {floor?.levelName} */}
					 </div>
				<div>
					{/* <DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
						<div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Floor</label>
							<Select
								className='common-select-grey'
								options={DASHBOARD_DATA?.FLOOR_LIST}
								getOptionLabel={(o: any) => o.levelName}
								getOptionValue={(o: any) => o.levelID}
								onChange={(e: any) => setfloor(e)}
								value={DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''}
								placeholder='Floor'
							/>
						</div>
					</DashboardFilterWrapper> */}
				</div>
			</div>
			<div className='ItemLayout__Content'>
				<div className='bay-stats progressbar-box widget-levelMap-max-h dflex col-dir gap-10'>
					{data.length === 0 ? (
						<div className='no_data_found_df'>
							<span>No Data Found</span>
						</div>
					) : (
						<>
							{/* <div
											className='w-100 dflex justify-space-between gap-10'
											style={{ borderRadius:"5px"  }}
						>
							<div className='text-primary p-10'>
							Total Operators in {floor?.levelName} :
							<span className='text-primary p-10' style={{ fontWeight:600 }}>
							{total}
							</span>
							</div>
						
						</div> */}
							{data.map((item: any, key) => {
								let perc = ((item?.zCount / total) * 100).toFixed(2);
								return (
									<Fragment key={key}>
										<ProgressBar
											value={Number(perc)}
											label={item?.zoneName}
											endLabel={`${item?.zCount}`}
											tootTipText={`operators : ${item?.zCount} - total : ${total}`}
											color={ZoneColors(item?.zoneName)??COLOR_FX(key)}

										/>
										{/* <div
											className='w-100 dflex justify-space-between gap-10'
											style={{   borderRadius:"5px" , }}>
											<div className='text-primary p-10'>{item?.zoneName}</div>
											<div className='text-primary p-10' style={{ fontWeight:600 }}>{item?.zCount}</div>
										</div> */}
									</Fragment>
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TotalOperatorsByZone;
