import React from 'react';
import './commonStyle.css';

const PageNotFound = ({ error = true, msg, notFound }: any) => {
	// const { Profile_error } = useAppSelector((state) => state?.permissions);
	return (
		<div className='page-notfound-conatiner'>
			{notFound ? (
				<div>
					
				</div>
			) : error ? (
				<>
					<p>{error || 'Something went wrong.'}</p>
					<div onClick={() => window.location.reload()}></div>
					<span>{msg && msg}</span>
				</>
			) : (
				<>
					<div className='flexbox'>
						<div>
							<div className='hm-spinner'></div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default PageNotFound;
