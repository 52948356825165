import React, { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FormatDate, FormatTime } from '../../../../../utils/utilFunctions';
import { fetch_user_report } from '../../../store/action';

type Props = {}

const ParkingAllocatedReports = (props: Props) => {
    const fetchIdRef = useRef(0);
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
    
    const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);

    const columns = React.useMemo(
		() => [
			
			{ Header: 'Allocation Number', accessor: 'parkingID' },
			{ Header: 'Allocated Period', accessor: 'startDate',
			Cell: ({ row }: any) => {
				return (
					<p>
						{`${FormatDate(row?.original?.startDate,'DD-MM-YYYY')} 
						- ${FormatDate(row?.original?.endDate,'DD-MM-YYYY')}`}<br/>
						{
							row?.original?.stratTime ? `(${FormatTime(row?.original?.stratTime,'hh:mm a')} 
							- ${FormatTime(row?.original?.endTime,'hh:mm a')})` : ''
						}
					</p>
				);
			}
			},

			{ Header: 'Name', accessor: 'fullName' },
			{ Header: 'Email', accessor: 'emailID'},

			{ Header: 'Plate Number', accessor: 'plateNumber' },
			{ Header: 'Place of Registration', accessor: 'placeOfRegistration' },
			{ Header: 'Vehicle Category', accessor: 'vehicleCategory' },
			
			{ Header: 'Zone Name', accessor: 'zoneName'},
            { Header: 'Bay Name', accessor: 'bayName'},
			
			{ Header: 'Vehicle Color', accessor: 'colour' },
			{ Header: 'Vehicle Brand', accessor: 'brand' },

			
			{ Header: 'Contact', accessor: 'contactNo'},
			{ Header: 'Designation', accessor: 'desName'},
			{ Header: 'Department', accessor: 'depName'},
			
			
			{ Header: 'Personal Grade', accessor: 'fK_PriorityLevel' },
			{ Header: 'User Type', accessor: 'isVisitor', 
				Cell: ({ row }:any) => {
					return(
						<p>{row?.original?.isVisitor ? 'Visitor' : 'Employee'}</p>
					)
				}},	
			
            { Header: 'Approved On', accessor: 'approvedOn', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
            { Header: 'Approved By', accessor: 'approverName'},
            { Header: 'Site Name', accessor: 'siteName'},
            
            { Header: 'Last Parked On', accessor: 'lastParkedOn', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'Last Parked Bay', accessor: 'lastParkedBayName'},
			{ Header: 'Last Parked Zone', accessor: 'lastParkedZoneName'},
			{ Header: 'Status', accessor: 'currentStatus_str'},	
			
			{ Header: 'Permanent Parking', accessor: 'isPermenantParking', 
				Cell: ({ row }:any) => {
					return(
						<p>{row?.original?.isPermenantParking ? 'Yes' : 'No'}</p>
					)
				}},	
			{ Header: 'Medical Pass Required', accessor: 'isMedicalPass', 
				Cell: ({ row }:any) => {
					return(
						<p>{row?.original?.isMedicalPass ? 'Yes' : 'No'}</p>
					)
				}},	
			{ Header: 'Request Type', accessor: 'requestTypeName'},	
				
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,
				show: false,
			}
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		filters: { query: string };
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_user_report(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					1,
					signal
				);
			}
		},
		[dispatch, navigate]
	);
	

  return (
    <CommonTable
		data={VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA??[]}
		columns={columns}
		pageCount={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount ?? 0}
		fetchData={fetchData}
		loading={VISITS_REPORTS_DATA?.USER_REPORTS_LOADER}
		error={VISITS_REPORTS_DATA?.USER_REPORTS_ERROR}
		limit={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit ?? 1}
		page_no={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.pageNo}
		filters={VISITS_REPORTS_DATA?.USER_REPORTS_FILTER}
	/>
  )
}

export default ParkingAllocatedReports