import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { fetch_Users_AllocatedParkingCount } from '../../store/action'

type Props = {}

const DashIcons = (props: Props) => {
	const dispatch  = useAppDispatch();
	const USERS_ALLOCATIONS = useAppSelector((state) => state?.findacar)
	useEffect(() => {
		let postData = {'filters': USERS_ALLOCATIONS.USER_ALLOCATED_PARKING_FILTER }
	  fetch_Users_AllocatedParkingCount(dispatch, postData)
	}, [USERS_ALLOCATIONS.USER_ALLOCATED_PARKING_FILTER, dispatch])
	
	

  return (
    <div className="w-100 mb-20">
			<div className="row mts-20">
			<div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Total Allocated  <span>{USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.totalCount}</span></h3>
				</div>
			</div>
			<div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap green">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Permanent Allocations <span className="text-green">{USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.permenantParking}</span></h3>
				</div>
			</div>
			<div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap yellow">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Temporary Allocations <span className="text-yellow">{USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.temporaryParking}</span></h3>
				</div>
			</div>
			{/* <div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Active Allocations  <span> */}
						{/* {USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.active} */}
						{/* 0</span></h3>
				</div>
			</div> */}
			<div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap red">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Revoked Allocations <span className="text-red">{USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.revoked}</span></h3>
				</div>
			</div>
			<div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap orange">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Cancelled Allocations <span className="text-orange">{USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.cancelled}</span></h3>
				</div>
			</div>
			{/* <div className="col">
				<div className="icon-box white-bg">
					<div className="icon-wrap">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Expired Allocations <span>
						{/* {USERS_ALLOCATIONS?.USER_ALLOCATED_PARKINGS_COUNT?.expired} */}
				{/*		0</span></h3>
				</div>
			</div> */}
		</div>
		</div>
  )
}

export default DashIcons