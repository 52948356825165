import { utils, writeFileXLSX } from "xlsx";

export const exportDataToExcel = (data: any, fileName: string, title: string, columns: any[]) => {
    // Initialize colsLength to the length of provided columns
    let colsLength = columns?.length??6;

    // Transform the data according to the provided columns
    console.log('EXPORTCSVDATA=>',data,columns)
    let reportData = data.map((el: any) => {
        let obj: any = {};
        columns.forEach((col) => {
            const key = col.id;
            console.log('EXPORTCSVDATAKEYS=>',typeof el[key],key,key?.includes('_fn'),col)
            if(typeof el[key] !== 'object' && typeof el[key] !== 'function' && col.fn===undefined){
                const newKey = col.displayName;
                obj[newKey] = el[key];
            }else if(col.fn){
                let newFnVal = col?.fn?.fn
                let processedKey = col?.fn?.k

                let processedVal = newFnVal(el[key])
                obj[processedKey] = processedVal

                console.log('PROCESSEDCols=>',newFnVal,processedKey,processedVal,obj)
            }
        });
        return obj;
    });

    // Create a new workbook and worksheet
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(reportData);

    // console.log('NEWRANGE=>',newRange)
    console.log('WSREF',ws['!ref'])
    
    // Shift all cell references by one row down
    let oldWs = ws
    
    if(ws['!ref']){
        let range = utils.decode_range(ws['!ref']);

        // Start shifting rows from the bottom to the top
        for (let row = range.e.r; row >= range.s.r; row--) {
            for (let col = range.e.c; col >= range.s.c; col--) {
                let oldCell = utils.encode_cell({ c: col, r: row });
                let newCell = utils.encode_cell({ c: col, r: row + 1 });
                ws[newCell] = oldWs[oldCell];
            }
        }

        // Adjust the range to include the newly added row at the top
        range.e.r++;
        ws['!ref'] = utils.encode_range(range)
    }
   
    // Add the filename in cell A1
    ws['A1'] = { t: 's', v: `${title}`, s: { font: { bold: true } } };

    // Merge the cells for the header row
    const range = { s: { c: 0, r: 0 }, e: { c: colsLength-1, r: 0 } };
    if (!ws['!merges']){
        ws['!merges'] = [];
    }
    ws['!merges'].push(range);

    // Append the worksheet to the workbook
    utils.book_append_sheet(wb, ws, "ReportData");

    // Write the workbook to a file
    return writeFileXLSX(wb, `${fileName}.xlsx`);
}
