import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import CommonTable from '../../../common/components/Table/CommonTable';
// import { fetch_siteDDL } from '../../../common/general/action';
import ModalLayout from '../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { fetch_parkingRequestslist, fetch_parkingRequestslist_bulk, parkingRequestStatus_action } from '../store/action';
import { commonSearchQuery, FilterProps } from '../../../config/filterSearch';
import TreeSelectCustom from '../../../common/components/tree_select/NewTreeSelectCustom';
import ErrorComponent from '../../../common/components/ErrorComponent';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FailedToast, SuccesToast } from '../../../utils/utilFunctions';
import { fetch_RejectionReasonDDL } from '../../../common/general/action';
import ButtonLoader from '../../../common/components/ButtonLoader';

type Props = {
	handleClose: () => void;
}

type ddl = {
    label: string;
    value: any;
};


const approve_schema = yup.object().shape({
	reqestType : yup.number(),
    ApprovedComments : yup.string().required(),
    ApprovedZoneID : yup.number().when('reqestType', 
		{ 
			is : (reqestType:any) => reqestType===1 || reqestType===2,
			then : yup.number().required()
		}),
	SiteID : yup.number().when('reqestType', 
	{ 
		is : (reqestType:any) => reqestType===1 || reqestType===2,
		then : yup.number().required('Issue in Data')
	})
})

const reject_schema = yup.object().shape({
    ApprovedComments : yup.string().required(),
    FK_RejectResonID : yup.number().required(),
    
})

const BulkApproveModal = (props: Props) => {
    const dispatch = useAppDispatch();
	const navigate = useNavigate();
    const [action, setAction] = useState<any>(1)
	const [selZone, setSelZone] = useState<ddl[]>([])
    const [selectedRows, setSelectedRows] = useState<any[]>([])
	const GENERAL_DDL = useAppSelector((state) => state?.general);
    const PARKINGDATA = useAppSelector((state) => state?.parkingrequest);
	
	const [loading, setLoading] = useState(false)

    useEffect(() => {
        reset()
        clearErrors()
		
		setSite(GENERAL_DDL?.SITE_DDL? GENERAL_DDL?.SITE_DDL[0]?.value : null)
		fetch_RejectionReasonDDL(dispatch)
		fetch_parkingRequestslist_bulk(navigate,
			dispatch, {
			'filters': {
				'query': `RequestStatus=0 AND FK_RequestType=1 AND tsp.Fk_SiteID=${GENERAL_DDL?.SITE_DDL? GENERAL_DDL?.SITE_DDL[0]?.value : null}`,
			},
			'pagination': {
				'pageNo': PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
				'limit': PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.limit ?? 1,
			},
		});
		
		// return () => controller.abort();
	}, [GENERAL_DDL?.SITE_DDL])

    const { register, handleSubmit,  formState: { errors }, reset, control, setValue, watch, clearErrors } = useForm({
		mode: 'onBlur',
        resolver: yupResolver(action===2 ? reject_schema : approve_schema),
        reValidateMode: 'onChange',
        shouldFocusError: true,
	})

	const fetchIdRef = useRef(0);
	const [filterActive, setFilterActive] = useState(true)

    const [site, setSite] = useState<any>('')
    const [reqType, setReqType] = useState<any>(1)
    const allSameRType = () =>{		
		let rTyprArr = selectedRows && selectedRows?.map((el:any) => el.fK_RequestType)
		let allRType = rTyprArr.every( (v:any) => v === rTyprArr[0] ) ? rTyprArr[0] : null
		console.log('ALLSAMERTYPE',rTyprArr,allRType,parseInt(allRType))
		return parseInt(allRType)
		
	}
    let ReqTypeArr = [
		{ value : 1, label : 'Employee Parking Request'}, { value : 2, label : 'Visitor Parking Request'}, { value : 3, label : 'Employee Vehicle Update Request'}, 
		{ value : 4, label : 'Visitor Vehicle Update Request'}, { value : 5, label : 'Employee Overtime Parking Request'}, { value : 6, label : 'Visitor Overtime Parking Request'}, 
		{ value : 7, label : 'Employee Parking Request Cancellation'}, { value : 8, label : 'Visitor Parking Request Cancellation'}]
    let actionArr = [
        { value : 1, label : 'Approve Requests'}
    ]

    const onSubmitHandler = (data: any) => {
        console.log('data', data);
		setLoading(true)
        let arrVal = []
        for(let i=0; i < selectedRows?.length; ++i){
            if(action===2){
            arrVal.push(
                {
                    ApprovedComments : data.ApprovedComments,
                    FK_RejectResonID : data.FK_RejectResonID,
                    RequestID        : selectedRows[i]?.pRequestID,
                    Status           : 2
                }
            )}
            else{
                    if(allSameRType()===1 || allSameRType()===2 || reqType===1 || reqType===2 ){
                        arrVal.push(
                            {
                                ApprovedComments : data.ApprovedComments,
                                ApprovedZoneID	 : data?.ApprovedZoneID,
                                SiteID			 : data?.SiteID,
                                ApprovedBayID    : data?.ApprovedBayID,
                                Status 			 : 1,
                                RequestID        : selectedRows[i]?.pRequestID,
                            }
                        )
                    }
                    else{
                        arrVal.push(
                            {
                                ApprovedComments : data.ApprovedComments,
                                RequestID        : selectedRows[i]?.pRequestID,
                                Status           : 1
                            }
                        )
                    }
                   
            }
        }
		let postData = {
            UpdateParkingRequest : [...arrVal]
        }
		const Success = (res: any) => {
			setLoading(false)
            SuccesToast(res?.response?.data);
            fetch_parkingRequestslist_bulk(navigate,
                dispatch, {
				'filters': {
					'query': PARKINGDATA?.BULK_PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
			fetch_parkingRequestslist(navigate,
                dispatch, {
				'filters': {
					'query': PARKINGDATA?.PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGDATA?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGDATA?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
            
            reset();
            props.handleClose();
        };
        const Failed = (err: any) => {
			setLoading(false)
            FailedToast(err?.response)		;
			fetch_parkingRequestslist_bulk(navigate,
                dispatch, {
				'filters': {
					'query': PARKINGDATA?.BULK_PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
			fetch_parkingRequestslist(navigate,
                dispatch, {
				'filters': {
					'query': PARKINGDATA?.PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGDATA?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGDATA?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
            
            props.handleClose();
            
        };

        parkingRequestStatus_action(postData, Success, Failed);
        props.handleClose();
    };

    const clearFilter = () =>{
		setFilterActive(false)
		fetch_parkingRequestslist_bulk('', dispatch, {
			'filters': {
				'query': `RequestStatus=0`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKINGDATA.BULK_PARKING_REQUEST_PAGINATION.limit,
			},
		})
		setReqType('')
		setSite('')
	}

    const filterSearch = () =>{
        setFilterActive(true)
		let  QueryInput : FilterProps[] = [
			{
				field :'FK_RequestType', 
            	value : reqType, 
            	operator: '='
			},
			{
				field :'tsp.Fk_SiteID', 
            	value : site, 
            	operator: '='
			},
            {
				field :'RequestStatus', 
            	value : '0', 
            	operator: '='
			}
		]

        fetch_parkingRequestslist_bulk('', dispatch, {
			'filters': {
				'query': commonSearchQuery(QueryInput,'AND'),
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKINGDATA.BULK_PARKING_REQUEST_PAGINATION.limit,
			},
		}
        );
    }
    // const makeDisableReqTyp = (v:number) =>{
    //     if(site){
    //         return v!==1 && v!==2
    //     }else{
    //         return false
    //     }
    // }
    
	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy:string;
		filters: {query: string;}

	};

	const reqTypeArr:any = ['Employee Parking Request', 'Visitor Parking Request', 'Employee Vehicle Update Request', 'Visitor Vehicle Update Request',
	 'Employee Overtime Parking Request', 'Visitor Overtime Parking Request', 'Employee Parking Request Cancellation', 'Visitor Parking Request Cancellation']
    const fetchData = useCallback(
		({ pageSize, pageIndex, signal ,OrderBy ,filters}: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current && filterActive) {
				fetch_parkingRequestslist_bulk(
					navigate,
					dispatch,
					{
						'filters': {
							'query':filters.query,
							OrderBy
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
                if(PARKINGDATA?.BULK_PARKING_REQUEST_LIST_DATA){
                	setSelectedRows([...PARKINGDATA?.BULK_PARKING_REQUEST_LIST_DATA])
                }
			}
		},
		[dispatch, navigate]
	);	

    
	const columns = React.useMemo(
		() => [
			{ Header: 'Id', accessor: 'pRequestID' ,orderByKey:"pRequestID"},
			{ Header: 'Requested Date', accessor: 'requestedOn' ,orderByKey:"requestedOn", DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'Requested By', accessor: 'requestedBUName' ,orderByKey:"requestedBUName"},
			{ Header: 'Requested For', accessor: 'requestedFUName' ,orderByKey:"requestedFUName"},

			{ Header: 'Site', accessor: 'siteName' ,orderByKey:"siteName", hideHeader: site ? false : true, hideBody: site ? false : true},
			{ Header: 'Type of Request', accessor: 'fK_RequestType' ,orderByKey:"fK_RequestType", hideHeader: reqType ? false : true, hideBody: reqType ? false : true, 
				Cell: ({ row }:any) => {
					return <p>{reqTypeArr[parseInt(row?.values?.fK_RequestType)-1]}</p>
				}
			},

			{ Header: 'Personal Grade', accessor: 'rF_PriorityLevelID' ,orderByKey:"rF_PriorityLevelID"},
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					//console.log(row)
					return (
						<Fragment>
							
						</Fragment>
					);
				},
			},
		],
		[]
	);

console.log('ASDF',allSameRType(),allSameRType()===1,allSameRType()===2,site,(allSameRType()===1 || allSameRType()===2) && site ? true : false)
console.log('ERROR',errors)
console.log('SELECTEDROWS',selectedRows)
  return (
    <ModalLayout big open={true} handleClose={props.handleClose} title="Bulk Operation" subtitle='Approve or reject requests with same Site or Request Type'>
		<div className='modal-body-content' style={{ height:'60vh' }}>
		<div className='modal-body '>
		<div className='row'>
			<div className='col'>
				<div className='form-group'>
					<ReactSelect
						options={ReqTypeArr}
						className={'common-select common-select-grey'}
						placeholder={'Requests'}
						isMulti={false}
						isClearable
                        // isOptionDisabled={(option) => makeDisableReqTyp(option.value)}
                        value={ReqTypeArr?.find((c) => c.value === reqType)}
                        onChange={(val) =>{ 
							setReqType(val?.value); 
							setValue('reqestType',val?.value)
                            if(!site && (val?.value===1 || val?.value===2)){
                                setSite(GENERAL_DDL?.SITE_DDL && GENERAL_DDL?.SITE_DDL[0]?.value)
								setValue('SiteID',GENERAL_DDL?.SITE_DDL && GENERAL_DDL?.SITE_DDL[0]?.value)
                            }else if((val?.value!==1 && val?.value!==2)){
								setSite(null)
							}
                        }}
					/>
				</div>
			</div>
            <div className='col'>
				<div className='form-group'>
					<ReactSelect
						options={GENERAL_DDL?.SITE_DDL}
						className={'common-select common-select-grey'}
						placeholder={'Requests in Site'}
						isMulti={false}
                        isDisabled={reqType===3 || reqType===4 || reqType===5 || reqType===6 || reqType===7 || reqType===8}
						isClearable
                        value={site ? GENERAL_DDL?.SITE_DDL?.find((c) => c.value === site) : null}
                        onChange={(val) =>{ 
							setSite(val?.value);
							setValue('SiteID',val?.value)
						}}
					/>
				</div>
			</div>
				
            <div className='col-2'>
				<div className='form-group'>
					<button onClick={()=>{ 
						if(reqType || site)
							filterSearch()
						else{
							console.log('REQ',reqType,'SIT',site)
						}
						}} className='form-control btn blue-btn'>Search</button>
				</div>
			</div>
            {
                filterActive ?
                <div className='col-2'>
				<div className='form-group'>
					<button onClick={()=>{ clearFilter() }} className='form-control btn-outline-blue btn'>Clear</button>
				</div>
			</div>
            :   null
            }		
        </div>
        {
            filterActive ?
        <CommonTable
            columns={columns}
            data={PARKINGDATA?.BULK_PARKING_REQUEST_LIST_DATA ?? []}
            fetchData={fetchData}
            pageCount={PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.totalCount ?? 0}
            loading={PARKINGDATA?.BULK_PARKING_REQUEST_LOADER}
            page_no={PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.pageNo ?? 1}
            limit={PARKINGDATA?.BULK_PARKING_REQUEST_PAGINATION?.limit ?? 1}
            error={PARKINGDATA?.BULK_PARKING_REQUEST_ERROR ?? ""}
            selectRowsFx={setSelectedRows}

            rowsSelected={selectedRows}
            filters={PARKINGDATA?.BULK_PARKING_REQUEST_FILTER}
        />
        
            :   null
        }
		<div  className={`table-pagination ${selectedRows && selectedRows?.length>0 ? '' : 'disableAndOpacity2'}`}>
            <div className='row'>
				<div className='form-group inline-input col-12'>
                    <label className='col-1'>Action</label>
					<ReactSelect
						options={actionArr}
						className={'common-select common-select-grey col-12'}
						placeholder={'Bulk Action'}
						isMulti={false}
						isClearable
                        value={actionArr?.find((c) => c.value === action)}
                        onChange={(val) =>{ 
							setAction(val?.value); }}
					/>
				</div>
			</div>
        </div>
		{/*{	selectedRows && selectedRows?.length>0 ?*/}
  {/*          filterActive ?*/}
  {/*          action===1 ?*/}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className='row'>
					{	(allSameRType()===1 || allSameRType()===2 || reqType===1 || reqType===2
						|| allSameRType()===5 || allSameRType()===6 || reqType===5 || reqType===6
						) && site ?
					<div className='col-12'>

						<div className='form-group inline-input col-12'>
							<label className='col-1'>Zone </label>
							<TreeSelectCustom 	grey classnames={'col-12'} styleSx={{ maxWidth:'unset' }} fixSite={site}
								selectedZones={selZone}	setselectedZones={setSelZone}	onchangeFx={(e:any)=>{ setValue('ApprovedZoneID',e?.value);
							}} isMulti={false}/>
						</div>
						<ErrorComponent name='ApprovedZoneID' errors={errors} title='Zone' />
						
					</div>
					:	null
					}
					<div className='col-12'>
						<div className='form-group inline-input'>
							<textarea placeholder='Remarks' className='form-control grey-input' {...register('ApprovedComments')}></textarea>
						</div>
					</div>
					<ErrorComponent name='ApprovedComments' errors={errors} title='Comments' />
					<ErrorComponent name="SiteID" errors={errors} title='Site'/>
				</div>
				<div className='col-12'>
					<div className='modal-footer'>
						<button type='submit' className='btn btn-secondary' disabled={loading}>
							<ButtonLoader text='Submit' loading={loading}
							/>
						</button>
						<button type='reset' onClick={()=>{ reset()
		clearErrors()
		 props.handleClose()}} className='btn btn-outline-blue close-modal-button'>
							Close
						</button>
					</div>
				</div>
				</form>
  {/*          :*/}
  {/*          <form onSubmit={handleSubmit(onSubmitHandler)}>*/}
  {/*              <div className="row">*/}
  {/*                  <div className="col-12">*/}
  {/*                      <div className="form-group inline-input">*/}
  {/*                          <label>Select Rejection Reason *</label>*/}
  {/*                          <ReactSelect*/}
  {/*                              options={GENERAL_DDL.REJECTIONREASON_DDL}*/}
  {/*                              className={'common-select col-9 common-select-grey'}*/}
  {/*                              placeholder={'Rejection Reasons'}*/}
  {/*                              isMulti={false}*/}
  {/*                              value={GENERAL_DDL.REJECTIONREASON_DDL?.find((c:any)=> c.value === reqType)}*/}
  {/*                              onChange={(val:any) =>{ */}
  {/*                                  setValue('FK_RejectResonID',val?.value)*/}
  {/*                                  setReqType(val?.value); }}*/}
  {/*                          />*/}
  {/*                      </div>*/}
  {/*                      <ErrorComponent name='FK_RejectResonID' title='Rejection Reason' errors={errors} />*/}
  {/*                  </div>*/}
  {/*                  <div className="col-12">*/}
  {/*                      <div className="form-group inline-input">*/}
  {/*                          <textarea placeholder="Remarks" className="form-control grey-input" {...register('ApprovedComments')}></textarea>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*              <div className="col-12">*/}
  {/*                  <div className="modal-footer">*/}
  {/*                      <button type="submit" className="btn btn-secondary" disabled={loading}>*/}
		{/*					<ButtonLoader text='Reject' loading={loading}*/}
		{/*					/>*/}
		{/*				</button>*/}
  {/*                      <button onClick={()=>{ reset()*/}
		{/*clearErrors()*/}
		{/* props.handleClose();}} type="reset" className="btn btn-outline-blue close-modal-button">Close</button>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*          </form>*/}
		{/*	:	null*/}
  {/*          :   null*/}
  {/*      }*/}
		</div>     
		</div>
        
    </ModalLayout>
  )
}

export default BulkApproveModal