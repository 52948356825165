import React, { Fragment, useEffect, useRef, useState } from 'react';
import ProgressBar from '../../../../common/components/graph_components/ProgressBar';
import { useAppSelector } from '../../../../config/hooks';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import { DatePicker, Select } from 'antd';

import { fetch_security_manual_violations } from './../../store/action';
import moment from 'moment';
import { COLORS, COLOR_FX } from '../../../../utils/Data';

const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

const SecurityViolations = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);

	const [date, setdate] = useState<any>([moment().subtract(7, 'day').startOf('day'), moment().endOf('day')]);

	const [total, settotal] = useState(0);
	const [loading, setloading] = useState(false);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
		
		};

		setloading(true);

		fetch_security_manual_violations(postData, signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ?? [];
				setdata(data);
				settotal(res?.response?.totalCount);
			}

			setTimeout(() => {
				setloading(false)
			}, 1500);
		});

		return () => {
			controller.abort();
		};
	}, [date]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	return (
		<div className='ItemLayout__Wrapper'>
			<div className='ItemLayout__Title'>
				<div> Manual Reported Vioaltions</div>
				<div>
					<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
						{/* <div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Floor</label>
							<Select
								className='common-select-grey'
								options={DASHBOARD_DATA?.FLOOR_LIST}
								getOptionLabel={(o: any) => o.levelName}
								getOptionValue={(o: any) => o.levelID}
								onChange={(e: any) => setfloor(e)}
								value={DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''}
								placeholder='Floor'
							/>
						</div> */}
						<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker
									size='large'
									// showTime
									onChange={(date: any) => setdate(date)}
									value={date}
									allowClear={false}
								/>
							</div>
					</DashboardFilterWrapper>
				</div>
			</div>
			<div className='ItemLayout__Content'>
				<div className='bay-stats progressbar-box widget-levelMap-max-h'>
					{data.length === 0 ? (
						<div className="no_data_found_df">

							<span>
								No Data Found


							</span>
						</div>
					) : (
						<>
							{data.map((item: any, key) => {
								let perc = (item?.violationCount / total) * 100;
								return (
									<Fragment key={key}>
										<ProgressBar
											value={perc}
											label={item?.violationName}
											endLabel={`${item?.violationCount}`}
											tootTipText={`${item?.violationName} : ${item?.violationCount} - Total Violations : ${total}`}
											color={COLOR_FX(key)}

										/>
									</Fragment>
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SecurityViolations;
