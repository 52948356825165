import React, {useEffect, useState} from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks'
import ParkingAllocatedReports from './ParkingAllocated'
import ParkingRequestsReports from './ParkingRequests'
import ViolationsReports from './Violations'
import VisitReports from './VisitReports'

import { export_userReport } from '../../../store/action'
import { exportHistory_as_excel, exportHistory_as_pdf } from '../../../../realtime_monitior/store/action'
import { UPDATE_USER_REPORT_FILTER_UPDATE } from '../../../store/reportSlice'
import CircularProgress from '@mui/material/CircularProgress'
import { Tooltip } from 'antd'

type Props = {
   tabVal:any;
   setTabVal:any;
   setResetFilter:any;
}

const ReportTable = (props: Props) => {
    const [openExport, setOpenExport] = useState(false)
    const [printLoading, setPrintLoading] = useState(false)
    const updateLoaderFn = () =>{
		setPrintLoading(false)
	}
    const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);
    const dispatch = useAppDispatch();

   
   const exportPDF = () =>{
      setPrintLoading(true)
      let qrD = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query
      let qD2 = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query?.replaceAll(/CAST \(|as date\) \(|AS date\)/g,'')
      let postdata = {
         "filters":{
         "query": `${qD2}${qrD!=='' ? ` AND ` : ''}(${
            props?.tabVal===4 ? ` VDate ` : props?.tabVal===2 ? ` ParkingDate ` : ` StartDate `
         } >= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[0]}' AND ${
            props?.tabVal===4 ? ` VDate ` : props?.tabVal===2 ? ` ParkingDate ` : ` EndDate `
         } <= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[1]}')`,
         "OrderBy":""
      },
         "pagination": {
             "pageNo": 1,
             "limit": VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount
         },            
         "FromDate": VISITS_REPORTS_DATA?.USER_REPORT_DATE[0],
         "ToDate": VISITS_REPORTS_DATA?.USER_REPORT_DATE[1]
     }
      let urlText = ''
      let fileName = ''
      if(props?.tabVal===1){
         urlText = 'parking-allocated/export'
         fileName = 'Parking_Allocation_Report'
         export_userReport(urlText,fileName,'pdf',postdata,updateLoaderFn)

      }
      else if(props?.tabVal===2){

         let qr = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query?.replaceAll('tvm.',' ')
         let qr2 = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query?.replaceAll(/CAST \(|as date\) \(|AS date\)/g,'')
         exportHistory_as_pdf({
            'ToDate': VISITS_REPORTS_DATA?.USER_REPORT_DATE[1],
            'FromDate': VISITS_REPORTS_DATA?.USER_REPORT_DATE[0],
            'filters': {
               'query': `${qr2}${qr!=='' ? ` AND ` : ''}( ParkingDate  >= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[0]}' AND  ParkingDate  <= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[1]}')`,
            },
            'pagination': {
               'pageNo': 1,
               'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount,
            },
         },updateLoaderFn);
      }
      else if(props?.tabVal===3){
         urlText = 'parking-requests/export'
         fileName = 'Parking_Requests_Report'
         export_userReport(urlText,fileName,'pdf',postdata,updateLoaderFn)

      }
      else{
         urlText = 'violation/export'
         fileName = 'Violation_Report'
         export_userReport(urlText,fileName,'pdf',postdata,updateLoaderFn)

      }

   }

   const exportExcel = () =>{
      setPrintLoading(true)
      let qrD = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query
      let qD2 = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query?.replaceAll(/CAST \(|as date\) \(|AS date\)/g,'')
      let postdata = {
         "filters":{
         "query": `${qD2}${qrD!=='' ? ` AND ` : ''}(${
            props?.tabVal===4 ? ` VDate ` : props?.tabVal===2 ? ` ParkingDate ` : ` StartDate `
         } >= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[0]}' AND ${
            props?.tabVal===4 ? ` VDate ` : props?.tabVal===2 ? ` ParkingDate ` : ` EndDate `
         } <= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[1]}')`,
         "OrderBy":""
      },
         "pagination": {
             "pageNo": 1,
             "limit": VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount
         },            
     }
      let urlText = ''
      let fileName = ''
      if(props?.tabVal===1){
         urlText = 'parking-allocated/export-excel'
         fileName = 'Parking_Allocation_Report'
         export_userReport(urlText,fileName,'xlsx',postdata,updateLoaderFn)
      }
      else if(props?.tabVal===2){
         let qr = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query?.replaceAll('tvm.',' ')
         let qr2 = VISITS_REPORTS_DATA?.USER_REPORTS_FILTER?.query?.replaceAll(/CAST \(|as date\) \(|AS date\)/g,'')
         exportHistory_as_excel({
            'ToDate': VISITS_REPORTS_DATA?.USER_REPORT_DATE[1],
            'FromDate': VISITS_REPORTS_DATA?.USER_REPORT_DATE[0],
            'filters': {
               'query': `${qr2}${qr!=='' ? ` AND ` : ''}( ParkingDate >= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[0]}' AND  ParkingDate <= '${VISITS_REPORTS_DATA?.USER_REPORT_DATE[1]}')`,
            },
            'pagination': {
               'pageNo': 1,
               'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount,
            },
         },updateLoaderFn);
      }
      else if(props?.tabVal===3){
         urlText = 'parking-requests/export-excel'
         fileName = 'Parking_Requests_Report'
         export_userReport(urlText,fileName,'xlsx',postdata,updateLoaderFn)
      }
      else{
         urlText = 'violation/export-excel'
         fileName = 'Violation_Report'
         export_userReport(urlText,fileName,'xlsx',postdata,updateLoaderFn)
      }

      
   }

  return (
    <div className="white-card">
    <div className="custom-tabing-menu row justify-space-between">
       <ul style={{ width:'70%' }}>
          <li>
             <a className={ props?.tabVal===1 ? "active" : ''}  style={{ borderTopLeftRadius:'10px' }} onClick={()=>{ props?.setTabVal(1); props?.setResetFilter(true);
               dispatch(UPDATE_USER_REPORT_FILTER_UPDATE()) }} >Parking Allocated</a>
          </li>
          <li>
             <a className={ props?.tabVal===2 ? "active" : ''} onClick={()=>{ props?.setTabVal(2); props?.setResetFilter(true);
               dispatch(UPDATE_USER_REPORT_FILTER_UPDATE()) }} >Visits</a>
          </li>
          <li>
             <a className={ props?.tabVal===3 ? "active" : ''} onClick={()=>{ props?.setTabVal(3); props?.setResetFilter(true);
               dispatch(UPDATE_USER_REPORT_FILTER_UPDATE()) }} >Parking Requests</a>
          </li>
          <li>
             <a className={ props?.tabVal===4 ? "active" : ''} onClick={()=>{ props?.setTabVal(4); props?.setResetFilter(true);
               dispatch(UPDATE_USER_REPORT_FILTER_UPDATE()) }} >Violations</a>
          </li>
       </ul>
       <Tooltip title={VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA&&VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA?.length>0 
         && VISITS_REPORTS_DATA?.USER_REPORT_DATE && VISITS_REPORTS_DATA?.USER_REPORT_DATE?.length>0 
         ? '' :
            'Please select and apply a proper date range to Export report'} >
       <div className='dflex align-items-center justify-content-center' style={{ margin:'0 1%'}}>
       <div  className={`btn btn-sqaure btn-secondary btn-secondary-hover openMiniModalLine 
       ${VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA&&VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA?.length>0 
         && VISITS_REPORTS_DATA?.USER_REPORT_DATE && VISITS_REPORTS_DATA?.USER_REPORT_DATE?.length>0
         ? '' : 'disableAndOpacity2' }`} 
       onClick={() =>{ setOpenExport(!openExport) }}>
         {
            printLoading ?
            <CircularProgress color='inherit' size={20}/>
            :  
            <>
               
               <i className='fa-solid fa-download'></i>
               {	openExport ?
				         <div className='miniModalOpenBox'>
                        <div className='miniBoxModalContent'>
                           <div onClick={exportPDF}>
                              <button>
                                 <p>Export PDF</p>
                              </button>
                           </div>
                           <div onClick={exportExcel}>
                              <button>
                                 <p>Export CSV</p>
                              </button>
                           </div>
                        </div>
                     </div>
					   :	
                  null
			      }
               
            </>
         }
       </div>
       </div>
       </Tooltip>
    </div>
    <div className="tabing-row active" id="visits">
       <div className="custom-tabing-body">
          {
            props?.tabVal===1 ?
               <ParkingAllocatedReports/>
            :  props?.tabVal===2 ?
               <VisitReports/>
            :  props?.tabVal===3 ?
               <ParkingRequestsReports/>
            :  <ViolationsReports/>
          }
       </div>
    </div>
 </div>
  )
}

export default ReportTable