import React, { useEffect, useRef, useState } from 'react';
import PieChartIndex from '../PieChart';
import { COLORS } from '../../../../utils/Data';
import { fetch_parkingRequest_count } from '../../store/action';
import { useAppSelector } from '../../../../config/hooks';
import moment from 'moment';
import DashboardFilterWrapper from './../DashboardFilterWrapper';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

const TotalParkingRequest = (props: Props) => {
	const [data, setdata] = useState<any>();
	const [piedata, setpiedata] = useState<any>([]);

	const [loading, setloading] = useState(false);

	const [date, setdate] = useState<any>([moment().subtract(30, 'days'), moment()])

	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);




	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FromDate : date[0].format("YYYY-MM-DD"),
			ToDate : date[1].format("YYYY-MM-DD")

		};
		setloading(true);

		fetch_parkingRequest_count(postData, signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ;

				let formattedData : any= [
				{name :"Pending" ,value :data?.pending},
				{name :"Approved" ,value :data?.approved},
				{name :"Rejected" ,value :data?.rejected},
			]


			setpiedata(formattedData);
			setdata(data)
			} 
			setTimeout(() => {
				setloading(false)
			}, 1500);
		});
		return () => {
			controller.abort();
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, date]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};



	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>{props.name} </div>
					<div>
					<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
							{/* <div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Type</label>
								<Select
									className='common-select-grey'
									options={typeOption}
									onChange={(_: any ,option:any) => settype(option)}
									value={type?.value}
									placeholder='type'
									style={{ width:"100%"}}
									size='large'

								/>
							</div> */}
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker size='large'  inputReadOnly  onChange={(date: any) => setdate(date)} value={date} allowClear={false} />
							</div>
					
						</DashboardFilterWrapper>
					</div>
				</div>
				<div className='ItemLayout__Content'>
					<PieChartIndex data={piedata} COLORS={COLORS}  />
					<div className='dashboard-details'>
						<ul className='parking-list'>
							<li>
								Approve<span className='text-right'>{data?.approved ?? 0}</span>
							</li>
							<li>
								Pending <span className='text-right'>{data?.pending ?? 0}</span>
							</li>
							<li>
								Rejected <span className='text-right'>{data?.rejected ?? 0}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default TotalParkingRequest;
