/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import L, { LatLngBoundsExpression } from 'leaflet';
import './style.css';
import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd.min.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { find_cars_search_realtime } from '../../../../findvehicles/store/action';
import {
	calcBayTypeCount,
	calcIconType,
	calcType,
	// occupiedIcon,
	// outofserviceIcon,
	PointsToLatLng,
	popupOptions,
	popUpTemplate,
	popUpTemplateUpdate,
	// reservedIcon,
	scaleIconForMarker,
	// vacantIcon,
	// violationIcon,
	// violationIcon2,
} from './mapUtils';
import { Fetch_BaySearch, FetchFloors, FetchRealTimeData, FetchSites } from '../../../store/action';
import { useAppDispatch } from '../../../../../config/hooks';
import { FailedToast2 } from '../../../../../utils/utilFunctions';
import { FailedToast } from '../../../../../utils/utilFunctions';

let map: L.Map;

const Map = () => {
	const [siteList, setsiteList] = useState<any>([]);
	const [floorList, setfloorList] = useState<any>([]);
	const [site, setsite] = useState<number | null>(null);
	const [currentSelectedVehicle, setcurrentSelectedVehicle] = useState<any>();

	const [currentSelectedBay, setcurrentSelectedBay] = useState<any>();

	const dispatch = useAppDispatch();

	const ChangeSite = async (siteID: number) => {
		setsite(siteID);

		return FetchFloors(siteID)?.then((resposne) => {
			setfloorList(resposne?.response?.data ?? []);

			let postData = {
				'SiteID': siteID,
				'floorID': resposne?.response?.data[0]?.levelID,
			};
			return FetchRealTimeData(dispatch, postData);
		});
	};

	const getBayData = (
		levelID: number,
		success?: ((res: any) => void) | undefined,
		failed?: ((err: any) => void) | undefined,
		signal?: AbortSignal
	) => {
		let postData = {
			'SiteID': site,
			'floorID': levelID,
		};
		FetchRealTimeData(dispatch, postData, success, failed, signal);
	};

	useEffect(() => {
		FetchSites()?.then((res) => {
			setsiteList(res?.response ?? []);

			if (res?.statusCode === 200) {
				FetchFloors(res?.response[0]?.value)?.then((res2) => {
					setsite(res?.response[0]?.value);
					setfloorList(res2?.response?.data ?? []);
				});
			}
		});
	}, []);

	const initialRef = useRef<any>();
	const markersLayer = useRef<any>();
	const clearIntervalID = useRef<any>();
	const controlRef = useRef<any>();
	const currentLvlRef = useRef<any>();
	const bayDataRef = useRef<any>();

	const [Options, setOptions] = useState<any>([]);
	const [bayOptions, setbayOptions] = useState<any>([]);
	const [bayData, setbayData] = useState<any>([]);

	const handleFetchVehicles = async (key?: string) => {
		let postData = {
			'SearchText': key ?? '',

			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		let Response = await find_cars_search_realtime(postData);

		let data = Response?.response?.data ?? [];
		setOptions(data || []);
		return data;
	};

	const handleFetchBays = async (key?: string) => {
		let postData = {
			'SiteID': site,
			'BayRefID': key,
		};
		let Response = await Fetch_BaySearch(postData);

		let data = Response?.response?.data ?? [];
		setbayOptions(data || []);
		return data;
	};

	// handle vehicle search
	const fetchVehicles = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleFetchVehicles(inputValue));
		});
	const fetchBays = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleFetchBays(inputValue));
		});

	useEffect(() => {
		handleFetchVehicles();
	}, []);

	useEffect(() => {
		if (site) {
			handleFetchBays();
		}
	}, [site]);

	const buildMap = useCallback(() => {
		map = L.map('map', {
			crs: L.CRS.Simple,
			minZoom: -4,
			maxZoom: 6,
			center: [0, 0],
			zoom: 13,
			attributionControl: !1,
		});

		let overlayMaps: any = {};

		let bayCount: any = {};

		let overlayMapData: any = {};
		var legend = new L.Control({ position: 'bottomleft' });

		map.on('baselayerchange', function (e) {
			const controller = new AbortController();
			const signal = controller.signal;
			markersLayer.current.clearLayers();

			if (clearIntervalID.current) {
				clearInterval(clearIntervalID.current);
			}

			map.fitBounds(overlayMaps[e.name].getBounds());
			const sucess = (res: any) => {
				let bayResponseData = res?.response ?? [];

				setbayData(bayResponseData);
				bayDataRef.current = bayResponseData;
				bayCount = calcBayTypeCount(bayResponseData);
				// map.removeControl(legend)

				legend.onAdd = function (map) {
					var div = L.DomUtil.create('div', 'legend');
					div.innerHTML += `<h5>Total Bay - ${bayCount?.total_bay ?? 0}  </h5>`;
					div.innerHTML += `<i style="background: #cd0000"></i><span>In violation - ${bayCount?.is_in_violation ?? 0} </span><br>`;
					div.innerHTML += `<i style="background: #1e3dde"></i><span> Parked - ${bayCount?.is_occupied ?? 0}</span><br>`;
					div.innerHTML += `<i style="background: #6faadd"></i><span>Reserved - ${bayCount?.is_reserved ?? 0}</span><br>`;
					div.innerHTML += `<i style="background: #3b9c10"></i><span> Vacant - ${bayCount?.is_vacant ?? 0}</span><br>`;
					div.innerHTML += `<i style="background: #ee9c28"></i><span>Out of service - ${bayCount?.is_out_of_service ?? 0}</span><br>`;
					div.innerHTML += `<i style="background: #f2fa05"></i><span>Unregistered - ${bayCount?.unknown_vehicle ?? 0}</span><br>`;
					div.innerHTML += `<i style="background: #e60ec2"></i><span>No Plate - ${bayCount?.plate_null ?? 0}</span><br>`;


					return div;
				};

				legend.addTo(map);

				console.log('layers', controlRef.current);

				console.log('MAP CONNTROLL', map);

				markersLayer.current.clearLayers();
				if (res?.response) {
					res?.response.forEach((item: any) => {
						let marker: any;
						marker = L.marker(L.latLng(PointsToLatLng(item.position)), {
							icon: calcIconType(item),
						});
						marker.customID = item?.id ?? null;
						marker
							.on('click', function (e: L.LeafletMouseEvent) {
								const largeIcon = scaleIconForMarker(marker);
								marker.setIcon(largeIcon);

								marker.getPopup().setContent(popUpTemplateUpdate(item, site, calcType(item)), popupOptions);
								marker.getPopup().update();
							})
							.bindPopup(popUpTemplate(item, site, calcType(item)), popupOptions)
							.bindTooltip(`${item?.parkingDetails?.bayName}`)
							.openTooltip();
						markersLayer.current.addLayer(marker);
					});

					markersLayer.current.addTo(map);
				}
			};

			currentLvlRef.current = { level: e.name, id: overlayMapData[e.name]?.levelID };

			//console.log('overlayMapData', overlayMapData);
			getBayData(overlayMapData[e.name]?.levelID, sucess, () => { }, signal);
			//console.log("overlayMapData",overlayMapData[e.name])

			clearIntervalID.current = setInterval(() => {
				map.removeControl(legend);

				getBayData(overlayMapData[e.name]?.levelID, sucess, () => { }, signal);
			}, 120000);
		});

		floorList.forEach(
			(
				item: {
					imageWidth: number;
					imageHeight: number;
					sketchImageUrl: string;
					levelName: string | number;
					fK_SiteID: any;
					levelID: any;
				},
				index: number
			) => {
				//console.log('item', item);
				let bounds: LatLngBoundsExpression = [
					[0, item?.imageWidth],
					[-1 * item?.imageHeight, 0],
				];

				if (item?.sketchImageUrl) {
					const image = L.imageOverlay(item?.sketchImageUrl, bounds, { className: 'image-map' });
					overlayMaps[item?.levelName] = image;
					overlayMapData[item?.levelName] = { fK_SiteID: item?.fK_SiteID, levelID: item?.levelID };
					// images.push(image);
					if (index === 0) {
						initialRef.current = image;
					}
				}
			}
		);

		markersLayer.current = new L.LayerGroup();

		L.Control.Layers.include({
			getOverlays: function () {
				// create hash to hold all layers
				let control: any;
				let layers: any;
				layers = {};
				control = this;

				// loop thru all layers in control
				control._layers.forEach(function (obj: any) {
					//console.log("obj",obj)
					var layerName;

					// check if layer is an overlay
					if (obj.overlay) {
						// get name of overlay
						layerName = obj.name;
						// store whether it's present on the map or not
						return (layers[layerName] = control._map.hasLayer(obj.layer));
					}
				});

				return layers;
			},
		});
		controlRef.current = new L.Control.Layers(overlayMaps).addTo(map);
		if (initialRef.current) {
			initialRef.current.addTo(map);
		}
	}, [floorList]);

	const selectedVehicleOpen = (current: any, SITEID: number | null) => {
		console.log('Hello i run', SITEID);
		let count = 0

		const BayOpenFX = (cur: any, bayDataCur = []) => {

			console.log("cur",cur)

			if (typeof markersLayer.current?._layers === 'object') {
				let markerArray = Object.values(markersLayer.current?._layers);
				console.log("currentLvlRef.current?.id ",currentLvlRef.current?.id )

				if (cur && currentLvlRef.current?.id !== Number(cur?.parkedFloorID)) {
					map.eachLayer(function (layer) {
						map.removeLayer(layer);
					});
					let lvl = floorList.find((item: { levelID: any }) => item?.levelID === cur?.parkedFloorID);

					console.log('lvl', lvl);
					let layer = controlRef.current?._layers?.find((item: { name: any }) => item?.name === lvl?.levelName);
					console.log('layer', layer);

					if (layer) {
						layer?.layer.addTo(map);
					}
					// controlRef.current?._layers[2]?.layer.addTo(map);
				}


				console.log("cur?.refbayid",cur?.refbayid)


				let a: any = markerArray?.find((item: any) => {
					// console.log("item?.customID",item?.customID)


					return item?.customID === cur?.refbayid;
				});

				let SelBaydata = bayDataCur.find((item: any) => item.id === cur?.refbayid);



				if (typeof SelBaydata === "undefined") {

					console.log("i RUN SELE BAYAYAYYAYAY")

					if (count > 10) {
						FailedToast({
							appData: "Something went wrong!!",
							devData: "Unable to locate vehicle."
						})
					}

					if (count > 10) return

					setTimeout(() => {
						count += 1
						BayOpenFX(current, bayDataRef.current);
					}, 1000);

				}

				console.log("THIS A ======> FInd Vehicle" ,a)
				console.log("THIS SelBaydata ======> FInd Vehicle" ,SelBaydata)

				if (typeof SelBaydata === "undefined") return

				console.log("THIS IS AAAAA",a)

				if (a ) {
					const largeIcon = scaleIconForMarker(a);
					a.setIcon(largeIcon);
					a.getPopup().setContent(popUpTemplateUpdate(SelBaydata, SITEID, calcType(SelBaydata)), popupOptions);
					a.getPopup().update();

					a.openPopup();
				console.log("RUN POPUP OPEN",a)

				}
			}
		};
		if(current)
			BayOpenFX(current, bayDataRef.current);
	};

	const selectedBayOpen = (current: any, SITEID: number | null) => {

		let count = 0

		const BayOpenFX = (cur: any, bayDataCur = []) => {
			if (typeof markersLayer.current?._layers === 'object') {
				let markerArray = Object.values(markersLayer.current?._layers);

				if (cur && currentLvlRef.current?.id !== cur?.floorID) {
					map.eachLayer(function (layer) {
						map.removeLayer(layer);
					});
					let lvl = floorList.find((item: { levelID: any }) => item?.levelID === cur?.floorID);

					console.log('lvl', lvl);
					let layer = controlRef.current?._layers?.find((item: { name: any }) => item?.name === lvl?.levelName);
					console.log('layer', layer);

					if (layer) {
						layer?.layer.addTo(map);
					}
					// controlRef.current?._layers[2]?.layer.addTo(map);
				}

				let a: any = markerArray?.find((item: any) => {
					return item?.customID === cur?.bayRefID;
				});

				let SelBaydata:any = bayDataCur.find((item: any) => item.id === cur?.bayRefID);

				if (typeof SelBaydata === "undefined") {

					if (count > 10) {
						FailedToast({
							appData: "Something went wrong!!",
							devData: "Unable to locate bay."
						})
					}

					if (count > 10) return
					console.log("i RUN SELE BAYAYAYYAYAY")
					setTimeout(() => {
						count += 1
						BayOpenFX(current, bayDataRef.current);
					}, 1000);

				}

				console.log("THIS A ======> FInd Bay" ,a)
				console.log("THIS SelBaydata ======> FInd Bay" ,SelBaydata)


				if (typeof SelBaydata === "undefined") return

				if (a && SelBaydata) {
					const largeIcon = scaleIconForMarker(a);
					a.setIcon(largeIcon);
					// a.openPopup();

					a.getPopup().setContent(popUpTemplateUpdate(SelBaydata, SITEID, calcType(SelBaydata)), popupOptions);
					a.getPopup().update();

					a.openPopup();
					if(SelBaydata?.position??undefined)
						map?.flyTo(L.latLng(SelBaydata?.position?.x, SelBaydata?.position?.y), 0.05);
						//map?.setZoomAround(L.point(SelBaydata?.position?.x,SelBaydata?.position?.y),0.01)
				}
			}
		};

		// if (current && currentLvlRef.current?.id !== current?.floorID) {
		// 	setTimeout(() => {
		// 		BayOpenFX(current, bayDataRef.current);
		// 	}, 4000);
		// }
		if(current)
			BayOpenFX(current, bayDataRef.current);
	};

	useEffect(() => {
		buildMap();

		// renderMarkers(Level1);
		console.log('refreshed');
		console.log('------------');
		return () => {
			map.off();
			map.remove();
			clearInterval(clearIntervalID.current);
		};
	}, [buildMap]);

	return (
		<Fragment>
			<div className='w-100'>
				<div className='search-filter-row'>
					<div className='filter-block' id='quick-search'>
						<div className='row'>
							<div className='col'>
								<div className='form-group'>
									<Select
										onChange={(e: any) => {
											setsite(e?.value);
											ChangeSite(e?.value);

											console.log('e', e);
										}}
										options={siteList}
										value={siteList ? siteList.find((c: { value: number | null }) => c.value === site) : ''}
										placeholder='Search Site'
										className='common-select'
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
												textTransform: 'capitalize',
												fontSize: '14px',
											}),
										}}
									/>
								</div>
							</div>

							{/*  ======== VEHICLE SEARCH ========== */}

							<div className='col'>
								<div className='form-group'>
									<AsyncSelect
										className={'common-select '}
										cacheOptions
										isClearable
										getOptionLabel={(e) => `${e.firstName} - ${e.emailID}`}
										getOptionValue={(e) => e.userUniqueID}
										loadOptions={fetchVehicles}
										placeholder='Find Vehicles'
										value={Options?.find((el: any) => el?.refbayid === currentSelectedVehicle?.refbayid) ?? ''}
										onChange={(e: any) => {
											if(e){
												if (e.parkedSiteID !== site) {
													ChangeSite(e.parkedSiteID);
												}
												setcurrentSelectedVehicle(e);
												selectedVehicleOpen(e, e.parkedSiteID);
											}else{
												setcurrentSelectedVehicle(null);
												selectedVehicleOpen(null, null);
											}
											
										}}
										// isClearable={true}
										defaultOptions={Options}
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
												textTransform: 'capitalize',
												fontSize: '14px',
											}),
										}}
										formatOptionLabel={(o) => (
											<div className='dflex justify-space-between align-items-center'>
												<div className='dflex col-dir gap-5'>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															width: '100%',
															alignItems: 'center',
															// justifyContent: 'space-between',
														}}>
														<span style={{ fontSize: '.85rem', fontWeight: 600 }}>{o?.plateNumber} - </span>
														<small style={{ fontStyle: 'italic' }}>{o?.placeOfRegistration}-</small>
														<small style={{ fontStyle: 'italic' }}>{o?.vehicleCategory}</small>
													</div>
												</div>

												<div>
													<div className='badge dark-blue'>{o?.bayName}</div>
												</div>
											</div>
										)}
									/>
								</div>
							</div>
							{/*  ======== BAY SEARCH ========== */}
							<div className='col'>
								<div className='form-group'>
									<AsyncSelect
										className={'common-select '}
										cacheOptions
										isClearable
										getOptionLabel={(e) => e.bayName}
										getOptionValue={(e) => e.bayRefID}
										loadOptions={fetchBays}
										placeholder='Find Bays'
										value={bayOptions?.find((el: any) => el?.bayRefID === currentSelectedBay?.bayRefID) ?? ''}
										onChange={(e: any) => {
											if(e){
												if (e.fK_SiteID !== site) {
													ChangeSite(e.fK_SiteID);
												}
												setcurrentSelectedBay(e);
												selectedBayOpen(e, e.fK_SiteID);
											}
											else{
												setcurrentSelectedBay(null);
												selectedBayOpen(null, null);
											}
											
										}}
										// isClearable={true}
										defaultOptions={bayOptions}
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
												textTransform: 'capitalize',
												fontSize: '14px',
											}),
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='map-container'>
				<div id='map'></div>
			</div>
		</Fragment>
	);
};

export default Map;
