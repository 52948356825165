import React, { useEffect } from 'react';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import { Route, Routes, Navigate } from 'react-router-dom';
import ApplicationLogs from './screens/application/applicationlogs';
import AuditLogs from './screens/audit/auditlogs';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { useRedirect } from '../../utils/hooks/useRedirect';
import './logs.css'
import { subModuleList } from '../../config/subModuleList';
import { useDispatch } from 'react-redux';
import { RESET_STATE } from './store/logsSlice';

const Logsindex = () => {
	const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
	const redirectedPath = useRedirect([
		{
			path: 'application',
			submoduleID: subModuleList['Application Logs'],
		},
		{
			path: 'audit',
			submoduleID: subModuleList['Audit Logs'],
		},
	]);
	return (
		<div className='navtabs__container'>
			<NavTabs>
				<PermissionWrapper subModuleID={subModuleList['Application Logs']}>
					<LinkTab label='Application Logs' href='application' />
				</PermissionWrapper>

				<PermissionWrapper subModuleID={subModuleList['Audit Logs']}>
					<LinkTab label='Audit Logs' href='audit' />
				</PermissionWrapper>
			</NavTabs>
			<Routes>
				<Route
					path={'application'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Application Logs']}>
							<ApplicationLogs />
						</PermissionWrapper>
					}
				/>
				<Route
					path={'audit'}
					element={
						<PermissionWrapper subModuleID={subModuleList['Audit Logs']}>
							<AuditLogs />
						</PermissionWrapper>
					}
				/>

				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default Logsindex;
