import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../config/hooks';
import { sub_modules } from '../../modules/security/screens/Roles/components/types';

type Props = {
	children: React.ReactNode;
	subModuleID: number | string;
	type?: 'isView' | 'isCreate' | 'isEdit' | 'isDelete' | 'isCreateOrEdit';
};

const PermissionWrapper = ({ children, subModuleID, type = 'isView' }: Props) => {
	let isPermission = false;
	const MODULE_PERMISSIONS = useAppSelector((state) => state?.login?.USER_PERMISSION);

	const [isPerm, setIsPerm] = useState<sub_modules>();

	useEffect(() => {
		let HasAccessModules = [...MODULE_PERMISSIONS.filter((item) => item?.user_has_access === true)];
		let SubModules: sub_modules[] = [];
		HasAccessModules.forEach((item) => {
			SubModules = [...SubModules, ...item?.sub_modules];
		});
		let currentSubmodule = SubModules.find((item) => item?.fK_SubModuleID === Number(subModuleID));
		setIsPerm(currentSubmodule);
	}, [MODULE_PERMISSIONS, subModuleID]);

	switch (type) {
		case 'isView':
			isPermission = isPerm?.isView ?? false;
			break;
		case 'isCreate':
			isPermission = isPerm?.isCreate ?? false;

			break;
		case 'isEdit':
			isPermission = isPerm?.isEdit ?? false;

			break;
		case 'isDelete':
			isPermission = isPerm?.isDelete ?? false;
			break;
		case 'isCreateOrEdit':
			isPermission = (isPerm?.isCreate === true || isPerm?.isEdit === true);
			break;
		default:
			break;
	}

	if (isPermission) {
		return <>{children}</>;
	} else return null;
};

export default PermissionWrapper;
