import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

const initialState = {
	EXPORT_REPORTS_GRAPH       :   [],
    EXPORT_REPORTS_GRAPH_LOAD  :   true,
    EXPORT_REPORTS_SITE            :   0,
    EXPORT_REPORTS_FLOOR           :   0,
    EXPORT_REPORTS_DATE            :   [],
    EXPORT_REPORTS_TIME            :   [],
	EXPORT_REPORTS_FILTER_MEASURE	:	'avg',
	EXPORT_REPORTS_FILTER_CHART	:	'bar',
    EXPORT_GROUP_OBJ            :   null
};

export const EXPORTREPORTSLICE = createSlice({
	name: 'EXPORTREPORTSLICE',
	initialState: initialState,
	reducers: {
        EXPORT_UPDATE_REPORTS_LOADER: (state) => {
            state.EXPORT_REPORTS_GRAPH_LOAD = true;
		}, 
        EXPORT_UPDATE_REPORTS_FILTER: (state, action) => {
            state.EXPORT_REPORTS_DATE = action.payload?.dates; 
            state.EXPORT_REPORTS_TIME = action.payload?.times; 
            state.EXPORT_REPORTS_FLOOR = action.payload?.floor; 
            state.EXPORT_REPORTS_SITE = action.payload?.site;
        },
		EXPORT_UPDATE_REPORTS_GRAPH: (state, action) => {
            console.log('EXPORTUPDATEACTION',action.payload)
            state.EXPORT_REPORTS_GRAPH = action.payload?.data;
            state.EXPORT_REPORTS_GRAPH_LOAD = false;
        	state.EXPORT_REPORTS_FILTER_MEASURE = action.payload?.measure;
			state.EXPORT_REPORTS_FILTER_CHART   = action.payload?.chartType;
            state.EXPORT_GROUP_OBJ = action.payload?.imExport;
        }, 
	    EXPORT_UPDATE_REPORTS_DATE_TIME_SITE_FLOOOR: (state, action) => {
            state.EXPORT_REPORTS_DATE = action.payload?.date;
            state.EXPORT_REPORTS_TIME = action.payload?.time;
            state.EXPORT_REPORTS_SITE = action.payload?.site;
            state.EXPORT_REPORTS_FLOOR = action.payload?.floor;
        },
		EXPORT_UPDATE_REPORTS_SITE_FLOOR: (state, action) => {
            state.EXPORT_REPORTS_SITE = action.payload?.site;
            state.EXPORT_REPORTS_FLOOR = action.payload?.floor;
        },
		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
    EXPORT_UPDATE_REPORTS_GRAPH, EXPORT_UPDATE_REPORTS_LOADER, 
    EXPORT_UPDATE_REPORTS_DATE_TIME_SITE_FLOOOR, EXPORT_UPDATE_REPORTS_FILTER, RESET_STATE, EXPORT_UPDATE_REPORTS_SITE_FLOOR
  } =
	EXPORTREPORTSLICE.actions;
export const EXPORTREPORTSData = (state: RootState) => state;
export default EXPORTREPORTSLICE.reducer;
