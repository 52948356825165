import React, { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_user_report } from '../../../store/action';
import moment from 'moment';

type Props = {}

const VisitReports = (props: Props) => {
    const fetchIdRef = useRef(0);
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
    
    const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);
		
function time_convert(num:number)
{ 
   var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
   var d = (num / 60) % 24
   var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
   var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
 console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
 return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
}


    const columns = React.useMemo(
		() => [
			{ Header: 'Parking Date', accessor: 'parkingDate', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A' },
			
			{ Header: 'Name', accessor: 'fullName'},
			{ Header: 'Email', accessor: 'emailID'},
			
			{ Header: 'Plate Number', accessor: 'plateNumber'},
			{ Header: 'Place of Registration', accessor: 'placeOfRegistration' },
			{ Header: 'Vehicle Category', accessor: 'vehicleCategory' },
            { Header: 'Site Name', accessor: 'siteName'},
            { Header: 'Zone Name', accessor: 'zoneName'},
            { Header: 'Bay Name', accessor: 'bayName'},
			{ Header: 'Bay Type', accessor: 'bTypeName'},

			{ Header: 'Entry Timestamp', accessor: 'inTime' },
			{ Header: 'Exit Timestamp', accessor: 'outTime' },
			{ Header: 'Dwell Time', accessor: 'duration', 
				Cell: ({ row }:any) => {
					return <p>{time_convert(row?.original?.duration)}</p>
				}
			},
			
			{ Header: 'Contact', accessor: 'contactNo'},
			
			{ Header: 'Designation', accessor: 'desName'},
			{ Header: 'Department', accessor: 'depName'},
			{ Header: 'Personal Grade', accessor: 'fK_PriorityLevel'},
			
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,
				show: false,
			}				
			
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		filters: { query: string };
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_user_report(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					2,
					signal
				);
			}
		},
		[dispatch, navigate]
	);
	

  return (
    <CommonTable
		data={VISITS_REPORTS_DATA?.USER_REPORTS_LIST_DATA??[]}
		columns={columns}
		pageCount={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.totalCount ?? 0}
		fetchData={fetchData}
		loading={VISITS_REPORTS_DATA?.USER_REPORTS_LOADER}
		error={VISITS_REPORTS_DATA?.USER_REPORTS_ERROR}
		limit={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit ?? 1}
		page_no={VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.pageNo}
		filters={VISITS_REPORTS_DATA?.USER_REPORTS_FILTER}
	/>
  )
}

export default VisitReports