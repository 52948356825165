import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import { useAppDispatch, useAppSelector } from '../../../../config/hooks'
import { fetch_report_occupancy_historical_bayType, fetch_report_occupancy_historical_weekday, fetch_report_occupancy_historical_zone, fetch_report_occupancy_hourly_bayType, fetch_report_occupancy_hourly_overall, fetch_report_occupancy_hourly_weekday, fetch_report_occupancy_hourly_zone, fetch_report_occupancy_summary_bayType, fetch_report_occupancy_summary_weekday, fetch_report_occupancy_summary_zone } from '../../store/action'
import FilterBar from './components/FilterBar'
import Reports from './components/reportDisplayNew'

type Props = {}

const Occupancy = (props:Props) => {
 
	const [tabVal, setTabVal] = useState(0)
  const [measure, setMeasure] = useState('avg')
	const [chartType, setChartType] = useState('')
  const [isUnRecVeh, setIsUnRecVeh] = useState('0')

  const [filename, setFilename] = useState('')
    const [fileNameSub, setFilenameSub] = useState('')
    const [fileDateStr, setFileDateStr] = useState('')
    const [fileTimeStr, setFileTimeStr] = useState('')
    const [fileSectionStr, setFileSectionStr] = useState('')
  
  
  return (
    <div className="main-body-content license">
        <FilterBar tabVal={tabVal} setTabVal={setTabVal} isUnRecVeh={isUnRecVeh} setIsUnRecVeh={setIsUnRecVeh}
          measure={measure} setMeasure={setMeasure} chartType={chartType} setChartType={setChartType}
          
          setFilename={setFilename} setFilenameSub={setFilenameSub} 
          setFileDateStr={setFileDateStr} setFileTimeStr={setFileTimeStr} 
          setFileSectionStr={setFileSectionStr}
          />
        <Reports 
        filename={filename} setFilename={setFilename} fileNameSub={fileNameSub} setFilenameSub={setFilenameSub} 
        fileDateStr={fileDateStr} setFileDateStr={setFileDateStr} fileTimeStr={fileTimeStr} setFileTimeStr={setFileTimeStr} 
        fileSectionStr={fileSectionStr} setFileSectionStr={setFileSectionStr}

        tabVal={tabVal} setTabVal={setTabVal} isUnRecVeh={isUnRecVeh} setIsUnRecVeh={setIsUnRecVeh} 
        measure={measure} setMeasure={setMeasure} chartType={chartType} setChartType={setChartType}/>
    </div>
  )
}

export default Occupancy
