import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	PARKING_POLICY_LIST         : [],
	PARKING_POLICY_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	PARKING_POLICY_LOADER       : true,
	PARKING_POLICY_FILTER       : { query: "" } as FilterObject,
	PARKING_POLICY_ERROR        : '',

	VIOLATION_POLICY_LIST         : [],
	VIOLATION_POLICY_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	VIOLATION_POLICY_LOADER       : true,
	VIOLATION_POLICY_FILTER       : { query: "" } as FilterObject,
	VIOLATION_POLICY_ERROR        : '',

	POLICY_ASSIGNMENT_LIST         : [],
	POLICY_ASSIGNMENT_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	POLICY_ASSIGNMENT_LOADER       : true,
	POLICY_ASSIGNMENT_FILTER       : { query: "" } as FilterObject,
	POLICY_ASSIGNMENT_ERROR        : '',

	POLICY_ASSIGNMENT_ZONES_LIST         : [],
	POLICY_ASSIGNMENT_ZONES_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	POLICY_ASSIGNMENT_ZONES_LOADER       : true,
	POLICY_ASSIGNMENT_ZONES_FILTER      : { query: "" } as FilterObject,
	POLICY_ASSIGNMENT_ZONES_ERROR        : '',
	
};

export const PARKINGPOLICYSLICE = createSlice({
	name: 'PARKINGPOLICY',
	initialState: initialState,
	reducers: {
		UPDATE_PARKING_POLICY_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.PARKING_POLICY_LIST = Object.assign([], data);
			state.PARKING_POLICY_PAGINATION = pagination;
			state.PARKING_POLICY_FILTER = filters;
			state.PARKING_POLICY_LOADER = false;
			state.PARKING_POLICY_ERROR = '';
		},
		UPDATE_PARKING_POLICY_LOADER: (state, action) => {
			console.log("i get data")
			const { loader } = action.payload;
			state.PARKING_POLICY_LOADER = loader;
		},

		UPDATE_PARKING_POLICY_ERROR: (state, action) => {
			const { error } = action.payload;
			state.PARKING_POLICY_ERROR = error;
			state.PARKING_POLICY_LOADER = false;
		},

		UPDATE_VIOLATION_POLICY_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.VIOLATION_POLICY_LIST = Object.assign([], data);
			state.VIOLATION_POLICY_PAGINATION = pagination;
			state.VIOLATION_POLICY_FILTER = filters;
			state.VIOLATION_POLICY_LOADER = false;
			state.VIOLATION_POLICY_ERROR = '';
		},
		UPDATE_VIOLATION_POLICY_LOADER: (state, action) => {
			console.log("i get data")
			const { loader } = action.payload;
			state.VIOLATION_POLICY_LOADER = loader;
		},

		UPDATE_VIOLATION_POLICY_ERROR: (state, action) => {
			const { error } = action.payload;
			state.VIOLATION_POLICY_ERROR = error;
			state.VIOLATION_POLICY_LOADER = false;
		},

		UPDATE_POLICY_ASSIGNMENT_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.POLICY_ASSIGNMENT_LIST = Object.assign([], data);
			state.POLICY_ASSIGNMENT_PAGINATION = pagination;
			state.POLICY_ASSIGNMENT_FILTER = filters;
			state.POLICY_ASSIGNMENT_LOADER = false;
			state.POLICY_ASSIGNMENT_ERROR = '';
		},
		UPDATE_POLICY_ASSIGNMENT_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.POLICY_ASSIGNMENT_LOADER = loader;
		},

		UPDATE_POLICY_ASSIGNMENT_ERROR: (state, action) => {
			const { error } = action.payload;
			state.POLICY_ASSIGNMENT_ERROR = error;
			state.POLICY_ASSIGNMENT_LOADER = false;
		},

		UPDATE_POLICY_ASSIGNMENT_ZONESDATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.POLICY_ASSIGNMENT_ZONES_LIST = Object.assign([], data);
			state.POLICY_ASSIGNMENT_ZONES_PAGINATION = pagination;
			state.POLICY_ASSIGNMENT_ZONES_FILTER = filters
			state.POLICY_ASSIGNMENT_ZONES_LOADER = false;
			state.POLICY_ASSIGNMENT_ZONES_ERROR = '';
		},

		RESET_POLICY_FILTERS_ONLY: (state) => {
			state.PARKING_POLICY_FILTER = initialState.PARKING_POLICY_FILTER;
			state.VIOLATION_POLICY_FILTER = initialState.VIOLATION_POLICY_FILTER;
			state.POLICY_ASSIGNMENT_FILTER = initialState.POLICY_ASSIGNMENT_FILTER;
			state.POLICY_ASSIGNMENT_ZONES_FILTER = initialState.POLICY_ASSIGNMENT_ZONES_FILTER;
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const { 
	UPDATE_PARKING_POLICY_DATA, UPDATE_PARKING_POLICY_LOADER, UPDATE_PARKING_POLICY_ERROR,
	UPDATE_VIOLATION_POLICY_DATA, UPDATE_VIOLATION_POLICY_LOADER, UPDATE_VIOLATION_POLICY_ERROR, RESET_STATE, RESET_POLICY_FILTERS_ONLY,
	UPDATE_POLICY_ASSIGNMENT_DATA, UPDATE_POLICY_ASSIGNMENT_LOADER, UPDATE_POLICY_ASSIGNMENT_ERROR, UPDATE_POLICY_ASSIGNMENT_ZONESDATA
 } =
	PARKINGPOLICYSLICE.actions;
export const POLICYData = (state: RootState) => state;
export default PARKINGPOLICYSLICE.reducer;
