import Request from '../../../config/api';
import {
	APPLICATION_SETTINGS_CPK,
	APPLICATION_SETTINGS_DATA_ARCHIVE,
	APPLICATION_SETTINGS_DATA_CLEAN_UP,
	APPLICATION_SETTINGS_DATA_CLEAN_UP_ACTION,
	APPLICATION_SETTINGS_DATA_CLEAR_LOG,
	APPLICATION_SETTINGS_DATA_SYNC,
	APPLICATION_SETTINGS_DATA_SYNC_NOW,
	APPLICATION_SETTINGS_DOWNLOAD_TEMPLATE,
	APPLICATION_SETTINGS_GENERAL,
	APPLICATION_SETTINGS_GENERAL_ACTION,
	APPLICATION_SETTINGS_LOG_CLEAN_UP,
	APPLICATION_SETTINGS_LOG_CLEAN_UP_ACTION,
	APPLICATION_SETTINGS_UPLOAD_TEMPLATE,
	COMPANY_PROFILE,
	LICENSE,
} from '../../../config/endpoint';
import {
	UPDATE_COMPANY_PROFILE,
	UPDATE_DATA_CLEAN_UP_DATA,
	UPDATE_GENERAL_SETTINGS_DATA,
	UPDATE_LOG_CLEAN_UP_DATA,
	UPDATE_SYNC_LIST_DATA,
	UPDATE_SYNC_LIST_ERROR,
	UPDATE_SYNC_LIST_LOADER,
} from './applicationSlice';
import { APPLICATION_SETTINGS_GPK, COMPANY_PROFILE_ACTION, APPLICATION_SETTINGS_DATA_SYNC_STATUS, APPLICATION_SETTINGS_DATA_AUTO_SYNC } from './../../../config/endpoint';

// type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_logclean_up_list = (dispatch: any) => {
	let postData = {
		'AppKey': '',
	};
	let api_url = APPLICATION_SETTINGS_LOG_CLEAN_UP;
	Request('post', api_url, postData, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_LOG_CLEAN_UP_DATA({
					data: res?.response?.data ?? [],
				})
			);
		}
	});
};

export const fetch_general_settings_list = (dispatch: any) => {
	let postData = {
		'AppKey': '',
	};
	let api_url = APPLICATION_SETTINGS_GENERAL;
	Request('post', api_url, postData, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_GENERAL_SETTINGS_DATA({
					data: res?.response?.data ?? [],
				})
			);
		}
	});
};

export const fetch_dataclean_up_list = (dispatch: any) => {
	let postData = {
		'AppKey': '',
	};
	let api_url = APPLICATION_SETTINGS_DATA_CLEAN_UP;
	Request('post', api_url, postData, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_DATA_CLEAN_UP_DATA({
					data: res?.response?.data ?? [],
				})
			);
		}
	});
};

export const update_log_settings = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = APPLICATION_SETTINGS_LOG_CLEAN_UP_ACTION;
	Request('put', api_url, postData, undefined, success, failed);
};

export const update_data_settings = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = APPLICATION_SETTINGS_DATA_CLEAN_UP_ACTION;
	Request('put', api_url, postData, undefined, success, failed);
};

export const update_general_settings = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = APPLICATION_SETTINGS_GENERAL_ACTION;
	Request('put', api_url, postData, undefined, success, failed);
};

export const fetch_company_profile = (dispatch: any) => {
	let api_url = COMPANY_PROFILE;
	Request('get', api_url, '')?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_COMPANY_PROFILE({
					data: res?.response?.data ?? [],
				})
			);
		}
	});
};

export const update_company_profile = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = COMPANY_PROFILE_ACTION;
	Request('put', api_url, postData, undefined, success, failed);
};

export const fetch_license = async () => {
	let api_url = LICENSE;
	return await Request('get', api_url, '', undefined);
};

export const fetch_product_key = async () => {
	let api_url = APPLICATION_SETTINGS_GPK;
	return await Request('file-download', api_url, '', undefined);
};

export const update_license_file = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = APPLICATION_SETTINGS_CPK;
	Request('post', api_url, postData, undefined, success, failed);
};

export const fetch_sync_list = (dispatch: any, postData: any, signal?: AbortSignal) => {
	let api_url = APPLICATION_SETTINGS_DATA_SYNC;

	dispatch(
		UPDATE_SYNC_LIST_LOADER({
			loader: true,
		})
	);
	Request('post', api_url, postData, signal)?.then((res) => {
		const { response, statusCode } = res;
		const { data, pagination, filters } = response;
		if (statusCode === 200) {
			dispatch(
				UPDATE_SYNC_LIST_DATA({
					data,
					pagination,
					filters,
				})
			);
		} else {
			dispatch(
				UPDATE_SYNC_LIST_ERROR({
					error: 'some error occurred',
				})
			);
		}
	});
};

export const sync_now = async (id:number , source:any ,sucess: ((res: any) => void) | undefined,failed: ((err: any) => void) | undefined) => {
	let api_url = `${APPLICATION_SETTINGS_DATA_SYNC_NOW}?SyncID=${id}&Source=${source}`;
	return await Request('get', api_url, '',undefined,sucess,failed);
};

export const auto_sync_now = async (postData:any ,sucess: ((res: any) => void) | undefined,failed: ((err: any) => void) | undefined) => {
	let api_url = APPLICATION_SETTINGS_DATA_AUTO_SYNC;
	return await Request('post', api_url, postData,undefined,sucess,failed);
};

export const update_sync_status = async () => {
	let api_url = APPLICATION_SETTINGS_DATA_SYNC_STATUS;
	return await Request('get', api_url, '');
};


export const archive_data = async (sucess: ((res: any) => void) | undefined , failed: ((err: any) => void) | undefined) => {
	let api_url = APPLICATION_SETTINGS_DATA_ARCHIVE;
	return await Request('get', api_url, '',undefined,sucess , failed );
};


export const clear_log = async (sucess: ((res: any) => void) | undefined , failed: ((err: any) => void) | undefined ) => {
	let api_url = APPLICATION_SETTINGS_DATA_CLEAR_LOG;
	return await Request('get', api_url, '',undefined,sucess , failed );
};


export const uploadTemplate = async (postData:any ,sucess: ((res: any) => void) | undefined , failed: ((err: any) => void) | undefined ,uploadRef:any) => { 
	let api_url = APPLICATION_SETTINGS_UPLOAD_TEMPLATE;
	return await Request('post', api_url, postData,undefined,sucess , failed ,true,uploadRef);
	
 }


 export const downloadTemplate = async (sucess: ((res: any) => void) | undefined , failed: ((err: any) => void) | undefined) => { 
	let api_url = APPLICATION_SETTINGS_DOWNLOAD_TEMPLATE;
	return await Request('file-download', api_url, '',undefined,sucess , failed ,);

	
}

 