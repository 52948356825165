import { NavigateFunction } from 'react-router-dom';
import Request from '../../../config/api';
import auth from '../../../config/auth';
import {  LOGIN_AUTH, LOGIN_FORGOT, LOGIN_RESET, PROFILE_DETAILS, PROFILE_PASSWORD_RESET, PROFILE_PERMISSIONS } from '../../../config/endpoint';
import { LOGIN_SUCCESS_DATA, UPDATE_USER_PERMISSION, UPDATE_USER_PROFILE } from './loginSlice';
import { UserPermssion } from '../../../config/permissionLocal';
import axios from 'axios';
import { globalConfig } from '../../../../config/config';

export const fetch_login = (navigate: NavigateFunction ,dispatch: any, postData: any, Failed:(err:any)=>void ,suc:any) => {
	let api_url = LOGIN_AUTH;
	Request('post', api_url, postData, undefined, suc, Failed)?.then((res) => {
		const { response, statusCode } = res;


		console.log("response",response)
		
		if (statusCode === 200) {
			dispatch(
				LOGIN_SUCCESS_DATA({
					data: response
				})
			);
		}
	});
};

export const fetch_forgot = (navigate: NavigateFunction , postData: any, Success: (res:any)=>void, Failed:(err:any)=>void) => {
	let api_url = LOGIN_FORGOT;
	Request('post', api_url, postData, undefined, Success, Failed)
};

export const reset_password = (navigate: NavigateFunction ,dispatch: any, postData: any, Success: (res:any)=>void, Failed:(err:any)=>void, tk:any) => {
	let api_url = LOGIN_RESET;
	if(process.env.NODE_ENV === "development"){
		axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
	}else{
		axios.defaults.baseURL = globalConfig.get()?.HOST ;

	}
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + tk;
	axios.post(api_url, postData,{
		headers: { 'Content-Type': 'application/json' }
	}).then((res)=>{ Success(res) }).catch((err)=>{ Failed(err) })
	// Request('post', api_url, postData, undefined, Success, Failed)
};

export const fetchUserPermission = (dispatch: any,Success?: (res:any)=>void, Failed?:(err:any)=>void, navigate?:any) => {
	let api_url = PROFILE_PERMISSIONS;
	Request('get', api_url, '', undefined, Success, Failed)?.then((res) => {
		const { statusCode } = res;
		console.log('RESPONSE PERMISSION API',res)
		if (statusCode === 200) {
			dispatch(
				UPDATE_USER_PERMISSION({
					data: res?.response ?? UserPermssion,
				})
			);
		}
		else if(statusCode === 400) {

			console.log("PERMISSISON ERROR RUN")
			auth.logout(() => {
				navigate('/login', { replace: true });
				// if (isAuthenticated) {
				// 	handleLogout();
				// }
			})

		} 
	});
};


export const fetchUserProfile = (dispatch: any) => {
	let api_url = PROFILE_DETAILS;
	Request('get', api_url, '', undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_USER_PROFILE({
					data: res?.response?.data ?? {},
				})
			);
		} 
	});
};


export const profilePasswordReset = ( postData: any , success: ((res: any) => void) | undefined ,failed: ((err: any) => void) | undefined) => {
	let api_url = PROFILE_PASSWORD_RESET;
	Request('post', api_url, postData, undefined,success,failed)
};

export const SSO_LOGIN = ( postData: any ) => {

	// let api_url = LOGIN_RESET;
	if(process.env.NODE_ENV === "development"){
		axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
	}else{
		axios.defaults.baseURL = globalConfig.get()?.HOST ;

	}
	let api_url = '/auth/authorize-sso';

	axios.defaults.headers.common['Authorization'] = 'Bearer ' + postData?.sso_token;
	return axios.post(api_url, postData,{
		headers: { 'Content-Type': 'application/json' }
	})
	// return Request('post', api_url, postData, undefined,success,failed)
};


			// Request('post','/auth/authorize-sso', postData)








