/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { deleteAll_web_notification, delete_web_notification, fetch_web_notifications, readAll_web_notification, read_web_notification } from '../../general/action';
import ModalLayout from '../../layouts/ModalLayout';
import CommonTable from '../Table/CommonTable';
import { SuccesToast } from '../../../utils/utilFunctions';

type Props = {
  open: boolean;
  handleClose: () => void;
};


const NotificationModal = (props: Props) => {

  const NOTIFICATION = useAppSelector((state) => state?.general)
  const [loader, setLoader] = useState(null)
  const fetchIdRef = useRef(0);
  let dispatch = useAppDispatch();


  const fetchData = useCallback(
    ({ pageSize, pageIndex, signal ,filters}: any) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        fetch_web_notifications(
          dispatch,
          {
            'filters': {
              'query':filters.query || '', 
				"orderby": 'createdOn desc  '
            
            },
              
            'pagination': {
              'pageNo': pageIndex,
              'limit': pageSize,
            },
          },
          signal
        );
      }
    },
    [dispatch]
  );



  const columns = React.useMemo(
    () => [
      { Header: 'Notification ID', accessor: 'wNotificationID' },

      // { Header: 'Notification Type', accessor: 'title' },

      { Header: 'Date Time', accessor: 'createdOn', DateFormat: true , FormatType: 'DD-MMM-YYYY hh:mm A'  },

      { Header: 'Details', accessor: 'nMessages' },


      {
        Header: '',
        accessor: 'actionBar',
        hideHeader: true,

        Cell: ({ row }: any) => {
          return (
            <Fragment>
              <div className='button-group'>
                <div className="button-group">
                  {
                    row?.original?.isRead ?
                    null
                    :
                    <a className="btn btn-sqaure btn-white width-auto" onClick={() => readNotification(row?.original?.wNotificationID)}>
                      <i className="fa-solid fa-eye"></i> 
                      <span>MARK AS READ</span>
                    </a>
                  }
                  
                  <a className="btn btn-sqaure btn-red" onClick={() => deleteNotification(row?.original?.wNotificationID)}>
                    <i className="fa-solid fa-trash"></i>
                  </a>
                </div>
              </div>
            </Fragment>
          );
        },
      },
    ],
    []
  );

  const readNotification = (id: number) => {
    // let postData = {
    //   "ID": id,
    //   "IsRead": 1
    // }
    const success = (res:any) =>{
      SuccesToast(res?.response?.data)
      setLoader(null)
      fetch_web_notifications(
        dispatch,
        {
          'filters': {
            'query': '', 
            "orderby": 'createdOn desc  '
          
          },
            
          'pagination': {
            'pageNo': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.pageNo,
            'limit': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.limit,
          },
        },
        
      );
    }
    read_web_notification(id,success)
  }

  const deleteNotification = (id: number) => {
    let postDAta = {
      "ID" : [id]
    }
    const success = (res:any) =>{
      SuccesToast(res?.response?.data)
      setLoader(null)
      fetch_web_notifications(
        dispatch,
        {
          'filters': {
            'query': '', 
            "orderby": 'createdOn desc  '
          
          },
            
          'pagination': {
            'pageNo': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.pageNo,
            'limit': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.limit,
          },
        },
        
      );
    }
    delete_web_notification(postDAta,success)
  }

  const deleteAllNotification = () => {
    const success = (res:any) =>{
      SuccesToast(res?.response?.data)
      setLoader(null)
      fetch_web_notifications(
        dispatch,
        {
          'filters': {
            'query': '', 
            "orderby": 'createdOn desc  '
          
          },
            
          'pagination': {
            'pageNo': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.pageNo,
            'limit': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.limit,
          },
        },
        
      );
    }
    deleteAll_web_notification(success)
  }

  const readAllNotification = () => {
    const success = (res:any) =>{
      SuccesToast(res?.response?.data)
      setLoader(null)
      fetch_web_notifications(
        dispatch,
        {
          'filters': {
            'query': '', 
            "orderby": 'createdOn desc  '
          
          },
            
          'pagination': {
            'pageNo': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.pageNo,
            'limit': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.limit,
          },
        },
        
      );
    }
    readAll_web_notification(success)
  }


  const NotifHeader = () =>{
    return(
      <div className='modal-header ' 
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 style={{ fontSize:'1rem' , fontWeight:600, textTransform:"uppercase"  }} >Notification Details</h1>
          <div className="button-group" style={{ marginRight:'2rem' }}>
            <a className="btn btn-sqaure btn-red" style={{ width:'auto', padding: '0 15px 0 15px', letterSpacing: 0 }}  
              onClick={() => deleteAllNotification()}>
              <i className="fa-solid fa-trash" style={{ marginRight:'8px' }}></i> <span style={{ position:'relative', top:'1px' }}>CLEAR ALL</span>
            </a>
            <a className="btn btn-sqaure btn-white btn-outline-blue width-auto"
               onClick={() => readAllNotification()}>
              <i className="fa-solid fa-eye"></i> <span>MARK ALL AS READ</span>
            </a>
            
        </div>
				<div className='close-modal close-modal-button' onClick={props.handleClose}></div>
      </div>
    )
  }

  return (
    <ModalLayout open={props.open} handleClose={props.handleClose} title='Notification Details' subtitle='' big
      customHeader={<NotifHeader/>}
    >

      <CommonTable
        columns={columns}
        data={NOTIFICATION?.NOTIFICATIONS_LIST ?? []}
        fetchData={fetchData}
        pageCount={NOTIFICATION?.NOTIFICATIONS_PAGINATION?.totalCount ?? 0}
        loading={NOTIFICATION?.NOTIFICATIONS_LOADER}
        page_no={NOTIFICATION?.NOTIFICATIONS_PAGINATION?.pageNo ?? 1}
        limit={NOTIFICATION?.NOTIFICATIONS_PAGINATION?.limit ?? 1}
        filters={NOTIFICATION?.NOTIFICATIONS_FILTER}
      />
    </ModalLayout>
  )
}

export default NotificationModal