import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import auth from './config/auth'

const ProtectedRoute = () => {
	return (
		auth.isAuthenticated() ? <Outlet/> : <Navigate to='/login' replace/>
	  )
}

export default ProtectedRoute
