import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../common/layouts/ModalLayout';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { fetch_parkingRequestslist, parkingRequestStatus_action } from '../store/action';
import { FailedToast, SuccesToast } from '../../../utils/utilFunctions';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import ReactSelect from 'react-select';
import { fetch_RejectionReasonDDL } from '../../../common/general/action';
import ErrorComponent from '../../../common/components/ErrorComponent';

type Props = {
    open: boolean;
    handleClose: () => void;
    Data?:any
}

const schema = yup.object().shape({
    ApprovedComments : yup.string(),
    FK_RejectResonID : yup.number().required(),
    
})

const RejectModal = (props: Props) => {

    useEffect(() => {
		const controller = new AbortController();
        reset()
	clearErrors()
		fetch_RejectionReasonDDL(dispatch, undefined, controller.signal)
    }, [])
    

    const { register, handleSubmit,  formState: { errors }, reset, control, setValue, watch, clearErrors } = useForm({
		mode: 'onBlur',
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        shouldFocusError: true,
	})
    const navigate                      = useNavigate();
    const dispatch = useAppDispatch()
      const PARKINGREQUESTS              = useAppSelector((state) => state?.parkingrequest);
      const GENERAL_DDL = useAppSelector((state) => state?.general);
    const [reqType, setReqType] = useState()

    const onSubmitHandler = (data: any) => {
        console.log('data', data);
        let arrVal = []
        for(let i=0; i < props.Data?.id.length; ++i){
            arrVal.push(
                {
                    ApprovedComments : data.ApprovedComments,
                    FK_RejectResonID : data.FK_RejectResonID,
                    RequestID        : props.Data?.id[i],
                    Status           : 2
                }
            )
        }
		let postData = {
            UpdateParkingRequest : [...arrVal]
        }
		const Success = (res: any) => {

            SuccesToast(res?.response?.data);
            fetch_parkingRequestslist(navigate,
                dispatch, {
				'filters': {
					'query': PARKINGREQUESTS?.PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
            reset();
            props.handleClose();
        };
        const Failed = (err: any) => {
            FailedToast(err?.response)		;
            fetch_parkingRequestslist(navigate,
                dispatch, {
				'filters': {
					'query': PARKINGREQUESTS?.PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
            
            props.handleClose();
        };

        parkingRequestStatus_action(postData, Success, Failed);
        
    };
    
    return (
        <ModalLayout open={props.open} handleClose={()=>{ reset()
            clearErrors()
             props.handleClose()}} title='Reject Parking Request'>


            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group inline-input">
                            <label>Select Rejection Reason</label>
                            <ReactSelect
                                options={GENERAL_DDL.REJECTIONREASON_DDL}
                                className={'common-select col-9 common-select-grey'}
                                placeholder={'Rejection Reasons'}
                                isMulti={false}
                                value={GENERAL_DDL.REJECTIONREASON_DDL?.find((c:any)=> c.value === reqType)}
                                onChange={(val:any) =>{ 
                                    setValue('FK_RejectResonID',val?.value)
                                    setReqType(val?.value); }}
                            />
                        </div>
                        <ErrorComponent name='FK_RejectResonID' title='Rejection Reason' errors={errors} />
                    </div>
                    <div className="col-12">
                        <div className="form-group inline-input">
                            <textarea placeholder="Remarks" className="form-control grey-input" {...register('ApprovedComments')}></textarea>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-secondary">Reject</button>
                        <button onClick={()=>{ reset()
		clearErrors()
		 props.handleClose();}} type="reset" className="btn btn-outline-blue close-modal-button">Close</button>
                    </div>
                </div>
            </form>



        </ModalLayout>
    )
}

export default RejectModal