import React, { Fragment, useEffect, useRef, useState } from 'react';
import PopUp from '../../../../common/components/popUp';
import { fetch_BayDDL, fetch_BayTypeDDL } from '../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { Tooltip } from 'antd';

import './style.css';
import { get_reservedBays } from '../../store/action';
import moment from 'moment';

type Props = {
	selectedZone?: { label: string; value: any };
	date?: any;
	selectedBay?: any;
	setselectedBay?: any;
	siteID?:any;
	time?:any;
};

// const ReserveABay = forwardRef((props: Props ,ref) => {

const ReserveABay = (props: Props) => {
	console.log('props', props?.selectedZone);
	const dispatch = useAppDispatch();

	const childRef: any = useRef(null);

	const [selectedBayType, setselectedBayType] = useState<any>(null);
	// const [selectedBay, setselectedBay] = useState<any>(null);
	const [date, setdate] = useState<any>(props.date??[moment().subtract(30, 'days').startOf('day'), moment().endOf('day')]);
	const [reservedBays, setreservedBays] = useState<any[]>([]);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
			props.setselectedBay(null);
			setselectedBayType(null);
		}
	};

	const save = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	useEffect(() => {
		console.log(props)
		fetch_BayTypeDDL(dispatch,props?.siteID);
	}, [dispatch, props?.siteID]);

	useEffect(() => {
		console.log('SECONDUSEFFECTCALLED',props)
		let currFromTime = '00:00:00'
		let currToTime = '23:59:59'
		if(props?.time && props?.time?.length>0 && props?.time!==null){
			let TempcurrFromTime = typeof props?.time[0] === "object" ? props?.time[0].format('HH:mm:ss') : 
				moment(props?.time[0], ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid() ? 
					moment(props?.time[0], ['HH:mm', 'HH:mm:ss', 'hh:mm a']).format('HH:mm:ss') : null
			let TempcurrToTime = typeof props?.time[1] === "object" ? props?.time[1].format('HH:mm:ss') : 
			moment(props?.time[1], ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid() ? 
				moment(props?.time[1], ['HH:mm', 'HH:mm:ss', 'hh:mm a']).format('HH:mm:ss') : null

			currFromTime = moment(TempcurrFromTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid() ? moment(TempcurrFromTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).format('HH:mm:ss') : '00:00:00'
			currToTime = moment(TempcurrToTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).isValid() ? moment(TempcurrToTime, ['HH:mm', 'HH:mm:ss', 'hh:mm a']).format('HH:mm:ss') : '23:59:59'
		}
		if (selectedBayType?.value && props?.selectedZone?.value) {
			let postData = { query: `FK_ZoneID=${props?.selectedZone?.value} AND FK_BayTypeID=${selectedBayType?.value}` };
			fetch_BayDDL(dispatch, postData);
			let postData2 = {
				FromDate	: date[0].format('YYYY-MM-DD'),
				ToDate		: date[1].format('YYYY-MM-DD'),
				FromTime	: currFromTime,
				ToTime		: currToTime,
				ZoneID		: props?.selectedZone?.value,
			};

			get_reservedBays(postData2)?.then((res) => {
				let data = res?.response?.data ?? [];

				let formattedData = data.map((item: any) => item?.fK_BayID);

				setreservedBays(formattedData);
			});
		}else if(selectedBayType===null){
			if(props?.selectedZone?.value){
				let postData = { query: `FK_ZoneID=${props?.selectedZone?.value}` };
				fetch_BayDDL(dispatch, postData);
			}
			

			let postData2 = {
				FromDate: date[0].format('YYYY-MM-DD'),
				ToDate: date[1].format('YYYY-MM-DD'),
				FromTime	: currFromTime,
				ToTime		: currToTime,
				ZoneID: props?.selectedZone?.value,
			};

			get_reservedBays(postData2)?.then((res) => {
				let data = res?.response?.data ?? [];

				let formattedData = data.map((item: any) => item?.fK_BayID);

				setreservedBays(formattedData);
			});
		}
	}, [date, dispatch, props?.selectedZone, selectedBayType?.value, props?.time]);

	const GENERAL_DDL = useAppSelector((state) => state?.general);

	const newLocal = (
		<p className='label-style text-right text-hover' style={{ textDecoration: 'underline' }} onClick={() => {}}>
			Reserve A Bay
		</p>
	);

	return (
		<>
			<PopUp icon={newLocal} ref={childRef}>
				<div className='reserve-bay-wrapper'>
					<div className='col-4 p-10' style={{ borderRight: '1px solid var(--lightgrey)' }}>
						<div className='row'>
							<div
								className='dflex justify-space-between w-100 align-items-center pb-10'
								style={{ borderBottom: '1px solid var(--lightgrey)' }}>
								<h2 className='border-title ml-5'>Bay Types </h2>
							</div>
						</div>
						<div className='row'>
							<div className='dflex col-dir gap-5 new-scrollbar' style={{ overflow: 'auto', maxHeight:'40vh', width:'12vw' }}>
								<div
									className={selectedBayType===null ? 'btn-primary p-5' : 'p-5'}
									onClick={() => setselectedBayType(null)}
									style={{ border: '1px solid var(--lightgrey)', fontSize: '.85rem', fontWeight: 500 }}
								>
									All
								</div>
								{GENERAL_DDL?.BAYTYPE_DDL &&
									GENERAL_DDL?.BAYTYPE_DDL.map((item, key) => {
										return (
											<div
												className={item?.value === selectedBayType?.value ? 'btn-primary p-5' : 'p-5'}
												onClick={() => setselectedBayType(item)}
												style={{ border: '1px solid var(--lightgrey)', fontSize: '.85rem', fontWeight: 500 }}
												key={key}>
												{item?.label}
											</div>
										);
									})}
							</div>
						</div>
					</div>
					<div className='col-12'>
						<div className='row'>
							<div
								className='dflex justify-space-between w-100 align-items-center pb-10'
								style={{ borderBottom: '1px solid var(--lightgrey)' }}>
								<div
									className='p-5 ml-5'
									style={{ border: '1px solid var(--lightgrey)', fontSize: '.85rem', fontWeight: 500 }}>
									<h2 className='border-title ml-5'>{selectedBayType?.label}</h2>
								</div>
								<div></div>
								<div className='p-5' style={{ border: '1px solid var(--lightgrey)', fontSize: '.85rem', fontWeight: 500 }}>
									{props.selectedBay?.label}
								</div>
							</div>
						</div>
						<div className='row dflex col-dir' style={{ justifyContent: 'space-between', height: '350px' }}>
							<div className='row'>
								<div className='dflex p-10' style={{ overflow: 'auto', flexWrap: 'wrap', maxHeight: '280px' }}>
									{GENERAL_DDL?.BAY_DDL &&
										GENERAL_DDL?.BAY_DDL.map((item, key) => {
											return (
												<Fragment key={key}>
													{reservedBays.includes(item?.value) ? (
														<>
															<Tooltip title={`${item?.label} - Reserved`}>
																<div
																	style={{
																		width: '30px',
																		height: '45px',
																		background: 'var(--darkgrey)',
																		// pointerEvents: reservedBays.includes(item?.value) ? 'none' : 'all',
																		opacity: 0.5,
																		border: '1px solid var(--grey-primary)',
																	}}></div>
															</Tooltip>
														</>
													) : (
														<>
															<Tooltip title={item?.label}>
																<div
																	onClick={() => props.setselectedBay(item)}
																	style={{
																		width: '30px',
																		height: '45px',
																		background: 'var(--lightgrey)',
																		border:
																			item?.value === props.selectedBay?.value
																				? '1px solid var(--primary)'
																				: '1px solid var(--grey-primary)',
																	}}></div>
															</Tooltip>
														</>
													)}
													{/* <Tooltip title={item?.label}>
														<div
															className={item?.value === selectedBay?.value ? 'btn-primary' : ''}
															onClick={() => setselectedBay(item)}
															style={{
																width: '30px',
																height: '45px',
																background: reservedBays.includes(item?.value) ? 'var(--darkgrey)' : 'var(--lightgrey)',
																// pointerEvents: reservedBays.includes(item?.value) ? 'none' : 'all',
																opacity: reservedBays.includes(item?.value) ? 0.5 : 1,
																border:
																	item?.value === selectedBay?.value
																		? '1px solid var(--primary)'
																		: '1px solid var(--grey-primary)',
															}}></div>
													</Tooltip> */}
												</Fragment>
											);
										})}
									{GENERAL_DDL?.BAY_DDL && GENERAL_DDL?.BAY_DDL.length === 0 && <div>No bays found.</div>}
								</div>
							</div>

							<div className='row' style={{ borderTop: '1px solid var(--lightgrey)' }}>
								<div className='dflex flex-justify-end  gap-10 p-10'>
									<button className='filter-btn' onClick={handleClose}>
										close
									</button>
									<button className='filter-btn btn-primary' onClick={save}>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopUp>
		</>
	);
};

export default ReserveABay;
