import React from 'react';
import { Area, Bar, CartesianGrid, ComposedChart, Label, Legend, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { COLOR_FX } from '../../../utils/Data';
import ProgressBar from '../../../common/components/graph_components/ProgressBar';

export const ProgressBarChartObject = React.forwardRef(({loader, data, unit='', title=''}, ref) =>{
  console.log('PROGRESSBARCHARTOBJ',loader,data,ref)
	return(
		<div ref={ref} className='card-body col-12' id={`ProgressChartId-${title}`}>
				{
				data && data?.length>0 ?
				data.map((el,key) => {
					return (
						<div className='custom-progressbar'>
							<label>{el.name}</label>
							<div className='full-progressbar'>
								<ProgressBar value={el.value}
                    // isAnimationActive={false}
										color={COLOR_FX(key)}
								
								/>
								<span>{el.value_label ?? el.value}{unit===''?'%':`${unit}`}</span>
							</div>									
						</div>
					);
				})
				:
				<div style={{ width:'100%', textAlign:'center' }}>
					<p>No data</p>
				</div>
			}
			</div>
	)
})

export const LineBarGraphChartObject = React.forwardRef((
  {
    data, extraFunc=null, unitY='', yTickFormaterFn=null, numberOnlyInY=null, isAreaOpen=false, lineType='monotone', graphType="line"
  }, ref) => {
    let colV = 0;
    
    if(graphType==="line"){
      var legendDiv = {
        fontWeight : 600,
        borderRadius : '5px',
        padding: '1%',
        backgroundColor: 'rgba(0,0,0,0.05)',
        left:0,
        width:'100%',
        alignItems:'center'
    }
      return (
        <ResponsiveContainer height="100%">
        <ComposedChart
            data={data}
            width={500}
            height={300}
            margin={{
                top: 5,
                right: 20,
                left: extraFunc? 20 : -10,
                bottom: 5,
              }}
        >
            <CartesianGrid strokeDasharray="3 3" ref={ref}/>
                <XAxis 
                    dataKey="name"                        
                    padding={{ left: 20, right:20}} 
                    allowDataOverflow={true} 
                    interval={data && data.length > 10 ? 2 : 0}
                    style={{textTransform :"capitalize" , fontWeight:700 , fontSize:"9.5px" }} />
                <YAxis  
                    padding={{ top:10, bottom: 10 }}    
                    unit={unitY}  
                    tickFormatter={yTickFormaterFn??extraFunc??null}
                    tickSize={1}
                    allowDecimals={numberOnlyInY??true}
                    style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}/>      
                <Legend wrapperStyle={legendDiv}  style={{ textTransform :"uppercase"}}/>
                      {
                        data && Object.keys(data[0]) ? Object.keys(data[0]).map((el, idx) =>{ 
                            if(el!=='name' && el!=='toolTipLabel' && !el?.includes('-toolTipLabel')){
                            return (
                              <>
                                {
                                  isAreaOpen ?
                                    <>
                                      <defs>
                                        <linearGradient id={`colorLine-${idx}`} x1="0" y1="0" x2="0" y2="1">
                                          <stop offset="5%" stopColor={COLOR_FX(colV)} stopOpacity={0.4}/>
                                          <stop offset="95%" stopColor={COLOR_FX(colV)} stopOpacity={0}/>
                                        </linearGradient> 
                                      </defs>
                                      <Area type='monotone' dataKey={el} 
                                        legendType='none'
                                        tooltipType='none'
                                        strokeOpacity={1}
                                        fillOpacity={1} fill={`url(#colorLine-${idx})`}
                                        stroke={COLOR_FX(colV)} 
                                      />
                                    </>
                                  :
                                    null
                                }
                                <Line type={lineType} dataKey={el} 
                                    strokeWidth={2}
                                    strokeOpacity={1}
                                    isAnimationActive={false}
                                    stroke={COLOR_FX(colV++)} />
                              </>
                            )}
                            else return null
                        }) 
                        :
                        null
                      }
                    </ComposedChart>
                  </ResponsiveContainer>
      )
    }else{
      var legendDiv = {
        fontWeight : 600,
        borderRadius : '5px',
        padding: '1%',
        backgroundColor: 'rgba(0,0,0,0.05)',
        left:0,
        width:'100%',
        alignItems:'center'
    } 
       
        return (
              <ResponsiveContainer height="100%">
                <ComposedChart
                    data={data}
                    width={500}
                    height={300}
                    margin={{
                        top: 5,
                        right: 20,
                        left: extraFunc? 20 : -10,
                        bottom: 5,
                      }}
                >
                <CartesianGrid strokeDasharray="3 3" ref={ref}/>
                    <XAxis dataKey="name" padding={{ left: 20, right:20}} allowDataOverflow={true} 
                        interval={data && data.length > 10 ? 2 : 0}
                        
                        style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"9.5px" }}/>
                    <YAxis  padding={{ top:20 }} style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
                      unit={unitY}  
                      tickFormatter={yTickFormaterFn??extraFunc??null}
                      className='customBarGraphLabelAdjust'
                      //label={{ value: labelY, angle: -90, fontWeight:600 , fontSize:"11.5px", positon:'insideLeft'}}
                    />
                    <Legend wrapperStyle={legendDiv} style={{ textTransform :"uppercase"}}/>
                    {
                        data && Object.keys(data[0]) ? Object.keys(data[0]).map(el =>{ 
                            if(el!=='name' && el!=='toolTipLabel' && !el?.includes('-toolTipLabel')){
                            return (
                                <Bar dataKey={el} 
                                opacity={1}
                                isAnimationActive={false}
                                fill={COLOR_FX(colV++)} />
                            )
                        }
                            else return null
                        }) 
                        :   null
                    }
                </ComposedChart>
              </ResponsiveContainer>
        )
    }
})

export const EntryExitGraphChartObject = React.forwardRef((
  {
    data, extraFunc=null 
  }, ref) => {
    console.log('asdfg')
    const legendDiv = {
      fontWeight : 600,
      borderRadius : '5px',
      padding: '1%',
      backgroundColor: 'rgba(0,0,0,0.05)'
  }
		return (
      <ResponsiveContainer height="100%">
      <ComposedChart
          data={data}
          width={500}
          height={300}
          margin={{
              top: 5,
              right: 20,
              left: extraFunc? 20 : -10,
              bottom: 5,
            }}
      >
				<CartesianGrid stroke="#f5f5f5" ref={ref}/>
        			<XAxis dataKey="name" padding={{ left: 20, right:20}} 
						allowDataOverflow={true} 
						// interval={REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA && REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA?.length > 10 ? 2 : 0}
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
				  	/>
        			<YAxis  padding={{ top:20 }} 
					    yAxisId="left"
						allowDecimals={false}
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
						label={{ value: 'Vehicles', angle: -90, fontWeight:600 , fontSize:"12.5px", position: 'insideLeft'}}/>
							   
					<YAxis  padding={{ top:20 }} 
						yAxisId="right"
						unit={'%'}
						orientation="right"
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
						label={{ value: 'Occupancy Percentage', angle: 90, fontWeight:600 , fontSize:"12.5px", position: 'insideRight'}}/>
        			<Legend wrapperStyle={legendDiv} />
        			<Bar dataKey="Vehicles Entered" fill="#ff7300"
						yAxisId="left"
						strokeWidth={1}
            isAnimationActive={false}
						opacity={1} />
        			<Bar dataKey="Vehicles Exited" fill="#82ca9d"
						yAxisId="left"
						strokeWidth={1}
            isAnimationActive={false}
						opacity={1} />
        			<Line type="monotone" dataKey="Occupancy" stroke="#413ea0"
						yAxisId="right"
						strokeWidth={2}
            isAnimationActive={false}
						strokeOpacity={1} />
			</ComposedChart>
      </ResponsiveContainer>
		)
})