import React from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart, Area } from 'recharts';

type Props = {
	data: any[];
	xAxisName: string;
	yAxisName: string;
	xTickCount?: number;
	yTickCount?: number;
	Yunit?:string;
	numberOnlyInY?:boolean;
	labelY?:string;
	labelOffset?:number;
	extraFunc?:any;
	yTickFormaterFn?:any

};

const RLineChart = (props: Props) => {
	return (
		<ResponsiveContainer width='100%' height='100%'>
			<ComposedChart
				width={500}
				height={290}
				data={props?.data}
				margin={{
					top: 25,
					right: 30,
					left: 5,
					bottom: 5,
				}}
				>
				<CartesianGrid strokeDasharray="3 3" stroke="#DFE2E6" />
				<XAxis
					dataKey={props?.xAxisName}
					style={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '10.5px' }}
					tickCount={props?.xTickCount ?? 5}
					tickMargin={10}


				


				/>
				<YAxis
					className='label-style'
					style={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '10.5px' }}
					tickCount={props?.yTickCount ?? 5}
					unit={props?.extraFunc? '' : props?.Yunit}
					allowDecimals={props?.numberOnlyInY??true}
					tickFormatter={props?.yTickFormaterFn??props?.extraFunc??null}
					padding={{ top:15 }}
				>
				{
					props?.labelY ?
					<Label value={props?.labelY} angle={-90} fontWeight={600}  offset={props?.labelOffset??-20} position={'left'} />
					:	null
				}
				</YAxis>
				<Tooltip isAnimationActive={false}  
                        formatter={(label:any) =>{
							let v :any = ''
							v = `${props?.extraFunc ? props?.extraFunc(label) : label} ${props?.extraFunc ? '' : props?.Yunit??''}`
							return v
						}}
                        labelFormatter={
                            (label:any, payload) =>{ 
                               let v:any = ''
                               if(payload && payload?.length>0 ? payload[0].payload?.toolTipLabel : false)
                                  v = <p>{label} <br/>{payload[0].payload?.toolTipLabel}</p>
                                else v = label
                               return v
                              }
                        }
                    />
				<Legend style={{ textTransform: 'uppercase' }} />
				<defs>
                    <linearGradient id={`colorLine-dash`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={'#d4421e'} stopOpacity={0.4}/>
                        <stop offset="95%" stopColor={'#d4421e'} stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <Area type='monotone' dataKey={props?.yAxisName} 
                    legendType='none'
                    tooltipType='none'
                    strokeOpacity={1}
                    fillOpacity={1} fill={`url(#colorLine-dash)`}
                	stroke={'#d4421e'} 
				/>
				<Line type='monotone' dataKey={props?.yAxisName} stroke='#d4421e'  strokeWidth={2}/>
			</ComposedChart>
		</ResponsiveContainer>
	);
};

export default RLineChart;
