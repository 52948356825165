/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteCustomConfirm from '../../../../../common/components/DeleteCustomConfirm';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { delete_violation_policy, fetch_violation_policy } from '../../../store/actions';
import AddModal from './addModal';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';

type Props = {};
// eslint-disable-next-line no-lone-blocks
{
	/**
		@hardcoded PENALTY VALUES
		0 => none 1=> self 2=>Esc1 3=>Esc2
		**/
}

const ListTable = (props: Props) => {
	const [addModal, setaddModal] = useState(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const addModalClose = () => setaddModal(false);
	const fetchIdRef = useRef(0);
	const delModalClose = () => setdeleteModal(false);
	const [deleteModal, setdeleteModal] = useState(false);
	const [dataDetail, setdataDetail] = useState();
	const [ID, setID] = useState();
	const [loader, setLoader]	= useState(false)


	const VIOLATION_POLICY_DATA = useAppSelector((state) => state?.policy);

	const penaltyArr = ['NONE', 'SELF', 'ESCALATION 1', 'ESCALATION 2'];

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'vTypeID', orderByKey: "vTypeID" },
			{ Header: 'VIOLATION NAME', accessor: 'violationName', orderByKey: "violationName" },
			{ Header: 'CREATED ON', accessor: 'createdOn', DateFormat: true, FormatType: 'DD-MMM-YYYY hh:mm A', orderByKey: "createdOn" },
			{ Header: 'CREATED BY', accessor: 'createdUser', orderByKey: 'FullName'},

			{
				Header: '1st PENALTY',
				accessor: 'penalty1',
				orderByKey : 'penalty1',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<p>{penaltyArr[row?.values?.penalty1]}</p>
						</Fragment>
					);
				},
			},
			{
				Header: '2nd PENALTY',
				accessor: 'penalty2',
				orderByKey: 'penalty2',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<p>{penaltyArr[row?.values?.penalty2]}</p>
						</Fragment>
					);
				},
			},
			{
				Header: '3rd PENALTY',
				accessor: 'penalty3',
				orderByKey: 'penalty3',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<p>{penaltyArr[row?.values?.penalty3]}</p>
						</Fragment>
					);
				},
			},
			{
				Header: 'VIOLATION CATEGORY',
				accessor: 'violationCategory',
				orderByKey: 'violationCategory'
				// ,
				// orderByKey: 'vCategory'
			},

			{
				Header: 'VIOLATION EXCEPTION',
				accessor: 'isEnableVException',
				orderByKey: 'isEnableVException',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<StatusChipComponent
								chipType={row?.values?.isEnableVException ? 'success' : 'error'}
								text={row?.values?.isEnableVException ? 'Active' : 'Inactive'}
							/>
						</Fragment>
					);
				},
			},


			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					if(!row?.original?.isSystem)
					return (
						<Fragment>
							<div className='button-group'>
							<PermissionWrapper subModuleID={subModuleList['Violation Policies']} type='isEdit'>


							<a onClick={() => handleOpen(row?.original)} className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-pen'></i>
								</a>
							</PermissionWrapper>

							<PermissionWrapper subModuleID={subModuleList['Violation Policies']} type='isDelete'>

							<a className='btn btn-sqaure btn-red modal-button' onClick={() => handleDeletModalOpen(row?.values?.vTypeID)}>
									<i className='fa-solid fa-trash'></i>
								</a>
							</PermissionWrapper>

						
							</div>
						</Fragment>
					);
					else
					return(
						<Fragment>
							<div className='button-group'>
								<PermissionWrapper subModuleID={subModuleList['Violation Policies']} type='isEdit'>
									<a onClick={() => handleOpen(row?.original)} className='btn btn-sqaure btn-white modal-button'>
										<i className='fa-solid fa-pen'></i>
									</a>
								</PermissionWrapper>
							</div>
						</Fragment>
					)
				},
				show: true,
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string; }

	};

	const fetchData = useCallback(({ pageSize, pageIndex, signal, OrderBy, filters }: IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_violation_policy(
				navigate,
				dispatch,
				{
					'filters': {
						'query': filters.query || '',
						OrderBy,
					},
					'pagination': {
						'pageNo': pageIndex,
						'limit': pageSize,
					},
				},
				signal
			);
		}
	}, [dispatch, navigate]);

	const handleOpen = (item: any) => {
		setdataDetail(item);
		setaddModal(true);
	};

	const handleDeletModalOpen = (id: any) => {
		setID(id);
		setdeleteModal(true);
	};
	const handleDelete = (id: any) => {
		setLoader(true)
		const sucess = (res: any) => {
			console.log(res);
			setLoader(false)
			SuccesToast(res?.response?.data);
			setdeleteModal(false);

			fetch_violation_policy(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 15,
				},
			});
		};
		const failed = (err: any) => {
			setLoader(false)
			FailedToast(err?.response)		;
			setdeleteModal(false);
		};

		delete_violation_policy(id, sucess, failed);
	};

	return (
		<>
			<CommonTable
				data={VIOLATION_POLICY_DATA?.VIOLATION_POLICY_LIST ?? []}
				columns={columns}
				pageCount={VIOLATION_POLICY_DATA?.VIOLATION_POLICY_PAGINATION?.totalCount ?? 0}
				fetchData={fetchData}
				limit={VIOLATION_POLICY_DATA?.VIOLATION_POLICY_PAGINATION?.limit ?? 1}
				page_no={VIOLATION_POLICY_DATA?.VIOLATION_POLICY_PAGINATION?.pageNo ?? 1}
				filters={VIOLATION_POLICY_DATA?.VIOLATION_POLICY_FILTER}
				loading={VIOLATION_POLICY_DATA?.VIOLATION_POLICY_LOADER}
				adjustSpacing={true}

			/>
			{addModal && <AddModal open={addModal} handleClose={addModalClose} Data={dataDetail} />}
			<CustomConfirm
				open={deleteModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						handleDelete(ID);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
		</>
	);
};

export default ListTable;
