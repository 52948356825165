/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import BarGraphCard from '../../../components/BarGraphCard';
import LineGraphCard from '../../../components/LineGraphCard';
import ProgressBarCard from '../../../components/ProgressBarCard';
import ReactSelect from 'react-select';
import { fetch_report_dwell_daily_bayType, fetch_report_dwell_daily_overall, fetch_report_dwell_daily_zone, fetch_report_dwell_distribution_bayType, fetch_report_dwell_distribution_time, fetch_report_dwell_distribution_zone, fetch_report_dwell_summary_bayType, fetch_report_dwell_summary_weekday, fetch_report_dwell_summary_zone } from '../../../store/action';
import { UPDATE_REPORTS_LOADER } from '../../../store/reportSlice';

type Props = {
    tabVal : number;
    setTabVal : any;
	measure : string;
	setMeasure : any;
	chartType : string;
	setChartType : any;
	isUnRecVeh : string; 
	setIsUnRecVeh : any;
	
	filename	: string;
	setFilename : any;
	fileNameSub : string;
	setFilenameSub 	: any;
    fileDateStr		: string;
	setFileDateStr 	: any;
	fileTimeStr 	: string;
	setFileTimeStr	: any;
    fileSectionStr  : string;
	setFileSectionStr : any
}


const valConvertTimeToReadable = (num:number) =>{ 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
//   console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
  return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes?`${minutes} min` : ''}` : `${minutes} min`}`;         
}

const valConvertTimeToReadablePerc = (num:number) =>{ 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);

	var hoursPerc = days && d >= 0 ? (d*0.04) : (num / 60) >= 0 ? (num / 60) : null
	var minutesPerc = hours ? ((d-hours)*(days?0.069:0.02)) : ((d * 60)/(days?0.069:1))
	var totalTimePerc = 0
	if(days)
		totalTimePerc += parseFloat(days.toFixed(2))
	if(hoursPerc)
		totalTimePerc += parseFloat(hoursPerc.toFixed(2))
	if(minutesPerc)
		totalTimePerc += parseFloat(minutesPerc.toFixed(2)) 

		console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes,'hrPerc',hoursPerc,'minPerc',minutesPerc,'totalPerc',totalTimePerc)
  return `${totalTimePerc}${days?` Days` : hours ? ` Hrs` : ` min`}`;         
}

const ReportDisplay = (props: Props) => {
    const REPORTSDATA = useAppSelector((state) => state?.reportData)
    const SITE_FLOOR = useAppSelector((state)=>state?.general)
	
    const dispatch  = useAppDispatch();
	useEffect(() => {
		dispatch(UPDATE_REPORTS_LOADER())
	}, [props.tabVal])
    useEffect(() => {
        dispatch(UPDATE_REPORTS_LOADER())

		if(REPORTSDATA?.REPORTS_SITE!==0 && SITE_FLOOR?.SITE_DDL[0]?.value){
			let postData = {
				DataType	  :	REPORTSDATA?.REPORTS_FILTER_MEASURE,
				SiteID      :   REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value,
				Todate      :   REPORTSDATA?.REPORTS_DATE?.length > 0 ? 
				dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
				FromDate    :   REPORTSDATA?.REPORTS_DATE?.length > 0 ? 
				dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),
				FloorID     :	0,   
				//REPORTSDATA?.REPORTS_FLOOR,
				FromTime    :   REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('HH:mm:ss') : '00:00:00',
				ToTime      :   REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('HH:mm:ss') :  '23:59:00',
				UnknownVehicle : parseInt(props?.isUnRecVeh)
			}
        if(props.tabVal===0){
          fetch_report_dwell_summary_weekday(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
          fetch_report_dwell_summary_bayType(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
          fetch_report_dwell_summary_zone(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        }
        else if(props.tabVal===1){
          fetch_report_dwell_daily_bayType(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
          fetch_report_dwell_daily_overall(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
          fetch_report_dwell_daily_zone(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        }
        else{
          fetch_report_dwell_distribution_time(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
          fetch_report_dwell_distribution_bayType(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
          fetch_report_dwell_distribution_zone(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        }
		// props.setMeasure(REPORTSDATA?.REPORTS_FILTER_MEASURE)
		// props.setChartType(REPORTSDATA?.REPORTS_FILTER_CHART)
		props?.setFilename(`${REPORTSDATA?.REPORTS_DATE?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('DD-MMM-YYYY') : dayjs().subtract(7,'day').format('DD-MMM-YYYY')} - ${
            REPORTSDATA?.REPORTS_DATE?.length > 0 ?  dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('DD-MMM-YYYY') : dayjs().format('DD-MMM-YYYY')} (${
                REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
                    REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'})`)
					props?.setFilenameSub(`(${SITE_FLOOR?.SITE_DDL?.find(el => el.value===REPORTSDATA?.REPORTS_SITE)?.label})`)

					props?.setFileDateStr(`${REPORTSDATA?.REPORTS_DATE?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('DD-MMM-YYYY') : dayjs().subtract(7,'day').format('DD-MMM-YYYY')} - ${
						REPORTSDATA?.REPORTS_DATE?.length > 0 ?  dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('DD-MMM-YYYY') : dayjs().format('DD-MMM-YYYY')}`)
					props?.setFileTimeStr(`${REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
							REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'}`)
					props?.setFileSectionStr(`${SITE_FLOOR?.SITE_DDL?.find(el => el.value===REPORTSDATA?.REPORTS_SITE)?.label}`)

        }
      }, [props.tabVal,SITE_FLOOR, REPORTSDATA?.REPORTS_FLOOR, REPORTSDATA?.REPORTS_SITE, props?.chartType, props?.measure, props?.isUnRecVeh])

	return (
		<div className='white-card data-clean'>
			<div className='custom-tabing-menu customTab-menu'>
				<ul>
					<li onClick={() =>{ props.setTabVal(0)
					dispatch(UPDATE_REPORTS_LOADER()) }}>
						<a className={props.tabVal === 0 ? 'active' : ''}  style={{ borderTopLeftRadius:'10px' }}>Summary</a>
					</li>
					<li onClick={() =>{ props.setTabVal(1)
					dispatch(UPDATE_REPORTS_LOADER()) }}>
						<a className={props.tabVal === 1 ? 'active' : ''}>Daily Dwell Time</a>
					</li>
					<li onClick={() =>{ props.setTabVal(2)
					dispatch(UPDATE_REPORTS_LOADER()) }}>
						<a className={props.tabVal === 2 ? 'active' : ''}>Dwell Time Distribution</a>
					</li>
				</ul>
				<ul className='customTab-menu-ul'>
				<li>						
						<div className="form-group">
							<ReactSelect
								options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								className={'common-select'}
								placeholder={'Vehicle Type'}
								isMulti={false}
								isClearable={false}
                        	    	value={[
										{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
									].find((el:any) => el.value===props.isUnRecVeh)??''}
                            		onChange={(val:any) =>{ props.setIsUnRecVeh(val?.value); }}
							/>
						</div>
					</li>
					{
						props.tabVal === 0 ?  
						<li>
						<div className="form-group">
							<ReactSelect
								options={[
									{ value : 'avg', label : 'Average' },
									{ value : 'min', label : 'Min' },
									{ value : 'max', label : 'Max' }
								]}
								className={'common-select'}
								placeholder={'Measure'}
								isMulti={false}
								isClearable={false}
                        	    	value={[
									{ value : 'avg', label : 'Average' },
									{ value : 'min', label : 'Min' },
									{ value : 'max', label : 'Max' }
								].find((el:any) => el.value===props.measure)??''}
                            		onChange={(val:any) =>{ props.setMeasure(val?.value); }}
							/>
						</div>
					</li>
						:
						<li>						
						<div className="form-group">
							<ReactSelect
								options={[
									{ value : 'bar', label : 'Bar Graph' },
									{ value : 'line', label : 'Line Graph' },
									{ value : 'area', label : 'Area Graph' }
								]}
								className={'common-select'}
								placeholder={'Chart Type'}
								isMulti={false}
								isClearable={true}
                        	    	value={[
										{ value : 'bar', label : 'Bar Graph' },
										{ value : 'line', label : 'Line Graph' },
										{ value : 'area', label : 'Area Graph' }
								].find((el:any) => el.value===props.chartType)??''}
                            		onChange={(val:any) =>{ props.setChartType(val?.value); }}
							/>
						</div>
						</li>
					}
				</ul>
			</div>

			<div className='tabing-row active' id='summary'>
				<div className='custom-tabing-body'>
					{props.tabVal === 0 ? (
						<div className='row msb-20'>
							<ProgressBarCard title={'Dwell Time Day of Week'} data={REPORTSDATA?.REPORTS_GRAPH_ONE_DATA}
							    loader={REPORTSDATA?.REPORTS_GRAPH_ONE_LOAD}
								fileName={`(${props?.filename})`}
								titleArr={['Dwell Time Day of Week',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
								csvColumns={REPORTSDATA?.REPORTS_GRAPH_ONE_CSV_COLS??null}
							/>
							<ProgressBarCard title={'Dwell Time By Bay-Type'} data={REPORTSDATA?.REPORTS_GRAPH_TWO_DATA}
							loader={REPORTSDATA?.REPORTS_GRAPH_TWO_LOAD} fileName={`(${props?.filename} ${props?.fileNameSub})`}
							titleArr={['Dwell Time By Bay-Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
							csvColumns={REPORTSDATA?.REPORTS_GRAPH_TWO_CSV_COLS??null}
							/>
							<ProgressBarCard title={'Dwell Time By Zone'} data={REPORTSDATA?.REPORTS_GRAPH_THREE_DATA} 
							loader={REPORTSDATA?.REPORTS_GRAPH_THREE_LOAD} fileName={`(${props?.filename} ${props?.fileNameSub})`}
							titleArr={['Dwell Time By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
							csvColumns={REPORTSDATA?.REPORTS_GRAPH_THREE_CSV_COLS??null}
							/>
						</div>
					) 
					: 
						props.chartType==='bar' ?
						(
							<div className='row msb-20'>
								<BarGraphCard 
									title={props.tabVal === 1 ? 'Daily Dwell Time Overall' : 'Dwell Time Distribution (By Time)'} 
									data={REPORTSDATA?.REPORTS_GRAPH_ONE_DATA} 
									loader={REPORTSDATA?.REPORTS_GRAPH_ONE_LOAD} 
									labelY={props.tabVal === 1 ? 'Time' : 'Dwell'} 
									unitY={props.tabVal === 1 ? '' : '%'}
									extraFunc={ props.tabVal !== 1 ? null : valConvertTimeToReadable} 
									yTickFormaterFn={ props.tabVal !== 1 ? null : valConvertTimeToReadable}
									fileName={`(${props?.filename})`}
									titleArr={props.tabVal === 1 ? ['Daily Dwell Time Overall',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]
										: ['Dwell Time Distribution (By Time)',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
										csvColumns={REPORTSDATA?.REPORTS_GRAPH_ONE_CSV_COLS??null}/>
								<BarGraphCard 
									title={props.tabVal === 1 ? 'Daily Dwell Time By Bay-Type' : 'Dwell Time Distribution By Bay-Type'} 
									data={REPORTSDATA?.REPORTS_GRAPH_TWO_DATA} 
									unitY={props.tabVal === 1 ? '' : '%'}
									loader={REPORTSDATA?.REPORTS_GRAPH_TWO_LOAD} 
									labelY={props.tabVal === 1 ? 'Time' : 'Dwell'} 
									fileName={`(${props?.filename} ${props?.fileNameSub})`}
								 	titleArr={props.tabVal === 1 ? ['Daily Dwell Time By Bay-Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]
										: ['Dwell Time Distribution By Bay-Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	extraFunc={ props.tabVal !== 1 ? null : valConvertTimeToReadable}  
									yTickFormaterFn={ props.tabVal !== 1 ? null : valConvertTimeToReadable}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_TWO_CSV_COLS??null}/>
								<BarGraphCard 
									title={props.tabVal === 1 ? 'Daily Dwell Time By Zone' : 'Dwell Time Distribution By Zone'} 
									data={REPORTSDATA?.REPORTS_GRAPH_THREE_DATA} 
									unitY={props.tabVal === 1 ? '' : '%'}
									loader={REPORTSDATA?.REPORTS_GRAPH_THREE_LOAD} 
									labelY={props.tabVal === 1 ? 'Time' : 'Dwell'} 
									fileName={`(${props?.filename} ${props?.fileNameSub})`}
								 	titleArr={props.tabVal === 1 ? ['Daily Dwell Time By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]
										: ['Dwell Time Distribution By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	extraFunc={ props.tabVal !== 1 ? null : valConvertTimeToReadable}  
									yTickFormaterFn={ props.tabVal !== 1 ? null : valConvertTimeToReadable}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_THREE_CSV_COLS??null}/>
							</div>
						)
						:	
						(
							<div className='row msb-20'>
								<LineGraphCard 
									title={props.tabVal === 1 ? 'Daily Dwell Time Overall' : 'Dwell Time Distribution (By Time)'} 
									data={REPORTSDATA?.REPORTS_GRAPH_ONE_DATA} lineType={'monotone'} 
									loader={REPORTSDATA?.REPORTS_GRAPH_ONE_LOAD} 
									labelY={props.tabVal === 1 ? 'Time' : 'Dwell'} 
									unitY={props.tabVal === 1 ? '' : '%'}
									extraFunc={ props.tabVal !== 1 ? null : valConvertTimeToReadable} 
									// yTickFormaterFn={valConvertTimeToReadablePerc}
									fileName={`(${props?.filename})`} 
									isAreaOpen={props.chartType==='area' ? true : false}
									titleArr={props.tabVal ===1 ? ['Daily Dwell Time Overall',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]
										: ['Dwell Time Distribution (By Time)',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
										csvColumns={REPORTSDATA?.REPORTS_GRAPH_ONE_CSV_COLS??null}/>
								<LineGraphCard 
									title={props.tabVal === 1 ? 'Daily Dwell Time By Bay-Type' : 'Dwell Time Distribution By Bay-Type'} 
									data={REPORTSDATA?.REPORTS_GRAPH_TWO_DATA} lineType={'monotone'} 
									loader={REPORTSDATA?.REPORTS_GRAPH_TWO_LOAD} 
									labelY={props.tabVal === 1 ? 'Time' : 'Dwell'} fileName={`(${props?.filename} ${props?.fileNameSub})`} 
									isAreaOpen={props.chartType==='area' ? true : false}
								 	titleArr={props.tabVal === 1 ? ['Daily Dwell Time By Bay-Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]
										: ['Dwell Time Distribution By Bay-Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	unitY={props.tabVal === 1 ? '' : '%'}
									extraFunc={ props.tabVal !== 1 ? null : valConvertTimeToReadable}  
								//	yTickFormaterFn={valConvertTimeToReadablePerc}
								
								csvColumns={REPORTSDATA?.REPORTS_GRAPH_TWO_CSV_COLS??null}/>
								<LineGraphCard 
									title={props.tabVal === 1 ? 'Daily Dwell Time By Zone' : 'Dwell Time Distribution By Zone'} 
									data={REPORTSDATA?.REPORTS_GRAPH_THREE_DATA} lineType={'monotone'} 
									loader={REPORTSDATA?.REPORTS_GRAPH_THREE_LOAD} 
									labelY={props.tabVal === 1 ? 'Time' : 'Dwell'} fileName={`(${props?.filename} ${props?.fileNameSub})`} 
									isAreaOpen={props.chartType==='area' ? true : false}
								 	titleArr={props.tabVal===1 ? ['Daily Dwell Time By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`] 
										: ['Dwell Time Distribution By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	unitY={props.tabVal === 1 ? '' : '%'}
									extraFunc={ props.tabVal !== 1 ? null : valConvertTimeToReadable}  
								//	yTickFormaterFn={valConvertTimeToReadablePerc}
								
								csvColumns={REPORTSDATA?.REPORTS_GRAPH_THREE_CSV_COLS??null}/>
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default ReportDisplay;