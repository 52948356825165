import React, { useEffect, useState } from 'react'
import { DatePicker } from "antd"
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { UPDATE_REPORTS_FILTER, UPDATE_REPORTS_LOADER } from '../../../store/reportSlice';
import { fetch_report_entry_exit } from '../../../store/action';
import ReactSelect from 'react-select';
import { fetch_floorLevel } from '../../../../../common/general/action';
import moment from 'moment';
const { RangePicker } = DatePicker;

type Props = {
	isUnRecVeh : string; 
	setIsUnRecVeh : any;
}

const FilterBar = (props: Props) => {
	const SITE_FLOOR = useAppSelector((state)=>state?.general)
	const REPORTSDATA = useAppSelector((state) => state?.reportData)
	
	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	  };

	const dispatch  = useAppDispatch();
	useEffect(() => {
		setTimeRange(REPORTSDATA?.REPORTS_TIME?.length>0 ? REPORTSDATA?.REPORTS_TIME : [moment('00:00:00','HH:mm:ss'), moment('23:59:59','HH:mm:ss')])
		setDateRange(REPORTSDATA?.REPORTS_DATE?.length>0 ? REPORTSDATA?.REPORTS_DATE : [moment(new Date(),'YYYY-MM-DD').subtract(7,'day'),moment(new Date(),'YYYY-MM-DD')])
		setSiteIdFx(REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value)
	}, [REPORTSDATA, SITE_FLOOR?.SITE_DDL])

	const [dateRange, setDateRange] 	= useState<any>([])
	const [timeRange, setTimeRange] 	= useState<any>([])
	const [siteIdFx, setSiteIdFx]         = useState<any>(REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value )

	
	const filterSearch = () =>{
		dispatch(UPDATE_REPORTS_LOADER())
        let postData = {
          SiteID      :   siteIdFx,
          Todate      :   dayjs(dateRange?dateRange[1]:'').format('YYYY-MM-DD'),
          FromDate    :   dayjs(dateRange?dateRange[0]:'').format('YYYY-MM-DD'),
          FromTime    :   timeRange && moment(timeRange[0],['HH:mm:ss','HH:mm a','hh:mm a'],true).isValid() ? moment(timeRange[0],['HH:mm:ss','HH:mm a','hh:mm a'],true).format('HH:mm:ss'):'00:00:00',
          ToTime      :   timeRange && moment(timeRange[1],['HH:mm:ss','HH:mm a','hh:mm a'],true).isValid() ? moment(timeRange[1],['HH:mm:ss','HH:mm a','hh:mm a'],true).format('HH:mm:ss') :  '23:59:00',
		  UnknownVehicle : parseInt(props?.isUnRecVeh)
      }
        fetch_report_entry_exit(postData, dispatch)
		dispatch(UPDATE_REPORTS_FILTER({
			dates:	dateRange,
			times:  timeRange,
			floor:  REPORTSDATA?.REPORTS_FLOOR, 
			site:   siteIdFx,
			unrecveh : props?.isUnRecVeh
		  }))
	}


  return (
    <div className="white-card data-clean mb-30">
			<div className="search-filter-row mb-0">
			<ul className='justify-space-between'>
							<li>
								<a  className="active pointerDefaultSet"><i className="fa-solid fa-magnifying-glass"></i>  Quick Search</a>
							</li>
							{	dateRange && timeRange &&
								moment(dateRange[0]).isValid() && moment(dateRange[1]).isValid() &&
								moment(timeRange[0]).isValid() && moment(timeRange[1]).isValid() &&
							<li className='reportsAndDashUserHelp'>
								Showing reports of vehicles entered, exited and was occupied between {moment(timeRange[0]).format('hh:mm a')} and {moment(timeRange[1]).format('hh:mm a')} on dates
								from {moment(dateRange[0]).format('DD-MMM-YYYY')} to {moment(dateRange[1]).format('DD-MMM-YYYY')}
							</li>
							}
						</ul>
				<div className="filter-block" id="quick-search">
						<div className="row">
							<div className="col-3">
								<div className="form-group">
									<RangePicker
										picker="date"
										allowClear={false}
										allowEmpty={[false,false]}
										showNow
										className='form-control'
										placeholder={['Start Date',' End Date']}
										inputReadOnly
										disabledDate={disabledDate}
										value={dateRange}
										format={'DD-MMM-YYYY'}
										onChange={(dates:any) =>{
											setDateRange(dates)
										}}
									/>
								</div>
							</div>
						 		 
				 			<div className="col-3">
								<div className="form-group">
									<RangePicker
										picker="time"
										allowClear={false}
										allowEmpty={[true,true]}
										showNow
										className='form-control'
										placeholder={['Start Time',' End Time']}
										inputReadOnly
										value={timeRange}
										format={'hh:mm a'}
										onChange={(dates:any) =>{
											setTimeRange(dates)
										}}
									/>
								</div>
							</div>

							<div className="col-3">
								<div className="form-group">
									<ReactSelect
										options={SITE_FLOOR.SITE_DDL}
										className={'common-select'}
										placeholder={'Site'}
										defaultValue={SITE_FLOOR?.SITE_DDL && SITE_FLOOR?.SITE_DDL[0]?.value}
										isMulti={false}
										isClearable={false}
                        	    		value={SITE_FLOOR.SITE_DDL.find((c: { value: number; }) => c.value === parseInt(siteIdFx))}
                            			onChange={(val) =>{ setSiteIdFx(val?.value); fetch_floorLevel(val?.value) }}
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group">
								<ReactSelect
								options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								className={'common-select'}
								placeholder={'Vehicle Type'}
								isMulti={false}
								isClearable={false}
                        	    	value={[
										{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
									].find((el:any) => el.value===props.isUnRecVeh)??''}
                            		onChange={(val:any) =>{ props.setIsUnRecVeh(val?.value); }}
							/>
								</div>
							</div>
						
					

							<div className="col-3">
								<div className="form-group" onClick={()=>{
									filterSearch()
								}}>
									<button className="form-control blue-btn">Generate Report</button>
								</div>
							</div>
						 
						</div>
				</div>

			</div>

		</div>
  )
}

export default FilterBar