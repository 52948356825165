type Props = {
    open: boolean;
    title?: string;
    description?: string;
    handleClose: () => void;
    ConfirmFx?:(id:any)=> void
    id?:any,
    children:React.ReactNode

}

const DeleteCustomConfirm = (props: Props) => {
    return (
        <div className={props.open ? `modal-popup delete-not text-center modal-open` : 'modal-popup delete-not text-center'} id="delete-note">
            <div className="modal-main-wrap">
                <div className="modal-popup-wrapper">
                    <div className="modal-header">
                        <div className="trash-icon">
                            <i className="fas fa-trash"></i>
                        </div>
                        <h3>Delete {props.title} ?</h3>
                        <a className="close-modal close-modal-button" onClick={props.handleClose}></a>
                    </div>

                    <div className="modal-body">

                        <form>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4>{props.description}</h4>
                                </div>
                                <div className="col-12">
                                    <div className="modal-footer  dflex justify-content-center">
                                        <button type="button" className="btn btn-outline-blue close-modal-button" onClick={props.handleClose}>Cancel</button>
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteCustomConfirm