import { useCallback, useState } from 'react'
import CsvDownload from 'react-csv-downloader'
import { Area, CartesianGrid, ComposedChart, Label, Legend, Line, LineChart,
    ResponsiveContainer, 
    Tooltip, XAxis, YAxis } from 'recharts'
import GraphLoader from '../../../common/components/GraphLoader'
import { ExportMultipleChartsToPdf } from './PdfExporter'
import { useAppSelector } from '../../../config/hooks'
import { COLOR_FX } from '../../../utils/Data'
import { useCurrentPng } from 'recharts-to-png'
import PdfExporterModal from './pdfExporterModal'
import { ZoneColors } from '../../../utils/zoneColorCodes'
import { exportDataToExcel } from '../../../common/components/ExcelFileExport'

type Props = {
    title: string,
    data: any,
    loader?:boolean,
    labelY?:string,
    labelX?:string,
    unitY?:string,
    unitX?:string,
    lineType?: 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter',
    fileName?:string,
    extraFunc?:any,
    numberOnlyInY?:boolean,
    yTickFormaterFn?:any,
    titleArr?:any,
    isAreaOpen?:boolean,
    csvColumns?:any
}

// const colorPallet = COLORS
//['#1947ba', '#ea0029', '#00953b', '#f39c12', '#8e44ad', '#f368e0', '#222f3e', '#84817a', '#33d9b2', '#f9d71b']  
const legendDiv = {
    fontWeight : 600,
    borderRadius : '5px',
    padding: '1%',
    backgroundColor: 'rgba(0,0,0,0.05)',
    left:0,
    width:'100%',
    alignItems:'center'
}

const LineGraphCard = (props: Props) => {
    // let colV = 0;
    // let colV1 = 0;
    // let colV2 = 0;
    const [openExport, setOpenExport] = useState('')
    const [opacitySet, setOpacitySet] = useState(null)
    const [openModal, setOpenModal] = useState(false)

  const handleAreaDownload = () =>{
    setOpenModal(true)
  }


    const handleMouseEnter = (o:any) => {
        const { dataKey } = o    
        if(!openModal)
          setOpacitySet(dataKey);
      };
    
    const handleMouseLeave = () => {
      if(!openModal)
        setOpacitySet(null)
      };     
      // console.log(props?.data,props?.data?.toolTipLabel)

    const CustomToolTip = ({ active, payload, label }:any) =>{
      

      if(active && payload && payload?.length){
        console.log('PROPS=>',active,'payload=>',payload,'label=>',label)

        const LabelFormatter = () =>{ 
          //console.log('labelpayload',payload); 
         let v:any = ''
         if(payload && payload?.length>0 ? payload[0].payload?.toolTipLabel && payload[0].payload?.toolTipLabel?.length>0 ? true : false : false)
            v = <p>{label} <br/>{payload[0].payload?.toolTipLabel?.map((el:any)=>{
              return <>{el}<br/></>
            })}</p>
          else
           v = label
         return v
        }

        const valueFormatter = (val?:any,obj?:any) =>{
          let v:any = ''
            console.log(payload,obj,obj  && obj?.payload[`${obj?.name}-toolTipLabel`])
            if(obj  && obj?.payload[`${obj?.name}-toolTipLabel`] ? obj?.payload[`${obj?.name}-toolTipLabel`] : false)
              v = <>{obj?.payload[`${obj.name}-toolTipLabel`]}<span
               style={{ whiteSpace : 'nowrap' }}>{` (${props?.extraFunc ? props?.extraFunc(val) : val} ${props?.unitY??''})`}</span></>
            else v = props?.extraFunc ? `${props?.extraFunc(val)} ${props?.unitY??''}` : `${val} ${props?.unitY??''}`
            console.log('VLA',v)
            return v
        }


        return (
          <div className='customToolTipBox'>
            <div className='customToolTipLabel'>
              {LabelFormatter()}
            </div>
            <div className='customToolTipContents'>
              {
                payload && payload?.length>0 ?
                  payload?.filter((em:any)=> em?.type!=='none')?.map((el:any)=>{
                    console.log('TOOLTIPLABELMAP',el)
                    if(!el?.dataKey?.includes('csvlabel'))
                    return (
                      <div  style={{ color: `${el?.color}`, fontSize:'5px !important'}}>
                        <p>{`${el?.dataKey} : `}{valueFormatter(el?.value,el)}</p>
                      </div>
                    )
                  })
                  : null
              }
            </div>
          </div>
        )
      }else 
        return null
    }


    const ChartObject = () =>{
      let colV = 0;
      return (
        <>
                    {/* <LineChart
                      data={props.data}
                      margin={{
                        top: 5,
                        right: 20,
                        left: props?.extraFunc? 20 : -10,
                        bottom: 5,
                      }}
                    > */}
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"                        
                        padding={{ left: 20, right:20}} 
                        allowDataOverflow={true} 
                        interval={props.data && props.data.length > 10 ? 2 : 0}
                        style={{textTransform :"capitalize" , fontWeight:700 , fontSize:"9.5px" }} />
                      <YAxis  padding={{ top:10, bottom: 10 }}    
                        unit={props?.unitY}  
                        tickFormatter={props?.yTickFormaterFn??props?.extraFunc??null}
                        tickSize={1}
                        allowDecimals={props?.numberOnlyInY??true}
                        style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}>
                          <Label value={props?.labelY} angle={-90} fontWeight={600} fontSize={"11.5px"} offset={props?.extraFunc?5:-20} position={'left'} />
                      </YAxis>
                      {
                        openModal ? null 
                        :
                        <Tooltip isAnimationActive={false}
                        // wrapperClassName='toolTipCustomClass'
                        allowEscapeViewBox={{ x:false, y:false }}
                        // position={{ y: props?.data?.length && Object.keys(props.data[0]) && 
                        //   Object.keys(props.data[0])?.length/2 > 6 ? (12 - Object.keys(props.data[0])?.length)*15 : 0 }}
                       
                       formatter={(value:any, _:any, payload:any)=>  
                        {

                          let v:any = ''
                          console.log('TOOLTIPPAYLOAD=>',payload)
                          if(payload  && payload.payload[`${payload.name}-toolTipLabel`] ? payload.payload[`${payload.name}-toolTipLabel`] : false)
                            v = <>{`${props?.extraFunc ? props?.extraFunc(value) : value} ${props?.unitY??''}`}<p>{payload.payload[`${payload.name}-toolTipLabel`]}</p></>
                          else v = props?.extraFunc ? `${props?.extraFunc(value)} ${props?.unitY??''}` : `${value} ${props?.unitY??''}`
                          
                          return v
                          
                        }
                        }
                       labelFormatter={(label:any, payload) =>{ 
                        //console.log('labelpayload',payload); 
                       let v:any = ''
                       console.log('labelFormatterPAYLOAD=>',payload)
                       if(payload && payload?.length>0 ? payload[0].payload?.toolTipLabel && payload[0].payload?.toolTipLabel?.length>0 ? true : false : false)
                          v = <p>{label} <br/>{payload[0].payload?.toolTipLabel?.map((el:any)=>{
                            return <>{el}<br/></>
                          })}</p>
                        else
                         v = label
                       return v
                      }}
                      content={<CustomToolTip/>}
                       />
                      
                      }
                      <Legend wrapperStyle={legendDiv} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  
                      style={{ textTransform :"uppercase"}}
                      />
                      {
                        props.data && Object.keys(props.data[0]) ? Object.keys(props.data[0]).map((el, idx) =>{ 
                            if(el!=='name' && el!=='toolTipLabel' && !el?.includes('-toolTipLabel') && !el?.includes('csvlabel')){
                            return (
                              <>
                                {
                                  props.isAreaOpen && props.isAreaOpen ?
                                    <>
                                      <defs>
                                        <linearGradient id={`colorLine-${idx}`} x1="0" y1="0" x2="0" y2="1">
                                          <stop offset="5%" stopColor={ZoneColors(el)??COLOR_FX(colV)} stopOpacity={0.4}/>
                                          <stop offset="95%" stopColor={ZoneColors(el)??COLOR_FX(colV)} stopOpacity={0}/>
                                        </linearGradient> 
                                      </defs>
                                      <Area type='monotone' dataKey={el} 
                                        legendType='none'
                                        tooltipType='none'
                                        strokeOpacity={opacitySet ? opacitySet===el ? 1 : 0 : 1 }
                                        fillOpacity={opacitySet ? opacitySet===el ? 1 : 0 : 1 } 
                                        fill={ ZoneColors(el) ? ZoneColors(el,'0.3')??`url(#colorLine-${idx})` : `url(#colorLine-${idx})`}
                                        stroke={ZoneColors(el)??COLOR_FX(colV)} 
                                      />
                                    </>
                                  :
                                    null
                                }
                                <Line type={props.lineType} dataKey={el} 
                                strokeWidth={2}
                                    strokeOpacity={opacitySet ? opacitySet===el ? 1 : 0.1 : 1 }
                                    stroke={ZoneColors(el)??COLOR_FX(colV++)} />
                              </>
                            )}
                            else return null
                        }) 
                        :
                        null
                      }
                    </>
      )
    }

    return(
        <div className={`col-6 ${!props?.loader&&props?.data&&props?.data?.length===0 ? 'disableAndOpacity2' : ''}`}>          
        <div className="card-second">
            <div className="card-header">
                <h3>{props.title}</h3>
                    <div className='btn btn-sqaure btn-secondary btn-secondary-hover openMiniModalLine' 
                      onClick={() =>{ setOpenExport(openExport===props.title ? '' : props.title) }}>
					          	<i className='fa-solid fa-download' ></i>
					          	{	openExport===props.title ?
					          	<div className='miniModalOpenBox'>
					          		<div className='miniBoxModalContent'>
					          			<div onClick={handleAreaDownload} role='button'>
                            <button>
                              <p>Export PDF</p>  
                            </button></div>

                            <div role="button">
									<button onClick={()=>{ 
										return exportDataToExcel(props?.data,`LineChart-${props.title} ${props?.fileName}`,
                    `${props.title} ${props?.fileName?.replaceAll('\n',' ')??''}`,
                    Object.keys(props.data[0])?.map((el:any) => {
                      return { "id" : el,
                      "displayName" : props?.csvColumns ? props?.csvColumns[`${el.toUpperCase()}`]??el.toUpperCase() : el.toUpperCase(),
                      "fn" : props?.csvColumns[`${el.toUpperCase()}_CSVLABEL_fn`]??undefined
                      }
                    })
										) }} >
										<p>Export CSV</p>
									</button>
								</div>

					          			{/* <CsvDownload  
									          filename={`LineChart-${props.title} ${props?.fileName}`}
									          extension=".csv"
									          text='Export CSV'
									          columns={
									          	Object.keys(props.data[0])?.map((el:any) => {
									          		return { "id" : el,
                                "displayName" : props?.csvColumns ? props?.csvColumns[`${el.toUpperCase()}`]??el.toUpperCase() : el.toUpperCase()  }
									          	})
									          }
									          datas={props.data}
                            title={`${props.title} ${props?.fileName?.replaceAll('\n',' ')??''}`}
								          >
                            <button><p>Export CSV</p></button>  
                          </CsvDownload>	 */}
							          </div>
						          </div>
						          :	null
						          }
					          </div>
            </div>
            <div className="card-body" style={{ height: '55vh' }}>
            {
                    props.loader ?    <GraphLoader/>  :   null
                }
                
              {   !props.loader &&
                  props.data && props.data?.length>0 ?
                <ResponsiveContainer id={`LineChartId-${props.title}`} >
                    <ComposedChart
                      data={props.data}
                      margin={{
                        top: 5,
                        right: 20,
                        left: props?.extraFunc? 20 : -10,
                        bottom: 5,
                      }}
                    >
                      {ChartObject()}
                    </ComposedChart>
                    
                </ResponsiveContainer>  
                : 
                props.loader ? null :
                <div style={{ width:'100%', height:'100%', display:'grid', placeItems:'center' }}>
                    <p>No data</p>
                </div>   
              }                                                                       
            </div>
        </div>
        {
          openModal && 
          <PdfExporterModal
            handleClose={()=>setOpenModal(false)}
            children={ChartObject}
            data={props?.data}
            extraFunc={props?.extraFunc}
            title={props?.title}
            titleArr={props?.titleArr}
          />
        }
    </div>
    
    )
}

export default LineGraphCard