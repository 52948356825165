import React from 'react'
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import FindMyCarResult from '../findMyCarResult'
type Props = {
  selectedCar: any
  open:boolean;
  handleClose:()=> void
}

const ViewModal = (props: Props) => {
  return (
    <ModalLayout big={true}  open={props.open} handleClose={props.handleClose} title={props?.selectedCar?.plateNumber} subtitle={`#${props?.selectedCar?.visit_ID}`}>
				<FindMyCarResult selectedCar={props?.selectedCar}/>
    </ModalLayout>
  )
}

export default ViewModal