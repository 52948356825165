import React, { useEffect, useState } from 'react';
import { fetch_turover_overall_count } from '../../store/action';
import SimpleBox from '../../../../common/components/graph_components/SimpleBox'
import moment from 'moment';
import { useAppSelector } from '../../../../config/hooks';

type Props = {}

const TurnoverOverall = (props: Props) => {

  const [data, setdata] = useState<any>();
	// const [date, setdate] = useState<any>([moment().subtract(1, 'day').startOf('day'), moment().endOf('day')]);
	const [date, setdate] = useState<any>(moment());

	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    let postData = {
  
      FromDate: date.format("YYYY-MM-DD"),
			ToDate: date.format("YYYY-MM-DD"),
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,

    };

    fetch_turover_overall_count(postData, signal)?.then((res) => {
      if (res?.statusCode < 400) {
        let data = res?.response?.totalCount;
        setdata(data);
      }
    });

    return () => {
      controller.abort();
    };
  }, [DASHBOARD_DATA?.SELECTED_SITE?.value, date]);

  return (
    <SimpleBox title='Turnover Overall Today' value={data ?? 0} className={'dark-blue-bg text-white'} />
  )
}

export default TurnoverOverall