


import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../config/hooks';
import {  fetch_dwell_time_overall } from '../../store/action';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import { DatePicker } from 'antd';
import RLineChart from '../LineChart';
import moment from 'moment';
import { addPluralIfMore } from '../../../../utils/utilFunctions';
import ReactSelect from 'react-select';
// import dayjs from 'dayjs';
// import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

type Props = {
	name: string;
};



// const rangePresets: any = [
// 	{ label: 'Last 7 Days', value: [moment().add(-7, 'd'), moment()] },
// 	{ label: 'Last 14 Days', value: [moment().add(-14, 'd'), moment()] },
// 	{ label: 'Last 30 Days', value: [moment().add(-30, 'd'), moment()] },
// 	{ label: 'Last 90 Days', value: [moment().add(-90, 'd'), moment()] },
//   ];

const DwellTimeOverall = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);
	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [loading, setloading] = useState(false)
	const [isUnRecVeh, setIsUnRecVeh] = useState('0')


	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);

	const valConvertTimeToReadable = (num:number) =>{ 
		var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
		var d = (num / 60) % 24
		var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
		var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
	//   console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
	  return `${days ? `${days} Day${addPluralIfMore(days)}` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
	}
	
const valConvertTimeToReadablePerc = (num:number) =>{ 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);

	var hoursPerc = days && d >= 0 ? Math.ceil(d*0.04) : Math.ceil(num / 60) >= 0 ? Math.ceil(num / 60) : null
	var minutesPerc = hours ? Math.ceil((d-hours)*(days?0.069:0.02)) : Math.ceil((d * 60)/(days?0.069:1))
	var totalTimePerc = 0
	if(days)
		totalTimePerc += days
	else if(hoursPerc)
		totalTimePerc += hoursPerc
	else if(minutesPerc)
		totalTimePerc += minutesPerc

		console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes,'hrPerc',hoursPerc,'minPerc',minutesPerc,'totalPerc',totalTimePerc)
  return `${totalTimePerc}${days?` Days` : hours ? ` Hrs` : ` min`}`;         
}


	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			UnknownVehicle : isUnRecVeh
		};

		if (floor?.levelID) {
			setloading(true)

			fetch_dwell_time_overall(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					let formattedData = data.map((item: any) => ({
						date: item?.parkingDate ? moment(item?.parkingDate).format('YYYY-MM-DD') : null,
                        Dwell : Math.round(item?.avrg),
						...item,
					}));
					setdata(formattedData);

				}

				setTimeout(() => {
					setloading(false)
				}, 1500);
			});
		}

		return () => {
			controller.abort();
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value, date, isUnRecVeh]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>
						Average Dwell {isUnRecVeh==='2' ? '(Registered Vehicles Only)' : isUnRecVeh==='1' ? '(Unregistered Vehicles Only)' : ''}
					</div>
					<div>
						<div style={{ fontSize :"12px"}}>
							{
								date[0].format('DD MMM YYYY hh:mm A') } - { date[1].format('DD MMM YYYY  hh:mm A') 
							}
						</div>
						<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose} >
							{/* <div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Type</label>
								<Select
									className='common-select-grey'
									options={typeOption}
									onChange={(_: any ,option:any) => settype(option)}
									value={type?.value}
									placeholder='type'
									style={{ width:"100%"}}
									size='large'

								/>
							</div> */}	
							<div className='form-group select-input full-label' style={{ marginBottom : '1px' }}>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker
									size='large'
									onChange={(date: any) => setdate(date)}
									value={date}
									allowClear={false}
								/>
							</div>
							
							
							<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Vehicle Type</label>
									<ReactSelect
										className='common-select-grey'
										options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								onChange={(val:any) =>{ setIsUnRecVeh(val?.value); }}
										value={
											[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]?.find((el:any) => el.value===isUnRecVeh) ?? ''
										}
										placeholder={'Vehicle Type'}
									/>
								</div>
						
						</DashboardFilterWrapper>
					</div>
				</div>

				<div className='ItemLayout__Content'>
					<RLineChart data={data} xAxisName={'date'} yAxisName='Dwell' xTickCount={1} Yunit={"min"} extraFunc={valConvertTimeToReadable}
					yTickFormaterFn={valConvertTimeToReadablePerc}
					/>
				</div>
			</div>
		</>
	);
};

export default DwellTimeOverall;
