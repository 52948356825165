import React from 'react'

type Props = {
    title : string,
    value : number | string
    className?: string
    color: string

}


const BoxWithBgClr = (props: Props) => {
    return (
     <div className={`${props.color} text-white white-card mb-20 dflex align-items-center w-100 justify-space-between`} style={{ height:"100%"}}>
            <p className="mb-0" style={{ maxWidth:"40%"}}>{props?.title}</p>
            <h3>{props?.value}</h3>
        </div>
   
    )
}

export default BoxWithBgClr