/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { deleteAll_web_notification, fetch_web_notifications, fetch_web_notifications_popUp } from '../../general/action';
import { UPDATE_LAST_NOTIFICATIONS } from '../../general/generalSlice';
import NotificationModal from './NotificationModal';
import { showToast } from './ToastNotification';
import { useNavigate } from 'react-router-dom';
import { SuccesToast } from '../../../utils/utilFunctions';

type Props = {
	dropdownRef: any;
};

const NotificationPopup = (props: Props) => {
	let dispatch = useAppDispatch();

	let navigate = useNavigate();

	const [viewModal, setviewModal] = useState(false);

	const viewModalOpen = () => {
		setviewModal(true);
	};

	const viewModalClose = () => setviewModal(false);

	const [data, setdata] = useState([]);
	const [notifyCount, setNotifyCount] = useState(0);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		let postData = {
			'filters': {
				'query': '',
				'orderby': 'createdOn desc  ',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 10,
			},
		};

		fetch_web_notifications_popUp(postData, signal)?.then((res) => {
			if (res?.statusCode === 200) {
				setdata(res?.response?.data ?? []);
				setNotifyCount(res?.response?.totalRecordsUnRead ?? 0);
			}
		});
		let timer = setInterval(() => {
			fetch_web_notifications_popUp(postData, signal)?.then((res) => {
				if (res?.statusCode === 200) {
					setdata(res?.response?.data ?? []);
					setNotifyCount(res?.response?.totalRecordsUnRead ?? 0);
				}
			});
		}, 120000);

		return () => {
			controller.abort();
			clearInterval(timer);
		};
	}, [dispatch]);

	// const notificationData = useAppSelector((state) => state?.general?.NOTIFICATIONS_LIST);
	const lastNotification: any = useAppSelector((state) => state?.general?.NOTIFICATIONS_LAST);

	useEffect(() => {
		if (data) {
			let lastRecord: any = data[0] ?? null;
			if (lastNotification && lastRecord?.wNotificationID !== lastNotification?.wNotificationID) {
				if (lastRecord?.nMessages) {
					showToast(lastRecord);
				}
				dispatch(UPDATE_LAST_NOTIFICATIONS({ data: lastRecord, totalRecordsUnRead: notifyCount }));
			}

			if (lastNotification === null) {
				dispatch(UPDATE_LAST_NOTIFICATIONS({ data: lastRecord, totalRecordsUnRead: notifyCount }));
			}
		}

		return () => {};
	}, [dispatch, lastNotification, data, notifyCount]);
	const NOTIFICATION = useAppSelector((state) => state?.general);

	const deleteAllNotification = () => {
		const success = (res: any) => {
			SuccesToast(res?.response?.data);
			setdata([]);
			setNotifyCount(0);
			setviewModal(false);
			fetch_web_notifications(dispatch, {
				'filters': {
					'query': '',
					'orderby': 'createdOn desc  ',
				},

				'pagination': {
					'pageNo': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.pageNo,
					'limit': NOTIFICATION?.NOTIFICATIONS_PAGINATION?.limit,
				},
			});
		};
		deleteAll_web_notification(success);
	};

	return (
		<>
			<div className='notification-popup new-scrollbar' ref={props?.dropdownRef} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
				<div className='notification-bar-header' style={{ position: 'sticky', top: 0, zIndex: 2, background: 'white' }}>
					<h2 className='border-title'>Notification</h2>
					<div style={{ fontSize: '14px' }}>
						<a onClick={() => deleteAllNotification()}>Clear All</a> |<a onClick={viewModalOpen}>View All</a>
					</div>
				</div>

				<ul>
					{data &&
						data.map((item: any, key: number) => {
							return (
								<Fragment key={key}>
									<li
										onClick={() => {
											if (item?.typeID === 1) {
												navigate('/parking-requests');
											}else if(item?.typeID === 3){
												navigate('/security/web-users-requests');
											}
										}}
										style={{ cursor: 'pointer' }}>
										<div className='icon'>
											<img src='assets/images/icons/violations.svg' alt='icon' />
										</div>
										<div className='dflex flex-col '>
											<strong>{item?.title}</strong>
											<p style={{ lineHeight: '1.5' }}>{item?.nMessages}</p>
										</div>
									</li>
								</Fragment>
							);
						})}
				</ul>
			</div>
			{viewModal && <NotificationModal open={viewModal} handleClose={viewModalClose} />}
		</>
	);
};

export default NotificationPopup;
