/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { delete_visitor_vehicle, fetch_visitor_detail } from '../../store/action';
import CustomConfirm from '../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { FormatDate, FormatTime } from '../../../../utils/utilFunctions';
import AddVehicleModal from './addVehicleModal';
import ImageView from '../../../../common/components/ImageView/ImageView';
import nodatafound from '../../../../assets/images/common/nodatafound.png';
import { subModuleList } from '../../../../config/subModuleList';
import Select from 'react-select';


type Props = {
	handleClose: () => void;
	Data?: any;
};



const AddVehicle = (props: Props) => {

	const [vehicles, setVehicles] = useState([]);
	const [allocatedVehicles, setAllocatedVehicles] = useState([]);
	const [loading, setloading] = useState(false);

	const [addVehicleModal, setaddVehicleModal] = useState(false);

	const [nodata, setnodata] = useState(false);




	
	const [triggerUseEffect, settriggerUseEffect] = useState(false)

	const TriggerApi = () => { 
		settriggerUseEffect(!triggerUseEffect)
	 }

	 console.log(props?.Data)

	useEffect(() => {
		if (props?.Data?.visitorID) {
			setloading(true)
			fetch_visitor_detail(props?.Data?.visitorID)?.then((res) => {
				let vehicleDetails = res?.response?.data?.vehicles ?? [];

				if(vehicleDetails.length === 0){
					setnodata(true)
				}else{
					setnodata(false)

				}
				let allocatedvehicles: any = [];
				let vehicles: any = [];
				setloading(false)
				vehicleDetails.forEach((item: any) => {
					if (item?.allocationDetails && item?.allocationDetails?.length!==0) {
						allocatedvehicles.push(item);
					} else {
						vehicles.push(item);
					}
				});
				setAllocatedVehicles(allocatedvehicles);
				setVehicles(vehicles);


			});
		}
	}, [props?.Data,triggerUseEffect]);

	return (
		<Fragment>


		
			<div className='form-class'>
			<div className="modal-body-content">

				
				<div className='dflex align-items-center justify-space-between p-5'>
					<h2 className='border-title'>Vehicles</h2>
					<div className='dflex gap-10'>
						<PermissionWrapper subModuleID={subModuleList.Visitors} type='isCreate'>
							<div className='btn btn-secondary small-btn ' onClick={() => setaddVehicleModal(true)}>
								Add new vehicle
							</div>
						</PermissionWrapper>
					</div>
				</div>
				<div className='modal-body-content' style={{ minHeight: '55vh', maxHeight: '55vh', padding: '0px 5px' }}>
					<div className='modal-body '>
						<div className='form-w-label modal-form'>
							<Fragment>
								
								{/* <AllocatedVehicle allocatedVehicles={allocatedVehicles} /> */}
								
								{
										allocatedVehicles.map((el: any, k: number) => {

											return (
												<Fragment key={k}>
	{
														el?.allocationDetails && el?.allocationDetails.map((item:any , key:number)=>{
															return(
																<AllocatedVehicle allocatedVehicles={el} k={key} selected={item} />
															)
														})
													}												</Fragment>
											);
										})
									}
								<UnAllocatedVehicle vehicles={vehicles} TriggerApi={TriggerApi} />

								{
									loading  && [...Array(4)].map((item)=>{
										return(<Fragment key={item}>
										<AccordionSkeleton />
										</Fragment>)
									})
								}
								{
									nodata &&  !loading  && <>
									<div className='dflex align-items-center justify-content-center'>
														<img src={nodatafound} alt="" style={{ width: "40%", objectFit: "contain" }} />
													</div>
									</>
								}
								
							</Fragment>
						</div>
					</div>
				</div>
				
			</div>
			<div className='modal-footer'>
					<div className='btn btn-outline-blue close-modal-button' onClick={props?.handleClose}>
						close
					</div>
				</div>
			</div>

			{<AddVehicleModal open={addVehicleModal} handleClose={() => setaddVehicleModal(false)} visitorID={props?.Data?.visitorID} TriggerApi={TriggerApi}/>}
		</Fragment>
	);
};

export default AddVehicle;

function AllocatedVehicle({ allocatedVehicles , k  ,selected}: any) {

	// const [selected, setselected] = useState(allocatedVehicles?.allocationDetails[0]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', }}>
			{/* {allocatedVehicles.map((el: any, k: number) => {
				return ( */}
					<Fragment>
						<Accordion
							defaultExpanded={k === 0 ? true : false}
							sx={{
								boxShadow: 'none',
								background: 'transparent',
								border: '1px solid var(--lightgrey)',
								marginBottom:"5px",
								'&:before': {
									display: 'none',
								}

							}}>
							<AccordionSummary
								expandIcon={<i className='fa fa-chevron-down' />}
								aria-controls='panel1bh-content'
								sx={{
									margin: 0,
									// height: '20px',
									// borderBottom: '1px solid var(--lightgrey)',
									border: 'none',
								}}>
								<div className='row w-100'>
									<div className='dflex justify-space-between w-100 align-items-center '>
										<div className='col-8 dflex gap-5 align-items-center' >
											<div onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>
											<ImageView className='avatar-round' src={allocatedVehicles?.lastTransactionImage} />

											</div>
											<h2 className='border-title ml-5'>
												{`${allocatedVehicles?.plateNumber} | `}
												<i>
													{`${FormatDate(selected?.startDate, 'DD-MMM-YYYY')} -
																	${FormatDate(selected?.endDate, 'DD-MMM-YYYY')}`}
												</i>
											</h2>
									
										</div>
										<div
											className='gap-10'
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
											style={{ display: 'flex', justifyContent: 'flex-end' }}>
														<div className='badge bg-primary'>Parking Allocated</div>
											<div className={`badge ${selected?.isVisitor ? "bg-yellow" :"bg-green"}`}>{selected?.isVisitor ? "Visitor" :"Employee"}</div>

											{/* <div className='badge bg-primary'>Parking Allocated</div> */}

											{/* <Select
												className='common-select common-select-grey'
												value={allocatedVehicles?.allocationDetails?.find((c:any) => c?.allocationID === selected?.allocationID)??''}
												onChange={(e)=>setselected(e)}
												options={allocatedVehicles?.allocationDetails}
												getOptionLabel={(e: any) =>
													`${FormatDate(e?.startDate, 'DD-MMM-YYYY')} - ${FormatDate(
														e.endDate,
														'DD-MMM-YYYY'
													)}`
												}
												getOptionValue={(e)=>e.allocationID}
											/> */}
										</div>
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group inline-input opacity-7'>
											<label>Allocation No </label>
											<input className='form-control ' type='text' id='' value={selected?.allocationID} readOnly />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Allocation Start </label>
											<input
												className='form-control'
												type='text'
												id=''
												value={`${FormatDate(selected?.startDate, 'DD-MMM-YYYY')} ${FormatTime(
													selected?.startTime,
													'HH:mm a'
												)}`}
												readOnly
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Approved On </label>
											<input
												className='form-control'
												type='text'
												id=''
												readOnly
												value={`${FormatDate(selected?.approvedOn, 'DD-MMM-YYYY HH:mm a')}`}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Approved By </label>
											<input className='form-control' type='text' id='' readOnly value={selected?.approvedBy} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Request Type</label>

											<input className='form-control' type='text' id='' readOnly value={selected?.requesteName} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Site</label>

											<input className='form-control' type='text' id='' readOnly value={selected?.allocatedSiteName} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Zone</label>

											<input className='form-control' type='text' id='' readOnly value={selected?.allocatedZoneName} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Bay</label>

											<input
												className='form-control'
												type='text'
												id=''
												readOnly
												value={selected?.allocatedBayName ?? '-'}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Allocation Expiry</label>

											<input
												className='form-control'
												type='text'
												id=''
												readOnly
												value={`${FormatDate(selected?.endDate, 'DD-MMM-YYYY')} ${FormatTime(selected?.endTime, 'HH:mm a')}`}
											/>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group inline-input opacity-7'>
											<label>Plate Number </label>
											<input className='form-control' type='text' id='' value={allocatedVehicles?.plateNumber} readOnly />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Vehicle Category </label>
											<input className='form-control' type='text' id='' readOnly value={allocatedVehicles?.vehicleCategory} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Place of Reg</label>
											<input className='form-control' type='text' id='' readOnly value={allocatedVehicles?.placeOfRegistration} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Brand</label>

											<input className='form-control' type='text' id='' readOnly value={allocatedVehicles?.brand} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Color</label>

											<input className='form-control' type='text' id='' readOnly value={allocatedVehicles?.colour} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Description</label>

											<input className='form-control' type='text' id='' readOnly value={allocatedVehicles?.descriptions} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Last Parked On</label>

											<input
												className='form-control'
												type='text'
												id=''
												readOnly
												value={FormatDate(allocatedVehicles?.lastTransactionDate, 'DD-MMM-YYYY HH:mm a')}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Last Parked Zone</label>

											<input
												className='form-control'
												type='text'
												id=''
												readOnly
												value={allocatedVehicles?.lastTransactionZoneName}
											/>
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Last Parked Bay</label>

											<input
												className='form-control'
												type='text'
												id=''
												readOnly
												value={allocatedVehicles?.lastTransactionBayName}
											/>
										</div>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</Fragment>
				{/* );
			})} */}
		</div>
	);
}

function UnAllocatedVehicle({ vehicles = []  ,TriggerApi}: any) {
	const [addVehicleModal, setaddVehicleModal] = useState(false);

	const [data, setdata] = useState<any>();

	const [deleteloader, setdeleteloader] = useState<any>(false);


	const handleOpen = (item: any) => {
		setdata(item);
		setaddVehicleModal(true);
	};

	const handleDelete = (item: any) => {
		setdata(item);
		setdelModal(true);
	};

	const [delModal, setdelModal] = useState(false);

	const delModalClose = () => setdelModal(false);

	const DeleteVehicle = (id: number) => {
		setdeleteloader(true)
		const success = (res: any) => {
			SuccesToast(res?.response?.data);

			if(TriggerApi){
				TriggerApi()
			}
			delModalClose();
		setdeleteloader(false)

		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
		setdeleteloader(false)

		};

		delete_visitor_vehicle(id, success, failed);
	};
	console.log("VEHICLE UPDATE DATA",data)

	return (
		<>
		<CustomConfirm
		open={delModal}
		handleClose={delModalClose}
		title='Are you sure?'
		description='This action is irreversible. Can you confirm?'>
		<button
			type='submit'
			className='btn btn-secondary'
			disabled={deleteloader}
			onClick={(e) => {
				e.preventDefault();
				DeleteVehicle(data?.vVehicleID);
			}}>
				<ButtonLoader text="Confirm" loading={deleteloader}/>
			
		</button>
	</CustomConfirm>
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				marginBottom: '2%',
			}}>
			{vehicles.map((el: any, k: number) => {
				return (
					<Fragment key={k}>
						<Accordion
							defaultExpanded={k === 0 ? true : false}
							sx={{
								boxShadow: 'none',
								background: 'transparent',
								border: '1px solid var(--lightgrey)',
								marginBottom:"5px",
								backgroundColor :"none",
								'&:before': {
									display: 'none',
								}


							}}>
							<AccordionSummary
								expandIcon={<i className='fa fa-chevron-down' />}
								aria-controls='panel1bh-content'
								sx={{
									margin: 0,
									border:'none',
									backgroundColor :"none"
									
									// height: '20px',
									
									// borderBottom: '1px solid var(--lightgrey)',
									
									// borderTop: '1px solid var(--lightgrey)',
								}}>
								<div className='row w-100'>
									<div className='dflex justify-space-between w-100 align-items-center '>
										<div className='col-8 dflex gap-5 align-items-center'>
										<div onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>

											<ImageView className='avatar-round' src={el?.lastTransactionImage} />

											</div>
											<h2 className='border-title ml-5'>
												{`${el?.plateNumber}  `}
												<i>
													{`${FormatDate(el?.startDate, 'DD-MMM-YYYY')} 
																	${FormatDate(el?.endDate, 'DD-MMM-YYYY')}`}
												</i>
											</h2>
										</div>
										<div className='col-4'>
											<div className='button-group gap-5'>
												<PermissionWrapper subModuleID={subModuleList.Visitors} type='isEdit'>
													<div className='btn btn-sqaure btn-white' onClick={(e) => {e.preventDefault();handleOpen(el)}}>
														<i className='fa-solid fa-pen'></i>
													</div>
												</PermissionWrapper>
												<PermissionWrapper subModuleID={subModuleList.Visitors} type='isDelete'>
													<div className='btn btn-sqaure btn-red ' onClick={(e) =>{ e.preventDefault();handleDelete(el)}}>
														<i className='fa-solid fa-trash'></i>
													</div>
												</PermissionWrapper>
											</div>
										</div>
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group inline-input'>
											<label>Plate Number </label>
											<input className='form-control' type='text' id='' value={el?.plateNumber} readOnly />
										</div>
										<div className='form-group inline-input'>
											<label>Vehicle Category </label>
											<input className='form-control' type='text' id='' disabled value={el?.vehicleCategory} />
										</div>
										<div className='form-group inline-input'>
											<label>Place of Reg</label>
											<input className='form-control' type='text' id='' disabled value={el?.placeOfRegistration} />
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group inline-input opacity-7'>
											<label>Brand</label>

											<input className='form-control' type='text' id='' disabled value={el?.brand} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Color</label>

											<input className='form-control' type='text' id='' disabled value={el?.colour} />
										</div>
										<div className='form-group inline-input opacity-7'>
											<label>Description</label>

											<input className='form-control' type='text' id='' disabled value={el?.descriptions} />
										</div>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</Fragment>
				);
			})}

			
			<AddVehicleModal open={addVehicleModal} handleClose={() =>{ setaddVehicleModal(false); setdata(null)}} Data={data} 
			 visitorID={data?.fK_VisitorID} TriggerApi={TriggerApi}
			/>
		</div>
		</>

	);
}


const AccordionSkeleton = () => { 
	return(
		<div className='row w-100 borderbox' style={{ borderRadius:"0px"}}>
									<div className='dflex justify-space-between w-100 align-items-center skeleton'>
										<div className='col-8 dflex gap-5 align-items-center ' >
											<div className='' onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>
											{/* <ImageView className='avatar-round' src={} /> */}
											<div className='avatar-round skeleton' style={{ border :"1px solid var(--lightgrey-second)"}} />

											</div>
											<h2 className='border-title ml-5 skeleton'>
												
											</h2>
										</div>
										{/* <div className='col-4' style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<div className='badge bg-primary'>Parking Allocated</div>
										</div> */}
									</div>
								</div>
	)
 }