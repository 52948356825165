import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import { fetch_floorLevelDDL, fetch_siteDDL } from '../../../../../common/general/action';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { definiton_add_edit, fetch_definitionList } from '../../../store/action';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any
};

const AddModal = (props: Props) => {

	const [DefId, setDefId] = useState('-')
	const [DefName, setDefName] = useState('')
	const [DefSite, setDefSite] = useState('')
	const [DefFLoor, setDefFLoor] = useState('')
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState({
		name : false,
		site : false,
		level : false
	})

	let navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		const controller = new AbortController()

		fetch_floorLevelDDL(dispatch)
		fetch_siteDDL(dispatch, controller.signal)
	  if(props.Data){
		setDefId(props.Data?.zoneID??'-')
		setDefName(props.Data?.zoneName??'')
		setDefSite(props.Data?.fK_SiteID??'')
		setDefFLoor(props.Data?.fK_FLevelID??'')
	  }
	  else{
		setDefId('-')
			setDefName('')
			setDefFLoor('')
			setDefSite('')
			
	  }
	}, [props.Data])

	const SITELIST = useAppSelector((state) => state?.general)
	const DEFINITION_DATA = useAppSelector((state) => state?.definition)


	const DefinitonActionFx = () =>{
		setLoading(true)

		let postdata:any = props.Data ? {
            name: DefName,
			ID : DefId,
			IsEnable : props.Data.isEnable,
			FK_ID : DefFLoor,
			FK_SiteID : DefSite,
			ReferenceID : props.Data ? props.Data.zRefrenceID : 0
		} :
		{
			name: DefName,
			IsEnable : true,
			FK_ID : DefFLoor,
			FK_SiteID : DefSite,
			ReferenceID : 0
		}

		
		const Success = (res:any) => {
			setLoading(false)

			SuccesToast(res?.response?.data)
			setDefId('')
			setDefName('')
			setDefFLoor('')
			setDefSite('')
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": ""
					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                'zone'
			);
			props.handleClose()
		}
		const Failed = (err:any) => {
			setLoading(false)

			FailedToast(err?.response)		
		}

		if(props.Data){
			definiton_add_edit('put',postdata,'zone',Success,Failed)
		}
		else{
			definiton_add_edit('post',postdata,'zone',Success,Failed)
		}

	}

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title={`${props.Data ? 'Edit' : 'Add'} New Zone`} subtitle={DefId!=='-' ? `#${DefId}` : 'Definitions'}>
				<div className="row">
					{/* {DefId!=='-' &&
					<div className="col-3">
						<div className="form-group inline-input full-label">
							<label>Zone ID</label>
							<input type="text" name='' placeholder='Zone Id Autogenerated' value={DefId} disabled className="form-control grey-input" />
						</div>
					</div>
					} */}
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Zone Name</label>
							<input type="text" name='' value={DefName} onChange={(e) => setDefName(e.target.value)}
								className="form-control grey-input" />
								{	error.name &&
									<p className='hookform-error-message'>A valid Name is required</p>}
						</div>
					</div>
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Site</label>
								<ReactSelect
									options={SITELIST?.SITE_DDL}
									className={'common-select common-select-grey col-12'}
									placeholder={'Select Site'}
									isMulti={false}
									value={SITELIST?.SITE_DDL?.find((c: any) => c.value === DefSite)}
									onChange={(val: any) => {
										fetch_floorLevelDDL(dispatch, { "query" : ` FK_SiteID=${val?.value}` })
										setDefSite(val?.value)
									}}
								/>
							{/* <select className="form-control grey-input" value={DefSite} onChange={(e)=>{
								fetch_floorLevelDDL(dispatch, { "query" : ` FK_SiteID=${e.target.value}` })
								setDefSite(e.target.value)}}>
								{
									SITELIST.SITE_DDL.map((el:any) => {
										return(
											<option value={el.value}>{el.label}</option>
										)
									})
								}
								<option value={''}>Select a Site</option>
							</select> */}
							{	error.site &&
									<p className='hookform-error-message'>A Site is required</p>}
						</div>
					</div>
					<div className={`col-12 ${DefSite==='' ? 'disableAndOpacity' : ''}`}>
						<div className="form-group inline-input full-label">
							<label>Floor Level</label>
							<ReactSelect
									options={SITELIST?.FLOORLEVEL_DDL}
									className={'common-select common-select-grey col-12'}
									placeholder={'Select Floor'}
									isMulti={false}
									value={SITELIST?.FLOORLEVEL_DDL?.find((c: any) => c.value === DefFLoor)}
									onChange={(val: any) => {
										setDefFLoor(val?.value)
									}}
								/>
							{/* <select className="form-control grey-input" value={DefFLoor} onChange={(e)=> setDefFLoor(e.target.value)}>
								{
									SITELIST.FLOORLEVEL_DDL.map((el:any) => {
										return(
											<option value={el.value}>{el.label}</option>
										)
									})
								}
								<option value={''}>Select a Floor</option>
							</select> */}
							{	error.level &&
									<p className='hookform-error-message'>A Level is required</p>}
						</div>
					</div>
					<div className="col-12">
						<div className="modal-footer">
							<button type="button" className="btn btn-outline-blue close-modal-button" onClick={props.handleClose}>Cancel</button>
							<button type="submit" className="btn btn-secondary" onClick={()=>{
								if( DefName!==''){
									setError({
										name : false, level : false, site : false
									})	
									DefinitonActionFx()
								}else{
									setError({
										name : DefName==='',
										level : DefFLoor==='',
										site : DefSite===''
									})
								}
							}}>
								<ButtonLoader text={`${props.Data ? 'Edit' : 'Add'} Zone`} loading={loading}/>
							</button>

						</div>	
					</div>
				</div>
		</ModalLayout>
	);
};

export default AddModal;
