import * as React from 'react';
import Box from '@mui/material/Box';

interface Iprops {
	children: any;
}

const NavTabs: React.FC<Iprops> = ({ children }) => {
	return (
		<Box
			sx={{
				width: '100%',
				borderBottom: 1,
				borderColor: 'divider',
				marginBottom: '30px',
				display: 'flex',
				overflowX: 'auto',
				overflowY: 'hidden',
				scrollbarWidth: 'none',
				'&::-webkit-scrollbar': { display: 'none' },
			}}>
			{children}
		</Box>
	);
};

export default NavTabs;
