/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { fetch_InvalidReasonDDL } from '../../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, FormatTime, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_parking_violations_History_list, fetch_parking_violations_list, update_violation_status } from '../../../store/action';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import dayjs from 'dayjs';
import ImageCarousel from '../../../../../common/components/ImageCarousel';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import { useNavigate } from 'react-router-dom';
import noimage from '../../../../../assets/images/common/noimage.jpg';
import ReactSelect from 'react-select';
import MainLayout from '../../../../../common/layouts/MainLayout';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../../config/subModuleList';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

const ViewModal = (props: Props) => {
	const [invalidReason, setInvalidReason] = useState('');
	const [remarks, setRemarks] = useState('');
	const fetchIdRef = useRef(0);
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState<boolean>(true);
	const [invalidModal, setInvalidModal] = useState(false);
	const confirmClose = () => setInvalidModal(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	useEffect(() => {
		const controller = new AbortController();
		fetch_InvalidReasonDDL(dispatch, undefined, controller.signal);
	}, []);

	const INVALIDREASONLIST = useAppSelector((state) => state?.general);
	const VIOLATION_HISTORY = useAppSelector((state) => state?.violations);

	const InvalidReasonUpdate = () => {
		setLoading(true);

		let postData = {
			'ViolationID': props.Data?.violationID,
			'ValidateStatus': 2,
			'FK_ReasonID': invalidReason,
			'ValidateRemarks': remarks,
		};

		const Success = (res: any) => {
			setLoading(false);
			setInvalidModal(false);
			SuccesToast(res?.response?.data);
			const controller = new AbortController();
			fetch_parking_violations_list(
				navigate,
				dispatch,
				{
					'filters': {
						'query': VIOLATION_HISTORY?.PARKING_VIOLATION_FILTER?.query || '',
					},
					'pagination': {
						'pageNo': VIOLATION_HISTORY?.PARKING_VIOLATION_PAGINATION?.pageNo,
						'limit': VIOLATION_HISTORY?.PARKING_VIOLATION_PAGINATION?.limit,
					},
				},
				controller.signal
			);
			props.handleClose();
			setRemarks('');
		};
		const Failed = (err: any) => {
			setLoading(false);
			setInvalidModal(false);
			FailedToast(err?.response);
		};
		update_violation_status(postData, Success, Failed);
	};

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'violationID', orderByKey: 'violationID' },

			{ Header: 'Date', accessor: 'date', DateFormat: true, orderByKey: 'VDate' },
			{ Header: 'Time', accessor: 'time', orderByKey: 'VTime' },
			{ Header: 'Plate Number', accessor: 'plateNo', orderByKey: 'plateNo' },
			{
				Header: 'Reported BY',
				accessor: 'reportedUser',
				Cell: ({ row }: any) => {
					return <>{row?.original?.reportedUser ?? 'System'}</>;
				},
			},
			{ Header: 'Violation Type', accessor: 'violationName' },
			// { Header: 'Reported By', accessor: 'reportedUser' },
			{
				Header: 'Violation Status',
				accessor: 'vStatus',
				Cell: ({ row }: any) => {
					return (
						<>
							{row?.original?.vStatus === 0 ? (
								'- '
							) : row?.original?.vStatus === 2 ? (
								'Inactive'
							) : (
								<label className='badge red'>Active</label>
							)}
						</>
					);
				},
			},
			{
				Header: 'Validation Status',
				accessor: 'validateStatus',
				Cell: ({ row }: any) => {
					return (
						// <StatusChipComponent
						// 	text={}
						// 	chipType={row?.values?.validateStatus === 2 ? 'error' : 'success'}
						// />
						<>
							{row?.values?.validateStatus === 2 ? (
								<label htmlFor='' className='badge yellow'>
									Invalid
								</label>
							) : (
								'Valid '
							)}
						</>
					);
				},
			},
			{ Header: 'actionBar', accessor: 'actionBar', hideHeader: true },
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parking_violations_History_list(
					dispatch,
					{
						'filters': {
							'query': ` ViolationID NOT IN (${props.Data?.violationID}) AND ${
								props.Data?.fK_UserUniqueID ? ` FK_UserUniqueID=${props.Data?.fK_UserUniqueID} ` : ''
							}${props.Data?.userType ? `AND FK_UserType=${props.Data?.userType} ` : ''}`,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, props.Data?.fK_UserUniqueID, props.Data?.userType, props.Data?.violationID]
	);

	const handleChangeExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(!expanded);
	};

	return (
		<>
			<ModalLayout
				open={props.open}
				handleClose={() => {
					props.handleClose();
					setRemarks('');
				}}
				big={true}
				title='Parking violations'
				subtitle={props.Data?.remarks}>
				<div className='modal-body-content' style={{ maxHeight: '55vh' }}>
					<div className='row'>
						<div className='col-7'>
							<div className='table-details'>
								<div>
									<div
										className='border-title-row flex-1 w-100'
										style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
										<h2 className='border-title '>Violation Type</h2>
										<div className='dflex gap-10'>
											<h2 className='border-title'>
												<label className='badge darkblue'>{props.Data?.violationName}</label>
											</h2>
											{props.Data?.validateStatus && props.Data?.validateStatus !== 1 ? (
												<h2 className='border-title'>
													<label className='badge yellow'>Invalid Violation </label>
												</h2>
											) : (
												<>
													{`${props.Data?.vStatus}` === `1` && (
														<>
															<h2 className='border-title'>
																<label className='badge red'>Active</label>
															</h2>
														</>
													)}
												</>
											)}
										</div>
									</div>
									<div className='dflex'>
										<ul style={{ textTransform: 'capitalize' }}>
											<li>
												User <span>{props.Data?.violatedUser || props?.Data?.gustName}</span>
											</li>
											<li>
												User Email <span>{props.Data?.emailID}</span>
											</li>
											<li>
												User Contact<span>{props.Data?.phoneNumber}</span>
											</li>
											<li>
												Personal Grade<span>{props.Data?.FK_PriorityLevel}</span>
											</li>
											<li>
												User Type <span>{props.Data?.userTypeName}</span>
											</li>
											<li>
												Department <span>{props.Data?.depName}</span>
											</li>
											<li>
												Designation <span>{props.Data?.desName}</span>
											</li>
											<li>
												Violation Date <span>{dayjs(props.Data?.date).format('DD-MMM-YYYY')}</span>
											</li>
											<li>
												Violation Time <span>{FormatTime(props.Data?.time, 'hh:mm a')}</span>
											</li>
											<li>
												Plate Number <span>{props.Data?.plateNo}</span>
											</li>
											<li>
												Place of Reg <span>{props.Data?.placeOfRegistration}</span>
											</li>
											<li>
												Vehicle Category <span>{props.Data?.vehicleCategory}</span>
											</li>
											
										</ul>
										<div className='seperator'></div>
										<ul style={{ textTransform: 'capitalize' }}>
											<li>
												Reported By <span>{props.Data?.reportedUser ?? 'System'}</span>
											</li>
											<li>
												Violation Category <span>{props.Data?.vCategory === 2 ? ' User' : 'Vehicle'}</span>
											</li>
											<li>
												Penalty Level<span>{props.Data?.penaltyLevel}</span>
											</li>
											<li>
												SIte <span>{props.Data?.siteName}</span>
											</li>
											<li>
												Zone <span>{props.Data?.zoneName}</span>
											</li>
											<li>
												Bay <span>{props.Data?.reporedBa ? props.Data?.reporedBa : props.Data?.reportedBay}</span>
											</li>
											
											{props.Data?.vReason && (
												<li>
													Invalid Violation Reason
													<span>{props.Data?.vReason}</span>
												</li>
											)}
											
											<li>
												Allocated Zone <span>{props.Data?.zoneNames??''}</span>
											</li>
											<li>
												Policy Name <span>{props.Data?.policyNames??''}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						{props.Data?.imagesList && props.Data?.imagesList.length !== 0 ? (
							<div className='col-5'>
								<div className='parking-lot-image carouselUlAdjust'>
									<ImageCarousel
										showThumbs={false}
										imageArr={props.Data?.imagesList?.map((el: any) => {
											return { src: el.images };
										})}
										showIndicators={false}
									/>
								</div>
							</div>
						) : 
						props.Data?.transactionImage ?
						(
							<div className='col-5'>
								<div className='parking-lot-image carouselUlAdjust'>
									<ImageCarousel showThumbs={false} imageArr={[{ src: props.Data?.transactionImage }]} showIndicators={false} />
								</div>
							</div>
						)
						: 
						(
							<div className='col-5'>
								<div className='parking-lot-image carouselUlAdjust'>
									<ImageCarousel showThumbs={false} imageArr={[{ src: noimage }]} showIndicators={false} />
								</div>
							</div>
						)}
					</div>
					{/* <div className='row'>
                        <div className='col-12'>
                            <div className='filter-block'>
                                <Accordion
                                    expanded={expanded}
                                    onChange={handleChangeExpand('panel1')}
                                    sx={{ boxShadow: 'none', border: 'none', background: 'transparent' , padding:"0px" }}>
                                    <AccordionSummary
                                        expandIcon={<i className='fa fa-chevron-down' />}
                                        aria-controls='panel1bh-content'
                                        id='panel1bh-header'
                                        sx={{ margin: 0, padding: '0px', minHeight: "20px !important", maxHeight: "20px !important", }}
                                    >
                                        <div className='accordion-box-heading'>
                                            <h2 className='border-title'>Violation History</h2>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='accordion-box-body'>
                                            <div className=''>
                                                <MainLayout>


                                                    <div className='reponsive-table'>

                                                        <CommonTable
                                                            data={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_DATA ?? []}
                                                            columns={columns}
                                                            pageCount={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_PAGINATION?.totalCount ?? 0}
                                                            fetchData={fetchData}
                                                            loading={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_LOADER}
                                                            error={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_ERROR}
                                                            limit={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_PAGINATION?.limit ?? 1}
                                                            page_no={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_PAGINATION?.pageNo ?? 1}
                                                            filters={VIOLATION_HISTORY?.REPORT_VIOLATION_HISTORY_FILTER}
                                                        />
                                                    </div>
                                                </MainLayout>

                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div> */}
					<></>
				</div>
				<PermissionWrapper subModuleID={subModuleList['Parking Violations']} type='isEdit'>
					{props.Data?.validateStatus !== 2 ? (
						<div className='row modal-footer'>
							<div className='col-12'>
								<div className='border-form'>
									<div>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group inline-input modal-footer-tx-align-left'>
													<label>Select Invalid Violation Reason</label>
													<ReactSelect
														options={INVALIDREASONLIST.INVALIDREASON_DDL}
														// className={'common-select'}
														placeholder={'Reasons for inavlid violations'}
														isMulti={false}
														isClearable
														menuPlacement='top'
														className='common-select common-select-grey'
														value={INVALIDREASONLIST.INVALIDREASON_DDL?.find(
															(c) => c?.value === invalidReason
														)}
														onChange={(val) => {
															setInvalidReason(val?.value);
														}}
													/>
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group inline-input modal-footer-tx-align-left'>
													<label style={{ minWidth:'10%' }}>Remarks</label>
													<input
														type='text'
														className='form-control grey-input'
														name=''
														placeholder='Remarks'
														value={remarks}
														onChange={(e) => {
															let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
															setRemarks(v);
														}}
													/>
												</div>
											</div>
											<p className='ml-5' style={{ color: 'orange', fontSize: '12px' }}>
												You can mark this violation as invalid with a predefined invalid reason.{' '}
												<b style={{ color: 'orange' }}>This is an irreversible action.</b>
											</p>
										</div>
										<div className={'row button-group '}>
											<button
												onClick={() => setInvalidModal(true)}
												className={`btn btn-secondary border-blue ${
													remarks !== '' && invalidReason ? '' : 'opacity-half'
												}`}>
												<ButtonLoader loading={loading} text={'Save'} />
											</button>

											<button
												onClick={() => {
													props.handleClose();
													setRemarks('');
												}}
												className='btn btn-outline-blue close-modal-button ml-5'>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</PermissionWrapper>
			</ModalLayout>
			{invalidModal && (
				<CustomConfirm handleClose={confirmClose} open={invalidModal} 
				title='Are you sure about Invalidating this Violation?'
				description='This action is irreversible. Can you confirm?'>
					<button className='btn btn-secondary' onClick={InvalidReasonUpdate}>
						{' '}
						Invalidate this Violation
					</button>
				</CustomConfirm>
			)}
		</>
	);
};

export default ViewModal;
