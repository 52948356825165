import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_visit_report } from '../../../store/action';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';

type Props = {}

const VisitsReports = (props: Props) => {
	const fetchIdRef = useRef(0);
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
    const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);
	const SITE_FLOOR = useAppSelector((state)=>state?.general)
	
function time_convert(num:number)
{ 
   var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
   var d = (num / 60) % 24
   var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
   var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
 console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
 return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
}


    const columns = React.useMemo(
		() => [
			{ Header: 'Visit ID', accessor: 'visitID'},
			{ Header: 'Entry Timestamp', accessor: 'entryDateTime', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'Exit Timestamp', accessor: 'exitDateTime', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},
			{ Header: 'Dwell Time', accessor: 'dwellTime',
			Cell: ({ row }: any) => {
				return (
					<p>
						{time_convert(row?.original?.duration??moment.duration(row?.original?.dwellTime).asMinutes())}
					</p>
				);
			}},
            { Header: 'Plate Number', accessor: 'plateText'},
			{ Header: 'Place of Registration', accessor: 'placeOfRegistration'},
			{ Header: 'Vehicle Category', accessor: 'vehicleCategory'},
			// { Header: 'Site Name', accessor: 'siteName'},
            { Header: 'Zone Name', accessor: 'zoneName'},
            { Header: 'Bay Name', accessor: 'bayName'},
			{ Header: 'Bay Type', accessor: 'bTypeName'},
			{ Header: 'Full Name', accessor: 'fullName'},
			{ Header: 'Email ID', accessor: 'emailID'},
			{ Header: 'Contact', accessor: 'contactNo'},
			
			{ Header: 'Designation', accessor: 'desName'},
			{ Header: 'Department Name', accessor: 'depName'},
			{ Header: 'Personal Grade', accessor: 'fK_PriorityLevel'},
			{ Header: 'Company Name', accessor: 'companyName'},
			{ Header: 'User Type', accessor: 'userTypeName'},
            { Header: 'Visit Status', orderByKey :  'exitDateTime',
				Cell: ({ row }: any) => {
					if(row?.original?.exitDateTime===null)
						return <StatusChipComponent chipType='pending' text='Visit On-going'  />
					else
						return <StatusChipComponent text='Visit Over'  /> 
				}
			},
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,
				show: false,
			}
			
		],
		[]
	);
	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		filters: {
			siteID : 0,	
			fromDate : "",	
			toDate : "",		
			fromTime : "",		
			toTime : "",		
			zoneIDs :"" ,			
			bayTypeIDs : "",		
			visitDurationFrom :0,		
			visitDurationTo : 0,		
			plateTextSearch : "",
			UserType			:	0,
			Ongoinvisits		:	"",
			query : ''
		};
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {

				let postdata = {
						siteID		:	filters?.siteID ? filters?.siteID : SITE_FLOOR?.SITE_DDL[0]?.value ,	
						toDate 		:	filters?.toDate ? 
				  dayjs(filters?.toDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
						fromDate    :   filters?.fromDate ? 
				  dayjs(filters?.fromDate).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),		
						fromTime    :   filters?.fromTime ? dayjs(filters?.fromTime,['HH:mm:ss','hh:mm a']).format('HH:mm:ss') : '00:00:00',
						toTime      :   filters?.toTime ? dayjs(filters?.toTime,['HH:mm:ss','hh:mm a']).format('HH:mm:ss') :  '23:59:00',		
						zoneIDs 	:	filters?.zoneIDs,			
						bayTypeIDs	:	filters?.bayTypeIDs,		
						visitDurationFrom	:	filters?.visitDurationFrom,		
						visitDurationTo		:	filters?.visitDurationTo,		
						plateTextSearch		:	filters?.plateTextSearch,
						UserType			:	0,
						Ongoinvisits		:	"",
						query 				:	filters?.query??''
				}
				

				fetch_visit_report(
					navigate,
					dispatch,
					{
						'filters' : postdata,
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);
	return (
			<CommonTable
				data={VISITS_REPORTS_DATA?.VISIT_REPORTS_LIST_DATA??[]}
				columns={columns}
				pageCount={VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.totalCount ?? 0}
				fetchData={fetchData}
				limit={VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.limit ?? 1}
				page_no={VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.pageNo}
				filters={VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER}
				loading={VISITS_REPORTS_DATA?.VISIT_REPORTS_LOADER}
			/>
    )
}

export default VisitsReports