/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { fetch_parking_policy } from "../../../store/actions";

type Props = {};

const FilterBar = (props: Props) => {
	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();

	const [val, setval] = useState('')
	const controller = new AbortController();
	const signal = controller.signal;
	const PARKING_POLICY_DATA = useAppSelector((state) => state?.policy)

	const filterSearch = (val:string) =>{
		setval(val)
		fetch_parking_policy(navigate, dispatch, {
			'filters': {
				'query':  val!=='' ? !isNaN(parseInt(val)) ? `PolicyID LIKE '${val}%'` : `PolicyName LIKE '%${val}%' ` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 10,
			},
		}, signal)
	
}

	return (
		<div className="search-row-button">
				<div className="row">
					<div className="col-5">
							<div className="form-group">
								<div className="pos-rel">
									<input className="form-control grey-input" name="" placeholder="Search Here" 
									value={val}
									onChange={(e)=>
									{
										let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');

											filterSearch(v)
										
										}}  />
									<i className="fa-solid fa-magnifying-glass"></i>
								</div>
							</div>
					</div>
				</div>
			</div>

	);
};

export default FilterBar;
