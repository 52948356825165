import React, { useState } from 'react'
import { data } from './data'
import { Tooltip } from 'antd'

type Props = {
    tree: any,
    accessPreviledges:any,
    setAccessPreviledges:any
}

const CheckboxTreeMap = ({tree, accessPreviledges, setAccessPreviledges}: Props) => {

    //console.log('ACCESSPREVILEDGE => ',accessPreviledges)

    const findParentSiteId = (id:number) =>{
        return tree.find((el:any) => 
            (el.siteChild && el.siteChild.length!==0 && el.siteChild.map((ex:any) => {return(ex.levelID)}).includes(id) )
        )?.siteID
    }

    const fetchAllSitesValCheck = (id:any) =>{
        if(accessPreviledges && accessPreviledges.length>0)
            return accessPreviledges.map((el:any)=> el.fK_Site).includes(id)
        else 
            return false
    }
    const fetchAllLevelsValCheck = (id:any) =>{
        if(accessPreviledges && accessPreviledges.length>0)
            return accessPreviledges.map((el:any)=> el.fk_FLevel).includes(id)
        else 
            return false
    }
    const fetchAllZonesValCheck = (id:any) =>{
        if(accessPreviledges && accessPreviledges.length>0)
            return accessPreviledges.map((el:any)=> el.fK_Zone).includes(id)
        else 
            return false
    }

    const setupNewTree = (id:any, nodeType:any, children:any, parentObjId?:any) =>{

        if(nodeType==='site'){
            if(children && children.length!==0){
                return children.map((el:any)=>{
                    return setupNewTree(el.levelID, 'level', el.levelChild, id)
                })
            }
            else {
                let obj = { fK_Site : id }
                return obj
            }
        }
        else if(nodeType==='level'){
            if(children && children.length!==0){
                return children.map((el:any)=>{
                    return setupNewTree(el.zoneID, 'zone', [], id)
                })
            }
            else {
                let obj = { fK_Site : parentObjId, fk_FLevel : id }
                return obj
            }
        }
        else{
            let obj = { fK_Site : findParentSiteId(parentObjId), fk_FLevel : parentObjId , fK_Zone : id }
            return obj
        }
    }

    const TreeNode = ({ nodeObj, parentObj } : { nodeObj : any, parentObj?: any }) => {
        if(nodeObj && nodeObj.siteID)
        return (
            <Tooltip title={nodeObj.siteChild && nodeObj.siteChild.length===0 ? 'No Levels added to this Site.' : ''} placement="leftTop">
            <li>
                {
                    parentObj===null && nodeObj.siteChild && nodeObj.siteChild.length===0 ?
                    <div className="checkboxRemoved">
                        <label><span>{nodeObj.siteName}</span></label>
                    </div>
                    :
                    <div className={`checkbox zindexCorrection translateYAdjust ${(nodeObj.siteChild && nodeObj.siteChild.length===0) || 
                        (   nodeObj.siteChild && nodeObj.siteChild.length!==0 &&
                            nodeObj.siteChild?.filter((el:any) => el?.levelChild?.length!==0)?.length === 0) ? 'disableAndOpacity' : ''}`}>
                        <input type="checkbox"  
                            disabled={nodeObj.siteChild && nodeObj.siteChild.length===0}
                            checked={fetchAllSitesValCheck(nodeObj.siteID)}
                            onChange={()=>{
                                console.log('ACCESSPREVED',accessPreviledges) 
                                if(fetchAllSitesValCheck(nodeObj.siteID))
                                {
                                    let newArr = accessPreviledges.filter((el:any)=>el.fK_Site!==nodeObj.siteID)
                                    console.log('ONCHANGE-CHECKBX-SITE',newArr,'ACCESSPREV',accessPreviledges)
                                    setAccessPreviledges(newArr)
                                }
                                else{
                                    let newArr = [...accessPreviledges, setupNewTree(nodeObj.siteID, 'site', nodeObj.siteChild, null).flat(1)].flat(1)
                                    console.log('ONCHANGE-CHECKBX-SITE',newArr,'ACCESSPREV',accessPreviledges)
                                    setAccessPreviledges(newArr)
                                }
                        }} />
                    
                        <label><span>{nodeObj.siteName}</span></label>
                    </div>
                
                }
                {
                    nodeObj.siteChild && nodeObj.siteChild.length!==0 ?
                        nodeObj.siteChild.map((e:any) => {
                            return(
                                <ul>
                                    <TreeNode nodeObj={e} parentObj={nodeObj.siteID}/>
                                </ul>
                            )
                        }) 
                        :   null
                }
               
            </li>
            </Tooltip>
            
        )
        else if(nodeObj && nodeObj.levelID)
        return (
            <Tooltip title={nodeObj.levelChild && nodeObj.levelChild.length===0 ? 'No Zones added to this Level.' : ''} placement="leftTop">
            <li>
                <div className={`checkbox zindexCorrection  ${nodeObj.levelChild && nodeObj.levelChild.length===0 ? 'disableAndOpacity' : ''}`}>
                    <input type="checkbox"  
                        disabled={nodeObj.levelChild && nodeObj.levelChild.length===0}
                        checked={fetchAllLevelsValCheck(nodeObj.levelID)}
                        onChange={()=>{
                            console.log('ACCESSPREVED',accessPreviledges)
                            if(fetchAllLevelsValCheck(nodeObj.levelID))
                            {
                                let newArr = accessPreviledges.filter((el:any)=>el.fk_FLevel!==nodeObj.levelID)
                                console.log('ONCHANGE-CHECKBX-LEVEL',newArr,'ACCESSPREV',accessPreviledges)
                                setAccessPreviledges(newArr)
                            }
                            else {
                                let newArr = [...accessPreviledges, setupNewTree(nodeObj.levelID, 'level', nodeObj.levelChild, parentObj).flat(1)].flat(1)
                                console.log('ONCHANGE-CHECKBX-LEVEL',newArr,'ACCESSPREV',accessPreviledges)
                                setAccessPreviledges(newArr)
                            }
                    }} />
                    <label><span>{nodeObj.levelName}</span></label>
                </div>
            
                
                {
                    nodeObj.levelChild && nodeObj.levelChild.length!==0 ?
                        nodeObj.levelChild.map((e:any) => {
                            return(
                                <ul>
                                    <TreeNode nodeObj={e} parentObj={nodeObj.levelID} />
                                </ul>
                            )
                        }) 
                        :   null
                }
               
            </li>
            </Tooltip>
        )
        else
        return (
            <li>
                <div className="checkbox zindexCorrection">
                    <input type="checkbox"  
                        checked={fetchAllZonesValCheck(nodeObj.zoneID)}
                        onChange={()=>{
                            console.log('ACCESSPREVED',accessPreviledges)
                            if(fetchAllZonesValCheck(nodeObj.zoneID))
                            {
                                let newArr = accessPreviledges.filter((el:any)=>el.fK_Zone!==nodeObj.zoneID)
                                console.log('ONCHANGE-CHECKBX-ZONE',newArr,'ACCESSPREV',accessPreviledges)
                                setAccessPreviledges(newArr)
                            }
                            else{
                                let newArr = [...accessPreviledges, setupNewTree(nodeObj.zoneID, 'zone', [], parentObj)].flat(1)
                                console.log('ONCHANGE-CHECKBX-ZONE',newArr,'ACCESSPREV',accessPreviledges)
                                setAccessPreviledges(newArr)
                            } 
                                
                    }} />
                    <label><span>{nodeObj.zoneName}</span></label>
                </div>
                {
                    nodeObj.zoneChild && nodeObj.zoneChild.length!==0 ?
                        nodeObj.zoneChild.map((e:any) => {
                            return(
                                <ul>
                                    <TreeNode nodeObj={e} parentObj={nodeObj.zoneID} />
                                </ul>
                            )
                        }) 
                        :   null
                }
               
            </li>
        )
    }

  return (
    <ul className="sitemap-style">
        {
            tree && tree.map((el:any) => {
                return(
                    <TreeNode nodeObj={el} parentObj={null} />
                )
            })
        }
    </ul>
  )
}

export default CheckboxTreeMap