import { MutableRefObject, useEffect } from 'react';

export default function useKeyboard(key: any, callback: () => void, ref?: MutableRefObject<HTMLDivElement | null>) {
	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent) => {
			if (event.key === key) {
				event.preventDefault();

				if (ref) {
					if (ref?.current?.contains(document?.activeElement)) {
						callback();
					}
				} else {
					callback();
				}

				console.log('REF::', ref?.current?.contains(document.activeElement));
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [key, callback, ref]);
}
