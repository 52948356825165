/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { fetch_parkingRequestCount, fetch_parkingRequestslist } from '../store/action';
// import { Field } from 'react-querybuilder';
import ReactSelect from 'react-select';
import { fetch_siteDDL, fetch_priorityLevelDDL } from '../../../common/general/action';
import QueryBModal from '../../../common/components/query_builder';
import { commonSearchQuery, ExtendedField, FilterProps } from '../../../config/filterSearch';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import ApproveModal from './ApproveModal';
// import RejectModal from './RejectModal';
import { DatePicker } from 'antd';
import BulkApproveModal from './BulkApproveModal';
import dayjs from 'dayjs';
import ParkingRequestAddModal from './NewParkingRequestModal/ParkingRequestAddModal';
import useKeyboard from '../../../utils/hooks/useKeyboard';
import { BULK_UPDATE_PARKING_REQUESTS_DATA, BULK_UPDATE_PARKING_REQUESTS_LOADER } from '../store/parkingRequestSlice';
const { RangePicker } = DatePicker;

const FilterBar = () => {
	const dispatch = useAppDispatch();
	const [addModal, setAddModal] = useState(false);
	const [advanceModal, setAdvanceModal] = useState(false);
	const [filterActive, setFilterActive] = useState(false);

	const [approveModal, setApproveModal] = useState(false);

	const [reqType, setReqType] = useState<any>(null);
	const [userSearch, setUserSearch] = useState<any>('');
	const [priority, setPriority] = useState<any>('');
	const [reqStatus, setReqStatus] = useState<any>('');
	const [dateRange, setDateRange] = useState<any>([]);
	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);
	const addModalOpen = () => setAddModal(true);
	const addModalClose = () => setAddModal(false);
	// const [bulkOp, setBulkOp] = useState('')

	const ApproveModalOpen = () => {
		dispatch(
			BULK_UPDATE_PARKING_REQUESTS_LOADER({
				loader: true,
			})
		);
		dispatch(
			BULK_UPDATE_PARKING_REQUESTS_DATA({
				data: [],
				pagination: { pageNo: 1, limit: 10, totalCount: 0 },
				filters: { query: ' RequestStatus=0' },
			})
		);

		setApproveModal(true);
	};
	const ApproveModalClose = () => {
		dispatch(
			BULK_UPDATE_PARKING_REQUESTS_LOADER({
				loader: true,
			})
		);
		dispatch(
			BULK_UPDATE_PARKING_REQUESTS_DATA({
				data: [],
				pagination: { pageNo: 1, limit: 10, totalCount: 0 },
				filters: { query: ' RequestStatus=0' },
			})
		);
		setApproveModal(false);
	};

	const PARKING_REQ_DATA = useAppSelector((state) => state?.parkingrequest);
	const GENERAL_DDL = useAppSelector((state) => state?.general);
	let ReqTypeArr = [
		{ value: 1, label: 'Employee Parking' },
		{ value: 2, label: 'Visitor Parking' },
		{ value: 3, label: 'Employee Vehicle Update' },
		{ value: 4, label: 'Visitor Vehicle Update' },
		{ value: 5, label: 'Employee Overnight time Parking' },
		{ value: 6, label: 'Visitor Overnight time Parking' },
		{ value: 7, label: 'Employee Parking Cancellation' },
		{ value: 8, label: 'Visitor Parking Cancellation' },
	];
	let ReqStatusArr = [
		{ value: '0', label: 'Pending' },
		{ value: '1', label: 'Approved' },
		{ value: '2', label: 'Rejected' },
		// { value: 4, label: 'Cancelled' },
		{ value: '6', label: 'Blacklisted' },
	];
	const [site, setSite] = useState<any>('');

	useEffect(() => {
		const controller = new AbortController();
		fetch_siteDDL(dispatch, controller.signal);
		fetch_priorityLevelDDL(dispatch, controller.signal);
		return () => controller.abort();
	}, [dispatch]);

	const fields: ExtendedField[] = [
		// { name: 'pRequestID', label: 'REQUEST ID' },
		{ name: 'RequestedFUName', label: 'REQUESTED FOR NAME' },
		{ name: 'RequestedBUName', label: 'REQUESTED BY NAME' },
		{ name: 'RequstedFUEmailID', label: 'REQUESTED FOR EMAIL' },

		{ name: 'tpvd.PlateNumber', label: 'PLATE NUMBER' },
		{ name: 'Category', label: 'VEHICLE CATEGORY' },
		{ name: 'PlaceOfRegistration', label: 'PLACE OF REGISTRATION' },

		{ name: 'rF_PriorityLevelID', label: 'PERSONAL GRADE', inputType:'number' },
		{ name: `CAST (RequestedOn as date)`, label: 'REQUESTED ON', inputType: 'date' },
		{ name: 'StartDate', label: 'START DATE', inputType: 'date' },
		{ name: 'EndDate', label: 'END DATE', inputType: 'date' },
		{ name: 'StartTime', label: 'START TIME', inputType: 'time' },
		{ name: 'EndTime', label: 'END TIME', inputType: 'time' },
		{
			name: 'RFUserType',
			label: 'USER TYPE',
			inputType: 'select',
			values: [
				{ name: "1", label: "Employee" },
				{ name: "2", label: "Visitor" }
			  ]
			// valueEditorType:"select",
		// 	options: [{
		// 		label: 'HIII',
		// 		value: 1,
		// 	},
		// 	{
		// 		label: 'Yooo',
		// 		value: 1,
		// 	},
		// 	{
		// 		label: 'HOOOO',
		// 		value: 1,
		// 	},
		
		// ]
		},
		// { name: 'FK_PriorityLevel', label: 'PRIORITY', inputType: 'number' },
		// { name: 'tsp.Fk_SiteID', label: 'SITE ID', inputType: 'number' },
		{ name: 'siteName', label: 'SITE NAME' },
		{ name: 'RequestStatus', label: 'REQUEST STATUS', inputType: 'select',
		values: [
			{ name: '0', label: 'Pending' },
			{ name: '1', label: 'Approved' },
			{ name: '2', label: 'Rejected' },
			// { name: '3', label: 'Cancelled' },
			// { name: '4', label: 'Revoked' },
			// { name: '5', label: 'Deleted' },
			{ name: '6', label: 'Blacklisted' },
			// { name: '7', label: 'Auto Rejected' }
		]
	},
		{ name: 'FK_RequestType', label: 'REQUEST TYPE ', inputType: 'select', values:[
			{ name: '1', label: 'Employee Parking' },
			{ name: '2', label: 'Visitor Parking' },
			{ name: '3', label: 'Employee Vehicle Update' },
			{ name: '4', label: 'Visitor Vehicle Update' },
			{ name: '5', label: 'Employee Overtime Parking' },
			{ name: '6', label: 'Visitor Overtime Parking' },
			{ name: '7', label: 'Employee Parking Cancellation' },
			{ name: '8', label: 'Visitor Parking Cancellation' },

		] },

		// { name: 'ApprovedZoneID', label: 'ZONE ID', inputType: 'number' },
		{ name: 'zoneName', label: 'ZONE NAME' },

		// { name: 'FK_ApprovedBayID', label: 'BAY ID', inputType: 'number' },
		{ name: 'bayName', label: 'BAY NAME' },
		{ name: 'isPermenantParking', label: 'Permenant Parking' , inputType:"select", values:[ 
			{ name : 'true', label : 'Yes'},
			{ name : 'false', label : 'No' }
	]},
	{ name: 'isMedicalPass', label: 'Medical Pass' , inputType:"select", values:[ 
			{ name : 'true', label : 'Yes'},
			{ name : 'false', label : 'No' }
	]},

	];

	const deployQueryFilter = (query: string) => {
		setFilterActive(true);

		fetch_parkingRequestslist('', dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_REQ_DATA.PARKING_REQUEST_PAGINATION.limit,
			},
		});
		advancemodalClose();
		setReqType('');
		setUserSearch('');
		setPriority('');
		setSite('');
		setReqStatus('');
		let postdata = {
			'query': query,
		};
		fetch_parkingRequestCount(dispatch, postdata);
	};

	const clearSearch = () => {
		setFilterActive(false);
		fetch_parkingRequestslist('', dispatch, {
			'filters': {
				'query': ``,
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_REQ_DATA.PARKING_REQUEST_PAGINATION.limit,
			},
		});
		setReqType('');
		setUserSearch('');
		setPriority('');
		setSite('');
		setReqStatus('');
		setDateRange([]);
		let postdata = {
			'query': '',
		};
		fetch_parkingRequestCount(dispatch, postdata);
	};

	const filterSearch = (val: any = null) => {
		if ((dateRange && dateRange?.length > 0) || userSearch || reqType || priority || site || reqStatus !== '' || val) {
			setFilterActive(true);
			let QueryInput: FilterProps[] = [
				{
					field: 'CAST (StartDate AS date)',
					value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
					operator: '>=',
				},
				{
					field: 'CAST (EndDate AS date)',
					value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
					operator: '<=',
				},
				{
					field: 'RequestedFUName',
					value: userSearch,
					operator: 'like',
				},
				{
					field: 'FK_RequestType',
					value: parseInt(reqType),
					operator: '=',
				},
				{
					field: 'FK_PriorityLevel',
					value: priority,
					operator: '=',
				},
				{
					field: 'tsp.Fk_SiteID',
					value: val ? val : site,
					operator: '=',
				},
				{
					field: 'RequestStatus',
					value: reqStatus,
					operator: '=',
				},
			];

			fetch_parkingRequestslist('', dispatch, {
				'filters': {
					'query': commonSearchQuery(QueryInput, 'AND'),
				},
				'pagination': {
					'pageNo': 1,
					'limit': PARKING_REQ_DATA.PARKING_REQUEST_PAGINATION.limit,
				},
			});
			let postdata = {
				'query': commonSearchQuery(QueryInput, 'AND'),
			};
			fetch_parkingRequestCount(dispatch, postdata);
		} else setFilterActive(false);
	};

	// const allSameSite = () =>{
	// 	let siteArr = selectedRows && selectedRows?.map((el:any) => el.tsp.Fk_SiteID)
	// 	console.log('suteARR',siteArr,siteArr.every( (v:any) => v === siteArr[0] ))
	// 	return siteArr.every( (v:any) => v === siteArr[0] ) ? siteArr[0] : null
	// }

	// const allSameRType = () =>{
	// 	let rTyprArr = selectedRows && selectedRows?.map((el:any) => el.fK_RequestType)
	// 	let allRType = rTyprArr.every( (v:any) => v === rTyprArr[0] ) ? rTyprArr[0] : null
	// 	return (allRType===3 || allRType===4 || allRType===7 || allRType===8 ) ? allRType : null

	// }

	// const [test, settest] = useState(false)
	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	return (
		<>
			<div className='search-filter-row' ref={ref}>
				<div className='row'>
					<div className='col-6'>
						<ul>
							<li>
								<a className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							{/* <li className={`select-box ${selectedRows && selectedRows?.length!==0 ? '' : 'disableAndOpacity'}`}>
									<ReactSelect
									options={[
										{value : 'approve', label : 'Approve Selected'}, {value : 'reject', label : 'Reject Selected'}
									]}
									className={'common-select common-select-grey'}
									placeholder={'Bulk Approve or Reject'}
									isMulti={false}
									isClearable
                        	    	value={[
										{value : 'approve', label : 'Approve Selected'}, {value : 'reject', label : 'Reject Selected'}
									]?.find((c:any)=> c.value === reqType)}
                            		onChange={(val:any) =>{ 
										setBulkOp(val?.value)
										if(val?.value==='approve')
											ApproveModalOpen()
										else if(val?.value==='reject')
											RejectModalOpen()
									}}
									/>

                        </li> */}
							<li>
								<a onClick={() => advancemodalOpen()}>
									<i className='fa-solid fa-filter'></i>Advanced Filter
								</a>
							</li>
							{filterActive && (
								<li>
									<a onClick={clearSearch}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
							<li>
								<a
									onClick={() => {
										const controller = new AbortController();
										fetch_siteDDL(dispatch, controller.signal);

										ApproveModalOpen();
									}}>
									<i className='fa-solid'></i>Bulk Operation
								</a>
							</li>
						</ul>
					</div>
					<div className='col-6 dflex align-items-center' style={{ justifyContent: 'flex-end' }}>
						<div className='col-5'>
							<div className='form-group' style={{ margin: 0 }}>
								<ReactSelect
									options={GENERAL_DDL?.SITE_DDL}
									className={'common-select common-select-grey'}
									placeholder={'Requests in Site'}
									isMulti={false}
									isClearable
									value={site && site !== '' ? GENERAL_DDL?.SITE_DDL?.find((c) => c.value === site) : null}
									onChange={(val, { action }) => {
										setSite(val?.value);
										if (action === 'clear') {
											filterSearch();
										} else if (action === 'select-option' || action === 'pop-value') {
											filterSearch(val?.value);
										}
									}}
								/>
							</div>
						</div>
						{/* <div className='col'> */}
						<a className='btn btn-secondary modal-button' onClick={() => addModalOpen()}>
							New Request
						</a>
						{/* </div> */}
					</div>

					{/* <button onClick={()=>settest(true)}>
					new modal
				</button> */}
				</div>

				<div className='filter-block' id='quick-search'>
					<div className='row'>
						<div className='col'>
							<div className='form-group'>
								<div className='pos-rel'>
									<input
										type='text'
										name=''
										placeholder='Search Requested For'
										className='form-control'
										value={userSearch}
										onChange={(e) => {
											let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
											setUserSearch(v);
										}}
									/>
									<i className='fa-solid fa-magnifying-glass'></i>
								</div>
							</div>
						</div>
						<div className='col-2'>
							<div className='form-group'>
								<RangePicker
									picker='date'
									allowClear
									
									inputReadOnly
									allowEmpty={[true, true]}
									className='form-control'
									placeholder={['Reqested ', 'Dates']}
									separator
									value={dateRange}
									format={'DD-MMM-YYYY'}
									onChange={(dates: any) => {
										setDateRange(dates);
									}}
								/>

								{/* <DatePicker
        							selected={startDate}
        							onChange={(dates:any) =>{
										const [start, end] = dates;
										setStartDate(start)
										setEndDate(end)
									}}
        							selectsStart
									dateFormat={'dd-MMM-yyyy'}
									className='form-control'
        							startDate={startDate}
        							endDate={endDate}
									selectsRange
									placeholderText="Requested Date Between"
									monthsShown={2}
									isClearable={startDate!==''}
      							/> */}
							</div>
						</div>

						<div className='col-3'>
							<div className='form-group'>
								<ReactSelect
									options={ReqTypeArr}
									className={'common-select'}
									placeholder={'Request Type'}
									isClearable
									isMulti={false}
									value={reqType ? ReqTypeArr?.find((c: any) => c.value === reqType) : null}
									onChange={(val: any) => {
										setReqType(val?.value);
									}}
									// menuPortalTarget={document.body}
									// 	styles={{ menuPortal: base => ({ ...base,  }),
									// 	menuList: (base) => ({
									// 		...base,

									// 		"::-webkit-scrollbar": {
									// 		  width: "4px",
									// 		  height: "0px",
									// 		},
									// 		"::-webkit-scrollbar-track": {
									// 		  background: "#f1f1f1"
									// 		},
									// 		"::-webkit-scrollbar-thumb": {
									// 		  background: "#888"
									// 		},
									// 		"::-webkit-scrollbar-thumb:hover": {
									// 		  background: "#555"
									// 		}
									// 	  })

									// }}
								/>
							</div>
						</div>
						<div className='col-2'>
							<div className='form-group'>
								<ReactSelect
									options={ReqStatusArr}
									className={'common-select'}
									placeholder={'Request Status'}
									isMulti={false}
									isClearable
									value={reqStatus ? ReqStatusArr?.find((c: any) => c.value === reqStatus) : null}
									onChange={(val) => {
										setReqStatus(val?.value);
									}}
								/>
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<button
									onClick={() => {
										filterSearch();
									}}
									className='form-control blue-btn'>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
				<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
			</div>
			{addModal && <ParkingRequestAddModal open={addModal} handleClose={addModalClose} />}
			{approveModal && <BulkApproveModal handleClose={ApproveModalClose} />}
		</>
	);
};

export default FilterBar;
