import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import ParkingPolicy from './screens/parking_policy/parkingPolicy';
import PolicyAssignment from './screens/policy_assignment/policyAssignment';
import ViolationPolicy from './screens/violation_policy/violationPolicy';
import { RESET_POLICY_FILTERS_ONLY, RESET_STATE } from './store/policyslice';

type Props = {};

const PolicyIndex = (props: Props) => {
  const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
  const redirectedPath = useRedirect([
    {
      path: 'parking-policy',
      submoduleID: subModuleList['Parking Policies'],
    },
    {
      path: 'violation-policies',
      submoduleID: subModuleList['Violation Policies'],
    },
    {
      path: 'policy-assignment',
      submoduleID: subModuleList['Policy Assignment'],
    },
  ]);

  return (
    <div className='navtabs__container' onClick={()=>{ dispatch(RESET_POLICY_FILTERS_ONLY()) }}>
      <NavTabs>
        <PermissionWrapper subModuleID={subModuleList['Parking Policies']}>
          <LinkTab label='Parking Policy' href='parking-policy' />
        </PermissionWrapper>

        <PermissionWrapper subModuleID={subModuleList['Violation Policies']}>
          <LinkTab label='Violation Types' href='violation-policies' />
        </PermissionWrapper>

        <PermissionWrapper subModuleID={subModuleList['Policy Assignment']}>
          <LinkTab label='Policy Assignment' href='policy-assignment' />
        </PermissionWrapper>
      </NavTabs>
      <Routes>
        <Route
          path={'parking-policy'}
          element={
            <PermissionWrapper subModuleID={subModuleList['Parking Policies']}>
              <ParkingPolicy />
            </PermissionWrapper>
          }
        />
        <Route
          path={'violation-policies'}
          element={
            <PermissionWrapper subModuleID={subModuleList['Violation Policies']}>
              <ViolationPolicy />
            </PermissionWrapper>
          }
        />
        <Route
          path={'policy-assignment'}
          element={
            <PermissionWrapper subModuleID={subModuleList['Policy Assignment']}>
              <PolicyAssignment />
            </PermissionWrapper>
          }
        />
        <Route path={'/*'} element={<Navigate to={redirectedPath} />} />
      </Routes>
    </div>
  );
};

export default PolicyIndex;
