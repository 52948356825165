type Props = {
    data?: any;
    handleClose: () => void;
    ConfirmFx?:()=> void
}

const FailedMessagePop = (props: Props) => {
    return (
        <div className={`modal-popup delete-not text-center modal-open`} id="delete-note">
            <div className="modal-main-wrap">
                <div className="modal-popup-wrapper">
                    <div className="modal-header" style={{ paddingBottom:'5%' }}>
                        <div className="trash-icon">
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                        <h3> {props?.data?.response?.appData || "Somthing went Wrong !"}</h3>
                        {/* <a className="close-modal close-modal-button" onClick={props.handleClose}></a> */}
                    </div>

                    <div className="modal-body">

                        <form>
                            <div className="row">
                                <div className="col-12 text-center text-grey" >
                                    <h4 style={{ fontWeight:400}}>{props?.data?.response?.devData || "An unexpected error has occured"}</h4>
                                </div>
                                <div className="col-12 ">
                                    <div className="modal-footer dflex justify-content-center">
                                        <button type="submit" className="btn btn-secondary" onClick={(e)=> { 
                                            e.preventDefault();
                                            props?.handleClose&&props?.handleClose();
                                            props?.ConfirmFx&&props?.ConfirmFx();
                                            }}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FailedMessagePop