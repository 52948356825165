/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_violations_chart } from '../../store/action';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import { DatePicker } from 'antd';
import RLineChart from '../LineChart';
import moment from 'moment';
// import CustomDatePicker from '../../../../common/components/CustomDatePicker';

type Props = {
	name: string;
};

const { RangePicker } = DatePicker;

let typeOption = [
	{
		label: 'Wrong Parking',
		value: 1,
	},
	{
		label: 'OVernight',
		value: 2,
	},
	{
		label: 'Others',
		value: 3,
	},
];

const ViolationOverall = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);
	// const [date, setdate] = useState<any>(moment().format('YYYY'));
	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);
	const [response, setresponse] = useState<any>();
	const [type, settype] = useState(typeOption[0]);
	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [loading, setloading] = useState(false)

	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			Type: type?.value,
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			// year: date,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
		};

		if (floor?.levelID) {
			setloading(true)
			fetch_violations_chart(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					setresponse(res?.response);

					setdata(data);
					setTimeout(() => {
						setloading(false)
					}, 1500);
				}
			});
		}

		return () => {
			controller.abort();
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value, type?.value, date]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	const checkIfReset = () =>{
		return ((date ? moment(date[1]).diff(date[0],'days') !==7 : false)) ? true : false 
	}

	const resetFilter = () => {
		setdate([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')])
		handleClose()
	}

	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>
						Parking violations by date-wise
					</div>
					<div>
						<div style={{ fontSize: "12px" }}>
							{
								date[0].format('DD MMM YYYY hh:mm A')} - {date[1].format('DD MMM YYYY  hh:mm A')
							}
						</div>
						<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose} clearAll={checkIfReset() ? resetFilter : null}>
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Date Range</label>
								{/* <CustomDatePicker
									picker='year'
									size='large'
									onChange={(date: any) => setdate(date?.format('YYYY'))}
									value={moment(date)}
									allowClear={false}
									format={'YYYY'}
								/> */}
								<RangePicker  
									size='large' 
									//showTime  
									inputReadOnly
									onChange={(date: any) => setdate(date)}
									value={date}
									allowClear={false}
								/>	
							</div>
						</DashboardFilterWrapper>
					</div>
				</div>
				<div className='ItemLayout__Content'>
					<div className='graph-tab-list mb-30 gap-10'>
						<div className={type?.value === 1 ? 'graph-box active' : 'graph-box'}>
							<a onClick={() => settype(typeOption[0])} className='graph-text'>
								Wrong Parking <span>{response?.totalWrong}</span>
							</a>
						</div>
						<div className={type?.value === 2 ? 'graph-box active' : 'graph-box'}>
							<a onClick={() => settype(typeOption[1])} className='graph-text'>
								Over Time <span>{response?.totalOvernigh}</span>
							</a>
						</div>
						<div className={type?.value === 3 ? 'graph-box active' : 'graph-box'}>
							<a onClick={() => settype(typeOption[2])} className='graph-text'>
								Other Violations <span>{response?.totalOthers}</span>
							</a>
						</div>
					</div>

					<RLineChart data={data} xAxisName='month' yAxisName='violations' numberOnlyInY={false} />
				</div>
			</div>
		</>
	);
};

export default ViolationOverall;
