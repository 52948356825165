import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuItem } from '@mui/material';

export function SortableItem({ id, column, disableCheckBox }: { id: any; column: any; disableCheckBox?:boolean }) {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	console.log("Coulumn",column,"TOGGLEPROPS=>",{...column.getToggleHiddenProps()})
	return (
		<div ref={setNodeRef} style={style}  className={disableCheckBox ? 'opacity-half' : ''}>
			<MenuItem sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}>
				<button {...listeners} {...attributes} style={{ background: 'transparent', border: 'none', padding: '8px 0px', cursor: 'grab' }}>
					<i className='fa-solid fa-grip-vertical'></i>
				</button>
				<label
					style={{
						fontSize: '14px',
						fontWeight: 600,
						textTransform: 'uppercase',
						display: 'flex',
						gap: '10px',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexGrow: 1,
					}}>
					<span>{column?.Header}</span>
					<input type='checkbox' {...column.getToggleHiddenProps()} style={{ border:'1px solid red'}} />
				</label>
			</MenuItem>
		</div>
	);
}
