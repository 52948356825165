import React, { Fragment } from 'react';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import MainLayout2 from '../../../../common/layouts/MainLayout2';
import { subModuleList } from '../../../../config/subModuleList';
import AssignPolicy from './components/AssignPolicy';
import FilterBar from './components/FilterBar';
import ListTable from './components/ListTable';
type Props = {};

const PolicyAssignment = (props: Props) => {
	return (
		<Fragment>
			<PermissionWrapper subModuleID={subModuleList['Policy Assignment']} type='isCreate'>
				<MainLayout2>
					<AssignPolicy />
				</MainLayout2>
			</PermissionWrapper>

			<div className='white-card data-clean'>
				<FilterBar />
				<ListTable />
			</div>
		</Fragment>
	);
};

export default PolicyAssignment;
