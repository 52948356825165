import Request from '../../../config/api';
import { UPDATE_GENERAL_DASHBOARD_DATA, UPDATE_GENERAL_DASHBOARD_ERROR, UPDATE_GENERAL_DASHBOARD_LOADER, UPDATE_ROLES_DATA, UPDATE_ROLES_ERROR, UPDATE_ROLES_LOADER, UPDATE_WEBUSERS_DATA, UPDATE_WEBUSERS_ERROR, UPDATE_WEBUSERS_LOADER, UPDATE_WEBUSER_REQUEST_DATA, UPDATE_WEBUSER_REQUEST_ERROR, UPDATE_WEBUSER_REQUEST_LOADER } from './securitySlice';
import { SECURITY_ROLES, SECURITY_ROLES_ACTION, SECURITY_WEBUSERS, SECURITY_ROLES_PSWRD, USERS, DEFINITION_LOCATION_TREE, SECURITY_WEBUSERS_ACTION, SECURITY_WEBUSER_REQUEST, SECURITY_WEBUSER_REQUEST_ACTION, SECURITY_WEBUSER_REQUEST_STATUS, SECURITY_WEBUSER_BY_ZONE, SECURITY_WEBUSERS_PASSWORD_RESET, GENERAL_DASHBOARD_SETTINGS, GENERAL_DASHBOARD_SETTINGS_ACTION, SECURITY_SYS_USER_TYPE, DEFINITION_LOCATION_TREE_WITHOUT_PERMISSION } from '../../../config/endpoint';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_rolelist = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = SECURITY_ROLES;

    dispatch(UPDATE_ROLES_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_ROLES_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {

            dispatch(UPDATE_ROLES_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const role_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = SECURITY_ROLES_ACTION;
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
            
        }
    });
};

export const role_detail = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = SECURITY_ROLES + id + '/';
    Request('get', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const delete_role = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = SECURITY_ROLES_ACTION + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};

export const fetch_webusers = (dispatch: any, postData: any, signal?: AbortSignal) => {
    dispatch(UPDATE_WEBUSERS_LOADER({
        loader: true,
    }))
    let api_url = SECURITY_WEBUSERS;
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_WEBUSERS_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_WEBUSERS_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const fetch_webusers_request = (dispatch: any, postData: any, signal?: AbortSignal) => {
    dispatch(UPDATE_WEBUSER_REQUEST_LOADER({
        loader: true,
    }))
    let api_url = SECURITY_WEBUSER_REQUEST;
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_WEBUSER_REQUEST_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_WEBUSER_REQUEST_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const webusers_detail = (id: number) => {
    let api_url = SECURITY_WEBUSERS + id + '/';
    return Request('get', api_url, '', undefined)
};

export const webusers_action = (type: t, postData: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = SECURITY_WEBUSERS_ACTION;
    Request(type, api_url, postData, undefined, sucess, failed)
};


export const webusers_delete = (id: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = SECURITY_WEBUSERS_ACTION +  id + '/';
    Request("delete", api_url, '', undefined, sucess, failed)
};

export const webusers_request_action = (type: t, postData: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = SECURITY_WEBUSER_REQUEST_ACTION;
    Request(type, api_url, postData, undefined, sucess, failed)
};


export const webusers_request_status = (type: t, postData: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = SECURITY_WEBUSER_REQUEST_STATUS;
    Request(type, api_url, postData, undefined, sucess, failed)
};

export const password_reset = (type: t, postData: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = SECURITY_WEBUSERS_PASSWORD_RESET;
    Request(type, api_url, postData, undefined, sucess, failed)
};


export const password_policy = (type: t, postData: any, Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = SECURITY_ROLES_PSWRD;
    Request(type, api_url, postData, undefined, Success, Failed)
};


export const search_user = (postData: any, signal?: AbortSignal) => {
    let api_url = USERS;
    return Request('post', api_url, postData, signal,)

};
export const search_web_user = (postData: any, signal?: AbortSignal) => {
    let api_url = SECURITY_WEBUSERS;
    return Request('post', api_url, postData, signal,)

};

export const search_web_user_request = (postData: any, signal?: AbortSignal) => {
    let api_url = SECURITY_WEBUSER_REQUEST;
    return Request('post', api_url, postData, signal,)

};
export const fetch_webusers_by_zone = (zoneID:number) => {
    let api_url = SECURITY_WEBUSER_BY_ZONE + zoneID + '/';
    return Request('get', api_url, "")

};
export const fetch_treeMap_withPermission = (Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = DEFINITION_LOCATION_TREE;
    Request('get', api_url, '', undefined, Success, Failed)
}

export const fetch_treeMapWithoutPermission = (Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = DEFINITION_LOCATION_TREE_WITHOUT_PERMISSION;
    Request('get', api_url, '', undefined, Success, Failed)
}


export const fetch_sys_userType = (Success?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = SECURITY_SYS_USER_TYPE;
   return  Request('get', api_url, '', undefined, Success, Failed)
}

export const fetch_rolelist_ddl = async (postData: any, signal?: AbortSignal) => {
    let api_url = SECURITY_ROLES;
    return await Request('post', api_url, postData, signal)
};

export const fetch_general_dashboard_settings = (dispatch: any, postData: any, signal?: AbortSignal) => {
    dispatch(UPDATE_GENERAL_DASHBOARD_LOADER({
        loader: true,
    }))
    let api_url = GENERAL_DASHBOARD_SETTINGS;
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {

            let formatteddata = data.map((item:any)=>({label :item?.name ,value:item?.dashboardID , ...item})) ?? []
            dispatch(
                UPDATE_GENERAL_DASHBOARD_DATA({
                   data : formatteddata,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_GENERAL_DASHBOARD_ERROR({
                error: "some error occurred",
            }));
        }
    });
};

export const general_dashboard_settings_action = (type: t, postData: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = GENERAL_DASHBOARD_SETTINGS_ACTION;
    Request(type, api_url, postData, undefined, sucess, failed)
};


