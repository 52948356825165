import { useEffect, useState } from 'react';
import { useAppSelector } from '../../config/hooks';
import { FrontendPermision, sub_modules } from '../../modules/security/screens/Roles/components/types';

type Iprops = {
	path: string;
	submoduleID: number | string;
};

export const useRedirect = (path: Iprops[]) => {
	const MODULE_PERMISSIONS = useAppSelector((state) => state?.login?.USER_PERMISSION);
	const [redirectPath, setredirectPath] = useState<string>('');

	useEffect(() => {
		if (path) {
			for (let i = 0; i < path.length; i++) {
				if (CheckPermission(MODULE_PERMISSIONS, Number(path[i]?.submoduleID))) {
					setredirectPath(path[i]?.path);	
					break;
				}
			}
		}
	}, [MODULE_PERMISSIONS, path]);

	return redirectPath;
};

const CheckPermission = (MODULE_PERMISSIONS: FrontendPermision[], subModuleID: number) => {
	let HasAccessModules = [...MODULE_PERMISSIONS.filter((item) => item?.user_has_access === true)];
	let SubModules: sub_modules[] = [];
	HasAccessModules.forEach((item) => {
		SubModules = [...SubModules, ...item?.sub_modules];
	});
	let currentSubmodule = SubModules.find((item) => item?.fK_SubModuleID === subModuleID);
	return currentSubmodule?.isView;
};
