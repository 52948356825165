import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { add_blacklisted, fetch_blacklisted_list } from '../../../store/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { useNavigate } from 'react-router-dom';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import DeleteConfirm from '../../../../../common/components/DeleteConfirm';
import { fetch_all_usersSearch } from '../../../../parking_request/store/action';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import FormInput from '../../../../../common/components/FormInput';
import FormTextarea from '../../../../../common/components/FormTextarea';
import FormSelect from '../../../../../common/components/FormSelect';
import { fetch_placeOfRegList } from '../../../../../common/general/action';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';


type Props = {
	open: boolean;
	handleClose: () => void;
};
const schema = yup.object().shape({
	PlateNo: yup.string().required(),
	Vehicle_Category: yup.string().required(),
	Place_Of_Registration: yup.string().required(),
	// confirmPlateNo 				: yup.string().oneOf([yup.ref('PlateNo'), null], 'Plate Numbers must match').required(),
	Descriptions: yup.string().required()
});

const AddModal = (props: Props) => {
	const {
		register,
		control,
		handleSubmit,
		watch,
		formState,
		reset,
		clearErrors
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [deleteModal, setDeleteModal] = useState(false);
	const [data, setData] = useState<any>()
	const cancelDelete = () => { setDeleteModal(false); setloading(false); }
	const [plateText, setPlateText] = useState('')
	const [plateCatText, setPlateCatText] = useState('')
	const [placeOfReg, setPlaceOfReg] = useState('')
	const [usersList, setUsersList] = useState<any>();

	const [loading, setloading] = useState(false)
	const [deleteLoader, setDeleteLoader] = useState(false)

	useEffect(() => {
		const controller = new AbortController();
		fetch_placeOfRegList(dispatch, controller.signal)
	
		return () => controller.abort();
	}, [dispatch])
	

	const handleUserInputChange = async (key?: string) => {
		let Response = await fetch_all_usersSearch(key);
		console.log('RESPONSE', Response);
		let data = Response?.response?.data ?? [];

		console.log('options', data);
		setUsersList(data);
		// setUserVehicleList([])

		// return data;
	};
	const PLACEOFREG_DDL = useAppSelector((state) => state?.general?.PLACEOFREG_DDL);

	const blackListVeh = (e: any) => {
		setDeleteLoader(true)
		let postData = {
			ID: null,
			PlateNo: e.PlateNo,
			Vehicle_Category: e.Vehicle_Category,
			Place_Of_Registration: e.Place_Of_Registration,
			Descriptions: e.Descriptions,
		}
		const Success = (res: any) => {
			fetch_blacklisted_list(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				}
			})
			SuccesToast(res?.data)
			setDeleteLoader(false)
			setPlateText('')
			setPlateCatText('')
			setPlaceOfReg('')
			setUsersList([])

			reset()
			clearErrors()
			props.handleClose()
			setloading(false)
			setDeleteModal(false)
			
		}
		const Failed = (err: any) => {
			FailedToast(err?.response)
			setDeleteLoader(false)
			setloading(false)
			setDeleteModal(false)

		}
		add_blacklisted('post', postData, Success, Failed)
	}
	const onSubmitHandler = (e: any) => {
		setData(e)
		setloading(true)
		setDeleteModal(true)
	};

	const UserExist = () => {
		console.log('Users=>', usersList)
		// let plNArr = usersList && usersList?.length!==0 ? 
		// 	usersList?.map((el:any) => {
		// 		return	{
		// 			'plate' : el?.plateNumber.toLowerCase(),
		// 			'reg' 	: el?.placeOfRegistration.toLowerCase(), 
		// 			'cat' 	: el?.vehicleCategory.toLowerCase()
		// 		}	
		// 	}) : []
		if (plateText !== '' && plateCatText !== '' && placeOfReg !== '' && usersList && usersList?.length !== 0) {
			// let tempObj = {
			// 	'plate' : plateText.toLowerCase(),
			// 	'reg' 	: placeOfReg.toLowerCase(), 
			// 	'cat' 	: plateCatText.toLowerCase()
			// }
			let obj = usersList?.find((el: any) =>
				el?.plateNumber.toLowerCase() === plateText.toLowerCase() && el?.placeOfRegistration.toLowerCase() === placeOfReg.toLowerCase() &&
				el?.vehicleCategory.toLowerCase() === plateCatText.toLowerCase())

			if (obj) {
				let txt = `Vehicle owner : ${obj?.fullName} | Type : ${obj?.isVisitor ? `Visitor` : `Employee`} `
				return <StatusChipComponent text={txt} />
			} else
				return <></>
		}
		else
			return <></>
	}


	console.log(formState.errors)
	return (
		<>
			<ModalLayout open={props.open} 
				handleClose={() => { 
					reset(); 
					clearErrors(); 
					setPlateText('')
					setPlateCatText('')
					setPlaceOfReg('')
					setUsersList([])
					props.handleClose(); 
				}} title='Add To Blacklist' subtitle=''>
				<form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
					<div className='col-12'>
						<div className='user-detail-form'>
							<div className='form-w-label modal-form'>
								<div className='row align-items-center'>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											<label htmlFor=''>Plate Number</label>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											{/* <input type='text' {...register('PlateNo')} placeholder='ABC123' className='form-control'
												onChange={(e) => {
													let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
													setPlateText(v)
													handleUserInputChange(v)
												}}
											/> */}
											<FormInput type='text' name="PlateNo" className='form-control' control={control}
												onChange={(e, formOnChange) => {
													let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
													formOnChange(v);
													setPlateText(v);
													handleUserInputChange(v);
												}} />

											<ErrorComponent name={'PlateNo'} title={'Plate Number'} errors={formState.errors} />
										</div>
									</div>
								</div>
								<div className='row align-items-center'>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											<label htmlFor=''>Vehicle Category</label>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											{/* <input type='text' {...register('Vehicle_Category')} placeholder='Enter Vehicle Category' className='form-control'
												onChange={(e) => {
													let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
													setPlateCatText(v)
												}}
											/> */}
											<FormInput type='text' name="Vehicle_Category" className='form-control' control={control}
												onChange={(e, formOnChange) => {
													let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
													formOnChange(v);
													setPlateCatText(v)
												}} />

											<ErrorComponent name={'Vehicle_Category'} title={'Vehicle Category'} errors={formState.errors} />
										</div>
									</div>
								</div>
								<div className='row align-items-center'>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											<label htmlFor=''>Place of Registration</label>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											{/* <input type='text' {...register('Place_Of_Registration')} placeholder='Enter PLace of Registration' className='form-control'
												onChange={(e) => {
													let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
													setPlaceOfReg(v)
												}}
											/> */}
											{/* <FormInput type='text' name="Place_Of_Registration" className='form-control' control={control}
												onChange={(e, formOnChange) => {
													let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
													formOnChange(v);
													setPlaceOfReg(v)

												}} /> */}
											<FormSelect
												control={control}
												name='Place_Of_Registration'
												options={PLACEOFREG_DDL || []}
												grey={true}
												classFx='form-control'
												isMulti={false}
												placeholder=''
											/>
											<ErrorComponent name={'Place_Of_Registration'} title={'Place Of Registration'} errors={formState.errors} />
										</div>
									</div>
								</div>
								{/* <div className='row align-items-center'>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											<label htmlFor=''>Reconfirm Plate Number.</label>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group form-group-hr'>
											<input type='text' {...register('confirmPlateNo')} placeholder='XX-YY-ZZ' className='form-control' />
											<ErrorComponent name={'confirmPlateNo'} title={'Confirm Plate Number'} errors={formState.errors} />
										</div>
									</div>
								</div> */}
								<div className='row'>
									<div className='col-12'>
										{/* <textarea
											{...register('Descriptions')}
											placeholder='Remarks'
											className='form-control'
											id=''
											cols={30}
											rows={10}
										>


										</textarea> */}

										<FormTextarea name="Descriptions" placeholder='Remarks' className='form-control ' control={control}

										/>
										<ErrorComponent name={'Descriptions'} title={'Remarks'} errors={formState.errors} />
									</div>
								</div>
							</div>
							<UserExist />

						</div>
					</div>
					<div className="col-12">
						<div className="modal-footer">
							<button type='submit' className="btn btn-secondary border-blue" disabled={loading}>
								<ButtonLoader text="Add To Blacklist" loading={loading} />
							</button>
							<button type='reset' 
								onClick={() => { 
									setPlateText('')
									setPlateCatText('')
									setPlaceOfReg('')
									setUsersList([])
									reset(); clearErrors(); props.handleClose(); }} className="btn btn-outline-blue close-modal-button">Close</button>
						</div>
					</div>

				</form>
			</ModalLayout>
			{deleteModal &&
			<CustomConfirm
			open={deleteModal}
			handleClose={cancelDelete}
			title='Are you sure?'
			description='Do you want to blacklist this vehicle?'>

			<button
			disabled={deleteLoader}
				type='submit'
				className='btn btn-secondary'
				onClick={(e) => {
					e.preventDefault();
					if (data) blackListVeh(data);
				}}>
			<ButtonLoader loading={deleteLoader} text="Confirm" />
				
			</button>

			</CustomConfirm>
				// <DeleteConfirm
				// 	description='Do you want to blacklist this vehicle?'
				// 	handleClose={cancelDelete}
				// 	open={true}
				// 	ConfirmFx={blackListVeh}
				// 	confirmLoading={deleteLoader}
				// 	id={data}
				// />
			}
		</>
	);
};

export default AddModal;
