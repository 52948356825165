import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_average_occupancy } from '../../store/action';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import { DatePicker, Select } from 'antd';
import RLineChart from '../LineChart';
import moment from 'moment';
import ReactSelect from 'react-select';
// import dayjs from 'dayjs';
// import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

let typeOption = [
	{
		label: 'Date Wise',
		value: 1,
	},
	{
		label: 'Hour Wise',
		value: 2,
	},
];



const OccupancyOverall = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);
	const [type, settype] = useState(typeOption[0]);
	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [loading, setloading] = useState(false)
	const [zone, setzone] = useState<any>();
	const [isUnRecVeh, setIsUnRecVeh] = useState('0')
	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);


	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
		setzone(DASHBOARD_DATA?.ZONE_LIST&&DASHBOARD_DATA?.ZONE_LIST[0])
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			type: type.value,
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			FromTime: date[0].format('HH:MM:00'),
			ToTime: date[1].format('HH:MM:00'),
			ZoneID: zone?.value??0,
			FloorID: floor?.levelID,
			UnknownVehicle : isUnRecVeh
			// zoneID: date[1].format('HH:MM:00'),

		};

		
			setloading(true)

			fetch_average_occupancy(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					let formattedData = data.map((item: any) => ({
						date: item?.vDate ? moment(item?.vDate).format('YYYY-MM-DD') : null,
						time: item?.times ? item?.times.slice(0, -3) : null,
						'Occupancy Average' : Math.round(item?.occupancy),
						...item,
					}));
					setdata(formattedData);

					setTimeout(() => {
						setloading(false)
					}, 1500);

				}
			});

			let timer = setInterval(() => {
				fetch_average_occupancy(postData, signal)?.then((res) => {
					if (res?.statusCode < 400) {
						let data = res?.response?.data ?? [];
						let formattedData = data.map((item: any) => ({
							date: item?.vDate ? moment(item?.vDate).format('YYYY-MM-DD') : null,
							time: item?.times ? item?.times.slice(0, -3) : null,
							'Occupancy Average' : Math.round(item?.occupancy),
							...item,
						}));
						setdata(formattedData);
	
						setTimeout(() => {
							setloading(false)
						}, 1500);
	
					}
				});
			}, 120000);
		

		return () => {
			controller.abort();
			clearInterval(timer);
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value, type.value, date, zone, isUnRecVeh]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>
						{props.name} by {type.label}
					</div>
					<div>
						<div style={{ fontSize: "12px" }}>
							{
								date[0].format('DD MMM YYYY hh:mm A')} - {date[1].format('DD MMM YYYY  hh:mm A')
							}
						</div>
						<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose} >
							
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker
									size='large'
									showTime  inputReadOnly
									onChange={(date: any) => setdate(date)}
									value={date}
									allowClear={false}
								/>
							</div>
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Type</label>
								<Select
									className='common-select-grey'
									options={typeOption}
									onChange={(_: any, option: any) => settype(option)}
									value={type?.value}
									placeholder='type'
									style={{ width: "100%" }}
									size='large'

								/>
							</div>
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Floor</label>
								<ReactSelect
									className='common-select-grey'
									options={DASHBOARD_DATA?.FLOOR_LIST}
									getOptionLabel={(o: any) => o.levelName}
									getOptionValue={(o: any) => o.levelID}
									onChange={(e: any) => setfloor(e)}
									value={DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''}
									placeholder='Floor'
								/>
							</div>
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Zone</label>
								<ReactSelect
									className='common-select-grey'
									options={DASHBOARD_DATA?.ZONE_LIST}
									onChange={(e: any) => setzone(e)}
									value={DASHBOARD_DATA?.ZONE_LIST.find((c: { value: number }) => c.value === zone?.value) ?? ''}
									placeholder='Zone'
								/>
							</div>
							<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Vehicle Type</label>
									<ReactSelect
										className='common-select-grey'
										options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								onChange={(val:any) =>{ setIsUnRecVeh(val?.value); }}
										value={
											[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]?.find((el:any) => el.value===isUnRecVeh) ?? ''
										}
										placeholder={'Vehicle Type'}
									/>
							</div>
						</DashboardFilterWrapper>
					</div>
				</div>

				<div className='ItemLayout__Content'>
					<RLineChart data={data} xAxisName={type?.value === 2 ? 'time' : 'date'} yAxisName='Occupancy Average' xTickCount={1} labelY='Bay'/>
				</div>
			</div>
		</>
	);
};

export default OccupancyOverall;
