import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../../dashboard/style.css';
import '../../../dashboard/components/style.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { widgets } from './AllDashboardWidgets';
import Widget from './Widget';
import { dl_layout } from './defaulLayoutFull';
import SelectDashboardItems from './SelectDashboardItems';
import { fetch_general_dashboard_settings } from '../../store/action';
import { useAppDispatch, useAppSelector } from './../../../../config/hooks';
// import { Select } from 'antd';
import AddModal from './AddModal';
import {
	UPDATE_DASHBOARD_BAYTYPE_LIST,
	UPDATE_DASHBOARD_FLOOR_LIST,
	UPDATE_DASHBOARD_SELECTED_FLOOR,
	UPDATE_DASHBOARD_SELECTED_SITE,
	UPDATE_DASHBOARD_SITE_LIST,
	UPDATE_DASHBOARD_ZONE_LIST,
} from '../../../dashboard/store/dashboardSlice';
import { FetchBayType, FetchFloors, FetchSites, FetchZones } from '../../../realtime_monitior/store/action';
import Select from 'react-select';

type Props = {};

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardCustomization = (props: Props) => {
	const GeneralDashboards: any = useAppSelector((state) => state?.security?.GENERAL_DASHBOARD_LIST_DATA);

	let dispatch = useAppDispatch();
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		FetchSites(signal).then((res) => {
			if (res?.statusCode === 200) {
				let data = res?.response ?? [];
				dispatch(UPDATE_DASHBOARD_SITE_LIST({ data }));
				dispatch(UPDATE_DASHBOARD_SELECTED_SITE({ data: data[0] }));
			}
		});
		return () => {
			controller.abort();
		};
	}, [dispatch]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (DASHBOARD_DATA?.SELECTED_SITE?.value) {
			FetchFloors(DASHBOARD_DATA?.SELECTED_SITE?.value, signal).then((response) => {
				if (response?.statusCode === 200) {
					let floorList = response?.response?.data ?? [];
					dispatch(UPDATE_DASHBOARD_FLOOR_LIST({ data: floorList }));
					dispatch(UPDATE_DASHBOARD_SELECTED_FLOOR({ data: floorList[0] }));
				}
			});
		}

		return () => {
			controller.abort();
		};
	}, [dispatch, DASHBOARD_DATA?.SELECTED_SITE?.value]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (DASHBOARD_DATA?.SELECTED_SITE?.value) {
			FetchZones(DASHBOARD_DATA?.SELECTED_SITE?.value, signal).then((response) => {
				if (response?.statusCode === 200) {
					let data = response?.response ?? [];
					dispatch(UPDATE_DASHBOARD_ZONE_LIST({ data }));
				}
			});
		}
		return () => {
			controller.abort();
		};
	}, [dispatch, DASHBOARD_DATA?.SELECTED_SITE?.value]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		FetchBayType(signal).then((response) => {
			if (response?.statusCode === 200) {
				let data = response?.response ?? [];
				dispatch(UPDATE_DASHBOARD_BAYTYPE_LIST({ data }));
			}
		});
		return () => {
			controller.abort();
		};
	}, [dispatch]);
	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		fetch_general_dashboard_settings(
			dispatch,
			{
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 1000,
				},
			},
			signal
		);
		return () => {
			controller.abort();
		};
	}, [dispatch]);

	const [layouts, setLayouts] = useState(dl_layout);
	const [items, setItems] = useState<any[]>(widgets);

	const [selectedDashboard, setselectedDashboard] = useState<any>();

	useEffect(() => {
		if (selectedDashboard) {
			if (selectedDashboard?.permission) {
				let permission = JSON.parse(selectedDashboard?.permission) ?? [];
				let newItems = widgets.filter((item) => permission.includes(item?.id));

				setItems(newItems);

				if (selectedDashboard?.items) {
					setLayouts(JSON.parse(selectedDashboard?.items));
				}
			}
		}
	}, [selectedDashboard]);

	useEffect(() => {
		if (GeneralDashboards) {
			setselectedDashboard(GeneralDashboards[0]);
		}
	}, [GeneralDashboards]);

	const onLayoutChange = (_: any, allLayouts: any) => {
		setLayouts(allLayouts);
	};

	const onRemoveItem = (itemId: number) => {
		setItems(items.filter((i) => i.id !== itemId));
	};
	const onAddItem = (itemId: number) => {
		console.log('ITEM ID', itemId);
		let newData = widgets.find((item) => item?.id === itemId);
		setItems([...items, newData]);

		console.log('newData', newData);
		console.log('items', items);
	};

	const [selectItemsModal, setselectItemsModal] = useState(false);

	const handleClose = () => setselectItemsModal(false);
	const handleOpen = () => setselectItemsModal(true);

	const [addModal, setaddModal] = useState(false);

	const [data, setData] = useState<any>();
	const handleAddOpen = (dataC?: any) => {
		setData(dataC);
		setaddModal(true);
	};

	useEffect(() => {
		let clear = setInterval(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
		return () => {
			clearInterval(clear);
		};
	}, []);



	return (
		<>
			<div>
				<div className='navtabs__container' style={{ position: 'relative' }}>
					<div
						className='dflex gap-10 ml-10'
						style={{ position: 'relative', right: '15px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
						<div className='dflex gap-10 '>
				
							<Select
								defaultValue={GeneralDashboards[0]?.value}
			
								options={GeneralDashboards}
								className={'common-select-small'}
								value={GeneralDashboards.find((c: any) => c.dashboardID === selectedDashboard?.dashboardID) ?? ''}
								onChange={(option: any) => {
									setselectedDashboard(option);
									// setLayouts(dl_layout)
									setLayouts(JSON.parse(option.items));
								}}
							/>
						</div>
		
						<div className='dflex gap-10'>
							<div>
								<button className='filter-btn btn-primary' onClick={handleOpen}>
									View All Widgets
								</button>
							</div>
							<div>
								<button className='filter-btn btn-primary' onClick={() => handleAddOpen(null)}>
									Create New Dashboard
								</button>
							</div>

							<div>
								<button className='filter-btn btn-primary' onClick={() => handleAddOpen(selectedDashboard)}>
									Update Dashboard
								</button>
							</div>
				
						</div>
					</div>
					<ResponsiveGridLayout
						className='layout'
						layouts={layouts}
						breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
						cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
						rowHeight={60}
						isDraggable={true}
						isResizable={true}
						onLayoutChange={onLayoutChange}>
						{items &&
							items.map((item) => {
								//console.log(item?.name, item?.dataGrid);
								return (
									<div key={item?.name} className='widget' data-grid={item?.dataGrid}>
										<div className='widget-remove' onClick={() => onRemoveItem(item?.id)}>
											<i className='fa-solid fa-circle-xmark' style={{ fontSize :".75rem"}}></i>
										</div>
										<Widget id={item?.id} component={item?.component} name={item?.name} NoLayout={item?.NoLayout} />
									</div>
								);
							})}
					</ResponsiveGridLayout>
				</div>
			</div>
			<SelectDashboardItems
				onRemoveItem={onRemoveItem}
				onAddItem={onAddItem}
				open={selectItemsModal}
				handleClose={handleClose}
				items={items}
			/>
			{<AddModal handleClose={() => setaddModal(false)} open={addModal} data={data} cur_layouts={layouts} permissions={items} />}
		</>
	);
};

export default DashboardCustomization;
