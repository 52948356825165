import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../common/layouts/ModalLayout';
import { useAppDispatch } from '../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { fetch_general_dashboard_settings, general_dashboard_settings_action } from '../../store/action';
import ButtonLoader from './../../../../common/components/ButtonLoader';

type Props = {
	open: boolean;
	handleClose: () => void;
	data?: any;
	permissions?:any
	cur_layouts: any;
};

const AddModal = (props: Props) => {
	const [name, setname] = useState(props?.data?.name ?? '');
	const [loader, setloader] = useState(false);
	let dispatch = useAppDispatch();

	useEffect(() => {
		if (props?.data?.name) {
			setname(props?.data?.name);
		}
	}, [props?.data?.name]);

	const UpdateDashboard = () => {
		setloader(true);
		let postData: any;

		let permission : any = []
		if(props?.permissions){
			props.permissions.forEach((item: { id: any; })=>{
				permission.push(item?.id)
			})
		}
		  
		if (props?.data) {
			postData = {
				'DashboardID': props?.data?.dashboardID,
				'name': name,
				'items': JSON.stringify(props?.cur_layouts),
				'permission' : JSON.stringify(permission)
			};
		} else {
			postData = {
				'name': name,
				'items': JSON.stringify(props?.cur_layouts),
				'permission' : JSON.stringify(permission)

			};
		}

		console.log("POSTDATA DASHBOARD",postData)
		console.log("POSTDATA DASHBOARD JSON LAY",props?.cur_layouts)

		const sucess = (res: any) => {
			SuccesToast(res?.response?.data);
			setloader(false);
			fetch_general_dashboard_settings(dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 1000,
				},
			});
            props?.handleClose()
		};
		const failed = (err: any) => {
			FailedToast(err?.response?.app_data);
			setloader(false);
		};

		if (props?.data) {
			general_dashboard_settings_action('put', postData, sucess, failed);
		} else {
			general_dashboard_settings_action('post', postData, sucess, failed);
		}
	};

	//  const UpdateDashboard = () => {
	//     let postData = {
	// 		"name":name,
	// 		"items":dl_layout
	// 	}
	// 	const sucess = (res:any) => {  }
	// 	const failed = (err:any) => {  }

	// 	general_dashboard_settings_action('post',postData ,sucess,failed)
	//   }

	return (
		<ModalLayout
			title={props?.data ? 'Update Dashboard' : 'Create Dashboard'}
			subtitle={props?.data ? 'Update current dashboard layout and name' : 'Create new dashboard with current layout'}
			open={props.open}
			handleClose={props?.handleClose}>
			<div>
				{props?.data ? (
					<>
						<div className='col-12'>
							<div className='form-group'>
								<label>Dashboard Name</label>
								<input
									type='text'
                                    value={name}
									placeholder=''
									onChange={(e) => setname(e.target.value)}
									className='form-control grey-input'
								/>
							</div>
						</div>
					</>
				) : (
					<>
						<div className='col-12'>
							<div className='form-group'>
								<label>Dashboard Name</label>
								<input type='text' placeholder='' onChange={(e) => setname(e.target.value)} className='form-control grey-input' />
							</div>
						</div>
					</>
				)}
			</div>

			<div className='modal-footer'>
				<button type='submit' className={name=== ""? "btn btn-secondary border-blue opacity-half" :'btn btn-secondary border-blue'} onClick={UpdateDashboard} disabled={loader}>
					<ButtonLoader loading={loader} text={props?.data ? 'Update dashboard' : 'Create Dashboard'} />
				</button>

				<button
					type='reset'
					className='btn btn-outline-blue close-modal-button'
					onClick={() => {
						props.handleClose();
					}}>
					Close
				</button>
			</div>
		</ModalLayout>
	);
};

export default AddModal;
