import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { search_user } from '../../../../security/store/action';
import AsyncSelect from 'react-select/async';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import {
	delete_notification_exception_user,
	fetch_notificationTemplate_list,
	fetch_notification_exception,
	update_notification_exception,
} from '../../../store/action';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import FormSelectMultiple from '../../../../../common/components/FormSelectMultiple';
import ButtonLoader from '../../../../../common/components/ButtonLoader';

import { DatePicker } from "antd"
import moment from 'moment';
const { RangePicker } = DatePicker;

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

const schema = yup.object().shape({
	descriptions: yup.string().required(),
	isTemporary: yup.boolean().required(),
	fK_TemplateIDs: yup.array().of(yup.number()).required(),
	startDate: yup.date().required(),
	endDate: yup
		.date()
		.when('startDate', (ParameterStartDate, yup) => ParameterStartDate && yup.min(ParameterStartDate, 'End date cannot be before start date'))
		.required(),
	users: yup.array().of(
		yup.object().shape({
			neuid: yup.string(),
			fK_NExceptionID: yup.string(),
			fK_UserUniqueID: yup.string(),
		})
	),
});

const AddModal = (props: Props) => {
	const [loading, setloading] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		reset,
		control,
		setValue,
		clearErrors,
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		shouldFocusError: true,
	
	});

	console.log('errors', errors);

	const [userOptions, setuserOptions] = useState<any>([]);
	const [selectesUsers, setselectesUsers] = useState<any>([]);
	const NOTIFICATION_DATA = useAppSelector((state) => state?.notifications);
	const [date, setDate] = useState<any>([])

	console.log('EDIT DATA', props?.Data);
	let dispatch = useAppDispatch();

	useEffect(() => {
		fetch_notificationTemplate_list(dispatch, {
			'filters': {
				'QUERY': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 100,
			},
		});

		reset();
		clearErrors();
	}, [dispatch]);

	const TemplateList = useAppSelector((state) => state?.notifications?.NOTIFICATION_TEMPLATE_LIST_DATA) ?? [];
	const TemplateListDDL = TemplateList.map((item: any) => ({ label: `${item?.templateCode} - ${item?.subject}`, value: item?.templateId }));

	useEffect(() => {
		if (props.Data) {
			setValue('descriptions', props?.Data?.descriptions);
			setValue('isTemporary', props?.Data?.isTemporary);
			setValue(
				'fK_TemplateIDs',
				props?.Data?.fK_TemplateIDs.split(',').map((x: any) => parseInt(x, 10))
			);
			setValue('startDate', props?.Data?.users && moment(props?.Data?.users[0]?.startDate).format('YYYY-MM-DD'));
			setValue('endDate', props?.Data?.users && moment(props?.Data?.users[0]?.endDate).format('YYYY-MM-DD'));
			setDate([moment(props?.Data?.users[0]?.startDate), moment(props?.Data?.users[0]?.endDate),])
			const RefetchCurentUsersUser = async () => {
				let users = props?.Data?.users.map((item: any) => item?.fK_UserUniqueID).join(',');
				let postData = {
					'filters': {
						'query': users ? `(UserUniqueID IN (${users}))` : '',
					},
					'pagination': {
						'pageNo': 1,
						'limit': 200,
					},
				};
				search_user(postData)?.then((res: any) => {
					let Data = res?.response?.data || [];
					let ProccessedData =
						Data.map((t: any) => ({ value: t.userUniqueID, label: `${t.firstName} - ${t.emailID}`, isPrev: true })) || [];

					let selectData = props?.Data?.users.map((item: any) => {
						let itemData;
						ProccessedData.find((_item: any) => {
							if (_item?.value === item?.fK_UserUniqueID) {
								itemData = { ...item, ..._item };
								return itemData;
							} else return null;
						});
						return itemData;
					});
					setselectesUsers(selectData);
					setuserOptions(selectData);
				});
			};

			RefetchCurentUsersUser();
		} else {
		}
	}, [props.Data, setValue]);

	useEffect(() => {
		console.log('SELECTED DATA', selectesUsers);
	}, [selectesUsers]);

	const Search_user_fx = async (key?: string) => {
		let postData = {
			'filters': {
				'query': key ? `((firstName like '%${key}%') OR (emailID like '%${key}%'))` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		return await search_user(postData);
	};

	const loadOptions = async (inputValue: string) => {
		return Search_user_fx(inputValue)?.then((res: any) => {
			let Data = res?.response?.data || [];
			let ProccessedData = Data.map((t: any) => ({ value: t.userUniqueID, label: `${t.firstName} - ${t.emailID}` })) || [];
			setuserOptions(ProccessedData);
			return ProccessedData;
		});
	};

	// useEffect(() => {
	// 	loadOptions('');
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const onSubmitHandler = (data: any) => {
		let FormattedData = selectesUsers.map((item: any) => ({
			'neuid': item?.neuid ?? null,
			'fK_NExceptionID': item?.fK_NExceptionID ?? null,
			'fK_UserUniqueID': item?.value,
			'startDate': dayjs(data?.startDate).format('YYYY-MM-DD'),
			'endDate': dayjs(data?.endDate).format('YYYY-MM-DD'),
		}));

		let postData: any = {
			descriptions: data?.descriptions,
			isTemporary: data?.isTemporary,
			fK_TemplateIDs: data?.fK_TemplateIDs.join(','),
			users: FormattedData,
		};
		if (props?.Data) {
			postData['nExceptionID'] = props?.Data?.nExceptionID;
		}

		let prevData = props?.Data?.users.map((item: any) => item?.fK_UserUniqueID) ?? [];

		let currentPrevUser = selectesUsers.map((item: any) => {
			if (item?.isPrev) {
				return item?.fK_UserUniqueID;
			} else {
				return null;
			}
		});

		let deletedUser = prevData.filter((item: any) => !currentPrevUser.includes(item));
		let deletedUserNeuid: number[] = [];
		props?.Data?.users.forEach((item: any) => {
			console.log('DD ITEM', item);
			if (deletedUser.includes(item?.fK_UserUniqueID)) {
				deletedUserNeuid.push(Number(item?.neuid));
			}
		});

		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			reset();
			props.handleClose();
			clearErrors();
			fetch_notification_exception(dispatch, {
				'filters': {
					'query': NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_FILTER?.query || '',
				},
				'pagination': {
					'pageNo': NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.pageNo,
					'limit': NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.limit,
				},
			});
			setloading(false)

		};
		const Failed = (err: any) => {
			FailedToast(err?.response);
			setloading(false)

		};
		setloading(true)

		if (props?.Data) {
			update_notification_exception('put', postData, Success, Failed);
			delete_notification_exception_user('put', { id: deletedUserNeuid });
		} else {
			update_notification_exception('post', postData, Success, Failed);
		}
	};

	return (
		<ModalLayout
			open={props.open}
			handleClose={() => {
				reset();
				clearErrors();
				props.handleClose();
			}}
			title={props?.Data ? 'Update Exception' : 'Add New Exception'}
			subtitle='Notification'>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className='row'>
					<div className='col-12'>
						<div className='form-group select-input full-label'>
							<label>Select Users</label>
							<AsyncSelect
								className={'common-select-default common-select-grey'}
								cacheOptions
								loadOptions={loadOptions}
								onChange={(e, option) => {
									setselectesUsers(e);
								}}
								placeholder='Search users'
								defaultOptions={userOptions}
								value={selectesUsers}
								isClearable={selectesUsers.some((v: any) => !v?.isFixed)}
								isMulti
							/>
							<ErrorComponent errors={errors} name='users' title='users' />
						</div>
					</div>

					<hr className='w-100' />
					<div className='col-12'>
						<div className='form-group inline-input full-label'>
							<label>Description</label>
							<input type='text' className='form-control grey-input' {...register('descriptions')} />
						</div>
						<ErrorComponent errors={errors} name='descriptions' title='Descriptions' />
					</div>

					<hr className='w-100' />
					<div className='col-12'>
						<div className='form-group inline-input full-label'>
							<label>Exception Period</label>
							<RangePicker
								picker="date"
								allowClear
								inputReadOnly
								allowEmpty={[true, true]}
								className='form-control'
								placeholder={['Start Date', ' End Date']}
								separator
								value={date}
								disabledDate={(curr) => curr && curr < moment().subtract(1, 'day').endOf('day')}
								format={'DD-MMM-YYYY'}
								onChange={(dates: any) => {
									setDate(dates)
									setValue('startDate', moment(dates[0]).format('YYYY-MM-DD'))
									setValue('endDate', moment(dates[1]).format('YYYY-MM-DD'))
								}}
							/>

							{/* <input type='date' className='form-control grey-input' {...register('startDate')} /> */}
						</div>
						<ErrorComponent errors={errors} name='startDate' title='Start Date' />
						<ErrorComponent errors={errors} name='endDate' title='End Date' />
					</div>
					{/* <div className='col-6'>
						<div className='form-group inline-input full-label'>
							<label>End Date</label>
							<input type='date' className='form-control grey-input' {...register('endDate')} />
						</div>
						<ErrorComponent errors={errors} name='endDate' title='End Date' />
					</div> */}
					<div className='col-12'>
						<div className='form-group select-input full-label'>
							<label>Exception Notifications</label>
							<FormSelectMultiple
								control={control}
								name='fK_TemplateIDs'
								options={TemplateListDDL}
								grey={true}
								isMulti={true}
								placeholder='Notifications'
							/>
							{/* <input className="form-control grey-input" {...register('fK_TemplateIDs')} /> */}
						</div>
						<ErrorComponent errors={errors} name='fK_TemplateIDs' title='Notifications' />
					</div>
					<hr className='w-100' />
					<div className='col-12'>
						<div className='form-group inline-input full-label '>
							<label>Enable Excpetion</label>

							<label className='switch'>


								{/* <input type='checkbox' {...register('isTemporary')} /> */}


								<Controller
									name={"isTemporary"}
									defaultValue={true}
									control={control}
									render={({ field: { value, onChange, onBlur } }) => (
										<>
											<input type='checkbox' checked={value} onChange={onChange} defaultChecked={true} />

										</>
									)}
								/>

								<span className='slider'></span>
							</label>
							<ErrorComponent errors={errors} name='isTemporary' title='Nottification Status' />
						</div>
					</div>
					<div className='col-12'>
						<div className='modal-footer'>
							<button type='submit' className='btn btn-secondary' disabled={loading}>

								<ButtonLoader text="Save" loading={loading} />

							</button>
							<button
								type='reset'
								onClick={() => {
									reset();
									clearErrors();
									props.handleClose();
								}}
								className='btn btn-outline-blue close-modal-button'>
								Close
							</button>
						</div>
					</div>
				</div>
			</form>
		</ModalLayout>
	);
};

export default AddModal;
