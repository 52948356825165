import React from 'react';
import DashIcons from './components/DashIcons';
import FilterBox from './components/FilterBar';
import ListTable from './components/ListTable';

type Props = {};

const FindMyCar = (props: Props) => {
	return (
		<div className='main-body-content'>
			<DashIcons />

			<div className='w-100 mb-20'>
				<div className='white-card'>
					<FilterBox />
					<ListTable />
				</div>
			</div>
		</div>
	);
};

export default FindMyCar;
