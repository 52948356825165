import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';


type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	DEFINITION_TABLE: [],
	DEFINITION_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	DEFINITION_LOADER: true,
	DEFINITION_FILTER: { query: "" } as FilterObject ,
	DEFINITION_ERROR: '',
};

export const DEFINITIONSLICE = createSlice({
	name: 'USER',
	initialState: initialState,
	reducers: {
		UPDATE_DEFINITION_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.DEFINITION_TABLE = data;
			state.DEFINITION_PAGINATION = pagination;
			state.DEFINITION_FILTER = filters;
			state.DEFINITION_LOADER = false;
			state.DEFINITION_ERROR = '';
		},
		UPDATE_DEFINITION_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.DEFINITION_LOADER = loader;
		},

		UPDATE_DEFINITION_ERROR: (state, action) => {
			const { error } = action.payload;
			state.DEFINITION_ERROR = error;
			state.DEFINITION_LOADER = false;
		},

		UPDATE_DEFINITION_FILTER_ONLY: (state, action) => {
			const { filters } = action.payload;
			state.DEFINITION_FILTER = filters;
		},

		RESET_DEFINITION_FILTERS_ONLY: (state) => {
			state.DEFINITION_FILTER = initialState.DEFINITION_FILTER
		},

		RESET_STATE:(state)=>{
			return {...initialState}
			// state.DEFINITION_TABLE =[];
			// state.DEFINITION_PAGINATION =    { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject;
			// // state.DEFINITION_FILTER ={ query: "" } as FilterObject ;
			// state.DEFINITION_LOADER =true;
			// state.DEFINITION_ERROR ='';
			// // state = initialState
		}
	},
});

export const { UPDATE_DEFINITION_DATA, UPDATE_DEFINITION_LOADER, UPDATE_DEFINITION_ERROR,RESET_STATE, UPDATE_DEFINITION_FILTER_ONLY, RESET_DEFINITION_FILTERS_ONLY } = DEFINITIONSLICE.actions;
export const DEFINITIONData = (state: RootState) => state;
export default DEFINITIONSLICE.reducer;
