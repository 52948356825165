/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../utils/utilFunctions';
import { fetch_webusers_request, webusers_request_status } from '../../security/store/action';
import CommonTable from '../../../common/components/Table/CommonTable';
import CustomConfirm from '../../../common/components/CustomConfrmBox';
import ViewModal from '../../security/screens/webUserRequest/components/viewModal';
import Select from 'react-select';
import { commonSearchQuery, FilterProps } from '../../../config/filterSearch';

type Props = {};

let statusOptions = [
	{ label: 'Pending', value: 0 },
	{ label: 'Approved', value: 1 },
	{ label: 'Rejected', value: 2 },
	{ label: 'Cancelled', value: 3 },
];

const ListTable = (props: Props) => {
	const fetchIdRef = useRef(0);
	const [selectedStatus, setselectedStatus] = useState<any>('');

	const [confirm, setconfirm] = useState(false);
	const [viewModal, setviewModal] = useState(false);
	const [ViewData, setViewData] = useState();
	const [filterActive, setFilterActive] = useState(false)
	const dispatch = useAppDispatch();

	const SECURITY_DATA = useAppSelector((state) => state?.security);
	const userData: any = useAppSelector((state) => state?.login?.USER_PROFILE);

	const confirmOpen = (data: any) => {
		setViewData(data);
		setconfirm(true);
	};
	const confirmClose = () => setconfirm(false);

	const viewModalOpen = (data: any) => {
		setViewData(data);
		setviewModal(true);
	};

	const viewModalClose = () => setviewModal(false);

	const columns = React.useMemo(
		() => [
			{ Header: 'Request No ', accessor: 'sRequestID' , orderByKey :"sRequestID"},

			{ Header: 'Requested Date ', accessor: 'requestedOn', DateFormat: true , orderByKey :"requestedOn"},

			{ Header: 'Requested By ', accessor: 'requestedByName' },

			{ Header: 'Requested For ', accessor: 'requestedForName'   },
			{
				Header: 'status ',
				accessor: 'requestStatus',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<label
								className={`${
									row?.original?.requestStatus === 1
										? 'badge green'
										: row?.original?.requestStatus === 2
										? 'badge red'
										: row?.original?.requestStatus === 3
										? 'badge dark-blue'
										: row?.original?.requestStatus === 0
										? 'badge yellow'
										: 'badge'
								} `}>
								{row?.original?.requestStatus === 1
									? 'Approved'
									: row?.original?.requestStatus === 2
									? 'Rejected'
									: row?.original?.requestStatus === 3
									? 'Cancelled'
									: row?.original?.requestStatus === 0
									? 'Pending'
									: null}
							</label>
						</Fragment>
					);
				},
			},

			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								{row?.original?.requestStatus === 0 && (
									<a className='btn btn-sqaure btn-red' onClick={() => confirmOpen(row?.original)}>
										<i className='fa-solid fa-xmark'></i>
									</a>
								)}
								<a className='btn btn-sqaure btn-white modal-button' onClick={() => viewModalOpen(row?.original)}>
									<i className='fa-solid fa-eye'></i>
								</a>
							</div>
						</Fragment>
					);
				},
			},
		],
		[]
	);

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy ,filters }: any) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_webusers_request(
					dispatch,
					{
						'filters': {
							'query': filters?.query && filters?.query!=='' ?
							filters?.query : 
							`requestedBy = ${userData?.userUniqueID} `,
                            OrderBy

							
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, userData?.userUniqueID]
	);
	const onDelete = (data: any) => {
		console.log('SUBMITTED DATA', data);

		let postData = {
			'sRequestID': data?.sRequestID,
			'Status': 3,
			'ApproverRemarks': 'cancelled',
			'RoleID': 0,
		};

		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			fetch_webusers_request(dispatch, {
				'filters': {
					'query': `requestedBy = ${userData?.userUniqueID}`,
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				},
			});
			confirmClose();
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
		};

		webusers_request_status('post', postData, Success, Failed);
	};

	const clearFilter = () =>{
		setFilterActive(false)
		fetch_webusers_request(
			dispatch,
			{
				'filters': {
					'query': `requestedBy = ${userData?.userUniqueID} `,
				},
				'pagination': {
					'pageNo': 1,
					'limit': SECURITY_DATA?.WEBUSERS_PAGINATION?.limit,
				},
			}
		);
	}

	const filterSearch = () =>{
		if(selectedStatus!=='' || selectedStatus!==null){
			setFilterActive(true)
			let QueryInput: FilterProps[] = [
				{
					field : 'RequestStatus',
					value : selectedStatus,
					operator : '='
				},
				{
					field : 'requestedBy',
					value : userData?.userUniqueID,
					operator : '='
				}
			]
			fetch_webusers_request(
				dispatch,
				{
					'filters': {
						'query': commonSearchQuery(QueryInput,'AND'),
					},
					'pagination': {
						'pageNo': 1,
						'limit': SECURITY_DATA?.WEBUSERS_PAGINATION?.limit,
					},
				}
			);
		}
		else{
			setFilterActive(false)
		}
		
	}

	return (
		<>
			<div className='row'>
				<div className='col-12'>
					<div className='search-filter-row'>
						<ul>
							<li>
								<a  className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							{filterActive && (
								<li>
									<a onClick={clearFilter}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
						</ul>

						<div className='filter-block input-bg-white' id='quick-search'>
							<div>
								<div className='row'>
									<div className='col-3'>
										<div className='form-group'>
											<Select
												options={statusOptions}
												className={'common-select'}
												placeholder='Status'
												isMulti={false}
												isClearable
												value={statusOptions.find((c) => c.value === selectedStatus)}
												onChange={(e: any,{ action }) =>{
													if(action==='clear')
														setselectedStatus('')
													else
														setselectedStatus(e?.value)}}
											/>
										</div>
									</div>
									<div className='col-2'>
										<div className='form-group'>
											<button onClick={()=> filterSearch()} className='form-control blue-btn'>Search</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-12'>
					<CommonTable
						columns={columns}
						data={SECURITY_DATA?.WEBUSER_REQUEST_LIST_DATA ?? []}
						fetchData={fetchData}
						pageCount={SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.totalCount ?? 0}
						loading={SECURITY_DATA?.WEBUSER_REQUEST_LOADER}
						page_no={SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.pageNo ?? 1}
						limit={SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.limit ?? 1}
					filters={SECURITY_DATA?.WEBUSERS_FILTER}
					/>

					{confirm && (
						<CustomConfirm handleClose={confirmClose} open={confirm}
						title='Are you sure?'
						description='This action is irreversible. Can you confirm?'>
							<button className='btn btn-secondary' onClick={() => onDelete(ViewData)}>
								Cancel Request
							</button>
						</CustomConfirm>
					)}
					{viewModal && <ViewModal open={viewModal} handleClose={viewModalClose} Data={ViewData} />}
				</div>
			</div>
		</>
	);
};

export default ListTable;
