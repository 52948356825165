/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import AddModal from './addModal';
import QueryBModal from '../../../../../common/components/query_builder'
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_notification_exception, fetch_notification_templateDDL } from '../../../store/action';
import { Field } from 'react-querybuilder';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from 'react-select';
import { commonSearchQuery, FilterProps } from '../../../../../config/filterSearch';
import dayjs from 'dayjs';
import { DatePicker } from "antd"
const { RangePicker } = DatePicker;

type Props = {};

const FilterBar = (props: Props) => {
	const dispatch = useAppDispatch();
	// const [startDate, setStartDate] = useState<any>('');
	// const [endDate, setEndDate] = useState<any>('')

	const [addModal, setaddModal] = useState(false);
	const [advanceModal, setAdvanceModal] = useState(false)
	const [statusVal, setStatusVal] = useState('null')
	const [date, setDate] = useState<any>([])

	// const [usrSearch, setUsrSearch] = useState('')
	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);
	const advancemodalOpen = () => setAdvanceModal(true)
	const advancemodalClose = () => setAdvanceModal(false)
    const NOTIFICATION_DATA = useAppSelector((state) => state?.notifications);


	const clearSearch = () =>{
		fetch_notification_exception(
            dispatch,
            {
                'filters': {
                    'query': '',
                },
                'pagination': {
                    'pageNo': 1,
                    'limit': NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.limit,
                },
            },
            
        );
		setDate([])
		setStatusVal('null')
	}

	const filterSearch = () => {
		let  QueryInput : FilterProps[] = [
			{
				field :'CAST (startDate AS date)', 
            	value : date && date?.length>0 ? `'${dayjs(date[0]).format('YYYY-MM-DD')}'` : null, 
            	operator: '>='
			},
			{
				field :'CAST (endDate AS date)', 
            	value : date && date?.length>1 ? `'${dayjs(date[1]).format('YYYY-MM-DD')}'` : null, 
            	operator: '<='
			},
			{
				field :'IsTemporary', 
            	value : statusVal!=='null' ? statusVal==='true' ? 1 : 0 : null, 
            	operator: '='
			}
		]
		fetch_notification_exception(
				dispatch,
				{
					'filters': {
						'query': commonSearchQuery(QueryInput,'AND'),
					},
					'pagination': {
						'pageNo': 1,
						'limit': NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.limit,
					},
				},
				
			);
	}

	const deployQueryFilter = (query:string) => {
		console.log('Query',query)
		fetch_notification_exception(dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.limit,
			},
			
		})
		advancemodalClose()
	}
	const fields: Field[] = [

		// { label: 'EXCEPTION ID', name:'nExceptionID'},
		{ label: 'STARTDATE', name:'CAST (startDate AS date)', inputType:'date'},
		{ label: 'ENDDATE', name:'CAST (endDate AS date)', inputType:'date'},
		// { label: 'TEMPLATE IDs', name:'FK_TemplateIDs'},
		// { label: 'TEMPORARY', name:'isTemporary', inputType:'checked'},
		// { label: 'COMPANY ID', name:'FK_CompanyID'}
];


	return (
		<>
			<div className='search-filter-row mb-30'>
				<div className='row'>
					<div className='col-5'>
						<ul>
							<li>
								<a className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							<li>
								<a onClick={() => advancemodalOpen()} >
									<i className='fa-solid fa-filter'></i>Advanced Filter
								</a>
							</li>
							{	NOTIFICATION_DATA.NOTIFICATION_EXCEPTION_FILTER.query!=='' &&
								<li>
								<a onClick={clearSearch}>
									<i className='fa-solid fa-times'></i>Clear All Filters
								</a>
							</li>
							}
						</ul>
					</div>
					<div className='col-7 text-right'>
						<a  onClick={addModalOpen} className='btn btn-secondary modal-button'>
							Add New Exceptions
						</a>
					</div>
				</div>

				<div className='filter-block' id='quick-search'>
					<div>
						<div className='row'>
							<div className='col'>
								<RangePicker
									picker="date"
									allowClear
									inputReadOnly
									allowEmpty={[true,true]}
									className='form-control'
									placeholder={['Start Date',' End Date']}
									separator
									value={date}
									format={'DD-MMM-YYYY'}
									onChange={(dates:any) =>{
										setDate(dates)
									}}
								/>
							</div>

							<div className='col'>
								<div className='form-group'>
									<ReactSelect
										options={[
											{ value : 'null', label : 'All'},
											{ value : 'false', label : 'Active' },
											{ value : 'true', label : 'Inactive' }
										]}
										className={'common-select'}
										placeholder={'Status'}
										isMulti={false}
                        	    		value={statusVal ? [
											{ value : 'null', label : 'All'},
											{ value : 'flase', label : 'Active' },
											{ value : 'true', label : 'Inactive' }
										].find((c) => c.value === statusVal) : null}
                            			onChange={(val:any) =>{ setStatusVal(val?.value) }}
									/>
								</div>
							</div>
							{/* <div className='col'>
								<div className='form-group'>
									<input type='text' name='' placeholder='User Name' className='form-control' value={usrSearch} onChange={(v)=>{ setUsrSearch(v.target.value) }} />
								</div>
							</div> */}

							<div className='col-2'>
								<div className='form-group'>
									<button onClick={()=>{ filterSearch() }} className='form-control blue-btn'>Search</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
			{addModal && <AddModal open={addModal} handleClose={addModalClose} />}
		</>
	);
};

export default FilterBar;
