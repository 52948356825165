import Request from "../../../config/api";
import { GET_VIOATION_TYPE_ZONE, PARKING_VIOLATIONS, REPORT_VIOLATIONS, USERS, USERS_VEHICLE, VEHICLE_LIST_SEARCH, VIOLATIONS_BLACKLISTED, VIOLATIONS_COUNT, VIOLATIONS_COUNT_BYTYPE, VIOLATIONS_GET_BLACKLISTED, VIOLATIONS_SEARCH, VIOLATION_POLICY } from "../../../config/endpoint";
import { UPDATE_BLACKLISTED_DATA, UPDATE_BLACKLISTED_ERROR, UPDATE_BLACKLISTED_LOADER, UPDATE_PARKING_VIOLATION_DATA, UPDATE_PARKING_VIOLATION_ERROR, UPDATE_PARKING_VIOLATION_LOADER, UPDATE_REPORT_VIOLATION_DATA, UPDATE_REPORT_VIOLATION_ERROR, UPDATE_REPORT_VIOLATION_HISTORY_DATA, UPDATE_REPORT_VIOLATION_HISTORY_ERROR, UPDATE_REPORT_VIOLATION_HISTORY_LOADER, UPDATE_REPORT_VIOLATION_LOADER, UPDATE_VIOLATION_COUNT, UPDATE_VIOLATION_COUNTBYTYPE } from "./violationSlice";
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_parking_violations_list = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = PARKING_VIOLATIONS;

    dispatch(UPDATE_PARKING_VIOLATION_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_PARKING_VIOLATION_DATA({
                    data,
                    pagination,
                    filters,
                    ...response
                })
            );
        } else {
            dispatch(UPDATE_PARKING_VIOLATION_ERROR({
                error: "some error occurred",
            }));
        }
    });
}

export const fetch_parking_violations_detail = (id:number, Success: (res:any)=>void, Failed:(err:any)=>void) => {
    let api_url = PARKING_VIOLATIONS +id
    Request('get', api_url, '', undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if(statusCode === 200){

        }else{

        }
    })
}


export const fetch_violation_count = (dispatch:any, postData:any) =>{
    let api_url = VIOLATIONS_COUNT;
    Request('post',api_url, postData,undefined)?.then((res) => {
        const { response, statusCode } = res 
        let count = response && response[0]
        if(statusCode===200){
            dispatch(UPDATE_VIOLATION_COUNT({
                count
            }))
            
        }
    })
}

export const fetch_violation_type_count = (dispatch:any, postData:any) =>{
    let api_url = VIOLATIONS_COUNT_BYTYPE;
    Request('post',api_url, postData,undefined)?.then((res) => {
        const { response, statusCode } = res 
        if(statusCode===200){
            dispatch(UPDATE_VIOLATION_COUNTBYTYPE({
                count : response
            }))
            
        }
    })
}


export const fetch_blacklisted_list = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = VIOLATIONS_GET_BLACKLISTED;

    dispatch(UPDATE_BLACKLISTED_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_BLACKLISTED_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
                UPDATE_BLACKLISTED_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    });
};

export const add_blacklisted = (type:t, postData:Object, Success: (res:any)=>void, Failed:(err:any)=>void) => {
    let api_url = VIOLATIONS_BLACKLISTED + 'action/'
    Request(type, api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if(statusCode === 200){

        }else{

        }
    })
}

export const delete_blacklisted = (id:number, Sucess: (res: any) => void, Failed: (err: any) => void) =>{
    let api_url = VIOLATIONS_BLACKLISTED + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
}

export const fetch_report_violations_list = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = REPORT_VIOLATIONS;

    dispatch(UPDATE_REPORT_VIOLATION_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_REPORT_VIOLATION_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
            UPDATE_REPORT_VIOLATION_ERROR({
                error : 'Some Error Occured'
            }))
        }
    });
};

export const fetch_parking_violations_History_list = (dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = PARKING_VIOLATIONS;
    dispatch(UPDATE_REPORT_VIOLATION_HISTORY_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_REPORT_VIOLATION_HISTORY_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
            UPDATE_REPORT_VIOLATION_HISTORY_ERROR({
                error : 'Some error occured'
            }))
        }
    });
};


export const add_report_violations = (type:t, postData:Object, Success: (res:any)=>void, Failed:(err:any)=>void) => {
    let api_url = REPORT_VIOLATIONS + 'action/'
    Request(type, api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if(statusCode === 200){

        }else{

        }
    })
}

export const fetch_report_violation_detail = (id:number, Success: (res:any)=>void, Failed:(err:any)=>void) => {
    let api_url = REPORT_VIOLATIONS +id
    Request('get', api_url, '', undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if(statusCode === 200){

        }else{

        }
    })
}

export const fetch_UsersVehicleList = async (postData:Object, signal?: AbortSignal) => {
    let api_url = USERS_VEHICLE
    return await Request('post',api_url,postData,signal)
}

export const fetch_vehicle_list = async (postData:Object, signal?: AbortSignal) => {
    let api_url = VEHICLE_LIST_SEARCH
    return await Request('post',api_url,postData,signal)
}

export const fetch_violation_search_list = async (postData:Object, signal?: AbortSignal) => {
    let api_url = VIOLATION_POLICY + 'search/'
    return await Request('post',api_url,postData,signal)
}

export const update_violation_status = (data:Object, Success:(res:any)=>void, Failed:(err:any)=>void) => {
    let api_url = REPORT_VIOLATIONS + 'validate/action/'
    Request('post', api_url, data, undefined, Success, Failed)
}

export const fetch_all_usersSearchWithViolation = (postData: any, signal?: AbortSignal) => {
    let api_url = USERS;
    return Request('post', api_url, postData, signal,)

};


export const search_user_or_visitor = (postData: any, signal?: AbortSignal) => {
    let api_url = VIOLATIONS_SEARCH;
    return Request('post', api_url, postData, signal,)
};


export const getViolationTypeByZone = (id: any, signal?: AbortSignal) => {
    let api_url = GET_VIOATION_TYPE_ZONE + id +'/' ;
    console.log('called')
    return Request('get', api_url, '', signal)
};