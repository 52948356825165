import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { customMsgError } from '../common/components/Notification/ToastNotification';
import { BackendPermision, FrontendPermision, Privilege } from '../modules/security/screens/Roles/components/types';
import { DEBUG } from './../config/endpoint';
import './util.css'


const log = (message: any, name = "DEBUG", color = "white",) => {
	if (!DEBUG) return
	console.log(`%c||=======${name}=======>`, `color:${color}`)
	console.log(message);
	console.log(`%c<=====================||`, `color:${color}`);
};

export default log;

export function Checkformat(format: any) {
	//console.log(format);
	let r = '';
	const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'jpeg'];
	const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'mov', 'MOV'];

	if (photoformats.includes(format)) {
		r = 'photo';
	} else if (videoformats.includes(format)) {
		r = 'video';
	} else {
		r = '';
	}

	return r;
}

export const fileToImage = (v:any) => {
	if(v){
		return URL.createObjectURL(v);
	  }
	else 
	  return ''
}

export const dataURLtoFile = (dataurl: any, filename: any) => {
	const arr = dataurl.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) u8arr[n] = bstr.charCodeAt(n);

	return new File([u8arr], filename, { type: mime });
};

export const FileDownloader = (file:any, file_name:string, file_type?:string) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    let cur_date = new Date();
    let file_extension = file_type??'csv'
    let date_string = `${cur_date.getDate()}${cur_date.getMonth()}${cur_date.getFullYear()}-${cur_date.getHours()}${cur_date.getMinutes()}${cur_date.getSeconds()}`
    let file_name_final = `${file_name}${date_string}.${file_extension}`
    link.setAttribute('download', file_name_final); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export const SuccesToast = (msg = 'Sucessfully Completed!!') => {
	toast.success(msg, {
		position: 'top-right',
		className: 'success-toast-message',
		style: { fontSize:"14px"},
		autoClose: 2500,
	});
};

export const FailedToast = (data?:any , customToastId ?: any) => {
	// toast.error(msg, {
	// 	position: 'top-right',
	// 	className: 'failed-toast-message',
	// 	autoClose: 2500,
	// });

	toast.error(customMsgError(data), {
	position: toast.POSITION.TOP_RIGHT,
    className:"toast-notification",
    autoClose : 6000,
	pauseOnHover:true,
	toastId: customToastId,

    // delay: 5000,

    // hideProgressBar:true
	});
};

export const FailedToast2 = (msg?:any) => {
	toast.error(msg, {
		position: 'top-right',
		className: 'failed-toast-message',
		autoClose: 5000,
	});
	
};

export const WarnToast = (msg = 'Something went wrong!!') => {
	toast.warn(msg, {
		position: 'top-right',
		style: { fontSize:"14px"},
		autoClose: 6000,
	});
};

export const isPdf = (src = '') => {
	return src?.includes('.pdf') || false;
};

export const UploadProgress = (p: any, toastId: any) => {
	const progress = p.loaded / p.total;
	if (toastId?.current === null) {
		toastId.current = toast('Upload in Progress', {
			progress: progress,
			autoClose: 2500,
		});
	} else {
		toast.update(toastId.current, {
			progress: progress,
			autoClose: 2500,
		});
	}
};

export function getFromLS(key: any) {
	let ls: any = {};
	if (global.localStorage) {
		try {
			let d: any = global.localStorage.getItem('rgl-8');
			ls = JSON.parse(d) || {};
		} catch (e) { }
	}
	return ls[key];
}

export function saveToLS(key: any, value: any) {
	if (global.localStorage) {
		global.localStorage.setItem(
			'rgl-8',
			JSON.stringify({
				[key]: value,
			})
		);
	}
}


type FilterObject = {
	key: string;
	value: any;
};

// call after getting the filterArray before fetching api
export const FilteredObject = (filterArray: Array<FilterObject>) => {
	const FilterObject = filterArray.reduce(
		(obj, item) => ({
			...obj,
			[item.key]: item.value,
		}),
		{}
	);
	return FilterObject || null;
};


// call before dispatching to the state
export const ObjectToArray = (FilterObject: FilterObject, CurrentFilters: Array<FilterObject>) => {
	let FilterArr: Array<FilterObject> = [...CurrentFilters];
	FilterArr = FilterArr.filter((item) => item.key !== FilterObject.key);
	FilterArr.push(FilterObject);
	return FilterArr;
};

interface IFIlterArray {
	field: string,
	value: any,
	operator?: 'like' | 'lesser' | 'greater' | 'equals' | 'notEquals' | 'lessEquals' | 'greatEquals' | 'in' | 'notIN' | 'between' | 'notBetween'
}


export const FilterConvert = (FilterObject: any,) => {

	let FIlterArray: IFIlterArray[] = []

	for (const key in FilterObject) {

		FIlterArray.push({
			field: key,
			value: FilterObject[key],
		})
		//console.log(`${key}: ${user[key]}`);
	}

	return FIlterArray

};

export const FormatDate = (d?: string, format?:string) => {
	let f = format ? format : 'DD-MMM-YYYY'
	if (dayjs(d).format("DD-MMM-YYYY") === "Invalid Date" || !dayjs(d).isValid() || !d) {
		return ''
	} else {
		return dayjs(d).format(f)
	}

}


export const FormatTime = (time: string, format?:string, datetime?: boolean, zeroSeconds?: boolean) => {
	// TODO :: add time format function
	console.log('FORMATTIMECHECK=>',time)
	let f = format ? format : 'hh:mm a'
	let d = datetime ? time : new Date(`July 1, 1999, ${time}`)
	console.log('d=>',d,'valid->',dayjs(d).isValid())
	if (dayjs(d).isValid()) {
		if(zeroSeconds){
			let tempTime = dayjs(d).startOf('minute')
			return dayjs(tempTime).format(f)
		}
		return dayjs(d).format(f)
	} else {
		return ''
	}

}


export const BackendToFrontendPermission = (default_F_Permision: FrontendPermision[], new_B_Permissions: BackendPermision[]) => {
	let TempModuleArr: FrontendPermision[] = [];
	for (let i = 0; i < default_F_Permision.length; i++) {
		let TempSubModuleArr = [];
		for (let j = 0; j < default_F_Permision[i].sub_modules.length; j++) {
			let TempSubModuleObjIndex = new_B_Permissions.findIndex((item) => item?.fK_SubModuleID === default_F_Permision[i].sub_modules[j].fK_SubModuleID);
			let TempSubModuleObj = new_B_Permissions[TempSubModuleObjIndex];
			TempSubModuleArr.push({
				fK_SubModuleID: TempSubModuleObj?.fK_SubModuleID,
				sub_module_name: default_F_Permision[i].sub_modules[j]?.sub_module_name,
				isView: TempSubModuleObj?.isView,
				isCreate: TempSubModuleObj?.isCreate,
				isEdit: TempSubModuleObj?.isEdit,
				isDelete: TempSubModuleObj?.isDelete,
				prevID: TempSubModuleObj?.prevID,
				fK_RoleID: TempSubModuleObj?.fK_RoleID,
			});
		}
		TempModuleArr.push({
			module_id: default_F_Permision[i]?.module_id,
			module_name: default_F_Permision[i]?.module_name,
			full_control: default_F_Permision[i]?.full_control,
			user_has_access: default_F_Permision[i]?.user_has_access,
			sub_modules: TempSubModuleArr,
		});
	}

	return TempModuleArr;
};

export const BackendToFrontendPermission2 = (default_F_Permision: FrontendPermision[], new_B_Permissions: BackendPermision[]) => {
	let TempModuleArr: FrontendPermision[] = [];
	for (let i = 0; i < default_F_Permision.length; i++) {
		let TempSubModuleArr = [];
		let userHasAccess = false;

		for (let j = 0; j < default_F_Permision[i].sub_modules.length; j++) {
			let TempSubModuleObjIndex = new_B_Permissions.findIndex((item) => item?.fK_SubModuleID === default_F_Permision[i].sub_modules[j].fK_SubModuleID);
			let TempSubModuleObj = new_B_Permissions[TempSubModuleObjIndex];
			TempSubModuleArr.push({
				fK_SubModuleID: TempSubModuleObj?.fK_SubModuleID,
				sub_module_name: default_F_Permision[i].sub_modules[j]?.sub_module_name,
				isView: TempSubModuleObj?.isView,
				isCreate: TempSubModuleObj?.isCreate,
				isEdit: TempSubModuleObj?.isEdit,
				isDelete: TempSubModuleObj?.isDelete,
				prevID: TempSubModuleObj?.prevID,
				fK_RoleID: TempSubModuleObj?.fK_RoleID,
			});
		}

		TempModuleArr.push({
			module_id: default_F_Permision[i]?.module_id,
			module_name: default_F_Permision[i]?.module_name,
			full_control: default_F_Permision[i]?.full_control,
			user_has_access: userHasAccess,
			sub_modules: TempSubModuleArr,
		});




	}


	let ModulesWithViewPermission = TempModuleArr.filter((item) => item?.sub_modules.filter((items) => items?.isView === true))

	console.log("ModulesWithViewPermission", ModulesWithViewPermission)

	return ModulesWithViewPermission;
};

export const FrontendTobackend = (default_F_Permision: FrontendPermision[]) => {

	let Val: Privilege[] = [] as Privilege[]
	for (let i = 0; i < default_F_Permision.length; i++) {
		Val.push(...default_F_Permision[i]?.sub_modules)
	}

	return Val
}

export function countString(str:string, letter:string) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charAt(i) === letter) {
            count += 1;
        }
    }
    return count;
}

export const valConvertToDec = (v:any,decPlace:number = 1) => {
    if(isNaN(parseInt(v)))
        return 0
    else
        return Math.round((v+Number.EPSILON)*10*decPlace)/(10*decPlace)
}

export const addPluralIfMore = (val:number, maxVal:number = 1) =>{
    if(val>maxVal)
        return 's'
    else 
        return ''
}

export const filterDuplicateObjArr = (arr:any[], idKey?:string, sortKey?:string, culminateKeyValueFn?:(key:string, val:any, newVal?:any)=>any) =>{
	let idK = idKey ? idKey : 'id'
	if(arr && arr?.length>0){
		const ids = arr?.map(o => o[idK])
		const filtered = arr?.filter(( el, index) => !ids.includes(el[idK], index + 1))
		//This line filters the input array to remove duplicate objects based on the value of the key specified by idK. 
		//It does this by checking if the value of the key for the current element (el) is not present in the ids array beyond the current index (index + 1). 
		//This ensures that only the first occurrence of each value is kept in the filtered array.

		if(culminateKeyValueFn){
			let newArr:any = []
			for(let i=0; i<arr?.length; ++i){
				if(newArr && newArr?.some((el:any) => el[idK]===arr[i][idK])){
					let idx = newArr?.findIndex((em:any) => em[idK]===arr[i][idK])
					newArr[idx] = culminateKeyValueFn(idK, newArr?.find((el:any)=> el[idK]===arr[i][idK]), arr[i])
				}else{
					newArr?.push(culminateKeyValueFn(idK, null, arr[i]))
				}
			}
			return newArr
		}

		if(sortKey){
			return filtered?.sort((a,b) => (a[sortKey] > b[sortKey]) ? 1 : ((b[sortKey] > a[sortKey]) ? -1 : 0))
		}
		return filtered
	}else{
		return []
	}
	
}

export const MinMaxOfArrayOfObj = (arr:any[], idKey:string) =>{
	let minObj:any = []
	let maxObj:any = []
	console.log('MINMAXFN',arr,idKey)
	if(arr && arr?.length>0){
		minObj[0] = arr[0]
		maxObj[0] = arr[0]
		for(let i=1; i<arr?.length; ++i){
			console.log('MINMAXLOOPCONDN\n',minObj[0][idKey],maxObj[0][idKey],arr[i][idKey],'\nFLOATVALs=>',parseFloat(maxObj[0][idKey]),parseFloat(minObj[0][idKey]),parseFloat(arr[i][idKey]))
			if(minObj[0][idKey] > arr[i][idKey] || parseFloat(minObj[0][idKey]) > parseFloat(arr[i][idKey])){
				minObj = []
				minObj[0] = arr[i]
			}
			else if(minObj[0][idKey] === arr[i][idKey] || parseFloat(minObj[0][idKey]) === parseFloat(arr[i][idKey]))
				minObj.push(arr[i])
			
			if(maxObj[0][idKey] < arr[i][idKey] || parseFloat(maxObj[0][idKey]) < parseFloat(arr[i][idKey])){
				maxObj = []
				maxObj[0] = arr[i]
			}
			else if(maxObj[0][idKey] === arr[i][idKey] || parseFloat(maxObj[0][idKey]) === parseFloat(arr[i][idKey]))
				maxObj.push(arr[i])
			console.log('MINMAXLOOP i=>',i,'Min',minObj,'Max',maxObj,'Arr',arr)
		}
	}
	
	return { 'min' : minObj, 'max' : maxObj }
}

export const CheckStringHasLetters = (str:string) =>{
	return /([^\s])/.test(str)
}

export const CheckStringIsEmail = (str:string) =>{
	return /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/.test(str)
}