import React, { useState } from 'react';
import noimage from '../../../assets/images/common/noimage.jpg'



type Props = {
	src: string;
	className?: string;
	style?: React.CSSProperties;
};

const ImageView = ({ src, className, style }: Props) => {
	const [viewImage, setviewImage] = useState(false);

	return (
		<>
			<img alt='' src={src || noimage} className={className} style={style} onClick={() => src && setviewImage(true)} />
			<div
				style={{
					position: 'fixed',
					left: 0,
					top: 0,
					zIndex: 1000,
					height: '100vh',
					width: '100vw',
					background: 'rgb(0,0,0,0.5)',
					display: viewImage ? 'flex' : 'none',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
                    
				<div style={{ position: 'fixed', right: '30px', top: '30px' }} onClick={() => setviewImage(false)}>
					<div
						className='btn btn-sqaure btn-white '
						onClick={(e) => {
							setviewImage(true);
						}}>
						<i className='fa-solid fa-xmark'></i>
					</div>
				</div>
				<img src={src} alt='' style={{ maxHeight: '90%', maxWidth: '90%', objectFit: 'contain' }} />
			</div>
		</>
	);
};

export default ImageView;
