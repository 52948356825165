import React from 'react'

const CheckBox = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <div className="custom_checkbox_wrapper">
            <label className="custom_checkbox">
                <input type="checkbox" name="checkbox" {...props}/>
            </label>
        </div>
    )
}

export default CheckBox