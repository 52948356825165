import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import { T_NottificationSettings } from './notification.inteface';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	NOTIFICATION_TEMPLATE_LIST_DATA: [],
	NOTIFICATION_TEMPLATE_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	NOTIFICATION_TEMPLATE_LOADER: true,
	NOTIFICATION_TEMPLATE_FILTER: { query: '' } as FilterObject,
	NOTIFICATION_TEMPLATE_ERROR: '',

	NOTIFICATION_TEMPLATE_PARAMETERS: [],
	NOTIFICATION_SETTINGS: {} as T_NottificationSettings,
	NOTIFICATION_SETTINGS_CONFIGURATION: [] ,


	NOTIFICATION_HISTORY_LIST_DATA: [],
	NOTIFICATION_HISTORY_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	NOTIFICATION_HISTORY_LOADER: true,
	NOTIFICATION_HISTORY_FILTER: { query: '' } as FilterObject,
	NOTIFICATION_HISTORY_ERROR: '',

	NOTIFICATION_EXCEPTION_LIST_DATA: [],
	NOTIFICATION_EXCEPTION_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	NOTIFICATION_EXCEPTION_LOADER: true,
	NOTIFICATION_EXCEPTION_FILTER: { query: '' } as FilterObject,
	NOTIFICATION_EXCEPTION_ERROR: '',
};

export const NOTIFICATIONSLICE = createSlice({
	name: 'NOTIFICATION',
	initialState: initialState,
	reducers: {
		UPDATE_NOTIFICATIONS_TEMPLATE_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.NOTIFICATION_TEMPLATE_LIST_DATA = data;
			state.NOTIFICATION_TEMPLATE_PAGINATION = pagination;
			state.NOTIFICATION_TEMPLATE_FILTER = filters;
			state.NOTIFICATION_TEMPLATE_LOADER = false;
			state.NOTIFICATION_TEMPLATE_ERROR = '';
		},
		UPDATE_NOTIFICATIONS_TEMPLATE_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.NOTIFICATION_TEMPLATE_LOADER = loader;
		},

		UPDATE_NOTIFICATIONS_TEMPLATE_ERROR: (state, action) => {
			const { error } = action.payload;
			state.NOTIFICATION_TEMPLATE_ERROR = error;
			state.NOTIFICATION_TEMPLATE_LOADER = false;
		},

		UPDATE_NOTIFICATIONS_HISTORY_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.NOTIFICATION_HISTORY_LIST_DATA = data;
			state.NOTIFICATION_HISTORY_PAGINATION = pagination;
			state.NOTIFICATION_HISTORY_FILTER = filters;
			state.NOTIFICATION_HISTORY_LOADER = false;
			state.NOTIFICATION_HISTORY_ERROR = '';
		},
		UPDATE_NOTIFICATIONS_HISTORY_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.NOTIFICATION_HISTORY_LOADER = loader;
		},

		UPDATE_NOTIFICATIONS_HISTORY_ERROR: (state, action) => {
			const { error } = action.payload;
			state.NOTIFICATION_HISTORY_ERROR = error;
			state.NOTIFICATION_HISTORY_LOADER = false;
		},
		UPDATE_NOTIFICATIONS_EXCEPTION_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.NOTIFICATION_EXCEPTION_LIST_DATA = data;
			state.NOTIFICATION_EXCEPTION_PAGINATION = pagination;
			state.NOTIFICATION_EXCEPTION_FILTER = filters;
			state.NOTIFICATION_EXCEPTION_LOADER = false;
			state.NOTIFICATION_EXCEPTION_ERROR = '';
		},
		UPDATE_NOTIFICATIONS_EXCEPTION_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.NOTIFICATION_EXCEPTION_LOADER = loader;
		},

		UPDATE_NOTIFICATIONS_EXCEPTION_ERROR: (state, action) => {
			const { error } = action.payload;
			state.NOTIFICATION_EXCEPTION_ERROR = error;
			state.NOTIFICATION_EXCEPTION_LOADER = false;
		},

		UPDATE_NOTIFICATIONS_TEMPLATE_PARAMETERS: (state, action) => {
			const { data } = action.payload;
			state.NOTIFICATION_TEMPLATE_PARAMETERS = data;
		},

		UPDATE_NOTIFICATIONS_SETTINGS: (state, action) => {
			const { data } = action.payload;
			state.NOTIFICATION_SETTINGS = data;
		},
		UPDATE_NOTIFICATIONS_SETTINGS_CONFIGURATION: (state, action) => {
			const { data } = action.payload;
			state.NOTIFICATION_SETTINGS_CONFIGURATION = data;
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
	UPDATE_NOTIFICATIONS_TEMPLATE_DATA,
	UPDATE_NOTIFICATIONS_TEMPLATE_LOADER,
	UPDATE_NOTIFICATIONS_TEMPLATE_ERROR,
	UPDATE_NOTIFICATIONS_TEMPLATE_PARAMETERS,
	UPDATE_NOTIFICATIONS_SETTINGS,
	UPDATE_NOTIFICATIONS_SETTINGS_CONFIGURATION,

	UPDATE_NOTIFICATIONS_HISTORY_DATA,
	UPDATE_NOTIFICATIONS_HISTORY_LOADER,
	UPDATE_NOTIFICATIONS_HISTORY_ERROR,
	UPDATE_NOTIFICATIONS_EXCEPTION_DATA,
	UPDATE_NOTIFICATIONS_EXCEPTION_LOADER,
	UPDATE_NOTIFICATIONS_EXCEPTION_ERROR,
	RESET_STATE
} = NOTIFICATIONSLICE.actions;
export const NOTIFICATIONData = (state: RootState) => state;
export default NOTIFICATIONSLICE.reducer;
