import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import { UserPermssion } from '../../../config/permissionLocal';

const initialState = {
	LOGIN_LOADER: false,
	LOGIN_ERROR: '',
    LOGIN_DATA: {},
	TOKEN : "",

    USER_PERMISSION: UserPermssion,
	USER_PROFILE : {}



};

export const LOGINSLICE = createSlice({
	name: 'LOGIN',
	initialState: initialState,
	reducers: {
		LOGIN_SUCCESS_DATA: (state, action) => {
			const { data } = action.payload;
			state.LOGIN_DATA = data;
			state.LOGIN_LOADER = false;
			state.LOGIN_ERROR = '';
			state.TOKEN = data?.accessToken;

		},
		LOGIN_ATTEMPT_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.LOGIN_LOADER = loader;
		},

		LOGIN_ATTEMPT_ERROR: (state, action) => {
			const { error } = action.payload;
			state.LOGIN_ERROR = error;
		},

		UPDATE_USER_PERMISSION: (state, action) => {
			const { data } = action.payload;
			state.USER_PERMISSION = data;
		},
		UPDATE_USER_PROFILE: (state, action) => {
			const { data } = action.payload;
			state.USER_PROFILE = data;
		},

		UPDATE_TOKEN: (state, action) => {
			const { data } = action.payload;
			state.TOKEN = data;

		},
	},
});

export const { LOGIN_SUCCESS_DATA, LOGIN_ATTEMPT_ERROR, LOGIN_ATTEMPT_LOADER ,UPDATE_USER_PERMISSION ,UPDATE_USER_PROFILE ,UPDATE_TOKEN} = LOGINSLICE.actions;
export const LOGINData = (state: RootState) => state;
export default LOGINSLICE.reducer;
