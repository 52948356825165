/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { commonSearchQuery, FilterProps } from '../../../../../config/filterSearch';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_report_violations_list } from '../../../store/action';
import ReportModal from './AddNewReport';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from 'react-select';
import { fetch_violationTypeDDL } from '../../../../../common/general/action';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../../config/subModuleList';
import useKeyboard from '../../../../../utils/hooks/useKeyboard';
import moment from 'moment';
const { RangePicker } = DatePicker;

type Props = {};

const FilterBar = (props: Props) => {
	const [reportModal, setreportModal] = useState(false);

	const reportModalOpen = () => setreportModal(true);
	const reportModalClose = () => setreportModal(false);
	const [userSearch, setUserSearch] = useState('');
	const [plateSearch, setPlateSearch] = useState('');

	const [violationType, setViolationType] = useState<any>('');
	const [userType, setUserType] = useState<any>('');
	const [dateRange, setDateRange] = useState<any>([]);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	useEffect(() => {
		const controller = new AbortController();
		fetch_violationTypeDDL(dispatch, controller.signal);
	}, [dispatch]);

	const VIOLATION_TYPE_LIST = useAppSelector((state) => state?.general);
	const PARKING_VIOLATION = useAppSelector((state) => state?.violations);

	const filterSearch = () => {
		let QueryInput: FilterProps[] = [
			{
				field: 'CAST (VDate AS date)',
				value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: 'CAST (VDate AS date)',
				value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'fullName',
				value: userSearch !== '' ? userSearch : null,
				operator: 'like',
			},
			{
				field: 'PlateNo',
				value: plateSearch !== '' ? plateSearch : null,
				operator: 'contains',
			},
			{
				field: 'FK_ViolationType',
				value: violationType !== '' ? violationType : null,
				operator: '=',
			},
			{
				field: 'Utype',
				value: userType !== '' ? userType : null,
				operator: '=',
			},
		];

		fetch_report_violations_list(navigate, dispatch, {
			'filters': {
				'query': commonSearchQuery(QueryInput, 'AND'),
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.REPORT_VIOLATION_PAGINATION.limit,
			},
		});
	};

	const clearSearch = () => {
		fetch_report_violations_list(navigate, dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.REPORT_VIOLATION_PAGINATION.limit,
			},
		});
		setDateRange([]);
		setUserSearch('');
		setPlateSearch('');
		setViolationType('');
		setUserType('')
	};

	// const vcatArr = [
	// 	{ label : 'Vehicle', value : 1 },
	// 	{ label : 'User', value : 2}
	// ]

	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	return (
		<>
			{/* <div className="float-btn text-right">
				
			</div> */}
						<div className='search-filter-row' ref={ref}>

				<div className='row'>
					<div className='col-8'>
						<ul>
							<li>
								<a href='#quick-search' className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							{PARKING_VIOLATION.REPORT_VIOLATION_FILTER?.query !== '' && (
								<li>
									<a onClick={clearSearch}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
						</ul>
					</div>
					<div className='col-4 text-right'>
					<PermissionWrapper subModuleID={subModuleList['Report Violation']} type='isCreate'>						
						<a onClick={reportModalOpen} className='btn btn-secondary  modal-button'>
							Report Violation
						</a>
					</PermissionWrapper>

					</div>
				</div>

				<div className='filter-block' id='quick-search'>
					<div className='row'>
					<div className='col'>
							<div className='form-group'>
								<div className="pos-rel">

								<input
									type='text'
									name=''
									placeholder='Plate Number '
									className='form-control'
									value={plateSearch}
									onChange={(e) => 
										{
											let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
											setPlateSearch(v)}}
								/>
								<i className='fa-solid fa-magnifying-glass'></i>

								</div>

							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
								<div className="pos-rel">

								<input
									type='text'
									name=''
									placeholder='User'
									className='form-control'
									value={userSearch}
									onChange={(e) =>{ 
										let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
										
										setUserSearch(v)}}
								/>
								<i className='fa-solid fa-magnifying-glass'></i>

								</div>

							</div>
						</div>
						<div className='col'>
							<div className={`form-group`}>
								<RangePicker
									picker='date'
									allowClear
									allowEmpty={[true, true]}
									className='form-control'
									placeholder={['Violated', ' Between']}
									separator
									value={dateRange}
									format={'DD-MMM-YYYY'}
									disabledDate={(current:any)=>(current > moment().add(1,'day').startOf('day') ) }
									onChange={(dates: any) => {
										setDateRange(dates);
									}}
									inputReadOnly
								/>
								{/* <DatePicker
        							selected={startDate}
        							onChange={(dates:any) =>{
										const [start, end] = dates;
										setStartDate(start)
										setEndDate(end)
									}}
        							selectsStart
									dateFormat={'dd-MMM-yyyy'}
									className='form-control'
        							startDate={startDate}
        							endDate={endDate}
									selectsRange
									placeholderText="Violation Date Between"
									monthsShown={2}
									isClearable={startDate!==''}
      							/> */}
							</div>
						</div>
					
						<div className='col'>
							<div className='form-group'>
								<ReactSelect
									options={VIOLATION_TYPE_LIST?.VIOLATIONTYPE_DDL}
									className={'common-select'}
									placeholder={'Violation Type'}
									isMulti={false}
									getOptionLabel={(o) => {
										return `${o.label} (${o.value === 1 || o.value === "1" ? 'Vehicle' : 'User'})`;
									}}
									getOptionValue={(o:any) => o.vType}
									isClearable
									value={violationType ? VIOLATION_TYPE_LIST?.VIOLATIONTYPE_DDL.find((c) => c?.vType === violationType) : null}
									onChange={(val) => {
										setViolationType(val?.vType);
									}}
								/>
							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
								<ReactSelect
									options={[
										{ value: 1, label: 'Employee' },
										{ value: 2, label: 'Visitor' },
									]}
									className={'common-select'}
									placeholder={'User Type'}
									isMulti={false}
									isClearable
									value={userType ? [
										{ value: 1, label: 'Employee' },
										{ value: 2, label: 'Visitor' },
									].find((c) => c?.value === userType) : null}
									onChange={(val) => {
										setUserType(val?.value);
									}}
								/>
							</div>
						</div>

						{/* <div className='col'>
							<div className='form-group'>
								<input
									type='text'
									name=''
									placeholder='Vehicle User'
									className='form-control'
									value={userSearch}
									onChange={(e) => setUserSearch(e.target.value)}
								/>
							</div>
						</div> */}
						<div className='col-2'>
							<div className='form-group'>
								<button
									onClick={() => {
										filterSearch();
									}}
									className='form-control blue-btn'>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ReportModal open={reportModal} handleClose={reportModalClose} />
		</>
	);
};

export default FilterBar;
