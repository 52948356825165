/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import ProgressBar from '../../../common/components/graph_components/ProgressBar';
import { ExportMultipleChartsToPdf } from './PdfExporter';
import CsvDownload from 'react-csv-downloader';
import { useAppSelector } from '../../../config/hooks';
import { Tooltip } from 'antd';
import { COLORS, COLOR_FX } from '../../../utils/Data';
import PdfExporterModal from './pdfExporterModal';
import { ZoneColors } from '../../../utils/zoneColorCodes';
import { exportDataToExcel } from '../../../common/components/ExcelFileExport';

const ProgressBarCard = (
	{ title, data, loader=false, label='', unit='', fileName='', toolTipVal='', titleArr=[], csvColumns=null }: 
	{ title: string; data: any; loader?:boolean; label?:string; unit?:string; fileName?:string; toolTipVal?:string;
		titleArr?:any; csvColumns?:any }) => {
	const [openExport, setOpenExport] = useState('')
	const [openModal, setOpenModal] = useState(false)

	const ChartObject = () =>{
		return(
			<div className='card-body col-12' id={`ProgressChartId-${title}`}>
					{
						loader ?
							<div className='custom-progressbar skelton-Progress'>
								<label></label>
								<div className='full-progressbar'>
									<ProgressBar value={0}
											color={''}
									
									/>
									<span></span>
								</div>
							</div>
						:
						data && data?.length>0 ?
					data.map((el: any ,key:number) => {
						return (
							<Tooltip className='custom-progressbar' title={openModal ? '' : el?.toolTipVal}>
								<label>{el.name}</label>
								<div className='full-progressbar'>
									<ProgressBar value={el.value}
											color={ZoneColors(el?.name)??COLOR_FX(key)}
									
									/>
									<span>{el.value_label ?? el.value}{unit===''?'%':`${unit}`}</span>
								</div>									
							</Tooltip>
						);
					})
					:
					<div style={{ width:'100%', textAlign:'center' }}>
						<p>No data</p>
					</div>
				}
				</div>
		)
	}

	const img = useAppSelector((state) => state?.applicationSettings?.COMPANY_PROFILE?.photo);
console.log(data,'FILENAME',fileName)
	return (
		<div className={`col-6 ${!loader&&data&&data?.length===0 ? 'disableAndOpacity2' : ''}`}>
			<div className='card-second'>
				<div className='card-header'>
					<h3>{title}</h3>
					<div className='btn btn-sqaure btn-secondary btn-secondary-hover openMiniModalLine' 
						onClick={() =>{ setOpenExport(openExport===title ? '' : title) }}>
						<i className='fa-solid fa-download'></i>
						{	openExport===title ?
						<div className='miniModalOpenBox'>
							<div className='miniBoxModalContent'>
								<div role="button" >
									<button onClick={() => setOpenModal(true)
								// ExportMultipleChartsToPdf({ val: `ProgressChartId-${title}`, pgtitle: `${title} ${fileName}`,
								//  headerimg : img,
								//  pgTitleArr: titleArr??[] })
								 		}>
											<p>Export PDF</p></button>
								</div>
								<div role="button">
									<button onClick={()=>{ 
										return exportDataToExcel(data, `ProgressChart-${title} ${fileName}`,
											`${title??''} ${fileName?.replaceAll('\n',' ')??''}`,
											Object.keys(data[0])?.map((el:any) => {
												return { "id" : el, 
													"displayName" : csvColumns ? csvColumns[`${el.toUpperCase()}`]??el.toUpperCase() : el.toUpperCase()  }
											})
										) }} >
										<p>Export CSV</p>
									</button>
								</div>
								{/* <CsvDownload  
									filename={`ProgressChart-${title} ${fileName}`}
									extension=".csv"
									columns={
										Object.keys(data[0])?.map((el:any) => {
											return { "id" : el, 
												"displayName" : csvColumns ? csvColumns[`${el.toUpperCase()}`]??el.toUpperCase() : el.toUpperCase()  }
										})
									}
									title={`${title??''} ${fileName?.replaceAll('\n',' ')??''}`}
									datas={data}
								>
									<button>
										<p>Export CSV</p>	
									</button>	
								</CsvDownload>	 */}
							</div>
						</div>
						:	null
						}
					</div>
				</div>
				<div className='card-body' id={`ProgressChartId-${title}`}>
					{
						loader ?
							<div className='custom-progressbar skelton-Progress'>
								<label></label>
								<div className='full-progressbar'>
									<ProgressBar value={0}
											color={''}
									
									/>
									<span></span>
								</div>
							</div>
						:
						data && data?.length>0 ?
					data.map((el: any ,key:number) => {
						console.log('ZONECOLORS',el,ZoneColors(el?.name))
						return (
							<Tooltip className='custom-progressbar' title={el?.toolTipVal}>
								<label>{el.name}</label>
								<div className='full-progressbar'>
									<ProgressBar value={el.value}
											color={ZoneColors(el?.name)??COLOR_FX(key)}
									
									/>
									<span>{el.value_label ?? el.value}{unit===''?'%':`${unit}`}</span>
								</div>									
							</Tooltip>
						);
					})
					:
					<div style={{ width:'100%', textAlign:'center' }}>
						<p>No data</p>
					</div>
				}
				</div>
			</div>
			{
          openModal && 
          <PdfExporterModal
            handleClose={()=>setOpenModal(false)}
            children={ChartObject}
            title={title}
            titleArr={titleArr}
          />
        }
		</div>
	);
};

export default ProgressBarCard;
