import React from 'react'

type Props = {
    title : string,
    value : number | string
    className?: string
}

const SimpleBox = (props: Props) => {
    return (
        // <div className="col">
            <div className={"icon-box large-icon-box white-bg text-center "  +props.className }>
                <p className="w-100">{props?.title}</p>
                <h2>{props?.value}</h2>
            </div>
        // </div>
    )
}

export default SimpleBox