import React, { useEffect } from 'react';

interface Iprops {
	open: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	title?: string;
	subtitle?: string;
	big?: boolean;
	buttonName?: string;
	buffonFx?: () => void;
	customHeader?: React.ReactNode;
}

const ModalLayout: React.FC<Iprops> = ({ children, open, handleClose, title, subtitle, big, buttonName, buffonFx, customHeader }) => {


	return (
		<React.Fragment>
			<div className={open ? `modal-popup modal-open ` : 'modal-popup '} >
				<div className='modal-main-wrap'>
					<div className={big ? 'modal-popup-wrapper big-modal' : 'modal-popup-wrapper'}>
						
							{
								customHeader	?
									customHeader
								:	
									<div className='modal-header '>
										<h1 style={{ fontSize:'1rem' , fontWeight:600, textTransform:"uppercase"  }} >{title}</h1>
										<p style={{ fontSize:'.8rem' , marginTop :"5px" }}>{subtitle}</p>
										<div className='close-modal close-modal-button' onClick={handleClose}></div>		
									</div>
							}
							
						{/* <div className='dflex col-dir mr-auto ' style={{ textTransform:"uppercase" , marginTop:"10px" ,gap:"5px"}}>
						<h1 className='p-0 m-0' style={{ fontSize:'1rem' ,}}>{Route1 ? get_keyword(Route1?.name) : get_keyword(Route2?.name)} </h1>
						<p style={{ fontSize:'.7rem'  }}>Parking Management System</p>
					</div> */}
						<div className='modal-body'>{children}</div>
						{buttonName && (
							<div className='modal-footer'>
								<div className='btn btn-outline-blue close-modal-button' onClick={buffonFx}>
									Close
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ModalLayout;
