import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import { commonSearchQuery, FilterProps } from '../../../../../config/filterSearch';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_notification_history, fetch_notification_templateDDL } from '../../../store/action';
import { DatePicker } from 'antd';
import CustomDatePicker from '../../../../../common/components/CustomDatePicker';
import moment from 'moment';

type Props = {}


const options = [
    {
      id: 1,
      label: "1",
      options: [
        { label: "sub", value: "sub" },
        { label: "sub", value: "sub" }
      ]
    },
    {
      id: 3,
      label: "2",
      options: [
        { label: "sub", value: "sub" },
        { label: "sub", value: "sub" }
      ]
    },
    { id: 5, label: "3", options: [{ label: "No sub category", disabled: true }] }
  ];


const FilterBar = (props: Props) => {

    const [notificationTypeList, setNotificationTypeList] = useState([])
    const [notfType, setNotfType] = useState<any>('')
    const [emailid, setEmailid] = useState<any>('')
    const [subject, setSubject] = useState('')
    const [startDate, setStartDate] = useState<any>('');
    const dispatch = useAppDispatch();
    const NOTIFICATION_DATA = useAppSelector((state) => state?.notifications);
    const filterSearch = () => {
		let  QueryInput : FilterProps[] = [
			{
				field :'CAST (SendDate AS date)', 
            	value : startDate!=='' ? `'${dayjs(startDate).format('YYYY-MM-DD')}'` : null, 
            	operator: '='
			},
			{
				field :'EmailTo', 
            	value : emailid!=='' ? emailid : null, 
            	operator: 'like'
			},
			{
				field :'ESubject', 
            	value : subject!=='' ? subject : null, 
            	operator: 'like'
			},
            {
				field :'FKEmailTemplateID', 
            	value : notfType!=='' ? notfType : null, 
            	operator: '='
			}
		]
		fetch_notification_history(
				dispatch,
				{
					'filters': {
						'query': commonSearchQuery(QueryInput),
					},
					'pagination': {
                        'pageNo': 1,
                        'limit': NOTIFICATION_DATA?.NOTIFICATION_HISTORY_PAGINATION?.limit,
					},
				},
				
			);
	}

    const clearSearch = () =>{
		fetch_notification_history(
            dispatch,
            {
                'filters': {
                    'query': '',
                },
                'pagination': {
                    'pageNo': 1,
                    'limit': NOTIFICATION_DATA?.NOTIFICATION_HISTORY_PAGINATION?.limit,
                },
            },
            
        );
		setStartDate('')
		setSubject('')
        setEmailid('')
        setNotfType('')
	}

    useEffect(() => {
        fetch_notification_templateDDL().then(res => {
            console.log('RESP',res)
            if(res && res?.response?.data){
                setNotificationTypeList(res?.response?.data.map((el:any)=>{ return { value : el.templateId, label : el.templateCode } }))
            }
        })
    }, [])
    

    return (
        <>
            <div className="search-filter-row mb-30">
                <div className="row">
                    <div className="col-5">
                        <ul>
                            <li>
                                <a className="active pointerDefaultSet"><i className="fa-solid fa-magnifying-glass"></i> Quick Search</a>
                            </li>
                            { NOTIFICATION_DATA.NOTIFICATION_HISTORY_FILTER?.query!=='' &&
                            <li>
					            <a onClick={clearSearch}>
						            <i className='fa-solid fa-times'></i>Clear All Filters
					            </a>
				            </li>
				            }
                        </ul>
                    </div>
                </div>

                <div className="filter-block mb-30" id="quick-search">
                    <div>
                        <div className="row">
                            <div className="col">
                                <CustomDatePicker
                                    value={startDate ? moment(startDate) : null}
                                    onChange={(date:any)=>{
                                        setStartDate(date)
                                    }}
                                    format={'DD-MMM-YYYY'}
                                    allowClear
                                    placeholder='Exception Date'
                                    className='form-control'
                                />
                            {/* <div className='form-group date-iconAdd'>
								<ReactDatePicker
        							selected={startDate}
        							onChange={(date:any) =>{
										setStartDate(date)
									}}
									dateFormat={'dd-MMM-yyyy'}
									className='form-control'
        							startDate={startDate}
									placeholderText="Exception Date"
									isClearable={startDate!==''}
      							/>
							</div>
							 */}
                            </div>

                            <div className="col">
                                <div className="form-group">
                                    <input type="text" name="" placeholder="Send Email" className="form-control" value={emailid} onChange={(v)=> setEmailid(v.target.value) } />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input type="text" name="" placeholder="Email Subject" className="form-control" value={subject} onChange={(v)=> setSubject(v.target.value)} />
                                </div>
                            </div>
                            
                            <div className="col">
                                <div className="form-group">
                                    <ReactSelect
										options={notificationTypeList}
										className={'common-select'}
										placeholder={'Notification Type'}
										isMulti={false}
                        	    		value={notfType ? notificationTypeList.find((c:any) => c.value === notfType) : null}
                            			onChange={(val:any) =>{ setNotfType(val?.value) }}
									/>
                                </div>
                            </div>                       

                            <div className="col-2">
                                <div className="form-group">
                                    <button onClick={()=> filterSearch() } className="form-control blue-btn">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FilterBar