import React from 'react'

type Props = {
  chipType? : "success" | "error" | "pending" | "warning" | "",
  text?: string,
  classname?: string
}

const StatusChipComponent = (props: Props) => {
  return (
    <label className={ 
      `${props.chipType==='success' ? "badge green"
    : props.chipType==='error' ? "badge red"
    : props.chipType==='pending' ? "badge dark-blue"
    : props.chipType==='warning' ? "badge yellow" 
    : props.chipType==='' ? '' :
    "badge blue"} ${props?.classname}`}>
      {
        props.text && props.text!=='' ? props.text
        : props.chipType==='success' ? "Success"
        : props.chipType==='error' ? "Error"
        : props.chipType==='pending' ? "Pending"
        : "Warning"
      }
    </label>
  )
}

export default StatusChipComponent