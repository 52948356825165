import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../../common/layouts/ModalLayout';

// import { useAppDispatch } from './../../../../config/hooks';
// import { fetch_departmentDDL, fetch_designationDDL, fetch_priorityLevelDDL, fetch_siteDDL } from '../../../../common/general/action';
import AddUser from './AddVisitor';
import AddVehicle from './AddVehicle';
import { fetch_placeOfRegList } from '../../../../common/general/action';
import { useAppDispatch } from '../../../../config/hooks';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

const AddModal = (props: Props) => {
	const [tabVal, setTablVal] = useState(1);

	let dispatch = useAppDispatch();

	useEffect(() => {
		const controller = new AbortController();
		
		fetch_placeOfRegList(dispatch, controller.signal)
		// fetch_departmentDDL(dispatch, controller.signal);
		// fetch_priorityLevelDDL(dispatch, controller.signal);
		// fetch_siteDDL(dispatch, controller.signal);
		// fetch_designationDDL(dispatch, controller.signal);
		return () => controller.abort();
	}, [dispatch]);

	return (
		<ModalLayout
			open={props.open}
			handleClose={props.handleClose}
			title={props.Data ? 'Edit Visitor ' : 'Add Visitor'}
			subtitle={tabVal ===1 ? "Edit visitor details" :"Edit visitor vehicle details"}
			big>
			<div className='form-tabs'>
				<ul>
					<li>
						<a  className={`${!tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(1)}>
							Visitor Info
						</a>
					</li>
					{props?.Data && (
						<li>
							<a  className={`${tabVal ? '' : 'active'} tab-link`} onClick={() => setTablVal(0)}>
								Vehicle Info
							</a>
						</li>
					)}
				</ul>
			</div>

			<div className='modal-body'>
				{tabVal === 1 ? (
					<AddUser Data={props?.Data} handleClose={props?.handleClose} />
				) : (
					<AddVehicle Data={props?.Data} handleClose={props?.handleClose} />
				)}
			</div>
		</ModalLayout>
	);
};

export default AddModal;


