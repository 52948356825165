/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { findTheEvent } from '../../../../../utils/moduleEvent';
import { fetch_application_logs } from '../../../store/action';
import ViewApplicationLog from './ViewApplicationLog'
import dayjs from 'dayjs';

function ListTable() {
	const fetchIdRef                    = useRef(0);
	const [viewModal, setViewModal] = useState(false)
	const [appDetail, setAppDetail] = useState(null)
    const ViewModalClose = () => setViewModal(false)
	
	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();
	const APPLICATION_LOGS_DATA = useAppSelector((state) => state?.logs)
	const ViewModalOpen = (data:any) =>{
		setAppDetail(data)
		setViewModal(true)
	}

	const columns = React.useMemo(
		()	=>	[
			{ Header: 'ID', accessor:'transactionID' ,orderByKey : "transactionID", tdClass : 'dataClip'},
			{ Header: 'DATE-TIME', accessor:'createdOn', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A' ,orderByKey : "createdOn"},
			{ Header: 'USERNAME', accessor:'createdUser', tdClass : 'dataCapitalize'},
			{ Header: 'SUB MODULE', accessor:'subModuleName', tdClass : 'dataCapitalize'},
			
			{ Header: 'EVENT ID', accessor:'applicationEventID',orderByKey : "ApplicationEventID"},
			{ Header: 'EVENT TYPE', accessor:'eventKey',orderByKey : "descriptions",
			Cell: ({ row }: any) => {
				return (
					<Fragment>
						{findTheEvent(row?.values?.eventKey)}
					</Fragment>
				);
			},
			},
			// { Header: 'EVENT DESCRIPTION', accessor:'descriptions',orderByKey : "Descriptions", tdClass : 'dataClip'},
			
			{ Header: 'SOURCE IP',accessor:'ip'},
			{ Header: 'INTERFACE', accessor:'source',
			Cell: ({ row }: any) => {
				return (
					<Fragment>
						{userTypes[parseInt(row?.values?.source)-1]}
					</Fragment>
				);
			},
			},
			{ Header: 'STATUS', accessor:'status',
			Cell: ({ row }: any) => {
				return (
					<Fragment>
						<StatusChipComponent chipType={row?.values?.status===1 ? 'success' : row?.values?.status===0 ? 'error' : 'pending'} />
					</Fragment>
				);
			},
			
			},
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a  onClick={() => ViewModalOpen(row?.original)}  className="btn btn-sqaure btn-white modal-button"><i className="fa-solid fa-eye"></i></a>
							</div>
						</Fragment>
					);
				},
				show: true,
			} 	
		],
		[]
	);

	type IfetchData = {
		pageSize :number,
		pageIndex :number,
		signal :AbortSignal,
		OrderBy : string,
		filters: {query: string;}

	}

	let userTypes = [ 'System User',  'Api User']

	const fetchData = useCallback(({ pageSize, pageIndex ,signal ,OrderBy ,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_application_logs(navigate, dispatch, {
				'filters': {
					'query':filters.query || `CAST (CreatedOn AS date) >= '${dayjs().subtract(3,'day').format('YYYY-MM-DD')}' AND CAST (CreatedOn AS date) <= '${dayjs().format('YYYY-MM-DD')}'`,
					OrderBy
				},
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			},signal);
		}
	}, [dispatch, navigate]);


    return (
		<>
			<CommonTable 
				data={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_LIST??[]} 
				columns={columns} 
				pageCount={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_PAGINATION?.totalCount ?? 0} 
				fetchData={fetchData} 
		  		limit={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_PAGINATION?.limit ?? 1} 
				page_no={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_PAGINATION?.pageNo ?? 1}
				error={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_ERROR ?? ''}
				filters={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_FILTER}	
				loading={APPLICATION_LOGS_DATA?.APPLICATION_LOGS_LOADER}			
		
			/>
          { viewModal && <ViewApplicationLog  open={viewModal} handleClose={ViewModalClose} Data={appDetail} />}
		</>
    );
}

export default ListTable;