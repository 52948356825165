/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
// import CommonInput from '../../../../../common/components/commonInput';
import { useAppDispatch } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { fetch_rolelist } from '../../../store/action';
import AddRole from './addRole';

type Props = {}

const FilterBar = (props: Props) => {

    const [modalOpen, setmodalOpen] = useState(false);
    const handleOpen = () => setmodalOpen(true);
    const handleClose = () => setmodalOpen(false);

    const [search, setsearch] = useState('')

    let navigate = useNavigate()
    const dispatch = useAppDispatch()

    const searchFx = (val: any) => {
        setsearch(val)
        let posdData = {
            "filters": {
                "query": val
            },
            "pagination": {
                "pageNo": 1,
                "limit": 10
            }
        }
            fetch_rolelist(navigate, dispatch, posdData)


    }


    return (
        <div className="search-row-button">
            <div className="row">
                <div className="col-5">
                    <div className="form-group">
                        <div className="pos-rel">
                            <input className="form-control grey-input" name="" value={search} placeholder="Search Here" onChange={(e) =>
                                {

                                    let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
                                    searchFx(v)
                                }
                               } />
                            {/* <CommonInput value={search} setValue={setsearch} className="form-control grey-input"  placeholder="Search Here"   validateFx={validateFx}/> */}
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                </div>
                <div className="col-7 text-right">
                    <PermissionWrapper subModuleID={subModuleList.Roles} type='isCreate'>
                        <a onClick={handleOpen} className="btn btn-secondary modal-button">Add New Roles</a>
                    </PermissionWrapper>

                </div>
            </div>

            <>
                <AddRole handleClose={handleClose} open={modalOpen} />
            </>
        </div>
    )
}

export default FilterBar