/* eslint-disable jsx-a11y/anchor-is-valid */
import FormSelect from "../../../../../common/components/FormSelect";
import { useForm } from "react-hook-form";
import { commonSearchQuery, FilterProps } from "../../../../../config/filterSearch";
import { fetch_webusers_request, search_user, search_web_user } from "../../../store/action";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import AsyncSelect from "react-select/async";
import { useEffect, useRef, useState } from "react";

type Props = {};

let statusOptions = [
  { label: "Pending", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Rejected", value: 2 },
  { label: "Cancelled", value: 3 },
];

const FilterBar = (props: Props) => {
  const { register, handleSubmit, control, reset, setValue } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
  });

  let dispatch = useAppDispatch();
  const [usersList, setusersList] = useState([]);
  const [userOptions, setuserOptions] = useState<any>([]);
  const [usersByList, setusersByList] = useState([]);
  const [userByOptions, setuserByOptions] = useState<any>([]);
  useEffect(() => {
    handleUsrForInputChange();
    handleUsrByInputChange();
  }, []);

  const SECURITY_DATA = useAppSelector((state) => state?.security);

  const handleUsrForInputChange = async (key?: string) => {
    let postData = {
      filters: {
        query: key ? `(firstName like '%${key}%')` : "",
      },
      pagination: {
        pageNo: 1,
        limit: 20,
      },
    };
    let Response = await search_user(postData);

    let data = Response?.response?.data ?? [];

    let Options = data.map((item: any) => ({
      value: item?.userUniqueID,
      label: item?.firstName + " - " + item?.emailID,
    }));
    setusersList(data);
    setuserOptions(Options || []);
    return Options;
  };

  const handleUsrByInputChange = async (key?: string) => {
    let postData = {
      filters: {
        query: key ? `(firstName like '%${key}%')` : "",
      },
      pagination: {
        pageNo: 1,
        limit: 20,
      },
    };
    let Response = await search_web_user(postData);

    let data = Response?.response?.data ?? [];

    let Options = data.map((item: any) => ({
      value: item?.fK_UserUniqueID,
      label: item?.userName + " - " + item?.emailID,
    }));
    setusersByList(data);
    setuserByOptions(Options || []);
    return Options;
  };

//   const promiseUsrForOptions = (inputValue: string) =>
//     new Promise<any[]>((resolve) => {
//       resolve(handleUsrForInputChange(inputValue));
//     });

//   const promiseUsrByOptions = (inputValue: string) =>
//     new Promise<any[]>((resolve) => {
//       resolve(handleUsrByInputChange(inputValue));
//     });

//   const handleUsrForChange = (val: any) => {
//     let data: any = usersList.find((item: any) => item?.userUniqueID === val?.value);

//     console.log("RequestedFor", val);
//     console.log("RequestedFor", usersByList);
//     console.log("RequestedFor", data);

//     setValue("RequestedFor", data?.userUniqueID);
//   };

//   const handleUsrByChange = (val: any) => {
//     let data: any = usersByList.find((item: any) => item?.fK_UserUniqueID === val?.value);

//     console.log("usersByList RequestedBy", data);

//     console.log(val);
//     console.log(usersByList);
//     console.log(data);

//     setValue("RequestedBy", data?.fK_UserUniqueID);
//   };

  const handleUsrForChangeNew = (val: any) => {
    setValue("RequestedFor", val?.value);
  };

  const handleUsrByChangeNew = (val: any) => {
    setValue("RequestedBy", val?.value);
  };


  const onSubmitHandler = (data: any) => {
    let QueryInput: FilterProps[] = [
      {
        field: "RequestStatus",
        value: data?.RequestStatus,
        operator: "=",
      },
      {
        field: "RequestedFor",
        value: data?.RequestedFor,
        operator: "=",
      },
      {
        field: "roles",
        value: data?.roles,
        operator: "=",
      },
      {
        field: "RequestedBy",
        value: data?.RequestedBy,
        operator: "=",
      },
    ];

    let query = commonSearchQuery(QueryInput, "AND");

    console.log("QueryInputDATA", data);

    console.log("QueryInput", QueryInput);

    console.log("query", query);

    fetch_webusers_request(dispatch, {
      filters: {
        query: query,
      },
      pagination: {
        pageNo: 1,
        limit: SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.limit,
      },
    });
  };

  
  const loadOptionsby = async (key: any) => {
    return handleUsrByInputChange(key).then((res) => {
      return res;
    });
  };
  

  const loadOptionsfor = async (key: any) => {
    return handleUsrForInputChange(key).then((res) => {
      return res;
    });
  };

  const selectInputRef1  :any= useRef();
  const selectInputRef2 :any = useRef();
  const selectInputRef3 :any = useRef();


  const onClear = () => {
    selectInputRef1?.current?.clearValue();
    selectInputRef2?.current?.clearValue();
    selectInputRef3?.current?.clearValue();
	
  };





  return (
    <div className="search-filter-row">
      <ul>
        <li>
          <a  className="active pointerDefaultSet">
            <i className="fa-solid fa-magnifying-glass"></i> Quick Search
          </a>
        </li>
        {SECURITY_DATA.WEBUSER_REQUEST_FILTER?.query !== "" && (
          <li>
            <a
              onClick={() => {
                reset();
                fetch_webusers_request(dispatch, {
                  filters: {
                    query: "",
                  },
                  pagination: {
                    pageNo: 1,
                    limit: SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.limit,
                  },
                });
				onClear()
              }}
			  
            >
              <i className="fa-solid fa-times"></i>Clear All Filters
            </a>
          </li>
        )}
      </ul>

      <div className="filter-block" id="quick-search">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <FormSelect
                  control={control}

                  name="RequestStatus"
                  options={statusOptions}
                  isMulti={false}
                  placeholder="Status"
                />
              </div>
            </div>

            {/* <div className='col'>
							<div className='form-group'>
								<AsyncSelect
									className={'common-select'}
									cacheOptions
									filterOption={() => true}
									loadOptions={promiseUsrByOptions}
									onChange={handleUsrByChange}
									placeholder='Requested By'
									defaultOptions={userByOptions}
									isClearable
								/>
							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
								<AsyncSelect
									className={'common-select'}
									cacheOptions
									filterOption={() => true}
									loadOptions={promiseUsrForOptions}
									onChange={handleUsrForChange}
									placeholder='Requested For'
									defaultOptions={userOptions}
									isClearable
								/>
							</div>
						</div> */}

            <div className="col">
              <div className="form-group">
                <AsyncSelect
				ref={selectInputRef1}
                  className={"common-select"}
                  cacheOptions
                  filterOption={() => true}
                  loadOptions={loadOptionsby}
                  onChange={handleUsrByChangeNew}
                  placeholder="Requested By "
                  isClearable
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <AsyncSelect
				ref={selectInputRef2}

                  className={"common-select"}
                  cacheOptions
                  filterOption={() => true}
                  loadOptions={loadOptionsfor}
                  onChange={handleUsrForChangeNew}
                  placeholder="Requested For "
                  isClearable
                />
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <button className="form-control blue-btn">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterBar;
