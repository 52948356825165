 // export const a =''

import Request from "../../../config/api";
import { DASHBOARD_24_HOUR, DASHBOARD_AVERAGE_OCCUPANCY, DASHBOARD_OCCUPANCY_LEVELMAP, DASHBOARD_OCCUPANCY_HEATMAP, DASHBOARD_PARKING_AVAILABILITY, DASHBOARD_PARKING_REQUEST_COUNT, DASHBOARD_TOTAL_IN_OUT, DASHBOARD_VIOLATIONS_CHART, DASHBOARD_ENTERED_VEHICLE_COUNT, DASHBOARD_TURNOVER, DASHBOARD_NO_OF_VISIT_COUNT, DASHBOARD_NO_OF_OPERATORS, DASHBOARD_TOTAL_BAY_COUNT, DASHBOARD_SYSTEM_USER_COUNT, DASHBOARD_AVERAGE_APPROVE_REQUEST, DASHBOARD_TOTAL_MANAGER_COUNT, DASHBOARD_TURNOVER_OVERALL_COUNT, DASHBOARD_SETTINGS_ACTION, DASHBOARD_SECURITY_MANUAL_VIOLATIONS, REPORTS_AND_DASHBOARD_GRAPH, DASHBOARD_SECURITY_DWELL_AVERGAE_OVERALL, DASHBOARD_VIOLATIONS_BY_ZONE, DASHBOARD_24_HOUR_BY_ZONE } from "../../../config/endpoint";
import { DashboardPostData } from './dashboard.interface';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';


export const fetch_parking_availability = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_PARKING_AVAILABILITY;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_moreThan_24hr = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_24_HOUR;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_occupancy_levelmap = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_OCCUPANCY_LEVELMAP;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_occupancy_heatmap = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_OCCUPANCY_HEATMAP;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_totalInOut = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_TOTAL_IN_OUT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_parkingRequest_count = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_PARKING_REQUEST_COUNT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_violations_chart = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_VIOLATIONS_CHART;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_average_occupancy = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_AVERAGE_OCCUPANCY;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_entered_vehicle_count = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_ENTERED_VEHICLE_COUNT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
};

export const fetch_turnover = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_TURNOVER;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_no_of_visits = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_NO_OF_VISIT_COUNT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_no_of_operators = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_NO_OF_OPERATORS;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_total_bayCount = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_TOTAL_BAY_COUNT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_total_systemUserCount = (signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_SYSTEM_USER_COUNT;
    return Request('get', api_url, '', signal, Sucess, Failed)
}
export const fetch_average_approve_requests = (signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_AVERAGE_APPROVE_REQUEST;
    return Request('get', api_url, '', signal, Sucess, Failed)
}
export const fetch_total_managers = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_TOTAL_MANAGER_COUNT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_turover_overall_count = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_TURNOVER_OVERALL_COUNT;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_security_manual_violations = (postData: DashboardPostData, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_SECURITY_MANUAL_VIOLATIONS;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_dwell_time_overall = (postData: object, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_SECURITY_DWELL_AVERGAE_OVERALL;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_dwell_distribution_time = (postData: object, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = REPORTS_AND_DASHBOARD_GRAPH + 'distribution-dwell-time-overall';
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const dashboard_settings_action = (type: t, postData: any, sucess: (res: any) => void, failed: (err: any) => void) => {
    let api_url = DASHBOARD_SETTINGS_ACTION;
    Request(type, api_url, postData, undefined, sucess, failed)
};


export const fetch_violations_by_zone = (postData: any, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_VIOLATIONS_BY_ZONE;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}

export const fetch_parkedMoreThanDay_by_zone = (postData: any, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = DASHBOARD_24_HOUR_BY_ZONE;
    return Request('post', api_url, postData, signal, Sucess, Failed)
}


export const fetch_entryexitoccupancy_dash = (postData : object, signal?: AbortSignal, Sucess?: (res: any) => void, Failed?: (err: any) => void) =>{
    
    return Request('post',`${REPORTS_AND_DASHBOARD_GRAPH}entry-exit-vehicle`, postData, signal, Sucess, Failed)
}


