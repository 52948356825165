import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorComponent from '../../../common/components/ErrorComponent';
import TreeSelectCustom from '../../../common/components/tree_select/NewTreeSelectCustom';
import { fetch_BayDDL } from '../../../common/general/action';
import ModalLayout from '../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { FailedToast, FormatDate, SuccesToast } from '../../../utils/utilFunctions';
import { checkVehicleHasRequests, fetch_parkingRequestslist, get_available_zonesListGraph, get_reservedBayList, parkingRequestStatus_action } from '../store/action';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import ZoneAvailabilityCheckGraph from './NewParkingRequestModal/ZoneAvailabilityCheckGraph';
import ReactSelect from 'react-select';
import CheckAvilability from './NewParkingRequestModal/CheckAvilability';
import ReserveABay from './NewParkingRequestModal/ReserveABay';
import dayjs from 'dayjs';
import AllocationChart from './NewParkingRequestModal/AllocationChart';
import ButtonLoader from '../../../common/components/ButtonLoader';


type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};

type ddl = {
    label: string;
    value: any;
};

const schema = yup.object().shape({
	reqestType : yup.number(),
    ApprovedComments : yup.string().required(),
    ApprovedZoneID : yup.number().when('reqestType', 
		{ 
			is : (reqestType:any) => reqestType===1 || reqestType===2 || reqestType===5 || reqestType===6,
			then : yup.number().required()
		}),
    // ApprovedBayID : yup.number().default(0),
	SiteID : yup.number().when('reqestType', 
	{ 
		is : (reqestType:any) => reqestType===1 || reqestType===2 || reqestType===5 || reqestType===6,
		then : yup.number().required('Issue in Data')
	}),
	isVehicleHasPendingRequests: yup.boolean().oneOf([false], 'Pending requests exists binding current vehicle.'),

})


const ApproveModal = (props: Props) => {
	const dispatch = useAppDispatch();
    const navigate = useNavigate();
	// const [zoneTrafficOpen, setZoneTrafficOpen] = useState(false)
	// const [reservedBaysOpen, setReservedBaysOpen] = useState(false)
	// const [graphLoading, setGraphLoading] = useState(true)
	// const [bayLoading, setBayLoading] = useState(true)
	// const [zoneOccupancyByHourEachDay, setZoneOccupancyByHourEachDay] = useState([])
	// const [reservedBayList, setReservedBayList] = useState([])
	// const [selectedBay, setselectedBay] = useState<any>(null)
	const [isAllocationAvail, setIsAllocationAvail] = useState<any>([])
	const [tabData, setTabData] = useState<any>([])
	const [selectAllocation, setSelectAllocation] = useState<any>()
	const [loading, setLoading] = useState(false)
	const [listLoading, setListLoading] = useState(false)
	
	useEffect(() => {
		const controller = new AbortController();
		
	  fetch_BayDDL(dispatch, undefined,  controller.signal)
	  reset()
	clearErrors()
	if(props?.Data?.fK_RequestType)
		setValue('reqestType',props?.Data?.fK_RequestType)
		if(props?.Data?.fK_RequestType===3 || props?.Data?.fK_RequestType===4)
			isVehicleWithPendingRequests(props?.Data?.pRequestID)
	}, [])
	const [selZone, setSelZone] = useState<ddl[]>([])
	// const [levelId, setLevelId] = useState('')
	const setLevelSite = (val:any) => {
		if(val?.site)
			setValue('SiteID',val?.site)
		if(val?.zone){
			let postData = { 'query' : `FK_ZoneID=${val?.zone}` }
			const controller = new AbortController();
			fetch_BayDDL(dispatch, postData,  controller.signal)
		}

	}
	const PARKINGREQUESTS              = useAppSelector((state) => state?.parkingrequest);
	const { register, handleSubmit,  formState: { errors }, reset, control, setValue, watch, clearErrors, trigger } = useForm({
		mode: 'onBlur',
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        shouldFocusError: true,
	})
	const approveFn = (data:any) =>{
		setLoading(true)
		let arrVal = []
			if(props?.Data?.fK_RequestType===1 || props?.Data?.fK_RequestType===2){
				arrVal.push(
					{
						ApproverComments : data?.ApprovedComments,
						ApprovedZoneID	 : data?.ApprovedZoneID,
						SiteID			 : props?.Data?.fK_SiteID ? props?.Data?.fK_SiteID : data?.SiteID,
						ApprovedBayID    : data?.ApprovedBayID,
						Status 			 : 1,
						reqestType		 : data?.reqestType,
						RequestID        : props.Data?.pRequestID
					}
					)
			}else if( props?.Data?.fK_RequestType===5 || props?.Data?.fK_RequestType===6){
				arrVal.push(
                {
                    ApproverComments : data?.ApprovedComments,
					ApprovedZoneID	 : data?.ApprovedZoneID,
					SiteID			 : props?.Data?.fK_SiteID ? props?.Data?.fK_SiteID : data?.SiteID,
					ApprovedBayID    : data?.ApprovedBayID,
					Status 			 : 1,
					reqestType		 : data?.reqestType,
                    RequestID        : props.Data?.pRequestID,
					AllocationRefID  : selectAllocation
                }
				)
			}else{
				
				arrVal.push(
					{
						ApproverComments : data?.ApprovedComments,
						Status 			 : 1,
						RequestID        : props.Data?.pRequestID,
						reqestType 		 : data?.reqestType
					}
				)
            
			
            
        }
		let postData = {
            UpdateParkingRequest : [...arrVal]
        }
		const Success = (res: any) => {
			setLoading(false)
            SuccesToast(res?.response?.data);
            fetch_parkingRequestslist(navigate, dispatch, {
				'filters': {
					'query': PARKINGREQUESTS?.PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
            reset();
            props.handleClose();
        };
        const Failed = (err: any) => {
			setLoading(false)
            FailedToast(err?.response)		;
			fetch_parkingRequestslist(navigate, dispatch, {
				'filters': {
					'query': PARKINGREQUESTS?.PARKING_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
					'limit': PARKINGREQUESTS?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
				},
			});
            props.handleClose();

        };

        parkingRequestStatus_action(postData, Success, Failed);
        
	}
	const GENERAL_DDL = useAppSelector((state) => state?.general);

	const allSameZone = () =>{
		if(props.Data?.id.length === 1)
			return true 
		else 
			return props.Data?.zone ? true : false
	}

	const isVehicleWithPendingRequests = (id:number) => {
		const successFx = (res: any) => {
			console.log(res);
			setValue('isVehicleHasPendingRequests', res?.response?.data && res?.response?.data?.length>0);
			trigger('isVehicleHasPendingRequests');
		};
		const failedFx = (err: any) => {
			console.log(err);
		};
		checkVehicleHasRequests( id , successFx, failedFx);
		
	};
	const selectionFn = (val:any) =>{
		if(val){
			setValue('SiteID',val?.fK_SiteID)
			setValue('ApprovedZoneID', val?.fK_ZoneID)
			setValue('ApprovedBayID', val?.fK_BayID)
		}
	}
	console.log('EERORS',errors)
	return (
		<ModalLayout big open={props.open} handleClose={()=>{ reset()
			clearErrors()
			 props.handleClose()}} title='Approve Parking Request'>
			<form onSubmit={handleSubmit(approveFn)} className={props?.Data?.fK_RequestType ? '' : allSameZone() ? '' : 'disableAndOpacity2'}>
				<div className='row'>
					{	props?.Data?.fK_RequestType===1 || props?.Data?.fK_RequestType===2
						|| props?.Data?.fK_RequestType===5 || props?.Data?.fK_RequestType===6
					?
					<div className='col-12'>
						{
											props?.Data?.fK_RequestType===5 || props?.Data?.fK_RequestType===6 ?
												<AllocationChart pRequestID={props?.Data?.pRequestID} tabData={setTabData}
												setIsAllocationAvail={setIsAllocationAvail}
												datePeriod={[dayjs(props.Data?.startDate,'YYYY-MM-DD'), dayjs(props.Data?.endDate,'YYYY-MM-DD')]} 
												rfUserType={props?.Data?.rfUserType??null}
												isVisitor={props?.Data?.rfUserType===1 ? false : true} 
												reqTp={props?.Data?.fK_RequestType} 
												hideAllocation={false}
												userId={props?.Data?.requestedFUID}
												setAllocation={setSelectAllocation}
												selectionFn={selectionFn}

												selectAllocation={selectAllocation}
												setListLoading={setListLoading}
												/>
											:	
												null
										}
						{
							props?.Data?.fK_RequestType===1 || props?.Data?.fK_RequestType===2 ?
							<>
						<div className='form-group inline-input'>
							<label className='col-3'>Zone </label>
							<TreeSelectCustom 	grey classnames={'col-9'} fixSite={props.Data?.fK_SiteID} styleSx={{ maxWidth:'unset' }}
								selectedZones={selZone}	setselectedZones={setSelZone}	
								onchangeFx={(e:any)=>{ 
									
									setValue('ApprovedZoneID',e?.value);
							}}
								showAll={setLevelSite}	isMulti={false}/>
						</div>
						<ErrorComponent name='ApprovedZoneID' errors={errors} title='Zone' />
						<ErrorComponent name='fK_SiteID' errors={errors} title='Site'/>
						</>	: null
						}
						{	(selZone && selZone?.length!==0 && props.Data?.startDate && props.Data?.endDate ) &&
											!(props.Data && (props.Data?.requestStatus===1 || props.Data?.requestStatus===2)) ?
											
                                        <div className='dflex align-items-center gap-5 w-100 p-10' style={{ justifyContent: 'flex-end' }}>
											<CheckAvilability selectedZone={selZone[0]}
											 date={[dayjs(props.Data?.startDate,'YYYY-MM-DD'), dayjs(props.Data?.endDate,'YYYY-MM-DD')]} />
                                            
										</div>
										:	null
										}
										
						
					</div>
					:	null
					}
										
					<div className='col-12'>
						<div className='form-group inline-input'>
							<textarea placeholder='Remarks' className='form-control grey-input' {...register('ApprovedComments')}></textarea>
						</div>
					</div>
					<ErrorComponent name='ApprovedComments' errors={errors} title='Comments' />
					<ErrorComponent name='isVehicleHasPendingRequests' title='Is Vehicle Allocated' errors={errors}/>

				</div>
										
										{
											!listLoading &&
											props?.Data && tabData && tabData?.length===0 &&
											(props?.Data?.fK_RequestType===5 || props?.Data?.fK_RequestType===6) &&
											<p className='hookform-error-message'>Overtime Allocations can ONLY BE APPROVED if there are any previously approved allocations exists.</p>
										}
				<div className='col-12'>
					<div className='modal-footer'>
						<button type='submit' className='btn btn-secondary' disabled={loading}>
							<ButtonLoader loading={loading} text="Approve" />
						</button>
						<button type='reset' onClick={()=>{ reset()
		clearErrors()
		 props.handleClose()}} className='btn btn-outline-blue close-modal-button'>
							Close
						</button>
					</div>
				</div>
				</form>
		</ModalLayout>
	);
};

export default ApproveModal;
