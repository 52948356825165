import { useMemo } from 'react';
import { useAppSelector } from '../../config/hooks';
import jwt_decode from 'jwt-decode';

interface IauthData {
	isAuth: boolean;
	token: string | null;
	exp: number;

}

interface Ijdt {
	user_id: string;
	username: string;
	uuid: string;
	nbf: number;
	exp: number;
	iat: number;
}

const checkIfExpired = (exp: number) => {
	if (Date.now() >= exp * 1000) {
		return false;
	} else {
		return true;
	}
};
export default function useIsAuthenticated() {
	const token: any = useAppSelector((state) => state.login.TOKEN);
	const authData = useMemo(() => {

		if (!token) {
			return null;
		} else {
			try {
				const jdt: Ijdt = jwt_decode(token);
				let authData: IauthData = {
					token: token,
					isAuth: checkIfExpired(jdt?.exp),
					exp : jdt?.exp
				};
				return authData;
			} catch {
				return null;
			}
		}
	}, [token]);

	return authData;
}
