import React, { Fragment, useEffect, useState } from 'react';
import { FetchAvailabilityCheck } from '../../../store/action';
import { useAppDispatch, useAppSelector } from './../../../../../config/hooks';
import { Card, Col, Row } from 'antd';

type Props = {};

const RealTimeGraph = (props: Props) => {
	let dispatch = useAppDispatch();

	const data: any = useAppSelector((state) => state?.realtime?.AVAILABILITY_CHECK_LIST_DATA);
	const dataArray: any = useAppSelector((state) => state?.realtime?.AVAILABILITY_CHECK_LIST_DATA?.data) ?? [];

	const ParikignAvlFilter = useAppSelector((state) => state?.realtime?.AVAILABILITY_CHECK_FILTER);

	// useEffect(() => {
	// 	if (ParikignAvlFilter) {
	// 		setValue('SiteID', ParikignAvlFilter?.SiteID);
	// 		setValue('FloorID', ParikignAvlFilter?.FloorID);
	// 		setValue('ZoneID', ParikignAvlFilter?.ZoneID);
	// 	}

	// useEffect(() => {
	// 	let postData = {
	// 		'SiteID': ParikignAvlFilter?.SiteID,
	// 		'FloorID': ParikignAvlFilter?.FloorID,
	// 		'ZoneID': ParikignAvlFilter?.ZoneID,
	// 	};
	// 	FetchAvailabilityCheck(dispatch, postData);
	// }, [ParikignAvlFilter?.FloorID, ParikignAvlFilter?.SiteID, ParikignAvlFilter?.ZoneID, dispatch]);

	const [active, setactive] = useState('all');

	return (
		<div className='col'>
			{/* <div className='row mb-30'>
				<div className='col'>
					<div className={active === 'Normal' ? 'icon-box bg-grey bg-primary-bs' : 'icon-box bg-grey'} onClick={()=>setactive('Normal')}>
						<div className='icon-wrap'>
							<i className='fa-solid fa-user'></i>
						</div>
						<span>
							Normal <span>0</span>
						</span>
					</div>
				</div>

				<div className='col'>
					<div className={active === 'Ladies' ? 'icon-box bg-grey bg-primary-bs' : 'icon-box bg-grey'} onClick={()=>setactive('Ladies')}>
						<div className='icon-wrap'>
							<i className='fa-solid fa-user'></i>
						</div>
						<span>
							Ladies <span>0</span>
						</span>
					</div>
				</div>

				<div className='col'>
					<div className={active === 'Occupied' ? 'icon-box bg-grey bg-primary-bs' : 'icon-box bg-grey'} onClick={()=>setactive('Occupied')}>
						<div className='icon-wrap'>
							<i className='fa-solid fa-user'></i>
						</div>
						<span>
							Occupied <span>0</span>
						</span>
					</div>
				</div>

				<div className='col'>
					<div className={active === 'Disabled' ? 'icon-box bg-grey bg-primary-bs' : 'icon-box bg-grey'} onClick={()=>setactive('Disabled')}>
						<div className='icon-wrap'>
							<i className='fa-solid fa-user'></i>
						</div>
						<span>
							Disabled <span>0</span>
						</span>
					</div>
				</div>
			</div> */}
			<hr className='w-100' />

			<div className='row mb-30 gap-10'>
				<div className='col-2'>
					<div
						className={active === 'all' ? 'icon-box bg-grey bg-primary-bs' : 'icon-box bg-grey'}
						onClick={() => setactive('all')}>
						<div className='icon-wrap'>
							<i className='fa-solid fa-user'></i>
						</div>
						<h3>
							All <span>{data?.vecant} / {data?.totalBays}</span>
						</h3>
					</div>
				</div>
				{dataArray.map((item: any, key: number) => {
					return (
						<Fragment key={key}>
							<div className='col-2'>
								<div
									className={active === item?.bTypeName ? 'icon-box bg-grey bg-primary-bs' : 'icon-box bg-grey'}
									onClick={() => setactive(item?.bTypeName)}>
									<div className='icon-wrap'>
										<i className='fa-solid fa-user'></i>
									</div>
									<h3 >
										{item?.bTypeName} <span>{item?.vecantCount}/{item?.totalBay}</span>
									</h3>
								</div>
							</div>
						</Fragment>
					);
				})}
			</div>

			<hr className='w-100' />
			<div className='w-100 dflex  gap-10 '>
				<div
					className='col-3 dflex col-dir align-items-center justify-space-between icon-box'
					style={{ border: '1px solid var(--lightgrey)', borderRadius: '5px' ,background:"white" }}>
					<div style={{ borderBottom: '1px solid var(--lightgrey)' }} className='p-10 w-100'>
						<h3 style={{ textTransform :"uppercase" ,fontSize:"12px"}} >Total Bays</h3>
					</div>
					<div className='p-10 w-100'>
						<h3 className='border-title'> {data?.totalBays}</h3>
					</div>
				</div>
				<div
					className='col-3 dflex col-dir align-items-center justify-space-between icon-box  '
					style={{ border: '1px solid var(--lightgrey)', borderRadius: '5px',background:"white" }}>
					<div style={{ borderBottom: '1px solid var(--lightgrey)' }} className='p-10 w-100'>
						<h3 style={{ textTransform :"uppercase" ,fontSize:"12px"}} >Occupied Bays</h3>
					</div>
					<div className='p-10 w-100'>
						<h3 className='border-title'> {data?.occupied}</h3>
					</div>
				</div>
				<div
					className='col-3 dflex col-dir align-items-center justify-space-between icon-box  '
					style={{ border: '1px solid var(--lightgrey)', borderRadius: '5px',background:"white" }}>
					<div style={{ borderBottom: '1px solid var(--lightgrey)' }} className='p-10 w-100'>
						<h3 style={{ textTransform :"uppercase" ,fontSize:"12px"}} >Vacant Bays</h3>
					</div>
					<div className='p-10 w-100'>
						<h3 className='border-title'> {data?.vecant}</h3>	
					</div>
				</div>

				<div
					className='col-3 dflex col-dir align-items-center justify-space-between icon-box '
					style={{ border: '1px solid var(--lightgrey)', borderRadius: '5px',background:"white" }}>
					<div style={{ borderBottom: '1px solid var(--lightgrey)' }} className='p-10 w-100'>
						<h3 style={{ textTransform :"uppercase" ,fontSize:"12px"}} >Reserved Bays</h3>
					</div>
					<div className='p-10 w-100'>
						<h3 className='border-title'> {data?.reserved}</h3>
					</div>
				</div>
				<div
					className='col-3 dflex col-dir align-items-center justify-space-between icon-box '
					style={{ border: '1px solid var(--lightgrey)', borderRadius: '5px',background:"white" }}>
					<div style={{ borderBottom: '1px solid var(--lightgrey)' }} className='p-10 w-100'>
						<h3 style={{ textTransform :"uppercase" ,fontSize:"12px"}} >Bays in Violation</h3>
					</div>
					<div className='p-10 w-100'>
						<h3 className='border-title'> {data?.inViolationBayCount}</h3>
					</div>
				</div>
			</div>
			{/* <img src="assets/images/common/floor-plan.jpg" /> */}
		</div>
	);
};

export default RealTimeGraph;
