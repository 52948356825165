import dayjs from 'dayjs';
import React from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { FormatDate } from '../../../../../utils/utilFunctions';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data: any;
};

const AddModal = (props: Props) => {
	let actionArr = [ 'Create' , 'Update', 'Delete' ]
	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title='Audit Log Viewer' subtitle='Logs' big>
				<div className="modal-body scrollNotVisible" style={{ maxHeight:'70vh' }}>
				<div className="table-details">
					<ul>
						<li>Module <span>{props.Data?.displayName}</span></li>
						<li>User ID <span>{props.Data?.createdBy}</span></li>
						<li>Identifier <span>{props.Data?.identifier}</span></li>
						<li>Date Time <span>{FormatDate(props.Data?.createdOn, 'DD-MMM-YYYY hh:mm:ss a')}</span></li>
					</ul>
					<div className="seperator"></div>
					<ul>
						<li>Action <span>{actionArr[parseInt(props.Data?.action)-1]}</span></li>
						<li>User Name <span>{props.Data?.createdUser}</span></li>
						<li>Primary Key Value <span>{props.Data?.primaryKeyValue}</span></li>
					</ul>
				</div>

				<table className="style-table minimalTableDiv">
					<thead>
						<tr>
							<th>Parameter</th>
							<th>Before Value</th>
							<th>After Value</th>
						</tr>
					</thead>
					<tbody>
						{
							props.Data?.logDetails && props.Data?.logDetails.map((item:any,k:number) => {
								return(
									<tr>
										<td>
											<label className="badge blue">{item.columnNew ? item.columnNew : item.columnOld}</label>
										</td>
										<td>
											<div style={{ overflowX:'auto', width:'20vw', maxHeight:'20vh' }}>
											{item.valueOld}
											</div>										
										</td>
										<td>
											<div style={{ overflowX:'auto', width:'20vw', maxHeight:'20vh' }}>
											{item.valueNew}	
											</div>									
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</div>

			<div className="modal-footer">
				<a onClick={props.handleClose} className="btn btn-outline-blue close-modal-button">Close</a>
			</div>
		</ModalLayout>
	);
}; 

export default AddModal;
