import React, { Fragment, useEffect, useState } from 'react';
import { fetch_floorLevel, fetch_site, fetch_zone, fetch_zoneDDL } from '../../general/action';
import { MenuItem } from '@mui/material';

import 'antd/dist/antd.min.css'
import { Input } from 'antd';
import { GridKeyboardArrowRight } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../config/hooks';

const { Search } = Input;

type Props = {
	isMulti?: boolean;
	grey?: boolean;
	selectedZones: any;
	setselectedZones: React.Dispatch<React.SetStateAction<any>>;
	onchangeFx?: any;
	showAll?: any;
	classnames?: string;
	getOnlyID?: boolean;
	fixSite?: any;
	fixLevel?: any;
	styleSx?: any;
};

type ddl = {
	label: string;
	value: any;
};
const TreeSelectCustom = ({
	isMulti = false,
	selectedZones,
	setselectedZones,
	onchangeFx = null,
	grey = false,
	showAll = null,
	classnames = '',
	fixSite = null,
	fixLevel = null,
	styleSx = null
}: Props) => {
	const dropdownRef = React.useRef(null);

	const [siteData, setsiteData] = useState<ddl[]>([]);
	const [levelData, setlevelData] = useState<ddl[]>([]);
	const [zoneData, setzoneData] = useState<ddl[]>([]);
	const [zoneDataList, setzoneDataList] = useState<ddl[]>([]);
	const [selectedSite, setselectedSite] = useState<any>(fixSite);
	const [selectedLevel, setselectedLevel] = useState<any>();
	const dispatch = useDispatch()

	const [dropDown, setdropDown] = useState<boolean>(false);
	const [siteErr, setSiteErr] = useState(false)
	const [levelErr, setLevelErr] = useState(false)
	const [zoneErr, setZoneErr] = useState(false)

	const ZONELISTING = useAppSelector((state) => state?.general)

	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			let targt: any = dropdownRef.current;
			if (dropDown && targt && !targt.contains(e.target)) {
				setdropDown(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [dropDown, setdropDown]);

	useEffect(() => {

		fetch_zoneDDL(dispatch)
		setzoneData([]);
		setzoneDataList([])

		fetch_site()?.then((res) => {
			setSiteErr(false)
			let data = res?.response ?? [];
			setsiteData(data);
			setselectedSite(fixSite ? fixSite : data[0]?.value);
			let siteId = fixSite ? fixSite : data[0]?.value;
			setzoneData([]);
			setzoneDataList([])
			if(fixSite){
				if(data && data?.find((el:any)=> el.value===fixSite)){
					fetch_floorLevel(fixSite ? fixSite : data[0]?.value)?.then((res) => {
						let data = res?.response ?? [];
						setlevelData(data);
						setzoneData([]);
						setselectedLevel(fixLevel ? fixLevel : data[0]?.value);
						if (showAll) {
							showAll({ site: siteId, level: fixLevel ? fixLevel : data[0]?.value });
						}
						fetch_zone(fixLevel ? fixLevel : data[0]?.value)?.then((res) => {
							let data = res?.response ?? [];
							setzoneData(data);
							setzoneDataList(data);
						});
					});
				}
			}else{
				fetch_floorLevel(fixSite ? fixSite : data[0]?.value)?.then((res) => {
					let data = res?.response ?? [];
					setlevelData(data);
					setzoneData([]);
					setselectedLevel(fixLevel ? fixLevel : data[0]?.value);
					if (showAll) {
						showAll({ site: siteId, level: fixLevel ? fixLevel : data[0]?.value });
					}
					fetch_zone(fixLevel ? fixLevel : data[0]?.value)?.then((res) => {
						let data = res?.response ?? [];
						setzoneData(data);
						setzoneDataList(data);
					});
				});
			}
			
			
		}).catch((err) => {
			setSiteErr(true)
		})
	}, []);

	const fetchLevelFx = (id: number) => {
		setselectedSite(id);
		fetch_floorLevel(id)?.then((res) => {
			setLevelErr(false)
			let data = res?.response ?? [];
			setlevelData(data);
			setzoneData([]);
			setzoneDataList([]);
		}).catch((err)=>{
			setLevelErr(true)
		})
	};
	const fetchZoneFx = (id: number) => {
		setselectedLevel(id);
		if (showAll) {
			showAll({ site: selectedSite, level: id });
		}
		fetch_zone(id)?.then((res) => {
			setZoneErr(false)
			let data = res?.response ?? [];
			setzoneData(data);
			setzoneDataList(data);
		}).catch((err) => {
			setZoneErr(true)
		})
	};

	const search_zones = (label: any) => {
		if (!label) {
			setzoneDataList(zoneData);
		} else {
			let matches = zoneData.filter((item) => {
				const regex = new RegExp(`${label}`, 'gi');
				return item.label.match(regex);
			});
			let final_list_data: any = [];
			if (matches.length === 0) return;
			matches.map((item_val, length) => {
				final_list_data.push(item_val);
				return null;
			});
			setzoneDataList(final_list_data);
		}
	};

	const removeFx = (id: number) => {
		setselectedZones((prev:any) => prev.filter((item:any) => item.value !== id));
	};

	return (
		<div className={`pos-rel ${classnames}`} ref={dropdownRef}>
			<div className={`NewTree-input-like-div${grey ? '-grey' : ''}`}  style={styleSx} onClick={() => setdropDown(true)}>
				{selectedZones && selectedZones?.length === 0 && <p className='NewTree-input-like-div-placeholder'>Select Zone{isMulti?'s':''}</p>}
				{isMulti ? (
					selectedZones.map((item:any, key:number) => {
						return (
							<div className={`NewTree-selected-item${grey ? '-grey' : ''}`}>
								<label>{ZONELISTING.ZONE_DDL?.find((el:any) => el.value===item.value)?.label}</label>
								<div className='NewTree-close-icon' onClick={() => removeFx(item?.value)}>
									<i className='fa-solid fa-xmark'></i>
								</div>
							</div>
						);
					})
				) : (
					<label style={{ fontSize: '14px' , }} >{selectedZones && selectedZones?.length !== 0 ? 
						selectedZones[0]?.label ? selectedZones[0]?.label==='' || selectedZones[0]?.label===null ?
							ZONELISTING.ZONE_DDL?.find((el:any) => el.value===selectedZones[0].value)?.label : selectedZones[0]?.label 
							: ZONELISTING.ZONE_DDL?.find((el:any) => el.value===selectedZones[0].value)?.label
						: ''}</label>
				)}
			</div>
			<div className={dropDown ? 'NewTree-dropdown-wrapper newTreeInsideDiv' : 'none'}>
				<div>
					{siteData && siteData?.length>0 &&
					siteData?.map((site, key: number) => {
						return (
							<MenuItem
								selected={selectedSite === site?.value}
								key={key}
								sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
								onClick={() =>{
									if(!fixSite)
										fetchLevelFx(site?.value)}}
								className={selectedSite === site?.value ? 'pointer pointer-active arrowParentDiv' : ''}>
								<label className={`label-dnd-menuitem ${fixSite ? parseInt(fixSite)===parseInt(site?.value) ? '' : 'disableAndOpacity2' : ''}`}>
									<span>{site?.label}</span>
								</label>
								{selectedSite === site?.value&&<div className='arrowShowsMore'><GridKeyboardArrowRight/></div>}
							</MenuItem>
						);
					})}
					{
						siteErr ? <p>Some Error Occurred</p> : null
					}
				</div>
				<div>
					{levelData && levelData?.length>0 && 
					levelData?.map((level, key: number) => {
						return (
							<MenuItem
								selected={selectedLevel === level?.value}
								key={key}
								sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
								className={selectedLevel === level?.value ? 'pointer pointer-active arrowParentDiv' : ''}
								onClick={() =>{
									if(!fixLevel)
										fetchZoneFx(level?.value)}}>
								<label className='label-dnd-menuitem'>
									<span>{level?.label}</span>
									{/* {selectedLevel === level?.value && <i className='fa-solid fa-arrow-right'></i>} */}
								</label>
								{selectedLevel === level?.value&&<div className='arrowShowsMore'><GridKeyboardArrowRight/></div>}
							</MenuItem>
						);
					})}
					{
						!(siteErr || zoneErr) && levelErr ? <p>Some Error Occurred</p> : null
					}
				</div>

				<div>
					<Search placeholder='search zones' onChange={(e) => search_zones(e.target?.value)} style={{ width: 200 }} />
					{
						zoneDataList && zoneDataList?.length>0 ?
						<div style={{ maxHeight: '200px', overflow: 'auto' }}>
						{zoneDataList?.map((zone, key: number) => {
							return (
								<Fragment key={key}>
									{isMulti ? (
										<MenuItem
											sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
											selected={selectedZones.some((item:any) => item?.value === Number(zone?.value))}>
											<label className='label-dnd-menuitem'>
												
												<input
													type='checkbox'
													checked={selectedZones.some((item:any) => item?.value === Number(zone?.value))}
													onClick={(e) => {
														if (!selectedZones.some((item:any) => item?.value === Number(zone?.value))) {
															setselectedZones((prev:any) => [...prev, { value : zone?.value}]);
															if (showAll) {
																showAll({ site: fixSite? fixSite : selectedSite, 
																	level: fixLevel ? fixLevel : selectedLevel });
															}
															
															if (onchangeFx) onchangeFx(zone,true);
														} else {
															
															setselectedZones((prev:any) =>
																prev.filter((item:any) => Number(item?.value) !== Number(zone?.value))
															);
															if (onchangeFx) onchangeFx(zone,false)
														}
													}}
													style={{ marginRight:'10%' }}
												/>
												<span>{zone?.label}</span>
											</label>
										</MenuItem>
									) : (
										<MenuItem
											sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}
											selected={selectedZones.some((item:any) => item?.value === Number(zone?.value))}>
											<label className='label-dnd-menuitem'>
												
												<input
													type='checkbox'
													checked={selectedZones.some((item:any) => item?.value === Number(zone?.value))}
													onClick={(e) => {
														if (!selectedZones.some((item:any) => item?.value === Number(zone?.value))) {
															setselectedZones([zone]);
															if (showAll) {
																showAll({ site: fixSite? fixSite : selectedSite, 
																	level: fixLevel ? fixLevel : selectedLevel });
															}
															
															if (onchangeFx) onchangeFx(zone);

														} else {
															setselectedZones((prev:any) =>
																prev.filter((item:any) => Number(item?.value) !== Number(zone?.value))
															);
															if (onchangeFx) onchangeFx(null)
														}
														setdropDown(false)
													}}
													style={{ marginRight:'10%' }}
												/>
												<span>{zone?.label}</span>
												
											</label>
										</MenuItem>
									)}
								</Fragment>
							);
						})}
						{
							!(siteErr || levelErr) && zoneErr ? <p>Some Error Occurred</p> : null
						}
					</div>
					:
					<div style={{ maxHeight: '200px', overflow: 'auto', textAlign:'center' }}>
						<p>No Zones</p>
					</div>
					}
					
				</div>
			</div>
		</div>
	);
};

export default TreeSelectCustom;
