import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { ZoneColors } from '../../../utils/zoneColorCodes';

type Iprops = {
	data: any;
	func?: any;
	COLORS: any;
};

export default class PieChartIndex extends PureComponent<Iprops> {
	render() {
		const { data, func, COLORS } = this.props;
		const values = data ? data.map((item: any) => item.value) : [];
		const sum = values.reduce((x: any, y: any) => x + y, 0);
		console.log('COL=>',COLORS,this.props)
		return (
			<ResponsiveContainer width='100%' height='100%'>

				
				<PieChart>
					{sum !== 0 && <Tooltip />}

					<Pie
						style={{ border: '1px solid blue' }}
						data={data}
						innerRadius={80}
						outerRadius={120}
						fill='#8884d8'
						onClick={func}
						dataKey='value'>
						{data.map((entry: any, index: number) => {
							console.log('PIECHART=>',entry,ZoneColors(entry?.name))
							return(
							<Cell key={`cell-${index}`} 
								fill={ZoneColors(entry?.name)??COLORS[index % COLORS.length]} />
						)})}
					</Pie>

					{/* {sum === 0 && (
						<>
							<Pie data={[{ name: 'NO DATA', value: 1 }]} fill='#eeeeee' dataKey='value' />
						</>
					)} */}
				</PieChart>
			</ResponsiveContainer>
		);
	}
}
