import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { fetch_parkingRequestCount } from '../store/action'

type Props = {}

const DashIcons = (props: Props) => {
    const dispatch = useAppDispatch();

    useEffect(() => {

        let postdata = {
            'query' : ""
        }
      fetch_parkingRequestCount(dispatch, postdata)
    }, [])
    
	const PARKINGDATA = useAppSelector((state) => state?.parkingrequest);

  return (
            <div className="w-100 mb-20">
                <div className="row mts-20">
                    <div className="col-4">
                        <div className="icon-box white-bg">
                            <div className="icon-wrap yellow">
                                <i className="fa-solid fa-car"></i>
                            </div>
                            <h3>Pending Request  <span className='text-darkyellow' style={{ fontWeight:'700' }}>{PARKINGDATA?.PARKING_REQUESTS_COUNT?.pending}</span></h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="icon-box white-bg">
                            <div className="icon-wrap green">
                                <i className="fa-solid fa-car"></i>
                            </div>
                            <h3>Approved Request <span className="text-green" style={{ fontWeight:'700' }}>{PARKINGDATA?.PARKING_REQUESTS_COUNT?.approved}</span></h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="icon-box white-bg">
                            <div className="icon-wrap red">
                                <i className="fa-solid fa-car"></i>
                            </div>
                            <h3>Rejected Request <span className="text-red" style={{ fontWeight:'700' }}>{PARKINGDATA?.PARKING_REQUESTS_COUNT?.rejected}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default DashIcons