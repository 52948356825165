/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import loginBg from '../../assets/images/login/login-bg.jpg';
import logo from '../../assets/images/login/logo.jpg';
import { fetch_forgot, fetch_login } from './store/action';
import ButtonLoader from './../../common/components/ButtonLoader';
// import { loginRequest } from '../../config/sso_auth';
import useKeyboard from '../../utils/hooks/useKeyboard';
// import StatusChipComponent from '../../common/components/statusChipComponent';
import { APPLICATION_SETTINGS_GPK } from '../../config/endpoint';
import { FailedToast, SuccesToast, WarnToast } from '../../utils/utilFunctions';
import { fetch_product_key, update_license_file } from '../application_settings/store/action';
import { Alert } from 'antd';
import auth from '../../config/auth';
import { globalConfig } from './../../../config/config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
// import { GenerateToken } from '../../authConfig';

const LoginIndex = ({redirectFX ,SSOAuthorizerFx}:any) => {
	let navigate = useNavigate();
	let dispatch = useDispatch();


	const isAuthenticated = useIsAuthenticated()

	console.log('ISAUH',isAuthenticated)

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [forgot, setForgot] = useState(false);
	const [loader, setloader] = useState(false);
	// const [loaderSSO, setloaderSSO] = useState(false);
	const [forgotSuc, setForgotSuc] = useState(false);
	const [showPass, setShowPass] = useState(false)

	const [expiredLic, setExpiredLic] = useState(false)
	const [prodKDown, setProdKDown] = useState(false)
	const [file, setFile] = useState(null)
	const [uploadSuc, setUploadSuc] = useState(false)

	const [err, setErr] = useState<any>(null);

	const { instance } = useMsal();

	const loginRequest = {
		scopes: ["User.Read"]
	};

	const handleLogin = () => {
		console.log("I RUN")
		instance.loginRedirect(loginRequest).catch((e:any) => {
			console.log(e);
		});
	}

	const loginAttempt = () => {
		setloader(true);
		let postdata = {
			'username': username,
			'password': password,
		};
		const Failed = (err: any) => {
			console.log('Error', err);
		if (err?.response?.status === 402) {
				setExpiredLic(true)
			} else {
				setExpiredLic(false)
			}
			setErr(err?.response?.devData);
			setloader(false);
		};
		const suc = (response:any) => {

			console.log("response",response)
			setloader(false);
			setErr(null)
			// setredirect(true)
			redirectFX()
			auth.login(response?.response?.accessToken, response?.response?.refreshToken, ()=>navigate('/',{ replace: true }));
		};
		fetch_login(navigate, dispatch, postdata, Failed, suc);
	};



	const updateNewLicense = (v: any) => {
		let d = new FormData();
		d.append('File', v);
		setUploadSuc(false)
		const Success = (res: any) => {
			setloader(false);
			setUploadSuc(true)
			setErr(null)
			SuccesToast(res?.response?.data);

		};
		const Failed = (err: any) => {
			FailedToast(err?.response);
			setloader(false);
		};
		update_license_file(d, Success, Failed);
	}

	const forgotLogin = () => {
		setloader(true);

		const Success = (res: any) => {
			setEmail('');
			setForgotSuc(true);
			SuccesToast(res?.response?.data)
			setErr(null)
			setloader(false);
		};
		const Failed = (err: any) => {
			console.log(err);
			setloader(false);
			FailedToast(err?.response)
			setErr(err?.response?.devData);
		};
		let postdata = {
			'EmailID': email,
		};
		fetch_forgot(navigate, postdata, Success, Failed);
	};

	const getFile = () => {
		fetch_product_key().then((res) => {
			if (res?.statusCode === 200) {
				const url = window.URL.createObjectURL(new Blob([res?.response]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', "pms-product-key.lic");
				// link.download = 'pms-product-key';
				// document.body.appendChild(link);
				link.click();
				setProdKDown(true)
			} else {
				FailedToast(err?.response)
			}

		})

		// FileDownloader
	}



	useKeyboard('Enter', loginAttempt);


	

	// const { accounts, inProgress } = useMsal();

	//console.log("accounts -login", accounts)


	return (
		<>

			{/* {
			auth.get_ot() === "true" && 
			<Alert
      message="SSO AUTHENTICATION"
      description="would you like to try sso authentication again?"
      type="info"
	  style={{ position:"fixed" , top:"0px" , gap:"10px" , zIndex:1000}}
      action={
     
		  <div className="btn btn-small btn-primary" onClick={()=>{
			// window.location.href =  globalConfig?.get()?.AUTH_URL;

			handleLogin()
		  }}>
				Try SSO 
		  </div>
		
       
      }
      closable
    />
		} */}


			<section className='full-height login-section' style={{ height: '100vh' }}>
				<div className='login-background'>
					<img src={loginBg} alt='' loading='eager' />
				</div>

				<div className='login-form'>
					<div className='login-row'>
						<div className='form-header'>
							<img src={logo} alt='logo' title='Smartbays Parking Solution' loading='eager' />
						</div>

						{forgot ?
							forgotSuc ?
								<div className='form-body'>
									<p style={{ fontSize: '1rem', color: 'var(--darkblue)', fontWeight: '500' }}>
										Reset your password using the link sent in your email. {' '}
									</p>
								</div>
								:
								<div className='form-body'>
									<p style={{ fontSize: '.8rem', color: 'var(--darkblue)', fontWeight: '500' }}>
										A link to reset your password will be sent to your valid email.{' '}
									</p>
								</div>
							: null
						}
						{forgotSuc ? null : forgot ? (
							<div className='form-body'>
								<div className='form-group'>
									<label htmlFor='email'>Email</label>
									<input
										type='email'
										className='form-control'
										id='email'
										placeholder='name@example.com'
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								{err && <p className='hookform-error-message'>{err}</p>}
								<div className='form-group'>
									<button
										className='btn btn-primary'
										onClick={() => {
											forgotLogin();
										}}>
										<ButtonLoader text='Forgot Password' loading={loader} />
									</button>
								</div>
							</div>
						) : (
							<div className='form-body'>
								{/* <form> */}
								<div className='form-group'>
									<label htmlFor='email'>Email</label>
									<input
										type='email'
										className='form-control'
										id='email'
										placeholder='name@example.com'
										required
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>
								</div>

								<div className='form-group'>
									<label className='passwordLabel' htmlFor='password'>
										<p>Password</p>
										<i>
											{
												!showPass ?
													<i className="fa fa-eye" aria-hidden="true" onClick={() => setShowPass(true)}></i>
													:
													<i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass(false)}></i>
											}
										</i>
									</label>
									<div className='pos-rel'>
										<input
											type={showPass ? 'text' : 'password'}
											className='form-control'
											id='password'
											placeholder='*********'
											required
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<a className='forgot-pass' onClick={() => { setErr(''); setForgot(true); }}>
											Forgot Password?
										</a>

								{ 
									expiredLic ? 
										prodKDown ?
											uploadSuc ?
												<p className='hookform-info-message'>License Activated</p>
											:
												<>
												<div className='hiddenFileInsertOption'>
													<input type="file" name="" id="" 
														onChange={(e: any) => {
															let file = e.target.files[0]

															if (file.name?.endsWith('.lic')) {
																setFile(file)
																updateNewLicense(file)
															}
															else { WarnToast("Not a valid license file.") }
													
														}}
													/>
												</div>
												<p  className='forgot-pass forgot-pass-license' style={{ zIndex:0 }}>Upload New Product-Key</p>	
												</>							
										:
											<div onClick={()=>{
												getFile()
											 }}>
												<p  className='forgot-pass forgot-pass-license' >Download Product Key</p>
											</div>
									: null
								}

									</div>
								</div>
								{err && <p className='hookform-error-message'>{err}</p>}
								
								<div className={`form-group ${username!=='' && password!=='' ? '' : 'disableAndOpacity2'}`}>
									<button
										className='btn btn-primary'
										disabled={loader}
										onClick={() => {
											loginAttempt();
										}}>
										<ButtonLoader text='Login' loading={loader} />
									</button>
								</div>
								<div className='form-group'>
									<div
										className='btn btn-primary'
										onClick={() => {

											if(isAuthenticated){
												SSOAuthorizerFx()
											}else{
												handleLogin();
												
											}
										}}>
										Sign in using SSO
									</div>
								</div>
								{/* <div>
									<button onClick={()=>GenerateJwt()}>
										AES
									</button>
								</div> */}
								{/* <div className='form-group'>
									<button
										className='btn btn-primary'
										onClick={() => {
											loginSSOAttempt();
										}}>
										<ButtonLoader text='Login with SSO' loading={loaderSSO} />
									</button>
								</div> */}
								{/* </form> */}
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default LoginIndex;
