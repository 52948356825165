import React, { Fragment, useCallback, useRef } from 'react';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_notification_history } from '../../../store/action';

type Props = {};

const ListTable = (props: Props) => {
	const dispatch = useAppDispatch();
	const NOTIFICATION_DATA = useAppSelector((state) => state?.notifications);

	const fetchIdRef = useRef(0);

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'heQueuID', orderByKey:'heQueuID' },
			{ Header: 'Send Date', accessor: 'sendDate', DateFormat: true, orderByKey: 'sendDate' },
			{ Header: 'Subject', accessor: 'eSubject', orderByKey: 'eSubject' },
			{ Header: 'Send To', accessor: 'emailTo', orderByKey:'emailTo' },
			{ Header: 'Notification Type', accessor: 'templateSubject', orderByKey: 'subject' },

			{
				Header: 'status ', accessor: 'requestStatus', Cell: ({ row }: any) => {

					return (
						<Fragment>

							<label className={
								`${row?.original?.sendStatus === 1 ? "badge green"
									: row?.original?.sendStatus === 2 ? "badge dark-blue"
										: row?.original?.sendStatus === 0 ? "badge red" :
											"badge"} `}>
								{
									row?.original?.sendStatus === 1 ? "Success"
										: row?.original?.sendStatus === 2 ? "Pending"
											: row?.original?.sendStatus === 0 ? "Failed" : null
								}
							</label>
						</Fragment>
					);
				},
			},
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				show: true,
			},
		],
		[]
	);
	// {
	// "heQueuID": 1,
	//     "eQueuID": 1,
	//     "fkEmailTemplateID": 1,
	// "emailTo": "twetywe@gmail.com,jhkjhdsa@gmail.com",
	// "eSubject": "welcom letter",
	//     "emailBody": "<text new >?",
	// "sendStatus": 1,
	//     "retrayCount": 0,
	//     "sendPriority": 1,
	// "sendDate": "2022-09-29T09:02:23",
	//     "fK_UserUniqueID": 1,
	//     "iDentifyID": 1,
	//     "hMovedDate": "2022-10-12T18:51:01.453",
	//     "templateSubject": "Welcome to ADNOC OIL",
	//     "createdOn": "2022-10-12T18:51:01.453",
	//     "createdBy": "1",
	//     "updatedOn": null,
	//     "updatedBy": null,
	//     "addedBy": "Muneer E . K",
	//     "updatedUser": null
	// }


	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		filters: {query: string;};
		OrderBy :string

	};

	const fetchData = useCallback(({ pageSize, pageIndex, signal, OrderBy  ,filters }: IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_notification_history(
				dispatch,
				{
					'filters': {
						'query':filters.query || '',
						OrderBy 
					},
					'pagination': {
						'pageNo': pageIndex,
						'limit': pageSize,
					},
				},
				signal
			);
		}
	}, [dispatch]);

	return (
		<>
			<CommonTable
				columns={columns}
				data={NOTIFICATION_DATA?.NOTIFICATION_HISTORY_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={NOTIFICATION_DATA?.NOTIFICATION_HISTORY_PAGINATION?.totalCount ?? 0}
				loading={NOTIFICATION_DATA?.NOTIFICATION_HISTORY_LOADER}
				page_no={NOTIFICATION_DATA?.NOTIFICATION_HISTORY_PAGINATION?.pageNo ?? 1}
				limit={NOTIFICATION_DATA?.NOTIFICATION_HISTORY_PAGINATION?.limit ?? 1}
				filters={NOTIFICATION_DATA?.NOTIFICATION_HISTORY_FILTER}
			/>
		</>
	);
};

export default ListTable;
