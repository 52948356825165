import React from 'react'
import MainLayout from '../../../../common/layouts/MainLayout';
import FilterBar from './components/FilterBar';
import ListTable from './components/ListTable';

type Props = {}

const NotificationException = (props: Props) => {
	return (
		<MainLayout>
			<FilterBar />
			<ListTable />
		</MainLayout>
	)
}

export default NotificationException