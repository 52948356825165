import React from 'react';
import LinkTab from '../../common/components/LinkTab';
// import NavTabs from '../../common/components/NavTabs';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import DefinitionCommon from './screens/definitionCommon';
import './definitionStyles.css';
import { definition_routes } from './config/definitionRoutes';
import UserType from './screens/components/UserType/UserType';
import Zone from './screens/components/Zone/Zone';
import ParkingBay from './screens/components/PakingBay/Bay';
import BayType from './screens/components/BayType/BayType';
import SiteMaster from './screens/components/SiteMaster/SiteMaster';
import GroupCompany from './screens/components/GroupCompany/GroupCompany';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useAppDispatch } from './../../config/hooks';
import { RESET_DEFINITION_FILTERS_ONLY, RESET_STATE } from './store/definitionSlice';
import { globalConfig } from '../../../config/config';
import { Box } from '@mui/material';
import PlaceOfReg from './screens/components/PlaceOfReg/PlaceOfReg';
import RejectionReason from './screens/components/RejectionReason';
import ViolationReason from './screens/components/ViolationReason';
import FloorLevel from './screens/components/FloorLevel';
import Department from './screens/components/Department';
import Designation from './screens/components/Designation';

const DefinitionIndex = () => {
	// let location = useLocation();
	let dispatch = useAppDispatch();
	let debug_val = false
	if (globalConfig?.get()?.DEBG)
		debug_val=true
	else 
		debug_val=false

	React.useEffect(() => {
			dispatch(RESET_STATE());
			

	}, [dispatch]);

	return (
		<PermissionWrapper subModuleID={subModuleList['Definition']}>
			<div className='navtabs__container'>
			<Box
				onClick={()=>{
					dispatch(RESET_DEFINITION_FILTERS_ONLY())
				}}
				sx={{
					width: '100%',
					borderBottom: 1,
					borderColor: 'divider',
					marginBottom: '30px',
					display: 'flex',
					overflowX: 'auto',
					overflowY: 'hidden',
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': { display: 'none' },
				}}
			>
				{definition_routes.map((item, key) => {
					if(debug_val===false && item?.path==='vehicleinout')
						return null 
					else 
						return <LinkTab label={item.title} href={item.path} key={key} />;
				})}
				{/* <LinkTab label='Zone' href='zone' />
				<LinkTab label='Parking Bay' href='parkingbay' />
				<LinkTab label='Bay Type' href='baytype' /> */}
				{/* <LinkTab label='User Type' href='user-type' /> */}
			</Box>
			<Routes>
				<Route
					path={definition_routes[0]?.path}
					element={
						<RejectionReason
							label={definition_routes[0]?.title}
							path={definition_routes[0]?.path}
							defId={definition_routes[0]?.defId}
							defname={definition_routes[0]?.defName}
							modalType={definition_routes[0]?.modalType}
						/>
					}
				/>
				<Route
					path={definition_routes[1]?.path}
					element={
						<ViolationReason
							label={definition_routes[1]?.title}
							path={definition_routes[1]?.path}
							defId={definition_routes[1]?.defId}
							defname={definition_routes[1]?.defName}
							modalType={definition_routes[1]?.modalType}
						/>
					}
				/>
				<Route
					path={definition_routes[3]?.path}
					element={
						<FloorLevel
							label={definition_routes[3]?.title}
							path={definition_routes[3]?.path}
							defId={definition_routes[3]?.defId}
							defname={definition_routes[3]?.defName}
							modalType={definition_routes[3]?.modalType}
						/>
					}
				/>
				<Route
					path={definition_routes[7]?.path}
					element={
						<Department
							label={definition_routes[7]?.title}
							path={definition_routes[7]?.path}
							defId={definition_routes[7]?.defId}
							defname={definition_routes[7]?.defName}
							modalType={definition_routes[7]?.modalType}
						/>
					}
				/>
				<Route
					path={definition_routes[8]?.path}
					element={
						<Designation
							label={definition_routes[8]?.title}
							path={definition_routes[8]?.path}
							defId={definition_routes[8]?.defId}
							defname={definition_routes[8]?.defName}
							modalType={definition_routes[8]?.modalType}
						/>
					}
				/>
				<Route path={'site'} element={<SiteMaster />} />
				<Route path={'zone'} element={<Zone />} />
				<Route path={'parkingbay'} element={<ParkingBay />} />
				<Route path={'baytype'} element={<BayType />} />
				<Route path={'groupcompany'} element={<GroupCompany />} />
				<Route path={'place-of-reg'} element={<PlaceOfReg/>} />
				{
					debug_val &&
					<Route path={'vehicleinout'} element={<UserType />} />
				}
				
				{/* <Route path={'user-type'} element={<UserType />} /> */}
				<Route path={'/*'} element={<Navigate to={definition_routes[0].path} />} />
			</Routes>
		</div>
		</PermissionWrapper>
		
	);
};

export default DefinitionIndex;
