/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBox from '../../../../../common/components/checkBox';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { fetch_parking_policy } from '../../../store/actions';
import ViewModal from './ViewModal';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';

type Props = {};

const ListTable = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const fetchIdRef = useRef(0);
	const ViewModalClose = () => setViewModal(false);
	const [viewModal, setViewModal] = useState(false);
	const [detail, setDetail] = useState(null);

	const PARKING_POLICY_DATA = useAppSelector((state) => state?.policy);

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'policyID', orderByKey: 'policyID' },
			{ Header: 'POLICY NAME', accessor: 'policyName', orderByKey: 'policyName' },
			{ Header: 'CREATED ON', accessor: 'createdOn', DateFormat: true, FormatType: 'DD-MMM-YYYY hh:mm A', orderByKey: 'createdOn' },
			{ Header: 'Number OF ASSIGNED ZONES', accessor: 'zoneCount', 
				orderByKey: 'zoneCount',
			Cell: ({ row }: any) => {
				return <div style={{ textAlign:'center', width:'100%' }}>{row?.original?.isSystem ? '-' : row?.original?.zoneCount}</div>
			} },
			{
				Header: 'DEFAULT',
				accessor: 'isDefault',
				Cell: ({ row }: any) => {
					if(row?.original?.isSystem)
						return <StatusChipComponent chipType='pending' text='System Default' />
					else 
						return null
					
				},
			},
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					if(!row?.original?.isSystem)
					return (
						<Fragment>
							<div className='button-group'>
								<PermissionWrapper subModuleID={subModuleList['Policy Assignment']} type='isDelete'>
									<a onClick={() => handleOpen(row?.original)} className='btn btn-sqaure btn-white modal-button'>
										<i className='fa-solid fa-eye'></i>
									</a>
								</PermissionWrapper>
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string };
	};

	const handleOpen = (data: any) => {
		setDetail(data);
		setViewModal(true);
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, filters, OrderBy }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parking_policy(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	return (
		<>
			<CommonTable
				data={PARKING_POLICY_DATA?.PARKING_POLICY_LIST ?? []}
				columns={columns}
				pageCount={PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.totalCount ?? 0}
				fetchData={fetchData}
				limit={PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.limit ?? 1}
				page_no={PARKING_POLICY_DATA?.PARKING_POLICY_PAGINATION?.pageNo ?? 1}
				filters={PARKING_POLICY_DATA?.PARKING_POLICY_FILTER}
				adjustSpacing={true}

			/>
			{viewModal ? <ViewModal open={viewModal} handleClose={ViewModalClose} Data={detail} /> : null}
		</>
		// <div className='reponsive-table'>
		// 	<table className='style-table mobile-responsive'>
		// 		<thead>
		// 			<tr>
		// 				<th>ID</th>
		// 				<th>Site Name</th>
		// 				<th>Zone Name</th>
		// 				<th>Policy Name</th>
		// 				<th>Start Date</th>
		// 				<th>End Date</th>
		// 			</tr>
		// 		</thead>
		// 		<tbody>
		// 			<tr>
		// 				<td data-label='ID'>PID12</td>
		// 				<td data-label='Site Name'>A1</td>
		// 				<td data-label='Zone Name'>Z1</td>
		// 				<td data-label='Policy Name'>Smoking</td>
		// 				<td data-label='Start Date'>10-01-2022</td>
		// 				<td data-label='End Date'>10-01-2023</td>
		// 			</tr>
		// 		</tbody>
		// 	</table>
		// 	<PageCount count={10} />
		// </div>
	);
};

export default ListTable;
