import React, { useEffect, useState } from 'react';
import MainLayout from '../../../../common/layouts/MainLayout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { password_policy } from '../../store/action';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../config/subModuleList';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import FormInput from './../../../../common/components/FormInput';

const schema = yup.object().shape({
	minimum: yup.number().required(),
	oneUpperCase: yup.boolean(),
	oneLowerCase: yup.boolean(),
	oneNumber: yup.boolean(),
	oneSymbol: yup.boolean(),
	expireDays: yup.number(),
	history: yup.boolean(),
});

const PasswordPolicy = () => {

	const {
		register,
		handleSubmit,
		formState,
		reset, clearErrors,
		control
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const [pwdId, setPwdId] = useState(1)
	const [loading, setloading] = useState(false)


	useEffect(() => {
		const getDefaultVal = (res: any) => {
			let data = res?.response?.data[0]
			setPwdId(data.pwdPolicyID)
			clearErrors()
			reset({
				minimum: data.minimumPWdLength,
				oneUpperCase: data.requirdUppercaseLetter,
				oneLowerCase: data.requiredLowercaseLetter,
				oneNumber: data.requeirdOneNumber,
				oneSymbol: data.requiredSymbolCharacter,
				expireDays: data.passwordExpiryDays ? data.passwordExpiryDays : 0,
				history: data.enforcePwdHistory,
			})
		}
		password_policy('get', null, getDefaultVal, () => { })
	}, [])

	//console.log(formState?.errors);
	//console.log(formState?.isValid);


	const onSubmitHandler = (e: any) => {
		// e.preventDefault()
		setloading(true)

		//console.log(e, 'sagdhajsgdhu');

		let postData = {
			pwdPolicyID: pwdId,
			minimumPWdLength: e.minimum,
			requirdUppercaseLetter: e.oneUpperCase,
			requiredLowercaseLetter: e.oneLowerCase,
			requiredSymbolCharacter: e.oneSymbol,
			passwordExpiryDays: e.expireDays === '' || e.expireDays === 0 ? null : e.expireDays,
			enforcePwdHistory: e.history,
			requeirdOneNumber: e.oneNumber
		}
		const Success = (res: any) => {
			SuccesToast(res?.data)
			setloading(false)

		}
		const Failed = (err: any) => {
			FailedToast(err?.response)
			setloading(false)

		}

		password_policy('put', postData, Success, Failed)
		//console.log(data);
		// reset();
	};

	return (
		<MainLayout>
			<div className='heading'>
				<h2 className='heading-main'>Password Policy</h2>
				<p>
					A password policy is a set of rules designed to increase the security of your  account by encouraging users to create and
					use strong passwords.
				</p>
				<p>
					The existing password policy can be modified below. This password policy applies to all people who are registered in this
					account. Minimum password length is 18{' '}
				</p>
			</div>

			<div className='row'>
				<div className='col-12'>
					<form onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='form-group inline-input'>
							<label>Minimum password length</label>
							{/* <input type='text' className='form-control grey-input' {...register('minimum')} /> */}
							<FormInput type='text' name="minimum" className='form-control grey-input' control={control}
								onChange={(e, formOnChange) => {
									let v = e.target.value.replace(/[^0-9+]/g, '');
									formOnChange(v)
								}} />

							<div className='col-6'></div>
						</div>

						<div className='form-group inline-input'>
							<label></label>
							<div className='checkbox-group'>
								<div className='checkbox'>
									<input type='checkbox' {...register('oneUpperCase')} />
									<label>
										<span>Require at least one uppercase letter</span>
									</label>
									<p></p>
								</div>

								<div className='checkbox'>
									<input type='checkbox' {...register('oneLowerCase')} />
									<label>
										<span>Require at least one lowercase letter</span>
									</label>
								</div>

								<div className='checkbox'>
									<input type='checkbox' {...register('oneNumber')} />
									<label>
										<span>Require at least one number</span>
									</label>

								</div>

								<div className='checkbox'>
									<input type='checkbox' {...register('oneSymbol')} />
									<label>
										<span>Require at least one Symbol Character</span>
									</label>
								</div>
							</div>
							<div className='col-6'></div>
						</div>

						<div className='form-group inline-input'>
							<label>Password expires in</label>
							{/* <input type='text' className='form-control grey-input' {...register('expireDays')} /> */}
							<FormInput type='text' name="expireDays" className='form-control grey-input' control={control}
								onChange={(e, formOnChange) => {
									let v = e.target.value.replace(/[^0-9+]/g, '');
									formOnChange(v)
								}} />
							<div className='col-6'>Days</div>
						</div>

						<div className='form-group inline-input'>
							<label>Enforce password history</label>
							<label className="switch">
								<input type="checkbox"  {...register('history')} />
								<span className="slider"></span>
								<span className="enable-text">Enable</span>
							</label>
						</div>

						<hr />

						<div className='text-right'>

							<PermissionWrapper subModuleID={subModuleList['Password Policy']} type='isEdit' >
								<button type='submit' className='btn btn-secondary' disabled={loading}>
									<ButtonLoader text="Update" loading={loading} />

								</button>
							</PermissionWrapper>
						</div>
					</form>
				</div>
			</div>
		</MainLayout>
	);
};

export default PasswordPolicy;
