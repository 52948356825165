// const jwt = require('jsonwebtoken');

var CryptoJS = require('crypto-js');

export function GenerateToken(email?: string) {
	let key = CryptoJS.enc.Base64.parse('bgUB6DOzdC8PhQiD32TiuP3J/FCShOKFBRzBzzvj6xs=' ); 
	let iv = CryptoJS.enc.Base64.parse('GomHK1qZyc3XIXsZMHK2wg=='); 
	var ciphertext = CryptoJS.AES.encrypt(email, key, {
		iv: iv,
	});
	try {
		return CryptoJS.enc.Base64.stringify(ciphertext.ciphertext);
	} catch {
		return null;
	}
}
