/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import uploadIcon from '../../../../assets/images/common/upload-icon.svg';
import { FailedToast, SuccesToast, WarnToast } from '../../../../utils/utilFunctions';
import { fetch_license, fetch_product_key, update_license_file } from '../../store/action';
import ButtonLoader from './../../../../common/components/ButtonLoader';
// import { APPLICATION_SETTINGS_GPK } from './../../../../config/endpoint';

type Props = {};

const License = (props: Props) => {
	const [data, setdata] = useState<any>();
	const [loader, setloader] = useState(false);

	const [file, setFile] = useState<any>(null);

	useEffect(() => {
		fetch_license().then((res) => {
			setdata(res?.response?.data);
		});
	}, []);

	const upload = () => {
		setloader(true);

		let d = new FormData();
		d.append('File', file);

		const Success = (res: any) => {
			fetch_license().then((ress) => {
				setdata(ress?.response?.data);
			});
			setloader(false);

			SuccesToast(res?.response?.data);

		};
		const Failed = (err: any) => {
			FailedToast(err?.response?.devData ? 
				err?.response?.devData=="Something Went Wrong!" ? 
					{ 'appData' : 'Liense file is invalid.', 'devData' : 'Error In License File!' }
					:
					err?.response 
				: 
				{ 'appData' : 'Liense file is invalid.', 'devData' : 'Unsupported file format detected.' });
			setloader(false);
		};
		update_license_file(d, Success, Failed);
	};

	const getFile = () => {
		fetch_product_key().then((res) => {
			const url = window.URL.createObjectURL(new Blob([res?.response]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', "pms-product-key.lic");
			// link.download = 'pms-product-key';
			// document.body.appendChild(link);
			link.click();
		})

		// FileDownloader
	}


	return (
		<div className='main-body-content license'>
			<div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main'>License</h2>
					<p>Please request the license by sending email to support@googerit.com and upload by clicking on below button.</p>
				</div>
				<div className='row'>
					<div id='userinfotab' className='col-6 plr-15'>
						<div className='form-group file-upload'>
							<input className='form-control' type='file' id='formFileMultiple' accept='.lic'
								onChange={(e: any) => {
									let file = e.target.files[0]
									//console.log("File", file)

									if (file.name?.endsWith('.lic')) {
										setFile(file)
									}
									else { WarnToast("Not a valid license file.") }

								}}
							/>
							{
								file ? <>
									{/* <img src={uploadIcon} alt='' className='upload-icon' /> */}
									<i className="fa-solid fa-file-circle-check" style={{ fontSize :'2.5rem'}}></i>
									<label htmlFor='formFileMultiple' className='form-label mt-10'>
										{
											file?.name
										}
									</label>
								</> : <>
									<img src={uploadIcon} alt='' className='upload-icon' />
									<label htmlFor='formFileMultiple' className='form-label'>
										Choose the license file (.lic) Or Drag it here.
									</label>
								</>
							}

						</div>
					</div>
					<div className='col-6 plr-15'>
						<div className='license row light-gray'>
							<div className='license-text col-6'>
								<p>License Status</p>
							</div>
							<div className='col-6'>
								{data?.isActive ? <p className='badge green'>Active</p> : <p className='badge red'>Expired</p>}
							</div>
						</div>
						<div className='license row light-gray'>
							<div className='license-text col-6'>
								<p>License Issued On</p>
							</div>
							<div className='col-6 license-date'>
								<p>{dayjs(data?.createdOn).format('YYYY MMM DD hh:mm:a')}</p>
							</div>
						</div>
						<div className='license row light-gray'>
							<div className='license-text col-6'>
								<p>License Activated On</p>
							</div>
							<div className='col-6 license-date'>
								<p>{dayjs(data?.fromDate).format('YYYY MMM DD hh:mm:a')}</p>
							</div>
						</div>
						<div className='license row light-gray'>
							<div className='license-text col-6'>
								<p>License Expiry On</p>
							</div>
							<div className='col-6 license-date'>
								<p>{dayjs(data?.toDate).format('YYYY MMM DD hh:mm:a')}</p>
							</div>
						</div>
						{/* <div className='client-id col-12'>
							<p>
								<span>Client ID :</span> adbf5a778175ee757c34d0eba4e932bc
							</p>
						</div>   */}
					</div>
				</div>
				<hr />

				<div className='text-right'>

					<a className='btn btn-secondary' onClick={getFile}>
						Download Product Key
					</a>

					<button className={`btn btn-secondary ${!file?.name?.endsWith('.lic')?'disableAndOpacity':''}`} 
						disabled={!file?.name?.endsWith('.lic')} onClick={upload}>
						<ButtonLoader text='Upload License' loading={loader} />

					</button>
				</div>
			</div>
		</div>
	);
};

export default License;
