import { Fragment, useEffect, useRef, useState } from 'react';
import ProgressBar from '../../../../common/components/graph_components/ProgressBar';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_parkedMoreThanDay_by_zone } from '../../store/action';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import { DatePicker } from 'antd';
import moment from 'moment';
import { COLOR_FX } from '../../../../utils/Data';
import { ZoneColors } from '../../../../utils/zoneColorCodes';

const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

const ParkedMoreThanDayZone = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);
	const [loading, setloading] = useState(false);
	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
		};

			setloading(true);
			fetch_parkedMoreThanDay_by_zone(postData, signal)?.then((res:any) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];

					let totalCount = data?.reduce(function(a:any, c:any) { return a + c?.parkingCount}, 0) ?? 0
					let newObjData:any = data?.map((el:any) =>{ 
						let valuePerc:any = parseInt(el?.parkingCount)/parseInt(totalCount)
						return({ ...el, 'totalCount' : totalCount, 
							'valuePerc' : parseFloat(valuePerc)*100 })}) ?? []

					setdata(newObjData);
					setTimeout(() => {
						setloading(false);
					}, 1500);
				}
			});
		
		return () => {
			controller.abort();
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, date]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	const checkIfReset = () =>{
		return ((date ? moment(date[1]).diff(date[0],'days') !==7 : false)) ? true : false 
	}

	const resetFilter = () => {
		setdate([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')])
		handleClose()
	 }

	return (
		<div className='ItemLayout__Wrapper'>
			<div className='ItemLayout__Title'>
				<div>Parked more than 24 Hours in each Zones </div>
				<div>
					
					<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose} clearAll={checkIfReset() ? resetFilter : null}>
						
						<div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Date Range</label>
							<RangePicker  size='large' 
								showTime  inputReadOnly
								onChange={(date: any) => setdate(date)}
								value={date}
								allowClear={false}
							
							/>						
                        </div>
					</DashboardFilterWrapper>
				</div>
			</div>
			<div className='ItemLayout__Content'>
				<div className='bay-stats progressbar-box widget-levelMap-max-h'>
					{data.length === 0 ? (
						<div className='no_data_found_df'>
							<span>No Data Found</span>
						</div>
					) : (
						<>
							{data.map((item: any, key) => {
								return (
									<Fragment key={key}>
										<ProgressBar
											value={item?.valuePerc}
											label={item?.zoneName}
											endLabel={`${item?.parkingCount}`}
											tootTipText={`Parked : ${item?.parkingCount} - total : ${item?.totalCount}`}
											color={ZoneColors(item?.zoneName)??COLOR_FX(key)}
											
										/>
									</Fragment>
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ParkedMoreThanDayZone;
