import React, { useEffect, useState } from 'react';
import ImageView from '../../../../common/components/ImageView/ImageView';
import { FailedToast, FormatDate, FormatTime } from '../../../../utils/utilFunctions';
import { fetch_cars_search_detail } from '../../store/action';

type Props = {
	selectedCar?: any;
};

const FindMyCarResult = (props: Props) => {
	const [data, setdata] = useState<any>();

	useEffect(() => {
		if (props?.selectedCar?.vehicleID) {
			const success = (res: any) => {
				console.log(res);
				setdata(res?.response?.data);
			};
			const failed = (err: any) => {
				FailedToast(err?.response)		;
			};
			fetch_cars_search_detail(props?.selectedCar?.vehicleID, success, failed);
		}
		return () => {
			setdata(null);
		};
	}, [props?.selectedCar?.vehicleID]);

	console.log("selectedCar",props?.selectedCar)
	console.log("data",data)
		
	function time_convert(num:number)
	{ 
	   var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
	   var d = (num / 60) % 24
	   var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	   var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
	 console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
	 return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
	}
	

	return (

		<div className="row">
		<div className="col-7">
			<div className="table-details" >
				<div>
					<div className="border-title-row flex-1">
						<h2 className="border-title">Details</h2>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							<h2 className="border-title"><label className="badge green">{props?.selectedCar?.isVisitor ? 'Visitor' : 'Employee'}</label></h2>		
							{
								data?.is_Violatted &&
								<h2 className='border-title'>
									<label className='badge red'>Active Violation</label>
								</h2>
							}				
						</div>

					</div>
					<div className="dflex" >
						<ul >
						<li>  {props?.selectedCar?.isVisitor ? "Visitor Name" :"Employee Name"}<span>{props?.selectedCar?.fullName}</span></li>

							<li>Plate Number <span>{props?.selectedCar?.plateNumber}</span></li>
							<li>Place of reg. <span>{props?.selectedCar?.placeOfRegistration}</span></li>

							<li>Vehicle Category <span>{props?.selectedCar?.vehicleCategory}</span></li>
							<li>Brand <span>{props?.selectedCar?.brand}</span></li>
							<li>Color <span>{props?.selectedCar?.colour}</span></li>
							<li>Vehicle Description <span>{data?.descriptions??props?.selectedCar?.rF_Descriptions}</span></li>
							
						</ul>
						<div className="seperator"></div>
						<ul>
							<li>Entry Time<span>{FormatDate(data?.entry_dateTime, 'DD-MMM-YYYY hh:mm a')}</span></li>
							<li>Dwell Time 
								<span>{
								data?.duration ?
									`${time_convert(data?.duration)}` :
								`${FormatTime(data?.current_dwell, 'H')} hours ${FormatTime(data?.current_dwell, 'm')} minutes ${FormatTime(data?.current_dwell, 's')>'0'? `${FormatTime(data?.current_dwell, 's')} seconds` : ``}`}</span></li>
							{/* <li>Approved By <span>{data?.approverName}</span></li>

							<li>Approved On <span>{FormatDate(data?.approvedOn, 'DD-MMM-YYYY hh:mm a')}</span></li> */}
							<li>Site <span>{data?.siteName}</span></li>
							<li>Zone <span>{data?.zoneName}</span></li>
							<li>Bay <span>{data?.bayName}</span></li>

									{/* <li>
										Allocated Date Period{' '}
										<span>{`${FormatDate(data?.startDate, 'DD-MMM-YYYY')} `}<br/>{` ${FormatDate(data?.endDate, 'DD-MMM-YYYY')}`}</span>
									</li>
									<li>
										Allocated Time Period{' '}
										<span>{`${FormatTime(
											data?.stratTime,
											'hh:mm a'
										)}	`}<br/>{` ${FormatTime(
											data?.endTime,
											'hh:mm a'
										)}`}</span>
									</li> */}
									<li>Last Updated <span>{FormatDate(data?.lastUpdatedOn, 'DD-MMM-YYYY hh:mm a')}</span></li>


						</ul>

					</div>

				</div>
			</div>
		</div>

		<div className="col-5">
			<div className="parking-lot-image dflex col-dir justify-space-between">
				<ImageView src={data?.imagePath} />

				{
					data?.captureTime && <p>Captured On : {FormatTime(data?.captureTime, 'DD-MMM-YYYY hh:mm a')}</p>
				}
				
			</div>
		</div>
	</div>
	);
};

export default FindMyCarResult;
