/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import BarGraphCard from '../../../components/BarGraphCard';
import LineGraphCard from '../../../components/LineGraphCard';
import ProgressBarCard from '../../../components/ProgressBarCard';
import ReactSelect from 'react-select';
import { fetch_report_turnover_daily_bayType, fetch_report_turnover_daily_overall, fetch_report_turnover_daily_zone, fetch_report_turnover_heatmap, fetch_report_turnover_summary_bayType, fetch_report_turnover_summary_weekday, fetch_report_turnover_summary_zone } from '../../../store/action';
import { UPDATE_REPORTS_LOADER } from '../../../store/reportSlice';

type Props = {
    tabVal : number;
    setTabVal : any;
	measure : string;
	setMeasure : any;
	chartType : string;
	setChartType : any;
	isUnRecVeh : string; 
	setIsUnRecVeh : any;
	
	filename	: string;
	setFilename : any;
	fileNameSub : string;
	setFilenameSub 	: any;
    fileDateStr		: string;
	setFileDateStr 	: any;
	fileTimeStr 	: string;
	setFileTimeStr	: any;
    fileSectionStr  : string;
	setFileSectionStr : any
}

const ReportDisplay = (props: Props) => {
    const REPORTSDATA = useAppSelector((state) => state?.reportData)
    const SITE_FLOOR = useAppSelector((state)=>state?.general)

    const dispatch  = useAppDispatch();
	useEffect(() => {
		dispatch(UPDATE_REPORTS_LOADER())
	}, [props.tabVal])
	useEffect(() => {
        dispatch(UPDATE_REPORTS_LOADER())
        if(REPORTSDATA?.REPORTS_SITE!==0 && SITE_FLOOR?.SITE_DDL[0]?.value){
            let postData = {
				DataType	  :	REPORTSDATA?.REPORTS_FILTER_MEASURE,
              SiteID      :   REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE :  SITE_FLOOR?.SITE_DDL[0]?.value,
              Todate      :   REPORTSDATA?.REPORTS_DATE?.length > 0 ? 
              dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
              FromDate    :   REPORTSDATA?.REPORTS_DATE?.length > 0 ? 
              dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),
              FloorID     :	 0,   
			  //REPORTSDATA?.REPORTS_FLOOR,
              FromTime    :   REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('HH:mm:ss') : '00:00:00',
              ToTime      :   REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('HH:mm:ss') :  '23:59:00',
			  UnknownVehicle : parseInt(props?.isUnRecVeh)
          }
      if(props.tabVal===0){
        fetch_report_turnover_summary_weekday(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        fetch_report_turnover_summary_bayType(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        fetch_report_turnover_summary_zone(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
      }
      else{
        fetch_report_turnover_daily_bayType(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        fetch_report_turnover_daily_overall(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
        fetch_report_turnover_daily_zone(postData, props.measure, props.chartType , parseInt(props?.isUnRecVeh), dispatch)
      }
    //   else{
    //     fetch_report_turnover_heatmap(postData, dispatch)
    //   }
	// props.setMeasure(REPORTSDATA?.REPORTS_FILTER_MEASURE)
	// props.setChartType(REPORTSDATA?.REPORTS_FILTER_CHART)
    props?.setFilename(`${REPORTSDATA?.REPORTS_DATE?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('DD-MMM-YYYY') : dayjs().subtract(7,'day').format('DD-MMM-YYYY')} - ${
        REPORTSDATA?.REPORTS_DATE?.length > 0 ?  dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('DD-MMM-YYYY') : dayjs().format('DD-MMM-YYYY')} (${
            REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
                REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'})`)
                props?.setFilenameSub(`(${SITE_FLOOR?.SITE_DDL?.find(el => el.value===REPORTSDATA?.REPORTS_SITE)?.label})`)

                props?.setFileDateStr(`${REPORTSDATA?.REPORTS_DATE?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('DD-MMM-YYYY') : dayjs().subtract(7,'day').format('DD-MMM-YYYY')} - ${
                    REPORTSDATA?.REPORTS_DATE?.length > 0 ?  dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('DD-MMM-YYYY') : dayjs().format('DD-MMM-YYYY')}`)
                props?.setFileTimeStr(`${REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
                        REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'}`)
                props?.setFileSectionStr(`${SITE_FLOOR?.SITE_DDL?.find(el => el.value===REPORTSDATA?.REPORTS_SITE)?.label}`)

        }
      }, [props.tabVal, REPORTSDATA?.REPORTS_FLOOR, REPORTSDATA?.REPORTS_SITE, props?.chartType, props?.measure, props?.isUnRecVeh])

	return (
		<div className="white-card data-clean">

			<div className="custom-tabing-menu customTab-menu">
				<ul>
					<li onClick={()=>{ props.setTabVal(0) 
					dispatch(UPDATE_REPORTS_LOADER()) }}>
						<a className={ props.tabVal===0 ? "active" : ""} style={{ borderTopLeftRadius:'10px' }}>Summary</a>
					</li>
					<li onClick={()=>{ props.setTabVal(1) 
					dispatch(UPDATE_REPORTS_LOADER()) }}>
						<a className={ props.tabVal===1 ? "active" : ""}>Daily Turn Over</a>
					</li>
					{/* <li onClick={()=>props.setTabVal(2)}>
						<a className={ props.tabVal===2 ? "active" : ""}>Turn Over Heatmap</a>
					</li> */}
				</ul>
				<ul className='customTab-menu-ul'>
				<li>						
						<div className="form-group">
							<ReactSelect
								options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								className={'common-select'}
								placeholder={'Vehicle Type'}
								isMulti={false}
								isClearable={false}
                        	    	value={[
										{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
									].find((el:any) => el.value===props.isUnRecVeh)??''}
                            		onChange={(val:any) =>{ props.setIsUnRecVeh(val?.value); }}
							/>
						</div>
					</li>
					{
						props.tabVal === 0 ?  
						<li>
						<div className="form-group">
							<ReactSelect
								options={[
									{ value : 'avg', label : 'Average' },
									{ value : 'min', label : 'Min' },
									{ value : 'max', label : 'Max' }
								]}
								className={'common-select'}
								placeholder={'Measure'}
								isMulti={false}
								isClearable={false}
                        	    	value={[
									{ value : 'avg', label : 'Average' },
									{ value : 'min', label : 'Min' },
									{ value : 'max', label : 'Max' }
								].find((el:any) => el.value===props.measure)??""}
                            		onChange={(val:any) =>{ props.setMeasure(val?.value); }}
							/>
						</div>
					</li>
						:
						<li>						
						<div className="form-group">
							<ReactSelect
								options={[
									{ value : 'bar', label : 'Bar Graph' },
									{ value : 'line', label : 'Line Graph' },
									{ value : 'area', label : 'Area Graph' }
								]}
								className={'common-select'}
								placeholder={'Chart Type'}
								isMulti={false}
								isClearable={true}
                        	    	value={[
										{ value : 'bar', label : 'Bar Graph' },
										{ value : 'line', label : 'Line Graph' },
										{ value : 'area', label : 'Area Graph' }
								].find((el:any) => el.value===props.chartType)??''}
                            		onChange={(val:any) =>{ props.setChartType(val?.value); }}
							/>
						</div>
						</li>
					}
				</ul>
			</div>

			<div className="tabing-row active" id="summary">
				<div className="custom-tabing-body">
					{
                        props.tabVal===0 ?
                            <div className="row msb-20">
                                <ProgressBarCard
                                    title={'Average Turn Over By Day of Week'} data={REPORTSDATA?.REPORTS_GRAPH_ONE_DATA}
                                    loader={REPORTSDATA?.REPORTS_GRAPH_ONE_LOAD}
                                    fileName={`(${props?.filename})`}
                                    titleArr={[`Average Turn Over By Day of Week`,`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_ONE_CSV_COLS??null}
								/>
                                <ProgressBarCard
                                    title={'Average Turn Over By Bay Type'} data={REPORTSDATA?.REPORTS_GRAPH_TWO_DATA}
                                    loader={REPORTSDATA?.REPORTS_GRAPH_TWO_LOAD}
                                    fileName={`(${props?.filename} ${props?.fileNameSub})`}
                                    titleArr={[`Average Turn Over By Bay Type`,`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_TWO_CSV_COLS??null}
								/>
                                <ProgressBarCard
                                    title={'Average Turn Over By Zone'} data={REPORTSDATA?.REPORTS_GRAPH_THREE_DATA}
                                    loader={REPORTSDATA?.REPORTS_GRAPH_THREE_LOAD}
                                    fileName={`(${props?.filename} ${props?.fileNameSub})`}
                                    titleArr={[`Average Turn Over By Zone`,`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_THREE_CSV_COLS??null}
								/>										
					        </div>
                        :
							props.chartType==='bar' ?
						(
							<div className='row msb-20'>
								<BarGraphCard 
									title={'Daily Turn Over Overall'} 
									data={REPORTSDATA?.REPORTS_GRAPH_ONE_DATA} 
									loader={REPORTSDATA?.REPORTS_GRAPH_ONE_LOAD} 
									labelY={'Cars'} 
									unitY={'%'}
									extraFunc={(v:any)=>{ return `${v}` }} 
									// yTickFormaterFn={valConvertTimeToReadablePerc}

									fileName={`(${props?.filename})`}
									titleArr={['Daily Turn Over Overall',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_ONE_CSV_COLS??null}/>
								<BarGraphCard 
									title={'Daily Turn Over By Bay Type'} 
									data={REPORTSDATA?.REPORTS_GRAPH_TWO_DATA} 
									unitY={'%'}
									loader={REPORTSDATA?.REPORTS_GRAPH_TWO_LOAD} 
									labelY={'Cars'} 
									fileName={`(${props?.filename} ${props?.fileNameSub})`}
								 	titleArr={['Daily Turn Over By Bay Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	extraFunc={(v:any)=>{ return `${v}` }} 
									// yTickFormaterFn={valConvertTimeToReadablePerc}
									
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_TWO_CSV_COLS??null}/>
								<BarGraphCard 
									title={'Daily Turn Over By Zone'} 
									data={REPORTSDATA?.REPORTS_GRAPH_THREE_DATA} 
									unitY={'%'}
									loader={REPORTSDATA?.REPORTS_GRAPH_THREE_LOAD} 
									labelY={'Cars'} 
									fileName={`(${props?.filename} ${props?.fileNameSub})`}
								 	titleArr={props.tabVal === 1 ? ['Hourly Occupancy By Bay-Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]
										: ['Historical Occupancy By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	extraFunc={(v:any)=>{ return `${v}` }} 
									// yTickFormaterFn={valConvertTimeToReadablePerc}
									
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_THREE_CSV_COLS??null}/>
							</div>
						)
						:	
						(
							<div className='row msb-20'>
								<LineGraphCard 
									title={'Daily Turn Over Overall'} 
									data={REPORTSDATA?.REPORTS_GRAPH_ONE_DATA} lineType={'monotone'} 
									loader={REPORTSDATA?.REPORTS_GRAPH_ONE_LOAD} 
									labelY={'Cars'} 
									unitY={'%'}
									extraFunc={(v:any)=>{ return `${v}` }} 
									// yTickFormaterFn={valConvertTimeToReadablePerc}

									fileName={`(${props?.filename})`} 
									isAreaOpen={props.chartType==='area' ? true : false}
									titleArr={['Daily Turn Over Overall',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]}
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_ONE_CSV_COLS??null}/>
								<LineGraphCard 
									title={'Daily Turn Over By Bay Type'} 
									data={REPORTSDATA?.REPORTS_GRAPH_TWO_DATA} lineType={'monotone'} 
									loader={REPORTSDATA?.REPORTS_GRAPH_TWO_LOAD} 
									labelY={'Cars'} fileName={`(${props?.filename} ${props?.fileNameSub})`} 
									isAreaOpen={props.chartType==='area' ? true : false}
								 	titleArr={['Daily Turn Over By Bay Type',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`] } 
								 	unitY={'%'}
									extraFunc={(v:any)=>{ return `${v}` }}  
									// yTickFormaterFn={valConvertTimeToReadablePerc}
									
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_TWO_CSV_COLS??null}/>
								<LineGraphCard 
									title={'Daily Turn Over By Zone'} 
									data={REPORTSDATA?.REPORTS_GRAPH_THREE_DATA} lineType={'monotone'} 
									loader={REPORTSDATA?.REPORTS_GRAPH_THREE_LOAD} 
									labelY={'Cars'} fileName={`(${props?.filename} ${props?.fileNameSub})`} 
									isAreaOpen={props.chartType==='area' ? true : false}
								 	titleArr={['Daily Turn Over By Zone',`Date : ${props?.fileDateStr}`, `Time : ${props?.fileTimeStr}`, `Site : ${props?.fileSectionStr}`]} 
								 	unitY={'%'}
									extraFunc={(v:any)=>{ return `${v}` }}  
									// yTickFormaterFn={valConvertTimeToReadablePerc}
									
									csvColumns={REPORTSDATA?.REPORTS_GRAPH_THREE_CSV_COLS??null}/>
							</div>
						)  
                    }
					
				</div>
			</div>
		</div>
	);
};

export default ReportDisplay;