import React from 'react';

type Props = {};

const TextEditorSkelton = (props: Props) => {
	return (
		<div className='w-100 full-height' style={{ border: '1px solid var(--lightgrey)' }}>
			<div className='w-100 dflex gap-10 p-10 ' style={{ alignItems:"center"  , borderBottom: '1px solid var(--lightgrey)' ,flexWrap:"wrap"}}>
				{[...Array(16)].map((item) => {
					return <div key={item} className='new-temp-skel-btn skeleton'></div>;
				})}
			</div>
            <div className='p-10 w-100'>
            <div className='skeleton w-100 ' style={{ minHeight :"470px"}}></div>

            </div>

		</div>
	);
};

export default TextEditorSkelton;
