/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { routes } from '../../config/routes';

import edit from '../../assets/images/icons/edit.svg';
import logout from '../../assets/images/icons/logout.svg';

import dp from '../../assets/images/common/profile-pic.png';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { FrontendPermision } from '../../modules/security/screens/Roles/components/types';
import { Fragment } from 'react';
import auth from '../../config/auth';
import { useNavigate } from 'react-router';
import ProfileUpdateModal from './ProfileUpdateModal';
import { fetchUserPermission } from '../../modules/auth/store/action';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const Sidebar = () => {
	const { pathname } = useLocation();
	const { instance } = useMsal();

	let current_tab: any = pathname.split('/');
	if (current_tab.length > 0) {
		current_tab = '/' + current_tab[1];
	} else {
		current_tab = '/';
	}
	let navigate = useNavigate();
	const module_permissions = useAppSelector((state) => state?.login?.USER_PERMISSION);

	const userData: any = useAppSelector((state) => state?.login?.USER_PROFILE);

	const [Permission, setPermission] = useState<FrontendPermision[]>([]);

	useEffect(() => {
		setPermission([...module_permissions.filter((item) => item?.user_has_access === true)]);
	}, [module_permissions]);

	const [modal, setmodal] = useState(false);

	const isAuthenticated = useIsAuthenticated();

	const handleLogout = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		});
	};
	return (
		<>
			<div className='sidebar-navigation'>
				<div className='navigation-header'>
					<div className='profile-pic'>
						<img src={dp} alt='profile-pic' title='John Deo - Business Excutives' />
					</div>
					<div className='profile-info'>
						<h3 style={{ textTransform: 'capitalize' }}>
							{userData?.firstName}
							<span>{userData?.desName}</span>
						</h3>
						<a>
							<img src={edit} alt='' onClick={() => setmodal(true)} />
						</a>
					</div>
				</div>

				<div className='navigation-menus'>
					<ul>
						<Scrollbars style={{ height: 'calc(100vh - 186px)' }} autoHide autoHideTimeout={0}>
							{routes.map((item, key) => {
								const { name, icon, path, title, module_id } = item;
								if (Permission.some((items: any) => items?.module_id === module_id)) {
									return (
										<MainComponent
											key={key}
											current_tab={current_tab}
											path={path}
											icon={icon}
											name={name}
											title={title}
										/>
									);
								} else {
									// return <MainComponent key={key} current_tab={current_tab} path={"/"} icon={icon} name={name} title={title} disabled={true}/>;
									// return <Skeleton />
								}
							})}

							<Fragment>
								<li
									className='mt-30'
									onClick={() => {
										auth.logout(() => {
											navigate('/login', { replace: true });
											if (isAuthenticated) {
												handleLogout();
											}
										});
									}}>
									<a className='navigation-menus-active-grey'>
										<span>
											<img src={logout} alt='icon' title={'Logout'} />
										</span>
										Logout
									</a>
								</li>
							</Fragment>
						</Scrollbars>
					</ul>
				</div>
			</div>
			<ProfileUpdateModal open={modal} handleClose={() => setmodal(false)} />
		</>
	);
};

export default Sidebar;

const MainComponent = ({ icon, title, path, current_tab, disabled }: any) => {
	const dispatch = useAppDispatch();
	let navigate = useNavigate();

	let class_name = current_tab === path || current_tab + '/*' === path ? 'navigation-menus-active' : '';

	return (
		<li className={disabled ? 'opacity-half' : ''} onClick={() => fetchUserPermission(dispatch, ()=>{}, ()=>{}, navigate)}>
			<NavLink to={path} className={class_name}>
				<span>
					<img src={icon} alt='icon' title={title} />
				</span>
				{title}
			</NavLink>
		</li>
	);
};

// const Skeleton = ({ icon, path, current_tab }: any) => {
// 	return (
// 		<li className=''>
// 			<a className={' skeleton'}>
// 				<span>
// 					<div className='custom-loader-spinner'></div>
// 				</span>
// 			</a>
// 		</li>
// 	);
// };
