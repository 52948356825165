import Request from "../../../config/api";
import { PARKING_ZONE_COUNT, PARKING_ZONE_REQUESTED_ZONES, SECURITY_WEBUSER_REQUEST_ACTION } from "../../../config/endpoint";

// type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetchParkingZonesBayCount = (zoneid: number) => {
    let postData = {
        "filters": {
            "query": ``,
            "OrderBy": ""
        },
        zoneid
    }
    let api_url = PARKING_ZONE_COUNT;
    return Request('post', api_url, postData)

};


export const fetchRequestedParkingZones = async (id:number) => {
    let api_url = PARKING_ZONE_REQUESTED_ZONES + `?UserUniqueID=${id}`;
    return await Request('get', api_url, '')

};


export const createWebuserRequest = (postData: any, signal?: AbortSignal, success?: ((res: any) => void), failed?: ((err: any) => void)) => {
    let api_url = SECURITY_WEBUSER_REQUEST_ACTION;
    Request('post', api_url, postData, signal, success, failed)
};


