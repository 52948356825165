/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import QueryBModal from '../../../../../common/components/query_builder'
import { Field } from 'react-querybuilder';
import { fetch_audit_logs } from '../../../store/action';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import dayjs from 'dayjs';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from 'react-select';

import { DatePicker } from "antd"
import useKeyboard from '../../../../../utils/hooks/useKeyboard';
import moment from 'moment';
const { RangePicker } = DatePicker;
// import ReactSelect from 'react-select';
// import { getAllModules, getAllSubModules } from '../../../../../utils/moduleEvent';

// const customWeekDay = [
// 	{ label: 'Sunday', value: 1 },
// 	{ label: 'Monday', value: 2 },
// 	{ label: 'Tuesday', value: 3 },
// 	{ label: 'Wednesday', value: 4 },
// 	{ label: 'Thursday', value: 5 },
// 	{ label: 'Friday', value: 6 },
// 	{ label: 'Saturday', value: 7 },
// 	{ label: 'day', value: 8 },
// ];

const FilterBar = () => {
	const [advanceModal, setAdvanceModal] = useState(false)
	const [dateRange, setDateRange] = useState<any>([moment(new Date(),'YYYY-MM-DD').subtract(3,'day'),moment(new Date(),'YYYY-MM-DD')])
	const [actID, setActID] = useState<any>('')
	
	const advancemodalOpen = () => setAdvanceModal(true)
    const advancemodalClose = () => setAdvanceModal(false)
	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();

	const [filterActive, setFilterActive] = useState(false)
	
	const [advanceActive, setAdvanceActive] = useState(false)
	const LOGS_LIST		= useAppSelector((state)=>state?.logs)
	const actArr = [ { value : 1, label : 'Create'}, {value : 2, label : 'Update'}, { value : 3, label : 'Delete' }]

	const deployQueryFilter = (query:string) => {
		setAdvanceActive(true)
		console.log('Query',query)
		fetch_audit_logs(navigate, dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': LOGS_LIST.AUDIT_LOGS_PAGINATION.limit,
			},
		})
		advancemodalClose()
	}
	  const fields :Field[] = [
		// { name: 'AuditTID', label: 'Audit ID' },
		// { name: 'TransactionID', label: 'Transaction ID'},
		{ name: 'CAST (CreatedOn AS date)', label: 'Created On', inputType: 'date' },
		{ name: 'CreatedBy', label: 'Created By' },
		// { name: 'PrimaryKeyColumn', label: 'Primary Key Column'},
		// { name: 'primaryKeyValue', label: 'Primary Key Value'},
		// { name: 'Ref_KeyColumn', label: 'Ref-Key Column'},
		// { name: 'Ref_KeyValue', label: 'Ref-Key Value'},
		// { name: 'FK_CompanyID', label: 'Company ID'},
		// { name: 'FkEntityID', label: 'Entity ID'},
		{ name: 'SourceIP', label: 'Source IP'},
		{ name: 'NewData', label: 'New Data'},
		{ name: 'OldData', label: 'Old Data'},
		{ name: 'CreatedBy', label: 'Created By ID'},
		{ name: 'Action', label: 'Action', inputType:"select", 
			values:[ 
			{ name : '1', label : 'Create'},
			{ name : '2', label : 'Update' },
			{ name : '3', label : 'Delete'}
		]}
	  ];

	  const filterSearch = () =>{
		setFilterActive(true)
		let dateFilter = ''
		if(dateRange && dateRange?.length>0)
			dateFilter = `CAST (CreatedOn AS date) >= '${dayjs(dateRange[0]).format('YYYY-MM-DD')}' `
		else	dateFilter = '' 
		if(dateRange && dateRange?.length>1)
			dateFilter = `CAST (CreatedOn AS date) >= '${dayjs(dateRange[0]).format('YYYY-MM-DD')}' AND CAST (CreatedOn AS date) <= '${dayjs(dateRange[1]).format('YYYY-MM-DD')}'`
		else 	dateFilter = ''

		let actionVal = ''
		if(actID!=='')
			actionVal = `Action = ${actID}`
		else actionVal = ''

		fetch_audit_logs(navigate, dispatch, {
			'filters': {
				'query': ` ${dateFilter!=='' ? dateFilter:''}${actionVal!=='' ? `${dateFilter!=='' ? ` AND ` : ''}${actionVal}` : ''}`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': LOGS_LIST.AUDIT_LOGS_PAGINATION.limit,
			},
		})
	}
	
	const clearSearch = () =>{
		fetch_audit_logs(navigate, dispatch, {
			'filters': {
				'query': `CAST (CreatedOn AS date) >= '${dayjs().subtract(3,'day').format('YYYY-MM-DD')}' AND CAST (CreatedOn AS date) <= '${dayjs().format('YYYY-MM-DD')}' `,
			},
			'pagination': {
				'pageNo': 1,
				'limit': LOGS_LIST.AUDIT_LOGS_PAGINATION.limit,
			},
		})
		setFilterActive(false)
		setAdvanceActive(false)
		setDateRange([moment(new Date(),'YYYY-MM-DD').subtract(3,'day'),moment(new Date(),'YYYY-MM-DD')])
		setActID('')
	}
	
	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	return (
		<div className='search-filter-row' ref={ref}>

			<ul>
				<li>
					<a className='active pointerDefaultSet'>
						<i className='fa-solid fa-magnifying-glass'></i> Quick Search
					</a>
				</li>
				<li>
					<a  onClick={()=> advancemodalOpen() } >
						<i className='fa-solid fa-filter'></i>Advanced Filter
					</a>
				</li>
				{	(advanceActive || filterActive) &&
					<li>
						<a onClick={clearSearch}>
							<i className='fa-solid fa-times'></i>Clear All Filters
						</a>
					</li>
				}
			</ul>

			<div className='filter-block'>
					<div className='row'>
							<div className='col'>
								<div className={`form-group`}>
								<RangePicker
									picker="date"
									inputReadOnly
									className='form-control'
									placeholder={['Created',' Between']}
									separator
									value={dateRange}
									format={'DD-MMM-YYYY'}
									allowClear={false}
									allowEmpty={[false,false]}
									onChange={(dates:any) =>{
										setDateRange(dates)
									}}
								/>
							{/* <DatePicker
        						selected={startDate}
        						onChange={(dates:any) =>{
									const [start, end] = dates;
									setStartDate(start)
									setEndDate(end)
								}}
								selectsStart
								dateFormat={'dd-MMM-yyyy'}
								className='form-control'
								startDate={startDate}
								endDate={endDate}
								selectsRange
								placeholderText="Created Between"
								monthsShown={2}
								isClearable={startDate!==''}
      						/> */}
								</div>
							</div>
							<div className='col'>
								<div className='form-group'>
								<ReactSelect
									options={actArr}
									className={'common-select'}
									placeholder={'Actions'}
									isMulti={false}
									isClearable
                        	    value={actID ? actArr?.find((c : any) => c.value === parseInt(actID)) : null}
                            	onChange={(val) =>{ setActID(val?.value) }}
								/>
								</div>
							</div>
						
						
							
							{/* <Input type='date' placeholder='Date Time' value={first} setValue={setfirst} /> */}
							
						<div className='col-2'>
							<div className='form-group'>
								<button onClick={()=>{ filterSearch() }}  className='form-control blue-btn'>Search</button>
							</div>
						</div>
					</div>
			</div>
			<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
		</div>
	);
};

export default FilterBar;
