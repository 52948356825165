import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';
import PopUp from '../../../../common/components/popUp';
import StatusChipComponent from '../../../../common/components/statusChipComponent';
import Request from '../../../../config/api';
import { APPLICATION_SETTINGS_GENERAL } from '../../../../config/endpoint';
import { FormatDate, FormatTime } from '../../../../utils/utilFunctions';
import { get_allocationDetails_byID, get_allocationDetails_byUser } from '../../store/action';
type Props = {
    pRequestID?:any;
    reqTp?:any;
    isVisitor?:boolean;
    userId?:any;
    datePeriod?:any;
    tabData?:any;
    rfUserType?:any;
    setIsAllocationAvail?:any;
    selectAllocation?:any;
    setAllocation?:any;
    hideAllocation?:boolean;
    selectionFn?:any;
    setListLoading?: any;
};

const newLocal = (
	<p className='label-style text-right text-hover' style={{ textDecoration: 'underline' }} onClick={() => {}}>
		Previous Allocations
	</p>
);
const AllocationChart = ({ setListLoading = null, ...props}: Props) => {

  const [allocatedData, setAllocatedData] = useState([])
  const [selectedData, setSelectedData] = useState<any>(null)
  const [prohDate, setProhDate] = useState<any>()
  const [loading, setLoading] = useState(false)

  let startArr:any = ''
  let endArr:any = ''
  var tempMaxDate:any = ''
  var tempMinDate:any = ''
  var currD:any = ''
  var maxDate:any = ''
  var minDate:any = ''
  var dateMaxMinArr:any = []

    useEffect(() => {
      getProhibittedDate()
      
      if(props?.pRequestID){
        if(props?.reqTp!==5 && props?.reqTp!==6)
            getAllocation()
        else 
            getAllocationByReq()
      }else{
        getAllocation()
      }
    }, [props.userId])
    
  //   const handleClose = () => {
	// 	if (childRef.current) {
	// 		childRef.current.childFunction1();
	// 	}
	// };
    const getProhibittedDate = () =>{
      let postData = {
        'AppKey': '',
      }
      let api_url = APPLICATION_SETTINGS_GENERAL
      Request('post', api_url, postData, undefined)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
          setProhDate(dayjs().add(res?.response?.data?.future_parking_possible_limit?.settingsValue,'months').format('YYYY-MM-DD'))
        }
      });
    }
    const getAllocation = () =>{
        setAllocatedData([])
        setSelectedData(null)
        setLoading(true)
        if(setListLoading)
          setListLoading(true)
        let formData = props?.pRequestID ?
        {
          "IsVisitor": props?.rfUserType===1 ? false : true,
          "UserUniqueID": props?.userId
        }
        :
        {
            "IsVisitor": props?.isVisitor,
            "UserUniqueID": props?.userId
        }
          
        const SuccesFx = (res:any) =>{
          console.log(res)
          setAllocatedData(res?.response&&res?.response?.length>0 ? 
            res?.response?.filter((el:any) =>el.fK_RequestTypeID!==5 && el.fK_RequestTypeID!==6 && dayjs(el?.endDate).diff(dayjs(),'day') >=0)  : [])
          if(props?.tabData){
            props?.tabData(res?.response)
            startArr = res?.response?.map((el:any) => 
            ((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2) && el?.startDate) ? new Date(dayjs(el?.startDate).format('YYYY-MM-DD')) : '')
            
            endArr = res?.response?.map((el:any) => 
            ((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2) && el?.endDate) ? new Date(dayjs(el?.endDate).format('YYYY-MM-DD')) : '')

            tempMaxDate = dayjs(Math.max.apply(null,endArr)).add(1,'d').format('YYYY-MM-DD') 
            tempMinDate = dayjs(Math.min.apply(null,startArr)).subtract(1,'d').format('YYYY-MM-DD')
            currD = dayjs().subtract(1,'day').format('YYYY-MM-DD')

            maxDate = dayjs(tempMaxDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : tempMaxDate;
            minDate = dayjs(currD).diff(dayjs(tempMinDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : tempMinDate;

            dateMaxMinArr = []

            // if((props?.selectAllocation || selectedData) && allocatedData && allocatedData?.length>0 ){
            //     let allocateObj:any = selectedData ? selectedData : allocatedData?.find((el:any)=>el.parkingID===props?.selectAllocation)
            //     let maxDate1 = dayjs(allocateObj?.endDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : dayjs(allocateObj?.endDate).format('YYYY-MM-DD');
            //     let minDate2 = dayjs(currD).diff(dayjs(allocateObj?.startDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : dayjs(allocateObj?.startDate).format('YYYY-MM-DD')
            //     console.log('Max2',maxDate1,'min2',minDate2)
            //     dateMaxMinArr = [minDate2, maxDate1]
            // }else 
            if(props?.setIsAllocationAvail){
                dateMaxMinArr = [minDate, maxDate]
            }
            // console.log('MaxDate',maxDate,'minDAte',minDate)
            // console.log('StrArr',startArr,'EndArr',endArr)
            // console.log('PROH',prohDate)
            if(dateMaxMinArr && dateMaxMinArr?.length>0)
              props?.setIsAllocationAvail(dateMaxMinArr)

          }
          setLoading(false)
          if(setListLoading)
            setListLoading(false)
        }
        const FailedFx = (res:any) =>{
          setAllocatedData([])
          setSelectedData(null)
          setLoading(false)
          if(setListLoading)
            setListLoading(false)
        }
        get_allocationDetails_byUser(formData, SuccesFx, FailedFx)
    
      }
  
      const getAllocationByReq = () =>{
        setAllocatedData([])
        setSelectedData(null)
        setLoading(true)
        if(setListLoading)
          setListLoading(true)
        const SuccesFx = (res:any) =>{
          console.log(res)
          setAllocatedData(res?.response&&res?.response?.length>0 ? 
            res?.response?.filter((el:any) =>el.fK_RequestTypeID!==5 && el.fK_RequestTypeID!==6 && dayjs(el?.endDate).diff(dayjs(),'day') >=0) : [])
          if(props?.tabData){
            props?.tabData(res?.response)
            
            startArr = res?.response?.map((el:any) => 
            ((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2) && el?.startDate) ? new Date(dayjs(el?.startDate).format('YYYY-MM-DD')) : '')
            
            endArr = res?.response?.map((el:any) => 
            ((el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2) && el?.endDate) ? new Date(dayjs(el?.endDate).format('YYYY-MM-DD')) : '')
            
            tempMaxDate = dayjs(Math.max.apply(null,endArr)).add(1,'d').format('YYYY-MM-DD') 
            tempMinDate = dayjs(Math.min.apply(null,startArr)).subtract(1,'d').format('YYYY-MM-DD')
            currD = dayjs().subtract(1,'day').format('YYYY-MM-DD')

            maxDate = dayjs(tempMaxDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : tempMaxDate;
            minDate = dayjs(currD).diff(dayjs(tempMinDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : tempMinDate;

          //   if((props?.selectAllocation || selectedData) && allocatedData && allocatedData?.length>0 ){
          //     let allocateObj:any = selectedData ? selectedData : allocatedData?.find((el:any)=>el.parkingID===props?.selectAllocation)
          //     let maxDate1 = dayjs(allocateObj?.endDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : dayjs(allocateObj?.endDate).format('YYYY-MM-DD');
          //     let minDate2 = dayjs(currD).diff(dayjs(allocateObj?.startDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : dayjs(allocateObj?.startDate).format('YYYY-MM-DD')

          //       props?.setIsAllocationAvail([minDate2, maxDate1])
          // }else 
          if(props?.setIsAllocationAvail)
            props?.setIsAllocationAvail([minDate, maxDate])
            console.log('MaxDate',maxDate,'minDAte',minDate)
            console.log('StrArr',startArr,'EndArr',endArr)

          }
          setLoading(false)
          if(setListLoading)
            setListLoading(false)
        }
        const FailedFx = (res:any) =>{
          setAllocatedData([])
          setSelectedData(null)
          setLoading(false)
          if(setListLoading)
            setListLoading(false)
        }
        get_allocationDetails_byID(props?.pRequestID, SuccesFx, FailedFx)
    
      }

    const selectAllocatedDates = (v:any) =>{

        let allocateObj:any = v ? v : allocatedData?.find((el:any)=>el.parkingID===props?.selectAllocation)
        let maxDate1 = dayjs(allocateObj?.endDate).diff(dayjs(prohDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(prohDate).format('YYYY-MM-DD') : dayjs(allocateObj?.endDate).format('YYYY-MM-DD');
        let minDate2 = dayjs(currD).diff(dayjs(allocateObj?.startDate).format('YYYY-MM-DD'),'days') > 0  ?  dayjs(currD).format('YYYY-MM-DD') : dayjs(allocateObj?.startDate).format('YYYY-MM-DD')
        console.log('Max2',maxDate1,'min2',minDate2)
        dateMaxMinArr = [minDate2, maxDate1]
      
    console.log('MaxDate',maxDate,'minDAte',minDate)
    console.log('StrArr',startArr,'EndArr',endArr)
    console.log('PROH',prohDate)
    if(dateMaxMinArr && dateMaxMinArr?.length>0)
      props?.setIsAllocationAvail(dateMaxMinArr)
    }

    // const childRef: any = useRef(null);
  return  (props?.reqTp!==5 && props?.reqTp!==6) || props?.hideAllocation ? null 
        :

        <ReactSelect
            options={allocatedData}
            // styles={{ control: (styles) => ({ ...styles, marginRight : '5%'}) }}
            styles={{
              control: (styles) => ({
                ...styles,
                padding: '8px 0px',
                marginBottom: '10px',
                background: 'var(--grey-primary)',
                border: 'none',
              }),
              placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
            }}
            className={'common-select common-select-grey'}
            placeholder={'Previous Allocations'}
            getOptionValue={(o:any)=> o?.parkingID}
            isLoading={loading}
            // value={selectedData ? allocatedData?.find((el:any)=>el.parkingID===selectedData?.parkingID) : null}
            formatOptionLabel={(o) => (
              <div className='dflex justify-space-between align-items-center'>
                <div className='dflex col-dir gap-5'>
                  <div style={{ display:'flex', flexDirection:'column' }}>
                    <span
                      style={{
                        fontSize: '.85rem',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                      }}>
                      {`${FormatDate(o?.startDate, 'DD-MMM-YYYY')} (${FormatTime(o?.stratTime, 'hh:mm a')}) - 
                      ${FormatDate(o?.endDate, 'DD-MMM-YYYY')} (${FormatTime(o?.endTime, 'hh:mm a')})`}{' '}
                    </span>
                    <small style={{ fontStyle: 'italic' }}>{o?.zoneName}{o?.bayName?`/ Bay : ${o?.bayName}` : ''}</small>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      // justifyContent: 'space-between',
                    }}>
                    <span style={{ fontSize: '.85rem', fontWeight: 600 }}>
                      {`${o?.plateNumber} - ${o?.vehicleCategory} - ${o?.placeOfRegistration}`} {'  '}
                    </span>
                    <small style={{ fontStyle: 'italic' }}>
                      Approved On : {FormatDate(o?.approvedOn, 'DD-MMM-YYYY hh:mm  a')}
                    </small>
                    
                  </div>
                </div>

                <div>
                  <StatusChipComponent text={`Parking ID : ${o?.parkingID}`} />
                </div>
              </div>
            )}
            isMulti={false}
            onChange={(v)=>{
              setSelectedData(allocatedData?.find((el:any)=>el.parkingID===v?.parkingID))
              selectAllocatedDates(allocatedData?.find((el:any)=>el.parkingID===v?.parkingID))
              console.log('VAL',v)
              if(props?.selectionFn){
                props?.selectionFn(allocatedData?.find((el:any)=>el.parkingID===v?.parkingID))
              }
              props?.setAllocation(v?.parkingID)
            }}
        />
      
      
    //     <PopUp icon={newLocal} ref={childRef}>
		// 	<div className='reserve-bay-wrapper'>
					
		// 			<div className='col-12'>
    //                     <div className='row'>
		// 					<div
		// 						className='dflex justify-space-between w-100 align-items-center pb-10'
		// 						style={{ borderBottom: '1px solid var(--lightgrey)' }}>
		// 						<h2 className='border-title ml-5'>Previous Allocations </h2>
		// 					</div>
		// 				</div>
		// 				<div className='row dflex col-dir' style={{ justifyContent: 'space-between' }}>
		// 					<div className='row'>
    //                         <div className='reponsive-table scrollNotVisibleY' style={{ maxHeight: allocatedData&&allocatedData?.length>0 ? '45vh' :'10vh', minHeight:'10vh' }}>
    //                             <table  className='style-table mobile-responsive'>
    //                                 <thead>
    //                                     <tr>
    //                                         <th>ID</th><th>Allocated Period</th><th>Zone</th><th>Approved On</th><th>Vehicle</th>    
    //                                     </tr>
    //                                 </thead>
                                        
    //                                 {	allocatedData
    //                                   &&
    //                                   allocatedData?.filter((el:any)=> (el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2))?.length>0 ?
    //                                     allocatedData?.filter((el:any)=> (el?.fK_RequestTypeID===1 || el?.fK_RequestTypeID===2))?.map((item:any)=>{
    //                                         return(
    //                                             <tr style={{ height:'auto' }}>
    //                                                 <td>{item.parkingID}</td>
		// 											<td style={{ width:'35%' }}>{`${FormatDate(item.startDate, 'DD-MMM-YYYY')} (${FormatTime(item.stratTime, 'hh:mm a')})`}
		// 											<br/>{`${FormatDate(item.endDate, 'DD-MMM-YYYY')} (${FormatTime(item.endTime, 'hh:mm a')})`}</td>
		// 											<td style={{ width:'25%' }}>{item.zoneName}{item.bayName ? <><br/>{`(Bay - ${item.bayName})`}</> : ''}</td>
		// 											<td style={{ width:'35%' }}>{FormatDate(item.approvedOn, 'DD-MMM-YYYY HH:mm  a')}</td>    
    //                                                 <td style={{ width:'25%' }}>
    //                                                     {`${item.plateNumber}`}
    //                                                     -{`${item.vehicleCategory}`}
    //                                                     -{`${item.placeOfRegistration}`}
    //                                                 </td>
    //                                             </tr>
    //                                         )
    //                                     })
		// 								:	<tr><td colSpan={4} style={{ textAlign:'center' }}>No Data</td></tr>
    //                                 }
    //                             </table>
    //                             </div>
								
		// 						{/* <div className='dflex p-10' style={{ overflow: 'auto', flexWrap: 'wrap', height: '280px' }}>
									
		// 						</div> */}
		// 					</div>

		// 					<div className='row' style={{ borderTop: '1px solid var(--lightgrey)' }}>
		// 						<div className='dflex flex-justify-end  gap-10 p-10'>
		// 							<button className='filter-btn' onClick={handleClose}>
		// 								close
		// 							</button>
									
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// </PopUp>
 
}

export default AllocationChart