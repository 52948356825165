import React, { useEffect, useRef, useState } from 'react';

import { Responsive, WidthProvider } from 'react-grid-layout';

import './style.css';

import Select from 'react-select';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { FailedToast, saveToLS, SuccesToast } from '../../utils/utilFunctions';
import { getFromLS } from './../../utils/utilFunctions';
import 'antd/dist/antd.min.css';
// import { Select } from 'antd';

import Widget from './components/widget';
import { initialLayout } from './components/initilaLayout';

import { CircularProgress } from '@mui/material';
import { FetchBayType, FetchFloors, FetchSites, FetchZones, FetchZonesByLevel } from '../realtime_monitior/store/action';
import { useAppDispatch, useAppSelector } from './../../config/hooks';
import {
	UPDATE_DASHBOARD_BAYTYPE_LIST,
	UPDATE_DASHBOARD_FLOOR_LIST,
	UPDATE_DASHBOARD_FLOOR_ZONE_LIST,
	UPDATE_DASHBOARD_SELECTED_FLOOR,
	UPDATE_DASHBOARD_SELECTED_SITE,
	UPDATE_DASHBOARD_SITE_LIST,
	UPDATE_DASHBOARD_ZONE_LIST,
} from './store/dashboardSlice';
import { dashboard_settings_action } from './store/action';
import { fetchUserProfile } from '../auth/store/action';
import { widgets } from '../security/screens/DashboardCustomization/AllDashboardWidgets';
import { dl_layout } from '../security/screens/DashboardCustomization/defaulLayoutFull';
import GraphLoader from './../../common/components/GraphLoader';
import FullLoader from './../../common/components/FullLoader';
import { useResize } from '../../utils/hooks/useResize';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
	let dispatch = useAppDispatch();

	const [showLoader, setshowLoader] = useState(true);

	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const UserProfile: any = useAppSelector((state) => state?.login?.USER_PROFILE);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		FetchSites(signal).then((res) => {
			if (res?.statusCode === 200) {
				let data = res?.response ?? [];
				dispatch(UPDATE_DASHBOARD_SITE_LIST({ data }));
				dispatch(UPDATE_DASHBOARD_SELECTED_SITE({ data: data[0] }));
			}
		});
		return () => {
			controller.abort();
		};
	}, [dispatch]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (DASHBOARD_DATA?.SELECTED_SITE?.value) {
			FetchFloors(DASHBOARD_DATA?.SELECTED_SITE?.value, signal).then((response) => {
				if (response?.statusCode === 200) {
					let floorList = response?.response?.data ?? [];
					dispatch(UPDATE_DASHBOARD_FLOOR_LIST({ data: floorList }));
					dispatch(UPDATE_DASHBOARD_SELECTED_FLOOR({ data: floorList[0] }));
				}
			});
		}

		return () => {
			controller.abort();
		};
	}, [dispatch, DASHBOARD_DATA?.SELECTED_SITE?.value]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (DASHBOARD_DATA?.SELECTED_SITE?.value) {
			FetchZones(DASHBOARD_DATA?.SELECTED_SITE?.value, signal).then((response) => {
				if (response?.statusCode === 200) {
					let data = response?.response ?? [];
					dispatch(UPDATE_DASHBOARD_ZONE_LIST({ data }));
				}
			});
		}
		return () => {
			controller.abort();
		};
	}, [dispatch, DASHBOARD_DATA?.SELECTED_SITE?.value]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (DASHBOARD_DATA?.SELECTED_SITE?.value && DASHBOARD_DATA?.SELECTED_FLOOR?.levelID) {
			FetchZonesByLevel(DASHBOARD_DATA?.SELECTED_SITE?.value, DASHBOARD_DATA?.SELECTED_FLOOR?.levelID, signal).then((response) => {
				if (response?.statusCode === 200) {
					let data = response?.response ?? [];
					dispatch(UPDATE_DASHBOARD_FLOOR_ZONE_LIST({ data }));
				}
			});
		}
		return () => {
			controller.abort();
		};
	}, [dispatch, DASHBOARD_DATA?.SELECTED_SITE?.value, DASHBOARD_DATA?.SELECTED_FLOOR?.levelID]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		FetchBayType(signal).then((response) => {
			if (response?.statusCode === 200) {
				let data = response?.response ?? [];
				dispatch(UPDATE_DASHBOARD_BAYTYPE_LIST({ data }));
			}
		});
		return () => {
			controller.abort();
		};
	}, [dispatch]);

	const [layouts, setLayouts] = useState(dl_layout);
	const [items, setItems] = useState<any>(widgets);
	//

	const [selectedDashboard, setselectedDashboard] = useState<any>();

	useEffect(() => {
		if (UserProfile?.personalDashboard?.items) {
			setselectedDashboard(UserProfile?.personalDashboard);
		} else if (UserProfile?.generalDashboard?.items) {
			setselectedDashboard(UserProfile?.generalDashboard);
		}
	}, [UserProfile?.generalDashboard, UserProfile?.personalDashboard]);

	useEffect(() => {
		if (selectedDashboard) {
			if (selectedDashboard?.permission) {
				let permission = JSON.parse(selectedDashboard?.permission) ?? [];
				let newItems = widgets.filter((item) => permission.includes(item?.id));
				setItems(newItems);

				// setItems((prev: any[]) => prev.filter((item: { id: any }) => permission.includes(item?.id)));
				if (selectedDashboard?.items) {
					setLayouts(JSON.parse(selectedDashboard?.items));
					setTimeout(() => {
						setshowLoader(false);
					}, 2000);
					//console.log("I RAN " ,JSON.parse(selectedDashboard?.items))
				}
			}
		}
	}, [selectedDashboard]);

	const onLayoutChange = (_: any, allLayouts: any) => {
		setLayouts(allLayouts);
	};
	const onLayoutSave = () => {
		UpdateDashboard();
		// saveToLS('layouts', layouts);
		setedit(false);
		// setloader(true);
		// setTimeout(() => {
		// 	setloader(false);
		// }, 500);
	};

	const UpdateDashboard = () => {
		setloader(true);
		let postData: any;
		if (UserProfile?.personalDashboard) {
			postData = {
				'DSettingsID': UserProfile?.personalDashboard?.dashboardID,
				'FK_UserUniqueID': UserProfile?.userUniqueID,
				'Settings': JSON.stringify(layouts),
				'permission': UserProfile?.generalDashboard?.permission,
				'FK_DashboardID': UserProfile?.generalDashboard?.dashboardID,
			};
		} else {
			postData = {
				'DSettingsID': 0,
				'FK_UserUniqueID': UserProfile?.userUniqueID,
				'Settings': JSON.stringify(layouts),
				'permission': UserProfile?.generalDashboard?.permission,
				'FK_DashboardID': UserProfile?.generalDashboard?.dashboardID,
			};
		}
		const sucess = (res: any) => {
			// SuccesToast(res?.response?.data);
			SuccesToast('Settings Editted Successfully');
			fetchUserProfile(dispatch);
			setloader(false);
		};
		const failed = (err: any) => {
			FailedToast(err?.response?.app_data);
			setloader(false);
		};

		console.log('postData', postData);

		if (UserProfile?.personalDashboard) {
			dashboard_settings_action('put', postData, sucess, failed);
		} else {
			dashboard_settings_action('post', postData, sucess, failed);
		}
	};

	const [edit, setedit] = useState(false);

	const [loader, setloader] = useState(false);

	// const handleEditOpen = () => setselectItemsModal(false);
	// const handleOpen = () => setselectItemsModal(true);

	useEffect(() => {
		let clear = setInterval(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
		return () => {
			clearInterval(clear);
		};
	}, []);


	



	// setInterval(() => {
	// console.log("Bounding Client",componentRef.current.getBoundingClientRect())

	// }, 4000);

	return (
		<>
			<div className='navtabs__container' style={{ position: 'relative',}} >
				<div className='flex-justify-end' style={{ position: 'relative', right: '15px' }}>
					<div className='col-2'>
						{/* <Select
							// style={{ width: 120 }}
							onChange={(_, option: any) => {
								let data = { label: option.label, value: option.value };
								dispatch(UPDATE_DASHBOARD_SELECTED_SITE({ data: data }));
							}}
							options={DASHBOARD_DATA?.SITE_LIST}
							value={DASHBOARD_DATA?.SELECTED_SITE?.value}
							size={'middle'}
						/> */}

						<Select
							options={DASHBOARD_DATA?.SITE_LIST}
							className={'common-select-small'}
							value={DASHBOARD_DATA?.SITE_LIST.find((c) => c.value === DASHBOARD_DATA?.SELECTED_SITE?.value) ?? ''}
							onChange={(option: any) => {
								let data = { label: option.label, value: option.value };
								dispatch(UPDATE_DASHBOARD_SELECTED_SITE({ data: data }));
							}}

							// 	menuPortalTarget={document.body}
							// 	styles={{ menuPortal: base => ({ ...base,  }),
							// 	menuList: (base) => ({
							// 		...base,

							// 		"::-webkit-scrollbar": {
							// 		  width: "4px",
							// 		  height: "0px",
							// 		},
							// 		"::-webkit-scrollbar-track": {
							// 		  background: "#f1f1f1"
							// 		},
							// 		"::-webkit-scrollbar-thumb": {
							// 		  background: "#888"
							// 		},
							// 		"::-webkit-scrollbar-thumb:hover": {
							// 		  background: "#555"
							// 		}
							// 	  })

							// }}
						/>
					</div>
					{/* <button onClick={()=> {console.log("CURRENT LAYOUT" , layouts);console.log("Dashboard LAYOUT" , JSON.parse(UserProfile?.generalDashboard?.items) )}
					
					}>
						Log
					</button> */}
					<div className='btn btn-sqaure btn-white' style={{ width: '35px', height: '35px' }} onClick={() => setedit(!edit)}>
						{edit ? <i className='fa-solid fa-pen-to-square'></i> : <i className='fa-solid fa-pen'></i>}
					</div>
					<div className='btn btn-sqaure btn-white ' style={{ width: '35px', height: '35px' }} onClick={onLayoutSave}>
						{loader ? (
							<CircularProgress color='inherit' style={{ width: '12px', height: '12px' }} />
						) : (
							<i className='fa-solid fa-floppy-disk'></i>
						)}
					</div>
				</div>
				{showLoader && (
					<div
						style={{
							display: 'flex',
							width: '100%',
							height: '100%',
							position: 'absolute',
							background: 'var(--lightgrey)',
							top: 0,
							left: 0,
							zIndex: 1000,
						}}>
						{/* {
					[...Array(6)].map((item)=>{
						return(
							<div style={{ height: '40vh' , width:"100%" }}>
							<GraphLoader />

							</div>
						)
					})

					} */}
						<FullLoader />
					</div>
				)}

				<ResponsiveGridLayout
					className='layout'
					layouts={layouts}
					breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
					cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
					rowHeight={60}
					autoSize={true}
					isDraggable={edit}
					isResizable={false}
					onLayoutChange={onLayoutChange}>
					{items &&
						items.map((item: any) => (
							<div key={item?.name} className='widget' data-grid={item?.dataGrid}>
								<Widget id={item?.id} component={item?.component} name={item?.name} NoLayout={item?.NoLayout} />
							</div>
						))}
				</ResponsiveGridLayout>
			</div>
		</>
	);
};

export default Dashboard;
