class Auth {
	[x: string]: any;
	constructor() {
		this.access_token = localStorage.getItem('access_token');
		this.refresh_token = localStorage.getItem('refresh_token');
		this.lang = localStorage.getItem('lang');
		this.ot = localStorage.getItem('ot'); // ot - onetimessotried
		this.lo = localStorage.getItem('lo'); // ol -logged out to revent redirection


		if (this.access_token) {
			this.authenticated = true;
		} else {
			this.authenticated = false;
		}
	}

	login(access_token: string, refresh_token: string, callback: any) {
		this.set_access_token(access_token);
		// this.set_refresh_token(refresh_token);
		if (access_token) {
			this.authenticated = true;
			callback();
		}
	}

	logout(callback: any) {
		this.authenticated = false;
		sessionStorage.setItem("lo","true")

		localStorage.clear();
		callback();
	}

	isAuthenticated() {
		return this.authenticated;
	}

	getToken() {
		return this.access_token;
	}

	getRefreshToken() {
		return this.refresh_token;
	}

	get_lang() {
		return this.lang;
	}

	get_ot() {
		return this.ot;
	}
	get_lo() {
		return this.lo;
	}

	set_refresh_token(token: any) {
		localStorage.setItem('refresh_token', token);
		this.refresh_token = token;
	}

	set_access_token(token: any) {
		localStorage.setItem('access_token', token);
		this.access_token = token;
	}

	set_lang(lang: any) {
		localStorage.setItem('lang', lang);
		this.lang = lang;
	}

	set_ot(ot: any) {
		localStorage.setItem('ot', ot);
		this.ot = ot;
	}
}

export default new Auth();
