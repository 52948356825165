import Request from '../../../config/api';
import { PARKING_REQUEST, PARKING_REQUEST_ACTION, PARKING_REQUEST_STATUS, PARKING_REQUEST_COUNT, BUILDING_LIST_DDL, USERS, PARKING_REQUEST_EMPLOYEE, PARKING_REQUEST_VISITOR, PARKING_OVERTIME_REQUEST_EMPLOYEE, PARKING_OVERTIME_REQUEST_VISITOR, ALLOCATION_AVAILABILITY_GRAPH, RESERVED_BAYLIST, PREVIOS_ALLOCATION_DETAILS_USER_EDITON, PREVIOS_ALLOCATION_DETAILS_PR_EDITON, GET_EVERY_USERS, ISALLOCATION_REQUEST, ISVEHICLE_HAS_REQUESTS, ALLOCATED_USERS_PARKING_URL, NEW_URL_PARKING_REQUEST, NEW_PREVIOS_ALLOCATION_DETAILS_PR_EDITON } from '../../../config/endpoint';
import { UPDATE_PARKING_REQUESTS_DATA, UPDATE_PARKING_REQUESTS_LOADER ,UPDATE_PARKING_REQUESTS_ERROR, UPDATE_PARKING_REQUESTS_COUNT, BULK_UPDATE_PARKING_REQUESTS_LOADER, BULK_UPDATE_PARKING_REQUESTS_DATA, BULK_UPDATE_PARKING_REQUESTS_ERROR} from './parkingRequestSlice';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_parkingRequestslist = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = PARKING_REQUEST;

    dispatch(UPDATE_PARKING_REQUESTS_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_PARKING_REQUESTS_DATA({
                    data,
                    pagination,
                    filters
                })
            );
        } else {
            dispatch(UPDATE_PARKING_REQUESTS_ERROR({
                error: "Some error occured",
            }));
        }
    });
};

export const fetch_parkingRequestslist_bulk = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = PARKING_REQUEST;

    dispatch(BULK_UPDATE_PARKING_REQUESTS_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                BULK_UPDATE_PARKING_REQUESTS_DATA({
                    data,
                    pagination,
                    filters
                })
            );
        } else {
            dispatch(BULK_UPDATE_PARKING_REQUESTS_ERROR({
                error: "Some error occured",
            }));
        }
    });
};


export const fetch_parkingRequestCount = (dispatch:any, postData: Object) => {
    let api_url = PARKING_REQUEST_COUNT;
    Request('post', api_url, postData, undefined)?.then((res) => {
        console.log(res)
        const { statusCode, response } = res;
        if (statusCode === 200) {
            dispatch(
                UPDATE_PARKING_REQUESTS_COUNT({
                    count : response
                })
            )
        } else {
        }
    });
}

export const parkingRequest_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = PARKING_REQUEST_ACTION;
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }});
};


export const fetch_parkingRequest_detail = (id: number, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = PARKING_REQUEST + id + '/';
    return Request('get', api_url, '', undefined, Sucess, Failed)
};

export const fetch_buildingList = (postData: Object, Success: (res: any) => void) => {
    let api_url = BUILDING_LIST_DDL;
    return Request('post', api_url, postData, undefined, Success)
}

export const parkingRequestStatus_action = (postData: Object, Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = PARKING_REQUEST_STATUS;
    Request('put', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }});
}



export const fetch_all_usersSearch = (postData: any, signal?: AbortSignal) => {
    let api_url = postData === '' ? GET_EVERY_USERS : `${GET_EVERY_USERS}?searchtext=${postData}`;
    return Request('get', api_url, '', signal)

};

export const add_parking_request = (reqType:number, postData:any, Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = reqType===1 ? PARKING_REQUEST_EMPLOYEE
    :   reqType===2 ?   PARKING_REQUEST_VISITOR   :   reqType===5 ? PARKING_OVERTIME_REQUEST_EMPLOYEE
    :   PARKING_OVERTIME_REQUEST_VISITOR ;
    console.log('POSTDATA=>',postData)
    Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }});
};

export const get_available_zonesListGraph = (postData:any, Success: (res:any) => void, Failed: (err: any) => void) => {
    let api_url = ALLOCATION_AVAILABILITY_GRAPH
    Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if(statusCode === 200){}
        else{}
    })
}

export const get_available_zonesListGraph2 = (postData:any, Success?: (res:any) => void, Failed?: (err: any) => void) => {
    let api_url = ALLOCATION_AVAILABILITY_GRAPH
    return Request('post', api_url, postData, undefined, Success, Failed)
}


export const get_reservedBayList = (postData:any, Success?: (res:any) => void, Failed?: (err: any) => void) => {
    let api_url = RESERVED_BAYLIST
    return Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if(statusCode === 200){}
        else{}
    })
}

export const get_reservedBays = (postData:any, Success?: (res:any) => void, Failed?: (err: any) => void) => {
    let api_url = RESERVED_BAYLIST
    return Request('post', api_url, postData, undefined, Success, Failed)
   
}

export const get_allocationDetails_byUser = (postData:any, Success: (res:any) => void, Failed: (err: any) => void ) => {
    let api_url = PREVIOS_ALLOCATION_DETAILS_USER_EDITON
    Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if(statusCode === 200){}
        else{}
    })
}

export const get_allocationDetails_byID = (id:any, Success: (res:any) => void, Failed: (err: any) => void) => {
    // let api_url = PREVIOS_ALLOCATION_DETAILS_PR_EDITON+id
    let api_url = NEW_PREVIOS_ALLOCATION_DETAILS_PR_EDITON+id
    Request('get', api_url, undefined, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if(statusCode === 200){}
        else{}
    })
}

export const checkAlreadyAllocationExists = (postData:any, Success: (res:any) => void, Failed: (err: any) => void) => {
    let api_url = ISALLOCATION_REQUEST
    Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if(statusCode === 200){}
        else{}
    })
}

export const checkAlreadyAllocationExistsFilterApi = (postData:any, Success: (res:any) => void, Failed: (err: any) => void) => {
    let api_url = ALLOCATED_USERS_PARKING_URL
    Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        // const { response, statusCode } = res;
        // if(statusCode === 200 && response && response?.length>0){
        //     Success(response)
        // }
        // else{
        //     Failed(response)
        // }
    })
}

export const checkVehicleHasRequests = (postData:any, Success: (res:any) => void, Failed: (err: any) => void) => {
    let api_url = ISVEHICLE_HAS_REQUESTS+postData
    Request('get', api_url, undefined, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res;
        if(statusCode === 200){}
        else{}
    })
}
