import Request from '../../../config/api';
import {
    ALLOCATED_USERS_PARKING_REVOKE, ALLOCATED_USERS_PARKING_URL, ALLOCATED_USERS_PARKING_URL_ACTION, ALLOCATED_USERS_PARKING_URL_COUNT,
    ALLOCATED_VISITORS_PARKING_URL, ALLOCATED_VISITORS_PARKING_URL_COUNT, FIND_MY_CAR_DETAILS, FIND_MY_CAR_TODAYS_COUNT, FIND_MY_CAR_URL, REALTIME_MONITOR_FINDVEHICLE
} from '../../../config/endpoint';
import {
    UPDATE_FIND_A_VEHICLE_DATA,
    UPDATE_FIND_A_VEHICLE_ERROR,
    UPDATE_FIND_A_VEHICLE_LOADER,
    UPDATE_USER_ALLOCATED_PARKING_COUNT, UPDATE_USER_ALLOCATED_PARKING_DATA, UPDATE_USER_ALLOCATED_PARKING_ERROR, UPDATE_USER_ALLOCATED_PARKING_LOADER,
    UPDATE_VISITOR_ALLOCATED_PARKING_COUNT, UPDATE_VISITOR_ALLOCATED_PARKING_DATA, UPDATE_VISITOR_ALLOCATED_PARKING_ERROR, UPDATE_VISITOR_ALLOCATED_PARKING_LOADER
} from './findacarslice';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const find_cars_search = async (postData: Object, signal?: AbortSignal) => {
    let api_url = FIND_MY_CAR_URL
    return await Request('post', api_url, postData, signal)
}

export const find_cars_search_realtime = async (postData: Object, signal?: AbortSignal) => {
    let api_url = REALTIME_MONITOR_FINDVEHICLE
    return await Request('post', api_url, postData, signal)
}

export const fetch_cars_search_detail = (id: number, Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = FIND_MY_CAR_DETAILS + id
    Request('get', api_url, '', undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if (statusCode === 200) {

        } else {

        }
    })
}


export const update_approved_allocations = (type: t, postData: Object, Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = ALLOCATED_USERS_PARKING_URL_ACTION
    Request(type, api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if (statusCode === 200) {

        } else {

        }
    })
}

export const revoke_approved_allocation = (postData: Object, Success: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = ALLOCATED_USERS_PARKING_REVOKE
    Request('post', api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if (statusCode === 200) {

        } else {

        }
    })
}


export const fetch_Users_AllocatedParkingslist = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = ALLOCATED_USERS_PARKING_URL;

    dispatch(UPDATE_USER_ALLOCATED_PARKING_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_USER_ALLOCATED_PARKING_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_USER_ALLOCATED_PARKING_ERROR({
                error: "Some error occured",
            }));
        }
    });
};

export const fetch_find_a_vehicle = (dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = FIND_MY_CAR_URL;

    dispatch(UPDATE_FIND_A_VEHICLE_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_FIND_A_VEHICLE_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_FIND_A_VEHICLE_ERROR({
                error: "Some error occured",
            }));
        }
    });
};

export const fetch_Users_AllocatedParkingCount = (dispatch: any, postData?: Object) => {
    let api_url = ALLOCATED_USERS_PARKING_URL_COUNT;
    Request('post', api_url, postData, undefined)?.then((res) => {
        console.log(res)
        const { statusCode, response } = res;
        if (statusCode === 200) {
            dispatch(
                UPDATE_USER_ALLOCATED_PARKING_COUNT({
                    count: response
                })
            )
        } else {
        }
    });
}

export const fetch_todays_parking_count = () => {
    let api_url = FIND_MY_CAR_TODAYS_COUNT;
    return Request('get', api_url, '', undefined)
}

// export const fetch_Visitors_AllocatedParkingslist = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
//     let api_url = ALLOCATED_VISITORS_PARKING_URL;

//     dispatch(UPDATE_VISITOR_ALLOCATED_PARKING_LOADER({
//         loader: true,
//     }));
//     Request('post', api_url, postData, signal)?.then((res) => {
//         const { response, statusCode } = res;
//         const { data, pagination, filters } = response;
//         if (statusCode === 200) {
//             dispatch(
//                 UPDATE_VISITOR_ALLOCATED_PARKING_DATA({
//                     data,
//                     pagination,
//                     filters,
//                 })
//             );
//         } else {
//             dispatch(UPDATE_VISITOR_ALLOCATED_PARKING_ERROR({
//                 error: "Some error occured",
//             }));
//         }
//     });
// };

// export const fetch_Visitors_AllocatedParkingCount = (dispatch:any, postData?: Object) => {
//     let api_url = ALLOCATED_VISITORS_PARKING_URL_COUNT;
//     Request('get', api_url, postData, undefined)?.then((res) => {
//         console.log(res)
//         const { statusCode, response } = res;
//         if (statusCode === 200) {
//             dispatch(
//                 UPDATE_VISITOR_ALLOCATED_PARKING_COUNT({
//                     count : response
//                 })
//             )
//         } else {
//         }
//     });
// }
