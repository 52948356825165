import { FrontendPermision } from "../modules/security/screens/Roles/components/types";

export const UserPermssion: FrontendPermision[] = [
	{
		'module_id': 1,
		'module_name': 'Dashboards',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 31,//changed
				'sub_module_name': 'Dashboards',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 2,
		'module_name': 'Find Vehicles',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 1,
				'sub_module_name': 'Find A Car',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 2,
				'sub_module_name': 'Allocated Parking',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 3,
		'module_name': 'Real Time Monitoring',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 3,
				'sub_module_name': 'Real Time Monitoring',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 4,
				'sub_module_name': 'Availability Check',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 5,
				'sub_module_name': 'Parking History',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 6,
				'sub_module_name': 'Transactions',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 4,
		'module_name': 'Parking Requests',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 7,
				'sub_module_name': 'Parking Requests',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 5,
		'module_name': 'Violations',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 8,
				'sub_module_name': 'Parking Violations',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 9,
				'sub_module_name': 'Black Listed Vehicles',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 10,
				'sub_module_name': 'Report Violation',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 6,
		'module_name': 'Users',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 11,
				'sub_module_name': 'Users',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 15,
		'module_name': 'Visitors',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 33,//changed
				'sub_module_name': 'Visitors',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 7,
		'module_name': 'Policies',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 12,
				'sub_module_name': 'Parking Policies',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 13,
				'sub_module_name': 'Violation Policies',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 14,
				'sub_module_name': 'Policy Assignment',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 8,
		'module_name': 'Reports & Dashboards',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 32,//changed
				'sub_module_name': 'Reports & Dashboards',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 9,
		'module_name': 'Parking Zones',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 15,
				'sub_module_name': 'Parking Zones',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 10,
		'module_name': 'Notifications',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 16,
				'sub_module_name': 'Notifications',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 17,
				'sub_module_name': 'Notification Exceptions',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 18,
				'sub_module_name': 'Notification Settings',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 19,
				'sub_module_name': 'Notification History',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 11,
		'module_name': 'Logs',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 20,
				'sub_module_name': 'Application Logs',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 21,
				'sub_module_name': 'Audit Logs',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 12,
		'module_name': 'Definitions',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 22,
				'sub_module_name': 'Definitions',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		]
	},
	{
		'module_id': 13,
		'module_name': 'Application Settings',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 23, // changed
				'sub_module_name': 'Application Settings',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 24, //changed
				'sub_module_name': 'Data Synchronization',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 25,//changed
				'sub_module_name': 'License',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 26,//changed
				'sub_module_name': 'Company Profile',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
	{
		'module_id': 14,
		'module_name': 'Security',
		'full_control': false,
		'user_has_access': false,
		'sub_modules': [
			{
				'fK_SubModuleID': 27,//changed
				'sub_module_name': 'Roles',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 28,//changed
				'sub_module_name': 'Web Users',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 29,//changed
				'sub_module_name': 'Web Users Request',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
			{
				'fK_SubModuleID': 30,//changed
				'sub_module_name': 'Password Policy',
				'isView': false,
				'isCreate': false,
				'isEdit': false,
				'isDelete': false,
			},
		],
	},
];