import React, { useEffect } from 'react'
import ParkingRequest from './screens/parkingRequest'
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RESET_STATE } from './store/parkingRequestSlice';

type Props = {}

const ParkingRequestIndex = (props: Props) => {
  const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  
  return (
    <div className='navtabs__container'>
    <Routes>
      <Route path={''} element={<ParkingRequest />} />
      <Route path={'/*'} element={<Navigate to='' />} />
    </Routes>
  </div>
  )
}

export default ParkingRequestIndex