
interface  Iroutes{ 

    path: string,
    name: string,
    exact: boolean,
    title: string,
    modalType: Number,
    defName: string,
    defId: string,
    referenceId?: string,
    componentsNeed?: Array<string>
    // Component: JSX.Element | null | any

}

  


export const definition_routes : Iroutes[]= [
    //PMS
    {
        path: 'rejectionreason',
        name: 'rejection',
        exact: false,
        title: "Rejection Reason",
        modalType: 1,
        defName: 'rejectReason',
        defId: 'rejectID',
        componentsNeed: ['descriptions', 'isDefault','fK_CompanyID','companyName']
    },
    {
        path: 'violationreason',
        name: 'violationreason',
        exact: false,
        title: "Invalid Violation Reason",
        modalType: 1,
        defName: 'vReason',
        defId: 'vReasonID',
        componentsNeed: ['descriptions', 'isDefault','fK_CompanyID','companyName']
    },
    //INX
    {
        path: 'site',
        name: 'site',
        exact: false,
        title: "Site",
        modalType: 2,
        defName: 'siteName',
        defId: 'siteID',
        componentsNeed: ['fK_CompanyID']
    },
    {
        path: 'floorlevel',
        name: 'floorlevel',
        exact: false,
        title: "Floor",
        modalType: 2,
        defName: 'levelName',
        defId: 'levelID',
        referenceId : 'flRefrenceID',
        componentsNeed: ['companyName','fK_CompanyID','fK_SiteID','siteName','ReferenceID','image']
    },
    {
        path: 'zone',
        name: 'zone',
        exact: false,
        title: "Zone",
        modalType: 2,
        defName: 'zoneName',
        defId: 'zoneID',
        referenceId : 'zRefrenceID',
        componentsNeed: ['levelName', 'fK_FLevelID','ReferenceID']
    },    
    {
        path: 'parkingbay',
        name: 'parkingbay',
        exact: false,
        title: "Parking Bay",
        modalType: 2,
        defName: 'bayName',
        defId: 'bayID',
        referenceId : 'pbRefrenceID',
        componentsNeed: ['companyName','fK_CompanyID','fK_ZoneID','zoneName','ReferenceID']
        
    },
    {
        path: 'baytype',
        name: 'baytype',
        exact: false,
        title: "Bay-Type",
        modalType: 2,
        defName: 'bTypeName',
        defId: 'bTypeID',
        referenceId : 'btReferenceID',
        componentsNeed: ['fK_CompanyID','isDefault','ReferenceID']
        
    },

    //SAP
    {
        path: 'department',
        name: 'department',
        exact: false,
        title: "Department",
        modalType: 1,
        defName: 'depName',
        referenceId: 'depRefrenceID',
        defId: 'departmentID'
        
    },
    {
        path: 'designation',
        name: 'designation',
        exact: false,
        title: "Designation",
        modalType: 1,
        defName: 'desName',
        defId: 'designationID',
        referenceId: 'desRefrenceID',
        componentsNeed: ['fK_CompanyID']
    },
    {
        path: 'groupcompany',
        name: 'groupcompany',
        exact: false,
        title: "Group Company",
        modalType: 1,
        defName: 'companyName',
        defId: 'groupCompanyID',
        referenceId: 'compRefrenceID',
        componentsNeed: ['companyDescription','contactNo','emailID']
    },
    {
        path: 'place-of-reg',
        name: 'placeofreg',
        exact: false,
        title: "Place of Registration",
        modalType: 1,
        defName: 'CityCountry',
        defId: 'PlaceOfRegID',
        referenceId: 'ASKGsReference',
        componentsNeed: ['AnprRefPlaceOFRegID','Abbreviation']
    },
    {
        path: 'vehicleinout',
        name: 'vehicleinout',
        exact: false,
        title: "Vehicle In-Out (for testing)",
        modalType: 1,
        defName: 'companyName',
        defId: 'groupCompanyID',
        referenceId: 'compRefrenceID',
        componentsNeed: ['companyDescription','contactNo','emailID']
    }
    // {
    //     path: 'prioritylevel',
    //     name: 'prioritylevel',
    //     exact: false,
    //     title: "Priority Level",
    //     modalType: 2,
    //     defName: 'pLevel',
    //     defId: 'priorityID',
    //     componentsNeed: ['descriptions','fK_SiteID','siteName']
    // },
    // {
    //     path: 'location',
    //     name: 'location',
    //     exact: false,
    //     title: "Location",
    //     modalType: 1,
    //     defName: 'locName',
    //     defId: 'locationID',
    //     componentsNeed: ['fK_CompanyID']
    // },
    // {
    //     path: 'building',
    //     name: 'building',
    //     exact: false,
    //     title: "Building",
    //     modalType: 1,
    //     defName: 'bdName',
    //     defId: 'buldingID'
    // },
    
    //ASK
    // {
    //     path: 'vehicleplate',
    //     name: 'vehicleplate',
    //     exact: false,
    //     title: "Vehicle Plate",
    //     modalType: 2,
    //     defName: '',
    //     defId: 'vpCategoryID',
    //     componentsNeed: [
    //         //'siteName',
    //         'descriptions']
    // },
        
    
    
    
]