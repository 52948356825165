import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { definition_routes } from '../../config/definitionRoutes';
import { fetch_definitionList } from '../../store/action';
// import { fetch_definitionList } from '../../store/action';
import { UPDATE_DEFINITION_FILTER_ONLY } from '../../store/definitionSlice';
import AddModal from './AddModalComponents/addModalSimple';
import AddModalSite from './AddModalComponents/addModalSite';
import AddModalDrop from './AddModalComponents/addModalZone';
import PriorityAddModal from './AddModalComponents/priorityLevelAdd';

function FilterBar({val='', path, modalType}:{val:string, path:string, modalType:Number}) {
    const [addModal, setaddModal] = useState(false);

	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);
	let navigate = useNavigate()
    const dispatch = useAppDispatch()
	const DEFINITION_DATA = useAppSelector((state) => state?.definition)

	const DefinitionSearch = (searchK:string) => 	{
    
		console.log('defSearch',path, searchK)
		// return dispatch(UPDATE_DEFINITION_FILTER_ONLY({
		// 	filters : {
		// 		"query": searchK!=='' ? 
		// 		`${	path==='rejectionreason' ? 
		// 		!isNaN(parseInt(searchK)) ? `RejectID = ${searchK}` : `RejectReason LIKE '${searchK}%' OR Descriptions LIKE '${searchK}%'`	:
		// 		path==='violationreason' ? 
		// 		!isNaN(parseInt(searchK)) ? ` VReasonID = ${searchK}` : `VReason LIKE '${searchK}%' OR Descriptions LIKE '${searchK}%'`	:
		// 		path==='site' ? 
		// 		!isNaN(parseInt(searchK)) ? `SiteID = ${searchK}` : `SiteName LIKE '${searchK}%'` :
		// 		path==='department' ? 
		// 		!isNaN(parseInt(searchK)) ? `DepartmentID = ${searchK}` : `DepName LIKE '${searchK}%'`	:
		// 		path==='designation' ? 
		// 		!isNaN(parseInt(searchK)) ? `DesignationID = ${searchK}` : `DesName LIKE '${searchK}%' `	:
		// 		path==='prioritylevel' ? 
		// 		!isNaN(parseInt(searchK)) ? `PriorityID = ${searchK} OR PLevel = ${searchK}` : `Descriptions LIKE '${searchK}%'` :				
		// 		path==='vehicleplate' ? 
		// 		!isNaN(parseInt(searchK)) ? `VPCategoryID = ${searchK}` : `Descriptions LIKE '${searchK}%'`	:
		// 		!isNaN(parseInt(searchK)) ? `LevelID = ${searchK}` : `LevelName LIKE '${searchK}%'`

		// 		}` : '' }
		// }))

		return fetch_definitionList(navigate, dispatch,
			{
				"filters": {
					"query": searchK!=='' ? 
					`${	path==='rejectionreason' ? 
					!isNaN(parseInt(searchK)) ? `RejectID LIKE '${searchK}%'` : `RejectReason LIKE '${searchK}%' OR Descriptions LIKE '${searchK}%'`	:
					path==='violationreason' ? 
					!isNaN(parseInt(searchK)) ? ` VReasonID LIKE '${searchK}%'` : `VReason LIKE '${searchK}%' OR Descriptions LIKE '${searchK}%'`	:
					path==='site' ? 
					!isNaN(parseInt(searchK)) ? `SiteID LIKE '${searchK}%'` : `SiteName LIKE '${searchK}%'` :
					path==='department' ? 
					!isNaN(parseInt(searchK)) ? `DepartmentID LIKE '${searchK}%'` : `DepName LIKE '${searchK}%'`	:
					path==='designation' ? 
					!isNaN(parseInt(searchK)) ? `DesignationID LIKE '${searchK}%'` : `DesName LIKE '${searchK}%' `	:
					path==='groupcompany' ? 
					!isNaN(parseInt(searchK)) ? `DesignationID LIKE '${searchK}%'` : `DesName LIKE '${searchK}%' `	:
					path==='prioritylevel' ? 
					!isNaN(parseInt(searchK)) ? `PriorityID LIKE '${searchK}%' OR PLevel LIKE '${searchK}%'` : `Descriptions LIKE '${searchK}%'` :				
					path==='vehicleplate' ? 
					!isNaN(parseInt(searchK)) ? `VPCategoryID LIKE '${searchK}%'` : `Descriptions LIKE '${searchK}%'`	:
					!isNaN(parseInt(searchK)) ? `LevelID LIKE '${searchK}%'` : `LevelName LIKE '${searchK}%'`

					}` : ''
				},
				"pagination": {
					"pageNo": 1,
					"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
				}
			},
			path==='floorlevel' ? 'floorlevel-without-Permission' : path
		);
	}

    return (
        <div className='search-row-button'>
            <div className='row'>
					<div className='col-5'>
						<div className='form-group'>
							<div className='pos-rel'>
								<input type='text' placeholder='Search'  className="form-control grey-input"
							 		onChange={(v)=>{ 
										DefinitionSearch(v.target.value) }} />
								<i className='fa-solid fa-magnifying-glass'></i>
							</div>
						</div>
					</div>
					<div className='col-7 text-right'>
						<a onClick={addModalOpen}   className='btn btn-secondary modal-button'>
							Add New {val}
						</a>
					</div>
			</div>
			{addModal&&(
			path==='prioritylevel' ?
			<PriorityAddModal open={addModal} handleClose={addModalClose} label={val} path={path}/>
			:
			definition_routes.find(el => el.path===path)?.componentsNeed?.some(el=>el==='fK_ZoneID')	?
			<AddModalDrop open={addModal} handleClose={addModalClose} label={val} path={path} />
			:
			definition_routes.find(el => el.path===path)?.componentsNeed?.some(el=>el==='fK_SiteID') ?
            <AddModalSite  open={addModal} handleClose={addModalClose} label={val} path={path} />
            :
			<AddModal open={addModal} handleClose={addModalClose} label={val} path={path} />)
			}
        </div>
    );
}

export default FilterBar;

