/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import MainLayout from '../../../../common/layouts/MainLayout'
import FilterBar from './components/FilterBar'
import ListTable from './components/ListTable'

type Props = {}

const NotificationHistory = (props: Props) => {
	return (
		<MainLayout>
			<FilterBar />
			<ListTable />
		</MainLayout>
	)
}

export default NotificationHistory