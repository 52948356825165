// import React, { PureComponent } from 'react';
import React, { useState } from 'react';

import { LineChart, Line,  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type Props = {
	data: any[];
};

const AvlChart = (props: Props) => {
	console.log('AVL CHART', props.data);
    const [opacitySet, setOpacitySet] = useState(null)

	const handleMouseEnter = (o:any) => {
        const { dataKey } = o    
        setOpacitySet(dataKey);
      };
    
    const handleMouseLeave = () => {
        setOpacitySet(null)
      };


	return (
		<div className='p-10' style={{ width: '750px', height: '500px' }}>
			<ResponsiveContainer width='100%' height='100%'>
				<LineChart
					width={500}
					height={300}
					data={props?.data}
					// margin={{
					// 	top: 5,
					// 	right: 30,
					// 	left: 20,
					// 	bottom: 5,
					// }}
          >
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis
						dataKey='date'
						style={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '10.5px' }}
						// label={{ value: 'Time', angle: 0, position: 'outside' }}
					/>
					<YAxis
						unit={'%'}
						style={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '10.5px' }}
						label={{ value: 'Traffic Percentage', angle: -90, position: 'insideLeft'  }}
						fontSize={'10.5px'}
					/>
					<Tooltip />
					<Legend iconType="rect"  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  
					 wrapperStyle={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '10.5px' ,
    backgroundColor: 'var(--lightgrey)',
    width:"92%",
    left:"8%",
    padding:'5px'


        
        }}/>
					<Line type='monotone' dataKey='00:00-05:59' stroke='#1947ba'  activeDot={{ r: 8 }} strokeOpacity={opacitySet ? opacitySet==='00:00-05:59' ? 1 : 0.1 : 1 } />
					<Line type='monotone' dataKey='06:00-11:59' stroke='#ea0029'  activeDot={{ r: 8 }} strokeOpacity={opacitySet ? opacitySet==='06:00-11:59' ? 1 : 0.1 : 1 } />
					<Line type='monotone' dataKey='12:00-17:59' stroke='#00953b'  activeDot={{ r: 8 }} strokeOpacity={opacitySet ? opacitySet==='12:00-17:59' ? 1 : 0.1 : 1 } />
					<Line type='monotone' dataKey='18:00-23:59' stroke='#f39c12'  activeDot={{ r: 8 }} strokeOpacity={opacitySet ? opacitySet==='18:00-23:59' ? 1 : 0.1 : 1 } />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default AvlChart;
