import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_dwell_distribution_time } from '../../store/action';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import { DatePicker, Select } from 'antd';
import RLineChart from '../LineChart';
import moment from 'moment';
// import dayjs from 'dayjs';
// import type { Dayjs } from 'dayjs';
import RBarChart from './../BarCharts';
import DwellBarChart from '../DwellBarChart';
import ReactSelect from 'react-select';

const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

let typeOption = [
	{
		label: 'Date Wise',
		value: 1,
	},
	{
		label: 'Hour Wise',
		value: 2,
	},
];

const valConvertToTime = (v: any, f: any = '') => {
	if (v && moment(v, ['HH:mm:ss', 'HH:mm', 'hh:mm', 'hh:mm a'], true).isValid())
		return f !== ''
			? moment(v, ['HH:mm:ss', 'HH:mm', 'hh:mm', 'hh:mm a'], true).get('hours')
			: moment(v, ['HH:mm:ss', 'HH:mm', 'hh:mm', 'hh:mm a'], true).format('hh:mm A');
	else return v;
};

const valConvertTo1Dec = (v: any) => {
	if (isNaN(parseInt(v))) return 0;
	else return Math.round((v + Number.EPSILON) * 10) / 10;
};

const valConvertTimeToReadable = (num:number) =>{ 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
  return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
}

const DwellDistributionOverall = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);
	const [type, settype] = useState(typeOption[0]);
	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [loading, setloading] = useState(false);
	const [isUnRecVeh, setIsUnRecVeh] = useState('0')

	const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);

	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			FromTime: date[0].format('HH:MM:00'),
			ToTime: date[1].format('HH:MM:00'),
			UnknownVehicle : isUnRecVeh
		};

		if (floor?.levelID) {
			setloading(true);

			fetch_dwell_distribution_time(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					// let formattedData = data.map((item: any) => ({
					// 	date: item?.vDate ? moment(item?.vDate).format('YYYY-MM-DD') : null,
					// 	time: item?.times ? item?.times.slice(0, -3) : null,
					// 	...item,

					// }));
					// setdata(formattedData);

					// let weekArr2 = data.filter(
					// 	(value: { times: any }, index: any, self: any[]) =>
					// 		index === self.findIndex((t: { times: any }) => t.times === value.times)
					// );

					// let newArr2 = weekArr?.map((el: any) => {
					// 	let obj = {
					// 		name: `${
					// 			valConvertToTime(el?.times, 'h') <= 0 ? 'Less than 0 Hour' : `${valConvertToTime(el?.times, 'h')} Hours`
					// 		}`,
					// 		'Vehicle Percentage': valConvertTo1Dec(el?.dwellPercenatage),
					// 	};
					// 	return obj;
					// });

					let weekArr = res?.response?.data?.filter(
						(value: { hh : any}, index: any, self: any[]) =>
							index === self.findIndex((t: { hh : any}) => t.hh === value.hh ))
					let newArr = weekArr?.map((el:any)=>{
							let obj = { name : `${parseInt(el?.hh) > 23 ? `24hrs+` : `${parseInt(el?.hh)} - ${parseInt(el?.hh)+1} Hrs`}`
							, toolTipLabel : ` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`
							 ,
							 'Dwell' : valConvertTo1Dec(el?.dwellPercenatage) 
							}
							return obj
						})

					setdata(newArr);

					setTimeout(() => {
						setloading(false);
					}, 1500);
				}
			});
		}

		return () => {
			controller.abort();
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value, type.value, date, isUnRecVeh]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>
						{props.name} {isUnRecVeh==='2' ? '(Registered Vehicles Only)' : isUnRecVeh==='1' ? '(Unregistered Vehicles Only)' : ''} by {type.label}
					</div>
					<div>
						<div style={{ fontSize: '12px' }}>
							{date[0].format('DD MMM YYYY hh:mm A')} - {date[1].format('DD MMM YYYY  hh:mm A')}
						</div>
						<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
							{/* <div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Type</label>
								<Select
									className='common-select-grey'
									options={typeOption}
									onChange={(_: any, option: any) => settype(option)}
									value={type?.value}
									placeholder='type'
									style={{ width: '100%' }}
									size='large'
								/>
							</div> */} 
							<div className='form-group select-input full-label' style={{ marginBottom : '1px' }}>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker size='large' showTime inputReadOnly onChange={(date: any) => setdate(date)} value={date} allowClear={false} />
							</div>
							<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Vehicle Type</label>
									<ReactSelect
										className='common-select-grey'
										options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								onChange={(val:any) =>{ setIsUnRecVeh(val?.value); }}
										value={
											[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]?.find((el:any) => el.value===isUnRecVeh) ?? ''
										}
										placeholder={'Vehicle Type'}
									/>
							</div>
						</DashboardFilterWrapper>
					</div>
				</div>

				<div className='ItemLayout__Content'>

					<DwellBarChart title={'Dwell Time Distribution (By Time)'} data={data} loader={loading} unitY={'%'} labelY={'Dwell'} />
				</div>
			</div>
		</>
	);
};

export default DwellDistributionOverall;
