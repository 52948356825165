



import React, { useEffect, useState } from 'react';
import PieChartIndex from '../PieChart';
import { COLORS } from '../../../../utils/Data';
import { fetch_total_systemUserCount } from '../../store/action';


type Props = {
	name: string;
};

const TotalSysUserPie = (props: Props) => {
	const [data, setdata] = useState<any>();
	const [piedata, setpiedata] = useState<any>([]);



	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;


		fetch_total_systemUserCount(signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ;

				let formattedData : any= [
				{name :"Pending" ,value :data?.pending},
				{name :"Approved" ,value :data?.approved},
				{name :"Rejected" ,value :data?.rejected},
				{name :"Cancelled" ,value :data?.cancelled},

			]
			setpiedata(formattedData);
			setdata(data)
			} 
		});
		return () => {
			controller.abort();
		};
	}, []);


	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>{props.name} </div>
				</div>
				<div className='ItemLayout__Content'>
					<PieChartIndex data={piedata} COLORS={COLORS}  />
					<div className='dashboard-details'>
						<ul className='parking-list'>
							<li>
								Approve<span className='text-right'>{data?.approved}</span>
							</li>
							<li>
								Pending <span className='text-right'>{data?.pending}</span>
							</li>
							<li>
								Rejected <span className='text-right'>{data?.rejected}</span>
							</li>
                            <li>
								Cancelled <span className='text-right'>{data?.cancelled}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default TotalSysUserPie;

