import React, { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormSelect from '../../../../common/components/FormSelect';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { fetch_visitorlist, visitor_action } from '../../store/action';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import ErrorComponent from '../../../../common/components/ErrorComponent';
import dayjs from 'dayjs';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import moment from 'moment';
import CustomDatePicker from '../../../../common/components/CustomDatePicker';

const schema = yup.object().shape({
	visitorID: yup.string().typeError('Please enter a valid value'),
	firstName: yup.string().typeError('Please enter a valid value').matches(/^[a-zA-Z_\s]+$/,'Name cannot have numbers').required(),
	contactNo: yup.string().typeError('Please enter a valid value').nullable().notRequired().when('contactNo', {
		is: (value: any) => value?.length,
		then: rule => rule.matches(
			///^[0-9]+$/,
			/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
		 'Enter valid contact'),
	}).test('len','Minimum 7 and maximum 12 digits can be in contact',(value:any)=>(value?.length > 0 && value?.match(/\d/g)?.length > 7 && value?.match(/\d/g)?.length < 13 )),
	emailID: yup.string().typeError('Please enter a valid value').email().required(),
	// dob: yup.date().nullable()
    // .default(undefined),
	gender: yup.number().typeError('Please enter a valid value'),
	companyName: yup.string().typeError('Please enter a valid value').required(),
	emirates_ID: yup.string().typeError('Please enter a valid value'),

},
[
	['contactNo', "contactNo"],
]
);

type Props = {
	handleClose: () => void;
	Data?: any;
};

const AddUser = (props: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		control,
		setValue,
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});
	let dispatch = useAppDispatch();

	const [loading, setloading] = useState(false);

	console.log('DATA ==>', props?.Data,"ERRORS=>",errors);

	useEffect(() => {
		if (props.Data) {
			setValue('visitorID', props?.Data?.visitorID);
			setValue('firstName', props?.Data?.firstName);
			setValue('contactNo', props?.Data?.contactNo);
			setValue('emailID', props?.Data?.emailID);
			setValue('gender', props?.Data?.gender);
			// setValue('dob', props?.Data?.dob ? dayjs(props?.Data?.dob).format('YYYY-MM-DD') : null);
			setValue('companyName', props?.Data?.companyName);
			setValue('emirates_ID', props?.Data?.emirates_ID);

		}
	}, [props.Data, reset, setValue]);

	const onSubmitHandler = (data: Object) => {
		setloading(true);

		console.log('EEE', data);
		// if(data && data?.dob)
		// 	delete data?.dob
		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			fetch_visitorlist('', dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				},
			});
			reset();
			setloading(false);

			props.handleClose();
		};
		const Failed = (err: any) => {
			setloading(false);

			FailedToast(err?.response)		;
		};

		if (props?.Data) {
			visitor_action('put', data, Success, Failed);
		} else {
			visitor_action('post', data, Success, Failed);
		}
	};

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className="modal-body-content">

				
				<div className='form-tabs-block active-tab' style={{ minHeight: '55vh' }}>
					<div className='row'>
						{/* <div className='col-5'>
							<div className='form-group file-upload'>
								<input className='form-control' type='file' id='formFileMultiple' multiple />
								<img src='assets/images/common/upload-icon.svg' alt='' className='upload-icon' />
								<label
									//for="formFileMultiple"

									className='form-label'>
									Choose a file Or Drag it here.
								</label> 
							</div>
						</div> */}
						<div className='col-12'>
							<div className='user-detail-form'>
								<div className='form-w-label modal-form'>
									<div className='row'>
										<div className='col-6'>
											<div className='form-group'>
												<label>Visitor Name</label>
												<input type='text' {...register('firstName')} placeholder='' className='form-control' />
												<ErrorComponent errors={errors} name='firstName' title='Username' />
											</div>
										</div>
										{/* <div className='col-6'>
											<div className='form-group'>
												<label>Date of birth</label>
												<Controller
													name='dob'
													control={control}
													render={({ field: { value, onChange, onBlur } }) => (
														<>
															<CustomDatePicker
																className='form-control'
																style={{
																	position : 'absolute', right : '20px', width : '5%'
																}}
																size='large'
																onChange={onChange}
																value={value ? moment(value) : null}
																defaultPickerValue={moment().add(-15, 'years')}
																disabledDate={(current:any) => current > moment('31-12','DD-MM').add(-15, 'years')}
																allowClear={false}
																setValue={setValue}
																name={'dob'}
																format={'YYYY-MM-DD'}
															/>
														</>
													)}
												/>
												<ErrorComponent errors={errors} name='dob' title='Date of birth' />
											</div>
										</div> */}
									</div>
									<div className='row'>
										<div className='col-6'>
											<div className='form-group'>
												<label>Contact Number</label>
												<input type='text' {...register('contactNo')} placeholder='+971-XX-1234567' className='form-control' />
												<ErrorComponent errors={errors} name='contactNo' title='Contact number' />
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group'>
												<label>Company Name</label>
												<input type='text' {...register('companyName')} placeholder='' className='form-control' />
												<ErrorComponent errors={errors} name='companyName' title='Company Name' />
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-6'>
											<div className='form-group'>
												<label>Email</label>
												<input type='text' {...register('emailID')} placeholder='' className='form-control' />
												<ErrorComponent errors={errors} name='emailID' title='Email' />
											</div>
										</div>
										<div className='col-6'>
											<div className='form-group'>
												<label>Gender</label>
												<FormSelect
													control={control}
													name='gender'
													options={[
														{ label: 'Male', value: 1 },
														{ label: 'Female', value: 2 },
													]}
													grey={true}
													isMulti={false}
													placeholder=''
												/>
												<ErrorComponent errors={errors} name='gender' title='Gender' />
											</div>
										</div>
									</div>
									<div className="row">
									<div className='col-6'>
											<div className='form-group'>
												<label>Emirates ID</label>
												<input type='text' {...register('emirates_ID')} placeholder='' className='form-control' />
												<ErrorComponent errors={errors} name='emirates_ID' title='Emirates ID' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				
				</div>
				<div className='modal-footer'>
					<button type='submit' className='btn btn-secondary border-blue' disabled={loading}>
						<ButtonLoader text={props?.Data ? 'Update Visitor' : 'Add Visitor'} loading={loading} />
					</button>

					<button
						type='reset'
						className='btn btn-outline-blue close-modal-button'
						onClick={() => {
							reset();
							clearErrors();
							props.handleClose();
						}}>
						Close
					</button>
				</div>

			</form>
		</Fragment>
	);
};

export default AddUser;
