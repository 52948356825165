import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_occupancy_heatmap } from '../../../store/action';
import HeatMap from 'react-heatmap-grid';
import './style.css';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import moment from 'moment';
import DashboardFilterWrapper from '../../DashboardFilterWrapper';
import { DatePicker, Select } from 'antd';
import ReactSelect from 'react-select';
import { FetchZonesByLevel } from '../../../../realtime_monitior/store/action';
import { UPDATE_DASHBOARD_FLOOR_ZONE_LIST } from '../../../store/dashboardSlice';
import { filterDuplicateObjArr } from '../../../../../utils/utilFunctions';
const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

// const xLabels = new Array(23).fill(0).map((_, i) => ` ${i + 1}:00 `);

let x = [
	'12:00AM',
	'01:00AM',
	'02:00AM',
	'03:00AM',
	'04:00AM',
	'05:00AM',
	'06:00AM',
	'07:00AM',
	'08:00AM',
	'09:00AM',
	'10:00AM',
	'11:00AM',
	'12:00PM',
	'01:00PM',
	'02:00PM',
	'03:00PM',
	'04:00PM',
	'05:00PM',
	'06:00PM',
	'07:00PM',
	'08:00PM',
	'09:00PM',
	'10:00PM',
	'11:00PM',

];

let typeOption = [
	{
		label: 'Date Wise',
		value: 1,
	},
	{
		label: 'Hour Wise',
		value: 2,
	},
];

let colStrip = [	{ col : `rgba(34,186,210, 1)`, val : 2}, 
{ col : `rgba(17,190,189, 1)`, val : 5},
{ col : `rgba(10,183,140, 1)`, val : 10},
{ col : `rgba(1,181,84, 1)`, val : 19},
{ col : `rgba(37,179,41, 1)`, val : 25},
{ col : `rgba(145,180,0, 1)`, val : 35},
{ col : `rgba(214,178,2, 1)`, val : 44},
{ col : `rgba(220,165,0, 1)`, val : 59},
{ col : `rgba(218,152,0, 1)`, val : 69},
{ col : `rgba(228,131,0, 1)`, val : 79},
{ col : `rgba(235,42,51, 1)`, val : 89},
{ col : `rgba(255,0,0, 1)`, val : 100}
]

const findCellColr = (val:number) =>{
	return colStrip?.find(el => val <= el.val)?.col
}

const yLabels = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
// const data2 = new Array(yLabels.length).fill(0).map(() => new Array(x.length).fill(0).map(() => Math.floor(Math.random() * 100)));

const HeatMapIndex = (props: Props) => {
	const childRef: any = useRef(null);
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);
	let dispatch = useAppDispatch();

	// const [date, setdate] = useState<string>(dayjs().subtract(1, 'days').format('YYYY-MM-DD'));
	const [date, setdate] = useState<any>([moment().subtract(3, 'days').startOf('day'), moment().endOf('day')]);
	const [type, settype] = useState(typeOption[0]);
	const [floor, setfloor] = useState<any>();
	const [zone, setzone] = useState<any>();
	const [data, setdata] = useState<any>();
	const [loading, setloading] = useState(false)
	const [isUnRecVeh, setIsUnRecVeh] = useState('0')
	// const [scaleVal, setScaleVal] = useState(null)

	// const culminateObj = (id : string, obj : any, arr : any) =>{
	// 	console.log('HEATMAPDATAculminateObj ID',id,'obj',obj,'arr',arr)
	// 	let newObj = obj ?? {...arr}
	// 	let dyz = moment(date[1]).diff(date[0],'days')+1
	// 	newObj['totalCountByday'] = obj ? obj['totalCountByday'] + arr['totalCountByday'] : arr['totalCountByday']
	// 	newObj['occupaidCount'] = obj ? obj['occupaidCount'] + arr['occupaidCount'] : arr['occupaidCount']
	// 	newObj['occupancyPerc'] = obj ? obj['occupancyPerc'] + arr['occupancyPerc'] : arr['occupancyPerc']

	// 	newObj['occupancyPerc'] = ( ((newObj['occupaidCount'])) /(newObj['totalCountByday']*dyz))*100
	// 	console.log('HEATMAPDATANEWOBJ',newObj)
	// 	return newObj
	// }

	useEffect(() => {
		setfloor(DASHBOARD_DATA?.FLOOR_LIST&&DASHBOARD_DATA?.FLOOR_LIST[0]);
	}, [DASHBOARD_DATA?.FLOOR_LIST]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (DASHBOARD_DATA?.SELECTED_SITE?.value && floor?.levelID) {
			FetchZonesByLevel(DASHBOARD_DATA?.SELECTED_SITE?.value, floor?.levelID, signal).then((response) => {
				if (response?.statusCode === 200) {
					let data = response?.response ?? [];
					dispatch(UPDATE_DASHBOARD_FLOOR_ZONE_LIST({ data }));
				}
			});
		}
		return () => {
			controller.abort();
		};
	}, [dispatch, DASHBOARD_DATA?.SELECTED_SITE?.value, floor?.levelID]);


	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			'Type': 2,
			FloorID: floor?.levelID,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			FromTime: date[0].format('00:00:00'),
			ToTime: date[1].format('23:59:59'),
			ZoneID :zone?.value??0,
			UnknownVehicle : isUnRecVeh
		};
		if (floor?.levelID) {
			setloading(true)
		}

		fetch_occupancy_heatmap(postData, signal)?.then((res) => {
			if (res?.statusCode < 400) {
				let data = res?.response?.data ?? [];
				setTimeout(() => {
					setloading(false)
				}, 1500);
				// const size = 3;
				// const rest = data.reduce((acc, curr) => {
				// 	if (!(i % size)) {    // if index is 0 or can be divided by the `size`...
				// 		// acc.push(data.slice(i, i + size));   // ..push a chunk of the original array to the accumulator
				// 	}
				// 	return acc;
				// }, []);

				let sunday: number[] = [];
				let monday: number[] = [];
				let tuesday: number[] = [];
				let wednesday: number[] = [];
				let thursday: number[] = [];

				let friday: number[] = [];
				let saturday: number[] = [];

				// console.log('HEATMAPDATA=>',data,'formattedData=>',filterDuplicateObjArr(data, 'type', undefined, culminateObj))

				data.forEach((item: any) => {
					// let dyz = moment(date[1]).diff(date[0],'days')+1
					// let ocPerc = ( ((item?.occupaidCount)) /(item?.totalCountByday*dyz))
					// let val =	Math.round((ocPerc) * 100) / 100;
					let val = Math.round(item?.occupancyPerc)
					switch (item?.day_Name) {
						case 'Sunday':
							sunday.push(val);
							break;
						case 'Monday':
							monday.push(val);
							break;
						case 'Tuesday':
							tuesday.push(val);
							break;
						case 'Wednesday':
							wednesday.push(val);
							break;
						case 'Thursday':
							thursday.push(val);
							break;
						case 'Friday':
							friday.push(val);
							break;
						case 'Saturday':
							saturday.push(val);
							break;

						default:
							break;
					}
				});

				let fommattedArray = [sunday, monday, tuesday, wednesday, thursday, friday, saturday];

				setdata(fommattedArray);
			}
		});
	
		return () => {
			controller.abort();
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, date, zone, floor, isUnRecVeh]);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};
	return (
		<div className='ItemLayout__Wrapper'>
			<div className='ItemLayout__Title'>
				<div>{props.name} {isUnRecVeh==='2' ? '(Registered Vehicles Only)' : isUnRecVeh==='1' ? '(Unregistered Vehicles Only)' : ''} of 
				{floor&&floor?.levelName}{zone&&zone?.label ? ` / ${zone?.label}` : ''}</div>

				<div>
				<div style={{ fontSize: '12px' }}>
							{date[0].format('DD MMM YYYY')} - {date[1].format('DD MMM YYYY')}
						</div>
					<DashboardFilterWrapper ref={childRef}  loading={loading} handleClose={handleClose}>
						<div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Date Range</label>
							<RangePicker size='large'  inputReadOnly onChange={(date: any) => setdate(date)} value={date} allowClear={false} />
						</div>
						<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Floor</label>
									<ReactSelect
										className='common-select-grey'
										options={DASHBOARD_DATA?.FLOOR_LIST}
										getOptionLabel={(o: any) => o.levelName}
										getOptionValue={(o: any) => o.levelID}
										onChange={(e: any) =>{ setfloor(e); setzone(0); }}
										value={
											DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''
										}
										placeholder='Floor'
									/>
								</div>
						<div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Zone</label>
							<ReactSelect
								className='common-select-grey'
								options={DASHBOARD_DATA?.FLOOR_ZONE_LIST}
								onChange={(e: any) => setzone(e)}
								value={DASHBOARD_DATA?.FLOOR_ZONE_LIST?.find((c: { value: number }) => c.value === zone?.value) ?? ''}
								placeholder='Zone'
							/>
						</div>
						<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Vehicle Type</label>
									<ReactSelect
										className='common-select-grey'
										options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								onChange={(val:any) =>{ setIsUnRecVeh(val?.value); }}
										value={
											[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]?.find((el:any) => el.value===isUnRecVeh) ?? ''
										}
										placeholder={'Vehicle Type'}
									/>
								</div>
						
					</DashboardFilterWrapper>
				</div>
			</div>
			<div className='ItemLayout__Content'>
				<div style={{ fontSize: '13px' }} className='heatmap-wrapper'>
					{data ? (
						<>
							<HeatMap
								xLabels={x}
								yLabels={yLabels}
								data={data}
								xLabelsLocation={'bottom'}
								xLabelWidth={10}
								unit={'%'}
								cellStyle={(background: any, value:number, min: number, max: number, data: any, x: any, y: any) => {
									return {
										// background:
										// 	value < 90
										// 		? `rgb(0, 151, 230, ${1 - (max - value) / (max - min)})`
										// 		: value > 95
										// 		? 'rgb(220,20,60)'
										// 			? value > 90 && value < 95
										// 			: 'rgb(255,160,122)'
										// 		: '',

										background: findCellColr(value),
										// value===100 ? `rgba(255,0,0,1)` : `rgba(0, 151, 230 , ${value /100})`,
										fontSize: '11.5px',
										color: '#444',
										position:'relative',
										border: '.2px solid var(--lightgrey)',
									};
								}}
								// cellRender={(value:any) => {
								// 	return <div style={{ height:'100%', width:'100%', position:'absolute' }} 
								// 		onMouseEnter={()=>{ setScaleVal(value) }}
								// 		onMouseLeave={()=>{ setScaleVal(null) }}
								// 	></div>
								// }}
								height={'35px'}
								xLabelsVisibility={[
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
									true,
									false,
								]}
							/>
							
						</>
					) : (
						<div style={{ width :"100%" , height:"100%" , display:"flex" , alignItems:"center" , justifyContent:"center"}}>
							<CircularProgress size={50} color='inherit' />
						</div>
					)}
					
				</div>
					{
						data &&
						<div className='heatMapColorGuide' style={{ position:'relative' }}>
							{/* {
								scaleVal!==null &&
								<div className='heatMapColorGuidePointerDiv' style={{ width:`${scaleVal}%` }}>
									<span></span>
								</div>
							} */}
							
							<div className='heatMapColorColumn' style={{ width:'0.1%' }}>
								<div className='heatMapColorStrip'
									style={{
											background : `linear-gradient(90deg, rgba(0,151,230,0) 0%, rgba(34,186,210,1) 100%)`,
											// outline : `black solid ${scaleVal===0 ? '1px' : '0px'}`
									}}
									></div>
									<div className='heatMapColorStripGrade'>
										<div>{0}</div>
									</div>
							</div>
							{
								colStrip?.map((el:any, k:number) => {
									console.log('COLstrip=>',`background: linear-gradient(90deg, ${k===0 ? '' : `rgba(${colStrip[k-1]?.col}) 50%, `} ${`rgba(${el?.col}) 100% `})`)
									return(			
										<div className='heatMapColorColumn' style={{ width:'8.33%' }}>
											<div className='heatMapColorStrip'
												style={{
													
background: `linear-gradient(90deg, ${k===0 ? `${el?.col}` : `${colStrip[k-1]?.col}`} 50%, ${`${el?.col} 100% `})`
	
	// rgba(34,186,210, ${el/100}) 2%, rgba(17,190,189, ${el/100}) 5%, 
	// rgba(10,183,140, ${el/100}) 10%, rgba(1,181,84, ${el/100}) 19%, 
	// rgba(37,179,41, ${el/100}) 25%, rgba(145,180,0, ${el/100}) 35%, 
	// rgba(214,178,2, ${el/100}) 44%, rgba(220,165,0, ${el/100}) 59%, 
	// rgba(218,152,0, ${el/100}) 69%, rgba(228,131,0, ${el/100}) 79%, 
	// rgba(235,42,51, ${el/100}) 89%, rgba(255,0,0, ${el/100}) 100%)
	
	
													//background: `linear-gradient(90deg, rgba(0,151,230,${(el-20)/100}) 0%, rgba(0,151,230,${el/100}) 95%, ${el===100 ? 'rgba(255,0,0,1)' : `rgba(0,151,230,${el/100})`} 95%)`,
													// outline : `black solid ${ scaleVal && scaleVal<=el ? '1px' : '0px'}`
													// `linear-gradient(90deg, rgba(0,151,230,${(el-20)/100}) 0%, rgba(0,151,230,${el/100}) 100%)`
												}}
											></div>
											<div className='heatMapColorStripGrade'>
												<div>{el?.val}</div>
											</div>
										</div>
									)
								})
							}
							{/* <div className='heatMapColorStrip-40'></div>
							<div className='heatMapColorStripGrade'>
								<div>40</div>
							</div>
							<div className='heatMapColorStrip-60'></div>
							<div className='heatMapColorStripGrade'>
								<div>60</div>
							</div>
							<div className='heatMapColorStrip-80'></div>
							<div className='heatMapColorStripGrade'>
								<div>80</div>
							</div>
							<div className='heatMapColorStrip-100'></div>
							<div className='heatMapColorStripGrade'>
								<div>100</div>
							</div> */}
						</div>
					}
			</div>
		</div>
	);
};

export default HeatMapIndex;


// background: rgb(34,186,210);
// background: linear-gradient(90deg, rgba(34,186,210,1) 2%, rgba(17,190,189,1) 5%, rgba(10,183,140,1) 10%, rgba(1,181,84,1) 19%, rgba(37,179,41,1) 25%, rgba(145,180,0,1) 35%, rgba(214,178,2,1) 44%, rgba(220,165,0,1) 59%, rgba(218,152,0,1) 69%, rgba(228,131,0,1) 79%, rgba(235,42,51,1) 89%, rgba(255,0,0,1) 100%);