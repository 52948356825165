import Map from './components/Map';
import MainLayout from '../../../../common/layouts/MainLayout';

type Props = {};

const RealtimeMonitor = (props: Props) => {
	return (
		<div className='main-body-content'>
			<MainLayout>
				<Map />
			</MainLayout>
		</div>
	);
};

export default RealtimeMonitor;
