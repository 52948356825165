import { DatePicker, TimePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react'
import ReactSelect from 'react-select';
// import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_visit_report, fetch_visit_report_plate_freq } from '../../../store/action';
import { fetch_BayTypeDDL, fetch_zoneDDL } from '../../../../../common/general/action';
import { SwapOutlined } from '@ant-design/icons'
import QueryBuilderModal from '../../../../../common/components/query_builder';
import { ExtendedField } from '../../../../../config/filterSearch';

const { RangePicker } = DatePicker;

type Props = {
	tabVal:any;
}

const FilterBoxBar = (props: Props) => {
    
	const dispatch = useAppDispatch();

    const GENERAL = useAppSelector((state:any)=>state?.general)
	const VISITS_REPORTS_DATA = useAppSelector((state:any) => state?.reportData);
	const [dateRange, setDateRange] = useState<any>([moment(new Date(),'YYYY-MM-DD').subtract(7,'day'),moment(new Date(),'YYYY-MM-DD')]);
    const [timeRange, setTimeRange] = useState<any>([moment('00:00:00','HH:mm:ss'), moment('23:59:59','HH:mm:ss')]);
    const [visitTimeRange, setVisitTimeRange] = useState<any>([0,0]);
    const [bayType, setBayType] = useState([])
    const [selZone, setSelZone] = useState([]);
	const [siteVal, setSiteVal] = useState<any>(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.siteID ? VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.siteID : GENERAL?.SITE_DDL[0]?.value)

	const [advanceModal, setAdvanceModal] = useState(false);

	const [plateText, setPlateText] = useState('')
	const [plateTextPerc, setPlateTextPerc] = useState('')

	const [visitFromDate, setVisitFromDate] = useState<any>('')
	const [visitToDate, setVisitToDate] = useState<any>('')
	const [visitFromHour, setVisitFromHour] = useState<any>('')
	const [visitToHour, setVisitToHour] = useState<any>('')
	const [visitFromMinute, setVisitFromMinute] = useState<any>('')
	const [visitToMinute, setVisitToMinute] = useState<any>('')

	const [filterActive, setFilterActive] = useState(false)

	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);

	const [advFilterBoxObj, setAdvFilterBoxObj] = useState<any>(null)
	const [avgDwellTime, setAvgDwellTime] = useState(moment('00:00:00','HH:mm;ss'))
	const [visitCount, setVisitCount] = useState('')

	const [onGoing, setOnGoing] = useState<any>('');

	useEffect(() => {
		if(siteVal){
			fetch_BayTypeDDL(dispatch, siteVal);
	  		fetch_zoneDDL(dispatch, { 'query' : `fK_SiteID=${siteVal}` });
		}else{
			fetch_BayTypeDDL(dispatch)
		}
	  
	}, [dispatch, siteVal ])

	useEffect(() => {
		clearSearch()
	}, [props?.tabVal])
	
	const convTimeFn = (val:any) =>{
		return moment.duration(val).asMinutes()
	}

	const fields: ExtendedField[] = 
	props?.tabVal===1 ?
	[
		// { name : "visitID", label: "Visit ID", inputType: 'number'},
		{ name : "CAST (InTime as date)",	label : "Entry Date",	inputType: 'date' },
		{ name : "CAST (OutTime as date)",	label : "Exit Date",	inputType: 'date' },

		// { name : 'CAST (ParkingDate AS date)', label: 'Parking Date', inputType: 'date' },
		// { name: 'brand', label: 'Brand'},
		// { name: 'color', label: 'Color'},

		// { name : "dwellTime",		label : "Dwell Time",		inputType: 'time' },//TODO
		{ name : "duration",		label : "Duration in Minutes",			inputType: 'time',	valueFn: convTimeFn  },
		{ name : "PlateNumber",		label : "Plate Number" },

		{ name: 'vehicleCategory', label: 'Vehicle Category' },
		{ name: 'placeOfRegistration', label: 'Place of Registration' },

		{ name : "zoneName",		label : "Zone Name" },
		{ name : "bayName",			label : "Bay Name" },

		// { name : "levelName",			label : "Level Name" },

		{ name : "bTypeName",		label : "Bay Type Name" },
		// { name : "matchPercentage", label : "Match Percentage",	inputType: 'number' },

		{ name: 'fullName', label: 'Full Name'},
		{ name: 'emailID', label: 'Email ID'},
		{ name: 'contactNo', label: 'Contact No'},
		{ name: 'depName', label: 'Department'},
		{ name: 'desName', label: 'Designation'},
		{ name: 'FK_PriorityLevel', label: 'Personal Grade', inputType:'number' },

		{ name: 'FK_UserType', label: 'User Type' , inputType:"select", values:[ 
			{ name : '1', label : 'Employee'},
			{ name : '2', label : 'Visitor' }
		]},
		// { name: "OutTime", label: "On-going Visits ( use 'is null' to check for vehicles that didn't exited and 'is not null' for exited  ) "}
	]
	:
	[
		// { name : "avarageDwellTime",		label : "Average Dwell Time",		inputType: 'time' },
		// { name : "duration",				label : "Duration",			inputType: 'time',	valueFn: convTimeFn  },

		{ name : "plateNumber",			label : "Plate Text" },
		{ name: 'VehicleCategory', label: 'Vehicle Category' },
		{ name: 'PlaceOfRegistration', label: 'Place of Registration' },
		
		// { name : "visitCount",				label : "Visit Count",	 	inputType: 'number'},

		{ name: 'FullName', label: 'Full Name'},
		{ name: 'EmailID', label: 'Email ID'},
		{ name: 'contactNo', label: 'Contact No'},
		{ name: 'DepName', label: 'Department'},
		{ name: 'DesName', label: 'Designation'},
		{ name: 'FK_PriorityLevel', label: 'Personal Grade', inputType:'number' },
		{ name: 'FK_UserType', label: 'User Type' , inputType:"select", values:[ 
			{ name : '1', label : 'Employee'},
			{ name : '2', label : 'Visitor' }
		]},
		{ name : "SiteName",			label : "Site Name" }
	]

	const deployQueryFilter = (query: string) => {
		setFilterActive(true);

		setAdvFilterBoxObj({
			'query': `${query} ${ props?.tabVal===1 ? `${query!=='' && onGoing!=='' ? ` AND ` : ''}${onGoing}` : ''}`,
		})

		let visitDurationTimePeriod = visitTimeRange&&visitTimeRange?.length > 0 ? visitTimeRange?.sort() : []
		let postdata = {
			siteID		:	siteVal,	
			toDate 		:	dateRange&&dateRange?.length>0 ? moment(dateRange[1]).format('YYYY-MM-DD') : 
				moment(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toDate,['YYYY-MM-DD'],true)?.isValid() ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
			fromDate    :  dateRange&&dateRange?.length>0 ? moment(dateRange[0]).format('YYYY-MM-DD') : 
				moment(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromDate,['YYYY-MM-DD'],true)?.isValid() ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromDate).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),		
			fromTime    :   timeRange&&timeRange?.length>0 && moment(timeRange[0],['HH:mm:ss','hh:mm a'],true).isValid() ? moment(timeRange[0]).format('HH:mm:ss') :
				VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromTime ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromTime).format('HH:mm:ss') : '00:00:00',
			toTime      :	timeRange&&timeRange?.length>0 && moment(timeRange[1],['HH:mm:ss','hh:mm a'],true).isValid() ? moment(timeRange[1]).format('HH:mm:ss') :   
				VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toTime ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toTime).format('HH:mm:ss') :  '23:59:00',		
			zoneIDs 	:	selZone&&selZone?.length>0 ? selZone?.map((el:any) => el?.value)?.toString() : "",			
			bayTypeIDs	:	bayType&&bayType?.length>0 ? bayType?.map((el:any) => el?.value)?.toString() : "",		
			visitDurationFrom	: visitDurationTimePeriod&&visitDurationTimePeriod?.length > 0 ?
				// moment.duration(moment(visitDurationTimePeriod[0],['HH:mm','HH:mm:ss','hh:mm a']).format('HH:mm')).asMinutes()
				visitDurationTimePeriod[0] 
				: 0,		
			visitDurationTo		: visitDurationTimePeriod&&visitDurationTimePeriod?.length > 0 ?
				// moment.duration(moment(visitDurationTimePeriod[1],['HH:mm','HH:mm:ss','hh:mm a']).format('HH:mm')).asMinutes() 
				visitDurationTimePeriod[1]
				: 0,		
			plateTextSearch		: plateText
		}
		if(plateTextPerc!=='' && !isNaN(parseFloat(plateTextPerc))){
			let tempPostData = { ...postdata, 
				matchPercentage : parseFloat(plateTextPerc) }
			postdata = { ...tempPostData}
		}

		let postdata2 = {
			...postdata,
			'query': `${query} ${ props?.tabVal===1 ? `${query!=='' && onGoing!=='' ? ` AND ` : ''}${onGoing}` : ''}`,
		};
		
		if(props?.tabVal===1){
			fetch_visit_report('', dispatch, {
				'filters': {
					...postdata2,
					UserType		:	0,
					Ongoinvisits	:	""
				},
				'pagination': {
					'pageNo': 1,
					'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
				},
			})
		}else{
			let avgObj = moment(avgDwellTime).get('hour')===0 && moment(avgDwellTime).get('minute')===0 ?
			 		{}	:
			 		{
			 			AvarageDwellTime : moment.duration({
			 					hour : moment(avgDwellTime).get('hour'),
			 					minute : moment(avgDwellTime).get('minute')
			 				}).asMinutes()
			 		}
			//let avgObj = moment(avgDwellTime).get('hour')===0 && moment(avgDwellTime).get('minute')===0 ?
			//		{}	:
			//		{
			//			AvarageDwellTime : moment(avgDwellTime).format('HH:mm')
			//		}
			let visitObj = isNaN(parseInt(visitCount)) ? {} : { VisitCount : visitCount }
			fetch_visit_report_plate_freq('', dispatch, {
				'filters': 
				{
					...postdata2,
					...avgObj,
					...visitObj
				},
				'pagination': {
					'pageNo': 1,
					'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
				},
			})
		}

		advancemodalClose()
	};

	
	const clearSearch = () => {
		setFilterActive(false)
		let postdata = {
			siteID		:	GENERAL?.SITE_DDL[0]?.value,	
			toDate 		:	dayjs().format('YYYY-MM-DD'),
			fromDate    :   dayjs().subtract(7,'day').format('YYYY-MM-DD'),		
			fromTime    :   '00:00:00',
			toTime      :	'23:59:00',		
			zoneIDs 	:	"",			
			bayTypeIDs	:	"",		
			visitDurationFrom	: 0,		
			visitDurationTo		: 0,		
			plateTextSearch		: '',
		}
		
		if(props?.tabVal===1){
			fetch_visit_report('', dispatch, {
				'filters': {...postdata,
					UserType		:	0,
        			Ongoinvisits	:	"",
        			query			:	""
				},
				'pagination': {
					'pageNo': 1,
					'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
				},
			})
		}else{
			fetch_visit_report_plate_freq('', dispatch, {
				'filters': {
					...postdata,
					// VisitCount : '',
					// AvarageDwellTime : ''
				},
				'pagination': {
					'pageNo': 1,
					'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
				},
			})
		}
		setTimeRange([moment('00:00:00','HH:mm:ss'), moment('23:59:59','HH:mm:ss')]);
		setBayType([])
		setDateRange([moment(new Date(),'YYYY-MM-DD').subtract(7,'day'),moment(new Date(),'YYYY-MM-DD')]);
		setSelZone([])
		setSiteVal(GENERAL?.SITE_DDL[0]?.value)
		setPlateText('')
		setVisitTimeRange([0,0])
		setVisitFromDate(''); setVisitFromHour(''); setVisitFromMinute('');
		setVisitToDate(''); setVisitToHour(''); setVisitToMinute(''); setPlateTextPerc('')
		setAdvFilterBoxObj(null)
		advancemodalClose()
		setVisitCount('')
		setAvgDwellTime(moment('00:00:00','HH:mm:ss'))
	};

	// console.log('VisitCount',visitCount,parseInt(visitCount),isNaN(parseInt(visitCount)))
	// console.log('AvgDwell',avgDwellTime,moment(avgDwellTime).get('hour'),moment(avgDwellTime).get('minute'),moment.duration({
	// 	hour : moment(avgDwellTime).get('hour'),
	// 	minute : moment(avgDwellTime).get('minute')
	// }).asMinutes())

	const filterSearch = () =>{
		setFilterActive(true)
		console.log('ONGOING=>',onGoing)
		let visitDurationTimePeriod = visitTimeRange&&visitTimeRange?.length > 0 ? visitTimeRange?.sort() : []
		let postdata = {
			siteID		:	siteVal,	
			toDate 		:	dateRange&&dateRange?.length>0 ? moment(dateRange[1]).format('YYYY-MM-DD') : 
				moment(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toDate,['YYYY-MM-DD'],true)?.isValid() ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
			fromDate    :  dateRange&&dateRange?.length>0 ? moment(dateRange[0]).format('YYYY-MM-DD') : 
				moment(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromDate,['YYYY-MM-DD'],true)?.isValid() ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromDate).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),		
			fromTime    :   timeRange&&timeRange?.length>0 && moment(timeRange[0],['HH:mm:ss','hh:mm a'],true).isValid() ? moment(timeRange[0]).format('HH:mm:ss') :
				VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromTime ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromTime).format('HH:mm:ss') : '00:00:00',
			toTime      :	timeRange&&timeRange?.length>0 && moment(timeRange[1],['HH:mm:ss','hh:mm a'],true).isValid() ? moment(timeRange[1]).format('HH:mm:ss') :   
				VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toTime ? dayjs(VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toTime).format('HH:mm:ss') :  '23:59:00',		
			zoneIDs 	:	selZone&&selZone?.length>0 ? selZone?.map((el:any) => el?.value)?.toString() : "",			
			bayTypeIDs	:	bayType&&bayType?.length>0 ? bayType?.map((el:any) => el?.value)?.toString() : "",		
			visitDurationFrom	: visitDurationTimePeriod&&visitDurationTimePeriod?.length > 0 ?
				// moment.duration(moment(visitDurationTimePeriod[0],['HH:mm','HH:mm:ss','hh:mm a']).format('HH:mm')).asMinutes()
				visitDurationTimePeriod[0] 
				: 0,		
			visitDurationTo		: visitDurationTimePeriod&&visitDurationTimePeriod?.length > 0 ?
				// moment.duration(moment(visitDurationTimePeriod[1],['HH:mm','HH:mm:ss','hh:mm a']).format('HH:mm')).asMinutes() 
				visitDurationTimePeriod[1]
				: 0,		
			plateTextSearch		: plateText
		}
		if(plateTextPerc!=='' && !isNaN(parseFloat(plateTextPerc))){
			let tempPostData = { ...postdata, 
				matchPercentage : parseFloat(plateTextPerc) }
			postdata = { ...tempPostData}
		}
		
		console.log('POSTDATA',postdata)
		if(props?.tabVal===1){
			fetch_visit_report('', dispatch, {
				'filters': advFilterBoxObj ? 
					{ 
						...advFilterBoxObj, 
						...postdata,
						UserType		:	0,
						Ongoinvisits	:	""
					} : 
					{
						...postdata,
						UserType		:	0,
						Ongoinvisits	:	"",
						query			:	`${onGoing}`
					},
				'pagination': {
					'pageNo': 1,
					'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
				},
			})
		}else{
			let avgObj = moment(avgDwellTime).get('hour')===0 && moment(avgDwellTime).get('minute')===0 ?
			 		{}	:
			 		{
			 			AvarageDwellTime : moment.duration({
			 					hour : moment(avgDwellTime).get('hour'),
			 					minute : moment(avgDwellTime).get('minute')
			 				}).asMinutes()
			 		}
			//let avgObj = moment(avgDwellTime).get('hour')===0 && moment(avgDwellTime).get('minute')===0 ?
			//		{}	:
			//		{
			//			AvarageDwellTime : moment(avgDwellTime).format('HH:mm')
			//		}
			let visitObj = isNaN(parseInt(visitCount)) ? {} : { VisitCount : visitCount }
			fetch_visit_report_plate_freq('', dispatch, {
				'filters': advFilterBoxObj ?	
							{ 
								...advFilterBoxObj, 
								...postdata,
								...avgObj,
								...visitObj
							}
							:
							{
								...postdata,
								...avgObj,
								...visitObj
							},
				'pagination': {
					'pageNo': 1,
					'limit': VISITS_REPORTS_DATA?.USER_REPORTS_PAGINATION?.limit,
				},
			})
		}
	}
	// const setLevelSite = (val:any) => {
	// 	console.log('VALlevel=>',val)
	// 	if(val?.site)
	// 		setSiteVal(val?.site)
	// }

	const dateReshuffle = ({ v=[], 
		fD=visitFromDate, fH=visitFromHour, fM=visitFromMinute,
		tD=visitToDate, tH=visitToHour, tM=visitToMinute }) =>{
		if(v && v?.length===2){
			let d1 = v[0]
			let d2 = v[1]
			console.log('d1',d1,'d2',d2)
			console.log('FRMDATE=>',fD,'H=>',fH,'M=>',fM)
			console.log('ToDATE=>',tD,'H=>',tH,'M=>',tM)

			let tempfH = fH==='' ? 0 : fH
			let tempfM = fM==='' ? 0 : fM

			let f1 = moment().set({ hour : tempfH, minute : tempfM })
			let t1 = moment().set({
				hour : tM==='' ? 0 : tM, 
				minute : tM==='' ? 0 : tM
			})

			if(d1 > d2 && d1!=='' && d2!=='' && !isNaN(d1) && !isNaN(d2)){

				let tempfD = fD==='' ? 0 : fD
				setVisitFromDate(tD==='' ? 0 : tD)
				setVisitToDate(tempfD)
				
				setVisitFromHour(t1)
				setVisitToHour(f1)
				
				setVisitFromMinute(t1)
				setVisitToMinute(f1)

				let vTm = v
				setVisitTimeRange([vTm[1], vTm[0]])
			}
			
		}
	}

  return (

    <div className="white-card data-clean mb-30">
		<div className="search-filter-row mb-0">
			<ul>
						<li>
							<a  className="active pointerDefaultSet"><i className="fa-solid fa-magnifying-glass"></i>  Quick Search</a>
						</li>
						<li>
							<a onClick={() => advancemodalOpen()}>
								<i className='fa-solid fa-filter'></i>Advanced Filter
							</a>
						</li>
						{filterActive && (
							<li>
								<a onClick={clearSearch}>
									<i className='fa-solid fa-times'></i>Clear All Filters
								</a>
							</li>
						)}
					</ul>
			<div className="filter-block" id="quick-search">
				<div className='row'>
                    <div className='col-3 mt-10'>
						<Tooltip title={'Entry-exit date range'}>
                        <RangePicker
						    picker='date'
						    allowClear={false}
						    allowEmpty={[false, false]}
						    className='form-control'
						    placeholder={['From Date', 'To Date']}
							inputReadOnly
						    value={dateRange}
						    format={'DD-MMM-YYYY'}
						    onChange={(dates: any) => {
							    setDateRange(dates);
						    }}
							separator={<SwapOutlined style={{ color : 'GrayText' }}/>}
					    />
						</Tooltip>
                    </div>
                    <div className='col-3 mt-10'>
					<Tooltip title={'Entry-exit time range'}>
                    <RangePicker
						picker='time'
						allowClear={false}
						allowEmpty={[false, false]}
						className='form-control'
						placeholder={['From Time', 'To Time']}
						inputReadOnly
						value={timeRange}
						format={'hh:mm a'}
						onChange={(dates: any) => {
							setTimeRange(dates);
						}}
						separator={<SwapOutlined style={{ color : 'GrayText' }}/>}
					/>
					</Tooltip>
                    </div>
					{/* <div className='col-6 mt-10 dflex flex-justify-between'> */}
					{
						props?.tabVal===1 &&
						<Tooltip title={'Dwell Duration From and To range'}  className='col-6 mt-10 dflex flex-justify-between'>
						<div className='form-group inline-input'>
							<input
								className='form-control '
								type='number'
								placeholder='Days'
								id=''
								value={visitFromDate}
								onChange={(e)=>{
									let val = e?.target?.value?.match(/^[0-9]+$/) ? parseInt(e?.target?.value) : 0
									// if(visitToDate===''){
									// 	setVisitToDate(val)
									// 	if(visitFromHour==='')
									// 		setVisitFromHour(moment())
									// 	if(visitFromMinute==='')
									// 		setVisitFromMinute(moment())
									// 	if(visitToHour==='')
									// 		setVisitToHour(moment())
									// 	if(visitToMinute==='')
									// 		setVisitToMinute(moment())
									// }
									setVisitFromDate(val)
									let v = visitTimeRange 
									v[0] = val*24*60
									
									setVisitTimeRange(v)
									dateReshuffle({v : v, fD : val})
								}}
							/>
							<TimePicker
								showMinute={false}
								showSecond={false}
								className='form-control '
								placeholder='Hours'
								format={'HH'}
								value={visitFromHour}
								onChange={(val:any)=>{
									let timeVal = moment(val).get('hours')
									
									setVisitFromHour(val)
									// if(visitFromMinute==='')
									// 		setVisitFromMinute(moment())
									// 	if(visitToHour==='')
									// 		setVisitToHour(moment())
									// 	if(visitToMinute==='')
									// 		setVisitToMinute(moment())
									let v = visitTimeRange
									let dVal = visitFromDate==='' ? 0 : visitFromDate
									v[0] = (timeVal*60) + (dVal*24*60)
									console.log('HOURVAL=>',val,timeVal,v,dVal)
									setVisitTimeRange(v)
									dateReshuffle({v : v, fH : timeVal})
								}}
							/>
							<TimePicker
								showHour={false}
								showSecond={false}
								className='form-control '
								placeholder='Minutes'
								format={'mm'}
								value={visitFromMinute}
								onChange={(val:any)=>{
									let timeVal = moment(val).get('minutes')
									setVisitFromMinute(val)
										// if(visitToHour==='')
										// 	setVisitToHour(moment())
										// if(visitToMinute==='')
										// 	setVisitToMinute(moment())
									let v = visitTimeRange 
									let dVal = visitFromDate==='' ? 0 : visitFromDate
									v[0] = timeVal + moment(visitFromHour).get('hours') + (dVal*24*60)
									
									setVisitTimeRange(v)
									dateReshuffle({v : v, fM : timeVal})
								}}
							/>
						</div>
						<SwapOutlined style={{ display:'grid', placeItems:'center', marginBottom:'15px', backgroundColor:'white', color : 'GrayText' }}/>
						<div className='form-group inline-input'>
							<input
								className='form-control '
								type='number'
								placeholder='Days'
								min={0}
								id=''
								value={visitToDate}
								onChange={(e)=>{
									let val = e?.target?.value?.match(/^[0-9]+$/) ? parseInt(e?.target?.value) : 0
									// if(val < visitFromDate){
									// 	setVisitToDate(visitFromDate)
									// 	setVisitFromDate(val)
									// }else{
										setVisitToDate(val)
									// }
									// if(visitFromDate===''){
									// 	setVisitFromDate(val)
									// 	if(visitFromHour==='')
									// 		setVisitFromHour(moment())
									// 	if(visitFromMinute==='')
									// 		setVisitFromMinute(moment())
									// 	if(visitToHour==='')
									// 		setVisitToHour(moment())
									// 	if(visitToMinute==='')
									// 		setVisitToMinute(moment())
									// }
									let v = visitTimeRange 
									v[1] = val*24*60 
									setVisitTimeRange(v)
									dateReshuffle({v : v, tD : val})
								}}
							/>
							
							<TimePicker
								showMinute={false}
								showSecond={false}
								className='form-control '
								placeholder='Hours'
								format={'HH'}
								value={visitToHour}
								onChange={(val:any)=>{
									let timeVal = moment(val).get('hours')
									setVisitToHour(val)
									// if(visitFromHour==='')
									// 		setVisitFromHour(moment())
									// 	if(visitFromMinute==='')
									// 		setVisitFromMinute(moment())
										
									// 	if(visitToMinute==='')
									// 		setVisitToMinute(moment())
									let v = visitTimeRange 
									let dVal = visitToDate==='' ? 0 : visitToDate
									v[1] = (timeVal*60) + (dVal*24*60)
									setVisitTimeRange(v)
									dateReshuffle({v : v, tH : timeVal})
								}}
							/>
							<TimePicker
								showHour={false}
								showSecond={false}
								className='form-control '
								placeholder='Minutes'
								format={'mm'}
								value={visitToMinute}
								onChange={(val:any)=>{
									let timeVal = moment(val).get('minutes')
									setVisitToMinute(val)
									// if(visitFromHour==='')
									// 		setVisitFromHour(moment())
									// 	if(visitFromMinute==='')
									// 		setVisitFromMinute(moment())
									// 	if(visitToHour==='')
									// 		setVisitToHour(moment())
										
									let v = visitTimeRange 
									let dVal = visitToDate==='' ? 0 : visitToDate
									v[1] = timeVal + moment(visitToHour).get('hours') + (dVal*24*60)
									
									setVisitTimeRange(v)
									dateReshuffle({v : v, tM : timeVal})
								}}
							/>
							
						</div>
						</Tooltip>
					}
				
					{/* </div> */}
                    
					<div className='col-3 mt-10'>
					<Tooltip title={'Enter plate text to search'}>
						<div className='form-group'>
							<div className='pos-rel'>
								<input
									type='text'
									name=''
									placeholder='Search Plate'
									className='form-control'
									value={plateText}
									onChange={(e) => setPlateText(e.target.value)}
								/>
								<i className='fa-solid fa-magnifying-glass'></i>
							</div>
						</div>
						</Tooltip>
					</div>
					<div className='col-2 mt-10'>
					<Tooltip title={<p>Enter the amount of similarity <i>(in percentage)</i>, the results needed to be with the text mentioned in <strong>'Search Plate'</strong></p>}>
						<div className='form-group'>
							<div className='pos-rel'>
								<input
									type='text'
									name=''
									placeholder='Search Plate Percentage'
									className='form-control'
									value={plateTextPerc}
									onChange={(e) =>{
										console.log('Enter=>',e.target.value,'test(e.target.value)',/^\d+(\.\d+)?$/.test(e.target.value))

										if(/^\d+(\.\d*)?$/.test(e.target.value) || 
										e.target.value==='')
											setPlateTextPerc(e.target.value)
										}}
								/>
								{/* <i className='fa-solid fa-percentage'></i> */}
							</div>
						</div>
						</Tooltip>
					</div>
					<Tooltip title={'Filter by Site'}>
					<div className='col-3 mt-10'>
					
                        <ReactSelect
                            className={'common-select'}
                            placeholder={'Select Site'}
                            options={GENERAL?.SITE_DDL}
							isMulti={false}
							value={siteVal ? GENERAL.SITE_DDL.find((c: { value: number; }) => c.value === parseInt(siteVal)) : null}
							isClearable={false}
                            onChange={(e:any)=>{ setSiteVal(e?.value);
								fetch_BayTypeDDL(dispatch, e?.value);
								fetch_zoneDDL(dispatch, { 'query' : `fK_SiteID=${e?.value}` })
							}}
                        />
                    </div>
					</Tooltip>
					<Tooltip title={'Filter by Zone'}>
					<div className={`col-3 mt-10 ${siteVal ? '' : 'disableAndOpacity2'}`}>
					
                        <ReactSelect
                            className={'common-select'}
                            placeholder={'Select Zones'}
                            options={GENERAL?.ZONE_DDL}
							isMulti
							isClearable
							value={selZone && selZone?.length>0 ? 
								selZone?.map((el:any) => el) 
								: null}
                            onChange={(e:any)=>{ setSelZone(e) }}
                        />
                    </div>
						</Tooltip>
                    {/* <div className='col-5 mt-10'>
                        <TreeSelectCustom
                            isMulti
                            selectedZones={selZone}	setselectedZones={setSelZone}
							showAll={setLevelSite}
                        />
                    </div> */}
                    <Tooltip title={'Filter by Bay Type'}>
					<div className='col-3 mt-10'>
					
                        <ReactSelect
                            className={'common-select'}
                            placeholder={'Select Bay Types'}
                            options={GENERAL?.BAYTYPE_DDL}
							isMulti
							isClearable
							value={bayType && bayType?.length>0 ?
								bayType?.map((el:any) => el)
								:	null}
                            onChange={(e:any)=>{ setBayType(e) }}
                        />
                    </div>
						</Tooltip>
                    {
						props?.tabVal!==1 &&
						<>
							<Tooltip title={'Filter by Average Dwell Time'}>
								<div className='col-1 mt-10'>
									<TimePicker
										showSecond={false}
										className='form-control '
										placeholder='Average Dwell Time'
										format={'HH:mm'}
										value={avgDwellTime}
										onChange={(val:any)=>{
											setAvgDwellTime(val)
										}}
									/>
								</div>
							</Tooltip>
							<div className='col-2 mt-10'>
								<Tooltip title={'Enter Visit Count to search'}>
									<div className='form-group'>
										<div className='pos-rel'>
											<input
												type='text'
												name=''
												placeholder='Visit Count'
												className='form-control'
												value={visitCount}
												onChange={(e) =>{
													if(/^\d+(\.\d*)?$/.test(e.target.value) || e.target.value==='')
														setVisitCount(e.target.value)
												}}
											/>
											<i className='fa-solid fa-user-group'>
												<span style={{ fontSize : '10px', fontWeight : 'bolder' }}>123</span>
											</i>
										</div>
									</div>
								</Tooltip>
							</div>
							
							
						</>
					}	
						{
								props?.tabVal===1 &&
								<div className='col-2 mt-10'>
							<div className='form-group'>
								<ReactSelect
									options={[
										{ value : '(OutTime is not null)', label : 'Show visits that are over'  },
										{ value : '(OutTime is null)', label : 'Show visits that are not over'  },
										{ value : '', label : 'Show all visits'  }
									]}
									className={'common-select'}
									placeholder={'Show all visits'}
									isMulti={false}
									value={[
										{ value : '(OutTime is not null)', label : 'Show visits that are over'  },
										{ value : '(OutTime is null)', label : 'Show visits that are not over'  },
										{ value : '', label : 'Show all visits'  }
									]?.find((c) => c.value === onGoing) ?? ''}
									onChange={(val: any) => {
										setOnGoing(val?.value);
									}}
								/>
							</div>
						</div>
							}
                    <div className='col-3 mt-10'>
					<div className="form-group" onClick={()=>{ filterSearch() }}>
                    	<button className="btn btn-secondary">Generate Report</button>
                	</div>
				</div>
                </div>
            </div>
        </div> 

		<QueryBuilderModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />

    </div>

//     <div className="table-details white-table-details" style={{ overflow:'unset' }}>
//     <div>
//        <div className="border-title-row flex-1">
//           <h2 className="border-title">Filters</h2>
//        </div>
//        <form>

//            <div className="form-group form-w-label mb-20">
//               <label >Date range</label>
//               <div className="row">
//                     <RangePicker
// 						picker='date'
// 						allowClear
// 						allowEmpty={[true, true]}
// 						className='form-control grey-input'
// 						placeholder={['From Date', 'To Date']}
// 						separator
// 						value={dateRange}
// 						format={'DD-MMM-YYYY'}
// 						onChange={(dates: any) => {
// 							setDateRange(dates);
// 						}}
// 					/>
//               </div>
//           </div>

//           <div className="form-group form-w-label mb-20">
//               <label >Time range</label>
//               <div className="row">
//                     <RangePicker
// 						picker='time'
// 						allowClear
// 						allowEmpty={[true, true]}
// 						className='form-control grey-input w-100'
// 						placeholder={['From Time', 'To Time']}
// 						separator
// 						value={timeRange}
// 						format={'hh:mm a'}
// 						onChange={(dates: any) => {
// 							setTimeRange(dates);
// 						}}
// 					/>
//               </div>
//           </div>

//           <div className="form-group form-w-label mb-20">
//               <label >Zone name</label>
//               <div className='row'>
//               <TreeSelectCustom
//                     grey
//                     styleSx={{ maxWidth:'unset' }}
//                     classnames={'w-100'}
//                     selectedZones={selZone}	setselectedZones={setSelZone}
//               />
//               </div>
              
//           </div>

//           <div className="form-group form-w-label mb-20">
//               <label >Bay type name</label>
//               <div className='row'>
//               <ReactSelect
//                 className={'common-select col-12 common-select-grey'}
//                 placeholder={'Select Bay Type'}
//                 options={GENERAL?.BAYTYPE_DDL}
//                 onChange={(e)=> setBayType(e?.value)}
//               />
              
//               </div>
//               {/* <input type="text" name="" placeholder="" className="form-control grey-input"/> */}
//           </div>

//           <div className="form-group form-w-label mb-20">
//               <label >Visit duration</label>
//               <div className="row align-items-center">
//                     <div className='col-5'>
//                         <TimePicker
//                             className='col-4 form-control grey-input'
//                             onChange={()=>{}}
//                             placeholder="Start Time"
//                         />
//                     </div>
                    
//                   {/* <div className="col-4">
//                       <input type="text" name="" placeholder="" className="form-control grey-input"/>
//                   </div> */}
//                   <div className="col-1 text-center"><label className="mb-0">To</label></div>
//                   <div className='col-5'>
//                         <TimePicker
//                             className='col-4 form-control grey-input'
//                             onChange={()=>{}}
//                             placeholder="End Time"
//                         />
//                     </div>
//                   <div className="col-1 text-center"><label className="mb-0">Min</label></div>
//               </div>
//           </div>

//           {/* <div className="form-group form-w-label mb-20">
//               <label >Plate text</label>
//               <div className="row align-items-center">
//                   <div className="col-4">
//                       <input type="text" name="" placeholder="" className="form-control grey-input"/>
//                   </div>
//                   <div className="col-3 text-center"><label className="mb-0">with similarity of</label></div>
//                   <div className="col-4">
//                       <input type="text" name="" placeholder="" className="form-control grey-input"/>
//                   </div>
//                   <div className="col-1 text-center"><label className="mb-0">%</label></div>
//               </div>
//           </div> */}

//           <div className="form-group">
//              <button type="submit" className="btn btn-secondary">Search</button>
//           </div>
//        </form>
//     </div>
//  </div>
  )
}

export default FilterBoxBar