import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_total_bayCount } from '../../store/action';

type Props = {};

const TotalBay = (props: Props) => {

	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);
	const [data, setdata] = useState<any>();
	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
		};

		if (DASHBOARD_DATA?.SELECTED_SITE?.value) {
			fetch_total_bayCount(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data;
					setdata(data);
				}
			});

			var timer = setInterval(() => {
				fetch_total_bayCount(postData, signal)?.then((res) => {
					if (res?.statusCode < 400) {
						let data = res?.response?.data;
						setdata(data);
					}
				});
			}, 120000);
		}

		return () => {
			controller.abort();
			if(timer)
				clearInterval(timer);
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value]);

	
	return (
		<div className={'icon-box large-icon-box white-bg text-center '}>
			<p className='w-100'> Occupied /Total bays</p>
			<h2>{data?.occupaidCount} / {data?.bayCount}</h2>
		</div>
	);
};

export default TotalBay;
