import React, { useState } from 'react';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { fetch_webusers } from '../../../store/action';
import AddModal from './addModal';

type Props = {};

const FilterBar = (props: Props) => {
	const dispatch = useAppDispatch();
	const SECURITY_DATA = useAppSelector((state) => state?.security);
    const [search, setsearch] = useState('')
	// "(tsp.emailID LIKE 'a%' OR vud.firstname LIKE 'a%' OR tsp.emailID LIKE 'a%' OR createdOn LIKE 'a%' OR createdUser LIKE 'a%' OR updatedUser LIKE 'a%' OR roleName LIKE 'a%' OR typeName LIKE 'a%')"


	const searchFx = (val: any) => {
		let posdData = {
			'filters': {
				'query': `(tsp.emailID LIKE '${val}%' OR vud.firstname LIKE '%${val}%' OR updatedUser LIKE '%${val}%' OR sysUserID  LIKE '${val}%')`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': SECURITY_DATA?.WEBUSERS_PAGINATION?.limit,
			},
		};

		setsearch(val)
		fetch_webusers(dispatch, posdData);
	};
	const [addModal, setaddModal] = useState(false);

	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);
	return (
		<>
			<div className='search-row-button'>
				<div className='row'>
					<div className='col-5'>
						<div className='form-group'>
							<div className='pos-rel'>
								<input
									className='form-control grey-input'
									name=''
									value={search}
									placeholder='Search Here'
									onChange={(e) => {
										let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');

										searchFx(v);
									}}
								/>
								<i className='fa-solid fa-magnifying-glass'></i>
							</div>
						</div>
					</div>
					<div className='col-7 text-right'>
						<PermissionWrapper subModuleID={subModuleList['Web User']} type='isCreate'>
							<a  className='btn btn-secondary modal-button' onClick={addModalOpen}>
								Add New User
							</a>
						</PermissionWrapper>
					</div>
				</div>
			</div>
			{addModal && <AddModal open={addModal} handleClose={addModalClose} />}
		</>
	);
};

export default FilterBar;
