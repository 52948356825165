import axios from 'axios';
import auth from './auth';
import { REFRESH_AUTHENTICATION } from './endpoint';
import { FailedToast, UploadProgress } from './../utils/utilFunctions';
import { globalConfig } from '../../config/config';

const endWithSlash = (text: string) => {
	if (!text) return '';
	if (text.endsWith('/')) {
		return text;
	} else {
		return text + '/';
	}
};

const Request = (
	type: 'get' | 'post' | 'put' | 'delete' | 'file-download' | 'file-download-post' | 'post-external',
	url: string,
	params: any,
	signal?: AbortSignal | undefined,
	success?: (res: any) => void,
	failed?: (err: any) => void,
	authorization_recall: boolean = true,
	upload?: any,
	splToken?: string
) => {
	let token = auth.getToken();

	if (token && authorization_recall) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	}
	if (!authorization_recall) {
		axios.defaults.headers.common['Authorization'] = 'No Auth';
	}

	if (process.env.NODE_ENV === 'development') {
		axios.defaults.baseURL = '/webservices/';
	} else {
		axios.defaults.baseURL = endWithSlash(globalConfig.get()?.HOST);
	}



	const success_validation = (type: any, res: any, success_callback: any) => {
		//console.log(`%c${type} Success Response :`, 'color: green', res);
		let response = {
			type,
			statusCode: res.status || null,
			response: res.data || null,
			error_dev: null,
			error_app: null,
		};
		if (success_callback) {
			success_callback(response);
		}
		return response;
	};

	const failed_validation = (type: any, res: any, failed_callback: any, current_fx: any, authorization_recall: boolean) => {
		console.log(`${type} Failed Response`, res);
		console.log(`${type} Failed Response data`, res.response);
		//console.log('authorization_recall:', authorization_recall);

		if (res.response && res.response.data) {
			if (res.response.status === 401 && authorization_recall) {
				AuthErrorValidation(current_fx);
			} else {
			
			}
		} else if (res?.message) {
			console.log('Eroor message CHECKKKKs', res?.message);
			// if (res?.response?.status > 499) network_error(res?.message);

			if (res?.code === "ERR_NETWORK") {
				FailedToast({ appData: "Connection to server lost.", devData: "Please check your internet connection and try reloading the page." }, "networkerror");
				// network_error(res?.message)
			}

		} else {
			let his: any = window.location;



			// FailedToast({appData :"Something went wrong.", devData:res?.message ?? "an unprecendented erro occured."},"unknownerror");

			// if (his) {
			// 	auth.logout(() => his.href('/'));
			// }
		}

		let statusCode = null;
		let error_dev_data = null;
		let error_app_data = null;
		
		console.log("FAILED RES < API TS", res)

		if (res?.response) {
			statusCode = res?.response?.status;
			error_dev_data = res?.data?.dev_data;
			error_app_data = res?.data?.app_data;
		}

		let response = {
			type,
			statusCode: statusCode,
			response: res.response ? res.response.data : {},
			error_dev: error_dev_data,
			error_app: error_app_data,
		};
		if (failed_callback) {
			failed_callback(response);
		}
		return response;
	};


	const AuthErrorValidation = (recallApi: any) => {
		
			//console.log('THIS IS POST DATA ::', post_data);
			const success = (res: any) => {
				const { response } = res;
				console.log('auth Refresh', response);
				auth.set_access_token(response.accessToken);
				console.log('recallApi', recallApi);

				// recallApi();

				window.location.reload();
			};

			const failed = (res: any) => {


				if (res?.message === "Network Error") {
					FailedToast({ appData: "Renew access token failed", devData: res?.message || "" }, "errorRefresh");
					const retryfailed = () => {
						auth.logout(() => (window.location.href = '/login'));
					}
					Request('post', REFRESH_AUTHENTICATION, "", signal, success, retryfailed, false)
				}else{
					auth.logout(() => (window.location.href = '/login'));
				}
			};

			return Request('post', REFRESH_AUTHENTICATION, "", signal, success, failed, false);
			// return null;
		} 





	switch (type) {
		case 'get':
			return axios
				.get<ResponseType>(url, { signal: signal })
				.then((response) => {
					return success_validation(type, response, success);
				})
				.catch((error) => {
					// log(error,"ERROR API ",'red');

					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});

		case 'post':
			return axios
				.post<ResponseType>(url, params, {
					headers: { 'Content-Type': 'application/json' },
					withCredentials: true,
					onUploadProgress: (progressEvent) => {
						typeof upload !== 'undefined' && UploadProgress(progressEvent, upload);
					},
					signal: signal,
				})
				.then((response) => {
					// log(response,"RESPONSE API ",'green');

					return success_validation(type, response, success);
				})
				.catch((error) => {
					// log(error,"ERROR API ",'red');

					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});
		
		case 'post-external':
			return axios
				.post<ResponseType>(url, params, {
					headers: { 'Content-Type': 'application/json',  'Authorization' : `Bearer ${splToken}`},
					withCredentials: true,
					onUploadProgress: (progressEvent) => {
						typeof upload !== 'undefined' && UploadProgress(progressEvent, upload);
					},
					signal: signal,
				})
				.then((response) => {
					// log(response,"RESPONSE API ",'green');

					return success_validation(type, response, success);
				})
				.catch((error) => {
					// log(error,"ERROR API ",'red');

					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});

		case 'put':
			return axios
				.put<ResponseType>(url, params, {
					onUploadProgress: (progressEvent) => {
						typeof upload !== 'undefined' && UploadProgress(progressEvent, upload);
					},
				})
				.then((response) => {
					return success_validation(type, response, success);
				})
				.catch((error) => {
					//console.log(`%c Error ==  ${error}`, 'color: red');

					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});

		case 'delete':
			return axios
				.delete(url)
				.then((response) => {
					return success_validation(type, response, success);
				})
				.catch((error) => {
					//console.log(`%c Error ==  ${error}`, 'color: red');

					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});

		case 'file-download':
			return axios
				.get(url, { responseType: 'blob' })
				.then((response) => {
					return success_validation(type, response, success);
				})
				.catch((error) => {
					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});

		case 'file-download-post':
			return axios
				.post(url, params, { responseType: 'blob' })
				.then((response) => {
					return success_validation(type, response, success);
				})
				.catch((error) => {
					const currentFxRecall = () => {
						Request(type, url, params, signal, success, failed, authorization_recall);
					};
					return failed_validation(type, error, failed, currentFxRecall, authorization_recall);
				});

		default:
			break;
	}
};

export default Request;
