import { DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	ActionProps,
	ActionWithRulesProps,
	CombinatorSelectorProps,
	ValueEditor as DefaultValueEditor,
	FieldSelectorProps,
	OperatorSelectorProps,
	ValueEditorProps,
	ValueSourceSelectorProps,
} from 'react-querybuilder';
import ReactSelect from 'react-select';

export interface ExtendedValueEditorProps extends ValueEditorProps{
	max ?: string | number,
	min ?: string | number
}

export const ValueEditor = (props: ExtendedValueEditorProps) => {
	const [dateVal, setDateVal] = useState<any>(props.inputType === 'date' || props.inputType === 'datetime' ? props?.value : '');
	
  
  console.log("AMAL OPTIONS" , props?.fieldData?.options)
	console.log("ValueEditorProps",props)
  console.log("AMAL OPTIONS" , props?.values)

	let opts: any = props?.values ?? [];

	let miscProps = props?.fieldData.miscProps??null
  
	let miscPropsFormatStr = miscProps?.inputShowHour && miscProps?.inputShowMinute && miscProps?.inputShowHour ? 'HH:mm:ss' :
	miscProps?.inputShowHour ? 'HH' :	miscProps?.inputShowMinute ? 'mm' :	miscProps?.inputShowSecond ? 'ss' : 'HH:mm'
  // let opts: any = props?.values ?props?.values.map((item)=>({label :item?.label , value : item?.name})) : [];

	const [val, setval] = useState('');
	


	return (
		<div className='col-3'>
			<div className='form-group' style={{ marginBottom: '5px',  }}>
				{props.inputType === 'date' || props.inputType === 'datetime' || props.inputType === 'smalldatetime' ? (
					<DatePicker
						{...props}
						picker='date'
						showTime={props.inputType === 'smalldatetime'}
						id=''
						className={`form-control ${props?.className}`}
						allowClear
						value={dateVal}
						onChange={(v) => {
							setDateVal(v);
							if(props?.fieldData?.valueFn){
								props.handleOnChange(props?.fieldData?.valueFn(moment(v).format(`YYYY-MM-DD${props.inputType === 'smalldatetime'?' HH:mm:ss' : ''}`)));
							}else{
								props.handleOnChange(moment(v).format(`YYYY-MM-DD${props.inputType === 'smalldatetime'?' HH:mm:ss' : ''}`));
							}
						}}
					/>
				) : 
				props.inputType === 'time' ? (
					<DatePicker
						{...props}
						picker="time"
						id=''
						className={`form-control ${props?.className}`}
						allowClear
						format={miscPropsFormatStr}
						showHour={ miscPropsFormatStr?.includes('HH') }
						showMinute={  miscPropsFormatStr?.includes('mm') }
						showSecond={  miscPropsFormatStr?.includes('ss') }
						value={dateVal}
						onChange={(v) => {
							setDateVal(v);
							let format = miscProps?.inputShowHour && miscProps?.inputShowMinute && miscProps?.inputShowHour ? 'HH:mm:ss' :
								miscProps?.inputShowHour ? 'HH' :	miscProps?.inputShowMinute ? 'mm' :	miscProps?.inputShowSecond ? 'ss' : 'HH:mm'
							
								if(props?.fieldData?.valueFn){
									props.handleOnChange(props?.fieldData?.valueFn(moment(v).format(format)));
								}else{
									props.handleOnChange(moment(v).format(format));
								}
							
						}}
					/>
				) : 
					props.inputType === 'select' ? (
					<ReactSelect
						options={opts}
						className={`common-select ${props?.className}`}
						isMulti={false}
						isDisabled={props?.disabled}
						isClearable={false}
						getOptionLabel={(o: any) => o?.label}
						getOptionValue={(o: any) => o?.name}
						value={props?.value ? opts?.find((el: any) => el?.name === props?.value) : null}
						onChange={(v) => {
							props?.handleOnChange(v?.name);
						}}
					/>
				) :
					props?.operator==='null' || props?.operator==='notNull' ?
					<></>
				:
				(
					<input
						className={`form-control ${props?.className}`}
						type={props?.inputType ?? 'text'}
						max={props?.max??''}
						min={props?.min?? props?.inputType === 'number' ? 0 : ''}
						value={val}
						translate='yes'
						onKeyDown={(evt) => props?.inputType === 'number' && ['e', 'E'].includes(evt.key) && evt.preventDefault()}
						placeholder={`Enter ${props?.inputType ?? 'text'} here`}
						onChange={(e) => {
							let v = e.target.value.replace(/[^a-zA-Z0-9\s@()_.-]/g, '');
							setval(v);
							props.handleOnChange(v);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export const CombinatorSelector = (props: CombinatorSelectorProps) => {
	let opts: any = props?.options ?? [];
	return (
		<div className='col-2'>
			<div className='form-group' style={{ marginBottom: '5px' }}>
				<ReactSelect
					options={opts}
					className={`common-select ${props?.className}`}
					placeholder={'Combination Operator'}
					isMulti={false}
					isDisabled={props?.disabled}
					isClearable={false}
					getOptionLabel={(o: any) => o?.label}
					getOptionValue={(o: any) => o?.name}
					value={props?.value ? opts?.find((el: any) => el?.name === props?.value) : null}
					onChange={(v) => {
						props?.handleOnChange(v?.name);
					}}
				/>
			</div>
		</div>
	);
};

export const FieldSelector = (props: FieldSelectorProps) => {
	let opts: any = props?.options ?? [];
	return (
		<div className='col-2'>
			<div className='form-group' style={{ marginBottom: '5px' }}>
				<ReactSelect
					options={opts}
					className={`common-select ${props?.className}`}
					placeholder={'Fields'}
					isMulti={false}
					menuPlacement={'auto'}
					isDisabled={props?.disabled}
					isClearable={false}
					getOptionLabel={(o: any) => o?.label}
					getOptionValue={(o: any) => o?.name}
					value={props?.value ? opts?.find((el: any) => el?.name === props?.value) : null}
					onChange={(v) => {
						props?.handleOnChange(v?.name);
					}}
				/>
			</div>
		</div>
	);
};

export const OperatorSelector = (props: OperatorSelectorProps) => {
	let opts: any = props?.options ?? [];
	return (
		<div className='col-2'>
			<div className='form-group' style={{ marginBottom: '5px' }}>
				<ReactSelect
					options={opts}
					className={`common-select ${props?.className}`}
					placeholder={'Operators'}
					menuPlacement={'auto'}
					isMulti={false}
					isDisabled={props?.disabled}
					isClearable={false}
					getOptionLabel={(o: any) => o?.label}
					getOptionValue={(o: any) => o?.name}
					value={props?.value ? opts?.find((el: any) => el?.name === props?.value) : null}
					onChange={(v) => {
						props?.handleOnChange(v?.name);
					}}
				/>
			</div>
		</div>
	);
};

export const AddGroupAction = (props: ActionWithRulesProps) => {
	return (
		<div className='col-3'>
			<div className='form-group'>
				<button className='btn btn-primary modal-button' onClick={props?.handleOnClick}>
					Group Rules
				</button>
			</div>
		</div>
	);
};

export const AddRuleAction = (props: ActionWithRulesProps) => {
	return (
		<div className='col-2'>
			<div className='form-group'>
				<button className='btn btn-secondary modal-button' onClick={props?.handleOnClick}>
					Add Rules
				</button>
			</div>
		</div>
	);
};

export const RemoveGroupAction = (props: ActionWithRulesProps) => {
	return (
		<div className='col' style={{ display: 'flex', alignItems: 'center' }}>
			<button className='btn btn-sqaure btn-red modal-button' onClick={props?.handleOnClick} style={{ marginBottom: '2%' }}>
				<i className='fa-solid fa-trash'></i>
			</button>
		</div>
	);
};

export const RemoveRuleAction = (props: ActionProps) => {
	return (
		<div className='col' style={{ display: 'flex', alignItems: 'center' }}>
			<button className='btn btn-sqaure btn-red modal-button' onClick={props?.handleOnClick}>
				<i className='fa-solid fa-trash'></i>
			</button>
		</div>
	);
};
