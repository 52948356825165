/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PopUp from './../../../common/components/popUp';
import { forwardRef } from 'react';
import ButtonLoader from '../../../common/components/ButtonLoader';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
	children: React.ReactNode;
	handleClose: () => void;
	clearAll?: any;
	loading: boolean;
};

const DashboardFilterWrapper = forwardRef((props: Props, ref: any) => {

	const newLocal = (
		<div className='button-group'>
			<a className='btn btn-sqaure btn-white modal-button'>
				<i className='fa-solid fa-filter'></i>
			</a>
		</div>
	);




	return (
		<PopUp icon={newLocal} ref={ref} >
			<div className='w-100 dflex align-items-center p-10' style={{ borderBottom: '1px solid var(--lightgrey)' }} >
				<h2 className='label-dnd-menuitem'>
					Apply Filter
					{props?.loading && (
						<>
							<CircularProgress
								style={{
									width: '16px',
									height: '16px',
									color: 'var(--primary)',
								}}
							/>
						</>
					)}
				</h2>
				{/* <hr className='w-100'/> */}
			</div>

			<div className='filter-wrapper'>{props.children}</div>
			<div className='dflex flex-justify-end  gap-10 p-10'>

				{
					props?.clearAll && <button className='filter-btn btn-primary' onClick={props?.clearAll}>
						<ButtonLoader text='Reset Filter' loading={false} />
					</button>
				}

				<button className='filter-btn' onClick={props.handleClose}>
					Close
				</button>

			</div>
		</PopUp>
	);
});

export default DashboardFilterWrapper;
