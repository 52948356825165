import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Popper, { PopperPlacementType } from '@mui/material/Popper';

import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { ClickAwayListener, SxProps } from '@mui/material';
import { Theme } from '@mui/system';

type Props = {
	children: React.ReactNode;
	iconClass?: string
	closeOutside?:boolean
	icon?:any
	handleClose?:()=>void
	placement?:PopperPlacementType
	style?:SxProps<Theme> ,
};

const PopUp = forwardRef((props:Props, ref) => {

	const dropdownRef = React.useRef(null);

	const [open, setopen] = useState<any>(false);
	const [anchor, setanchor] = useState<any>(null);

	const handleClick = (e: any) => {
		setanchor(e.currentTarget);
		setopen(!open);
	};

	const closeOnlyClick = () =>{
		setopen(false);
	}
	

	React.useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu then close the menu
			let targt: any = dropdownRef.current;
			if (open && targt && !targt.contains(e.target)) {
				if(props?.closeOutside){
					setopen(false);
				}
				
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [open, setopen ,props?.closeOutside]);


	useImperativeHandle(ref, () => ({
		childFunction1() {
		  setopen(false)
		},
	 }));
	 

	return (
		<ClickAwayListener onClickAway={closeOnlyClick}>
		<div ref={dropdownRef}>
			<div onClick={handleClick} style={{ cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' , }}>

			{props?.icon??
				<i className={open ? 'fa-solid fa-xmark' :props?.iconClass ?? 'fa-sharp fa-solid fa-bars'  }></i>}

			</div>
			<Popper open={open} anchorEl={anchor} placement={props?.placement ??'left-start'} transition sx={{ zIndex:1002 ,...props?.style}}>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper sx={{ boxShadow : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" , borderRadius:"8px"}}>{props?.children}</Paper>
					</Fade>
				)}
			</Popper>
		</div>
		</ClickAwayListener>
			
	);
});

export default PopUp;


