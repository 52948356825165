import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';


type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	PARKING_ZONES_LIST_DATA: [],
	PARKING_ZONES_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	PARKING_ZONES_LOADER: true,
	PARKING_ZONES_FILTER:  {query: "" } as FilterObject,
	PARKING_ZONES_ERROR: '',
};

export const PARKING_ZONES_SLICE = createSlice({
	name: 'PARKINGZONE',
	initialState: initialState,
	reducers: {
		UPDATE_PARKING_ZONES__DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.PARKING_ZONES_LIST_DATA = data;
			state.PARKING_ZONES_PAGINATION = pagination;
			state.PARKING_ZONES_FILTER = filters;
			state.PARKING_ZONES_LOADER = false;
			state.PARKING_ZONES_ERROR = '';
		},
		UPDATE_PARKING_ZONES__LOADER: (state, action) => {
			const { loader } = action.payload;
			state.PARKING_ZONES_LOADER = loader;
		},

		UPDATE_PARKING_ZONES__ERROR: (state, action) => {
			const { error } = action.payload;
			state.PARKING_ZONES_ERROR = error;
		},

		RESET_STATE: () => {
			return {...initialState}
		}

		
	
	},
});

export const { UPDATE_PARKING_ZONES__DATA, UPDATE_PARKING_ZONES__LOADER, UPDATE_PARKING_ZONES__ERROR, RESET_STATE } = PARKING_ZONES_SLICE.actions;
export const PARKINGZONEData = (state: RootState) => state;
export default PARKING_ZONES_SLICE.reducer;
