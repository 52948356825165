/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBox from '../../../../../common/components/checkBox';
import DeleteCustomConfirm from '../../../../../common/components/DeleteCustomConfirm';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { definiton_delete, fetch_definitionList } from '../../../store/action';
import { RESET_STATE } from '../../../store/definitionSlice';

import AddModal from './AddModal';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';

function ListTable() { 
    const [addModal, setaddModal] = useState(false);
    const [delModal, setdelModal] = useState(false);

	const [loader, setLoader]	= useState(false)

	const delModalClose = () => setdelModal(false);
	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);

    // const [isDefault, setIsDefault] = useState(false)
    const [id, setId] = useState(null)
    const [selectedData, setSelectedData] = useState({})

    let navigate = useNavigate()
    const dispatch = useAppDispatch()

    const DEFINITION_DATA = useAppSelector((state) => state?.definition)
    const fetchIdRef = useRef(0)

    useEffect(() => {
        dispatch(RESET_STATE())
    }, [])

    const handleDeletModalOpen = (id:any) =>{
        setId(id)
        setdelModal(true)
    }
    
    const ConfirmFx = (val:any) =>{
		setLoader(true)
        const Success = (res:any) => {
			setLoader(false)
			SuccesToast(res?.response?.data)
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": DEFINITION_DATA?.DEFINITION_FILTER?.query

					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                'place-of-reg'
			);
			delModalClose()
		}
		const Failed = (err:any) => {
			setLoader(false)
			FailedToast(err?.response)		
		}

        definiton_delete(val,'place-of-reg',Success,Failed)

    }

    const fetchData = useCallback(({ pageSize, pageIndex ,OrderBy, filters}: any) => {
		
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						'query':filters.query || '',
						OrderBy
					},
					"pagination": {
						"pageNo": pageIndex,
						"limit": pageSize
					}
				},
                'place-of-reg'
			);
		}
	},[dispatch, navigate]);

    const columns = React.useMemo(
		() => [
			{ Header: 'ID ', accessor: 'placeOfRegID'},

			{ Header: 'CITY / COUNTRY ', accessor: 'cityCountry' },

			{ Header: 'ANPR-REF', accessor: 'anprRefPlaceOfRegID' },

			{ Header: 'ABBREVIATION', accessor: 'abbreviation'},

			{ Header: 'ASKGS-REF', accessor: 'askGsReference' },
			
            
			{
				Header: 'ACTION',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
                    //console.log(row)
					return (
						<Fragment>
							<div className='button-group'>
								<a onClick={() =>{
                                    // row?.values && console.log(row)
                                    setSelectedData({
                                        ...row?.original
                                    })
                                    addModalOpen()
                                    
                                }} className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-pen'></i>
								</a>
								<a className="btn btn-sqaure btn-red modal-button" onClick={()=> handleDeletModalOpen(row?.values?.placeOfRegID)}>
                                    <i className="fa-solid fa-trash"></i>
                                </a>
							</div>
						</Fragment>
					);
				},
			},
		],
		[]
	);

    return (
        <>
            <CommonTable
                columns={columns}
                data={DEFINITION_DATA?.DEFINITION_TABLE??[]}
                fetchData={fetchData}
                pageCount={DEFINITION_DATA?.DEFINITION_PAGINATION?.totalCount ?? 0}
                loading={DEFINITION_DATA?.DEFINITION_LOADER}
                page_no={DEFINITION_DATA?.DEFINITION_PAGINATION?.pageNo ?? 1}
                limit={DEFINITION_DATA?.DEFINITION_PAGINATION?.limit ?? 1}
				filters={DEFINITION_DATA?.DEFINITION_FILTER}
				isPagination={false}
		   />
			{addModal&&
            <AddModal open={addModal} handleClose={addModalClose} Data={selectedData}/>}
			<CustomConfirm
				open={delModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						if (id) ConfirmFx(id);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
        </>
    );
}

export default ListTable;