import dayjs from "dayjs";
import moment from "moment";
import Request from "../../../config/api";
import { ALLOCATED_USERS_PARKING_URL, NEW_URL_PARKING_REQUEST, PARKING_REQUEST, PARKING_VIOLATIONS, REALTIME_MONITOR_TRANSACTION_HISTORY, REPORTS_AND_DASHBOARD_GRAPH } from "../../../config/endpoint";
import { UPDATE_REPORTS_GRAPH_FOUR, UPDATE_REPORTS_GRAPH_ONE, UPDATE_REPORTS_GRAPH_THREE, UPDATE_REPORTS_GRAPH_TWO, UPDATE_USER_REPORT_DATA, UPDATE_USER_REPORT_ERROR, UPDATE_USER_REPORT_LOADER, UPDATE_VISIT_REPORT_DATA, UPDATE_VISIT_REPORT_ERROR, UPDATE_VISIT_REPORT_LOADER } from "./reportSlice";
import { FailedToast, FileDownloader, MinMaxOfArrayOfObj, filterDuplicateObjArr } from "../../../utils/utilFunctions";
import { EXPORT_UPDATE_REPORTS_GRAPH } from "../../exportingReports/exportReportSlice";

export const valConvertTo1Dec = (v:any) => {
    if(isNaN(parseInt(v)) || !v)
        return 0
    else
        return Math.round((v+Number.EPSILON)*10)/10
}

export const valConvertToTime = (v:any, f:any='') => {
    if(v && moment(v, ['HH:mm:ss','HH:mm','hh:mm','hh:mm a'],true).isValid())
        return f!=='' ? moment(v, ['HH:mm:ss','HH:mm','hh:mm','hh:mm a'],true).get('hours') : moment(v, ['HH:mm:ss','HH:mm','hh:mm','hh:mm a'],true).format('hh:mm A')
    else 
        return v
}

export const valConvertTimeToReadable = (num:number) =>{ 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
//   console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
  return `${days ? `${days} Day${addPluralIfMore(days)}` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
}

export const addPluralIfMore = (val:number, maxVal:number = 1) =>{
    if(val>maxVal)
        return 's'
    else 
        return ''
}

export const convertToProperNum = (val?:any) => {
    if(typeof val !== 'number' && typeof val === 'string')
        if(!val)
            return 0
        else if( isNaN(parseInt(val)))
            return 0
        else return parseInt(val)
    else if(typeof val==='number' && isNaN(val))
        return 0
        else return val
}

///////////////////////////////////////////////
//////////////OCCUPANCY///////////////////////
/////////////////////////////////////////////

export const fetch_report_occupancy_summary_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
   
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'WEEK-DAY',
                'VALUE' : 'OCCUPANCY PERCENTAGE',
                'TOOLTIPVAL' : 'OCCUPIED BAYS COUNT',
                'ID' : 'Sl. No.'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                return { 
                    id : el.week_Day,
                    name : el.day_Name, 
                        value : valConvertTo1Dec(
                            el.occuPaidBaysPercenatage===0 && (parseInt(el?.occuPaidBaysCount)/parseInt(el?.totalBays))!==0 ?
                                parseFloat(el?.occuPaidBaysCount)/parseFloat(el?.totalBays) > 100 ?
                                100
                                :
                                parseFloat(el?.occuPaidBaysCount)/parseFloat(el?.totalBays)
                            :
                                el?.occuPaidBaysPercenatage
                            ),  
                    toolTipVal : `Occupied : ${el?.occuPaidBaysCount} Bays` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )}
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : [],
                    csv_cols : {}
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
    
}

export const fetch_report_occupancy_summary_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-BayTypes`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'BAY TYPE',
                'VALUE' : 'OCCUPANCY PERCENTAGE',
                'TOOLTIPVAL' : 'OCCUPIED BAYS COUNT',
                'ID' : 'BAYTYPE ID'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                return { id : el.bTypeID, name : el.bTypeName, value : valConvertTo1Dec(el.occupancyRate),  toolTipVal : `Occupied : ${el?.occupaidCount} Bays` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }else{ 
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )}
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
    
    
}

export const fetch_report_occupancy_summary_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-Zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'ZONE NAME',
                'VALUE' : 'OCCUPANCY PERCENTAGE',
                'TOOLTIPVAL' : 'OCCUPIED BAYS COUNT',
                'ID' : 'ZONE ID'
            }
            let newArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')?.map((el:any)=>{
                return { id : el.zoneID, name : el.zoneName, value : valConvertTo1Dec(el.occupancyRate),  toolTipVal : `Occupied : ${el?.occupaidCount} Bays` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            else{ 
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )}
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
    
    
}


export const fetch_report_occupancy_hourly_overall = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-overall`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME',
                'OCCUPANCY' : 'OCCUPANCY PERCENTAGE',
                'CSVLABEL' : 'OCCUPIED BAYS COUNT'
            }
            let responseArr = res?.response?.data 
            
            let timeArr = responseArr?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            let newArr = timeArr?.map((el:any)=>{
                    let obj = { name : valConvertToTime(el?.times) , 'Occupancy' : valConvertTo1Dec(el?.occupancyRate), 
                        toolTipLabel : [`( Occupied : ${el?.occupaidCount} Bay${addPluralIfMore(el?.occupaidCount)} )`],
                        csvlabel : `Occupied : ${el?.occupaidCount} Bay${addPluralIfMore(el?.occupaidCount)} ` }
                    console.log('OBJ',obj)
                    return obj
                })
                
                if(imExport){
                    dispatch(
                        EXPORT_UPDATE_REPORTS_GRAPH({
                            data    :   newArr,
                            measure :   measure,
                            chartType : chartType,
                            unrecveh : unrecveh,
                            imExport : imExport,
                            csv_cols : newColsCol
                        })
                    )
                }
                else {
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )}
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_FOUR({
                data : []
            })
        )
      })
    
}


export const fetch_report_occupancy_hourly_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME'
            }
            let responseData = res?.response?.data

            let weekArr = responseData?.filter(
                (value: { weekDayName: any; week_Day: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { weekDayName: any; week_Day: any }) => t.weekDayName === value.weekDayName && t.week_Day === value.week_Day))

            console.log('WEEKARR',weekArr)
            let timeArr = responseData?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            console.log('RESULTRESP=>',responseData)
            let newArr = timeArr?.map((el:any)=>{
                    let obj = <any>{ name : valConvertToTime(el?.times) }
                    for(let i=0; i<weekArr.length; ++i){
                        let oldObj = {...obj}
                        let occVal = responseData?.find((x:any)=> x.times===el.times && x.weekDayName===weekArr[i]?.weekDayName)
                        
                            if(occVal){
                                obj = {...oldObj, 
                                    [weekArr[i]?.weekDayName] : valConvertTo1Dec(occVal?.occupancyRate),
                                    [`${weekArr[i]?.weekDayName}-toolTipLabel`] : ` ${occVal?.occupaidCount} Bay${addPluralIfMore(occVal?.occupaidCount)}` 
                                }    
                                let newColsCol2 = { ...newColsCol, [`${weekArr[i]?.weekDayName}-TOOLTIPLABEL`] : [`${weekArr[i]?.weekDayName} BAYS OCCUPIED`]}
                                newColsCol = { ...newColsCol2}
                            }
                    }
                    
                    return obj
                })
                
            console.log('RESULTNEWARR',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            else
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
    
}

export const fetch_report_occupancy_hourly_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME'
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : valConvertToTime(el?.times) }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.times===el.times && x.bTypeName===bayTypeArr[i]?.bTypeName)
                    obj = {...oldObj, [bayTypeArr[i]?.bTypeName] : 
                        valConvertTo1Dec(occVal?.occupancyRate),
                        [`${bayTypeArr[i]?.bTypeName}-toolTipLabel`] :
                        ` ${occVal?.occupaidCount} Bay${addPluralIfMore(occVal?.occupaidCount)} ` }
                        let newColsCol2 = { ...newColsCol, [`${bayTypeArr[i]?.bTypeName}-TOOLTIPLABEL`] : [`${bayTypeArr[i]?.bTypeName} BAYS OCCUPIED`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }else
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
    
}

export const fetch_report_occupancy_hourly_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME'
            }
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : valConvertToTime(el?.times) }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.times===el.times && x.zoneName===zoneArr[i]?.zoneName)
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : 
                        valConvertTo1Dec(occVal?.occupancyRate),
                        [`${zoneArr[i]?.zoneName}-toolTipLabel`] 
                        : ` ${occVal?.occupaidCount} Bay${addPluralIfMore(occVal?.occupaidCount)} ` }
                        let newColsCol2 = { ...newColsCol, [`${zoneArr[i]?.zoneName}-TOOLTIPLABEL`] : [`${zoneArr[i]?.zoneName} BAYS OCCUPIED`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ3=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }else
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
    
}


export const fetch_report_occupancy_historical_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}historical-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'WEEK DAY',
                'OCCUPANCY' : 'OCCUPANCY PERCENTAGE',
                'CSVLABEL' : 'OCCUPIED BAYS'
            }
            let weekArr = res?.response?.data?.filter(
                (value: { day_Name : any, week_Day : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { day_Name : any, week_Day : any }) => t.day_Name === value.day_Name && t.week_Day === value.week_Day ))
            console.log('TIMEARR',weekArr)
            let newArr = weekArr?.map((el:any)=>{
                    let obj = { name : el?.day_Name , 'Occupancy' : valConvertTo1Dec(el?.occuPaidBaysPercenatage), 
                        toolTipLabel : [` ${el?.occuPaidBaysCount} Bay${addPluralIfMore(el?.occuPaidBaysCount)} `],
                        csvlabel : ` ${el?.occuPaidBaysCount} Bay${addPluralIfMore(el?.occuPaidBaysCount)} ` 
                    }
                    console.log('OBJ',obj)
                    return obj
                })
            let newArr2 = weekArr?.map((el:any)=>{
                    let obj = { name : el?.day_Name , 'Occupancy' : valConvertTo1Dec(el?.occuPaidBaysCount), 
                    //toolTipLabel : [` Occupaid Count : ${el?.occuPaidBaysCount}`] 
                }
                    console.log('OBJ',obj)
                    return obj
                })
                if(imExport){
                    dispatch(
                        EXPORT_UPDATE_REPORTS_GRAPH({
                            data    :   newArr,
                            measure :   measure,
                            chartType : chartType,
                            unrecveh : unrecveh,
                            imExport : imExport,
                            csv_cols : newColsCol
                        })
                    )
                }    

            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
        dispatch(
            UPDATE_REPORTS_GRAPH_FOUR({
                data : []
            })
        )
      })
    
}

export const fetch_report_occupancy_historical_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}historical-occupancy-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE'
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : moment(el.vDate).format('D MMM YY') }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName)
                    obj = {...oldObj, [bayTypeArr[i]?.bTypeName] : 
                        valConvertTo1Dec(occVal?.occupancyRate),
                        [`${bayTypeArr[i]?.bTypeName}-toolTipLabel`] :
                        ` ${occVal?.occupaidCount} Bay${addPluralIfMore(occVal?.occupaidCount)} ` }
                        let newColsCol2 = { ...newColsCol, [`${bayTypeArr[i]?.bTypeName}-TOOLTIPLABEL`] : [`${bayTypeArr[i]?.bTypeName} BAYS OCCUPIED`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }else
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_occupancy_historical_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}historical-occupancy-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE'
            }
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : dayjs(el?.vDate).format('D MMM YY') }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.zoneName===zoneArr[i]?.zoneName)
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : 
                        valConvertTo1Dec(occVal?.occupancyRate),
                        [`${zoneArr[i]?.zoneName}-toolTipLabel`] :
                        ` ${occVal?.occupaidCount} Bay${addPluralIfMore(occVal?.occupaidCount)} ` }
                    let newColsCol2 = { ...newColsCol, [`${zoneArr[i]?.zoneName}-TOOLTIPLABEL`] : [`${zoneArr[i]?.zoneName} BAYS OCCUPIED`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }else
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}

/////////////////////////////////////////////
///////////////DWELL_TIME////////////////////
/////////////////////////////////////////////

export const fetch_report_dwell_summary_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}average-dwell-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'ID' : 'Sl. No.',
                'NAME' : 'WEEK DAY',
                'VALUE' : 'DWELL PERCENTAGE',
                'TOOLTIPVAL' : 'DWELL DURATION'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                var sum = el?.dwellSum ?? 0
                var days = ((sum / 60) / 24) >= 0 ? Math.floor((sum / 60) / 24) : null
                var d = (sum / 60) % 24
                var hours = days && d >= 0 ? Math.floor(d) : (sum / 60) >= 0 ? Math.floor(sum / 60) : null;          
                var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);

                var totalDaysMin = el?.totalDwell??0
                //el?.week_DayCount ? (el?.week_DayCount*24*60) : 0

                var totdays = ((totalDaysMin / 60) / 24) >= 0 ? Math.floor((totalDaysMin / 60) / 24) : null
                var totD = (totalDaysMin / 60) % 24
                var totHours = totdays && totD >= 0 ? Math.floor(totD) : (totalDaysMin / 60) >= 0 ? Math.floor(totalDaysMin / 60) : null;          
                var totMinutes = totHours ? Math.ceil((totD-totHours)*60) : Math.ceil(totD * 60);
                let txt = `${days ? `${days} Day${addPluralIfMore(days)}` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`} `
                // / ${totdays ? `${totdays} Day${addPluralIfMore(totdays)}` : ''} ${totHours ? `${totHours} hr ${totMinutes} min` : `${totMinutes} min`}`
                return { id : el.week_Day,
                    name : el.day_Name, 
                    value : 
                    // valConvertTo1Dec(el?.week_DayCount ? 
                    //     el?.dwellSum < totalDaysMin ? ((el?.dwellSum/(totalDaysMin))*100) : (Math.floor(el?.dwellSum/(totalDaysMin))*100) 
                    //     : 0),  
                    valConvertTo1Dec(el.dwellPercenatage), 
                     
                    toolTipVal : `Dwell : ${txt}` 
                }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }}
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
}

export const fetch_report_dwell_summary_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}average-dwell-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'ID' : 'BAY TYPE ID',
                'NAME' : 'BAY TYPE',
                'VALUE' : 'DWELL PERCENTAGE',
                'TOOLTIPVAL' : 'DWELL DURATION'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                var sum = el?.dwellSum ?? 0
                var days = ((sum / 60) / 24) >= 0 ? Math.floor((sum / 60) / 24) : null
                var d = (sum / 60) % 24
                var hours = days && d >= 0 ? Math.floor(d) : (sum / 60) >= 0 ? Math.floor(sum / 60) : null;          
                var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);

                var totdays = ((el?.totalDwell / 60) / 24) >= 0 ? Math.floor((el?.totalDwell / 60) / 24) : null
                var totD = (el?.totalDwell / 60) % 24
                var totHours = totdays && totD >= 0 ? Math.floor(totD) : (el?.totalDwell / 60) >= 0 ? Math.floor(el?.totalDwell / 60) : null;          
                var totMinutes = totHours ? Math.ceil((totD-totHours)*60) : Math.ceil(totD * 60);
                let txt = `${days ? `${days} Day${addPluralIfMore(days)}` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`
                // / ${totdays ? `${totdays} Day${addPluralIfMore(totdays)}` : ''} ${totHours ? `${totHours} hr ${totMinutes} min` : `${totMinutes} min`}`
                
                return { id : el.bTypeID, name : el.bTypeName, value : valConvertTo1Dec(el.dwellPercenatage), toolTipVal : `Dwell : ${txt}` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }}
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_dwell_summary_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}average-dwell-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'ID' : 'ZONE ID',
                'NAME' : 'ZONE NAME',
                'VALUE' : 'DWELL PERCENTAGE',
                'TOOLTIPVAL' : 'DWELL DURATION'
            }
            let newArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')?.map((el:any)=>{
                var sum = el?.dwellSum ?? 0
                var days = ((sum / 60) / 24) >= 0 ? Math.floor((sum / 60) / 24) : null
                var d = (sum / 60) % 24
                var hours = days && d >= 0 ? Math.floor(d) : (sum / 60) >= 0 ? Math.floor(sum / 60) : null;          
                var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);

                var totdays = ((el?.totalDwell / 60) / 24) >= 0 ? Math.floor((el?.totalDwell / 60) / 24) : null
                var totD = (el?.totalDwell / 60) % 24
                var totHours = totdays && totD >= 0 ? Math.floor(totD) : (el?.totalDwell / 60) >= 0 ? Math.floor(el?.totalDwell / 60) : null;          
                var totMinutes = totHours ? Math.ceil((totD-totHours)*60) : Math.ceil(totD * 60);
                let txt = `${days ? `${days} Day${addPluralIfMore(days)}` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`} `
                // / ${totdays ? `${totdays} Day${addPluralIfMore(totdays)}` : ''} ${totHours ? `${totHours} hr ${totMinutes} min` : `${totMinutes} min`}`
                
                return { id : el.zoneID, name : el.zoneName, value : valConvertTo1Dec(el.dwellPercenatage),  toolTipVal : `Dwell : ${txt}` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}



export const fetch_report_dwell_daily_overall = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}daily-dwell-time-overall`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
                'CSVLABEL' : 'TOTAL DWELL',
                'DWELL TIME_CSVLABEL_fn' : {
                        k   :   'DWELL TIME',
                        fn  :   (val:any) => valConvertTimeToReadable(val)
                    },
            }
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            let newArr = timeArr?.map((el:any)=>{
                    let obj = { name : dayjs(el?.vDate).format('D MMM YY') ,
                     'Dwell Time' : el?.dwellSum??0
                     //valConvertTo1Dec(el?.dwellPercenatage)
                     , toolTipLabel : [` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`],
                     csvlabel : ` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`
                     }
                    console.log('OBJ',obj)
                    return obj
                })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
}

export const fetch_report_dwell_daily_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}daily-dwell-time-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
                'CSVLABEL' : 'TOTAL DWELL'
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : dayjs(el?.vDate).format('D MMM YY'), 
                toolTipLabel : [` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`],
                csvlabel : ` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}` }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    console.log('OBJVAL===>',res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName),bayTypeArr[i]?.bTypeName,el.vDate)
                    obj = {...oldObj, 
                        [bayTypeArr[i]?.bTypeName] : 
                        res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName)?.dwellSum??0,
                        //valConvertTo1Dec(res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName)?.dwellPercenatage) 
                    }
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_dwell_daily_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}daily-dwell-time-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
                'CSVLABEL' : 'TOTAL DWELL'
            }
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : dayjs(el?.vDate).format('D MMM YY'), 
                    toolTipLabel : [` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`],
                    csvlabel : ` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}` }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.zoneName===zoneArr[i]?.zoneName)?.dwellSum??0 
                    //    valConvertTo1Dec(res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.zoneName===zoneArr[i]?.zoneName)?.dwellPercenatage) 
                    }
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ3=>',newArr)
            
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}


export const fetch_report_dwell_distribution_time = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}distribution-dwell-time-overall`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
                'CSVLABEL1' : 'TOTAL DWELL',
                'CSVLABEL2' : 'DWELL DURATION',
                'DWELL' : 'DWELL PERCENTAGE'
            }
            let weekArr = res?.response?.data?.filter(
                (value: { hh : any}, index: any, self: any[]) =>
                    index === self.findIndex((t: { hh : any}) => t.hh === value.hh ))
            console.log('TIMEARR',weekArr)
            let newArr = weekArr?.map((el:any)=>{
                    let obj = { name : `${parseInt(el?.hh) > 23 ? `24hrs+` : `${parseInt(el?.hh)} - ${parseInt(el?.hh)+1} Hrs`}`, 
                        toolTipLabel : [` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`,` Dwell duration : ${valConvertTimeToReadable(el?.dwellSum)} `],
                        csvlabel1 : ` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`,
                        csvlabel2 : ` Dwell duration : ${valConvertTimeToReadable(el?.dwellSum)} `,
                     'Dwell' : valConvertTo1Dec(el?.dwellPercenatage) 
                    }
                    console.log('OBJ',obj)
                    return obj
                })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }    
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
}

export const fetch_report_dwell_distribution_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}distribution-dwell-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME',
                'TOOLTIPLABEL' : 'TOTAL DWELL'
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { hh : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { hh : any }) => t.hh === value.hh ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : `${parseInt(el?.hh) > 23 ? `24hrs+` : `${parseInt(el?.hh)} - ${parseInt(el?.hh)+1} Hrs`}`, 
                toolTipLabel : [` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`] }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    obj = {...oldObj, 
                        [bayTypeArr[i]?.bTypeName] : 
                        valConvertTo1Dec(res?.response?.data?.find((x:any)=> x.hh===el.hh && x.bTypeName===bayTypeArr[i]?.bTypeName)?.dwellPercenatage),
                        [`${bayTypeArr[i]?.bTypeName}-toolTipLabel`] : 
                        ` ${
                            valConvertTimeToReadable(res?.response?.data?.find((x:any)=> x.hh===el.hh && x.bTypeName===bayTypeArr[i]?.bTypeName)?.dwellSum)} ` 
                    }
                    let newColsCol2 = { ...newColsCol, [`${bayTypeArr[i]?.bTypeName}-TOOLTIPLABEL`] : [`${bayTypeArr[i]?.bTypeName} DWELL DURATION`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_dwell_distribution_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}distribution-dwell-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME',
                'TOOLTIPLABEL' : 'TOTAL DWELL'
            }
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { hh : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { hh : any }) => t.hh === value.hh ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : `${parseInt(el?.hh) > 23 ? `24hrs+` : `${parseInt(el?.hh)} - ${parseInt(el?.hh)+1} Hrs`}`, 
                toolTipLabel : [` Total Dwell : ${valConvertTimeToReadable(el?.totalDwell)}`] }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : 
                        valConvertTo1Dec(res?.response?.data?.find((x:any)=> x.hh===el.hh && x.zoneName===zoneArr[i]?.zoneName)?.dwellPercenatage),
                        [`${zoneArr[i]?.zoneName}-toolTipLabel`] : 
                        ` ${
                            valConvertTimeToReadable(res?.response?.data?.find((x:any)=> x.hh===el.hh && x.zoneName===zoneArr[i]?.zoneName)?.dwellSum)} ` 
                    }
                    let newColsCol2 = { ...newColsCol, [`${zoneArr[i]?.zoneName}-TOOLTIPLABEL`] : [`${zoneArr[i]?.zoneName} DWELL DURATION`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}

/////////////////////////////////////////////
///////////////TURN_OVER////////////////////
/////////////////////////////////////////////

export const fetch_report_turnover_summary_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}average-turnover-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'ID' : 'Sl. No.',
                'NAME' : 'WEEK DAY',
                'VALUE' : 'TURNOVER PERCENTAGE',
                'TOOLTIPVAL' : 'TURN OVER COUNT'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                return { id : el.week_Day,name : el.day_Name, value : valConvertTo1Dec(el.turnOverRate),  
                    toolTipVal : `Turn over count : ${el?.turnOverCount} vehicles per day\nTotal Vehicles : ${el?.totalTurnOver}` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                        
                    })
                )
            }}
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
}

export const fetch_report_turnover_summary_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}average-turnover-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'ID' : 'BAY TYPE ID',
                'NAME' : 'BAY TYPE',
                'VALUE' : 'TURNOVER',
                'TOOLTIPVAL' : 'TURN OVER COUNT'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                return { id : el.bTypeID, name : el.bTypeName, value : valConvertTo1Dec(el.turnOverRate),  
                    toolTipVal : `Turn over count : ${el?.turnOverCount} vehicles per day\nTotal Vehicles : ${el?.totalTurnOver}` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }}
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_turnover_summary_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}average-turnover-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'ID' : 'ZONE ID',
                'NAME' : 'ZONE NAME',
                'VALUE' : 'TURNOVER',
                'TOOLTIPVAL' : 'TURN OVER COUNT'
            }
            let newArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')?.map((el:any)=>{
                return { id : el.zoneID, name : el.zoneName, value : valConvertTo1Dec(el.turnOverRate), 
                    toolTipVal : `Turn over count : ${el?.turnOverCount} vehicles per day\nTotal Vehicles : ${el?.totalDwell}` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}



export const fetch_report_turnover_daily_overall = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}daily-turnover-by-overall`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
                'DAILY TURNOVER' : 'TURNOVER PERCENTAGE',
                'CSVLABEL1' : 'DAILY TURN OVER COUNT',
                'CSVLABEL2' : 'TOTAL TURN OVER COUNT'
            }
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            let newArr = timeArr?.map((el:any)=>{
                    let obj = { name : dayjs(el?.vDate).format('D MMM YY') , 
                    toolTipLabel : [` Daily Turnover : ${el?.turnOverCount} cars per day `,`Total Tunover : ${el?.totalTurnOver}`],
                    csvlabel1 : ` Daily Turnover : ${el?.turnOverCount} cars per day `,
                    csvlabel2 : `Total Tunover : ${el?.totalTurnOver}`,
                    'Daily Turnover' : valConvertTo1Dec(el?.turnOverRate) }
                    console.log('OBJ',obj)
                    return obj
                })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
}

export const fetch_report_turnover_daily_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}daily-turnover-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : dayjs(el?.vDate).format('D MMM YY'), toolTipLabel : [`Total turn over count : ${el?.totalTurnOver} cars per day`] }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    obj = {...oldObj, [bayTypeArr[i]?.bTypeName] : 
                        valConvertTo1Dec(res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName)?.turnOverRate),
                        [`${bayTypeArr[i]?.bTypeName}-toolTipLabel`] :  ` ${res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName)?.turnOverCount} cars per day `
                    }
                    let newColsCol2 = { ...newColsCol, [`${bayTypeArr[i]?.bTypeName}-TOOLTIPLABEL`] : [`${bayTypeArr[i]?.bTypeName} TURN OVER COUNT`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_turnover_daily_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}daily-turnover-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE',
            }
            
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : dayjs(el?.vDate).format('D MMM YY'), toolTipLabel : [`Total turn over count : ${el?.totalTurnOver} cars per day`] }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : 
                        valConvertTo1Dec(res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.zoneName===zoneArr[i]?.zoneName)?.turnOverRate),
                        [`${zoneArr[i]?.zoneName}-toolTipLabel`] :  ` ${res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.zoneName===zoneArr[i]?.zoneName)?.turnOverCount} cars per day `
                    }
                    let newColsCol2 = { ...newColsCol, [`${zoneArr[i]?.zoneName}-TOOLTIPLABEL`] : [`${zoneArr[i]?.zoneName} TURN OVER COUNT`]}
                    newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ3=>',newArr)
            
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}



export const fetch_report_turnover_heatmap = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DAY',
                'ID' : 'Sl.No',
                'DAILY TURNOVER' : 'TURNOVER PERCENTAGE'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                return { id : el.week_Day, name : el.day_Name, 
                    value : valConvertTo1Dec(el.occuPaidBaysPercenatage),  }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : []
                }))
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_FOUR({
                data : []
            })
        )
      })
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// ENTRY AND EXIT REPORTS /////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetch_report_entry_exit = (postData : object, dispatch : any, imExport ?: any) =>{
    
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}entry-exit-vehicle`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newArr = res?.response?.data?.map((el:any)=>{
                return { name : valConvertToTime(el.times), 
                    'Vehicles Entered'  : valConvertTo1Dec(el.enryCount),
                    'Vehicles Exited'   : valConvertTo1Dec(el.exitCount),
                    'Occupancy'         : valConvertTo1Dec(el.occupancyPercentage),
                    // 'Occupancy-toolTipLabel' : ` ( ${el?.currentOccupancyCount} vehicle${addPluralIfMore(el?.currentOccupancyCount)})`    
                }
            })
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : newArr
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_FOUR({
                data : []
            })
        )
      })
}


///////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// USER VISITOR REPORTS ////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////

export const fetch_visit_report = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = REPORTS_AND_DASHBOARD_GRAPH+'visitor-report'
    dispatch(UPDATE_VISIT_REPORT_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        
        const { data, pagination, 
            filters
        } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_VISIT_REPORT_DATA({
                    data,
                    pagination,
                    filters
                })
            );
        } else {
            dispatch(
                UPDATE_VISIT_REPORT_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}

export const fetch_visit_report_plate_freq = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = REPORTS_AND_DASHBOARD_GRAPH+'visit-plate-frequency'
    dispatch(UPDATE_VISIT_REPORT_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_VISIT_REPORT_DATA({
                    data,
                    pagination,
                    filters : {...filters,
                        siteID : filters?.siteID??'',
                        zoneIDs : filters?.zoneIDs??'',
                        bayTypeIDs : filters?.bayTypeIDs??'',
                        visitDurationFrom : filters?.visitDurationFrom??0,
                        visitDurationTo : filters?.visitDurationTo??0,
                        plateTextSearch : filters?.plateTextSearch??''
                        },
                })
            );
        } else {
            dispatch(
                UPDATE_VISIT_REPORT_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}

export const fetch_user_report = (history: any, dispatch: any, postData: any, tabType: any, signal?: AbortSignal) =>{
    let api_url = 
        tabType===3 ? PARKING_REQUEST : 
        tabType===4 ? PARKING_VIOLATIONS :
        tabType===2 ? REALTIME_MONITOR_TRANSACTION_HISTORY :
    ALLOCATED_USERS_PARKING_URL
    dispatch(UPDATE_USER_REPORT_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_USER_REPORT_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(
                UPDATE_USER_REPORT_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}




export const export_userReport = (urlText: string, fileName: string, fileType:string, postData:any, loaderFn:any) => {
    let api_url = REPORTS_AND_DASHBOARD_GRAPH+urlText;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()
        if(res?.statusCode===200){
            FileDownloader(res?.response,fileName,fileType)
        }else{
            FailedToast(res?.response)
        }

    })
}


export const export_visitReport = (urlText: string, fileName: string, fileType:string, postData:any, loaderFn:any) => {
    let api_url = REPORTS_AND_DASHBOARD_GRAPH+urlText;
    Request('file-download-post',api_url,postData)?.then((res) => {
        console.log(res)
        loaderFn()
        if(res?.statusCode===200){
            FileDownloader(res?.response,fileName,fileType)
        }else{
            FailedToast(res?.response)
        }

    })
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// AVAILABILTY //////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetch_report_availability_summary_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'WEEK-DAY',
                'VALUE' : 'AVAILABILITY PERCENTAGE',
                'TOOLTIPVAL' : 'AVAILABLE BAYS COUNT',
                'ID' : 'Sl. No.'
            }
            let newArr = res?.response?.data?.filter((elm:any)=> elm?.totalBays!==0 )?.map((el:any)=>{
                return { id : el.week_Day, name : el.day_Name, value : 
                    valConvertTo1Dec(100 - (el.occuPaidBaysPercenatage===0 && (parseInt(el?.occuPaidBaysCount)/parseInt(el?.totalBays))!==0 ?
                parseFloat(el?.occuPaidBaysCount)/parseFloat(el?.totalBays) > 100 ?
                100
                :
                parseFloat(el?.occuPaidBaysCount)/parseFloat(el?.totalBays)
            :
                el?.occuPaidBaysPercenatage)),  toolTipVal : `Available : ${el?.totalBays - el?.occuPaidBaysCount} Bays` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }}
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
    
}

export const fetch_report_availability_summary_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-BayTypes`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'BAY TYPE',
                'VALUE' : 'AVAILABILITY PERCENTAGE',
                'TOOLTIPVAL' : 'AVAILABLE BAYS COUNT',
                'ID' : 'BAYTYPE ID'
            }
            let newArr = res?.response?.data?.map((el:any)=>{
                return {  id : el.bTypeID, name : el.bTypeName, value : valConvertTo1Dec(100-el.occupancyRate),toolTipVal : `Available : ${el?.bayCount- el?.occupaidCount} Bays` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }}
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
    
    
}

export const fetch_report_availability_summary_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}avarage-occupancy-by-Zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'ZONE NAME',
                'VALUE' : 'AVAILABILITY PERCENTAGE',
                'TOOLTIPVAL' : 'AVAILABLE BAYS COUNT',
                'ID' : 'ZONE ID'
            }
            let newArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')?.map((el:any)=>{
                return { id : el.zoneID,name : el.zoneName, value : valConvertTo1Dec(100-el.occupancyRate),  toolTipVal : `Available : ${el?.bayCount - el?.occupaidCount} Bays` }
            })
            if(measure === "min"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.min
            }
            else if(measure === "max"){
                let nArr2 = MinMaxOfArrayOfObj(newArr, 'value')
                newArr = nArr2.max
            }
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
    
    
}



export const fetch_report_availability_hourly_overall = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-overall`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME',
                'AVAILABILITY' : 'AVAILABILITY PERCENTAGE',
                'TOOLTIPLABEL' : 'AVAILABLE BAYS COUNT'
            }
            let timeArr = res?.response?.data?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            let newArr = timeArr?.map((el:any)=>{
                    let obj = { name : valConvertToTime(el?.times) , 'Availability' : valConvertTo1Dec(100-el?.occupancyRate), toolTipLabel : [`( Available : ${el?.bayCount - el?.occupaidCount} Bay${addPluralIfMore(el?.bayCount - el?.occupaidCount)} )`] }
                    console.log('OBJ',obj)
                    return obj
                })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_FOUR({
                data : []
            })
        )
      })
    
}


export const fetch_report_availability_hourly_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME'
            }
            let weekArr = res?.response?.data?.filter(
                (value: { weekDayName: any; week_Day: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { weekDayName: any; week_Day: any; bayCount: any }) => t.bayCount!==0 && t.weekDayName === value.weekDayName && t.week_Day === value.week_Day))

            console.log('WEEKARR',weekArr)
            let timeArr = res?.response?.data?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            let newArr = timeArr?.map((el:any)=>{
                    let obj = { name : valConvertToTime(el?.times) }
                    for(let i=0; i<weekArr.length; ++i){
                        let oldObj = {...obj}
                        let occVal = res?.response?.data?.find((x:any)=> x.times===el.times && x.weekDayName===weekArr[i]?.weekDayName)
                        obj = {...oldObj, [weekArr[i]?.weekDayName] : 
                            valConvertTo1Dec( occVal?.bayCount===0 ? 0 : 100-(occVal?.occupancyRate??0)),
                        [`${weekArr[i]?.weekDayName}-toolTipLabel`] :
                        ` ${(occVal?.bayCount - (occVal?.occupaidCount??0))} Bay${addPluralIfMore((occVal?.bayCount - (occVal?.occupaidCount??0)))}` }
                        let newColsCol2 = { ...newColsCol, [`${weekArr[i]?.weekDayName}-TOOLTIPLABEL`] : [`${weekArr[i]?.weekDayName} BAYS OCCUPIED`]}
                                newColsCol = { ...newColsCol2}
                    }
                    console.log('OBJ',obj)
                    return obj
                })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
      })
    
}

export const fetch_report_availability_hourly_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME'
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : valConvertToTime(el?.times) }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.times===el.times && x.bTypeName===bayTypeArr[i]?.bTypeName)
                    obj = {...oldObj, [bayTypeArr[i]?.bTypeName] : 
                        valConvertTo1Dec(occVal?.bayCount===0 ? 0 : 100 - (occVal?.occupancyRate??0)),
                        [`${bayTypeArr[i]?.bTypeName}-toolTipLabel`] :
                        ` ${occVal?.bayCount - (occVal?.occupaidCount??0)} Bay${addPluralIfMore(occVal?.bayCount - (occVal?.occupaidCount??0))} ` }
                        let newColsCol2 = { ...newColsCol, [`${bayTypeArr[i]?.bTypeName}-TOOLTIPLABEL`] : [`${bayTypeArr[i]?.bTypeName} BAYS AVAILABLE`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
    
}

export const fetch_report_availability_hourly_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}hourly-occupancy-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'TIME'
            }
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            console.log('REFREATOREDARR=>HOURLYAVAILABILIIT',refactoredArr)
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { times : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { times : any }) => t.times === value.times ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : valConvertToTime(el?.times) }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.times===el.times && x.zoneName===zoneArr[i]?.zoneName)
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : 
                        valConvertTo1Dec(occVal?.bayCount===0 ? 0 : (100- occVal?.occupancyRate)),
                        [`${zoneArr[i]?.zoneName}-toolTipLabel`] 
                        : ` ${convertToProperNum((occVal?.bayCount - occVal?.occupaidCount))} Bay${addPluralIfMore((occVal?.bayCount - occVal?.occupaidCount))} ` }
                        let newColsCol2 = { ...newColsCol, [`${zoneArr[i]?.zoneName}-TOOLTIPLABEL`] : [`${zoneArr[i]?.zoneName} BAYS AVAILABLE`]}
                        newColsCol = { ...newColsCol2}
                        
                console.log('For=>OBJHOURLYAVAILABILIIT',obj,'OCCVAL=>',occVal,'ZONEARR=>',zoneArr[i])
                }
                return obj
            })
            console.log('OBJ3=>',newArr)
            
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
    
}


export const fetch_report_availability_historical_weekday = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}historical-occupancy-by-weekdays`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'WEEK DAY',
                'AVAILABILITY' : 'AVAILABILITY PERCENTAGE',
                'TOOLTIPLABEL' : 'AVAILABLE BAYS'
            }
            let weekArr = res?.response?.data?.filter(
                (value: { day_Name : any, week_Day : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { day_Name : any, week_Day : any }) => t.day_Name === value.day_Name && t.week_Day === value.week_Day ))
            console.log('TIMEARR',weekArr)
            let newArr = weekArr?.map((el:any)=>{
                    let obj = { name : el?.day_Name , 'Availability' : valConvertTo1Dec(100 - el?.occuPaidBaysPercenatage??0), toolTipLabel : [` ${convertToProperNum(el?.bayCount - el?.occuPaidBaysCount)} Bay${addPluralIfMore(convertToProperNum(el?.bayCount - el?.occuPaidBaysCount))} `] }
                    console.log('OBJ',obj)
                    return obj
                })
            let newArr2 = weekArr?.map((el:any)=>{
                    let obj = { name : el?.day_Name , 'Availability' : valConvertTo1Dec(el?.occuPaidBaysCount??0), 
                    //toolTipLabel : [` Occupaid Count : ${el?.availabilityBaysCount}`] 
                }
                    console.log('OBJ',obj)
                    return obj
                })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }    
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_ONE({
                    data : []
                })
            )
            dispatch(
                UPDATE_REPORTS_GRAPH_FOUR({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_ONE({
                data : []
            })
        )
        dispatch(
            UPDATE_REPORTS_GRAPH_FOUR({
                data : []
            })
        )
      })
    
}

export const fetch_report_availability_historical_bayType = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}historical-occupancy-by-baytype`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE'
            }
            let bayTypeArr = res?.response?.data?.filter(
                (value: { bTypeName: any; bTypeID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { bTypeName: any; bTypeID: any }) => t.bTypeName === value.bTypeName && t.bTypeID === value.bTypeID))
            console.log('BAYARR',bayTypeArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : moment(el.vDate).format('D MMM YY') }
                for(let i=0; i<bayTypeArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.bTypeName===bayTypeArr[i]?.bTypeName)
                    obj = {...oldObj, [bayTypeArr[i]?.bTypeName] : 
                        valConvertTo1Dec(occVal?.bayCount===0 ? 0 : (100 - occVal?.occupancyRate??0)),
                        [`${bayTypeArr[i]?.bTypeName}-toolTipLabel`] :
                        ` ${occVal?.bayCount - occVal?.occupaidCount??0} Bay${addPluralIfMore(occVal?.bayCount -occVal?.occupaidCount??0)} ` }
                        let newColsCol2 = { ...newColsCol, [`${bayTypeArr[i]?.bTypeName}-TOOLTIPLABEL`] : [`${bayTypeArr[i]?.bTypeName} BAYS AVAILABLE`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            console.log('OBJ2=>',newArr)
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_TWO({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_TWO({
                data : []
            })
        )
      })
}

export const fetch_report_availability_historical_zone = (postData : object, measure : string, chartType : string, unrecveh : number = 0, dispatch : any, imExport ?: any) =>{
    Request(imExport ? 'post-external' : 'post',`${REPORTS_AND_DASHBOARD_GRAPH}historical-occupancy-by-zone`, postData, undefined, undefined, undefined, true, undefined, imExport?.tk)?.then((res:any)=>{
        if(res?.response?.data){
            let newColsCol = {
                'NAME' : 'DATE'
            }
            let refactoredArr = filterDuplicateObjArr(res?.response?.data, 'zoneID','zoneName')
            let zoneArr = refactoredArr?.filter(
                (value: { zoneName: any; zoneID: any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { zoneName: any; zoneID: any }) => t.zoneName === value.zoneName && t.zoneID === value.zoneID))
            console.log('BAYARR',zoneArr)
            let timeArr = res?.response?.data?.filter(
                (value: { vDate : any }, index: any, self: any[]) =>
                    index === self.findIndex((t: { vDate : any }) => t.vDate === value.vDate ))
            console.log('TIMEARR',timeArr)
            
            let newArr = timeArr?.map((el:any) => {
                let obj = { name : dayjs(el?.vDate).format('D MMM YY') }
                for(let i=0; i<zoneArr.length; ++i){
                    let oldObj = {...obj}
                    let occVal = res?.response?.data?.find((x:any)=> x.vDate===el.vDate && x.zoneName===zoneArr[i]?.zoneName)
                    obj = {...oldObj, [zoneArr[i]?.zoneName] : 
                        valConvertTo1Dec(occVal?.bayCount===0 ? 0 : (100 - occVal?.occupancyRate??0)),
                        [`${zoneArr[i]?.zoneName}-toolTipLabel`] :
                        ` ${occVal?.bayCount - occVal?.occupaidCount??0} Bay${addPluralIfMore(occVal?.bayCount - occVal?.occupaidCount??0)} ` }
                        let newColsCol2 = { ...newColsCol, [`${zoneArr[i]?.zoneName}-TOOLTIPLABEL`] : [`${zoneArr[i]?.zoneName} BAYS AVAILABLE`]}
                        newColsCol = { ...newColsCol2}
                }
                console.log('OBJ',obj)
                return obj
            })
            if(imExport){
                dispatch(
                    EXPORT_UPDATE_REPORTS_GRAPH({
                        data    :   newArr,
                        measure :   measure,
                        chartType : chartType,
                        unrecveh : unrecveh,
                        imExport : imExport,
                        csv_cols : newColsCol
                    })
                )
            }
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : newArr,
                    measure : measure,
                    chartType : chartType,
                    unrecveh : unrecveh,
                    csv_cols : newColsCol
                })
            )
        }
        else{
            dispatch(
                UPDATE_REPORTS_GRAPH_THREE({
                    data : []
                })
            )
        }
      })
      .catch(()=>{
        dispatch(
            UPDATE_REPORTS_GRAPH_THREE({
                data : []
            })
        )
      })
}
