import dayjs from "dayjs"
import { useEffect, useState } from "react"
import CsvDownload from "react-csv-downloader"
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import GraphLoader from "../../../../../common/components/GraphLoader"
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks"
import { ExportMultipleChartsToPdf } from "../../../components/PdfExporter"
import { fetch_report_entry_exit } from "../../../store/action"
import { UPDATE_REPORTS_LOADER } from "../../../store/reportSlice"
import moment from "moment"
import { addPluralIfMore } from "../../../../../utils/utilFunctions"
import AreaGradient from "../../../components/AreaGradient"
import PdfExporterModal from "../../../components/pdfExporterModal"
import { exportDataToExcel } from "../../../../../common/components/ExcelFileExport"

type Props = {
	isUnRecVeh : string; 
	setIsUnRecVeh : any;
}

const legendDiv = {
    fontWeight : 600,
    borderRadius : '5px',
    padding: '1%',
    backgroundColor: 'rgba(0,0,0,0.05)'
}


const ReportDisplay = (props: Props) => {

	const REPORTSDATA = useAppSelector((state) => state?.reportData)
    const SITE_FLOOR = useAppSelector((state)=>state?.general)
    const dispatch  = useAppDispatch();
	// const img = useAppSelector((state) => state?.applicationSettings?.COMPANY_PROFILE?.photo);

	const csvColumns:any = {
		"NAME" 						: "TIME",
		"VEHICLES ENTERED" 			: "VEHICLES ENTERED (%)",
		"VEHICLES EXITED" 			: "VEHICLES EXITED (%)",
		"OCCUPANCY" 				: "OCCUPANCY (%)",
		"OCCUPANCY-TOOLTIPLABEL" 	: "VEHICLES OCCUPIED"
	}

	let pageTitle = `Entries and Exits Report`
	let dateFields = `${REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[0],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
	dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('MMM DD, YYYY') : dayjs().subtract(7,'day').format('MMM DD, YYYY')} to  ${
		REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[1],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
		dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY')}`
	let siteFields = `${REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value}`
	let timeFields = `${REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
		REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'}`


    useEffect(() => {
        dispatch(UPDATE_REPORTS_LOADER())
        if(SITE_FLOOR?.SITE_DDL && SITE_FLOOR?.SITE_DDL?.length>0){
        let postData = {
          SiteID      :   REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value ,
          Todate      :   REPORTSDATA?.REPORTS_DATE?.length > 0 ? 
          dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
          FromDate    :   REPORTSDATA?.REPORTS_DATE?.length > 0 ? 
          dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),
          FromTime    :   REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('HH:mm:ss') : '00:00:00',
          ToTime      :   REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('HH:mm:ss') :  '23:59:00',
		  UnknownVehicle : parseInt(props?.isUnRecVeh)
      	}
        fetch_report_entry_exit(postData, dispatch)
        }
      }, [SITE_FLOOR?.SITE_DDL, props?.isUnRecVeh])

    const [opacitySet, setOpacitySet] = useState(null)
    const [openExport, setOpenExport] = useState(false)
	const [openModal, setOpenModal] = useState(false)

    const handleMouseEnter = (o:any) => {
        const { dataKey } = o  
		if(!openModal)  
        setOpacitySet(dataKey);
      };
    
    const handleMouseLeave = () => {
		if(!openModal)
        setOpacitySet(null)
      };

	  const ChartObject = () =>{
		console.log('asdfg')
		return (
			<>
				<CartesianGrid stroke="#f5f5f5" />
        			<XAxis dataKey="name" padding={{ left: 20, right:20}} 
						allowDataOverflow={true} 
						// interval={REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA && REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA?.length > 10 ? 2 : 0}
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
				  	/>
        			<YAxis  padding={{ top:20 }} 
					    yAxisId="left"
						allowDecimals={false}
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
						label={{ value: 'Vehicles', angle: -90, fontWeight:600 , fontSize:"12.5px", position: 'insideLeft'}}/>
							   
					<YAxis  padding={{ top:20 }} 
						yAxisId="right"
						unit={'%'}
						orientation="right"
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
						label={{ value: 'Occupancy Percentage', angle: 90, fontWeight:600 , fontSize:"12.5px", position: 'insideRight'}}/>
					{
						openModal ?	null 
						:
						<Tooltip isAnimationActive={false} 
					formatter={(value:any, _:any, payload:any)=>  
						{
			
						  let v:any = ''
						  if(payload  && payload.payload[`${payload.name}-toolTipLabel`] ? payload.payload[`${payload.name}-toolTipLabel`] : false)
							v = <>{value} %<p>{payload.payload[`${payload.name}-toolTipLabel`]}</p></>
						  else v = `${value} Vehicle${addPluralIfMore(!isNaN(parseInt(value)) ? parseInt(value) : 0)}`
						  return v
						  
						}
						}
						/>
        					  

					}
        			<Legend wrapperStyle={legendDiv} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
        			<Bar dataKey="Vehicles Entered" fill="#ff7300"
						yAxisId="left"
						strokeWidth={opacitySet ? opacitySet==='Vehicles Entered' ? 1 : 0.1 : 1 }
						opacity={opacitySet ? opacitySet==='Vehicles Entered' ? 1 : 0.1 : 1 } />
        			<Bar dataKey="Vehicles Exited" fill="#82ca9d"
						yAxisId="left"
						strokeWidth={opacitySet ? opacitySet==='Vehicles Exited' ? 1 : 0.1 : 1 }
						opacity={opacitySet ? opacitySet==='Vehicles Exited' ? 1 : 0.1 : 1 } />
        			<Line type="monotone" dataKey="Occupancy" stroke="#413ea0"
						yAxisId="right"
						strokeWidth={opacitySet ? opacitySet==='Occupancy' ? 2 : 0.1 : 2 }
						strokeOpacity={opacitySet ? opacitySet==='Occupancy' ? 1 : 0.1 : 1 } />
			</>
		)
	  }

  return (
        <div className="white-card ">
			<div className="row msb-20 mb-30">
				<div className="col-12">
					<div className="card-second">
						<div className="card-header">
							<h3>Entries, Exits, Occupancy Report 
							{` from ${
						REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[0],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
						dayjs(REPORTSDATA?.REPORTS_DATE[0]).format('MMM DD, YYYY') : dayjs().subtract(7,'day').format('MMM DD, YYYY')} to  ${
							REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[1],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
						dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY')}`}
							</h3>
							{	REPORTSDATA?.REPORTS_GRAPH_FOUR_LOAD ? null 
							:
							<div className='btn btn-sqaure btn-secondary btn-secondary-hover openMiniModalLine' onClick={() =>{ setOpenExport(!openExport) }}>
					        <i className='fa-solid fa-download' ></i>
					          	{	openExport ?
					          	<div className='miniModalOpenBox'>
					          		<div className='miniBoxModalContent'>
					          			<div role="button" onClick={() =>{ 
											setOpenModal(true)
										// 	ExportMultipleChartsToPdf({ val: `BarLineComposedChartId`,
										//  pgtitle: `${pageTitle} from ${dateFields}`,
										// 			headerimg : img,
										// pgTitleArr:[pageTitle, `Date : ${dateFields}`, `Site : ${siteFields}`, `Time : ${timeFields}`] 
										
										// })
										}}>
											<button>
												<p>Export PDF</p>
											</button>
											</div>

											<div role="button">
									<button onClick={()=>{ 
										return exportDataToExcel(
											REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA,
											`BarChart-${'Entries and Exits Report'}${` from ${
												REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[0],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE[0]).format('MMM DD, YYYY') : dayjs().subtract(7,'day').format('MMM DD, YYYY')} to  ${
													REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[1],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY')}`}`,
											`Entries and Exits Report from ${
												REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[0],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE[0]).format('MMM DD, YYYY') : dayjs().subtract(7,'day').format('MMM DD, YYYY')} to  ${
													REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[1],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY')}`,
                    						Object.keys(REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA[0])?.map((el:any) => {
												return { "id" : el,"displayName" : csvColumns[`${el.toUpperCase()}`]??'' }
											})
										) }} >
											<p>Export CSV</p>
									</button>
								</div>

					              			{/* <CsvDownload  
									          filename={`BarChart-${'Entries and Exits Report'}${` from ${
												REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[0],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE[0]).format('MMM DD, YYYY') : dayjs().subtract(7,'day').format('MMM DD, YYYY')} to  ${
													REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[1],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY')}`}`}
									          extension=".csv"
									          columns={
									          	Object.keys(REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA[0])?.map((el:any) => {
									          		return { "id" : el,"displayName" : csvColumns[`${el.toUpperCase()}`]??'' }
									          	})
									          }
									          datas={REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA}
											  title={`Entries and Exits Report from ${
												REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[0],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE[0]).format('MMM DD, YYYY') : dayjs().subtract(7,'day').format('MMM DD, YYYY')} to  ${
													REPORTSDATA?.REPORTS_DATE?.length > 0 && moment(REPORTSDATA?.REPORTS_DATE[1],['YYYY-MM-DD','DD-MMM-YYYY','DD-MM-YYYY'],true).isValid() ?
												dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY')}`}
								          >
											<button>
												<p>Export CSV</p>
											</button>
										</CsvDownload>	 */}
							        </div>
						        </div>
						          :	null
						          }
					          </div>
							}  
						</div>
						<div className="card-body"  style={{ height: '60vh' }}>
							{	REPORTSDATA?.REPORTS_GRAPH_FOUR_LOAD ? 
									<GraphLoader/>
								: 
							REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA && REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA?.length>0 ?
							<ResponsiveContainer  width="100%" height="100%"   id={`BarLineComposedChartId`} >
							<ComposedChart
        					  width={500}
        					  height={400}
        					  data={REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA}
        					  margin={{
        					    top: 20,
        					    right: 20,
        					    bottom: 20,
        					    left: 20,
        					  }}
						  
        					>
								{/* <defs>
						          	<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            							<stop offset="5%" stopColor="#129a74" stopOpacity={0.1}/>
            							<stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
          							</linearGradient>
        						</defs> */}
        					  {ChartObject()}

							{/* <Area yAxisId="right" type="monotone" dataKey="Occupancy" strokeWidth={0} fillOpacity={1} fill="url(#colorUv)" /> */}
							  
        					</ComposedChart>
							</ResponsiveContainer>
							:	null
							}
						</div>
					</div>
				</div>
			</div>
			{
        		openModal && 
        			<PdfExporterModal
        				handleClose={()=>setOpenModal(false)}
        				children={ChartObject}
        				data={REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA}
        				title={pageTitle}
        				titleArr={[pageTitle, `Date : ${dateFields}`, `Site : ${siteFields}`, `Time : ${timeFields}`]}
          			/>
        	}
		</div>
  )
}

export default ReportDisplay