import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';


type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	VISITOR_LIST_DATA: [],
	VISITOR_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	VISITOR_LOADER: true,
	VISITOR_FILTER:  {query: "" } as FilterObject,
	VISITOR_ERROR: '',
};

export const VISITORSLICE = createSlice({
	name: 'VISITOR',
	initialState: initialState,
	reducers: {
		UPDATE_VISITORS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.VISITOR_LIST_DATA = data;
			state.VISITOR_PAGINATION = pagination;
			state.VISITOR_FILTER = filters;
			state.VISITOR_LOADER = false;
			state.VISITOR_ERROR = '';
		},
		UPDATE_VISITORS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.VISITOR_LOADER = loader;
		},

		UPDATE_VISITORS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.VISITOR_ERROR = error;
			state.VISITOR_LOADER = false;

		},

		RESET_STATE: () => {
			return {...initialState}
		}

		
	
	},
});

export const { UPDATE_VISITORS_DATA, UPDATE_VISITORS_LOADER, UPDATE_VISITORS_ERROR, RESET_STATE } = VISITORSLICE.actions;
export const VISITORData = (state: RootState) => state;
export default VISITORSLICE.reducer;
