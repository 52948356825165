import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { definition_routes } from '../../../config/definitionRoutes';
import { definiton_add_edit, fetch_definitionList } from '../../../store/action';

type Props = {
	open: boolean;
	handleClose: () => void;
	label: string;
	path: string;
	Data?: any
};

const AddModal = (props: Props) => {

	const [DefId, setDefId] = useState('-')
	const [DefName, setDefName] = useState('')
	const [DefDescp, setDefDescp] = useState('')
	const [DefZone, setDefZone] = useState('')
	const [loading, setLoading] = useState(false)

	const [isDefault, setIsDefault] = useState(false)
	let navigate = useNavigate()
	const dispatch = useAppDispatch()
	const ZONELIST = useAppSelector((state) => state?.general)
	const DEFINITION_DATA = useAppSelector((state) => state?.definition)

	const [error, setError] = useState({
		name : false,
		descriptions : false,
		zone : false
	})
	useEffect(() => {
	  if(props.Data){
		setDefId(props.Data?.id??'-')
		setDefName(props.Data?.name??'')
		setIsDefault(props.Data?.isDefault??false)
		setDefDescp(props.Data && props.Data.descriptions? props.Data.descriptions : '')
		setDefZone(props.Data && typeof props.Data.fK_ZoneID!=='undefined' ? props.Data.fK_ZoneID : '')
	  }
	}, [props.Data])
	

	const DefinitonActionFx = () =>{
		setLoading(true)

		let cmpNeed = definition_routes.find(el => el.path===props.path)?.componentsNeed??[]
		let postdata:any = props.Data ? {
			ID : DefId,
			Name : DefName,
			IsEnable : props.Data.isEnable
		} :
		{
			Name : DefName,
			IsEnable : true
		}
		if(cmpNeed && cmpNeed.length!==0){
			if(cmpNeed.some((el)=> el==='isDefault'))
				postdata = {...postdata, IsDefault : isDefault}
			if(cmpNeed.some((el)=> el==='descriptions'))
				postdata = {...postdata, descriptions : DefDescp}
			if(cmpNeed.some((el)=> el==='fK_ZoneID'))
				postdata = {...postdata, fk_ID : DefZone}
			if(cmpNeed.some((el)=> el==='ReferenceID'))
				postdata = {...postdata, ReferenceID : props.Data ? props.Data.referenceId :  0}
			
				console.log('postdata',postdata,cmpNeed.some((el)=> el==='fK_ZoneID'),cmpNeed)

		}
		const Success = (res:any) => {
			setLoading(false)

			SuccesToast(res?.response?.data)
			setDefId('')
			setDefName('')
			setDefDescp('')
			setDefZone('')
			setIsDefault(false)
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": ""
					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                props.path
			);
			props.handleClose()
		}
		const Failed = (err:any) => {
			setLoading(false)

			FailedToast(err?.response)		
		}

		if(props.Data){
			definiton_add_edit('put',postdata,props.path,Success,Failed)
		}
		else{
			definiton_add_edit('post',postdata,props.path,Success,Failed)
		}

	}

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title={`${props.Data ? 'Edit' : 'Add'} New ${props.label}`} subtitle={DefId!=='-' ? `#${DefId}` : 'Definitions'}>
				<div className="row">
					{/* {DefId!=='-' &&
					<div className="col-3">
						<div className="form-group inline-input full-label">
							<label>{props.label} ID</label>
							<input type="text" name='' value={DefId} disabled className="form-control grey-input" />
						</div>
					</div>
					} */}
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>{props.label} Name</label>
							<input type="text" name='' value={DefName} onChange={(e) => setDefName(e.target.value)}
								className="form-control grey-input" />
							{	error.name &&
									<p className='hookform-error-message'>A valid Name is required</p>}
						</div>
					</div>

                    {	definition_routes.find(el => el.path===props.path)?.componentsNeed?.some(el=>el==='fK_ZoneID') ?
					<div className="col-9">
						<div className="form-group inline-input full-label">
							<label>Zone</label>
							<select className="form-control grey-input" value={DefZone} onChange={(e)=> setDefZone(e.target.value)}>
								{
									ZONELIST.ZONE_DDL.map((el:any) => {
										return(
											<option value={el.value}>{el.label}</option>
										)
									})
								}
								<option value={''}>Select a Zone</option>
							</select>
							{	error.zone &&
									<p className='hookform-error-message'>A Zone is mandatory</p>}
						</div>
					</div>
					:	null
					}
					{	definition_routes.find(el => el.path===props.path)?.componentsNeed?.some(el=>el==='descriptions') ?
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>{props.label} Description</label>
							<textarea className="form-control grey-input"
								value={DefDescp} onChange={(e)=> setDefDescp(e.target.value) }
							></textarea>
							{	error.descriptions &&
									<p className='hookform-error-message'>A valid Description is required</p>}
						</div>
					</div>
					:	null
					}
					{	definition_routes.find(el => el.path===props.path)?.componentsNeed?.some(el=>el==='isDefault') ?
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<div className="checkbox  zindexCorrection">
								<input type="checkbox" name="" checked={isDefault} onChange={()=> setIsDefault(!isDefault) } />
								<label><span>Default</span></label>
							</div>
						</div>
					</div>
					:	null
					}
					<div className="col-12">
						<div className="modal-footer">
							<button type="button" className="btn btn-outline-blue close-modal-button"  onClick={props.handleClose}>Cancel</button>
							<button type="submit" className="btn btn-secondary" 
							onClick={()=>{
								if(
									(definition_routes.find(el => el.path===props.path)?.componentsNeed?.some(el=>el==='descriptions') ? DefDescp!=='' : true) 
								&& DefName!=='' && 
								(definition_routes.find(el => el.path===props.path)?.componentsNeed?.some(el=>el==='fK_ZoneID') ? DefZone!=='' : true)
								){
									setError({
										name : false, descriptions : false, zone : false
									})	
									DefinitonActionFx()
								}else{
									setError({
										name : DefName==='',
										descriptions : DefDescp==='',
										zone : DefZone===''
									})
								} 
								
							}
							}>
								<ButtonLoader text={`${props.Data ? 'Edit' : 'Add'} ${props.label}`} loading={loading}/>
							</button>

						</div>	
					</div>
				</div>
		</ModalLayout>
	);
};

export default AddModal;
