import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	APPLICATION_LOGS_LIST         : [],
	APPLICATION_LOGS_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	APPLICATION_LOGS_LOADER       : false,
	APPLICATION_LOGS_FILTER       : { query: "" } as FilterObject,
	APPLICATION_LOGS_ERROR        : '',

    AUDIT_LOGS_LIST         : [],
	AUDIT_LOGS_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	AUDIT_LOGS_LOADER       : false,
	AUDIT_LOGS_FILTER       : { query: "" } as FilterObject,
	AUDIT_LOGS_ERROR        : '',
	
};

export const LOGSSLICE = createSlice({
	name: 'LOGSSLICE',
	initialState: initialState,
	reducers: {
		UPDATE_APPLICATION_LOGS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.APPLICATION_LOGS_LIST = data;
			state.APPLICATION_LOGS_PAGINATION = pagination;
			state.APPLICATION_LOGS_FILTER = filters;
			state.APPLICATION_LOGS_LOADER = false;
			state.APPLICATION_LOGS_ERROR = '';
		},
		UPDATE_APPLICATION_LOGS_LOADER: (state, action) => {
			console.log("i get data")
			const { loader } = action.payload;
			state.APPLICATION_LOGS_LOADER = loader;
		},

		UPDATE_APPLICATION_LOGS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.APPLICATION_LOGS_ERROR = error;
			state.APPLICATION_LOGS_LOADER = false;
		},

        UPDATE_AUDIT_LOGS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.AUDIT_LOGS_LIST = data;
			state.AUDIT_LOGS_PAGINATION = pagination;
			state.AUDIT_LOGS_FILTER = filters;
			state.AUDIT_LOGS_LOADER = false;
			state.AUDIT_LOGS_ERROR = '';
		},
		UPDATE_AUDIT_LOGS_LOADER: (state, action) => {
			console.log("i get data")
			const { loader } = action.payload;
			state.AUDIT_LOGS_LOADER = loader;
		},

		UPDATE_AUDIT_LOGS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.AUDIT_LOGS_ERROR = error;
			state.AUDIT_LOGS_LOADER = false;

		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const { UPDATE_APPLICATION_LOGS_DATA, UPDATE_APPLICATION_LOGS_LOADER, UPDATE_APPLICATION_LOGS_ERROR, RESET_STATE,
    UPDATE_AUDIT_LOGS_DATA, UPDATE_AUDIT_LOGS_LOADER, UPDATE_AUDIT_LOGS_ERROR } =
	LOGSSLICE.actions;
export const LOGSData = (state: RootState) => state;
export default LOGSSLICE.reducer;
