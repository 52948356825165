/* eslint-disable jsx-a11y/anchor-is-valid */
import DashIcons from './dashIcons'
import FilterBox from './FilterBox'
import ListTable from './ListTable'

type Props = {}

const AllocatedParking = (props: Props) => {

  return (
    <div className="main-body-content">
		<DashIcons/>
		<div className="white-card">
			<FilterBox/>
			<ListTable/>
		</div>
	</div>
  )
}

export default AllocatedParking