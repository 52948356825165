import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LinkTab from '../../common/components/LinkTab'
import NavTabs from '../../common/components/NavTabs'
import Occupancy from './screens/occupancy/occupancy'
import DwellTime from './screens/dwell/dwellTime'
import TurnOver from './screens/turn_over/turnOver'
import EntryAndExit from './screens/entry_and_exit/entryAndExit'
import VisitReport from './screens/visit_report/visitReport'
import Availability from './screens/availability/availabilityDashboard'
import UserReports from './screens/user_reports/userReports'
import { fetch_floorLevelDDL, fetch_siteDDL } from '../../common/general/action'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { RESET_STATE, UPDATE_REPORTS_SITE_FLOOR } from './store/reportSlice'
import { fetch_company_profile } from '../application_settings/store/action'
import { fetch_treeMap_withPermission } from '../security/store/action'
import './components/customToolTipStyles.css'

type Props = {}

const ReportsIndex = (props: Props) => {
  const dispatch  = useAppDispatch();
  const REPORTSDATA = useAppSelector((state) => state?.reportData)

	const [tree, setTree] = useState<any>([]);
	const [treeLoading, settreeLoading] = useState<any>(false);

  useEffect(() => {
    
    const controller = new AbortController();
    fetch_siteDDL(dispatch, controller?.signal)
    fetch_floorLevelDDL(dispatch)
    fetch_company_profile(dispatch);

return () => { dispatch(RESET_STATE()); }
  }, [])

  useEffect(() => {
		const success = (res: any) => {
			setTree(res?.response ?? []);

		};
		const failed = (err: any) => {
			// setTimeout(() => {
			// 	settreeLoading(false);
			// }, 1500);
		};
		fetch_treeMap_withPermission(success, failed);
	}, []);

    
	useEffect(() => {
		if (tree) {
			let site = tree[0];
			let floor = tree[0]?.siteChild[0];
      if(!REPORTSDATA?.REPORTS_SITE){
        dispatch(UPDATE_REPORTS_SITE_FLOOR({
         site : site?.siteID,
         floor : floor?.levelID 
        }))
      }
		}
	}, [tree]);

  
  return (
    <div className='navtabs__container'>
    <NavTabs>
      <LinkTab label='Occupancy' href='occupancy' />
      <LinkTab label='Dwell Time' href='dwell-time' />
      <LinkTab label='Turn Over' href='turn-over' />
      <LinkTab label='Availability' href='availability' />
      <LinkTab label='Entry and Exit' href='entry-and-exit' />
      <LinkTab label='Visit Report' href='visit-report' />
      <LinkTab label='User Reports' href='user-reports' />
    </NavTabs>
    <Routes>
      <Route path={'occupancy'} element={<Occupancy />} />
      <Route path={'dwell-time'} element={<DwellTime />} />
      <Route path={'turn-over'} element={<TurnOver />} />
      <Route path={'entry-and-exit'} element={<EntryAndExit />} />
      <Route path={'visit-report'} element={<VisitReport />} />
      <Route path={'availability'} element={<Availability />} />
      <Route path={'user-reports'} element={<UserReports />} />
      <Route path={'/*'} element={<Navigate to='occupancy' />} />
    </Routes>
  </div>
  )
}

export default ReportsIndex