/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import { Field } from 'react-querybuilder';
import QueryBModal from '../../../../common/components/query_builder';

// import DatePicker from "react-datepicker";
import { DatePicker } from 'antd';
// import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from 'react-select';
import { fetch_Users_AllocatedParkingslist } from '../../store/action';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { commonSearchQuery, FilterProps } from '../../../../config/filterSearch';
import dayjs from 'dayjs';
import useKeyboard from '../../../../utils/hooks/useKeyboard';
const { RangePicker } = DatePicker;

type Props = {};

const FilterBox = (props: Props) => {
	const [advanceModal, setAdvanceModal] = useState(false);
	const dispatch = useAppDispatch();

	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);
	const [allocatedUserType, setAllocatedUserType] = useState(0);
	const USERS_ALLOCATIONS = useAppSelector((state) => state?.findacar);

	const [dateRange, setDateRange] = useState<any>([]);
	const [status, setStatus] = useState<any>();
	const [reqType, setReqType] = useState('');
	const [userSearch, setUserSearch] = useState('');
	const [vehicleSearch, setvehicleSearch] = useState('');

	const [userID, setUserID] = useState('');

	const statusArr:any = [ 
	{ value : 1, label : 'Active'},
	{ value : 11, label : 'Expired' }, 
	{ value : 4, label : 'Revoked' }, 
	{ value : 3, label : 'Cancelled' },
	{ value : 6, label : 'Blacklisted'} ]
	//appr1,cancel3,revoke4,blacklisted6
	const reqTypeArr: any = [
		{ value: 1, label: 'Employee Parking ' },
		{ value: 2, label: 'Visitor Parking ' },
		{ value: 5, label: 'Employee Overtime Parking ' },
		{ value: 6, label: 'Visitor Overtime Parking ' },
	];


	const fields: any[] = [
		{ name: 'CAST (startdate AS date)', label: 'Start Date', inputType: 'date' },
		{ name: 'CAST (enddate AS date)', label: 'End Date', inputType: 'date' },
		{ name: 'Fullname', label: 'Requested For Name' },
		{ name: 'EmailID', label: 'Email' },
		{ name: 'ContactNo', label: 'Contact Number' },
		{ name: 'desName', label: 'Designation Name'},
		{ name: 'depName', label: 'Department Name' },
		{ name: 'PlateNumber', label: 'Plate Number' },

		{ name: 'vehicleCategory', label: 'Vehicle Category' },
		{ name: 'placeOfRegistration', label: 'Place of Registration' },
		// { name: 'tz2.ZoneID', label: 'Zone ID' ,inputType:"number"},
		{ name: 'zoneName', label: 'Zone Name' },

		// { name: 'FK_BayID', label: 'Bay ID',inputType:"number" },
		{ name: 'bayName', label: 'Bay Name' },

		{ name: 'FK_RequestTypeID', label: 'Request Type' , inputType:"select", values:[
			{ name: "1", label: 'Employee Parking ' },
			{ name: "2", label: 'Visitor Parking ' },
			{ name: "5", label: 'Employee Overtime Parking ' },
			{ name: "6", label: 'Visitor Overtime Parking ' },
		]},
		// { name: 'FK_VehicleID', label: 'Vehicle Id' },
		{ name: 'CurrentStatus', label: 'Status' , inputType:"select", values:[ 
			{ name : 1, label : 'Active'},
			{ name : 11, label : 'Expired' }, 
			{ name : 4, label : 'Revoked' }, 
			{ name : 3, label : 'Cancelled' },
			{ name : 6, label : 'Blacklisted'} 
		]},
		{ name: 'vuav.FK_PriorityLevel', label: 'Personal Grade', inputType:'number' },
		
		{ name: 'isPermenantParking', label: 'Permenant Parking' , inputType:"select", values:[ 
				{ name : 'true', label : 'Yes'},
				{ name : 'false', label : 'No' }
		]},
		{ name: 'isMedicalPass', label: 'Medical Pass' , inputType:"select", values:[ 
				{ name : 'true', label : 'Yes'},
				{ name : 'false', label : 'No' }
		]},
	];

	const deployQueryFilter = (query: string) => {
		fetch_Users_AllocatedParkingslist('', dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_ALLOCATIONS.USER_ALLOCATED_PARKING_PAGINATION.limit,
			},
		});
		advancemodalClose();
		setUserSearch('');
		setUserID('');
		setStatus('');
		setReqType('');
		setDateRange([]);
		setvehicleSearch('')
	};

	const clearSearch = () => {
		fetch_Users_AllocatedParkingslist('', dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_ALLOCATIONS.USER_ALLOCATED_PARKING_PAGINATION.limit,
			},
		});

		setUserSearch('');
		setUserID('');
		setStatus('');
		setReqType('');
		setDateRange([]);
		setvehicleSearch('')
	};

	const filterSearch = () => {

		// const convStatus = (n:any) =>{
		// 	return n===1 || n===2 ? null : n
		// }

		let QueryInput: FilterProps[] = [
			{ field: 'isVisitor', value: allocatedUserType === 0 ? null : allocatedUserType === 2 ? '1' : '0', operator: '=' },
			{
				field: 'CAST (StartDate AS date)',
				value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: 'CAST (EndDate AS date)',
				value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'Fullname',
				value: userSearch,
				operator: 'like',
			},
			{
				field: 'PlateNumber',
				value: vehicleSearch,
				operator: 'like',
			},
			{
				field: 'FK_RequestTypeId',
				value: reqType,
				operator: '=',
			},
			{
				field: 'RequestedFUID',
				value: userID,
				operator: '=',
			},
			{
				field: 'CurrentStatus',
				value: status,
				operator: '=',
			},
		];

		fetch_Users_AllocatedParkingslist('', dispatch, {
			'filters': {
				'query': commonSearchQuery(QueryInput, 'AND'),
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_ALLOCATIONS.USER_ALLOCATED_PARKING_PAGINATION.limit,
			},
		});
	};

	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	return (
		<div className='search-filter-row' ref={ref}>
			<ul>
				<li>
					<a className='active pointerDefaultSet'>
						<i className='fa-solid fa-magnifying-glass'></i> Quick Search
					</a>
				</li>
				<li>
					<a onClick={() => advancemodalOpen()}>
						<i className='fa-solid fa-filter'></i>Advanced Filter
					</a>
				</li>
				{USERS_ALLOCATIONS.USER_ALLOCATED_PARKING_FILTER?.query !== '' && (
					<li>
						<a onClick={clearSearch}>
							<i className='fa-solid fa-times'></i>Clear All Filters
						</a>
					</li>
				)}
			</ul>

			<div className='filter-block' id='quick-search'>
				<div className='row'>
					{/* <div className='col-1'>
						<div className='form-group'>
							<ReactSelect
								options={[
									{ value: 0, label: 'All' },
									{ value: 1, label: 'Users' },
									{ value: 2, label: 'Visitors' },
								]}
								className={'common-select'}
								placeholder={'Type of Users'}
								isMulti={false}
								value={[
									{ value: 0, label: 'All' },
									{ value: 1, label: 'Users' },
									{ value: 2, label: 'Visitors' },
								].find((c: { value: number }) => c.value === allocatedUserType)}
								onChange={(val: any) => {
									setAllocatedUserType(val?.value);
								}}
							/>
						</div>
					</div> */}
						<div className='col'>
						<div className='form-group'>
						<div className='pos-rel'>

							<input
								type='text'
								name=''
								placeholder='Search User'
								className='form-control'
								value={userSearch}
								onChange={(e) => {
									setUserSearch(e.target.value);
								}}
							/>
								<i className='fa-solid fa-magnifying-glass'></i>

							</div>


						</div>
					</div>

					<div className='col'>
						<div className='form-group'>
						<div className='pos-rel'>

							<input
								type='text'
								name=''
								placeholder='Plate Number'
								className='form-control'
								value={vehicleSearch}
								onChange={(e) => {
									setvehicleSearch(e.target.value);
								}}
							/>
								<i className='fa-solid fa-magnifying-glass'></i>

							</div>


						</div>
					</div>

					<div className='col'>
			
						<div className='form-group'>
							<RangePicker
								picker='date'
								allowClear  inputReadOnly
								className='form-control'
								placeholder={['Start Date', 'End Date']}
								value={dateRange}
								format={'DD-MMM-YYYY'}
								onChange={(dates: any) => {
									setDateRange(dates);
								}}
							/>
							{/* <DatePicker
        							selected={startDate}
        							onChange={(dates:any) =>{
										const [start, end] = dates;
										setStartDate(start)
										setEndDate(end)
									}}
        							selectsStart
									dateFormat={'dd-MMM-yyyy'}
									className='form-control'
        							startDate={startDate}
        							endDate={endDate}
									selectsRange
									placeholderText="Requested For Between"
									monthsShown={2}
									isClearable={startDate!==''}
      							/> */}
						</div>
					</div>
					<div className="col=1">
								<div className="form-group">
								<ReactSelect
								options={statusArr}
								className={'common-select'}
								placeholder={'Status'}
								isMulti={false}
                        	    value={status ? statusArr.find((c: { value: number; }) => c.value === parseInt(status)) : null}
                            	onChange={(val) =>{ setStatus(val?.value) }}
							/>
								</div>
							</div>
					{/* <div className='col'>
						<div className='form-group'>
							<ReactSelect
								options={reqTypeArr}
								className={'common-select'}
								placeholder={'Request Type'}
								isMulti={false}
								value={reqTypeArr.find((c: { value: number }) => c.value === parseInt(reqType))}
								onChange={(val) => {
									setReqType(val?.value);
								}}
							/>
						</div>
					</div>
				 */}
					{/* <div className="col">
								<div className="form-group">
									<input type="text" name="" placeholder="User ID" className="form-control" value={userID} onChange={(e)=>{setUserID(e.target.value)}} />
								</div>
							</div> */}
					<div className='col-2'>
						<div className='form-group'>
							<button
								onClick={() => {
									filterSearch();
								}}
								className='form-control blue-btn'>
								Search
							</button>
						</div>
					</div>
				</div>
			</div>
			<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
		</div>
	);
};

export default FilterBox;
