import React from 'react';
import MainLayout from '../../../../common/layouts/MainLayout';
import FilterBar from './components/FilterBar';
import ListTable from './components/ApplicationListTable';

function ApplicationLogs() {
    return (
        <MainLayout>
            <FilterBar/>
            <ListTable/>
        </MainLayout>
    );
}

export default ApplicationLogs;