import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { add_report_violations, fetch_report_violations_list, getViolationTypeByZone, search_user_or_visitor } from '../../../store/action';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormSelect from '../../../../../common/components/FormSelect';
import { fetch_BayDDL, fetch_zoneDDL, fetch_violationTypeDDL } from '../../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, fileToImage, SuccesToast } from '../../../../../utils/utilFunctions';
import { useNavigate } from 'react-router';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
// import dayjs from 'dayjs';
// import ReactDatePicker from 'react-datepicker';
import { DatePicker } from 'antd';
import moment from 'moment';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import FormInput from '../../../../../common/components/FormInput';
import FormTextarea from '../../../../../common/components/FormTextarea';
import ActionConfirmBox from '../../../../../common/components/ActionConfirmBox';
import UPLOADICON from '../../../../../assets/images/common/upload-icon.svg'
import CustomDatePicker from '../../../../../common/components/CustomDatePicker';
import dayjs from 'dayjs';

type ddl = {
	label: string;
	value: any;
};
type Props = { 
	open: boolean;
	handleClose: () => void;
};

let plateRegex =    /^[A-Z0-9]+$/
const schema = yup.object().shape({

	// [A-Z0-9]
	plateNo: yup.string().when('violationTypeNo',{
		is : (violationTypeNo : number) => violationTypeNo == 1,
		then : yup.string().matches(plateRegex,"Plate number should only have numbers and uppercase letters.").required()
	}).nullable(),
	FK_UserUniqueID: yup.number(),
	FK_ViolationType: yup.number().required(),
	FK_ReportedBay: yup.number(),
	FK_SiteID: yup.number().required(),
	FK_ZoneID: yup.number().required(),
	// PenaltyLevelID : yup.number(),
	VDate: yup.string().required(),
	Images: yup.mixed(),
	Remarks: yup.string().required(),
	EmailID: yup.string(),
	PhoneNumber: yup.string(),

	violationTypeNo : yup.number()
});

const ReportModal = (props: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
		clearErrors, trigger, watch
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});
	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmData, setConfirmData] = useState<any>()
	
	const [userName, setUserName] = useState('');
	const [selectedZones, setselectedZones] = useState<ddl[]>([]);
	const [loading, setLoading] = useState(false);
	const [usersArr, setUsersArr] = useState<any>([]);
	const [usersList, setUsersList] = useState<any>();
	const [violationTypeArr, setViolationTypeArr] = useState<any>([])
	const [triggerSelUser, setTriggerSelUser] = useState(false)

	const [selectedUser, setselectedUser] = useState<any>('');

	const [isGuest, setIsGuest] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const ZONELIST = useAppSelector((state) => state?.general);
	const REPORTVIOLATION_DATA = useAppSelector((state) => state?.violations);

	const cancelConfirm = () =>{ setConfirmModal(false); setLoading(false); }


	useEffect(() => {
		handleUserInputChange();
		const controller = new AbortController();
		fetch_zoneDDL(dispatch, controller.signal);
		fetch_violationTypeDDL(dispatch, controller.signal);
		reset();
		setfile([]);
		setUserName('');
		setselectedZones([]);
		setUsersArr([]);
		setValue('VDate',moment())

		clearErrors();
	}, []);

	const [file, setfile] = useState<any>();

	const handleUserInputChange = async (key?: string) => {
		let postData = {
			'CommonSearch': key,
			'PlatNumber': key,
			'ViolationTypeID': ifViolation?.value,
		};

		let Response = await search_user_or_visitor(postData);
		let data = Response?.response ?? [];

		setUsersList(data);
		setUsersArr(data || []);

		return data;
	};

	const promiseUserOptions = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleUserInputChange(inputValue));
		});

	const handleUserChange = (val: any) => {
		let data: any = usersList.find((item: any) => item.plateNumber === val?.plateNumber);
		setselectedUser(data);
		if (data) {
			setValue('FK_UserUniqueID', parseInt(data.userUniqueID));
			setValue('PhoneNumber', data.contactNo);
			setValue('EmailID', data.emailID);
			setValue('plateNo', data?.plateNumber);
			setIsGuest(false);
		}else{
			setValue('FK_UserUniqueID', '');
			setValue('PhoneNumber', '');
			setValue('EmailID', '');
			setValue('plateNo', '');
			setIsGuest(false);
		}
	};
	console.log('errors', errors);

	const confirmApprove = () =>{
		let postData: any = new FormData();
		console.log('CONFIRMDATa',confirmData)
		setConfirmModal(false)
		if(isGuest){
			postData.append('gustname', userName);
			if(ifViolation?.typeOfViolation===1 || ifViolation?.typeOfViolation==="1")
				postData.append('plateNo', confirmData.plateNo);
			postData.append('EmailID', confirmData.EmailID);
			postData.append('FK_UserType', 0);
			postData.append('Utype', 0);//replacement for usertype
			postData.append('FK_UserUniqueID', 0);

		}else{
			if(ifViolation?.typeOfViolation===1 || ifViolation?.typeOfViolation==="1")
				postData.append('plateNo', confirmData.plateNo);
			postData.append('vehicleID', selectedUser?.vehicleID);
			postData.append('FK_UserUniqueID', confirmData.FK_UserUniqueID);
			postData.append('FK_UserType', selectedUser?.isVisitor === 0  ? 1 : 2);
			postData.append('Utype', selectedUser?.isVisitor === 0  ? 1 : 2);//replacement for usertype
		}
	
		postData.append('FK_ViolationType', confirmData.FK_ViolationType);
			postData.append('FK_ReportedBay', confirmData?.FK_ReportedBay ?? 0);

		postData.append('FK_ZoneID', confirmData.FK_ZoneID);
		postData.append('FK_SiteID',confirmData.FK_SiteID);
		postData.append('VDate', moment(confirmData?.VDate).format('YYYY-MM-DD'));
		postData.append('VTime', moment(confirmData?.VDate).format('hh:mm:00'));
		if (confirmData.Images && confirmData.Images.length !== 0)
			for (let i = 0; i < confirmData.Images.length; ++i) {
				postData.append('Images', confirmData.Images[i]);
			}
		postData.append('Remarks', confirmData.Remarks);
		const Success = (res: any) => {
			setLoading(false);
			setIsGuest(false);
			SuccesToast(res?.response?.data);
			fetch_report_violations_list(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': REPORTVIOLATION_DATA?.REPORT_VIOLATION_PAGINATION?.pageNo ?? 1,
					'limit': REPORTVIOLATION_DATA?.REPORT_VIOLATION_PAGINATION?.limit ?? 1,
				},
			});
			reset();

			setfile([]);

			setUserName('');
			setselectedZones([]);
			setUsersArr([]);
			setConfirmModal(false)
			setConfirmData(null)
			props.handleClose();
		};
		const Failed = (err: any) => {
			setLoading(false);
			setConfirmModal(false)
			setConfirmData(null)
			FailedToast(err?.response)		;
		};

		add_report_violations('post', postData, Success, Failed);
	}

	const onSubmitHandler = (data: any) => {
		console.log('data', data);
		setLoading(true);
		setConfirmData(data)
		setConfirmModal(true)
	};

	const closeAndClear = () => {
		reset();
		clearErrors();
		setfile([]);
		setUserName('');
		setselectedZones([]);
		setUsersArr([]);
		setifZone(null);
		setifBay(null);
		setifViolation(null);
		setIsGuest(false);
		setselectedUser('')
		setTriggerSelUser(false)
		props.handleClose();
	};

	const fetchViolationForZone = (id?:number) =>{
		getViolationTypeByZone(id)?.then((res:any)=>{
			console.log(res)
			setViolationTypeArr(res?.response?.data)
		})
		
	}

	// ? Check value

	const [ifZone, setifZone] = useState<any>(null);

	const [ifBay, setifBay] = useState<any>(null);
	const [ifViolation, setifViolation] = useState<any>(null);
	const setLevelSite = (val:any) => {
		console.log('VALlevel=>',val)
		if(val?.site)
			setValue('FK_SiteID',val?.site)
	}

	const getDisabledTime = (curr: any) => {
		//console.log('CURR=>',curr,type,dayjs(new Date()).diff(date[0],'hour'),dayjs(date[0]).diff(dayjs(new Date()),'hour'),dayjs(date[0]).format('DD-MM-YYYY'))
		// return {
		// 	disabledHours: () => [4,7],
		// 	disabledMinutes: () => [30,50],
		// 	disabledSeconds: () => [55, 56],
		// }
		if (curr && dayjs().diff(curr, 'hour') === 0) {
			let obj = {
				disabledHours: () => getDisabledHours(),
			}
			if(dayjs().diff(curr,'hour')===0)
				return {...obj, disabledMinutes: () => {
					console.log('MinuteDisable')
					return getDisabledMinutes(dayjs(curr).get('hour'));
				} }
			return obj
		} else {
			return {};
		}
	};

	const getDisabledHours = () => {
		var hours = [];
		for (var i = dayjs().get('hour')+1; i <=24; i++) {
			hours.push(i);
		}
		return hours;
	};

	const getDisabledMinutes = (selectedHour: any) => {
		console.log('MINUTES', selectedHour, dayjs().get('hour'), 'selectedMin',dayjs().get('minute'));
			if (selectedHour === dayjs().get('hour')) {
				var minutes = [];
				for (var i = dayjs().get('minute')+1; i <= 59; i++) {
					minutes.push(i);
				}
				console.log('MINUTES1', selectedHour, dayjs().get('hour'), 'selectedMin',dayjs().get('minute'),minutes);
				return minutes;
			}else{
				console.log('MINUTES2', selectedHour, dayjs().get('hour'), 'selectedMin',dayjs().get('minute'));
				return []
			}
	};

	return (
		<>
		<ModalLayout
			open={props.open}
			handleClose={closeAndClear}
			big
			title='Report Violation'
			subtitle='Either search vehicle or user details, or add guest details to report a violation'>
			<form onSubmit={handleSubmit(onSubmitHandler)} action='' method='post'>
				<div className="modal-body-content" style={{ maxHeight:"65vh"}}>

				<div className='row'>
					<div className='col-12'>
						<div className='report-violation-form'>
							<div className='form-w-label modal-form'>
								<h2 className='border-title' style={{ textTransform: 'none', fontWeight: '500' }}>
									Select Area<span style={{ color: 'red', marginLeft: '.5%' }}>*</span>
								</h2>
								<div className='w-100 hr' />
								<div className='row'>
									<div className='col-6'>
										<div className='form-group'>
											<label htmlFor=''>Zone</label>
											<TreeSelectCustom
												isMulti={false}
												selectedZones={selectedZones}
												setselectedZones={setselectedZones}
												showAll={setLevelSite}
												grey={true}
												onchangeFx={(e: any) => {
													setValue('FK_ZoneID', e?.value);
													setifZone(e);
													fetchViolationForZone(e?.value)
													fetch_BayDDL(dispatch, { 'query': `fk_ZoneID=${e?.value}` });
												}}
											/>

											<ErrorComponent title={'Zone'} errors={errors} name={'FK_ZoneID'} />
										</div>
									</div>
									<div className={ifZone ? 'col-6' : 'col-6 opacity-quater'}>
										<div className='form-group'>
											<label htmlFor=''>Bay</label>
											<FormSelect
												grey
												name='FK_ReportedBay'
												control={control}
												placeholder={`Bays in Zone`}
												options={ZONELIST.BAY_DDL}
												onchangeFx={(e) => setifBay(e)}
											/>
											<ErrorComponent title={'Bay'} errors={errors} name={'FK_ReportedBay'} />
										</div>
									</div>
								</div>

								<div className='row'>
									<div className={ifZone ? 'col-12' : 'col-12 opacity-quater'}>
										<div className='form-group'>
											<label htmlFor=''>Choose Violation Type</label>
											<FormSelect
												grey
												name={'FK_ViolationType'}
												control={control}
												placeholder='Violation Type'
												options={violationTypeArr && violationTypeArr?.length>0 ? violationTypeArr?.map((el: any) => {
													return {
														value: el.vtypeID,
														typeOfViolation: el.value,
														label: `${el.violationName} - ${el.value === 1 || el.value === "1" ? 'Vehicle ' : 'User '}`,
													};
												}) : []}
												onchangeFx={(e) =>{ setifViolation(e)
													setValue('violationTypeNo', parseInt(e?.typeOfViolation))
												}}
											/>
											<ErrorComponent title={'Violation Type'} errors={errors} name={'FK_ViolationType'} />
										</div>
									</div>
								</div>

								<div className={'col-12'}>
									{isGuest ? (
										<div className={`row ${ifViolation ? '' : ' opacity-quater'}`}>
											<p className='border-title w-100' style={{ textTransform: 'none', fontWeight: '500' }}>
												 User details<span style={{ color: 'red', marginLeft: '.5%' }}>*</span>
											</p>
											<div className='w-100 hr' />

											<div className='col-6' style={{ marginBottom:'20px' }}>
												<div className='form-group'>
													<label htmlFor=''>User Name / Guest Name</label>
													<input
														type='text'
														name=''
														value={userName}
														placeholder='User Name'
														className='form-control'
														onChange={(e) => {
															setValue('PhoneNumber', 0);
															setUserName(e.target.value);
														}}
													/>
												</div>
											</div>
											{
												ifViolation && (ifViolation?.typeOfViolation===1 || ifViolation?.typeOfViolation==="1") && 
											<div className='col-6'>
												<div className='form-group'>
													<label htmlFor=''>Plate Number<span style={{ color: 'red', marginLeft: '.5%' }}>*</span></label>
													{/* <input
														type='text'
														placeholder='Plate Number'
														{...register('plateNo')}
														className='form-control'
													/> */}
													<FormInput type='text' name="plateNo" className='form-control' control={control} />
													<ErrorComponent title={'Plate Number'} errors={errors} name={'plateNo'} />
												</div>
											</div> 
											}
											<div className='col-6'>
												<div className='form-group'>
													<label htmlFor=''>Email</label>
													{/* <input
														type='text'
														placeholder='Email'
														{...register('EmailID')}
														className='form-control'
													/> */}
													<FormInput type='text' name="EmailID" className='form-control' control={control} />
													<ErrorComponent title={'Email ID'} errors={errors} name={'EmailID'} />
												</div>
											</div>
										</div>
									) : (
										<div className={`row ${ifViolation ? '' : ' opacity-quater'}`}>
											<p className='border-title w-100' style={{ textTransform: 'none', fontWeight: '500' }}>
												Find User / Vehicle
											</p>
											<div className='w-100 hr' />

											<div className='col-12'>
												<div className='col'>
													<div className='row'>
														<div className='form-group'>
															<label htmlFor=''>Find User / vehicle <span style={{ color: 'red', marginLeft: '.5%' }}>*</span></label>
															<AsyncSelect
																className={'common-select common-select-grey'}
																placeholder='Search User / Vehicle'
																defaultOptions={usersArr}
																// className={'common-select common-select-grey'}
																styles={{
																	control: (styles) => ({
																		...styles,
																		padding: '8px 0px',
																		background: 'var(--grey-primary)',
																		border: 'none',
																	}),
																	placeholder: (styles) => ({ ...styles, fontSize: '.9rem' }),
																}}
																// getOptionLabel={(o) => `${o.fullName} - ${o.plateNumber} (${o.isVisitor === 0 ? 'Employee' :'Visitor'})`}
																getOptionValue={(o) => o.plateNumber}
																loadOptions={promiseUserOptions}
																onChange={(newValue, action) => {
																	setValue('isTriggerSelUser', true)
																	if(action && action?.action==='clear'){
																		setTriggerSelUser(true)
																		setValue('isTriggerSelUser', false)
																	}else{
																		setTriggerSelUser(false)
																	}

																	handleUserChange(newValue)
																}}
																value={
																	usersArr.find(
																		(c: any) => c.plateNumber === selectedUser?.plateNumber
																	) ?? ''
																}
																isClearable={true}
																isOptionDisabled={(option) =>
																	option.violation_exception_status === 'true'
																}
																formatOptionLabel={(o) => (
																	<div className='dflex justify-space-between align-items-center'>
																		<div className='dflex col-dir gap-5'>
																			<div>
																				<span
																					style={{
																						fontSize: '.85rem',
																						fontWeight: 600,
																						textTransform: 'capitalize',
																					}}>
																					{o?.fullName} -{' '}
																				</span>
																				<small style={{ fontStyle: 'italic' }}>{o?.emailID}</small>
																			</div>
																			<div
																				style={{
																					display: 'flex',
																					flexDirection: 'row',
																					width: '100%',
																					// justifyContent: 'space-between',
																				}}>
																				<span style={{ fontSize: '.85rem', fontWeight: 600 }}>
																					{o?.plateNumber} -{' '}
																				</span>
																				<small style={{ fontStyle: 'italic' }}>
																					{o?.placeOfRegistration}-
																				</small>
																				<small style={{ fontStyle: 'italic' }}>
																					{o?.vehicleCategory}
																				</small>
																			</div>
																		</div>
				
																		<div>
																			<StatusChipComponent text={o?.isVisitor ? 'Visitor' : 'Employee'} />
																		</div>
																	</div>
																)}
															/>
															{
																triggerSelUser &&
																<p className='hookform-error-message'>
																	User/Vehicle Details required
																</p>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
												
									<div className={` ${ifViolation ? '' : ' opacity-quater'}`} >
										<div
											className='border-title col-1'
											onClick={() => {
												setIsGuest(!isGuest);
												setValue('plateNo', '');
												setValue('FK_UserUniqueID', 0);
												setValue('PhoneNumber', 0);
												setValue('EmailID', '');
												setselectedUser('');
											}}
											style={{
												color: 'var(--primary)',
												paddingLeft: 0,
												textTransform:"none",
												textDecoration:"underline",
												fontWeight :400,
												padding : '10px 5px',
												cursor:'pointer'
											}}>
											{isGuest ?  `Known User?` :`Guest?`  }
										</div>
									</div>

									<h2 className={`border-title ${ifViolation ? '' : ' opacity-quater'}`} style={{ textTransform: 'none', fontWeight: '500', marginTop: '1%' }}>
										Violation Info <span style={{ color: 'red', marginLeft: '.5%' }}>*</span>
									</h2>
									<div className='w-100 hr' />
									<div className={`row ${ifViolation ? '' : ' opacity-quater'}`}>
										<div className='col-6'>
											<div className='form-group'  style={{position:"relative"}}>
												<label htmlFor=''>Date</label>
												{/* <input type='date' {...register('VDate')} className='form-control' /> */}

												<Controller
													
													name='VDate'
													control={control}
													render={({ field: { value, onChange, onBlur } }) => (
														<>
															<CustomDatePicker
												getPopupContainer={(trigger:any) => trigger.parentElement as HTMLDivElement}

																className={`form-control ${ifViolation ? '' : 'getDisabledBorder'}`}
																size='large'
																showTime
																hideIcon={!ifViolation}
																onChange={onChange}
																value={moment(value)}
																disabledDate={(current:any)=>(current > moment().add(1,'day').startOf('day') ) }
																allowClear={false}
																defaultValue={moment()}
																defaultPickerValue={moment()}
																format={'DD-MMM-YYYY - hh:mm:A'}
																setValue={setValue}
																name="VDate"
																disabledTime={(current:any) => getDisabledTime(current)}
															/>
								
														</>
													)}
												/>
												<ErrorComponent title={'Violation Date'} errors={errors} name={'VDate'} />
											</div>
										</div>
									
									</div>
									<div className={`row ${ifViolation ? '' : ' opacity-quater'}`}>
										<div className='col-12'>
											<div className='form-group'>
												<label htmlFor=''>Remarks<span style={{ color: 'red', marginLeft: '.5%' }}>*</span></label>
												{/* <textarea {...register('Remarks')} id='' rows={10} className='form-control'></textarea> */}

												<FormTextarea name="Remarks" placeholder='Remarks' className='form-control ' control={control} />
												<ErrorComponent title={'Remarks'} errors={errors} name={'Remarks'} />
											</div>
										</div>
									</div>
									<div className='row opacity-half-SingleRevoke'>
										<div className='col-12 opacity-half-SingleRevoke'>
											<div className='form-group file-upload'>
												<Controller
													control={control}
													name='Images'
													render={({ field: { onChange }, fieldState: { error }, formState }) => (
														<input
															className='form-control'
															type='file'
															id='formFileMultiple'
															capture='environment'
															multiple
															// {...register('Images')}
															onChange={(v: any) => {
																onChange(v);
																console.log(v.target.files);
																let newArr: any = [];
																Array.from(v.target.files).forEach((f) => newArr.push(f));
																setfile(newArr);
																setValue('Images', newArr);
															}}
														/>
													)}
												/>
												{file && file?.length !== 0 ? (
													<div className='parking-lot-image imageUploadDivGrid scrollNotVisibleY'>
														{file?.map((el: any) => {
															return <div className='imageGridDiv'><img src={fileToImage(el)} alt='' /></div>;
														})}
													</div>
												) : (
													<>
														<img
															src={UPLOADICON}
															alt=''
															className='upload-icon'
														/>
														<label htmlFor='formFileMultiple' className='form-label opacity-half-SingleRevoke'>
															Choose a file.
														</label>
													</>
												)}
											</div>

											<ErrorComponent title={'Images'} errors={errors} name={'Images'} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				</div>
				<div className={`col-12`}>
					<div className='modal-footer'>
						<button type='submit' className={`btn btn-secondary border-blue  ${ifViolation ? '' : ' opacity-quater'}`}
							onClick={()=>{
								if(!isGuest && selectedUser==='')
									setTriggerSelUser(true)
							}}
						>
							<ButtonLoader loading={loading} text={'Save'} />
						</button>
						<button
							type='reset'
							onClick={() => {
								reset();
								clearErrors();
								setfile([]);
								setUserName('');
								setselectedZones([]);
								setUsersArr([]);
								setifZone(null);
								setifBay(null);
								setifViolation(null);
								setIsGuest(false);
								setselectedUser('')
								setTriggerSelUser(false)
								props.handleClose();
							}}
							className='btn btn-outline-blue close-modal-button'>
							Close
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
		{confirmModal &&
			<ActionConfirmBox
				description={`Reported violation cannot be edited. Are you sure provided details are correct ?`}
				handleClose={cancelConfirm}
				open={true}
				ConfirmFx={confirmApprove}
			/>
		}
		</>
	);
};

export default ReportModal;
