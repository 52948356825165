import React from 'react'
interface Iprops {
    children: React.ReactNode;

}
const MainLayout2: React.FC<Iprops> = ({ children }) => {
    return (
        <div className="main-body-content modal-body">
            <div className="white-card data-clean mb-30">
                {children}
            </div>
        </div>
    )
}

export default MainLayout2