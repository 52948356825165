import { Field } from "react-querybuilder";
import log from "../utils/utilFunctions";

export interface FilterProps {
	field: string;
	value: any;
	valueFn?: any | null;
	operator?: 'like' | '<' | '>' | '=' | '!=' | '<=' | '>=' | 'in' | 'notIN' | 'between' | 'notBetween' | 'contains' | 'is not null' |'is null';
}

export const userSearchQuery = (val: any) => {
	if (isNaN(parseInt(val))) {
		if (val.includes(' ')) return `FirstName LIKE '${val}%' OR LastName LIKE '${val}%'`;
		else if (val.includes('@')) return `EmailID LIKE '${val}%'`;
		else if (val.includes('_')) return `LastName LIKE '${val}%' OR EmailID LIKE '${val}%'`;
		else return `FirstName LIKE '${val}%' OR LastName LIKE '${val}%' OR EmailID LIKE '${val}%'`;
	} else return `USERID LIKE '${val}%'`;
};

export const commonSearchQuery = (queryOpts: FilterProps[] , operand = "OR") => {
	let filterdQueryArray = queryOpts.filter((item) => !(item?.operator && (item?.operator==='like' || item?.operator==='contains') && item?.value==='' ) 
		&& (item?.value  || item?.value === 0));

    log(filterdQueryArray,"filterdQueryArray") 

	if (filterdQueryArray.length > 0) {
		let temp = '';
		for (let i = 0; i < filterdQueryArray.length; ++i) {
			let filterVal = filterdQueryArray[i]?.valueFn ? filterdQueryArray[i]?.valueFn(filterdQueryArray[i]?.value) : filterdQueryArray[i]?.value
			console.log('FILTERVAL=>',filterVal,'VAL=>',filterdQueryArray[i])
			if (filterVal || filterVal === 0 || filterVal === false) {
				temp += ` ${filterdQueryArray[i].field} ${filterdQueryArray[i].operator ? 
						filterdQueryArray[i].operator==='contains' ? 
							'like' : filterdQueryArray[i].operator : 'like'} ${
					filterdQueryArray[i].operator ? 
					filterdQueryArray[i].operator === 'contains' ?
						`'%${filterVal}%'` :
					filterdQueryArray[i].operator === 'like' ?
						`'${filterVal}%'` : 
					filterdQueryArray[i].operator === 'is null' || filterdQueryArray[i].operator === 'is not null' ?
					`` :
						filterVal==='true' ? 'true' : filterVal==='false' ? 'false' :
						filterVal 
						
						: `'${filterVal}%'`
				} ${i < (filterdQueryArray.length - 1 )? operand : ''}`;
			}
		}
		return `(${temp})`;
	} else {
		return ``;
	}
};

export interface ExtendedField extends Field{
	max ?: string | number,
	min ?: string | number,
	valueFn?: any | null,
}