import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PageNotFound from './common/components/PageNotFound';
import { useAppSelector } from './config/hooks';
import { routes } from './config/routes';
import { FrontendPermision } from './modules/security/screens/Roles/components/types';

const AppRouter: React.FC = () => {
	const module_permissions = useAppSelector((state) => state?.login?.USER_PERMISSION);

	const [arr, setarr] = useState<FrontendPermision[]>([]);
	const [redirectPath, setredirectPath] = useState<string>('');
	const [routePermssion, setroutePermssion] = useState<any>();

	useEffect(() => {
		setarr([...module_permissions.filter((item) => item?.user_has_access === true)]);
	}, [module_permissions]);

	useEffect(() => {
		let permissionobject: any = {};
		if (arr) {
			arr.forEach((item) => {
				permissionobject[item?.module_id] = item?.user_has_access;
			});
			setroutePermssion(permissionobject);
		}
	}, [arr]);

	useEffect(() => {
		if (routePermssion) {
			for (let i = 0; i < routes.length; i++) {
				if (routePermssion[routes[i]?.module_id] === true) {
					// navigate(routes[i]?.path)
					setredirectPath(routes[i]?.path);
					break;
				}
			}
		}
	}, [routePermssion]);

	return (
		<>
			<Routes>
				{routes.map((item, key) => {
					const { path, Component, module_id } = item;

					if (arr.some((items: any) => items?.module_id === module_id)) {
						return <Route key={key} path={path} element={<Component />} />;
					} else {
						return <Route key={key} path={path} element={<PageNotFound error={false} notFound={true} />} />;
					}
				})}
				{redirectPath && <Route path='/*' element={<Navigate to={redirectPath} replace />} />}

				<Route path='*' element={<PageNotFound error={false} notFound={true} />} />
			</Routes>
		</>
	);
};

export default AppRouter;
