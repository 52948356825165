import Request from "../../../config/api";
import { APPLICATION_SETTINGS_GENERAL, DEFINITION_URL } from "../../../config/endpoint"
import { RESET_STATE, UPDATE_DEFINITION_DATA, UPDATE_DEFINITION_ERROR, UPDATE_DEFINITION_LOADER } from "./definitionSlice"
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_definitionList = ( history:any, dispatch:any, postData:any, path:string  ,signal?:AbortSignal) => {
    
    // dispatch(RESET_STATE())
    dispatch(UPDATE_DEFINITION_LOADER({
        loader: true
    }))
    let api_url = DEFINITION_URL + path + '/';
    Request( path==='place-of-reg' ? 'get' : 'post', api_url, postData, signal)?.then((res)=> {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200 && data && typeof data!=='string'){      //check data is array. Getting Error in data as string causing app to CRASH
            dispatch(
                UPDATE_DEFINITION_DATA({
                    data,
                    pagination : pagination?? { pageNo: 1, limit: 10, totalCount: data?.length??0 },
                    filters : filters??{ query: "" },
                })
            )
        }
        else {
            dispatch(
                UPDATE_DEFINITION_ERROR({
                    error : 'Some error occured'
                })
            )
        }
    })
}

export const definiton_add_edit = (type:t, postData:Object, path:string, Success: (res:any)=>void, Failed:(err:any)=>void) => {
    let api_url = DEFINITION_URL + path + '/action/'
    Request(type, api_url, postData, undefined, Success, Failed)?.then((res) => {
        const { statusCode } = res
        if(statusCode === 200){

        }else{

        }
    })
}

export const definiton_delete = (id:any, path:string, Success: (res:any)=>void, Failed:(err:any)=>void ) => {
    let api_url = DEFINITION_URL + path + '/action/' + id + '/'
    Request('delete',api_url,'',undefined,Success, Failed)?.then((res)=>{
        const {statusCode} = res
        if(statusCode===200){}
        else {}
    })
}

export const get_general_settings_list = (success: (res: any) => void, failed: (res: any) => void) => {
	let postData = {
		'AppKey': '',
	};
	let api_url = APPLICATION_SETTINGS_GENERAL;
	Request('post', api_url, postData, undefined, success, failed)
};