import React, { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { delete_visitor, fetch_visitorlist, fetch_visitor_detail, visitor_vehice_action } from '../../store/action';

import ErrorComponent from '../../../../common/components/ErrorComponent';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import ModalLayout from '../../../../common/layouts/ModalLayout';
import { fetch_placeOfRegList } from '../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import FormSelect from '../../../../common/components/FormSelect';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
	visitorID?: any;
	TriggerApi?: () => void;
};

const schema = yup.object().shape({
	vVehicleID: yup.number(),
	fK_VisitorID: yup.number().required(),
	plateNumber: yup.string().required(),
	placeOfRegistration: yup.string().required(),
	brand: yup.string(),
	colour: yup.string(),
	vehicleCategory: yup.string().required(),
	descriptions: yup.string(),
	// isHide: yup.boolean(),
});

const AddVehicleModal = (props: Props) => {
	const [loading, setloading] = useState(false);
	// const VEHICLEPLATE_DDL = useAppSelector((state) => state?.general?.VEHICLEPLATE_DDL);
	// const USERS_DATA = useAppSelector((state) => state?.user);
	
	const PLACEOFREG_DDL = useAppSelector((state) => state?.general?.PLACEOFREG_DDL);


	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		control,
		setValue,
		clearErrors,
	} = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	console.log('errors', errors);

	console.log(props?.Data);

	useEffect(() => {
		if (props?.Data) {
			setValue('vVehicleID', props?.Data?.vVehicleID);
			setValue('plateNumber', props?.Data?.plateNumber);
			setValue('placeOfRegistration', props?.Data?.placeOfRegistration);
			setValue('brand', props?.Data?.brand);
			setValue('colour', props?.Data?.colour);
			setValue('vehicleCategory', props?.Data?.vehicleCategory);
			setValue('descriptions', props?.Data?.descriptions);
			setValue('isHide', props?.Data?.isHide);
		}
	}, [props?.Data, props?.visitorID, setValue]);

	useEffect(() => {
		if (props?.visitorID) {
			setValue('fK_VisitorID', props?.visitorID);
		}
	}, [props, setValue]);

	console.log('ddd', watch('fK_VisitorID'));

	const onSubmitHandler = (data: Object) => {
		setloading(true);
		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			if (props?.TriggerApi) {
				props?.TriggerApi();
			}
			setloading(false);
			reset();
			handleClose();
		};
		const Failed = (err: any) => {
			setloading(false);
			FailedToast(err?.response)		;
		};

		if (props?.Data) {
			visitor_vehice_action('put', data, Success, Failed);
		} else {
			visitor_vehice_action('post', data, Success, Failed);
		}
	};

	const handleClose = () => {
		clearErrors();
		reset();
		props.handleClose();
	};
	return (
		<Fragment>
			<ModalLayout open={props?.open} handleClose={handleClose} title={props?.Data ? 'Update Vehicle' : 'Add Vehicle'}>
				<form onSubmit={handleSubmit(onSubmitHandler)} className='w-100 full-height'>
					<div className='row'>
						<div className='col-12'>
							<div className='row'>
								<div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label> Plate Number </label>

										<Controller
											name='plateNumber'
											control={control}
											render={({ field: { value, onChange, onBlur } }) => (
												<>
													<input
														type='text'
														className='form-control'
														id=''
														value={value}
														onChange={(e) => {
															let v = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9s]/g, '');
															onChange(v);
														}}
													/>
												</>
											)}
										/>
										<ErrorComponent errors={errors} name='plateNumber' title='Plate Number ' />
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label> Place Of Registration </label>
										<FormSelect
											control={control}
											name='placeOfRegistration'
											options={PLACEOFREG_DDL || []}
											grey={true}
											classFx='form-control grey-input'
											isMulti={false}
											placeholder=''
										/>
										{/* <input className='form-control grey-input' type='text' {...register('placeOfRegistration')} /> */}
										<ErrorComponent errors={errors} name='placeOfRegistration' title='Place Of Registration' />
									</div>
								</div>

								<div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label> Vehicle Category </label>
										<input className='form-control grey-input' type='text' {...register('vehicleCategory')} />
										<ErrorComponent errors={errors} name='vehicleCategory' title='Vehicle Category' />
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label> Brand </label>
										<input className='form-control grey-input' type='text' {...register('brand')} />
										<ErrorComponent errors={errors} name='userID' title='UserId' />
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label> Colour </label>
										<input className='form-control grey-input' type='text' {...register('colour')} />
										<ErrorComponent errors={errors} name='userID' title='UserId' />
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label> Description </label>
										<input className='form-control grey-input' {...register('descriptions')} />
										<ErrorComponent errors={errors} name='userID' title='UserId' />
									</div>
								</div>
								{/* <div className='col-6'>
									<div className='form-group inline-input full-label'>
										<label>Hide Vehicle</label>
										<label className='switch'>
											<input type='checkbox' {...register('isHide')} />
											<span className='slider'></span>
										</label>
										<ErrorComponent errors={errors} name='userID' title='UserId' />
									</div>
								</div> */}
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-12'>
							<div className='modal-footer'>
								<div className='btn btn-outline-blue close-modal-button' onClick={handleClose}>
									close
								</div>
								<button type='submit' className='btn btn-secondary border-blue' disabled={loading}>
									<ButtonLoader text={props?.Data ? ' Update' : 'Add'} loading={loading} />
								</button>
							</div>
						</div>
					</div>
				</form>
			</ModalLayout>
		</Fragment>
	);
};

export default AddVehicleModal;
