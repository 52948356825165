import Request from '../../../config/api';
import { VISITOR, VISITOR_ACTION, VISITOR_VEHICLE_ACTION } from '../../../config/endpoint';
import { UPDATE_VISITORS_DATA, UPDATE_VISITORS_LOADER ,UPDATE_VISITORS_ERROR} from './visitorSlice';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_visitorlist = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = VISITOR;

    dispatch(UPDATE_VISITORS_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_VISITORS_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_VISITORS_ERROR({
                error: "Some error occured",
            }));
        }
    });
};


export const visitor_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = VISITOR_ACTION;
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};


export const fetch_visitor_detail = (id: number, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = VISITOR + id + '/';
    return Request('get', api_url, '', undefined, Sucess, Failed)
};

export const delete_visitor = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = VISITOR_ACTION + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};


export const visitor_vehice_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = VISITOR_VEHICLE_ACTION;
    Request(type, api_url, postData, undefined, Sucess, Failed)
};

export const delete_visitor_vehicle = (id: number, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = VISITOR_VEHICLE_ACTION + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)
};