/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import AddModal from './AddModal';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { fetch_userslist } from '../../store/action';
// import { Field } from 'react-querybuilder';
import ReactSelect from 'react-select';
import { fetch_departmentDDL, fetch_designationDDL, fetch_priorityLevelDDL, fetch_userTypeDDL } from '../../../../common/general/action';
import QueryBModal from '../../../../common/components/query_builder';
import { commonSearchQuery, ExtendedField, FilterProps } from '../../../../config/filterSearch';
import useKeyboard from '../../../../utils/hooks/useKeyboard';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../config/subModuleList';

const FilterBar = () => {
	const dispatch = useAppDispatch();
	const [addModal, setAddModal] = useState(false);
	const [advanceModal, setAdvanceModal] = useState(false);

	const [userType, setUserType] = useState<any>('');
	const [userSearch, setUserSearch] = useState<any>('');
	const [priority, setPriority] = useState<any>('');
	const [designation, setDesignation] = useState<any>('');
	const [department, setDepartment] = useState<any>('');

	const clearRef = useRef<any>();

	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);
	const addModalOpen = () => setAddModal(true);
	const addModalClose = () => setAddModal(false);
	const USERS_DATA = useAppSelector((state) => state?.user);
	const GENERAL_DDL = useAppSelector((state) => state?.general);

	useEffect(() => {
		const controller = new AbortController();
		fetch_departmentDDL(dispatch, controller.signal);
		fetch_priorityLevelDDL(dispatch, controller.signal);
		fetch_designationDDL(dispatch, controller.signal);
		fetch_userTypeDDL(dispatch, controller.signal);
		return () => controller.abort();
	}, [dispatch]);

	const fields: ExtendedField[] = [
		{ name: 'FirstName', label: 'USER NAME' },
		{ name: 'EmailID', label: 'EMAIL' },
		{ name: 'ContactNo', label: 'CONTACT No' },
		// { name: 'DOB', label: 'DATE OF BIRTH', inputType: 'date' },
		// { name: 'CAST (CreatedOn as smalldatetime)', label: 'CREATED DATE', inputType: 'datetime' },
		{ name: 'CAST (CreatedOn as date)', label: 'CREATED DATE', inputType: 'datetime' },

		// { name: 'FK_UserType', label: 'USER TYPE', inputType: 'number' },

		{ name: 'FK_UserType', label: 'USER TYPE', inputType:'select' ,	values: [
			{ name: "1", label: "Permanent" },
			{ name: "2", label: "Temporary" }
		  ] },
		{ name: 'FK_PriorityLevel', label: 'PERSONAL GRADE', inputType: 'text' },
		// { name: 'FK_DesignationID', label: 'DESIGNATION ID', inputType: 'number' },
		{ name: 'desName', label: 'DESIGNATION NAME'},
		// { name: 'FK_DepartmentID', label: 'DEPARTMENT ID', inputType: 'number' },
		{ name: 'depName', label: 'DEPARTMENT NAME' },

		// { name: 'FK_SiteID', label: 'SITE ID', inputType: 'number' },
		{ name: 'siteName', label: 'SITE NAME' },

	];

	const deployQueryFilter = (query: string) => {
		fetch_userslist('', dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_DATA.USER_PAGINATION.limit,
			},
		});
		advancemodalClose();
		setUserType('');
		setUserSearch('');
		setPriority('');
		setDepartment('');
		setDesignation('');
	};

	const clearSearch = () => {
		fetch_userslist('', dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_DATA.USER_PAGINATION.limit,
			},
		});
		setUserType('');
		setUserSearch('');
		setPriority('');
		setDepartment('');
		setDesignation('');
	};

	const filterSearch = () => {
		let QueryInput: FilterProps[] = [
			// {
			// 	field: 'FirstName',
			// 	value: userSearch,
			// 	operator: 'contains',
			// },

			{
				field: 'FK_UserType',
				value: userType,
				operator: '=',
			},

			{
				field: 'FK_DepartmentID',
				value: department,
				operator: '=',
			},
			{
				field: 'FK_DesignationID',
				value: designation,
				operator: '=',
			},
		];

		fetch_userslist('', dispatch, {
			'filters': {
				'query': `${userSearch!=='' ? `FirstName like '%${userSearch}%' OR gCompanyName like '%${userSearch}%' ${commonSearchQuery(QueryInput, 'AND')!==''? ' AND ' : ''}` : ''}${commonSearchQuery(QueryInput, 'AND')}`,
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_DATA.USER_PAGINATION.limit,
		},
		});

		// dispatch(UPDATE_USERS_FILTER({ filters :{ 'query': commonSearchQuery(QueryInput, 'AND')} }))
	};

	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	const handleInputChangeDepartment = (inputValue:string) => {
		if(inputValue) fetch_departmentDDL(dispatch, undefined ,inputValue);
		
 	}


	 const handleInputChangeDesignation = (inputValue:string) => {
		if(inputValue) fetch_designationDDL(dispatch, undefined ,inputValue);
 	}



	return (
		<>
			<div className='search-filter-row' ref={ref}>
				<div className='row'>
					<div className='col-8'>
						<ul>
							<li>
								<a className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							<li>
								<a onClick={() => advancemodalOpen()}>
									<i className='fa-solid fa-filter'></i>Advanced Filter
								</a>
							</li>
							{USERS_DATA.USER_FILTER?.query !== '' && (
								<li>
									<a onClick={clearSearch}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
						</ul>
					</div>
					<PermissionWrapper subModuleID={subModuleList.Users} type='isCreate'>
						<div className='col-4 text-right'>
							<a className='btn btn-secondary modal-button' onClick={() => addModalOpen()}>
								Add New User
							</a>
						</div>
					</PermissionWrapper>
				</div>

				<div className='filter-block' id='quick-search'>
					<div className='row'>
						<div className='col'>
							<div className='form-group'>
								<div className='pos-rel'>
									<input
										type='text'
										name=''
										placeholder='Search Name'
										className='form-control'
										value={userSearch}
										onChange={(e) =>{
											let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
											setUserSearch(v)}}
									/>
									<i className='fa-solid fa-magnifying-glass'></i>
								</div>
							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
								<ReactSelect
									ref={clearRef}
									options={GENERAL_DDL?.USERTYPE_DDL}
									className={'common-select'}
									placeholder={'User Type'}
									isMulti={false}
									isClearable
									value={GENERAL_DDL?.USERTYPE_DDL.find((c) => c.value === userType) ?? ''}
									onChange={(val: any) => {
										setUserType(val?.value);
									}}
								/>
							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
								<ReactSelect
									ref={clearRef}
									options={GENERAL_DDL?.DESIGNATION_DDL}
									className={'common-select'}
									placeholder={'Designation'}
									onInputChange={handleInputChangeDesignation}	
									isMulti={false}
									isClearable
									value={GENERAL_DDL?.DESIGNATION_DDL?.find((c: any) => c.value === designation) ?? ''}
									onChange={(val: any) => {
										setDesignation(val?.value);
									}}
								/>
							</div>
						</div>

						<div className='col'>
							<div className='form-group'>
								<ReactSelect
									ref={clearRef}
									options={GENERAL_DDL?.DEPARTMENT_DDL}
									className={'common-select'}
									placeholder={'Department'}
									onInputChange={handleInputChangeDepartment}
									isMulti={false}
									isClearable
									value={GENERAL_DDL?.DEPARTMENT_DDL?.find((c) => c.value === department) ?? ''}
									onChange={(val: any) => {
										setDepartment(val?.value);
									}}
								/>


								
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<button
									onClick={() => {
										filterSearch();
									}}
									className='form-control blue-btn'>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />

			{addModal && <AddModal open={addModal} handleClose={addModalClose} />}
		</>
		// <div className="search-row-button">
		// 	<div className="row">
		// 		<div className="col-3">
		// 				<div className="form-group">
		// 					<div className="pos-rel">
		// 						<input className="form-control grey-input" name="" placeholder="Search Here" onChange={(e) => search(e.target.value)} />
		// 						<i className="fa-solid fa-magnifying-glass"></i>
		// 					</div>
		// 				</div>
		// 		</div>
		// 		<div className="col-9 text-right">
		// 			<a className="btn btn-secondary btn-w-icon modal-button" onClick={() => addModalOpen()}>Add New User</a>
		// 		</div>
		// 	</div>
		// 	{addModal && <AddModal open={addModal} handleClose={addModalClose} />}

		// </div>
	);
};

export default FilterBar;
