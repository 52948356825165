import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';


type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	PARKING_REQUEST_LIST_DATA: [],
	PARKING_REQUEST_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	PARKING_REQUEST_LOADER: true,
	PARKING_REQUEST_FILTER:  {query: "" } as FilterObject,
	PARKING_REQUEST_ERROR: '',

	PARKING_REQUESTS_COUNT: { pending: 0, approved: 0, rejected: 0, revoked: 0, totalCount: 0 },

	BULK_PARKING_REQUEST_LIST_DATA: [],
	BULK_PARKING_REQUEST_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	BULK_PARKING_REQUEST_LOADER: true,
	BULK_PARKING_REQUEST_FILTER:  {query: " RequestStatus=0" } as FilterObject,
	BULK_PARKING_REQUEST_ERROR: '',
};

export const PARKING_REQUESTSLICE = createSlice({
	name: 'PARKING_REQUEST',
	initialState: initialState,
	reducers: {
		UPDATE_PARKING_REQUESTS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.PARKING_REQUEST_LIST_DATA = data;
			state.PARKING_REQUEST_PAGINATION = pagination;
			state.PARKING_REQUEST_FILTER = filters;
			state.PARKING_REQUEST_LOADER = false;
			state.PARKING_REQUEST_ERROR = '';
		},
		UPDATE_PARKING_REQUESTS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.PARKING_REQUEST_LOADER = loader;
		},
		UPDATE_PARKING_REQUESTS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.PARKING_REQUEST_ERROR = error;
			state.PARKING_REQUEST_LOADER = false;

		},
		
		BULK_UPDATE_PARKING_REQUESTS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.BULK_PARKING_REQUEST_LIST_DATA = data;
			state.BULK_PARKING_REQUEST_PAGINATION = pagination;
			state.BULK_PARKING_REQUEST_FILTER = filters;
			state.BULK_PARKING_REQUEST_LOADER = false;
			state.BULK_PARKING_REQUEST_ERROR = '';
		},
		BULK_UPDATE_PARKING_REQUESTS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.BULK_PARKING_REQUEST_LOADER = loader;
		},
		BULK_UPDATE_PARKING_REQUESTS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.BULK_PARKING_REQUEST_ERROR = error;
			state.BULK_PARKING_REQUEST_LOADER = false;

		},
		

		UPDATE_PARKING_REQUESTS_COUNT: (state, action) => {
			const { count } = action.payload;
			state.PARKING_REQUESTS_COUNT = count
		},

		RESET_STATE: () => {
			return {...initialState}
		}
		
	
	},
});

export const { UPDATE_PARKING_REQUESTS_DATA, UPDATE_PARKING_REQUESTS_LOADER, UPDATE_PARKING_REQUESTS_ERROR, UPDATE_PARKING_REQUESTS_COUNT, RESET_STATE,
	BULK_UPDATE_PARKING_REQUESTS_DATA, BULK_UPDATE_PARKING_REQUESTS_ERROR, BULK_UPDATE_PARKING_REQUESTS_LOADER } = PARKING_REQUESTSLICE.actions;
export const PARKING_REQUESTData = (state: RootState) => state;
export default PARKING_REQUESTSLICE.reducer;
