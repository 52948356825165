import React, { useEffect } from 'react'
import FilterBar from './components/FilterBar'
import RealTimeGraph from './components/RealTimeGraph'
import { fetch_floorLevelDDL, fetch_siteDDL, fetch_zoneDDL } from '../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { UPDATE_ZONE_DDL } from '../../../../common/general/generalSlice';

type Props = {}

const AvilabilityCheck = (props: Props) => {
  let dispatch = useAppDispatch()


	useEffect(() => {
		const controller = new AbortController();
        fetch_siteDDL(dispatch, controller.signal)
        // fetch_floorLevelDDL(dispatch)
	    	// fetch_zoneDDL(dispatch)
        dispatch(UPDATE_ZONE_DDL({ data : [] }))
		
		return () => controller.abort();
    }, [dispatch]);

 
    
  return (
    <div className="main-body-content license">
      <div className="white-card data-clean mb-30">
        <FilterBar/>
        <RealTimeGraph/>
      </div>
    </div>
  )
}

export default AvilabilityCheck