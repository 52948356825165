import auth from '../config/auth';
import { languages } from './../config/language';
import { DEBUG } from './../config/endpoint';

export const get_keyword = (key: Ikey) => {
	let lang: any = auth.get_lang();
	if (!lang) {
		lang = 'english';
	}
	if (Object.keys(languages?.english).length !== Object.keys(languages?.arabic).length) {
		if (!DEBUG) return

		console.error(`keys do not match in language.ts`);
		console.log(`%c↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓`, 'color: red;');

		console.log(`keys do not match in language.ts`);
		console.log('English:', Object.keys(languages?.english).length);
		console.log('Arabic:', Object.keys(languages?.arabic).length);

		console.log(`%c↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑`, 'color: red;');
	}
	if (key && lang) return languages[lang][key];


};

type Ikey =
	| 'findvehicles'
	| 'realtimemonitoring'
	| 'parkingrequests'
	| 'violations'
	| 'users'
	| 'visitors'
	| 'policies'
	| 'reportsanddashboards'
	| 'parkingzones'
	| 'notifications'
	| 'log'
	| 'definitions'
	| 'applicationsettings'
	| 'security'
	| 'common_save_button_01'
	| 'common_submit_button_02'
	| 'common_upload_button_03'
	| 'common_open_button_04'
	| 'common_edit_button_05'
	| 'common_chooseFile_text_06'
	| 'common_view_button_07'
	| 'common_cancel_button_08'
	| 'common_back_button_09'
	| 'common_close_button_10'
	| 'dashboard_1'
	| 'dashboard_subtitle_2'
	| 'security_3'
	| 's_r_title_4'
	| 's_r_subtitle_5'
	| 's_r_6'
	| 's_r_add_7'
	| 's_r_add_subtitle_8'
	| 's_r_add_id_9'
	| 's_r_add_name_10'
	| 's_r_add_remarks_11'
	| 's_r_add_module_12'
	| 's_r_add_view_13'
	| 's_r_add_create_14'
	| 's_r_add_edit_15'
	| 's_r_add_delete_16'
	| 's_r_add_fullcontrol_17'
	| 's_r_add_users_18'
	| 's_r_add_parking_requests_19'
	| 's_r_add_policies_20'
	| 's_r_add_parking_policy_21'
	| 's_r_add_violations_policy_22'
	| 's_r_add_other_confirmation_23'
	| 's_r_add_dashboard_24'
	| 's_r_add_role_button_25'
	| 's_r_edit_role_button_26'
	| 's_r_cancel_button_27'
	| 's_wu_title_28'
	| 's_wu_subtitle_29'
	| 's_wu_30'
	| 's_wu_requestid_31'
	| 's_wu_requestdate_32'
	| 's_wu_requestby_33'
	| 's_wu_requestedfor_34'
	| 's_wu_requestedzone_35'
	| 's_wu_action_36'
	| 's_wu_status_37'
	| 's_wu_search_button_38'
	| 's_wu_quicksearch_39'
	| 's_wu_status_40'
	| 's_wu_requestedrole_41'
	| 's_wu_requestedby_42'
	| 's_wu_requestid_43'
	| 's_wu_v_title_44'
	| 's_wu_v_username_45'
	| 's_wu_v_userid_46'
	| 's_wu_v_useremail_47'
	| 's_wu_v_usercontact_48'
	| 's_wu_v_requestfor_49'
	| 's_wu_v_role_50'
	| 's_wu_v_request_username_51'
	| 's_wu_v_request_userid_52'
	| 's_wu_v_request_useremail_53'
	| 's_wu_v_request_designation_54'
	| 's_wu_v_request_startdate_55'
	| 's_wu_v_request_company_56'
	| 's_wu_v_request_contactno_57'
	| 's_wu_v_request_grade_58'
	| 's_wu_v_request_site_59'
	| 's_wu_v_request_enddate_60'
	| 's_wu_v_zoneinfo_61'
	| 's_wu_v_zoneinfo_zone_62'
	| 's_wu_v_userresponsibility_63'
	| 's_wu_v_userresponsibility_sub_64'
	| 's_wu_v_selectstatus_65'
	| 's_wu_v_selectrole_66'
	| 's_wu_v_remarks_67'
	| 's_wu_v_submit_button_68'
	| 's_wu_v_cancel_button_69'
	| 's_wu_add_title_70'
	| 's_wu_add_subtitle_71'
	| 's_wu_add_appuserid_72'
	| 's_wu_add_firstname_73'
	| 's_wu_add_lastname_74'
	| 's_wu_add_userroles_75'
	| 's_wu_add_accessprivilege_76'
	| 's_wu_add_usertypes_77'
	| 's_wu_add_emailaddress_78'
	| 's_wu_add_loginmethod_79'
	| 's_wu_add_passwordreset_80'
	| 's_wu_add_usercredential_81'
	| 's_wu_add_save_button_82'
	| 's_wu_add_close_button_83'
	| 'security_passwordpolicy_title_84'
	| 'security_passwordpolicy_subtitle_85'
	| 'security_passwordpolicy_subtitle_86'
	| 's_paspol_minimumpassword_87'
	| 's_paspol_req_upper_88'
	| 's_paspol_req_lower_89'
	| 's_paspol_req_num_90'
	| 's_paspol_req_symb_91'
	| 's_paspol_expire_92'
	| 's_paspol_expire_days_93'
	| 's_paspol_enforce_94'
	| 's_paspol_password_rem_95'
	| 's_paspol_password_update_96'
	| 'definitions_97'
	| 'definitions_title_98'
	| 'definitions_subtitle_99'
	| 'def_department_100'
	| 'def_designation_101'
	| 'def_location_102'
	| 'def_rejection_reason_103'
	| 'def_add_department_104'
	| 'def_add_depId_105'
	| 'def_add_depName_106'
	| 'def_add_depDesc_107'
	| 'def_add_defaultCheck_108'
	| 'def_add_submit_109'
	| 'def_table_id_110'
	| 'def_table_name_111'
	| 'def_table_addedBy_112'
	| 'def_table_default_113'
	| 'def_table_action_114'
	| 'logs_115'
	| 'logs_subtitle_116'
	| 'logs_applicationLog_117'
	| 'logs_auditLog_118'
	| 'logs_dateTime_119'
	| 'logs_module_120'
	| 'logs_userId_121'
	| 'logs_eventType_122'
	| 'logs_eventCategory_123'
	| 'logs_app_search_124'
	| 'logs_action_125'
	| 'logs_table_id_126'
	| 'logs_table_datetime_127'
	| 'logs_table_username_129'
	| 'logs_table_interface_137'
	| 'logs_app_userid_128'
	| 'logs_app_eventid_130'
	| 'logs_app_eventtype_131'
	| 'logs_app_eventdesc_132'
	| 'logs_app_status_133'
	| 'logs_app_errorcode_134'
	| 'logs_app_desc_135'
	| 'logs_app_src_136'
	| 'logs_audit_performed_138'
	| 'logs_audit_module_139'
	| 'logs_audit_identifier_140'
	| 'logs_audit_ip_141'
	| 'audit_log_viewer_title_142'
	| 'audit_log_viewer_descp_143'
	| 'a_log_v_module_144'
	| 'a_log_v_userid_145'
	| 'a_log_v_identifier_146'
	| 'a_log_v_datetime_147'
	| 'a_log_v_action_148'
	| 'a_log_v_username_149'
	| 'a_log_v_value_150'
	| 'a_log_v_parameter_151'
	| 'a_log_v_before_152'
	| 'a_log_v_after_153'
	| 'a_log_v_close_button_154';
