import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
type getConfigurableProps = {
    showArrows              ?: boolean,
    showStatus              ?: boolean,
    showIndicators          ?: boolean,
    infiniteLoop            ?: boolean,
    showThumbs              ?: boolean,
    useKeyboardArrows       ?: boolean,
    autoPlay                ?: boolean,
    stopOnHover             ?: boolean,
    swipeable               ?: boolean,
    dynamicHeight           ?: boolean,
    emulateTouch            ?: boolean,
    autoFocus               ?: boolean,
    thumbWidth              ?: number,
    selectedItem            ?: number,
    interval                ?: number,
    transitionTime          ?: number,
    swipeScrollTolerance    ?: number,
    ariaLabel               ?: string,
    imageArr                :any
}

const ImageCarousel = ({
    showArrows              = true,
    showStatus              = true,
    showIndicators          = true,
    infiniteLoop            = true,
    showThumbs              = true,
    useKeyboardArrows       = true,
    autoPlay                = true,
    stopOnHover             = true,
    swipeable               = true,
    dynamicHeight           = false,
    emulateTouch            = true,
    autoFocus               = false,
    thumbWidth              = 100,
    selectedItem            = 0,
    interval                = 4000,
    transitionTime          = 500,
    swipeScrollTolerance    = 5,
    ariaLabel               = '',
    imageArr
}: getConfigurableProps) => {

    const createCarouselItemImage = (index:number, src:string, options?:any) => (
        <div key={index} 
            onClick={()=>{
                console.log('OPENURL=>',src)     
                window.open(src,'_blank') 
            }}>
            <div style={{ }}>
                <img src={src} alt='' title='' {...options} />
            </div>
            
            {/* <p className="legend">Image {index+1}</p> */}
        </div>
    );

    const baseChildren = <div>{imageArr.map((el:any, k:number) => createCarouselItemImage(k, el.src, el))}</div>;

  return (
    <Carousel  
    showArrows              = {showArrows}
    showStatus              = {showStatus}
    showThumbs              ={showStatus}
    showIndicators          = {showIndicators}
    infiniteLoop            = {infiniteLoop}
    useKeyboardArrows       = {useKeyboardArrows}
    autoPlay                = {autoPlay}
    stopOnHover             = {stopOnHover}
    swipeable               = {swipeable}
    dynamicHeight           = {dynamicHeight}
    emulateTouch            = {emulateTouch}
    autoFocus               = {autoFocus}
    thumbWidth              = {thumbWidth}
    selectedItem            = {selectedItem}
    interval                = {interval}
    transitionTime          = {transitionTime}
    swipeScrollTolerance    = {swipeScrollTolerance}

    ariaLabel               = {""}
    >
        {baseChildren.props.children}
    </Carousel>
  )
}

export default ImageCarousel;