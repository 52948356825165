import { CircularProgress } from '@mui/material';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { auto_sync_now, fetch_sync_list, sync_now } from '../../../store/action';

type Props = {};

const ListTable = (props: Props) => {
	const dispatch = useAppDispatch();
	const SYNC_DATA = useAppSelector((state) => state?.applicationSettings);

	const fetchIdRef = useRef(0);

	const columns = React.useMemo(
		() => [
			{ Header: 'DATA', accessor: 'syncName' },
			{ Header: 'SOURCE', accessor: 'syncSource' },
			{ Header: 'LAST SYNCED ON', accessor: 'lastExicutedOn', DateFormat: true, FormatType: 'DD-MMM-YYYY hh:mm A' },
			{ Header: 'PERFORMED BY', accessor: 'updatedByUser' },

			{
				Header: 'SYNC STATUS ',
				accessor: 'syncStatus',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<label
								style={{ whiteSpace: 'nowrap' }}
								className={`${
									row?.original?.syncStatus
										? row?.original?.syncStatus === 1
											? 'badge green'
											: row?.original?.syncStatus === 2
											? 'badge dark-green'
											: row?.original?.syncStatus === 3
											? 'badge red'
											: ''
										: 'badge yellow'
								} `}>
								{row?.original?.syncStatus
									? row?.original?.syncStatus === 1
										? 'In progress'
										: row?.original?.syncStatus === 2
										? 'Success'
										: row?.original?.syncStatus === 3
										? 'Failed'
										: ''
									: 'pending'}
							</label>
						</Fragment>
					);
				},
			},
			{
				Header: 'AUTO SYNC',
				accessor: 'enableAutoSync',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							{/* <div className='form-group inline-input'>
								<label className='switch'>
									<input type='checkbox' />
									<span className='slider'></span>
									<span className='enable-text'>Enable</span>
								</label>
							</div> */}
							{row?.original?.isManualSyncOnly ? '' : <AutoSync row={row} />}
						</Fragment>
					);
				},
			},
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return <SyncNow row={row} />;
				},
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		filters: { query: string };
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_sync_list(
					dispatch,
					{
						'filters': {
							'query': filters?.query || '',
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch]
	);

	return (
		<>
			<CommonTable
				columns={columns}
				data={SYNC_DATA?.SYNC_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={SYNC_DATA?.SYNC_LIST_PAGINATION?.totalCount ?? 0}
				loading={SYNC_DATA?.GENERAL_SETTINGS_LOADER}
				page_no={SYNC_DATA?.SYNC_LIST_PAGINATION?.pageNo ?? 1}
				limit={SYNC_DATA?.SYNC_LIST_PAGINATION?.limit ?? 1}
				isPagination={false}
				filters={SYNC_DATA?.SYNC_LIST_FILTER}
			/>
		</>
	);
};

export default ListTable;

function SyncNow({ row }: any) {
	const [loader, setloader] = useState(false);
	const dispatch = useAppDispatch();
	const SYNC_DATA = useAppSelector((state) => state?.applicationSettings);

	const syncNowFx = (id: any, source: any) => {
		setloader(true);
		const sucess = (res: { response: { data: string | undefined } }) => {
			SuccesToast(res?.response?.data);
			setloader(false);
			fetch_sync_list(dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': SYNC_DATA?.SYNC_LIST_PAGINATION?.pageNo ?? 1,
					'limit': SYNC_DATA?.SYNC_LIST_PAGINATION?.limit ?? 1,
				},
			});
		};
		const failed = (err: { response: { app_data: string | undefined } }) => {
			FailedToast(err?.response?.app_data);
			setloader(false);
			fetch_sync_list(dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': SYNC_DATA?.SYNC_LIST_PAGINATION?.pageNo ?? 1,
					'limit': SYNC_DATA?.SYNC_LIST_PAGINATION?.limit ?? 1,
				},
			});
		};

		sync_now(id, source, sucess, failed);
	};

	return (
		<Fragment>
			<button
				className='btn btn-secondary border-blue'
				type='submit'
				onClick={() => {
					if (row?.original?.syncSource === 'INX') {
						syncNowFx(row?.original?.fK_SiteID, row?.original?.syncSource);
					} else {
						syncNowFx(row?.original?.dSyncID, row?.original?.syncSource);
					}
				}}
				disabled={loader}>
				<ButtonLoader text='SYNC NOW' loading={loader} />
			</button>
		</Fragment>
	);
}

function AutoSync({ row }: any) {
	const [loader, setloader] = useState(false);
	const dispatch = useAppDispatch();

	const syncNowFx = (id: any, sync: boolean) => {
		let postData = {
			'DSyncID': id,
			'EnableAutoSync': !sync,
		};
		setloader(true);
		const sucess = (res: { response: { data: string | undefined } }) => {
			SuccesToast(res?.response?.data);
			setloader(false);
			fetch_sync_list(dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				},
			});
		};
		const failed = (err: { response: { app_data: string | undefined } }) => {
			FailedToast(err?.response?.app_data);
			setloader(false);
		};

		auto_sync_now(postData, sucess, failed);
	};

	return (
		<Fragment>
			{/* <button className='btn btn-secondary border-blue' type='submit' onClick={() => syncNowFx(row?.original?.dSyncID)} disabled={loader}>
				<ButtonLoader text='SYNC NOW' loading={loader} />
			</button> */}

			<div className={loader ? 'form-group inline-input opacity-half' : 'form-group inline-input'}>
				<label className='switch'>
					<input
						type='checkbox'
						onChange={() => syncNowFx(row?.original?.dSyncID, row?.original?.enableAutoSync)}
						checked={row?.original?.enableAutoSync}
					/>
					<span className='slider'></span>
					{loader ? (
						<CircularProgress
							style={{ color: 'var(--primary)', width: '15px', height: '15px', right: '0px', top: '8px', position: 'absolute' }}
						/>
					) : (
						<span className='enable-text'></span>
					)}
				</label>
			</div>
		</Fragment>
	);
}
