import React, { Fragment, useEffect, useRef, useState } from 'react';
import ProgressBar from '../../../../common/components/graph_components/ProgressBar';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_total_managers } from '../../store/action';
// import DashboardFilterWrapper from '../DashboardFilterWrapper';
// import Select from 'react-select';
import { 
	//COLORS, 
	COLOR_FX } from '../../../../utils/Data';
import { ZoneColors } from '../../../../utils/zoneColorCodes';

type Props = {
	name: string;
};

const TotalManagersByZone = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);

	const [total, settotal] = useState(0);

	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [loading, setloading] = useState(false);

	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			FloorID: floor?.levelID,
		};

		if (floor?.levelID) {
			setloading(true);

			fetch_total_managers(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					setdata(data);
					settotal(res?.response?.totalCount);
				}
				setTimeout(() => {
					setloading(false)
				}, 1500);
			});
		}

		return () => {
			controller.abort();
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value]);

	// const childRef: any = useRef(null);

	// const handleClose = () => {
	// 	if (childRef.current) {
	// 		childRef.current.childFunction1();
	// 	}
	// };

	return (
		<div className='ItemLayout__Wrapper'>
			<div className='ItemLayout__Title'>
				<div> Total Managers
					 {/* in {floor?.levelName} */}
					 </div>
				<div>
					{/* <DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
						<div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Floor</label>
							<Select
								className='common-select-grey'
								options={DASHBOARD_DATA?.FLOOR_LIST}
								getOptionLabel={(o: any) => o.levelName}
								getOptionValue={(o: any) => o.levelID}
								onChange={(e: any) => setfloor(e)}
								value={DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''}
								placeholder='Floor'
							/>
						</div>
					</DashboardFilterWrapper> */}
				</div>
			</div>
			<div className='ItemLayout__Content'>
				<div className='bay-stats progressbar-box widget-levelMap-max-h'>
					{data.length === 0 ? (
						<div className="no_data_found_df">
							<span>
								No Data Found

							</span>
						</div>
					) : (
						<>
							{data.map((item: any, key) => {
								let perc = (item?.zCount / total) * 100;
								return (
									<Fragment key={key}>
										<ProgressBar
											value={perc}
											label={item?.zoneName}
											endLabel={`${item?.zCount}`}
											tootTipText={`Managers : ${item?.zCount} - total : ${total}`}
											color={ZoneColors(item?.zoneName)??COLOR_FX(key)}

										/>
									</Fragment>
								);
							})}


						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TotalManagersByZone;
