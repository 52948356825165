/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
// import log from './../../utils/utilFunctions';
interface Iprop {
	type?: string;
	placeholder?: string;
	value: string;
	setValue: React.Dispatch<React.SetStateAction<any>> | React.Dispatch<React.SetStateAction<any>>;
	valid?: boolean;

	setvalid?: React.Dispatch<React.SetStateAction<any>> | React.Dispatch<React.SetStateAction<any>>;


	grey?: boolean;
	disabled?: boolean;
	req_length?: number;
	islowercase?: boolean;
	isuppercase?: boolean;
	issymbol?: boolean;
	isnumber?: boolean;
}
const PasswordValidation = ({
	type = 'text',
	value,
	setValue,
	placeholder,
	req_length = 6,
	grey,
	disabled,
	islowercase,
	isuppercase,
	issymbol,
	isnumber,valid, setvalid
}: Iprop) => {

	

	const [number, setnumber] = useState(isnumber);
	const [uppercase, setuppercase] = useState(isuppercase);
	const [lowercase, setlowercase] = useState(islowercase);
	const [symbol, setsymbol] = useState(issymbol);
	const [length, setlength] = useState(false);
	const [showPass, setShowPass] = useState(false)


	const onValidate = (value: any) => {
		setValue(value);

		let length = value.length >= req_length;
		let number = /[0-9]/.test(value);
		let uppercase = /[A-Z]/.test(value);
		let lowercase = /[a-z]/.test(value);
		let symbol = /[\!\@\#\$\%\^\&\*\+\_\-\~]/.test(value);

		if(!isnumber){
			number = true
		}
		if(!islowercase){
			lowercase = true
		}
		if(!isuppercase){
			uppercase = true
		}
		if(!issymbol){
			symbol = true
		}
		setlength(length);
		setnumber(number);
		setuppercase(uppercase);
		setlowercase(lowercase);
		setsymbol(symbol);
		if(setvalid)
		setvalid(length && number && uppercase && lowercase && symbol);
	};

	return (
		<div style={{ width: '100%', position: 'relative' }}>
			<input
				style={{
					background: grey ? 'var(--grey-primary)' : 'var(--white)',
					border: valid ? '1px solid green' : value !== '' ? '' : '',
				}}
				className='form-control'
				type={showPass ? type : 'password'}
				placeholder={placeholder}
				value={value}
				autoComplete="off"
				onChange={(e) => onValidate(e.target.value)}
				// ref={ref}
				aria-autocomplete='none'
				disabled={disabled ? true : false}

			/>
			{
				type==='text' &&
				<div style={{ position:'absolute', height:'42px', opacity: '.5', display:'grid', placeItems:'center', top:0, right:'3%' }}>
				{
					!showPass ?
						<i className="fa fa-eye" aria-hidden="true" onClick={() => setShowPass(true)}></i>
						:
						<i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPass(false)}></i>
				}
				</div>
			}
			{!valid && value  && (
				<p
				className='hookform-error-message'>
					Password should have
					{!length && ` a minimum length of  ${req_length} characters `}
					{!number||!uppercase||!lowercase||!symbol ? `, and requires at least ` : ' required'}
					
					{!number && '  1 number'}
					{!number&&!uppercase ? (!lowercase || !symbol) ? ',' : ', and' : ''}
					{!uppercase && '  1 uppercase'}
					{(!number || !uppercase) ? !lowercase ? !symbol ? ', ' : ', and' : '' : ''}
					{!lowercase && '  1 lowercase'}
					{(!number||!uppercase||!lowercase) && !symbol ? ', and ': ''}
					{!symbol && ' 1 symbol'}.
				</p>
			)}
		</div>
	);
};

export default PasswordValidation;
