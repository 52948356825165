import AvgDailyVisiit from '../../../dashboard/components/DashoboardItems/AvgDailyVisiit';
import ParkingAvailability from '../../../dashboard/components/DashoboardItems/ParkingAvailability';
import HeatMapIndex from '../../../dashboard/components/DashoboardItems/HeatMap/HeatMap';
import OccupancyByType from '../../../dashboard/components/DashoboardItems/OccupancyByType';
import OccupancyOverall from '../../../dashboard/components/DashoboardItems/OccupancyOverall';
import Total24hrPark from '../../../dashboard/components/DashoboardItems/Total24hrPark';
import TotalBay from '../../../dashboard/components/DashoboardItems/TotalBay';
import TotalInOut from '../../../dashboard/components/DashoboardItems/TotalInOut';
import TotalParkingRequest from '../../../dashboard/components/DashoboardItems/TotalParkingRequest';
import TurnOverByZones from '../../../dashboard/components/DashoboardItems/TurnOverByZones';
import TurnoverOverall from '../../../dashboard/components/DashoboardItems/TurnoverOverall';
import ViolationOverall from '../../../dashboard/components/DashoboardItems/ViolationOverall';
import EnteredVehicleCount from '../../../dashboard/components/DashoboardItems/EnteredVehicleCount';
import TotalOperatorsByZone from '../../../dashboard/components/DashoboardItems/TotalOperatorsByZone';
import TotalManagersByZone from '../../../dashboard/components/DashoboardItems/TotalManagersByZone';
import AverageTimeToApproveRequest from '../../../dashboard/components/DashoboardItems/AverageTimeToApproveRequest';
import TotalSysUserPie from './../../../dashboard/components/DashoboardItems/TotalSysUserPie';
import SecurityViolations from './../../../dashboard/components/DashoboardItems/SecurityViolations';
import DwellTimeOverall from '../../../dashboard/components/DashoboardItems/DwellTimeOverall';
import DwellDistributionOverall from '../../../dashboard/components/DashoboardItems/DwellDistributionOverall';
import ViolationsByZone from '../../../dashboard/components/DashoboardItems/ViolationsByZone';
import ParkedMoreThanDayZone from '../../../dashboard/components/DashoboardItems/ParkedMoreThanDayZone';
import EntryExitOccupancyCard from '../../../dashboard/components/DashoboardItems/EntryExitOccupancyCard';

export const widgets = [
	{
		id: 1,
		type: 'simple',
		name: 'Total Bay Count',
		component: TotalBay,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 2,
		type: 'simple',
		name: 'Average Time To ApproveRequest',
		component: AverageTimeToApproveRequest,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 3,
		type: 'progress_bar',
		name: 'Average Occupancy',
		component: OccupancyOverall,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 4,
		type: 'progress_bar',
		name: 'Occupancy By Bay type',
		component: OccupancyByType,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 5,
		type: 'progress_bar',
		name: 'Parking Availability',
		component: ParkingAvailability,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 6,
		type: 'progress_bar',
		name: 'Average Violations',
		component: ViolationOverall,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 7,
		type: 'progress_bar',
		name: 'Turnover By Overall',
		component: TurnoverOverall,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 8,
		type: 'progress_bar',
		name: 'Turnover By Zone',
		component: TurnOverByZones,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{ id: 9, type: 'progress_bar', name: 'Total In/Out', component: TotalInOut, dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity } },
	{
		id: 10,
		type: 'progress_bar',
		name: 'Total Parking Request',
		component: TotalParkingRequest,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 11,
		type: 'count',
		name: 'Entered VehicleCount',
		component: EnteredVehicleCount,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{ id: 12, type: 'heat_map', name: 'Occupancy HeatMap', component: HeatMapIndex, dataGrid: { w: 5, h: 6,  minW: 5, minH: 6, x: 0, y:Infinity } },
	{
		id: 13,
		type: 'progress_bar',
		name: 'Parked More than 24Hours',
		component: Total24hrPark,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 14,
		type: 'progress_bar',
		name: 'Avg Daily Visits',
		component: AvgDailyVisiit,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 15,
		type: 'progress_bar',
		name: 'Total Operators',
		component: TotalOperatorsByZone,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 16,
		type: 'progress_bar',
		name: 'Total Managers',
		component: TotalManagersByZone,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 17,
		type: 'progress_bar',
		name: 'Total Web User Request',
		component: TotalSysUserPie,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 18,
		type: 'progress_bar',
		name: 'Manual Violations',
		component: SecurityViolations,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 19,
		type: 'line_graph',
		name: 'Dwell Time Overall',
		component: DwellTimeOverall,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 20,
		type: 'line_graph',
		name: 'Dwell Distribution Overall',
		component: DwellDistributionOverall,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 21,
		type: 'simple',
		name: 'Violations By Zone',
		component: ViolationsByZone,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 22,
		type: 'simple',
		name: 'Parked more than 24 Hours in each zone',
		component: ParkedMoreThanDayZone,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
	{
		id: 23,
		type: 'simple',
		name: 'Occupancy trend with Entry and Exit',
		component: EntryExitOccupancyCard,
		NoLayout: true,
		dataGrid: { w: 3, h: 2, minW: 3, minH: 2, x: 0, y: Infinity },
	},
];
