import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import GraphLoader from '../../../common/components/GraphLoader';
type Props = {
	title: string;
	data: any;
	line?: number;
	loader?: boolean;
	labelY?: string;
	labelX?: string;
	unitY?: string;
	unitX?: string;
	noDownload?: boolean;
	fileName?: string;
};

const colorPallet = ['#1947ba', '#ea0029', '#00953b', '#f39c12', '#8e44ad', '#f368e0', '#222f3e', '#84817a', '#33d9b2', '#f9d71b'];

const legendDiv = {
	fontWeight: 600,
	borderRadius: '5px',
	padding: '1%',
	backgroundColor: 'rgba(0,0,0,0.05)',
	left: 0,
	width: '100%',
	alignItems: 'center'
}


const DwellBarChart = (props: Props) => {

	const [opacitySet, setOpacitySet] = useState(null)


	const handleMouseEnter = (o: any) => {
		const { dataKey } = o
		setOpacitySet(dataKey);
	};

	const handleMouseLeave = () => {
		setOpacitySet(null)
	};

	let colV = 0;

	return (
		<>
			{props.loader ? <GraphLoader /> : null}
			{!props.loader && props.data && props.data?.length > 0 ? (
				<ResponsiveContainer id={`BarChartId-${props.title}`}>
					<BarChart
						data={props.data}
						margin={{
							top: 5,
							right: 20,
							left: -20,
							bottom: 5,
						}}>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis
							dataKey='name'
							padding={{ left: 20, right: 20 }}
							allowDataOverflow={true}
							interval={props.data && props.data.length > 10 ? 2 : 0}
							style={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '9.5px' }}
							tick={{ fontSize: 8, }}

						/>
						<YAxis
							// padding={{ top: 20 }}
							style={{ textTransform: 'capitalize', fontWeight: 600, fontSize: '10.5px' }}
							unit={props?.unitY}
							label={{ value: props?.labelY, angle: -90, fontWeight: 600, fontSize: '11.5px', positon: 'insideLeft', }}
							tickMargin={-5}
							tickLine={false}
							tick={{ fontSize: 8, }}
						/>
						{/* <Tooltip isAnimationActive={false}  formatter={(label:any) => label + `${props?.unitY??''}`}
                    /> */}

						<Tooltip 
						isAnimationActive={false}
							formatter={(label: any) => label + `${props?.unitY ?? ''}`}
							labelFormatter={
								(label: any, payload) => {
									//console.log('labelpayload',payload); 
									let v: any = ''
									if (payload && payload?.length > 0 ? payload[0].payload?.toolTipLabel : false)
										v = <p>{label} <br />{payload[0].payload?.toolTipLabel}</p>
									else v = label
									return v
								}
							}
						/>
						<Legend wrapperStyle={legendDiv} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ textTransform: "uppercase" }} />
						{
							props.data && Object.keys(props.data[0]) ? Object.keys(props.data[0]).map(el => {
								if (el !== 'name' && el !== 'toolTipLabel') {
									return (
										<Bar dataKey={el}
											opacity={opacitySet ? opacitySet === el ? 1 : 0.1 : 1}
											fill={colorPallet[colV < 11 ? colV++ : 0]} />
									)
								}
								else return null
							})
								: null
						}
					</BarChart>
				</ResponsiveContainer>
			) : props.loader ? null : (
				<div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
					<p>No data</p>
				</div>
			)}
		</>
	);
};

export default DwellBarChart;
