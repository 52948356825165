/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useRef, useCallback } from 'react';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_parking_transaction,  } from '../../../store/action';

type Props = {};

const ListTable = (props: Props) => {
	const fetchIdRef = useRef(0);

	const dispatch = useAppDispatch();

	const REALTIME_DATA = useAppSelector((state) => state?.realtime);

	const columns = React.useMemo(
		() => [
			{ Header: 'id', accessor: 'vtid', orderByKey : 'vtid' },

			{ Header: 'Plate Number', accessor: 'plateNo', orderByKey :  'plateNo'},

			{ Header: 'datetime ', accessor: 'transactionDateTime' , 
			orderByKey :"transactionDateTime"  ,
			 DateFormat: true ,FormatType: 'DD-MMM-YYYY hh:mm A' },

			{ Header: 'transaction type', accessor: 'type' },
			// { Header: 'date', id:"datetype" ,accessor: 'transactionDateTime'  , DateFormat: true ,FormatType: 'DD-MMM-YYYY'},
			// { Header: 'time', id:"timetype" ,accessor: 'transactionDateTime', DateFormat: true   ,FormatType: 'hh:mm a'},
			{ Header: 'zone', accessor: 'zoneName', orderByKey: 'zoneName' },
			{ Header: 'Bay', accessor: 'bayName', orderByKey: 'bayName' },

			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,
			},
		],
		[]
	);

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: any) => {
			console.log("ORDER BY", OrderBy)
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parking_transaction(
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy : OrderBy ?? 'transactionDateTime desc'
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch]
	);

	return (
		<>
			<CommonTable
				columns={columns}
				data={REALTIME_DATA?.TRANSACTION_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={REALTIME_DATA?.TRANSACTION_PAGINATION?.totalCount ?? 0}
				loading={REALTIME_DATA?.TRANSACTION_LOADER}
				page_no={REALTIME_DATA?.TRANSACTION_PAGINATION?.pageNo ?? 1}
				limit={REALTIME_DATA?.TRANSACTION_PAGINATION?.limit ?? 1}
				filters={REALTIME_DATA?.TRANSACTION_FILTER}
			/>
		</>
	);
};

export default ListTable;
