import React, { useEffect, useState } from 'react';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import {
	archive_data,
	clear_log,
	fetch_dataclean_up_list,
	fetch_general_settings_list,
	fetch_logclean_up_list,
	update_data_settings,
	update_general_settings,
	update_log_settings,
} from '../../store/action';
import { DataObject, GeneralSettingObject, LogObject } from '../../store/appSettings.interfcae';
import { useAppDispatch, useAppSelector } from './../../../../config/hooks';
import ButtonLoader from './../../../../common/components/ButtonLoader';
import { TimePicker } from 'antd';
import moment from 'moment';
// import TreeSelectCustom from '../../../../common/components/tree_select/TreeSelectCustom';
// import { fetch_zone_with_ID } from '../../../../common/general/action';

type Props = {};

const ApplicationSettings = (props: Props) => {
	let dispatch = useAppDispatch();
	useEffect(() => {
		fetch_logclean_up_list(dispatch);
		fetch_general_settings_list(dispatch);
		fetch_dataclean_up_list(dispatch);
	}, [dispatch]);

	const ApplicationSettings = useAppSelector((state) => state?.applicationSettings);

	const [logCleanUp, setlogCleanUp] = useState<LogObject>({} as LogObject);
	const [dataCleanUp, setdataCleanUp] = useState<DataObject>({} as DataObject);
	const [generalSettings, setgeneralSettings] = useState<GeneralSettingObject>({} as GeneralSettingObject);

	const [logsLoader, setlogsLoader] = useState(false);
	const [dataLoader, setdataLoader] = useState(false);
	const [generalLoader, setgeneralLoader] = useState(false);

	const [archiveLoader, setarchiveLoader] = useState(false);

	const [clearLog, setclearLog] = useState(false);

	// const [selectedZone, setselectedZone] = useState<
	// 	{
	// 		label: string;
	// 		value: any;
	// 	}[]
	// >([]);

	useEffect(() => {
		setlogCleanUp(ApplicationSettings?.LOG_CLEAN_UP_OBJECT_DATA);
		setdataCleanUp(ApplicationSettings?.DATA_CLEAN_UP_OBJECT_DATA);
		setgeneralSettings(ApplicationSettings?.GENERAL_SETTINGS_OBJECT_DATA);
	}, [
		ApplicationSettings?.DATA_CLEAN_UP_OBJECT_DATA,
		ApplicationSettings?.GENERAL_SETTINGS_OBJECT_DATA,
		ApplicationSettings?.LOG_CLEAN_UP_OBJECT_DATA,
	]);

	// useEffect(() => {
	// 	if (ApplicationSettings?.GENERAL_SETTINGS_OBJECT_DATA?.approve_zone?.settingsValue) {
	// 		fetch_zone_with_ID(ApplicationSettings?.GENERAL_SETTINGS_OBJECT_DATA?.approve_zone?.settingsValue).then((res) => {
	// 			setselectedZone(res?.response);
	// 		});
	// 	}
	// }, [ApplicationSettings?.GENERAL_SETTINGS_OBJECT_DATA?.approve_zone]);

	const UpdateLogsSettings = () => {
		setlogsLoader(true);
		let data = {
			...logCleanUp,
		};
		let SettingsArray = Object.values(data);

		let postData = {
			settings: SettingsArray,
		};
		const Success = (res: any) => {
			fetch_logclean_up_list(dispatch);
			setlogsLoader(false);

			SuccesToast(res?.response?.data);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setlogsLoader(false);
		};
		update_log_settings(postData, Success, Failed);
	};
	const UpdateGeneralSettings = () => {
		setgeneralLoader(true);
		let data: GeneralSettingObject = {
			...generalSettings,
		};

		// if (selectedZone[0]?.value) {
		// 	data['approve_zone'] = {
		// 		settingsID: 13,
		// 		settingsValue: `${selectedZone[0]?.value}`,
		// 	};
		// }

		let SettingsArray = Object.values(data);

		let postData = {
			settings: SettingsArray,
		};
		const Success = (res: any) => {
			fetch_general_settings_list(dispatch);
			setgeneralLoader(false);

			SuccesToast(res?.response?.data);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setgeneralLoader(false);
		};
		update_general_settings(postData, Success, Failed);
	};
	const UpdateDatsettings = () => {
		setdataLoader(true);
		let data = {
			...dataCleanUp,
		};
		let SettingsArray = Object.values(data);

		let postData = {
			settings: SettingsArray,
		};
		const Success = (res: any) => {
			fetch_dataclean_up_list(dispatch);
			setdataLoader(false);

			SuccesToast(res?.response?.data);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setdataLoader(false);
		};
		update_data_settings(postData, Success, Failed);
	};

	const ClearLogFx = () => {
		setclearLog(true);

		const Success = (res: any) => {
			setclearLog(false);

			SuccesToast(res?.response?.data);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setclearLog(false);
		};

		clear_log(Success, Failed);
	};

	const ArchiveFx = () => {
		setarchiveLoader(false);

		const Success = (res: any) => {
			setarchiveLoader(false);

			SuccesToast(res?.response?.data);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setarchiveLoader(false);
		};
		archive_data(Success, Failed);
	};

	return (
		<div className='main-body-content'>
			<div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main mb-0'>Log Cleanup Settings</h2>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='form-group inline-input'>
							<label>Clear Application Logs</label>
							<label className='switch'>
								<input
									type='checkbox'
									checked={logCleanUp?.clear_aplication_logs?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setlogCleanUp((prev) => ({
												...prev,
												clear_aplication_logs: {
													...prev?.clear_aplication_logs,
													'settingsValue': 'true',
												},
											}));
										} else {
											setlogCleanUp((prev) => ({
												...prev,
												clear_aplication_logs: {
													...prev?.clear_aplication_logs,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<span className='slider'></span>
								<span className='enable-text'>Enable</span>
							</label>
						</div>

						<div className='form-group inline-input'>
							<label>Clear Application Log Older Than</label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={logCleanUp?.clear_aplication_log_older_than?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setlogCleanUp((prev) => ({
											...prev,
											clear_aplication_log_older_than: {
												...prev?.clear_aplication_log_older_than,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Days</label>
							</div>
						</div>

						<div className='form-group inline-input'>
							<label>Clear Audit Logs</label>
							<label className='switch'>
								<input
									type='checkbox'
									checked={logCleanUp?.clear_audit_logs?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setlogCleanUp((prev) => ({
												...prev,
												clear_audit_logs: {
													...prev?.clear_audit_logs,
													'settingsValue': 'true',
												},
											}));
										} else {
											setlogCleanUp((prev) => ({
												...prev,
												clear_audit_logs: {
													...prev?.clear_audit_logs,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<span className='slider'></span>
								<span className='enable-text'>Enable</span>
							</label>
						</div>
						<div className='form-group inline-input'>
							<label>Clear Audit Log Older Than</label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={logCleanUp?.clear_audit_log_older_than?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setlogCleanUp((prev) => ({
											...prev,
											clear_audit_log_older_than: {
												...prev?.clear_audit_log_older_than,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-1'>
								<label>Days</label>
							</div>
							{/* <div className='checkbox col-5'>
								<input
									type='checkbox'
									name=''
									checked={logCleanUp?.delete_user_transction_log_once_your_is_inactive?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setlogCleanUp((prev) => ({
												...prev,
												delete_user_transction_log_once_your_is_inactive: {
													...prev?.delete_user_transction_log_once_your_is_inactive,
													'settingsValue': 'true',
												},
											}));
										} else {
											setlogCleanUp((prev) => ({
												...prev,
												delete_user_transction_log_once_your_is_inactive: {
													...prev?.delete_user_transction_log_once_your_is_inactive,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<label>
									<span>Delete user transaction log once user is inactive</span>
								</label>
							</div> */}
						</div>

						<hr />

						<div className='text-right'>
							<button className='btn btn-outline-blue' disabled={clearLog} onClick={ClearLogFx}>
								<ButtonLoader loading={clearLog} text={'Clear Log'} />
							</button>
							<button className='btn btn-secondary' onClick={UpdateLogsSettings} disabled={logsLoader}>
								<ButtonLoader loading={logsLoader} text={'Update'} />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main mb-0'>Data Cleanup Settings</h2>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='form-group inline-input'>
							<label>Transaction Archive</label>
							<label className='switch'>
								<input
									type='checkbox'
									checked={dataCleanUp?.transaction_archive?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setdataCleanUp((prev) => ({
												...prev,
												transaction_archive: {
													...prev?.transaction_archive,
													'settingsValue': 'true',
												},
											}));
										} else {
											setdataCleanUp((prev) => ({
												...prev,
												transaction_archive: {
													...prev?.transaction_archive,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<span className='slider'></span>
								<span className='enable-text'>Enable</span>
							</label>
						</div>

						<div className='form-group inline-input'>
							<label>Archive Transaction Older Than</label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={dataCleanUp?.archive_transaction_older_than?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setdataCleanUp((prev) => ({
											...prev,
											archive_transaction_older_than: {
												...prev?.archive_transaction_older_than,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Days</label>
							</div>
						</div>




		<div className='form-group inline-input'>
							<label>Clear Parking History </label>
							<label className='switch'>
								<input
									type='checkbox'
									checked={dataCleanUp?.parking_history_archive?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setdataCleanUp((prev) => ({
												...prev,
												parking_history_archive: {
													...prev?.parking_history_archive,
													'settingsValue': 'true',
												},
											}));
										} else {
											setdataCleanUp((prev) => ({
												...prev,
												parking_history_archive: {
													...prev?.parking_history_archive,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<span className='slider'></span>
								<span className='enable-text'>Enable</span>
							</label>
						</div>




						<div className='form-group inline-input'>
							<label> Clear Parking History  Older Than</label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={dataCleanUp?.archive_parking_history_older_than?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setdataCleanUp((prev) => ({
											...prev,
											archive_parking_history_older_than: {
												...prev?.archive_parking_history_older_than,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Days</label>
							</div>
						</div>

						<hr />

						<div className='text-right'>
							<button className='btn btn-outline-blue' disabled={archiveLoader} onClick={ArchiveFx}>
								<ButtonLoader loading={archiveLoader} text={'Archive'} />
							</button>
							<button className='btn btn-secondary' onClick={UpdateDatsettings}>
								<ButtonLoader loading={dataLoader} text={'Update'} />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className='white-card-2 data-clean mb-30'>
				<div className='heading'>
					<h2 className='heading-main mb-0'>General Settings</h2>
				</div>
				<div className='row'>
					<div className='col-12'>
						<div className='form-group inline-input'>
							<label>INX Base URL</label>
							<div className='col-4 input-half dflex'>
								<input
									type='text'
									defaultValue={'https://'}
									readOnly
									className='form-control grey-input pr-0'
									style={{ width: '70px' }}
								/>
								<input
									type='url'
									name=''
									className='form-control grey-input pl-0'
									value={generalSettings?.inx_base_url?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\//g, '');
										setgeneralSettings((prev) => ({
											...prev,
											inx_base_url: {
												...prev?.inx_base_url,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
						</div>
						<div className='form-group inline-input'>
							<label>Escalation email Id </label>
							<div className='col-4 input-half'>
								<input
									type='email'
									name=''
									className='form-control grey-input'
									value={generalSettings?.escalation_email_id?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value;
										setgeneralSettings((prev) => ({
											...prev,
											escalation_email_id: {
												...prev?.escalation_email_id,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							{/* <div className='col-3'>
							</div> */}
						</div>
						<div className='form-group inline-input'>
							<label>Automatic parking approval</label>
							<label className='switch'>
								<input
									type='checkbox'
									checked={generalSettings?.automatic_parking_approval?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setgeneralSettings((prev) => ({
												...prev,
												automatic_parking_approval: {
													...prev?.automatic_parking_approval,
													'settingsValue': 'true',
												},
											}));
										} else {
											setgeneralSettings((prev) => ({
												...prev,
												automatic_parking_approval: {
													...prev?.automatic_parking_approval,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<span className='slider'></span>
								<span className='enable-text'>Enable</span>
							</label>
						</div>
						{/* <div className='form-group inline-input'>
							<label>Approve Zone</label>
							<div className='col-2 input-half'>
				
								<TreeSelectCustom grey selectedZones={selectedZone} setselectedZones={setselectedZone} isMulti={false} />
							</div>
						</div> */}

						<div className='form-group inline-input'>
							<label>Approve After </label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={generalSettings?.approve_after?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setgeneralSettings((prev) => ({
											...prev,
											approve_after: {
												...prev?.approve_after,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Days</label>
							</div>
						</div>

						<div className='form-group inline-input'>
							<label>Send validity expire email before </label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={generalSettings?.send_validity_expiry_email_before?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setgeneralSettings((prev) => ({
											...prev,
											send_validity_expiry_email_before: {
												...prev?.send_validity_expiry_email_before,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Days</label>
							</div>
						</div>
						{/* <div className='form-group inline-input'>
							<label> Validity expire notification time  </label>
							<div className='col-2 input-half'>
						
								<TimePicker 
								className='form-control grey-input'
								format="HH:mm"
								value={moment(generalSettings?.validity_expiry_notification_time?.settingsValue, "HH:mm")}
								allowClear={false}
								onChange={(value) => {
									setgeneralSettings((prev:any) => ({
										...prev,
										validity_expiry_notification_time: {
											...prev?.validity_expiry_notification_time,
											'settingsValue': value?.format("HH:mm"),
										},
									}));
								}}
						
								 />
							</div>
							<div className='col-6'>
								<label>24Hr</label>
							</div>
						</div> */}

						<div className='form-group inline-input'>
							<label>No of years for permanent parking </label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={generalSettings?.number_of_years_for_permanent_parking?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setgeneralSettings((prev) => ({
											...prev,
											number_of_years_for_permanent_parking: {
												...prev?.number_of_years_for_permanent_parking,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Years</label>
							</div>
						</div>
						<div className='form-group inline-input'>
							<label>Accept parking upto </label>
							<div className='col-2 input-half'>
								<input
									type='text'
									name=''
									className='form-control grey-input'
									value={generalSettings?.future_parking_possible_limit?.settingsValue ?? ''}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setgeneralSettings((prev) => ({
											...prev,
											future_parking_possible_limit: {
												...prev?.future_parking_possible_limit,
												'settingsValue': value,
											},
										}));
									}}
								/>
							</div>
							<div className='col-6'>
								<label>Months</label>
							</div>
						</div>
						

						{/* <div className='form-group inline-input'>
							<label>Allow after exceeding limit</label>
							<label className='switch'>
								<input
									type='checkbox'
									checked={generalSettings?.allow_after_exceeding_limit?.settingsValue === 'true'}
									onChange={(e) => {
										if (e.target.checked) {
											setgeneralSettings((prev) => ({
												...prev,
												allow_after_exceeding_limit: {
													...prev?.allow_after_exceeding_limit,
													'settingsValue': 'true',
												},
											}));
										} else {
											setgeneralSettings((prev) => ({
												...prev,
												allow_after_exceeding_limit: {
													...prev?.allow_after_exceeding_limit,
													'settingsValue': 'false',
												},
											}));
										}
									}}
								/>
								<span className='slider'></span>
								<span className='enable-text'>Enable</span>
							</label>
						</div> */}

						<hr />

						<div className='text-right'>
							<button className='btn btn-secondary' onClick={UpdateGeneralSettings}>
								<ButtonLoader loading={generalLoader} text={'Update'} />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApplicationSettings;
