import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '../../../config/store';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

type VisitObject = {
    siteID : any,	
    fromDate : string,	
    toDate : string,		
    fromTime : string,		
    toTime : string,		
    zoneIDs : string,			
    bayTypeIDs : string,		
    visitDurationFrom : number,		
    visitDurationTo : number,		
    plateTextSearch : string,
	percentage : number
}

const initialState = {
	REPORTS_GRAPH_ONE_DATA  :   [],
    REPORTS_GRAPH_ONE_LOAD  :   true,
    REPORTS_GRAPH_TWO_DATA  :   [],
    REPORTS_GRAPH_TWO_LOAD  :   true,
    REPORTS_GRAPH_THREE_DATA    :   [],
    REPORTS_GRAPH_THREE_LOAD    :   true,
    REPORTS_GRAPH_FOUR_DATA     :   [],
    REPORTS_GRAPH_FOUR_LOAD     :   true,
    
	REPORTS_GRAPH_ONE_CSV_COLS  	:   {},
    REPORTS_GRAPH_TWO_CSV_COLS  	:   {},
    REPORTS_GRAPH_THREE_CSV_COLS    :   {},
    REPORTS_GRAPH_FOUR_CSV_COLS     :   {},
    
	REPORTS_SITE            :   0,
    REPORTS_FLOOR           :   0,
    REPORTS_DATE            :   [],
    REPORTS_TIME            :   [],
	REPORTS_FILTER_MEASURE	:	'avg',
	REPORTS_FILTER_CHART	:	'bar',
	REPORTS_FILTER_UNRECVEH	:	0,

    USER_REPORTS_LIST_DATA: [],
	USER_REPORTS_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	USER_REPORTS_LOADER: true,
	USER_REPORTS_FILTER:  { query: "" } as FilterObject,
	USER_REPORTS_ERROR: '',
	USER_REPORT_DATE: [],

    VISIT_REPORTS_LIST_DATA: [],
	VISIT_REPORTS_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	VISIT_REPORTS_LOADER: true,
	VISIT_REPORTS_FILTER:  {
		siteID : null,	
		fromDate : "",	
		toDate : "",		
		fromTime : "",		
		toTime : "",		
		zoneIDs :"" ,			
		bayTypeIDs : "",		
		visitDurationFrom :0,		
		visitDurationTo : 0,		
		plateTextSearch : "",
		percentage : 0
	} as VisitObject,
	VISIT_REPORTS_ERROR: '',

};

export const REPORTSLICE = createSlice({
	name: 'REPORTSLICE',
	initialState: initialState,
	reducers: {
        UPDATE_REPORTS_LOADER: (state) => {
            state.REPORTS_GRAPH_ONE_LOAD = true;
			state.REPORTS_GRAPH_TWO_LOAD = true;
			state.REPORTS_GRAPH_THREE_LOAD = true;
			state.REPORTS_GRAPH_FOUR_LOAD = true;

			state.REPORTS_GRAPH_ONE_DATA = [];
			state.REPORTS_GRAPH_TWO_DATA = [];
			state.REPORTS_GRAPH_THREE_DATA = [];
			state.REPORTS_GRAPH_FOUR_DATA = [];
        }, 
        UPDATE_REPORTS_FILTER: (state, action) => {
            state.REPORTS_DATE = action.payload?.dates; 
            state.REPORTS_TIME = action.payload?.times; 
            state.REPORTS_FLOOR = action.payload?.floor; 
            state.REPORTS_SITE = action.payload?.site;
			state.REPORTS_FILTER_UNRECVEH = action.payload?.unrecveh
        },
		UPDATE_REPORTS_GRAPH_ONE: (state, action) => {
            state.REPORTS_GRAPH_ONE_DATA = action.payload?.data;
			state.REPORTS_GRAPH_ONE_CSV_COLS = action.payload?.csv_cols;
            state.REPORTS_GRAPH_ONE_LOAD = false;
			state.REPORTS_FILTER_MEASURE = action.payload?.measure;
			state.REPORTS_FILTER_CHART   = action.payload?.chartType;
			state.REPORTS_FILTER_UNRECVEH = action.payload?.unrecveh
        }, 
		UPDATE_REPORTS_GRAPH_TWO: (state, action) => {
            state.REPORTS_GRAPH_TWO_DATA = action.payload?.data;
			state.REPORTS_GRAPH_TWO_CSV_COLS = action.payload?.csv_cols;
            state.REPORTS_GRAPH_TWO_LOAD = false;
			state.REPORTS_FILTER_MEASURE = action.payload?.measure;
			state.REPORTS_FILTER_CHART   = action.payload?.chartType;
			state.REPORTS_FILTER_UNRECVEH = action.payload?.unrecveh
        }, 
		UPDATE_REPORTS_GRAPH_THREE: (state, action) => {
            state.REPORTS_GRAPH_THREE_DATA = action.payload?.data;
			state.REPORTS_GRAPH_THREE_CSV_COLS = action.payload?.csv_cols;
            state.REPORTS_GRAPH_THREE_LOAD = false;
			state.REPORTS_FILTER_MEASURE = action.payload?.measure;
			state.REPORTS_FILTER_CHART   = action.payload?.chartType;
			state.REPORTS_FILTER_UNRECVEH = action.payload?.unrecveh
        }, 
		UPDATE_REPORTS_GRAPH_FOUR: (state, action) => {
            state.REPORTS_GRAPH_FOUR_DATA = action.payload?.data;
			state.REPORTS_GRAPH_FOUR_CSV_COLS = action.payload?.csv_cols;
            state.REPORTS_GRAPH_FOUR_LOAD = false;
			state.REPORTS_FILTER_MEASURE = action.payload?.measure;
			state.REPORTS_FILTER_CHART   = action.payload?.chartType;
			state.REPORTS_FILTER_UNRECVEH = action.payload?.unrecveh
        },
        UPDATE_REPORTS_DATE_TIME_SITE_FLOOOR: (state, action) => {
            state.REPORTS_DATE = action.payload?.date;
            state.REPORTS_TIME = action.payload?.time;
            state.REPORTS_SITE = action.payload?.site;
            state.REPORTS_FLOOR = action.payload?.floor;
        },
		UPDATE_REPORTS_SITE_FLOOR: (state, action) => {
            state.REPORTS_SITE = action.payload?.site;
            state.REPORTS_FLOOR = action.payload?.floor;
        },

        UPDATE_USER_REPORT_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.USER_REPORTS_LIST_DATA = Object.assign([], data);
			state.USER_REPORTS_PAGINATION = pagination;
			state.USER_REPORTS_FILTER = filters;
			state.USER_REPORTS_LOADER = false;
			state.USER_REPORTS_ERROR = '';
		},
		UPDATE_USER_REPORT_LOADER: (state, action) => {
			console.log("i get data")
			const { loader } = action.payload;
			state.USER_REPORTS_LOADER = loader;
		},

		UPDATE_USER_REPORT_ERROR: (state, action) => {
			const { error } = action.payload;
			state.USER_REPORTS_ERROR = error;
			state.USER_REPORTS_LOADER = false;
		},

        UPDATE_VISIT_REPORT_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.VISIT_REPORTS_LIST_DATA = Object.assign([], data);
			state.VISIT_REPORTS_PAGINATION = pagination;
			state.VISIT_REPORTS_FILTER = {...filters};
			state.VISIT_REPORTS_LOADER = false;
			state.VISIT_REPORTS_ERROR = '';
		},
		UPDATE_VISIT_REPORT_LOADER: (state, action) => {
			console.log("i get data")
			const { loader } = action.payload;
			state.VISIT_REPORTS_LOADER = loader;
		},

		UPDATE_VISIT_REPORT_ERROR: (state, action) => {
			const { error } = action.payload;
			state.VISIT_REPORTS_ERROR = error;
			state.VISIT_REPORTS_LOADER = false;
		},

		UPDATE_USER_REPORT_DATE_UPDATE: (state, action) =>{
			const { dates } = action.payload;
			state.USER_REPORT_DATE = dates
		},

		UPDATE_USER_REPORT_FILTER_UPDATE: (state) => {
			state.USER_REPORTS_FILTER = initialState?.USER_REPORTS_FILTER;
			state.USER_REPORTS_PAGINATION = initialState?.USER_REPORTS_PAGINATION;

		},

		UPDATE_PAGENO_ONTABCHANGE: (state) => {
			state.VISIT_REPORTS_PAGINATION = initialState?.VISIT_REPORTS_PAGINATION;
			state.USER_REPORTS_PAGINATION = initialState?.USER_REPORTS_PAGINATION;
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
    UPDATE_REPORTS_GRAPH_ONE, UPDATE_REPORTS_GRAPH_TWO, UPDATE_REPORTS_GRAPH_THREE, UPDATE_REPORTS_GRAPH_FOUR, UPDATE_REPORTS_LOADER, 
    UPDATE_REPORTS_DATE_TIME_SITE_FLOOOR, UPDATE_REPORTS_FILTER, UPDATE_USER_REPORT_DATA, UPDATE_USER_REPORT_LOADER, UPDATE_USER_REPORT_ERROR,
    UPDATE_VISIT_REPORT_DATA, UPDATE_VISIT_REPORT_ERROR, UPDATE_VISIT_REPORT_LOADER, RESET_STATE, UPDATE_REPORTS_SITE_FLOOR, UPDATE_USER_REPORT_DATE_UPDATE,
	UPDATE_USER_REPORT_FILTER_UPDATE, UPDATE_PAGENO_ONTABCHANGE
  } =
	REPORTSLICE.actions;
export const REPORTSData = (state: RootState) => state;
export default REPORTSLICE.reducer;
