import React, { useEffect } from 'react'
import headerImage from '../../assets/images/common/adnoc-logo.jpg'
import { useReactToPrint } from "react-to-print";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { GraphRoutes } from './graphRoute';
import { useAppSelector } from '../../config/hooks';
import html2PDF from 'jspdf-html2canvas';

const ImmediateXPorter = (props) => {

    const urlParams = useLocation()
    const REPORTSDATA = useAppSelector((state) => state?.exportReportData)
    console.log('urlParams',urlParams)
    console.log('XREPORTSDATA',REPORTSDATA)
    
    const componentRef = React.useRef(null);
    const graphComponentRef = React.useRef(null);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);


    const handlePrint = useReactToPrint({
        content         : reactToPrintContent,
        documentTitle   : `${REPORTSDATA?.EXPORT_GROUP_OBJ?.title}_${moment().format('YYYY-MMM-DD hh:mm a')}`,
        print           : async (printIframe) => {
            const document = printIframe?.contentDocument;
            if (document) {
              const html = document.getElementById("element-to-download-as-pdf");
              console.log(html);

              await html2PDF(html, {
                jsPDF: {
                  format: 'a4',
                },
                imageType: 'image/jpeg',
                output: `./pdf/${REPORTSDATA?.EXPORT_GROUP_OBJ?.title}_${moment().format('YYYYMMMDDHH:mm:ss')}.pdf`
              })

              
            }
          },
    })

    useEffect(() => {
        console.log('CALLED HANDLE PRINT,out useffect',componentRef,'\nGRAPHCOMP',graphComponentRef)
      if(componentRef && componentRef?.current && graphComponentRef && graphComponentRef?.current){
        console.log('CALLED HANDLE PRINT',componentRef)
        setTimeout(() => {
            console.log('CALLED HANDLE PRINT,settimeout useffect',componentRef,'\nGRAPHCOMP',graphComponentRef)
            handlePrint()
        }, 5000);
      }
    }, [componentRef, graphComponentRef])
    
    const PrintablePage = () =>{
        const { Comp } = GraphRoutes[REPORTSDATA?.EXPORT_GROUP_OBJ?.graphNo]
        console.log('CHARTCOMP',Comp)
        return(
        <div ref={componentRef} className='printablePageLayout' id="element-to-download-as-pdf"
            style={{ paddingTop : `2cm`, paddingBottom : `2cm`, 
            paddingLeft : `2cm`, paddingRight : `2cm` }}>
            <div className='printablePageInfoSection'>
                <div className='printablePageHeader'>
                    <div className='printablePageHeaderImage'>
                        <img src={headerImage} alt="headerImage" style={{ height:'100%' }} />
                    </div>
                    <div className='col-12 printablePageHeaderTitle' style={{ textAlign:'center' }}>Secured Car Parking Management System</div>
                </div>
                <div className={`printablePageSubtitle`}>
                    {
                        REPORTSDATA?.EXPORT_GROUP_OBJ?.titleArr && REPORTSDATA?.EXPORT_GROUP_OBJ?.titleArr?.length>0 ?
                            REPORTSDATA?.EXPORT_GROUP_OBJ?.titleArr?.map((el)=>{
                                return(
                                    <p>{el}</p>
                                )
                            })
                        :
                        <p>Report : <span>{REPORTSDATA?.EXPORT_GROUP_OBJ?.title}</span></p>
                    }
                </div>
            </div>
            <div className='printablePageGraphSection'>
                    <Comp
                        props={{
                            loader  :   REPORTSDATA?.EXPORT_REPORTS_GRAPH_LOAD, 
                            data    :   REPORTSDATA?.EXPORT_REPORTS_GRAPH, 
                            title   :   REPORTSDATA?.EXPORT_GROUP_OBJ?.title
                        }}
                        ref={graphComponentRef}
                    />            
            </div>
            
        </div>
        )
    }

  return (
    
        <div className='modal-body-content' style={{ maxHeight:'100vh !important', backgroundColor:'white !important' }}>
            {
                REPORTSDATA?.EXPORT_REPORTS_GRAPH &&
                REPORTSDATA?.EXPORT_GROUP_OBJ &&
                        GraphRoutes[REPORTSDATA?.EXPORT_GROUP_OBJ?.graphNo]?.Comp ?
                    <PrintablePage/>
                :
                    <p>No data</p>
            }
            
        </div>
  )
}

export default ImmediateXPorter