import React from 'react';

interface iprops {
	id: number;
	component: any;
	name: string;
	NoLayout?: boolean;
}

const Widget = ({ id, component: Item, name, NoLayout }: iprops) => {
	return (
        <>
                <Item key={name} name={name}  id={id}/> 
        </>

	);
};

export default Widget;
