import React, {useState} from 'react'
import VisitReport from './visitsReports'
import PlateFreq from './PlateFreq'
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks'

import { export_visitReport } from '../../../store/action'
import { CircularProgress } from '@mui/material'
import { UPDATE_PAGENO_ONTABCHANGE } from '../../../store/reportSlice'
import { Tooltip } from 'antd'


type Props = {
   tabVal:any;
   setTabVal:any
}

const ReportTable = (props: Props) => {
    const [openExport, setOpenExport] = useState(false)
    const [printLoading, setPrintLoading] = useState(false)

    const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);
    const GENERAL = useAppSelector((state)=>state?.general)
    const dispatch = useAppDispatch();

    const updateLoaderFn = () =>{
		setPrintLoading(false)
	}
    const exportPDF = () =>{
      setPrintLoading(true)
      let postData = {
         "filters":{ ...VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER},
         "pagination": {
             "pageNo": 1,
             "limit": VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.totalCount
         }
     }
     let urlText = ''
     if(props?.tabVal){
      urlText = 'visit-report/export'
     }
     else{
      urlText = 'plate-frequency/export'
     }
     export_visitReport(urlText,props?.tabVal?'Visit_Report' : 'Plate_Frequency','pdf',postData,updateLoaderFn)
    }
    const exportExcel = () =>{
      setPrintLoading(true)
      let postData = {
         "filters":{ ...VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER},
         "pagination": {
             "pageNo": 1,
             "limit": VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.totalCount
         }
     }
     
     let urlText = ''
     if(props?.tabVal){
      urlText = 'visit-report/export-excel'
     }
     else{
      urlText = 'plate-frequency/export-excel'
     }
     export_visitReport(urlText,props?.tabVal?'Visit_Report' : 'Plate_Frequency','xlsx',postData,updateLoaderFn)
    }

  return (
    <div className="white-card">
    <div className="custom-tabing-menu row justify-space-between">
       <ul style={{ width:'50%' }}>
          <li>
             <a className={ props?.tabVal ? "active" : ''}  style={{ borderTopLeftRadius:'10px' }} onClick={()=>{ props?.setTabVal(1); dispatch(UPDATE_PAGENO_ONTABCHANGE()); }} >Visits</a>
          </li>
          <li>
             <a className={ !props?.tabVal ? "active" : ''} onClick={()=>{ props?.setTabVal(0); dispatch(UPDATE_PAGENO_ONTABCHANGE()); }}>Plate Frequency</a>
          </li>
       </ul>
       <div className='dflex align-items-center justify-content-center' style={{ marginRight:'1%'}}>
       <Tooltip title={VISITS_REPORTS_DATA?.VISIT_REPORTS_LIST_DATA&&VISITS_REPORTS_DATA?.VISIT_REPORTS_LIST_DATA?.length>0 
         && VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromDate && VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toDate 
         ? '' :
            'Please select and apply a proper date range to Export report'} >
               <div  className={`btn btn-sqaure btn-secondary btn-secondary-hover openMiniModalLine 
       ${VISITS_REPORTS_DATA?.VISIT_REPORTS_LIST_DATA &&
         VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.fromDate && VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER?.toDate && 
        VISITS_REPORTS_DATA?.VISIT_REPORTS_LIST_DATA?.length>0 ? '' : 'disableAndOpacity2'}`} onClick={(e) =>{ setOpenExport(!openExport); e.stopPropagation(); e.preventDefault(); }}>
         {
            printLoading ?
            <CircularProgress color='inherit' size={20}/>
            :  
            <>
               <i className='fa-solid fa-download'></i>
               {	openExport ?
				         <div className='miniModalOpenBox'>
                        <div className='miniBoxModalContent'>
                           <div onClick={exportPDF}>
                              <button>
                                 <p>Export PDF</p>
                              </button>
                           </div>
                           <div onClick={exportExcel}>
                              <button>
                                 <p>Export CSV</p>
                              </button>
                           </div>
                        </div>
                     </div>
					   :	
                  null
			      }
            </>
         }         
       </div>
            </Tooltip>
       
       </div>
      
    </div>
    <div className="tabing-row active" id="visits">
       <div className="custom-tabing-body">
         {
            props?.tabVal ?
               <VisitReport/>
            :
               <PlateFreq/>
         }
       </div>
    </div>
 </div>
  )
}

export default ReportTable