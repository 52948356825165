import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import moment from 'moment';

type FilterObject = {
	query: string;
	orderBy? : string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	REALTIME_LIST_DATA: [],
	REALTIME_LOADER   : true,
	REALTIME_FILTER   : { query: '' } as FilterObject,
	REALTIME_ERROR    : '',


	TRANSACTION_LIST_DATA : [],
	TRANSACTION_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	TRANSACTION_LOADER    : true,
	TRANSACTION_FILTER    : { query: ` transactiondatetime >= '${moment().subtract(7,'days').format('YYYY-MM-DD')} 09:00:00' AND transactiondatetime <= '${moment().format('YYYY-MM-DD')} 19:00:00'` } as FilterObject,
	TRANSACTION_ERROR     : '',


	HISTORY_LIST_DATA     : [],
	HISTORY_PAGINATION    : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	HISTORY_LOADER        : true,
	HISTORY_FILTER        : { query: ` (CAST (ParkingDate AS date) >= '${moment().subtract(7,'days').format('YYYY-MM-DD')}' AND CAST (ParkingDate AS date) <= '${moment().format('YYYY-MM-DD')}') ` } as FilterObject,
	HISTORY_ERROR         : '',

	UNRECOGNIZED_LIST_DATA     : [],
	UNRECOGNIZED_PAGINATION    : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	UNRECOGNIZED_LOADER        : true,
	UNRECOGNIZED_FILTER        : { query: ` (CAST (ParkingDate AS date) >= '${moment().subtract(7,'days').format('YYYY-MM-DD')}' AND CAST (ParkingDate AS date) <= '${moment().format('YYYY-MM-DD')}') ` } as FilterObject,
	UNRECOGNIZED_ERROR         : '',

	AVAILABILITY_CHECK_LIST_DATA			: {} as any,
	AVAILABILITY_CHECK_LOADER        : true,
	AVAILABILITY_CHECK_FILTER        : {} as any,
	AVAILABILITY_CHECK_ERROR         : '',


	SITE_DATA              : [],
	FLOOR_DATA             : [],


	SELECTED_BAY : {} as any


};

export const REALTIMESLICE = createSlice({
	name: 'REALTIME',
	initialState: initialState,
	reducers: {
		UPDATE_REALTIME_DATA: (state, action) => {
			const { data, filters } = action.payload;
			state.REALTIME_LIST_DATA = data;
			state.REALTIME_FILTER = filters;
			state.REALTIME_LOADER = false;
			state.REALTIME_ERROR = '';
		},
		UPDATE_REALTIME_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.REALTIME_LOADER = loader;
			state.REALTIME_LIST_DATA = [];

		},

		UPDATE_REALTIME_ERROR: (state, action) => {
			const { error } = action.payload;
			state.REALTIME_ERROR = error;
			state.REALTIME_LOADER = false;
		},		

		UPDATE_SELECTED_BAY: (state, action) => {
			const { data } = action.payload;
			state.SELECTED_BAY = data;
		},

		UPDATE_HISTORY_DATA: (state, action) => {
			const { data, filters  ,pagination} = action.payload;
			state.HISTORY_LIST_DATA = data;
			state.HISTORY_PAGINATION = pagination;
			state.HISTORY_FILTER = filters;
			state.HISTORY_LOADER = false;
			state.HISTORY_ERROR = '';
		},
		UPDATE_HISTORY_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.HISTORY_LOADER = loader;
			state.HISTORY_LIST_DATA = [];

		},

		UPDATE_HISTORY_ERROR: (state, action) => {
			const { error } = action.payload;
			state.HISTORY_ERROR = error;
			state.HISTORY_LOADER = false;
		},

		UPDATE_UNRECOGNIZED_DATA: (state, action) => {
			const { data, filters  ,pagination} = action.payload;
			state.UNRECOGNIZED_LIST_DATA = data;
			state.UNRECOGNIZED_PAGINATION = pagination;
			state.UNRECOGNIZED_FILTER = filters;
			state.UNRECOGNIZED_LOADER = false;
			state.UNRECOGNIZED_ERROR = '';
		},
		UPDATE_UNRECOGNIZED_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.UNRECOGNIZED_LOADER = loader;
			state.UNRECOGNIZED_LIST_DATA = [];

		},

		UPDATE_UNRECOGNIZED_ERROR: (state, action) => {
			const { error } = action.payload;
			state.UNRECOGNIZED_ERROR = error;
			state.UNRECOGNIZED_LOADER = false;
		},

		UPDATE_TRANSACTION_DATA: (state, action) => {
			const { data, filters ,pagination} = action.payload;
			state.TRANSACTION_LIST_DATA = data;
			state.TRANSACTION_FILTER = filters;
			state.TRANSACTION_PAGINATION = pagination;
			state.TRANSACTION_LOADER = false;
			state.TRANSACTION_ERROR = '';
		},
		UPDATE_TRANSACTION_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.TRANSACTION_LOADER = loader;
			state.TRANSACTION_LIST_DATA = [];

		},

		UPDATE_TRANSACTION_ERROR: (state, action) => {
			const { error } = action.payload;
			state.TRANSACTION_ERROR = error;
			state.TRANSACTION_LOADER = false;
		},

		UPDATE_PARKING_AVAILABILITY_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.AVAILABILITY_CHECK_LOADER = loader;
			state.AVAILABILITY_CHECK_LIST_DATA = {};

		},

		UPDATE_PARKING_AVAILABILITY_DATA: (state, action) => {
			const { data, filters } = action.payload;
			state.AVAILABILITY_CHECK_LIST_DATA = data;
			state.AVAILABILITY_CHECK_FILTER = filters;
			state.AVAILABILITY_CHECK_LOADER = false;
			state.AVAILABILITY_CHECK_ERROR = '';
		},

		UPDATE_PARKING_AVAILABILITY_ERROR: (state, action) => {
			const { error } = action.payload;
			state.AVAILABILITY_CHECK_ERROR = error;
			state.AVAILABILITY_CHECK_LOADER = false;
		},

		RESET_STATE: () => {
			return {...initialState}
		}


	},
});

export const {
	UPDATE_REALTIME_DATA,
	UPDATE_REALTIME_LOADER,
	UPDATE_REALTIME_ERROR,

	UPDATE_SELECTED_BAY,

	UPDATE_HISTORY_DATA,
	UPDATE_HISTORY_LOADER,
	UPDATE_HISTORY_ERROR,

	UPDATE_UNRECOGNIZED_DATA,
	UPDATE_UNRECOGNIZED_LOADER,
	UPDATE_UNRECOGNIZED_ERROR,

	UPDATE_TRANSACTION_DATA,
	UPDATE_TRANSACTION_LOADER,
	UPDATE_TRANSACTION_ERROR,

	UPDATE_PARKING_AVAILABILITY_DATA,
	UPDATE_PARKING_AVAILABILITY_LOADER,
	UPDATE_PARKING_AVAILABILITY_ERROR, RESET_STATE


} = REALTIMESLICE.actions;
export const REALTIMEData = (state: RootState) => state;
export default REALTIMESLICE.reducer;
