import React, { useState } from 'react'
import FilterBar from './components/FilterBar'
import Reports from './components/reportDisplayNew'

type Props = {}

const DwellTime = (props:Props) => {
  const [tabVal, setTabVal] = useState(0)
  const [measure, setMeasure] = useState('avg')
	const [chartType, setChartType] = useState('')
  const [isUnRecVeh, setIsUnRecVeh] = useState('0')
  const [filename, setFilename] = useState('')
    const [fileNameSub, setFilenameSub] = useState('')
    const [fileDateStr, setFileDateStr] = useState('')
    const [fileTimeStr, setFileTimeStr] = useState('')
    const [fileSectionStr, setFileSectionStr] = useState('')

  return (
    <div className="main-body-content license">
        <FilterBar tabVal={tabVal} setTabVal={setTabVal} isUnRecVeh={isUnRecVeh} setIsUnRecVeh={setIsUnRecVeh}
          measure={measure} setMeasure={setMeasure} chartType={chartType} setChartType={setChartType}
            setFilename={setFilename} setFilenameSub={setFilenameSub} 
            setFileDateStr={setFileDateStr} setFileTimeStr={setFileTimeStr} 
            setFileSectionStr={setFileSectionStr}
            />
          <Reports 
          filename={filename} setFilename={setFilename} fileNameSub={fileNameSub} setFilenameSub={setFilenameSub} 
          fileDateStr={fileDateStr} setFileDateStr={setFileDateStr} fileTimeStr={fileTimeStr} setFileTimeStr={setFileTimeStr} 
          fileSectionStr={fileSectionStr} setFileSectionStr={setFileSectionStr} tabVal={tabVal} setTabVal={setTabVal} isUnRecVeh={isUnRecVeh} setIsUnRecVeh={setIsUnRecVeh}
        measure={measure} setMeasure={setMeasure} chartType={chartType} setChartType={setChartType}/>
    </div>
  )
}

export default DwellTime
