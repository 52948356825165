/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteConfirm from '../../../../../common/components/DeleteConfirm';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { delete_blacklisted, fetch_blacklisted_list } from '../../../store/action';
import CustomConfirm from '../../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../../common/components/ButtonLoader';

type Props = {};

const ListTable = (props: Props) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoader, setDeleteLoader] = useState(false)
	const cancelDelete = () => setDeleteModal(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const fetchIdRef = useRef(0);
	const [ID, setID] = useState();
	const BLACKLISTED_DATA = useAppSelector((state) => state?.violations);

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'bVehicelID', orderByKey: 'bVehicelID' },
			{ Header: 'Added On', accessor: 'blackListedAddedOn', DateFormat: true, FormatType: 'DD-MMM-YYYY', orderByKey: 'blackListedAddedOn' },
			{ Header: 'Plate Number', accessor: 'plateNo', orderByKey: 'plateNo' },
			{ Header: 'Place of Reg', accessor: 'placeOf_Registration', orderByKey: 'placeOf_Registration' },
			{ Header: 'Vehicle Category', accessor: 'vehicle_Category', orderByKey: 'vehicle_Category' },
			{ Header: 'Added By', accessor: 'addedBy' },
			{ Header: 'Remarks', accessor: 'descriptions', autoCapitalization : false },
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<PermissionWrapper subModuleID={subModuleList['Blacklisted Vehicles']} type='isDelete'>
									<a
										className='btn btn-sqaure btn-red modal-button'
										onClick={() => handleDeletModalOpen(row?.values?.bVehicelID)}>
										<i className='fa-solid fa-trash'></i>
									</a>
								</PermissionWrapper>
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string };
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_blacklisted_list(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	const handleDeletModalOpen = (id: any) => {
		setID(id);
		setDeleteModal(true);
	};
	const handleDelete = (id: any) => {
		setDeleteLoader(true)
		const sucess = (res: any) => {
			console.log(res);
			setDeleteLoader(false)
			SuccesToast(res?.response?.data);
			cancelDelete();

			fetch_blacklisted_list(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 15,
				},
			});
		};
		const failed = (err: any) => {
			setDeleteLoader(false)
			FailedToast(err?.response)		;
			cancelDelete();
		};

		delete_blacklisted(id, sucess, failed);
	};

	return (
		<>
			<CommonTable
				columns={columns}
				data={BLACKLISTED_DATA?.BLACKLISTED_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={BLACKLISTED_DATA?.BLACKLISTED_PAGINATION?.totalCount ?? 0}
				loading={BLACKLISTED_DATA?.BLACKLISTED_LOADER}
				page_no={BLACKLISTED_DATA?.BLACKLISTED_PAGINATION?.pageNo ?? 1}
				limit={BLACKLISTED_DATA?.BLACKLISTED_PAGINATION?.limit ?? 1}
				filters={BLACKLISTED_DATA?.BLACKLISTED_FILTER}
			/>
			
			<CustomConfirm
			open={deleteModal}
			handleClose={cancelDelete}
			title='Are you sure?'
			description='Do you want to delete blacklisted vehicle?'>

			<button
			disabled={deleteLoader}
				type='submit'
				className='btn btn-secondary'
				onClick={(e) => {
					e.preventDefault();
					if (ID) handleDelete(ID);
				}}>
			<ButtonLoader loading={deleteLoader} text="Confirm" />
				
			</button>

			</CustomConfirm>
		</>
	);
};

export default ListTable;
