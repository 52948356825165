import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { FieldErrorsImpl } from 'react-hook-form';

type Props = {
	errors: FieldErrorsImpl<{
		[x: string]: any;
	}>;
	name: string;
	title: string;
	customMessage?: string;
};

const ErrorComponent = (props: Props) => {
	return (
		<>
			<ErrorMessage
				errors={props?.errors}
				name={props?.name}
				render={({ message }) => (
					<p className='hookform-error-message'>{props?.customMessage ?? message?.replace(props?.name, props?.title)}</p>
				)}
			/>
		</>
	);
};

export default ErrorComponent;
