import Request from '../../config/api';
import {
	BAYTYPE_DDL_URL,
	BAY_DDL_URL,
	DEFINITION_URL,
	DEPARTMENT_DDL_URL,
	DESIGNATION_DDL_URL,
	FLOORLEVEL_DDL_URL,
	GROUP_COMPANY_DDL,
	INVALID_REASONS_DDL_URL,
	NOTIFICATION_MARK_ALLREAD,
	NOTIFICATION_WEB,
	NOTIFICATION_WEB_ACTION,
	NOTIFICATION_WEB_SINGLE_DELETE,
	PARKING_POLICY_DDL_URL,
	PLACE_OF_REG_LIST,
	PRIORITY_LEVEL_DDL_URL,
	REJECTION_REASON_DDL_URL,
	SITE_DDL_URL,
	VEHICLEPLATE_DDL_URL,
	VIOLATIONTYPE_DDL_URL,
	ZONE_ASSIGNED_DDL_URL,
	ZONE_DDL_URL,
} from '../../config/endpoint';
import {
	UPDATE_ASSINGED_ZONE_DDL,
	UPDATE_BAYTYPE_DDL,
	UPDATE_BAY_DDL,
	UPDATE_DEPARTMENT_DDL,
	UPDATE_DESIGNATION_DDL,
	UPDATE_FLOORLEVEL_DDL,
	UPDATE_GROUPCOMPANY_DDL,
	UPDATE_INVALIDREASON_DDL,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_ERROR,
	UPDATE_NOTIFICATIONS_LOADER,
	UPDATE_PLACEOFREG_DDL,
	UPDATE_POLICYNAME_DDL,
	UPDATE_PRIORITY_LVL_DDL,
	UPDATE_REJECTIONREASON_DDL,
	UPDATE_REQUEST_TYPE_DDL,
	UPDATE_SITE_DDL,
	UPDATE_USERTYPE_DDL,
	UPDATE_VEHICLEPLATE_DDL,
	UPDATE_VIOLATIONTYPE_DDL,
	UPDATE_ZONE_DDL,
} from './generalSlice';

export const fetch_designationDDL = (dispatch: any, signal?: AbortSignal, search="") => {
	let api_url = DESIGNATION_DDL_URL;
	let postData = {
		"filters": {
			"query": search,
			"OrderBy" : ""
		},
		"pagination": {
			"pageNo": 1,
			"limit": 100
	   
		}
	}

	Request('post', api_url, postData, signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_DESIGNATION_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};
export const fetch_groupCompanyDDL = (dispatch: any, signal?: AbortSignal, search = "") => {
	let api_url = GROUP_COMPANY_DDL;

	let postData = {
		"filters": {
			"query": search,
			"OrderBy" : ""
		},
		"pagination": {
			"pageNo": 1,
			"limit": 100
	   
		}
	}

	Request('post', api_url, postData, signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			let data = res?.response ?? []
			let formattedData = data.map((item:any)=>({label : item?.companyName, value : item?.groupCompanyID})) ?? []
			dispatch(
				UPDATE_GROUPCOMPANY_DDL({
					data:formattedData?? [],
				})
			);
		}
	});
};

export const fetch_placeOfRegList = (dispatch: any, signal: AbortSignal, search?: string) => {
	let api_url = PLACE_OF_REG_LIST;
	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			let data = res?.response?.data ?? []
			let formattedData = data.map((item:any)=>({label : item?.askGsReference, value : item?.askGsReference})) ?? []
			dispatch(
				UPDATE_PLACEOFREG_DDL({
					data:formattedData?? [],
				})
			);
		}
	});
};


export const fetch_departmentDDL = (dispatch: any, signal?: AbortSignal, search = "" ) => {
	let api_url = DEPARTMENT_DDL_URL;

	let postData = {
		"filters": {
			"query": search,
			"OrderBy" : ""
		},
		"pagination": {
			"pageNo": 1,
			"limit": 100
	   
		}
	}

	Request('post', api_url, postData, signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_DEPARTMENT_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_priorityLevelDDL = (dispatch: any, signal: AbortSignal, search?: string) => {
	let api_url = PRIORITY_LEVEL_DDL_URL;
	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_PRIORITY_LVL_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_siteDDL = (dispatch: any, signal: AbortSignal, search?: string) => {
	let api_url = SITE_DDL_URL;
	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_SITE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_zoneDDL = (dispatch: any, postData?: object) => {
	let api_url = ZONE_DDL_URL;
	let data = typeof postData === 'undefined' ? { 'query': '' } : postData;

	Request('post', api_url, data, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_ZONE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_floorLevelDDL = (dispatch: any, postData?: object) => {
	let api_url = FLOORLEVEL_DDL_URL;
	let data = typeof postData === 'undefined' ? { 'query': '' } : postData;

	Request('post', api_url, data, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_FLOORLEVEL_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_zoneAssigned = (dispatch: any, postData?: object) => {
	let api_url = ZONE_ASSIGNED_DDL_URL;
	let data = typeof postData === 'undefined' ? { 'query': '' } : postData;

	Request('post', api_url, data, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_ASSINGED_ZONE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_vehicleplateDDL = (dispatch: any, signal?: AbortSignal, search?: string) => {
	let api_url = VEHICLEPLATE_DDL_URL;
	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_VEHICLEPLATE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_BayDDL = (dispatch: any, postData?: object, signal?: AbortSignal) => {
	let api_url = BAY_DDL_URL;
	let data = typeof postData === 'undefined' ? { 'query': '' } : postData;

	Request('post', api_url, data, signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_BAY_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_BayTypeDDL = (dispatch: any, postData?: any) => {
	let api_url = BAYTYPE_DDL_URL;
	// let data = typeof postData === 'undefined' ? { 'Searchkey': '' } : postData;
	let data = typeof postData !== 'undefined' && postData ? '?SiteID='+postData : '';
	console.log('BAYTYPE',data,api_url,api_url+data,postData,typeof postData,typeof postData !== 'undefined' && postData)
	Request('get', api_url+data, undefined, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_BAYTYPE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_InvalidReasonDDL = (dispatch: any, postData?: object, signal?: AbortSignal) => {
	let api_url = INVALID_REASONS_DDL_URL;
	// let data = typeof postData==='undefined' ? { "query":"" } : postData

	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_INVALIDREASON_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_RejectionReasonDDL = (dispatch: any, postData?: object, signal?: AbortSignal) => {
	let api_url = REJECTION_REASON_DDL_URL;
	// let data = typeof postData==='undefined' ? { "query":"" } : postData

	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_REJECTIONREASON_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_policyNameDDL = (dispatch: any, postData?: object, signal?: AbortSignal) => {
	let api_url = PARKING_POLICY_DDL_URL;
	let data = typeof postData === 'undefined' ? { 'query': '' } : postData;

	Request('get', api_url, data, signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_POLICYNAME_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_violationTypeDDL = (dispatch: any, signal?: AbortSignal) => {
	let api_url = VIOLATIONTYPE_DDL_URL;
	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_VIOLATIONTYPE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

export const fetch_web_notifications = (dispatch: any, postData: any, signal?: AbortSignal ) => {
	dispatch(
		UPDATE_NOTIFICATIONS_LOADER({
			loader: true,
		})
	);

	let api_url = NOTIFICATION_WEB;
	Request('post', api_url, postData, signal)?.then((res) => {
		const { statusCode, response } = res;
		const { data, pagination, filters, totalRecordsUnRead } = response;
		if (statusCode === 200) {
			dispatch(
				UPDATE_NOTIFICATIONS({
					data,
					pagination,
					filters,
					totalRecordsUnRead
				})
			);
		} else {
			UPDATE_NOTIFICATIONS_ERROR({
				error: 'Some error occured',
			});
		}
	});
};

export const fetch_web_notifications_popUp = (postData: any, signal?: AbortSignal ) => {
	let api_url = NOTIFICATION_WEB;
	return Request('post', api_url, postData, signal);
};



export const read_web_notification = (postData: any, success?: (res: any) => void, failed?: (res: any) => void) => {
	let api_url = NOTIFICATION_WEB_ACTION+postData;
	Request('get', api_url, undefined, undefined, success, failed);
};

export const readAll_web_notification = ( success?: (res: any) => void, failed?: (res: any) => void) => {
	let api_url = NOTIFICATION_MARK_ALLREAD;
	Request('get', api_url, undefined, undefined, success, failed);
};

export const delete_web_notification = (postData: any, success?: (res: any) => void, failed?: (res: any) => void) => {
	let api_url = NOTIFICATION_WEB_SINGLE_DELETE;
	Request('put', api_url, postData, undefined, success, failed);
};

export const deleteAll_web_notification = ( success?: (res: any) => void, failed?: (res: any) => void) => {
	let api_url = NOTIFICATION_WEB_ACTION;
	Request('delete', api_url, undefined, undefined, success, failed);
};


export const fetch_site = () => {
	let api_url = SITE_DDL_URL;
	return Request('get', api_url, '');
};

export const fetch_floorLevel = (id: any) => {
	let postData = {
		'query': `${id ? ` FK_SiteID = ${id}` : ''}`,
	};
	let api_url = FLOORLEVEL_DDL_URL;
	return Request('post', api_url, postData);
};

export const fetch_zone = (id: any) => {
	let postData = {
		'query': `${id ? `fK_FLevelID = ${id}` : ''}`,
	};
	let api_url = ZONE_DDL_URL;
	return Request('post', api_url, postData);
};

export const fetch_zone_with_ID = async (id: any) => {
	let api_url = ZONE_DDL_URL;

	let postData = {
		'query': `${id ? ` ZoneID = ${id}` : ''}`,
	};
	return Request('post', api_url, postData, undefined);
};

export const fetch_userTypeDDL = (dispatch: any, signal?: AbortSignal) => {
	let api_url = DEFINITION_URL + 'user-type';

	let postdata = {
		filters: {query :""},
		pagination: {
			pageNo: 1,
			limit: 200,
		},
	};
	Request('post', api_url, postdata, signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			let response = res?.response?.data ?? [];
			let data = response.map((item: any) => ({ label: item?.typeName, value: item?.typeID }));
			dispatch(
				UPDATE_USERTYPE_DDL({
					data: data ?? [],
				})
			);
		}
	});
};

export const fetch_requestType = (dispatch: any, signal: AbortSignal, search?: string) => {
	let api_url = '';
	Request('get', api_url, '', signal)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_REQUEST_TYPE_DDL({
					data: res?.response ?? [],
				})
			);
		}
	});
};

