import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';

import AppRouter from './AppRouter';
import Navbar from './common/components/Navbar';
import Sidebar from './common/components/Sidebar';
import auth from './config/auth';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import LoginIndex from './modules/auth/Loginindex';
import ResetIndex from './modules/auth/components/ResetPasswrd';
import { fetchUserPermission, fetchUserProfile, SSO_LOGIN } from './modules/auth/store/action';
import { useAppDispatch } from './config/hooks';
import './App.css';
import FullLoader from './common/components/FullLoader';
import SSOLoder from './modules/auth/SSOLoder';
import { globalConfig } from '../config/config';
import Request from './config/api';

// import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { GenerateToken } from './authConfig';
import { REFRESH_AUTHENTICATION } from './config/endpoint';

import {  useMsal } from '@azure/msal-react';
import { FailedToast } from './utils/utilFunctions';
import useIsAuthenticated from './utils/hooks/useIsAuthenticated';
import moment from 'moment';
import axios from 'axios';
import ExportingReports from './modules/exportingReports'
import { UPDATE_TOKEN } from './modules/auth/store/loginSlice';
const loginRequest = {
	scopes: ['User.Read'],
};

function App() {
	const [isOpen, setisOpen] = useState(true);
	let navigate = useNavigate();

	const [loader, setloader] = useState(true);

	const toggle = () => setisOpen(!isOpen);
	let dipatch = useAppDispatch();

	useEffect(() => {
		const sucess = (res: any) => {
			setloader(false);
		};
		const Failed = (err: any) => {
			setloader(false);
		};
		fetchUserPermission(dipatch, sucess, Failed, navigate );
		fetchUserProfile(dipatch);
	}, [dipatch]);

	return (
		<div className={isOpen ? 'app-layout' : 'app-layout overflow-hidden show-icon'} dir={auth.get_lang() === 'arabic' ? 'rtl' : 'ltr'}>
			{loader ? (
				<FullLoader />
			) : (
				<>
					<Sidebar />
					<div className='admin-content'>
						<Navbar toggle={toggle} />
						<Fragment>
							<Routes>
								<Route element={<ProtectedRoute />}>
									<Route path='/*' element={<AppRouter />} />
								</Route>
							</Routes>
						</Fragment>
					</div>
				</>
			)}
		</div>
	);
}


const AppProtectRouting: FC = () => {
	const { instance, accounts, inProgress } = useMsal();

	const [authLoading, setauthLoading] = useState(false)
	let navigate = useNavigate();
	let dispatch = useAppDispatch();


	const authData = useIsAuthenticated()
 
	useEffect(() => {
		if (globalConfig?.get()?.SSO_REDIRECTION && auth?.get_ot() !== 'true' && auth?.get_lo()  !== 'true') {
			auth?.set_ot('true');
			console.log('INSTANCE=>',instance)
			const handleLogin = () => {
				instance
					.handleRedirectPromise()
					.then((tokenResponse) => {
						console.log('TOKENRESP',tokenResponse)
						if (!tokenResponse) {
							const accounts = instance.getAllAccounts();
							if (accounts.length === 0) {
								instance.loginRedirect();
							}
						} else {
						}
					})
					.catch((err) => {
						console.log('instanceHandleREdirectPromiseErr',err);
					});
			};
			handleLogin();
		}
		console.log('USEEFFECT RUN');
	}, [instance]);

	useEffect(() => {
		if (auth.isAuthenticated()) return;
		console.log("url" , accounts)
		if (accounts[0]?.idTokenClaims?.preferred_username) {
			let url = 'auth/authorize-sso/';
			let t = GenerateToken(accounts[0]?.idTokenClaims?.preferred_username);
			let postData = {
				key: t,
			};

			console.log("url" , accounts)
			setauthLoading(true)
			Request('post', url, postData)?.then((res) => {
				if (res?.statusCode === 200) {
					auth.login(res?.response?.accessToken, res?.response?.refreshToken, () => navigate('/', { replace: true }));
					setauthLoading(false)

				} else {
					setauthLoading(false)

					FailedToast(res?.response);
				}
			});
		}
	}, [accounts]);

	const [redirect, setredirect] = useState(false);

	const redirectFX = () => {
		setredirect(true);
	};

	// function signOutClickHandler() {
	// 	const logoutRequest = {
	// 	  account: instance.getAccountByHomeId(homeAccountId),
	// 	  postLogoutRedirectUri: "your_app_logout_redirect_uri",
	// 	};
	// 	instance.logoutRedirect(logoutRequest);
	//   }


	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if(authData?.isAuth){


			let cur = Date.now()/1000
			let day = moment.unix(cur)
			let exp = moment.unix(authData?.exp)

			let timeleft = exp.diff(day, 'seconds') - 10
			setTimeout(() => {

				axios
				.post<ResponseType>(REFRESH_AUTHENTICATION, '', {
					headers: { 'Content-Type': 'application/json' },
					withCredentials : true,
					signal: signal,
				})
				.then((response:any) => {

					auth.set_access_token(response?.accessToken ?? '');
					dispatch(UPDATE_TOKEN({data : response?.accessToken ?? '' }))
					// log(response,"RESPONSE API ",'green');

				})
				.catch((error) => {
					// log(error,"ERROR API ",'red');

				});

				console.log("TIME OUT RUN")
				
			},timeleft * 1000 );


			console.log("TIME OUT STARTED" , timeleft )

			
			
		}
	  
	
		return () => {
			controller.abort();
		};
	}, [authData, dispatch])


	const SSOAuthorizerFx = () =>{
	
		let url = 'auth/authorize-sso/';
		let t = GenerateToken(accounts[0]?.idTokenClaims?.preferred_username);
		let postData = {
			key: t,
		};
	
		console.log("url" , accounts)
		setauthLoading(true)
		Request('post', url, postData)?.then((res) => {
			if (res?.statusCode === 200) {
				auth.login(res?.response?.accessToken, res?.response?.refreshToken, () => navigate('/', { replace: true }));
				setauthLoading(false)
				setredirect(true)
			} else {
				setauthLoading(false)
	
				FailedToast(res?.response);
			}
		});
	}
	





	// const authData = useIsAuthenticated()

	//console.log("authData",authData)





	console.log('redirect', redirect);
	console.log('accounts',accounts,'authClassObj',auth)
	return (
		<Fragment>
			{accounts[0] || redirect || auth.isAuthenticated() ? (
				<Routes>
					<Route element={<ProtectedRoute />}>
						<Route path='/*' element={<App />} />
					</Route>
					<Route path='/login' element={authLoading ? <SSOLoder/> :<LoginIndex redirectFX={redirectFX} SSOAuthorizerFx={SSOAuthorizerFx}/>} />
					<Route path='/reset-password/:tk' element={<ResetIndex />} />
					<Route path='/exportreportsanddashboard/*' element={<ExportingReports/>} />
				</Routes>
			) : (
				<Routes>
					<Route path='*' element={<LoginIndex redirectFX={redirectFX} SSOAuthorizerFx={SSOAuthorizerFx}/>} />
					<Route path='/reset-password/:tk' element={<ResetIndex />} />
					<Route path='/exportreportsanddashboard/*' element={<ExportingReports/>} />
				</Routes>
			)}
		</Fragment>
		
	);
};

export default AppProtectRouting;
