import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import { TFloorItem } from './dashboard.interface';

interface ddl {
	label: string;
	value: any;
}
const initialState = {
	DASHBOARD_DATA: {},
	DASHBOARD_FILTERS: {} as any,

	SITE_LIST: [] as ddl[],
	FLOOR_LIST: [] as any,
	ZONE_LIST: [] as ddl[],
	FLOOR_ZONE_LIST: [] as ddl[],
	BAYTYPE_LIST:[] as ddl[],


	SELECTED_SITE: {} as ddl,
	SELECTED_FLOOR: {} as TFloorItem,
};

export const DASHBOARDSLICE = createSlice({
	name: 'DASHBOARD',
	initialState: initialState,
	reducers: {
		DASHBOARD_SUCCESS_DATA: (state, action) => {
			const { data } = action.payload;
			state.DASHBOARD_DATA = data;
		},
		UPDATE_DASHBOARD_FILTERS: (state, action) => {
			const { key, filter } = action.payload;
			state.DASHBOARD_FILTERS[key] = filter;
		},
		REMOVE_DASHBOARD_FILTERS: (state, action) => {
			const { key } = action.payload;
			delete state.DASHBOARD_FILTERS[key];
		},

		UPDATE_DASHBOARD_SITE_LIST: (state, action) => {
			const { data } = action.payload;
			state.SITE_LIST = data;
		},

		UPDATE_DASHBOARD_FLOOR_LIST: (state, action) => {
			const { data } = action.payload;
			state.FLOOR_LIST = data;
		},

		UPDATE_DASHBOARD_ZONE_LIST: (state, action) => {
			const { data } = action.payload;
			state.ZONE_LIST = data;
		},
		UPDATE_DASHBOARD_FLOOR_ZONE_LIST: (state, action) => {
			const { data } = action.payload;
			state.FLOOR_ZONE_LIST = data;
		},
		UPDATE_DASHBOARD_BAYTYPE_LIST: (state, action) => {
			const { data } = action.payload;
			state.BAYTYPE_LIST = data;
		},

		UPDATE_DASHBOARD_SELECTED_SITE: (state, action) => {
			const { data } = action.payload;
			state.SELECTED_SITE = data;
		},

		UPDATE_DASHBOARD_SELECTED_FLOOR: (state, action) => {
			const { data } = action.payload;
			state.SELECTED_FLOOR = data;
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
	DASHBOARD_SUCCESS_DATA,
	UPDATE_DASHBOARD_FILTERS,
	REMOVE_DASHBOARD_FILTERS,
	UPDATE_DASHBOARD_SITE_LIST,
	UPDATE_DASHBOARD_FLOOR_LIST,
	UPDATE_DASHBOARD_SELECTED_SITE,
	UPDATE_DASHBOARD_SELECTED_FLOOR,
	UPDATE_DASHBOARD_ZONE_LIST,
	UPDATE_DASHBOARD_FLOOR_ZONE_LIST,
	UPDATE_DASHBOARD_BAYTYPE_LIST,
	RESET_STATE

} = DASHBOARDSLICE.actions;
export const DASHBOARDData = (state: RootState) => state;
export default DASHBOARDSLICE.reducer;
