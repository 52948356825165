/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import CommonTable from '../../../../common/components/Table/CommonTable';
import ViewModal from './ViewModal';
import AddModal from './AddModal';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { useNavigate } from 'react-router-dom';
import { delete_visitor, fetch_visitorlist, fetch_visitor_detail } from '../../store/action';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../config/subModuleList';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import CustomConfirm from '../../../../common/components/CustomConfrmBox';
import ButtonLoader from '../../../../common/components/ButtonLoader';
 
const ListTable = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const VISTORS_DATA = useAppSelector((state) => state?.visitor);

	const fetchIdRef = useRef(0);

	const [editModal, setEditModal] = useState(false);
	const [loader, setloader] = useState(false);

	const [editData, seteditData] = useState();

	const [visitorDetail, setvisitorDetail] = useState();
	const [id, setid] = useState();


	const editModalOpen = (data: any) => {
		console.log(data);
		seteditData(data);
		setEditModal(true);
	};
	const editModalClose = () => setEditModal(false);



	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy:string;
		filters: {query: string;}
	};

	const [delModal, setdelModal] = useState(false);

	const delModalClose = () => setdelModal(false);

	const DeleteUser = (id: number) => {
		setloader(true)
		const success = (res: any) => {
			SuccesToast(res?.response?.data);
			fetch_visitorlist(navigate, dispatch, {
				'filters': {
					'query': VISTORS_DATA?.VISITOR_FILTER?.query || '',
				},
				'pagination': {
					'pageNo': VISTORS_DATA?.VISITOR_PAGINATION?.pageNo,
					'limit': VISTORS_DATA?.VISITOR_PAGINATION?.limit,
				},
			});
			delModalClose();
		setloader(false)

		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
		setloader(false)

		};

		delete_visitor(id, success, failed);
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal ,OrderBy ,filters}: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_visitorlist(
					navigate,
					dispatch,
					{
						'filters': {
							'query':filters.query || '',
							OrderBy
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	const columns = React.useMemo(
		() => [
			{ Header: 'Id', accessor: 'visitorID' ,orderByKey:"visitorID"},
			{ Header: 'User Name', accessor: 'firstName' ,orderByKey:"firstName"},
			{ Header: 'Email', accessor: 'emailID' ,orderByKey:"emailID"},
			{ Header: 'Contact No', accessor: 'contactNo' },
			{ Header: 'Company', accessor: 'companyName' },

	

			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
							<PermissionWrapper subModuleID={subModuleList.Visitors} type='isEdit' >
								<a onClick={() => editModalOpen(row?.original)} className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-pen'></i>
								</a>
							</PermissionWrapper>
							<PermissionWrapper subModuleID={subModuleList.Visitors} type='isDelete' >
							<div className=''>
										<a
											className='btn btn-sqaure btn-red modal-button'
											onClick={() => {
												setid(row?.original?.visitorID);
												setdelModal(true);
											}}>
											<i className='fa-solid fa-trash'></i>
										</a>
									</div>
							</PermissionWrapper>

							</div>
						</Fragment>
					);
				},
			},
		],
		[]
	);
	return (
		<>
			<CommonTable
				columns={columns}
				data={VISTORS_DATA?.VISITOR_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={VISTORS_DATA?.VISITOR_PAGINATION?.totalCount ?? 0}
				loading={VISTORS_DATA?.VISITOR_LOADER}
				page_no={VISTORS_DATA?.VISITOR_PAGINATION?.pageNo ?? 1}
				limit={VISTORS_DATA?.VISITOR_PAGINATION?.limit ?? 1}
				error={VISTORS_DATA?.VISITOR_ERROR ?? ""}
				filters={VISTORS_DATA?.VISITOR_FILTER}
			/>
			{/* {viewModal && <ViewModal open={viewModal} handleClose={viewModalClose} Data={visitorDetail} />} */}

			{editModal && <AddModal open={editModal} handleClose={editModalClose} Data={editData} />}

			<CustomConfirm
				open={delModal}
				handleClose={delModalClose}
				title='Are you sure?'
				description='This action is irreversible. Can you confirm?'>

				<button
				disabled={loader}
					type='submit'
					className='btn btn-secondary'
					onClick={(e) => {
						e.preventDefault();
						if (id) DeleteUser(id);
					}}>
				<ButtonLoader loading={loader} text="Confirm" />
					
				</button>

			</CustomConfirm>
		</>
	);
};

export default ListTable;
