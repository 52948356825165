import React from "react";
import { fetch_report_availability_historical_bayType, fetch_report_availability_historical_weekday, fetch_report_availability_historical_zone, fetch_report_availability_hourly_bayType, fetch_report_availability_hourly_overall, fetch_report_availability_hourly_weekday, fetch_report_availability_hourly_zone, fetch_report_availability_summary_bayType, fetch_report_availability_summary_weekday, fetch_report_availability_summary_zone, fetch_report_dwell_daily_bayType, fetch_report_dwell_daily_overall, fetch_report_dwell_daily_zone, fetch_report_dwell_distribution_bayType, fetch_report_dwell_distribution_time, fetch_report_dwell_distribution_zone, fetch_report_dwell_summary_bayType, fetch_report_dwell_summary_weekday, fetch_report_dwell_summary_zone, fetch_report_entry_exit, fetch_report_occupancy_historical_bayType, fetch_report_occupancy_historical_weekday, fetch_report_occupancy_historical_zone, fetch_report_occupancy_hourly_bayType, fetch_report_occupancy_hourly_overall, fetch_report_occupancy_hourly_weekday, fetch_report_occupancy_hourly_zone, fetch_report_occupancy_summary_bayType, fetch_report_occupancy_summary_weekday, fetch_report_occupancy_summary_zone, fetch_report_turnover_daily_bayType, fetch_report_turnover_daily_overall, fetch_report_turnover_daily_zone, fetch_report_turnover_summary_bayType, fetch_report_turnover_summary_weekday, fetch_report_turnover_summary_zone } from "../reports/store/action";
import { BarGraphChartObject, EntryExitGraphChartObject, LineBarGraphChartObject, ProgressBarChartObject } from "./exportableGraphComponents/exportableChartObjects";

export const dwellValConvertTimeToReadable = (num) =>{ 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
//   console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
  return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes?`${minutes} min` : ''}` : `${minutes} min`}`;         
}

export const GraphRoutes = [
    {
        path        : "occupancy-summary-by-day-of-week",
        actionFn    : fetch_report_occupancy_summary_weekday,
        title       : 'Occupancy By Day of Week',
        titleArr    : [],
        Comp        :  React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    {
        path        : "occupancy-summary-by-bay-type",
        actionFn    : fetch_report_occupancy_summary_bayType,
        title       : 'Occupancy By Bay-Type',
        titleArr    : [],
        Comp        :  React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    { 
        path        : "occupancy-summary-by-zone" ,
        actionFn    : fetch_report_occupancy_summary_zone,
        title       : 'Occupancy By Zone',
        titleArr    : [],
        Comp        :  React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    {   
        path        : "hourly-occupancy-overall" ,
        actionFn    : fetch_report_occupancy_hourly_overall,
        title       : 'Hourly Bay Occupancy Overall',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy',
                            extraFunc : (v) => { return `${v} %` } }} ref={ref}/>)
    },
    {
        path        : "hourly-occupancy-by-day" ,
        actionFn    : fetch_report_occupancy_hourly_weekday,
        title       : 'Hourly Occupancy By Day',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },
    {   
        path        : "hourly-occupancy-by-bay-type" ,
        actionFn    : fetch_report_occupancy_hourly_bayType,
        title       : 'Hourly Occupancy By Bay-Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },    
    {
        path        : "hourly-occupancy-by-zone" ,
        actionFn    : fetch_report_occupancy_hourly_zone,
        title       : 'Hourly Occupancy By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },    
    {    
        path        : "historical-occupancy-by-day" ,
        actionFn    : fetch_report_occupancy_historical_weekday,
        title       : 'Historical Occupancy By Day',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy', extraFunc : (v)=>{ return `${v} ` } }} ref={ref}/>)
    },
    {
        path        : "historical-occupancy-by-bay-type" ,
        actionFn    : fetch_report_occupancy_historical_bayType,
        title       : 'Historical Occupancy By Bay Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy', extraFunc : (v)=>{ return `${v} ` } }} ref={ref}/>)
    },  
    {  
        path        : "historical-occupancy-by-zone" ,
        actionFn    : fetch_report_occupancy_historical_zone,
        title       : 'Historical Occupancy By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy', extraFunc : (v)=>{ return `${v} ` } }} ref={ref}/>)
    },    
    {    
        path        : "peak-occupancy-by-day" ,
        actionFn    : fetch_report_occupancy_historical_weekday,
        title       : 'Peak Occupancy By Day',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Occupancy Count (cars)',  extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },
    {
        path        : "dwell-time-summary-by-day-of-week" ,
        actionFn    : fetch_report_dwell_summary_weekday,
        title       : 'Dwell Time Day of Week',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    {    
        path        : "dwell-time-summary-by-bay-type" ,
        actionFn    : fetch_report_dwell_summary_bayType,
        title       : 'Dwell Time By Bay-Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },    
    {
        path        : "dwell-time-summary-by-zone" ,
        actionFn    : fetch_report_dwell_summary_zone,
        title       : 'Dwell Time By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },    
    {
        path        : "daily-dwell-time-overall" ,
        actionFn    : fetch_report_dwell_daily_overall,
        title       : 'Daily Dwell Time Overall',
        titleArr    : [],
        Comp        :    React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Time', extraFunc : dwellValConvertTimeToReadable , 
									yTickFormaterFn : dwellValConvertTimeToReadable }} ref={ref}/>)
    },
    {    
        path        : "daily-dwell-time-by-bay-type" ,
        actionFn    : fetch_report_dwell_daily_bayType,
        title       : 'Daily Dwell Time By Bay-Type',
        titleArr    : [],
        Comp        :    React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Time', extraFunc : dwellValConvertTimeToReadable , 
									yTickFormaterFn : dwellValConvertTimeToReadable }} ref={ref}/>)
    },    
    {
        path        : "daily-dwell-time-by-zone" ,
        actionFn    : fetch_report_dwell_daily_zone,
        title       : 'Daily Dwell Time By Zone',
        titleArr    : [],
        Comp        :    React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Time', extraFunc : dwellValConvertTimeToReadable , 
									yTickFormaterFn : dwellValConvertTimeToReadable }} ref={ref}/>)
    },    
    {
        path        : "dwell-time-distribution-by-time" ,
        actionFn    : fetch_report_dwell_distribution_time,
        title       : 'Dwell Time Distribution (By Time)',
        titleArr    : [],
        Comp        :    React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Dwell', extraFunc : dwellValConvertTimeToReadable , 
									yTickFormaterFn : dwellValConvertTimeToReadable }} ref={ref}/>)
    },    
    {
        path        : "dwell-time-distribution-by-bay-type" ,
        actionFn    : fetch_report_dwell_distribution_bayType,
        title       : 'Dwell Time Distribution By Bay-Type',
        titleArr    : [],
        Comp        :    React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Dwell', extraFunc : dwellValConvertTimeToReadable , 
									yTickFormaterFn : dwellValConvertTimeToReadable }} ref={ref}/>)
    },    
    {
        path        : "dwell-time-distribution-by-zone" ,
        actionFn    : fetch_report_dwell_distribution_zone,
        title       : 'Dwell Time Distribution By Zone',
        titleArr    : [],
        Comp        :    React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Dwell', extraFunc : dwellValConvertTimeToReadable , 
									yTickFormaterFn : dwellValConvertTimeToReadable }} ref={ref}/>)
    },
    {
        path        : "availability-summary-by-day-of-week",
        actionFn    : fetch_report_availability_summary_weekday,
        title       : 'Availability By Day of Week',
        titleArr    : [],
        Comp        :  React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    {
        path        : "availability-summary-by-bay-type",
        actionFn    : fetch_report_availability_summary_bayType,
        title       : 'Availability By Bay-Type',
        titleArr    : [],
        Comp        :  React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    { 
        path        : "availability-summary-by-zone" ,
        actionFn    : fetch_report_availability_summary_zone,
        title       : 'Availability By Zone',
        titleArr    : [],
        Comp        :  React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },
    {   
        path        : "hourly-availability-overall" ,
        actionFn    : fetch_report_availability_hourly_overall,
        title       : 'Hourly Bay Availability Overall',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },
    {
        path        : "hourly-availability-by-day" ,
        actionFn    : fetch_report_availability_hourly_weekday,
        title       : 'Hourly Availability By Day',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },
    {   
        path        : "hourly-availability-by-bay-type" ,
        actionFn    : fetch_report_availability_hourly_bayType,
        title       : 'Hourly Availability By Bay-Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },    
    {
        path        : "hourly-availability-by-zone" ,
        actionFn    : fetch_report_availability_hourly_zone,
        title       : 'Hourly Availability By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability',
                            extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },    
    {    
        path        : "historical-availability-by-day" ,
        actionFn    : fetch_report_availability_historical_weekday,
        title       : 'Historical Availability By Day',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability', extraFunc : (v)=>{ return `${v} ` } }} ref={ref}/>)
    },
    {
        path        : "historical-availability-by-bay-type" ,
        actionFn    : fetch_report_availability_historical_bayType,
        title       : 'Historical Availability By Bay Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability', extraFunc : (v)=>{ return `${v} ` } }} ref={ref}/>)
    },  
    {  
        path        : "historical-availability-by-zone" ,
        actionFn    : fetch_report_availability_historical_zone,
        title       : 'Historical Availability By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability', extraFunc : (v)=>{ return `${v} ` } }} ref={ref}/>)
    },    
    {    
        path        : "peak-availability-by-day" ,
        actionFn    : fetch_report_availability_historical_weekday,
        title       : 'Peak Availability By Day',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Availability Count (cars)',  extraFunc : (v)=>{ return `${v} %` } }} ref={ref}/>)
    },
    {    
        path        : "average-turnover-summary-by-day-of-week" ,
        actionFn    : fetch_report_turnover_summary_weekday,
        title       : 'Average Turn Over By Day of Week',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },    
    {
        path        : "average-turnover-summary-by-bay-type" ,
        actionFn    : fetch_report_turnover_summary_bayType,
        title       : 'Average Turn Over By Bay Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },    
    {
        path        : "average-turnover-summary-by-zone" ,
        actionFn    : fetch_report_turnover_summary_zone,
        title       : 'Average Turn Over By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <ProgressBarChartObject {...props} ref={ref}/>)
    },    
    {
        path        : "daily-turnover-overall" ,
        actionFn    : fetch_report_turnover_daily_overall,
        title       : 'Daily Turn Over Overall',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Cars', unit : '%',  extraFunc : (v)=>{ return `${v}` } }} ref={ref}/>)
    },    
    {
        path        : "daily-turnover-by-bay-type" ,
        actionFn    : fetch_report_turnover_daily_bayType,
        title       : 'Daily Turn Over By Bay Type',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Cars', unit : '%',  extraFunc : (v)=>{ return `${v}` } }} ref={ref}/>)
    },    
    {
        path        : "daily-turnover-by-zone" ,
        actionFn    : fetch_report_turnover_daily_zone,
        title       : 'Daily Turn Over By Zone',
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <LineBarGraphChartObject 
                            props = {{...props, labelY : 'Cars', unit : '%',  extraFunc : (v)=>{ return `${v}` } }} ref={ref}/>)
    },    
    {
        path        : "entry-exit-occupancy",
        actionFn    : fetch_report_entry_exit,
        title       : `Entries and Exits Report`,
        titleArr    : [],
        Comp        :   React.forwardRef(({props}, ref) => <EntryExitGraphChartObject
                            {...props} ref={ref}/>)
    }
]