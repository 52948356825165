import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	BLACKLISTED_LIST_DATA    : [],
	BLACKLISTED_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	BLACKLISTED_LOADER       : true,
	BLACKLISTED_FILTER       : { query: "" } as FilterObject,
	BLACKLISTED_ERROR        : '',
	
	REPORT_VIOLATION_DATA 	  	  : [],
	REPORT_VIOLATION_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	REPORT_VIOLATION_LOADER       : true,
	REPORT_VIOLATION_FILTER       : { query: "" } as FilterObject,
	REPORT_VIOLATION_ERROR        : '',

	REPORT_VIOLATION_HISTORY_DATA 	  	  : [],
	REPORT_VIOLATION_HISTORY_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	REPORT_VIOLATION_HISTORY_LOADER       : true,
	REPORT_VIOLATION_HISTORY_FILTER       : { query: "" } as FilterObject,
	REPORT_VIOLATION_HISTORY_ERROR        : '',

	PARKING_VIOLATION_DATA 	  	  : [],
	PARKING_VIOLATION_PAGINATION   : { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	PARKING_VIOLATION_LOADER       : true,
	PARKING_VIOLATION_FILTER       : { query: "" } as FilterObject,
	PARKING_VIOLATION_ERROR        : '',
	PARKING_VIOLATION_SITE		   : '',

	VIOLATION_COUNT			:	{ totlaViolations: 0, firstPenalty : 0, secondPenalty : 0, thirdPenalty : 0 }, 
	VIOLATION_COUNT_BYTYPE  :	[]
};

export const VIOLATIONSSLICE = createSlice({
	name: 'VIOLATIONS',
	initialState: initialState,
	reducers: {
		UPDATE_PARKING_VIOLATION_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.PARKING_VIOLATION_DATA = data;
			state.PARKING_VIOLATION_PAGINATION = pagination;
			state.PARKING_VIOLATION_FILTER = filters;
			state.PARKING_VIOLATION_LOADER = false;
			state.PARKING_VIOLATION_ERROR = '';
			state.PARKING_VIOLATION_SITE = action.payload?.siteID;
		},
		UPDATE_PARKING_VIOLATION_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.PARKING_VIOLATION_LOADER = loader;
		},

		UPDATE_PARKING_VIOLATION_ERROR: (state, action) => {
			const { error } = action.payload;
			state.PARKING_VIOLATION_ERROR = error;
			state.PARKING_VIOLATION_LOADER = false;
		},
		
		UPDATE_BLACKLISTED_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.BLACKLISTED_LIST_DATA = data;
			state.BLACKLISTED_PAGINATION = pagination;
			state.BLACKLISTED_FILTER = filters;
			state.BLACKLISTED_LOADER = false;
			state.BLACKLISTED_ERROR = '';
		},
		UPDATE_BLACKLISTED_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.BLACKLISTED_LOADER = loader;
		},

		UPDATE_BLACKLISTED_ERROR: (state, action) => {
			const { error } = action.payload;
			state.BLACKLISTED_ERROR = error;
			state.BLACKLISTED_LOADER = false;
		},

		UPDATE_REPORT_VIOLATION_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.REPORT_VIOLATION_DATA = data;
			state.REPORT_VIOLATION_PAGINATION = pagination;
			state.REPORT_VIOLATION_FILTER = filters;
			state.REPORT_VIOLATION_LOADER = false;
			state.REPORT_VIOLATION_ERROR = '';
		},
		UPDATE_REPORT_VIOLATION_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.REPORT_VIOLATION_LOADER = loader;
		},

		UPDATE_REPORT_VIOLATION_ERROR: (state, action) => {
			const { error } = action.payload;
			state.REPORT_VIOLATION_ERROR = error;
			state.REPORT_VIOLATION_LOADER = false;
		},
		UPDATE_REPORT_VIOLATION_HISTORY_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.REPORT_VIOLATION_HISTORY_DATA = data;
			state.REPORT_VIOLATION_HISTORY_PAGINATION = pagination;
			state.REPORT_VIOLATION_HISTORY_FILTER = filters;
			state.REPORT_VIOLATION_HISTORY_LOADER = false;
			state.REPORT_VIOLATION_HISTORY_ERROR = '';
		},
		UPDATE_REPORT_VIOLATION_HISTORY_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.REPORT_VIOLATION_HISTORY_LOADER = loader;
		},
		UPDATE_VIOLATION_COUNT: (state, action) => {
			const { count } = action.payload;
			state.VIOLATION_COUNT = count
		},
		UPDATE_REPORT_VIOLATION_HISTORY_ERROR: (state, action) => {
			const { error } = action.payload;
			state.REPORT_VIOLATION_HISTORY_ERROR = error;
			state.REPORT_VIOLATION_HISTORY_LOADER = false;
		},
		
		UPDATE_VIOLATION_COUNTBYTYPE: (state, action) => {
			const { count } = action.payload;
			state.VIOLATION_COUNT_BYTYPE = count
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const { UPDATE_BLACKLISTED_DATA, UPDATE_BLACKLISTED_LOADER, UPDATE_BLACKLISTED_ERROR, UPDATE_VIOLATION_COUNT, UPDATE_VIOLATION_COUNTBYTYPE,
	UPDATE_REPORT_VIOLATION_DATA, UPDATE_REPORT_VIOLATION_LOADER, UPDATE_REPORT_VIOLATION_ERROR, RESET_STATE,
	UPDATE_REPORT_VIOLATION_HISTORY_DATA, UPDATE_REPORT_VIOLATION_HISTORY_LOADER, UPDATE_REPORT_VIOLATION_HISTORY_ERROR,
	UPDATE_PARKING_VIOLATION_DATA, UPDATE_PARKING_VIOLATION_LOADER, UPDATE_PARKING_VIOLATION_ERROR } =
	VIOLATIONSSLICE.actions;
export const VIOLATIONSData = (state: RootState) => state;
export default VIOLATIONSSLICE.reducer;
