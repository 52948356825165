import React, { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_visit_report_plate_freq } from '../../../store/action';
import dayjs from 'dayjs';

type Props = {}

const PlateFreq = (props: Props) => {
    const fetchIdRef = useRef(0);
    const navigate = useNavigate();
	const dispatch = useAppDispatch();
    const VISITS_REPORTS_DATA = useAppSelector((state) => state?.reportData);
	const SITE_FLOOR = useAppSelector((state)=>state?.general)

	function dwell_time_convert(num:number)
{ 
   var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
   var d = (num / 60) % 24
   var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
   var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
//  console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
 return `${days ? `${days} Days` : ''} ${hours ? `${hours} hours ${minutes} minutes` : `${minutes} minutes`}`;         
}



    const columns = React.useMemo(
		() => [
            { Header: 'Plate Number', accessor: 'plateNumber'},
			{ Header: 'Place of registration', accessor: 'placeOfRegistration'},
			{ Header: 'Vehicle Category', accessor: 'vehicleCategory'},
            { Header: 'Visit Count', accessor: 'visitCount'},
            { Header: 'Average Dwell Time', accessor: 'avarageDwellTime',
			Cell: ({ row }: any) => {
				return (
					<p>
						{dwell_time_convert(row?.original?.duration/row?.original?.visitCount)}
					</p>
				);
			}
			},
			{ Header: 'Full Name', accessor: 'fullName'},
			{ Header: 'Email', accessor: 'emailID'},
			{ Header: 'Site Name', accessor: 'siteName'},
			{ Header: 'Contact', accessor: 'contactNo'},
			
			
			{ Header: 'Designation', accessor: 'desName'},
			{ Header: 'Department Name', accessor: 'depName'},
			{ Header: 'Personal Grade', accessor: 'fK_PriorityLevel'},
			{ Header: 'Company Name', accessor: 'companyName'},
			{ Header: 'User Type', accessor: 'utype', 
				Cell: ({ row }:any) => {
					return <p>{row?.original?.utype===1 ? 'Employee' : 'Visitor'}</p>
				}
			},
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,
				show: false,
			}			
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		filters: {
			siteID : 0,	
			fromDate : "",	
			toDate : "",		
			fromTime : "",		
			toTime : "",		
			zoneIDs :"" ,			
			bayTypeIDs : "",		
			visitDurationFrom :0,		
			visitDurationTo : 0,		
			plateTextSearch : "",
			query : ''
		};
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				let postdata = {
					siteID		:	filters?.siteID ? filters?.siteID : SITE_FLOOR?.SITE_DDL[0]?.value ,	
					toDate 		:	filters?.toDate ? 
			  dayjs(filters?.toDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
					fromDate    :   filters?.fromDate ? 
			  dayjs(filters?.fromDate).format('YYYY-MM-DD') : dayjs().subtract(7,'day').format('YYYY-MM-DD'),		
					fromTime    :   filters?.fromTime ? dayjs(filters?.fromTime,['HH:mm:ss','hh:mm a']).format('HH:mm:ss') : '00:00:00',
					toTime      :   filters?.toTime ? dayjs(filters?.toTime,['HH:mm:ss','hh:mm a']).format('HH:mm:ss') :  '23:59:00',		
					zoneIDs 	:	filters?.zoneIDs,			
					bayTypeIDs	:	filters?.bayTypeIDs,		
					visitDurationFrom	:	filters?.visitDurationFrom,		
					visitDurationTo		:	filters?.visitDurationTo,		
					plateTextSearch		:	filters?.plateTextSearch,
					query 				:	filters?.query??''
			}
				fetch_visit_report_plate_freq(
					navigate,
					dispatch,
					{
						'filters': postdata,
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	return (
			<CommonTable
				data={VISITS_REPORTS_DATA?.VISIT_REPORTS_LIST_DATA??[]}
				columns={columns}
				pageCount={VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.totalCount ?? 0}
				fetchData={fetchData}
				limit={VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.limit??1}
				page_no={VISITS_REPORTS_DATA?.VISIT_REPORTS_PAGINATION?.pageNo??1}
				filters={VISITS_REPORTS_DATA?.VISIT_REPORTS_FILTER}
				loading={VISITS_REPORTS_DATA?.VISIT_REPORTS_LOADER}
			/>
    )
}

export default PlateFreq