import React, { Fragment, useEffect, useRef, useState } from 'react';
import ProgressBar from '../../../../common/components/graph_components/ProgressBar';
import { useAppSelector } from '../../../../config/hooks';
import { fetch_occupancy_levelmap, fetch_parking_availability } from '../../store/action';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import Select from 'react-select';
import { DatePicker } from 'antd';
import moment from 'moment';
import { COLORS, COLOR_FX } from '../../../../utils/Data';
import { filterDuplicateObjArr } from '../../../../utils/utilFunctions';
import { ZoneColors } from '../../../../utils/zoneColorCodes';
import ReactSelect from 'react-select';

const { RangePicker } = DatePicker;

type Props = {
	name: string;
};

let typeOption = [
	// {
	// 	label: 'Floor',
	// 	value: 1,
	// },
	{
		label: 'Zone',
		value: 2,
	},
	{
		label: 'Bay Type',
		value: 3,
	},
	{
		label: 'All Zones',
		value: 4
	}
];

const OccupancyByType = (props: Props) => {
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);

	const [data, setdata] = useState([]);
	const [type, settype] = useState(typeOption[2]);
	const [floor, setfloor] = useState(DASHBOARD_DATA?.SELECTED_FLOOR);
	const [zone, setzone] = useState<any>();
	const [bay_type, setbay_type] = useState<any>();
	const [loading, setloading] = useState(false);
	const [date, setdate] = useState<any>([moment().startOf('day'), moment().endOf('day')]);
	const [isUnRecVeh, setIsUnRecVeh] = useState('0')


	// const culminateObj = (id : string, obj : any, arr : any) =>{
	// 	console.log('culminateObj ID',id,'obj',obj,'arr',arr)
	// 	let newObj = obj ?? {...arr}
	// 	let dyz = moment(date[1]).diff(date[0],'days')+1
		
	// 	newObj['totalCount'] = obj ? obj['totalCount'] + arr['totalCount'] : arr['totalCount']
	// 	newObj['occupaidCount'] = obj ? obj['occupaidCount'] + arr['occupaidCount'] : arr['occupaidCount']
	// 	newObj['occupancyPerc'] = ( ((newObj['totalCount']*dyz) - (newObj['occupaidCount'])) /(newObj['totalCount']*dyz))*100
	// 	console.log('NEWOBJ=>',newObj)
	// 	return newObj
	// }
	const culminateObj = (id : string, obj : any, arr : any) =>{
		console.log('culminateObj ID',id,'obj',obj,'arr',arr)
		let newObj = obj ?? {...arr}
		let dyz = moment(date[1]).diff(date[0],'days')+1
		newObj['totalCount'] = obj ? obj['totalCount'] + arr['totalCount'] : arr['totalCount']
		newObj['occupaidCount'] = obj ? obj['occupaidCount'] + arr['occupaidCount'] : arr['occupaidCount']
		newObj['vanced'] = obj ? obj['vanced'] + arr['vanced'] : arr['vanced']

		newObj['occupancyPerc'] = ( ((newObj['occupaidCount'])) /(newObj['totalCount']*dyz))*100
		console.log('NEWOBJ',newObj)
		return newObj
	}

	useEffect(() => {
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR);
	}, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		let postData = {
			Type: type?.value===4 ? 2 : type?.value,
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FloorID: type?.value===4 ? 0 : floor?.levelID,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			// FromTime: date[0].format('HH:MM:00'),
			// ToTime: date[1].format('HH:MM:00'),
			FromTime: '00:00:00',
			ToTime: '23:59:59',
			zoneID : type?.value===4 ? 0 : zone?.value,
			UnknownVehicle : isUnRecVeh
		};

		if (floor?.levelID || type?.value===4) {
			// handleClose();
			setloading(true);

			// fetch_occupancy_levelmap(postData, signal)?.then((res) => {
			// 	if (res?.statusCode < 400) {
			// 		let data = res?.response?.data ?? [];
			// 		let arr = filterDuplicateObjArr(data, 'type', undefined, culminateObj)
			// 		setdata(arr);
			// 		setTimeout(() => {
			// 			setloading(false);
			// 		}, 1500);
			// 	}
			// });
			fetch_parking_availability(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					let arr = filterDuplicateObjArr(data, 'type', undefined, culminateObj)
					setdata(arr);
					setTimeout(() => {
						setloading(false);
					}, 1500);
				}
			});
		}

		return () => {
			controller.abort();
		};
	}, [floor?.levelID, DASHBOARD_DATA?.SELECTED_SITE?.value, type?.value, date, zone?.value, isUnRecVeh]);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	const checkIfReset = () =>{
		return (type!==typeOption[1] || floor!==DASHBOARD_DATA?.SELECTED_FLOOR || zone!==undefined || bay_type!==undefined || 
			(date ? moment(date[1]).diff(date[0],'days') !==7 : false)) ? true : false 
	}

	const resetFilter = () => {
		settype(typeOption[2])
		setfloor(DASHBOARD_DATA?.SELECTED_FLOOR)
		setzone(undefined)
		setdate([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')])
		setbay_type(undefined)
		handleClose()
	 }

	return (
		<div className='ItemLayout__Wrapper'>
			<div className='ItemLayout__Title'>
				<div>Occupancy By {type?.label}{floor && type?.value!==4 ? `${' '}(${floor?.levelName})` : ''} </div>
				<div>
					
					<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose} clearAll={checkIfReset() ? resetFilter : null}>
						
						{/* <div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Date Range</label>
							<RangePicker  size='large' 
								showTime={false}  inputReadOnly
								onChange={(date: any) => setdate(date)}
								value={date}
								allowClear={false}
							
							/>						</div> */}
						<div className='form-group select-input full-label'>
							<label className='label-dnd-menuitem'>Type</label>
							<Select
								className='common-select-grey'
								options={typeOption}
								onChange={(e: any) => settype(e)}
								value={typeOption.find((c: { value: number }) => c.value === type?.value) ?? ''}
								placeholder='type'
							/>
						</div>
						{(type?.value !== 1 && type?.value !== 4) && (
							<>
								<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Floor</label>
									<Select
										className='common-select-grey'
										options={DASHBOARD_DATA?.FLOOR_LIST}
										getOptionLabel={(o: any) => o.levelName}
										getOptionValue={(o: any) => o.levelID}
										onChange={(e: any) => setfloor(e)}
										value={
											DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''
										}
										placeholder='Floor'
									/>
								</div>
								{/* <div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Zone</label>
									<Select
										className='common-select-grey'
										options={DASHBOARD_DATA?.ZONE_LIST}
										onChange={(e: any) => setzone(e)}
										value={DASHBOARD_DATA?.ZONE_LIST.find((c: { value: number }) => c.value === zone?.value) ?? ''}
										placeholder='Zone'
									/>
								</div> */}
								{/* {type?.value !== 2 && (
									<div className='form-group select-input full-label'>
										<label className='label-dnd-menuitem'>Bay Type</label>
										<Select
											className='common-select-grey'
											options={DASHBOARD_DATA?.BAYTYPE_LIST}
											onChange={(e: any) => setbay_type(e)}
											value={
												DASHBOARD_DATA?.BAYTYPE_LIST.find(
													(c: { value: number }) => c.value === bay_type?.value
												) ?? ''
											}
											placeholder='Bay Type'
										/>
									</div>
								)} */}
							</>
						)}
						<div className='form-group select-input full-label'>
									<label className='label-dnd-menuitem'>Vehicle Type</label>
									<ReactSelect
										className='common-select-grey'
										options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								onChange={(val:any) =>{ setIsUnRecVeh(val?.value); }}
										value={
											[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]?.find((el:any) => el.value===isUnRecVeh) ?? ''
										}
										placeholder={'Vehicle Type'}
									/>
							</div>
					</DashboardFilterWrapper>
				</div>
			</div>
			<div className='ItemLayout__Content'>
				<div className='bay-stats progressbar-box widget-levelMap-max-h'>
					{data.length === 0 ? (
						<div className='no_data_found_df'>
							<span>No Data Found</span>
						</div>
					) : (
						<>
							{data.map((item: any, key) => {
								console.log('DATA->ITEM',item)
								return (
									<Fragment key={key}>
										<ProgressBar
											value={item?.occupancyPerc}
											label={item?.type}
											endLabel={`${item?.occupancyPerc.toFixed(2)}%`}
											tootTipText={`occupied : ${item?.occupaidCount}`}
											color={type?.value!==3 ? ZoneColors(item?.type)??COLOR_FX(key) : COLOR_FX(key)}
											
										/>
									</Fragment>
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default OccupancyByType;
