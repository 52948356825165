import React from 'react'
interface Iprops {
    children: React.ReactNode ;

}
const MainLayout: React.FC<Iprops>= ({ children }) => {
    return (
        <div className="main-body-content">
            <div className="white-card">
                {children}
            </div>
        </div>
    )
}

export default MainLayout