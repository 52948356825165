import React, { Fragment } from 'react';
import MainLayout from '../../../../common/layouts/MainLayout';
import FilterBar from './components/FilterBar';
import ListTable from './components/ListTable'; 
type Props = {};

const ParkingPolicy = (props: Props) => {
	return (
		<Fragment>
			<MainLayout>
				<FilterBar />
				<ListTable />
			</MainLayout>
		</Fragment>
	);
};

export default ParkingPolicy;
