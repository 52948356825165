/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import CommonTable from '../../../common/components/Table/CommonTable';
import ViewModal from './NewParkingRequestModal/ParkingRequestAddModal';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { useNavigate } from 'react-router-dom';
import { fetch_parkingRequestslist, fetch_parkingRequest_detail, parkingRequestStatus_action } from '../store/action';
import StatusChipComponent from '../../../common/components/statusChipComponent';
import { FailedToast, SuccesToast } from '../../../utils/utilFunctions';
import ApproveModal from './ApproveModal';
import RejectModal from './RejectModal';
import { fetch_siteDDL } from '../../../common/general/action';
import PermissionWrapper from '../../../common/components/PermissionWrapper';
// import Request from '../../../config/api';
// import { SITE_DDL_URL } from '../../../config/endpoint';
// import { UPDATE_SITE_DDL } from '../../../common/general/generalSlice';
import { subModuleList } from './../../../config/subModuleList';
import { CircularProgress } from '@mui/material';

const ListTable = () => {
	useEffect(() => {
		const controller = new AbortController();

		fetch_siteDDL(dispatch, controller.signal);
	}, []);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const PARKINGDATA = useAppSelector((state) => state?.parkingrequest);
	const GENERAL_DDL = useAppSelector((state) => state?.general);

	const fetchIdRef = useRef(0);
	const [viewModal, setViewModal] = useState(false);
	const [userDetail, setuserDetail] = useState<any>();
	const [approveModal, setApproveModal] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [idVal, setIdVal] = useState<any>('');
	const [zoneidVal, setZoneIdVal] = useState<any>('');
	const [viewLoader, setViewLoader] = useState<any>('');
	const ApproveModalOpen = (id: number) => {
		setIdVal(id);
		const sucess = (res: any) => {
			setuserDetail(res?.response?.data);
			console.log(res?.response?.data);

			setApproveModal(true);
		};
		const failed = (err: any) => {
			FailedToast();
		};

		fetch_parkingRequest_detail(id, sucess, failed);
	};
	const ApproveModalClose = () => setApproveModal(false);

	// const quickApprove = (id:number) =>{
	// 	let arrVal = []
	//     arrVal.push(
	// 				{
	// 					Status 			 : 1,
	// 					RequestID        : id
	// 				}
	// 			)
	// 	let postData = {
	//         UpdateParkingRequest : [...arrVal]
	//     }
	// 	const Success = (res: any) => {

	//         SuccesToast(res?.response?.data);
	//         fetch_parkingRequestslist(navigate, dispatch, {
	// 			'filters': {
	// 				'query': PARKINGDATA?.PARKING_REQUEST_FILTER?.query,
	// 			},
	// 			'pagination': {
	// 				'pageNo': PARKINGDATA?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1,
	// 				'limit': PARKINGDATA?.PARKING_REQUEST_PAGINATION?.limit ?? 1,
	// 			},
	// 		});
	//     };
	//     const Failed = (err: any) => {
	//         FailedToast(err?.response)		;
	//     };

	//     parkingRequestStatus_action(postData, Success, Failed);

	// }

	const RejectModalOpen = (id: number) => {
		setIdVal(id);
		setRejectModal(true);
	};
	const RejectModalClose = () => setRejectModal(false);

	const viewModalOpen = (id: number) => {
		const sucess = (res: any) => {
			setuserDetail(res?.response?.data);
			setViewLoader(null);

			console.log(res?.response?.data);
			setViewModal(true);
		};
		const failed = (err: any) => {
			setViewLoader(null);
			FailedToast();
		};

		fetch_parkingRequest_detail(id, sucess, failed);
	};

	const viewModalClose = () => setViewModal(false);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string };
	};
	// const customDisableCheckBox = (obj:any) =>{
	// 	if(obj)
	// 		return obj?.requestStatus !== 0
	// 	else{
	// 		if(PARKINGDATA?.PARKING_REQUEST_LIST_DATA && PARKINGDATA?.PARKING_REQUEST_LIST_DATA?.length>0){
	// 			let arr:any = PARKINGDATA?.PARKING_REQUEST_LIST_DATA[0]
	// 			if ((PARKINGDATA?.PARKING_REQUEST_LIST_DATA?.filter((el:any) => el.fK_RequestType !== arr?.fK_RequestType).length > 0) ||
	// 			(PARKINGDATA?.PARKING_REQUEST_LIST_DATA?.filter((el:any) => el.fK_SiteID !== arr?.fK_SiteID).length > 0))
	// 				return false
	// 			else return true
	// 		}
	// 		else{
	// 			return false
	// 		}
	// 	}

	// }

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parkingRequestslist(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query,
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	const statusArr: any = [	
		{ value: 'warning', text: 'Pending' },
		{ value: 'success', text: 'Approved' },
		{ value: 'error', text: 'Rejected' },
		{ value: 'pending', text: 'Cancelled' },
		{ value: 'error', text: 'Revoked' },
		{ value: 'error', text: 'Deleted' },
		{ value: 'error', text: 'Blacklisted' },
		{ value: 'error', text: 'Auto Rejected' },
	];
	const reqType: any = [
		'Employee Parking Request',
		'Visitor Parking Request',
		'Employee Vehicle Update Request',
		'Visitor Vehicle Update Request',
		'Employee Overtime Parking Request',
		'Visitor Overtime Parking Request',
		'Employee Parking Request Cancellation',
		'Visitor Parking Request Cancellation',
	];

	const columns = React.useMemo(
		() => [
			{ Header: 'Id', accessor: 'pRequestID',
			 orderByKey: 'pRequestID'
			 },

			{ Header: 'Requested By', accessor: 'requestedBUName', 
			orderByKey: 'requestedBUName' 
		},
			{ Header: 'Requested For', accessor: 'requestedFUName',
			 orderByKey: 'requestedFUName'
			 },
			 
			 { Header: 'Plate Number', accessor: 'rF_PlateNumber' ,orderByKey:"rF_PlateNumber"},
			 { Header: 'Vehicle Category', accessor: 'rF_VehicleCategory' ,orderByKey:"rF_VehicleCategory"},
			{ Header: 'Place of Registration', accessor: 'rF_PlaceOfRegistration' ,orderByKey:"rF_PlaceOfRegistration"},
			// { Header: 'Group Company', accessor: 'requestedFUCompany' ,orderByKey:"requestedFUCompany"},
			{ Header: 'Site', accessor: 'siteName' ,orderByKey :'siteName' },
			{
				Header: 'Type of Request',
				accessor: 'fK_RequestType',
				orderByKey :'fK_RequestType' ,
				Cell: ({ row }: any) => {
					return <p>{row?.original?.requestTypeName}</p>;
				},
			},
			{
				Header: 'Requested On',
				accessor: 'requestedOn',
				orderByKey:"requestedOn",
				DateFormat: true,
				FormatType: 'DD-MMM-YYYY hh:mm A',
			},
			{
				Header: 'Personal Grade',
				accessor: 'rF_PriorityLevelID',
				orderByKey:"rF_PriorityLevelID"
			},
			{
				Header: 'Status',
				accessor: 'requestStatus',
				orderByKey:"requestStatus",
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<StatusChipComponent
								chipType={statusArr[row?.values?.requestStatus]?.value}
								text={statusArr[row?.values?.requestStatus]?.text}
							/>
						</Fragment>
					);
				},
			},
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					//console.log(row)
					return (
						<Fragment>
							<div className='button-group'>
								{row?.values?.requestStatus === 0 ? (
									<PermissionWrapper subModuleID={subModuleList['Parking Requests']} type='isEdit'>
										<>
											<a
												className='btn btn-sqaure btn-green modal-button'
												onClick={() => {
													setZoneIdVal(row?.original);
													ApproveModalOpen(row?.values?.pRequestID);
												}}>
												<i className='fa-solid fa-check' />
											</a>
											{/* <a
												className='btn btn-sqaure btn-red modal-button'
												onClick={() => RejectModalOpen(row?.values?.pRequestID)}>
												<i className='fa-solid fa-xmark' />
											</a> */}
										</>
									</PermissionWrapper>
								) : null}

								<a
									onClick={() => {
										setViewLoader(row?.original?.pRequestID);
										viewModalOpen(row?.original?.pRequestID);
									}}
									className='btn btn-sqaure btn-white modal-button'>
									{viewLoader === row?.original?.pRequestID ? (
										<CircularProgress size={18} />
									) : (
										<i className='fa-solid fa-eye'></i>
									)}
								</a>
							</div>
						</Fragment>
					);
				},
			},
		],
		[viewLoader]
	);
	return (
		<>
			<CommonTable
				columns={columns}
				data={PARKINGDATA?.PARKING_REQUEST_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={PARKINGDATA?.PARKING_REQUEST_PAGINATION?.totalCount ?? 0}
				loading={PARKINGDATA?.PARKING_REQUEST_LOADER}
				page_no={PARKINGDATA?.PARKING_REQUEST_PAGINATION?.pageNo ?? 1}
				limit={PARKINGDATA?.PARKING_REQUEST_PAGINATION?.limit ?? 1}
				error={PARKINGDATA?.PARKING_REQUEST_ERROR ?? ''}
				// selectRowsFx={setSelectedRows}
				// rowsSelected={selectedRows}
				filters={PARKINGDATA?.PARKING_REQUEST_FILTER}
			/>
			{approveModal && <ApproveModal Data={userDetail} open={approveModal} handleClose={ApproveModalClose} />}
			{rejectModal && <RejectModal Data={{ id: [idVal] }} open={rejectModal} handleClose={RejectModalClose} />}
			{/* {viewModal && <ViewModal open={viewModal} handleClose={viewModalClose} Data={userDetail} />} */}
			{viewModal && <ViewModal open={viewModal} handleClose={viewModalClose} Data={userDetail} reqType={userDetail?.fK_RequestType} />}
		</>
	);
};

export default ListTable;
