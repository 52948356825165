import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

type Iprop = {
	label: string;
	value: any;
	vType?: string;
	stRefrenceID?: any;
};

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

type notification = {
	wNotificationID: number;
	title: string;
	nMessages: string;
	notifiedUserUniqueID: number;
	fK_CompanyID: number;
	rownum: number;
	createdOn: string;
	createdBy: string;
	isRead: number;
	updatedOn: string;
	updatedBy: string;
	createdUser: string;
	updatedUser: string;
	companyName: string;
};

const initialState = {
	DESIGNATION_DDL: [] as Iprop[],
	DEPARTMENT_DDL: [] as Iprop[],
	PRIORITY_LVL_DDL: [] as Iprop[],
	SITE_DDL: [] as Iprop[],
	FLOORLEVEL_DDL: [] as Iprop[],
	ZONE_DDL: [] as Iprop[],
	BAY_DDL: [] as Iprop[],
	BAYTYPE_DDL: [] as Iprop[],
	ASSIGNED_ZONE_DDL: [] as Iprop[],
	VEHICLEPLATE_DDL: [] as Iprop[],
	INVALIDREASON_DDL: [] as Iprop[],
	POLICYNAME_DDL: [] as Iprop[],
	VIOLATIONTYPE_DDL: [] as Iprop[],
	REJECTIONREASON_DDL: [] as Iprop[],
	USERTYPE_DDL: [] as Iprop[],
	GROUPCOMPANY_DDL: [] as Iprop[],
	PLACEOFREG_DDL: [] as any[],

	REQUESTTYPE_DDL: [
		{
			label: 'Employee Parking Request',
			value: 1,
		},
		{
			label: 'Visitor Parking Request',
			value: 2,
		},
		{
			label: 'Employee Overtime Request',
			value: 5,
		},
		{
			label: 'Visitor Overtime Request',
			value: 6,
		},
		{
			label: 'Employee Change Vehicle Request',
			value: 3,
		},
		{
			label: 'Visitor Change Vehicle Request',
			value: 4,
		},
		{
			label: 'Employee Parking Request Cancellation',
			value: 7,
		},
		{
			label: 'Visitor Parking Request Cancellation',
			value: 8,
		},
	] as Iprop[],

	NOTIFICATIONS_LIST: [] as notification[],
	NOTIFICATIONS_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	NOTIFICATIONS_LOADER: false,
	NOTIFICATIONS_FILTER: { query: '' } as FilterObject,
	NOTIFICATIONS_ERROR: '',
	NOTIFICATIONS_LAST: null,
	NOTIFICATIONS_UNREAD:0
};

export const GENERALSLICE = createSlice({
	name: 'GENERAL',
	initialState: initialState,
	reducers: {
		UPDATE_DESIGNATION_DDL: (state, action) => {
			const { data } = action.payload;
			state.DESIGNATION_DDL = data;
		},
		UPDATE_DEPARTMENT_DDL: (state, action) => {
			const { data } = action.payload;
			state.DEPARTMENT_DDL = data;
		},
		UPDATE_PRIORITY_LVL_DDL: (state, action) => {
			const { data } = action.payload;
			state.PRIORITY_LVL_DDL = data;
		},
		UPDATE_SITE_DDL: (state, action) => {
			const { data } = action.payload;
			state.SITE_DDL = data;
		},
		UPDATE_ASSINGED_ZONE_DDL: (state, action) => {
			const { data } = action.payload;
			state.ASSIGNED_ZONE_DDL = data;
		},
		UPDATE_ZONE_DDL: (state, action) => {
			const { data } = action.payload;
			state.ZONE_DDL = data;
		},
		UPDATE_VEHICLEPLATE_DDL: (state, action) => {
			const { data } = action.payload;
			state.VEHICLEPLATE_DDL = data;
		},
		UPDATE_BAY_DDL: (state, action) => {
			const { data } = action.payload;
			state.BAY_DDL = data;
		},
		UPDATE_INVALIDREASON_DDL: (state, action) => {
			const { data } = action.payload;
			state.INVALIDREASON_DDL = data;
		},
		UPDATE_POLICYNAME_DDL: (state, action) => {
			const { data } = action.payload;
			state.POLICYNAME_DDL = data;
		},
		UPDATE_VIOLATIONTYPE_DDL: (state, action) => {
			const { data } = action.payload;
			state.VIOLATIONTYPE_DDL = data;
		},
		UPDATE_FLOORLEVEL_DDL: (state, action) => {
			const { data } = action.payload;
			state.FLOORLEVEL_DDL = data;
		},
		UPDATE_BAYTYPE_DDL: (state, action) => {
			const { data } = action.payload;
			state.BAYTYPE_DDL = data;
		},
		UPDATE_REJECTIONREASON_DDL: (state, action) => {
			const { data } = action.payload;
			state.REJECTIONREASON_DDL = data;
		},
		UPDATE_USERTYPE_DDL: (state, action) => {
			const { data } = action.payload;
			state.USERTYPE_DDL = data;
		},
		UPDATE_GROUPCOMPANY_DDL: (state, action) => {
			const { data } = action.payload;
			state.GROUPCOMPANY_DDL = data;
		},
		UPDATE_PLACEOFREG_DDL: (state, action) => {
			const { data } = action.payload;
			state.PLACEOFREG_DDL = data;
		},
		UPDATE_NOTIFICATIONS: (state, action) => {
			const { data, pagination, filters, totalRecordsUnRead } = action.payload;
			console.log('red=>',action.payload)
			state.NOTIFICATIONS_LIST = data;
			state.NOTIFICATIONS_PAGINATION = pagination;
			state.NOTIFICATIONS_FILTER = filters;
			state.NOTIFICATIONS_LOADER = false;
			state.NOTIFICATIONS_ERROR = '';
			state.NOTIFICATIONS_UNREAD = totalRecordsUnRead
		},

		UPDATE_LAST_NOTIFICATIONS: (state, action) => {
			const { data, totalRecordsUnRead } = action.payload;

			state.NOTIFICATIONS_LAST = data;
			state.NOTIFICATIONS_UNREAD = totalRecordsUnRead
		},

		UPDATE_NOTIFICATIONS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.NOTIFICATIONS_LOADER = loader;
		},

		UPDATE_NOTIFICATIONS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.NOTIFICATIONS_ERROR = error;
		},

		UPDATE_REQUEST_TYPE_DDL: (state, action) => {
			const { data } = action.payload;
			state.REQUESTTYPE_DDL = data;
		},
	},
});

export const {
	UPDATE_DESIGNATION_DDL,
	UPDATE_DEPARTMENT_DDL,
	UPDATE_PRIORITY_LVL_DDL,
	UPDATE_SITE_DDL,
	UPDATE_VIOLATIONTYPE_DDL,
	UPDATE_BAYTYPE_DDL,
	UPDATE_FLOORLEVEL_DDL,
	UPDATE_REJECTIONREASON_DDL,
	UPDATE_USERTYPE_DDL,
	UPDATE_REQUEST_TYPE_DDL,
	UPDATE_ASSINGED_ZONE_DDL,
	UPDATE_GROUPCOMPANY_DDL,
	UPDATE_ZONE_DDL,
	UPDATE_VEHICLEPLATE_DDL,
	UPDATE_BAY_DDL,
	UPDATE_INVALIDREASON_DDL,
	UPDATE_POLICYNAME_DDL,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_LOADER,
	UPDATE_NOTIFICATIONS_ERROR,
	UPDATE_LAST_NOTIFICATIONS,
	UPDATE_PLACEOFREG_DDL,
} = GENERALSLICE.actions;
export const GENERALData = (state: RootState) => state;
export default GENERALSLICE.reducer;
