//  icons

import dashboard from '../assets/images/icons/dashboard.svg';
import findvehicles from '../assets/images/icons/find-vehicles.svg';
import realtimemonitoring from '../assets/images/icons/real-time-monitoring.svg';
import parkingrequests from '../assets/images/icons/parking-requests.svg';
import violations from '../assets/images/icons/violations.svg';
import users from '../assets/images/icons/users.svg';
import visitors from '../assets/images/icons/visitors2.svg';
import policies from '../assets/images/icons/policies.svg';
import reportsanddashboards from '../assets/images/icons/reports-and-dashboards.svg';
import parkingzones from '../assets/images/icons/parking-zones.svg';
import notifications from '../assets/images/icons/notifications.svg';
import logs from '../assets/images/icons/logs.svg';
import definitions from '../assets/images/icons/definitions.svg';
import applicationsettings from '../assets/images/icons/application-settings.svg';
import security from '../assets/images/icons/security.svg';

//  modules

import UserIndex from '../modules/user/userIndex';
import SecurityIndex from '../modules/security/securityIndex';
import DashboardIndex from '../modules/dashboard/dashboardIndex';
import DefinitionIndex from '../modules/definition/definitionIndex';
import LogsIndex from '../modules/logs/logsindex';
import FindvehiclesIndex from '../modules/findvehicles/findvehiclesIndex';
import RealtimeMonitorIndex from '../modules/realtime_monitior/realtimeMonitorIndex';
import ParkingRequestIndex from './../modules/parking_request/parkingRequestIndex';
import ViolationIndex from '../modules/violations/violationIndex';
import PolicyIndex from '../modules/policies/policyIndex';
import ReportsIndex from '../modules/reports/reportsIndex';
import ParkingZonesIndex from '../modules/parking_zones/parkingZonesIndex';
import NotificationIndex from '../modules/notifications/notificationIndex';
import ApplicationSettingsIndex from '../modules/application_settings/applicationSettingsIndex';
import VisitorIndex from '../modules/visitor/visitorIndex';

export interface Iroutes {
	path: string;
	name: string;
	exact: boolean;
	icon: string;
	title: string;

	Component: JSX.Element | null | any;
	module_id: number;
}

export const routes: Iroutes[] = [
	{
		path: '/dashboard',
		name: 'dashboard',
		exact: false,
		icon: dashboard,
		title: 'Dashboards',
		Component: DashboardIndex,
		module_id: 1,
	},
	{
		path: '/find-vehicles/*',
		name: 'findvehicles',
		exact: false,
		icon: findvehicles,
		title: 'Find Vehicle',
		Component: FindvehiclesIndex,
		module_id: 2,
	},
	{
		path: '/real-time-monitoring/*',
		name: 'realtimemonitoring',
		exact: false,
		icon: realtimemonitoring,
		title: 'Real Time Monitoring',
		Component: RealtimeMonitorIndex,
		module_id: 3,
	},
	{
		path: '/parking-requests/*',
		name: 'parkingrequests',
		exact: false,
		icon: parkingrequests,
		title: 'Parking Requests',
		Component: ParkingRequestIndex,
		module_id: 4,
	},
	{
		path: '/violations/*',
		name: 'violations',
		exact: false,
		icon: violations,
		title: 'Violations',
		Component: ViolationIndex,
		module_id: 5,
	},
	{
		path: '/users/*',
		name: 'users',
		exact: false,
		icon: users,
		title: 'Users',
		Component: UserIndex,
		module_id: 6,

	},
    {
		path: '/visitor/*',
		name: 'visitors',
		exact: false,
		icon: visitors,
		title: 'Visitors',
		Component: VisitorIndex,
		module_id: 15,
	},
	{
		path: '/policies/*',
		name: 'policies',
		exact: false,
		icon: policies,
		title: 'Policies',
		Component: PolicyIndex,
		module_id: 7,
	},
	{
		path: '/reports-and-dashboards/*',
		name: 'reportsanddashboards',
		exact: false,
		icon: reportsanddashboards,
		title: 'Reports & Dashboards',
		Component: ReportsIndex,
		module_id: 8,
	},
	{
		path: '/parking-zones/*',
		name: 'parkingzones',
		exact: false,
		icon: parkingzones,
		title: 'Parking Zones',
		Component: ParkingZonesIndex,
		module_id: 9,
	},
	{
		path: '/notifications/*',
		name: 'notifications',
		exact: false,
		icon: notifications,
		title: 'Notifications',
		Component: NotificationIndex,
		module_id: 10,
	},
	{
		path: '/logs/*',
		name: 'log',
		exact: false,
		icon: logs,
		title: 'Logs',
		Component: LogsIndex,
		module_id: 11,

	},
	{
		path: '/definitions/*',
		name: 'definitions',
		exact: false,
		icon: definitions,
		title: 'Definitions',
		Component: DefinitionIndex,
		module_id: 12,
	},
	{
		path: '/application-settings/*',
		name: 'applicationsettings',
		exact: false,
		icon: applicationsettings,
		title: 'Application Settings',
		Component: ApplicationSettingsIndex,
		module_id: 13,
	},
	{
		path: '/security/*',
		name: 'security',
		exact: false,
		icon: security,
		title: 'Security',
		Component: SecurityIndex,
		module_id: 14,
	},

];
