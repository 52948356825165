import React, { Fragment, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import PopUp from './../../../../common/components/popUp';
import AvlChart from './AvlChart';
import { get_available_zonesListGraph2 } from '../../store/action';
import { FormatDate } from '../../../../utils/utilFunctions';
import { fetch_BayDDL } from '../../../../common/general/action';

type Props = {
	selectedZone?: { label: string; value: any };
	date?:any;
	time?:any;
};

const newLocal = (
	<p className='label-style text-right text-hover' style={{ textDecoration: 'underline' }} onClick={() => {}}>
		Check Zone Occupancy
	</p>
);
const CheckAvilability = (props: Props) => {
	const [date, setdate] = useState<any>(props?.date??[moment().subtract(30, 'days').startOf('day'), moment().endOf('day')]);
	const [data, setdata] = useState([]);
	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	useEffect(() => {
		let postData = { query: `FK_ZoneID=${props?.selectedZone?.value}` };
		fetch_BayDDL(dispatch, postData);
			
		let currFromTime = '00:00:00'
		let currToTime = '23:59:59'
		console.log('PROPS=>',props)
		if(props?.time && props?.time?.length>0){
			currFromTime = moment(props?.time[0],['HH:mm','HH:mm:ss','hh:mm a']).isValid() ? moment(props?.time[0],'HH:mm:ss').format('HH:mm:ss') : '00:00:00'
			currToTime = moment(props?.time[1],['HH:mm','HH:mm:ss','hh:mm a']).isValid() ? moment(props?.time[1],'HH:mm:ss').format('HH:mm:ss') : '23:59:59'
		}
		
		let postData2 = {
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			FromTime: currFromTime,
			ToTime: currToTime,
			ZoneID: props?.selectedZone?.value,
		};
		
		get_available_zonesListGraph2(postData2)?.then((res) => {
			let data = res?.response?.data ?? [];
			let formattedData = data.map((el: any) => {
				let k1 = `${el.tf1}-${el.tt1}`;
				let k2 = `${el.tf2}-${el.tt2}`;
				let k3 = `${el.tf3}-${el.tt3}`;
				let k4 = `${el.tf4}-${el.tt4}`;
				let obj = Object.assign(
					{},
					{
						'date': `${FormatDate(el.dates)}`,
						[k1]: el.t1Count,
						[k2]: el.t2Count,
						[k3]: el.t3Count,
						[k4]: el.t4Count,
					}
				);

				return obj;
			});

			setdata(formattedData);
			console.log('Response ZONE LIST GRAPh', res);
		});
	}, [date, props?.selectedZone?.value]);

	const dispatch = useAppDispatch();
	const childRef: any = useRef(null);
	const GENERAL_DDL = useAppSelector((state) => state?.general);

	return (
		<PopUp icon={newLocal} ref={childRef}>
			<div className='dflex col-dir'>
				<div className='row'>
					<div
						className='dflex justify-space-between w-100 align-items-center pb-10 col-12'
						style={{ borderBottom: '1px solid var(--lightgrey)' }}>
						{/* <h2 className='border-title ml-5'>Zone Traffic </h2> */}
						<div className='p-10 ml-5 col-4' style={{ fontSize: '.85rem', fontWeight: 500 }}>
							<h2 className='border-title ml-5'>{`Zone Occupancy Check `}
							{
								GENERAL_DDL?.BAY_DDL?.length?
								<>
									<br/>
									<span style={{ fontSize: '.85rem', fontWeight: 500 }}>({` ${GENERAL_DDL?.BAY_DDL?.length} Bays `})</span>
								</>								 
								: null
							}
							</h2>
						</div>
						{
							date && date?.length>0 ?
							<div className='p-10 ml-5 col-4'>
								<h6 className='border-title ml-5'  style={{ fontSize: '.85rem', fontWeight: 500 }}>
								{
									props?.date ? 
									`${moment(props?.date[0]).format('DD-MMM-YYYY')} - ${moment(props?.date[1]).format('DD-MMM-YYYY')}` : null
								}
								{
									props?.time && props?.time?.length > 0 ? 
									<>
										<br/>
										{
											`${moment(props?.time[0],['hh:mm a','hh:mm:ss','HH:mm:ss','HH:mm'])?.isValid() ? 
											moment(props?.time[0],['hh:mm a','hh:mm:ss','HH:mm:ss','HH:mm']).format('hh:mm a') :
											moment(props?.time[0]).format('hh:mm a')}`
										}
										{
											` - ${moment(props?.time[1],['hh:mm a','hh:mm:ss','HH:mm:ss','HH:mm'])?.isValid() ? 
											moment(props?.time[1],['hh:mm a','hh:mm:ss','HH:mm:ss','HH:mm']).format('hh:mm a') :
											moment(props?.time[1]).format('hh:mm a')}}`
										}
										{/* {`${moment(props?.time[0],['hh:mm a','hh:mm:ss','HH:mm:ss','HH:mm']).format('hh:mm a')} - ${moment(props?.time[1],['hh:mm a','hh:mm:ss','HH:mm:ss','HH:mm']).format('hh:mm a')}`}	 */}
									</>
									: 
									null
								}
								</h6>
							</div>
							: null
						}
						
						{/* <div className='p-10 col-3' style={{ fontSize: '.85rem', fontWeight: 500 }}>

                            <Select />
						</div> */}
					</div>
				</div>
				<div className='row'>
					<div className='col-6'>
						<AvlChart data={data} />
					</div>
				</div>
				<div className='row' style={{ borderTop: '1px solid var(--lightgrey)' }}>
					<div className='dflex flex-justify-end  gap-10 p-10'>
						<button className='filter-btn btn-secondary' onClick={handleClose}>
							close
						</button>
					</div>
				</div>
			</div>
		</PopUp>
	);
};

export default CheckAvilability;
