import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import Visitors from './screens/visitor';
import { RESET_STATE } from './store/visitorSlice';

const VisitorIndex = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		  return () => {
			  dispatch(RESET_STATE())
		  }
	  }, [dispatch])
	
	const redirectedPath = useRedirect([
		{
			path: '',
			submoduleID: subModuleList.Visitors,
		},
	]);
	return (
		<div className='navtabs__container'>
			<Routes>
				<Route
					path={''}
					element={
						<PermissionWrapper subModuleID={subModuleList.Visitors}>
							<Visitors />
						</PermissionWrapper>
					}
				/>
				<Route path={'/*'} element={<Navigate to={redirectedPath} />} />
			</Routes>
		</div>
	);
};

export default VisitorIndex;
