import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup'
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import ErrorComponent from '../../../../../common/components/ErrorComponent';
import FormSelect from '../../../../../common/components/FormSelect';
import FormSelectMultiple from '../../../../../common/components/FormSelectMultiple';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom';
import { fetch_policyNameDDL, fetch_zoneDDL } from '../../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { fetch_parking_policy, fetch_zones_assigned_toDates, policy_assignment_action } from '../../../store/actions';
import { DatePicker } from 'antd';
import ActionConfirmBox from '../../../../../common/components/ActionConfirmBox';
const { RangePicker } = DatePicker;
 

type Props = {}
const yesterday = new Date(Date.now() -86400000);
const schema = yup.object().shape({
	ParameterFKID		:	yup.number().required(),
	ParameterStartDate	:	yup.date().min(yesterday, "Date cannot be in the past").required(),
	ParameterEndDate	:	yup.date().min(yesterday, "Date cannot be in the past")
	.when("ParameterStartDate",
		(ParameterStartDate, yup) => ParameterStartDate && yup.min(ParameterStartDate, "End date cannot be before start date")).required(),
	ParameterFK_ZoneIDs	:	yup.mixed().required(),
});


const AssignPolicy = (props: Props) => {
	const navigate                      = useNavigate();

	const dispatch	= useAppDispatch();
	const GENERALDATA = useAppSelector((state) => state?.general)

	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmData, setConfirmData] = useState<any>()

	const [zonesAvailList, setZonesAvailList] = useState<any>([])
	const [zonesList, setZonesList] = useState<any>([])
	const [date, setdate] = useState<any>([])
	const [loading, setLoading] = useState(false)
	const cancelConfirm = () =>{ setConfirmModal(false); setLoading(false); }

	const {
		register,
		handleSubmit, control,
		getValues,
		formState,
		reset,
		clearErrors,
		watch,
		setValue
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		const controller = new AbortController();
		fetch_zoneDDL(dispatch, undefined)
		fetch_policyNameDDL(dispatch, controller.signal)
		clearErrors()
		reset()
	}, [])

	const confirmApprove = () =>{
		setConfirmModal(false)
		let postData = {
			ParameterFKID		: confirmData?.ParameterFKID,
			ParameterStartDate	: dayjs(confirmData?.ParameterStartDate).format('YYYY-MM-DD'),
			ParameterEndDate	: dayjs(confirmData?.ParameterEndDate).format('YYYY-MM-DD'),
			ParameterFK_ZoneIDs	: confirmData?.ParameterFK_ZoneIDs.toString()
		}
		const Success = (res: any) => {
			reset()
			setZonesAvailList([])
			setZonesList([])
			setLoading(false)
			setdate([])
			fetch_zoneDDL(dispatch, undefined)
			fetch_parking_policy(navigate, dispatch, {
				'filters': {
					'query': '',
				},
				'pagination': {
					'pageNo': 1,
					'limit': 10,
				},
			});
			setConfirmModal(false)
			setConfirmData(null)
			SuccesToast(res?.data)
		}
		const Failed = (err: any) => {
			setLoading(false)
			FailedToast(err?.response)		
			setConfirmModal(false)
			setConfirmData(null)
		}
		policy_assignment_action('post',postData,Success,Failed)
	}

	const onSubmitHandler = (e: any) => {
		console.log('DAta',e)
		setLoading(true)
		setConfirmData(e)
		setConfirmModal(true)
		
	};

	const getZonesListOfDates = (startVal:any, endVal:any, zoneId?:any) =>{
		console.log('STR',watch('ParameterStartDate'))
		console.log('END',watch('ParameterEndDate'))
		//console.log('val',startVal,endVal,zoneId)
		let str = startVal ? startVal : watch('ParameterStartDate');
		let end = endVal ? endVal : watch('ParameterEndDate');
		if(str && end){
			const success = (res:any) =>{
				console.log('ZONERES',res)
				setZonesAvailList(res.response.data)
			}
				let postData = { "StartDate" : dayjs(str).format('YYYY-MM-DD'), "EndaDate" : dayjs(end).format('YYYY-MM-DD') }
				fetch_zones_assigned_toDates(postData, success)
			
		}
		
	}

	const getDisabledDates = (curr:any) =>{
		return  curr < dayjs().subtract(1,'day').endOf('day')
	}
	//console.log(formState.errors)
	//console.log(getValues,"fhfs")

  return (
    <>
			<div className="heading">
				<h2 className="heading-main mb-0">Assign Policy</h2>
			</div>
			<div className="policy-form">
				<form onSubmit={handleSubmit(onSubmitHandler)}>
					
					<div className="row">
						<div className="col-6">
							<div className="form-group select-input full-label">
								<label>Policy Name</label>
								<FormSelect
									control={control}
									name='ParameterFKID'
									options={GENERALDATA.POLICYNAME_DDL?.filter((el:any)=>!el?.isSystem)}
									// options={Policy}
									grey={true}
									isMulti={false}
									placeholder='Policy  Name'
									
								/>
								<ErrorComponent name='ParameterFKID' errors={formState.errors} title={'Policy Name'} />
							</div>
						</div>
						
						<div className="col-6">
							<div className="select-input">
								<label>Zone </label>
								<TreeSelectCustom isMulti={true} selectedZones={zonesList} setselectedZones={setZonesList}
								grey={true}
								onchangeFx={(e:any,type:any)=>{
									let arr = []
									if(type){
										arr = [...zonesList, { value : e.value}]
									}
									else{
										arr = zonesList?.filter((el:any)=>el?.value!==e.value)
									}
									
									setValue('ParameterFK_ZoneIDs', arr.map((el:any) => el.value).toString())
								}}

								/>
								{/* <FormSelectMultiple
									control={control}
									name='ParameterFK_ZoneIDs'
									options={GENERALDATA.ZONE_DDL}
									// options={Zone}
									grey={true}
									isMulti={true}
									placeholder='Zone Name'
									onchangeFx={(e)=>{
										console.log(e)
										setZonesList(e)
									}}
								/> */}
								<ErrorComponent name="ParameterFK_ZoneIDs" errors={formState.errors} title={'Zones'}/>
							</div>
						</div>
						
					</div>
					<div className='row'>
						<div className="col-12">
							<div className="form-group select-input full-label">
								<label>Policy Date Period</label>
								<RangePicker
									getPopupContainer={trigger => trigger.parentElement as HTMLDivElement}
									className='form-control'
									showTime={false}
									format={'DD-MMM-YYYY'}
									onChange={(date: any) =>{ setdate(date);
										if(date && date?.length>0){
											setValue('ParameterStartDate',date[0])
											setValue('ParameterEndDate',date[1])
											getZonesListOfDates(date[0],date[1],null)
										}
										
									}}
									value={date}
									hideDisabledOptions
									inputReadOnly
									disabledDate={(curr)=>getDisabledDates(curr)}									
									allowClear={true}
								/>								
								<ErrorComponent name={'ParameterStartDate'} errors={formState.errors} title={'Policy Start Date'}/>
								<ErrorComponent name={'ParameterEndDate'} errors={formState.errors} title={'Policy End Date'}/>
							</div>
						</div>
						{/* <div className="col-6">
							<div className="form-group select-input full-label">
								<label>Policy End Date</label>
								<Controller
									control={control}
									name="ParameterEndDate"
									render={({
										field : { onChange, onBlur, value },
										fieldState: { error },
										formState,
									}) => (
										<DatePicker
											picker='date'
											placeholder='Policy End Date'
											className='form-control'
											onChange={(e)=>{
												onChange(moment(e,'YYYY-MM-DD').isValid() ? moment(e).format('YYYY-MM-DD') : null)
												getZonesListOfDates(null,e,null)
											}}
											value={value ? moment(value,'YYYY-MM-DD') : null}
											disabledDate={(curr) => getDisabledDates(curr)}
											onBlur={onBlur}
										/>
										// <input className="form-control"
										// onChange={(e)=>{
										// 	onChange(e)
										// 	getZonesListOfDates(null,e.target.value,null)
										// }}
										// type="date" onBlur={onBlur} value={value}  />
									)}
								/>
								<ErrorComponent name={'ParameterEndDate'} errors={formState.errors} title={'Policy End Date'}/>
							</div>
						</div> */}
						
					</div>
					{
						zonesAvailList && zonesAvailList?.map((el:any)=>{
							//console.log('ZONESAVL',zonesAvailList,'zones',zonesList,'COMP',zonesList?.find((x:any) => x.value === parseInt(el.zoneID)))
							if(zonesList && zonesList?.find((x:any) => x.value === parseInt(el.zoneID)))
							return (
								<p className='hookform-error-message'>
									Zone &nbsp;<StatusChipComponent text={el?.zoneName} /> &nbsp;is assigned on &nbsp;{
										el?.policyID===watch('ParameterFKID') ?
										<StatusChipComponent chipType='error' text={el?.policyName} /> : 'different' 
									}&nbsp; policy in same date intervals. { el?.policyID===watch('ParameterFKID') ? null : 'Previous policy will be expired when this policy starts.'}
								</p>
								
								
							)
							else return null
						})
					}
					<div className="row">
						<div className="col-12">
						<div className="modal-footer">
							<button type="submit" className="btn btn-secondary">
								<ButtonLoader loading={loading} text={'Assign'} />
							</button>
							<button type="reset"
								onClick={()=>{ reset(); clearErrors(); setZonesAvailList([]); setZonesList([]); setdate([]); }}
							className="btn btn-outline-blue close-modal-button">Clear</button>

						</div>	
					</div>
					</div>
				</form>
			</div>
			{confirmModal &&
			<ActionConfirmBox
				description={`If there are any policies assigned to these zones previously, it will be expired on activation of this policy ?`}
				handleClose={cancelConfirm}
				open={true}
				ConfirmFx={confirmApprove}
			/>
		}
		</>
  )
}

export default AssignPolicy