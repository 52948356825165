export const languages: any = {
	'english': {
		// heading and sidebar
		'dashboard': 'Dashboards',
		'findvehicles': 'Find Vehicle',
		'realtimemonitoring': 'Real Time Monitoring',
		'parkingrequests': 'Parking Requests',
		'violations': 'Violations',
		'users': 'Users',
		'visitors' : "Visitors",
		'policies': 'Policies',
		'reportsanddashboards': 'Reports & Dashboards',
		'parkingzones': 'Parking Zones',
		'notifications': 'Notifications',
		'log': 'Logs',
		'definitions': 'Definitions',
		'applicationsettings': 'Application Settings',
		'security': 'Security',

		//COMMON BUTTON AND FILTER PLACEHOLDERS
		'common_save_button_01': 'Save',
		'common_submit_button_02': 'Submit',
		'common_upload_button_03': 'Upload',
		'common_open_button_04': 'Open',
		'common_edit_button_05': 'Edit',
		'common_chooseFile_text_06': 'Choose a file Or Drag it here',
		'common_view_button_07': 'View',
		'common_cancel_button_08': 'Cancel',
		'common_back_button_09': 'Back',
		'common_close_button_10': 'Close',

		'dashboard_1': 'Dashboard',
		'dashboard_subtitle_2': 'Parking Management System',

		//SECURITY MODULE
		'security_3': 'Security',
		's_r_title_4': 'User and Role Management',
		's_r_subtitle_5': 'Parking Management System',
		's_r_6': 'Roles',
		's_r_add_7': 'Add New Role',
		's_r_add_subtitle_8': 'User Management',
		's_r_add_id_9': 'ID',
		's_r_add_name_10': 'Name',
		's_r_add_remarks_11': 'Remarks',
		's_r_add_module_12': 'MODULE NAME',
		's_r_add_view_13': 'VIEW',
		's_r_add_create_14': 'CREATE',
		's_r_add_edit_15': 'EDIT',
		's_r_add_delete_16': 'DELETE',
		's_r_add_fullcontrol_17': 'FULL CONTROL',
		's_r_add_users_18': 'Users',
		's_r_add_parking_requests_19': 'Parking Requests',
		's_r_add_policies_20': 'Policies',
		's_r_add_parking_policy_21': 'Parking Policy',
		's_r_add_violations_policy_22': 'Violations Policy',
		's_r_add_other_confirmation_23': 'OTHER CONFIRMATION',
		's_r_add_dashboard_24': 'Dashboard',
		's_r_add_role_button_25': 'Add Role',
		's_r_edit_role_button_26': 'Edit Role',
		's_r_cancel_button_27': 'Cancel',

		's_wu_title_28': 'Web User Access Request',
		's_wu_subtitle_29': 'Parking Management System',
		's_wu_30': 'Web Users',
		's_wu_requestid_31': 'REQUEST ID',
		's_wu_requestdate_32': 'REQUEST DATE',
		's_wu_requestby_33': 'REQUESTED BY',
		's_wu_requestedfor_34': 'REQUESTED FOR',
		's_wu_requestedzone_35': 'REQUESTED ZONE',
		's_wu_action_36': 'ACTION',
		's_wu_status_37': 'STATUS',
		's_wu_search_button_38': 'Search',
		's_wu_quicksearch_39': 'Quick Search',
		's_wu_status_40': 'Status',
		's_wu_requestedrole_41': 'Requested Role',
		's_wu_requestedby_42': 'Requested By',
		's_wu_requestid_43': 'Request ID',
		's_wu_v_title_44': 'Web User Request',
		's_wu_v_username_45': 'Username',
		's_wu_v_userid_46': 'User ID',
		's_wu_v_useremail_47': 'User Email',
		's_wu_v_usercontact_48': 'User Contact',
		's_wu_v_requestfor_49': 'REQUEST FOR',
		's_wu_v_role_50': 'ROLE',
		's_wu_v_request_username_51': 'Username',
		's_wu_v_request_userid_52': 'User ID',
		's_wu_v_request_useremail_53': 'User Email',
		's_wu_v_request_designation_54': 'Designation',
		's_wu_v_request_startdate_55': 'Start Date',
		's_wu_v_request_company_56': 'Company',
		's_wu_v_request_contactno_57': 'Contact No',
		's_wu_v_request_grade_58': 'Grade',
		's_wu_v_request_site_59': 'Site',
		's_wu_v_request_enddate_60': 'End Date',
		's_wu_v_zoneinfo_61': 'ZONE INFO',
		's_wu_v_zoneinfo_zone_62': 'ZONE',
		's_wu_v_userresponsibility_63': 'User Responsibility',
		's_wu_v_userresponsibility_sub_64': 'User Responsibility Description',
		's_wu_v_selectstatus_65': 'Select Status',
		's_wu_v_selectrole_66': 'Select Role',
		's_wu_v_remarks_67': 'Remarks',
		's_wu_v_submit_button_68': 'Submit',
		's_wu_v_cancel_button_69': 'Cancel',
		's_wu_add_title_70': 'Add New User',
		's_wu_add_subtitle_71': 'User Management',
		's_wu_add_appuserid_72': 'App User ID',
		's_wu_add_firstname_73': 'First Name',
		's_wu_add_lastname_74': 'Last Name',
		's_wu_add_userroles_75': 'User Roles',
		's_wu_add_accessprivilege_76': 'Access Privilege',
		's_wu_add_usertypes_77': 'User Types',
		's_wu_add_emailaddress_78': 'Email Address',
		's_wu_add_loginmethod_79': 'Login Method',
		's_wu_add_passwordreset_80': 'Password Reset',
		's_wu_add_usercredential_81': 'User Credential',
		's_wu_add_save_button_82': 'Save',
		's_wu_add_close_button_83': 'Close',

		'security_passwordpolicy_title_84': 'Password Policy',
		'security_passwordpolicy_subtitle_85':
			'A password policy is a set of rules design to increase the security of your account by encouraing users to create and use strong passwords.',
		'security_passwordpolicy_subtitle_86':
			'The existing password policy can be modified below. This password policy applies to all people who registered in this account. Minimum password length is 18',
		's_paspol_minimumpassword_87': 'Minimum password length',
		's_paspol_req_upper_88': 'Require at least one uppercase letter',
		's_paspol_req_lower_89': 'Require at least one lowercase letter',
		's_paspol_req_num_90': 'Require at least one number',
		's_paspol_req_symb_91': 'Require at least one Symbol Character',
		's_paspol_expire_92': 'Password expires in',
		's_paspol_expire_days_93': 'Days',
		's_paspol_enforce_94': 'Enforce password history',
		's_paspol_password_rem_95': 'passwords remembered',
		's_paspol_password_update_96': 'Update',

		//DEFINITIONS MODULE
		'definitions_97': 'Definitions',
		'definitions_title_98': 'Definitions',
		'definitions_subtitle_99': 'Parking Management System',
		'def_department_100': 'Department',
		'def_designation_101': 'Designation',
		'def_location_102': 'Location',
		'def_rejection_reason_103': 'Rejection Reason',

		'def_add_department_104': 'Add New Department',
		'def_add_depId_105': 'Department ID',
		'def_add_depName_106': 'Department Name',
		'def_add_depDesc_107': 'Department Description',
		'def_add_defaultCheck_108': 'Default',
		'def_add_submit_109': 'Submit',

		'def_table_id_110': 'ID',
		'def_table_name_111': 'NAME',
		'def_table_addedBy_112': 'ADDED By',
		'def_table_default_113': 'DEFAULT',
		'def_table_action_114': 'ACTION',

		//LOGS MODULE
		'logs_115': 'Logs',
		'logs_subtitle_116': 'Parking Management System',
		'logs_applicationLog_117': 'Application Logs',
		'logs_auditLog_118': 'Audit Logs',
		'logs_dateTime_119': 'Date Time',
		'logs_module_120': 'Module',
		'logs_userId_121': 'User ID',
		'logs_eventType_122': 'Event Type',
		'logs_eventCategory_123': 'Event Category',
		'logs_app_search_124': 'Search',
		'logs_action_125': 'Action',

		'logs_table_id_126': 'ID',
		'logs_table_datetime_127': 'DATETIME',
		'logs_table_username_129': 'USERNAME',
		'logs_table_interface_137': 'INTERFACE',

		'logs_app_userid_128': 'USER ID',
		'logs_app_eventid_130': 'EVENT ID',
		'logs_app_eventtype_131': 'EVENT TYPE',
		'logs_app_eventdesc_132': 'EVENT DESC',
		'logs_app_status_133': 'STATUS',
		'logs_app_errorcode_134': 'ERROR CODE',
		'logs_app_desc_135': 'ERROR DESC',
		'logs_app_src_136': 'SOURCE IP',

		'logs_audit_performed_138': 'PERFORMED BY',
		'logs_audit_module_139': 'MODULE',
		'logs_audit_identifier_140': 'IDENTIFIER',
		'logs_audit_ip_141': 'IP',

		'audit_log_viewer_title_142': 'Audit Log Viewer',
		'audit_log_viewer_descp_143': 'Logs',
		'a_log_v_module_144': 'Module',
		'a_log_v_userid_145': 'User ID',
		'a_log_v_identifier_146': 'Identifier',
		'a_log_v_datetime_147': 'Date Time',
		'a_log_v_action_148': 'Action',
		'a_log_v_username_149': 'User Name',
		'a_log_v_value_150': 'Value',

		'a_log_v_parameter_151': 'PARAMETER',
		'a_log_v_before_152': 'BEFORE',
		'a_log_v_after_153': 'AFTER',
		'a_log_v_close_button_154': 'CLOSE',
	},

	'arabic': {

		// sidebar and heading
		'dashboard': 'Dashboards',
		'findvehicles': 'Find Vehicle',
		'realtimemonitoring': 'Real Time Monitoring',
		'parkingrequests': 'Parking Requests',
		'violations': 'Violations',
		'users': 'Users',
		'visitors' : "Visitors",
		'policies': 'Policies',
		'reportsanddashboards': 'Reports & Dashboards',
		'parkingzones': 'Parking Zones',
		'notifications': 'Notifications',
		'log': 'Logs',
		'definitions': 'Definitions',
		'applicationsettings': 'Application Settings',
		'security': 'Security',
		
		//COMMON BUTTON AND FILTER PLACEHOLDERS
		'common_save_button_01': 'Save',
		'common_submit_button_02': 'Submit',
		'common_upload_button_03': 'Upload',
		'common_open_button_04': 'Open',
		'common_edit_button_05': 'Edit',
		'common_chooseFile_text_06': 'Choose a file Or Drag it here',
		'common_view_button_07': 'View',
		'common_cancel_button_08': 'Cancel',
		'common_back_button_09': 'Back',
		'common_close_button_10': 'Close',

		'dashboard_1': 'Dashboard',
		'dashboard_subtitle_2': 'Parking Management System',

		//SECURITY MODULE
		'security_3': 'Security',
		's_r_title_4': 'User and Role Management',
		's_r_subtitle_5': 'Parking Management System',
		's_r_6': 'Roles',
		's_r_add_7': 'Add New Role',
		's_r_add_subtitle_8': 'User Management',
		's_r_add_id_9': 'ID',
		's_r_add_name_10': 'Name',
		's_r_add_remarks_11': 'Remarks',
		's_r_add_module_12': 'MODULE NAME',
		's_r_add_view_13': 'VIEW',
		's_r_add_create_14': 'CREATE',
		's_r_add_edit_15': 'EDIT',
		's_r_add_delete_16': 'DELETE',
		's_r_add_fullcontrol_17': 'FULL CONTROL',
		's_r_add_users_18': 'Users',
		's_r_add_parking_requests_19': 'Parking Requests',
		's_r_add_policies_20': 'Policies',
		's_r_add_parking_policy_21': 'Parking Policy',
		's_r_add_violations_policy_22': 'Violations Policy',
		's_r_add_other_confirmation_23': 'OTHER CONFIRMATION',
		's_r_add_dashboard_24': 'Dashboard',
		's_r_add_role_button_25': 'Add Role',
		's_r_edit_role_button_26': 'Edit Role',
		's_r_cancel_button_27': 'Cancel',

		's_wu_title_28': 'Web User Access Request',
		's_wu_subtitle_29': 'Parking Management System',
		's_wu_30': 'Web Users',
		's_wu_requestid_31': 'REQUEST ID',
		's_wu_requestdate_32': 'REQUEST DATE',
		's_wu_requestby_33': 'REQUESTED BY',
		's_wu_requestedfor_34': 'REQUESTED FOR',
		's_wu_requestedzone_35': 'REQUESTED ZONE',
		's_wu_action_36': 'ACTION',
		's_wu_status_37': 'STATUS',
		's_wu_search_button_38': 'Search',
		's_wu_quicksearch_39': 'Quick Search',
		's_wu_status_40': 'Status',
		's_wu_requestedrole_41': 'Requested Role',
		's_wu_requestedby_42': 'Requested By',
		's_wu_requestid_43': 'Request ID',
		's_wu_v_title_44': 'Web User Request',
		's_wu_v_username_45': 'Username',
		's_wu_v_userid_46': 'User ID',
		's_wu_v_useremail_47': 'User Email',
		's_wu_v_usercontact_48': 'User Contact',
		's_wu_v_requestfor_49': 'REQUEST FOR',
		's_wu_v_role_50': 'ROLE',
		's_wu_v_request_username_51': 'Username',
		's_wu_v_request_userid_52': 'User ID',
		's_wu_v_request_useremail_53': 'User Email',
		's_wu_v_request_designation_54': 'Designation',
		's_wu_v_request_startdate_55': 'Start Date',
		's_wu_v_request_company_56': 'Company',
		's_wu_v_request_contactno_57': 'Contact No',
		's_wu_v_request_grade_58': 'Grade',
		's_wu_v_request_site_59': 'Site',
		's_wu_v_request_enddate_60': 'End Date',
		's_wu_v_zoneinfo_61': 'ZONE INFO',
		's_wu_v_zoneinfo_zone_62': 'ZONE',
		's_wu_v_userresponsibility_63': 'User Responsibility',
		's_wu_v_userresponsibility_sub_64': 'User Responsibility Description',
		's_wu_v_selectstatus_65': 'Select Status',
		's_wu_v_selectrole_66': 'Select Role',
		's_wu_v_remarks_67': 'Remarks',
		's_wu_v_submit_button_68': 'Submit',
		's_wu_v_cancel_button_69': 'Cancel',
		's_wu_add_title_70': 'Add New User',
		's_wu_add_subtitle_71': 'User Management',
		's_wu_add_appuserid_72': 'App User ID',
		's_wu_add_firstname_73': 'First Name',
		's_wu_add_lastname_74': 'Last Name',
		's_wu_add_userroles_75': 'User Roles',
		's_wu_add_accessprivilege_76': 'Access Privilege',
		's_wu_add_usertypes_77': 'User Types',
		's_wu_add_emailaddress_78': 'Email Address',
		's_wu_add_loginmethod_79': 'Login Method',
		's_wu_add_passwordreset_80': 'Password Reset',
		's_wu_add_usercredential_81': 'User Credential',
		's_wu_add_save_button_82': 'Save',
		's_wu_add_close_button_83': 'Close',

		'security_passwordpolicy_title_84': 'Password Policy',
		'security_passwordpolicy_subtitle_85':
			'A password policy is a set of rules design to increase the security of your account by encouraing users to create and use strong passwords.',
		'security_passwordpolicy_subtitle_86':
			'The existing password policy can be modified below. This password policy applies to all people who registered in this account. Minimum password length is 18',
		's_paspol_minimumpassword_87': 'Minimum password length',
		's_paspol_req_upper_88': 'Require at least one uppercase letter',
		's_paspol_req_lower_89': 'Require at least one lowercase letter',
		's_paspol_req_num_90': 'Require at least one number',
		's_paspol_req_symb_91': 'Require at least one Symbol Character',
		's_paspol_expire_92': 'Password expires in',
		's_paspol_expire_days_93': 'Days',
		's_paspol_enforce_94': 'Enforce password history',
		's_paspol_password_rem_95': 'passwords remembered',
		's_paspol_password_update_96': 'Update',

		//DEFINITIONS MODULE
		'definitions_97': 'Definitions',
		'definitions_title_98': 'Definitions',
		'definitions_subtitle_99': 'Parking Management System',
		'def_department_100': 'Department',
		'def_designation_101': 'Designation',
		'def_location_102': 'Location',
		'def_rejection_reason_103': 'Rejection Reason',

		'def_add_department_104': 'Add New Department',
		'def_add_depId_105': 'Department ID',
		'def_add_depName_106': 'Department Name',
		'def_add_depDesc_107': 'Department Description',
		'def_add_defaultCheck_108': 'Default',
		'def_add_submit_109': 'Submit',

		'def_table_id_110': 'ID',
		'def_table_name_111': 'NAME',
		'def_table_addedBy_112': 'ADDED By',
		'def_table_default_113': 'DEFAULT',
		'def_table_action_114': 'ACTION',

		//LOGS MODULE
		'logs_115': 'Logs',
		'logs_subtitle_116': 'Parking Management System',
		'logs_applicationLog_117': 'Application Logs',
		'logs_auditLog_118': 'Audit Logs',
		'logs_dateTime_119': 'Date Time',
		'logs_module_120': 'Module',
		'logs_userId_121': 'User ID',
		'logs_eventType_122': 'Event Type',
		'logs_eventCategory_123': 'Event Category',
		'logs_app_search_124': 'Search',
		'logs_action_125': 'Action',

		'logs_table_id_126': 'ID',
		'logs_table_datetime_127': 'DATETIME',
		'logs_table_username_129': 'USERNAME',
		'logs_table_interface_137': 'INTERFACE',

		'logs_app_userid_128': 'USER ID',
		'logs_app_eventid_130': 'EVENT ID',
		'logs_app_eventtype_131': 'EVENT TYPE',
		'logs_app_eventdesc_132': 'EVENT DESC',
		'logs_app_status_133': 'STATUS',
		'logs_app_errorcode_134': 'ERROR CODE',
		'logs_app_desc_135': 'ERROR DESC',
		'logs_app_src_136': 'SOURCE IP',

		'logs_audit_performed_138': 'PERFORMED BY',
		'logs_audit_module_139': 'MODULE',
		'logs_audit_identifier_140': 'IDENTIFIER',
		'logs_audit_ip_141': 'IP',

		'audit_log_viewer_title_142': 'Audit Log Viewer',
		'audit_log_viewer_descp_143': 'Logs',
		'a_log_v_module_144': 'Module',
		'a_log_v_userid_145': 'User ID',
		'a_log_v_identifier_146': 'Identifier',
		'a_log_v_datetime_147': 'Date Time',
		'a_log_v_action_148': 'Action',
		'a_log_v_username_149': 'User Name',
		'a_log_v_value_150': 'Value',

		'a_log_v_parameter_151': 'PARAMETER',
		'a_log_v_before_152': 'BEFORE',
		'a_log_v_after_153': 'AFTER',
		'a_log_v_close_button_154': 'CLOSE',
	},
};
