/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useCallback, useRef, useState} from 'react'
import ViewAuditLog from './ViewAuditLog'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_audit_logs, fetch_audit_log_detail } from '../../../store/action';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { FailedToast } from '../../../../../utils/utilFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

function ListTable() {
    const [viewModal, setViewModal] = useState(false)
	const [auditDetail, setAuditDetail] = useState(null)
    const ViewModalClose = () => setViewModal(false)
	const fetchIdRef                    = useRef(0);
	const [viewLoader, setViewLoader] = useState<any>(null)

	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();
	const AUDIT_LOGS_LIST = useAppSelector((state) => state?.logs)

	const ViewModalOpen = (id:number) =>{
		setViewLoader(id)
		const Success = (res:any) =>{
			console.log(res)
			setAuditDetail(res.response)
			setViewLoader(null)
			setViewModal(true)
		}
		const Failed = (err:any) => {
			FailedToast(err?.response)		;
			setViewLoader(null)
		}
		fetch_audit_log_detail(id, Success, Failed)
	}

	
	const columns = React.useMemo(
		()	=>	[
			{ Header: 'ID', accessor:'auditTID' , orderByKey:"auditTID"},
			{ Header: 'DATE-TIME', accessor:'createdOn', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A' , orderByKey:"CreatedOn"},
			{ Header: 'USERNAME', accessor:'createdUser', tdClass : 'dataCapitalize'},
			{ Header: 'MODULE', accessor:'displayName' , orderByKey:"displayName"},
			{ Header: 'IDENTIFIER', accessor:'identifier' , orderByKey:"identifier"},
			{ Header: 'IP', accessor:'sourceIP'},
			{ Header: 'INTERFACE', accessor:'source',
			Cell: ({ row }: any) => {
				return (
					<Fragment>
						{userTypes[parseInt(row?.values?.source)-1]}
					</Fragment>
				);
			},},
            {
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a  onClick={() => ViewModalOpen(row?.values?.auditTID)}  className="btn btn-sqaure btn-white modal-button">
									{
										parseInt(viewLoader)===row?.original?.pRequestID ?
										<CircularProgress size={18}/>
											:
										<i className='fa-solid fa-eye'></i>
									}
								</a>
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);
	type IfetchData = {
		pageSize :number,
		pageIndex :number,
		signal :AbortSignal,
		filters: {query: string;},
		OrderBy : string,

	}
	let userTypes = [ 'System User',  'Api User']


	const fetchData = useCallback(({ pageSize, pageIndex ,signal,OrderBy  ,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_audit_logs(navigate, dispatch, {
				'filters': {
					'query':filters.query ?? `CAST (CreatedOn AS date) >= '${dayjs().subtract(3,'day').format('YYYY-MM-DD')}' AND CAST (CreatedOn AS date) <= '${dayjs().format('YYYY-MM-DD')}'`,
					OrderBy
				},
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			},signal);
		}
	}, [ dispatch, navigate]);
    return (
        <>
            <CommonTable 
				data={AUDIT_LOGS_LIST?.AUDIT_LOGS_LIST??[]} 
				columns={columns} 
				pageCount={AUDIT_LOGS_LIST?.AUDIT_LOGS_PAGINATION?.totalCount ?? 0} 
				fetchData={fetchData} 
		  		limit={AUDIT_LOGS_LIST?.AUDIT_LOGS_PAGINATION?.limit ?? 1} 
				page_no={AUDIT_LOGS_LIST?.AUDIT_LOGS_PAGINATION?.pageNo ?? 1}
				error={AUDIT_LOGS_LIST?.AUDIT_LOGS_ERROR ?? ''}	
				filters={AUDIT_LOGS_LIST?.AUDIT_LOGS_FILTER}	
				loading={AUDIT_LOGS_LIST?.AUDIT_LOGS_LOADER}	
			/>
          {viewModal &&  <ViewAuditLog  open={viewModal} handleClose={ViewModalClose} Data={auditDetail} />	}
        </>
    );
} 

export default ListTable;