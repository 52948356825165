import React from 'react';
import Select, { MenuPlacement } from 'react-select';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface Iprops {
	name: string;
	options: any[];
	control: Control<FieldValues, any>;
	grey?: boolean;
	isMulti?: boolean;
	placeholder: string;
	onchangeFx?:(e:any)=>void;
	defaultValue?: any;
	classFx?: string;
	menuPlacement?:MenuPlacement
	onInputChange?:(e:any)=>void
	isClearable?:boolean
}

const FormSelect = ({ name, control, options = [], grey, isMulti, placeholder, onchangeFx, defaultValue,onInputChange, classFx ,menuPlacement, isClearable}: Iprops) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field: { value, onChange, onBlur } }) => {
                    
					return (
						<Select
							options={options}
							className={`${grey ? 'common-select common-select-grey' : 'common-select'} ${classFx}`}
							placeholder={placeholder}
							isMulti={isMulti}
                            value={options.find((c) => c.value === value)??''}
                            onChange={(val) => {onChange(val.value);onchangeFx && onchangeFx(val)}}
							onBlur={onBlur}
							defaultValue={ defaultValue??options.find((c) =>{
                                return c.value === value})}
							menuPlacement={menuPlacement}
							onInputChange={onInputChange}
							isClearable={isClearable??false}
							escapeClearsValue={isClearable??false}
							backspaceRemovesValue={isClearable??false}
						// 	menuPortalTarget={document.body}
						// 	styles={{ menuPortal: base => ({ ...base,  }),
						// 	menuList: (base) => ({
						// 		...base,
							
						// 		"::-webkit-scrollbar": {
						// 		  width: "4px",
						// 		  height: "0px",
						// 		},
						// 		"::-webkit-scrollbar-track": {
						// 		  background: "#f1f1f1"
						// 		},
						// 		"::-webkit-scrollbar-thumb": {
						// 		  background: "#888"
						// 		},
						// 		"::-webkit-scrollbar-thumb:hover": {
						// 		  background: "#555"
						// 		}
						// 	  })
						
						// }}


						/>
					);
				}}
			/>
		</>
	);
};

export default FormSelect;
