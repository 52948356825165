/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

type Props = {
	expand: boolean;

	expandFx: () => void;


};

export const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'align',
	'strike',
	'script',
	'blockquote',
	'background',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
	'code-block',
	'mention',
	'width',
	"height",
	'float'
];

const EditorToolbar = (props: Props) => {
	return (
		<>
			<div id='toolbar'>
				<span className='ql-formats'>
					<button className='ql-header btn' value='1'>
						H1
					</button>
				</span>
				<span className='ql-formats'>
					<button className='ql-header btn' value='2'>
						H2
					</button>
				</span>

				<span className='ql-formats'>
					<button className='ql-bold btn' />
				</span>
				<span className='ql-formats'>
					<button className='ql-italic btn' />
				</span>
				<span className='ql-formats'>
					<button className='ql-underline btn' />
				</span>
				<span className='ql-formats'>
					<button className='ql-strike btn' />
				</span>
				<span className='ql-formats'>
					<button className='ql-list btn' value='ordered' />
				</span>
				<span className='ql-formats'>
					<button className='ql-list btn' value='bullet' />
				</span>
				<span className='ql-formats'>
					<button className='ql-indent btn' value='-1' />
				</span>
				<span className='ql-formats'>
					<button className='ql-indent btn' value='+1' />
				</span>
				{/* <span className="ql-formats">
                    <button className="ql-script" value="super" />
                    <button className="ql-script" value="sub" />
                    <button className="ql-blockquote" />
                    <button className="ql-direction" />
                </span> */}

				<span className='ql-formats'>
					<select className='ql-align ' />
				</span>
				<span className='ql-formats'>
					<select className='ql-color ' />
				</span>
				<span className='ql-formats '>
					{/* <select className="ql-align" /> */}
					{/* <select className="ql-color" /> */}
					<select className='ql-background ' />
				</span>

				<span className='ql-formats'>
					<button className='ql-image btn'>{/* <i className='fa fa-image'></i> */}</button>
					{/* <select className="ql-color" />
                    <select className="ql-background" /> */}
				</span>

				<span className='ql-formats'>
					<button className='ql-blockquote btn' />
				</span>
				<span className='ql-formats'>
					<button className='ql-link btn' />
				</span>
				{/* <span className="ql-formats">
                    <button className="ql-link" />
                    <button className="ql-image" />
                    <button className="ql-video" />
                </span> */}
				{/* <span className="ql-formats">
                    <button className="ql-formula" />
                    <button className="ql-code-block" />
                    <button className="ql-clean" />
                </span> */}

				<div className='ql-formats'>
					{props?.expand ? (
						<button onClick={props?.expandFx} className=''>
							<i className='fa-solid fa-minimize'></i>
						</button>
					) : (
						<button onClick={props?.expandFx} className=''>
							<i className='fa-solid fa-maximize'></i>
						</button>
					)}
				</div>
		
			</div>
		</>
	);
};

export default EditorToolbar;
