/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { fetch_find_a_vehicle } from '../../../store/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';

type Props = {};

const FilterBox = (props: Props) => {
	const dispatch = useAppDispatch();

	const FINDACAR = useAppSelector((state) => state?.findacar);

	// const statusArr:any = [ { value : 0, label : 'Pending' }, { value : 1, label : 'Approved' }, { value : 2, label : 'Rejected' }, { value : 4, label : 'Revoked' } ]


	const filterSearch = (e: string) => {
		fetch_find_a_vehicle( dispatch, {
			'SearchText': e ?? '',

			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': FINDACAR.FIND_A_VEHICLE_PAGINATION.limit,
			},
		});
	};



	return (
		<div className='search-filter-row' >

		

				<div className='row'>
					<div className='col-5'>
						<div className='form-group'>
							<div className='pos-rel'>
								<input
									className='form-control grey-input'
									name=''
									placeholder='Search Employee / Visitor / Plate Number'
									onChange={(v) => filterSearch(v.target.value)}
								/>
								<i className='fa-solid fa-magnifying-glass'></i>
							</div>
						</div>
					</div>
			</div>
		</div>
	);
};

export default FilterBox;
