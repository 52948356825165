import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import loginBg from '../../../assets/images/login/login-bg.jpg';
import logo from '../../../assets/images/login/logo.jpg';
import { reset_password } from '../store/action';
import jwt_decode from "jwt-decode";
import { Statistic } from 'antd';
import dayjs from 'dayjs';
import { FailedToast, SuccesToast } from '../../../utils/utilFunctions';
import ButtonLoader from '../../../common/components/ButtonLoader';
import useKeyboard from '../../../utils/hooks/useKeyboard';
const { Countdown } = Statistic

type Props = {}

const ResetPasswrd = (props: Props) => {
    let navigate = useNavigate();
	let dispatch = useDispatch();
	let { tk } = useParams();
	const [resetpassword, setResetPassword] = useState('');
	const [password, setPassword] = useState('');
	const [tokenExp, settokenExp] = useState(false)
	const [loader, setloader] = useState(false);
	let jwtToken = ''

	try{
		jwtToken = jwt_decode(tk??'')
	}
	catch{
		jwtToken = ''
	}

	const decodedTk:any = jwtToken

    const resetpasswrd = () =>{
		setloader(true);
		console.log('DECOED',decodedTk)
		let postdata = {
			'UserUniqueID': decodedTk?.uuid,
			'NewPassword':password,
			...decodedTk
		}
		const Success = (res:any) =>{
			console.log(res)
			setloader(false);
			navigate('/login',{ replace: true })
			SuccesToast(res?.response?.data)
		}
		const Failed = (err:any) =>{
			console.log(err)	
			setloader(false);
			FailedToast(err?.response)		
		}
		
		reset_password(navigate, dispatch, postdata, Success, Failed, tk)
	}

	useKeyboard('Enter', resetpasswrd);


  return (
    <>
			<section className='full-height login-section' style={{ height: '100vh' }}>
				<div className='login-background'>
					<img src={loginBg} alt='' />
				</div>

				<div className='login-form'>
					<div className='login-row'>
						<div className='form-header'>
							<img src={logo} alt='logo' title='Smartbays Parking Solution' />
						</div>
							{
								!(decodedTk && decodedTk?.exp && 								
								tokenExp ||  Date.now() >= decodedTk?.exp * 1000)	?
							<div className='form-body'>
								{/* <form> */}
								<div className='form-group'>
									<label htmlFor='password'>New Password</label>
									<input
										type='password'
										className='form-control'
										id='password'
										placeholder='*********'
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>

								<div className='form-group'>
									<label htmlFor='password'>Confirm Password</label>
									<div className='pos-rel'>
										<input
											type='password'
											className='form-control'
											id='resetpassword'
											placeholder='*********'
											required
											value={resetpassword}
											onChange={(e) => setResetPassword(e.target.value)}
										/>
									</div>
								</div>
								{decodedTk && decodedTk?.exp && 								
									tokenExp ||  Date.now() >= decodedTk?.exp * 1000? 
									<p className='hookform-error-message'>Session has expired. Please try again from the beginning</p> :
									null
								}
								<div className='form-group'>
									<button className='btn btn-primary' 
									onClick={() => resetpasswrd()}>
										<ButtonLoader text='Change Password' loading={loader} />
									</button>
								</div>
								{/* </form> */}
							</div>
							:
							<div className='form-body'>
								<p style={{ fontSize: '1rem', color: 'var(--darkblue)', fontWeight: '500' }}>
									Session Expired. Please Try Again from the begining.
								</p>
								<div className='form-group'>
									<a className='btn btn-primary' href="/login">
										OK
									</a>
								</div>
							</div>
							}
						
					</div>
				</div>
			</section>
		</>
  )
}

export default ResetPasswrd