import React, { useEffect, useState } from 'react'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from 'react-select';
import { fetch_floorLevel, fetch_floorLevelDDL } from '../../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { DatePicker } from "antd"
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker';
import { UPDATE_REPORTS_FILTER, UPDATE_REPORTS_LOADER } from '../../../store/reportSlice';
import { fetch_report_turnover_daily_bayType, fetch_report_turnover_daily_overall, fetch_report_turnover_daily_zone, fetch_report_turnover_heatmap, fetch_report_turnover_summary_bayType, fetch_report_turnover_summary_weekday, fetch_report_turnover_summary_zone } from '../../../store/action';
import moment from 'moment';
// import Site_floorSelectPop from '../../../components/site_floorSelectPop';

const { RangePicker } = DatePicker;


const FilterBar = ({ tabVal, setTabVal, measure, chartType, isUnRecVeh,
	setFilename, setFilenameSub, setFileDateStr, setFileTimeStr, setFileSectionStr }:any) => {
	const SITE_FLOOR = useAppSelector((state)=>state?.general)
	const REPORTSDATA = useAppSelector((state) => state?.reportData)
	
	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	  };
	const dispatch  = useAppDispatch();
	useEffect(() => {
		setTimeRange(REPORTSDATA?.REPORTS_TIME?.length>0 ? REPORTSDATA?.REPORTS_TIME : [moment('00:00:00','HH:mm:ss'), moment('23:59:59','HH:mm:ss')])
		setDateRange(REPORTSDATA?.REPORTS_DATE?.length>0 ? REPORTSDATA?.REPORTS_DATE : [moment(new Date(),'YYYY-MM-DD').subtract(7,'day'),moment(new Date(),'YYYY-MM-DD')])
		setSiteIdFx(REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value)
		setFloorIdFx(0)

		setFilename(`${REPORTSDATA?.REPORTS_DATE?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('DD-MMM-YYYY') : dayjs().subtract(7,'day').format('DD-MMM-YYYY')} - ${
			REPORTSDATA?.REPORTS_DATE?.length > 0 ?  dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('DD-MMM-YYYY') : dayjs().format('DD-MMM-YYYY')} (${
				REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
					REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'})`)
					setFilenameSub(`(${SITE_FLOOR?.SITE_DDL?.find(el => el.value===REPORTSDATA?.REPORTS_SITE)?.label})`)
	
					setFileDateStr(`${REPORTSDATA?.REPORTS_DATE?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_DATE?.[0]).format('DD-MMM-YYYY') : dayjs().subtract(7,'day').format('DD-MMM-YYYY')} - ${
						REPORTSDATA?.REPORTS_DATE?.length > 0 ?  dayjs(REPORTSDATA?.REPORTS_DATE?.[1]).format('DD-MMM-YYYY') : dayjs().format('DD-MMM-YYYY')}`)
					setFileTimeStr(`${REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[0]).format('hh:mm a') : '12:00 am'} - ${
							REPORTSDATA?.REPORTS_TIME?.length > 0 ? dayjs(REPORTSDATA?.REPORTS_TIME[1]).format('hh:mm a') :  '11:59 pm'}`)
					setFileSectionStr(`${SITE_FLOOR?.SITE_DDL?.find(el => el.value===REPORTSDATA?.REPORTS_SITE)?.label}`)
	}, [REPORTSDATA, SITE_FLOOR?.SITE_DDL])

	const [siteIdFx, setSiteIdFx]         = useState<any>(REPORTSDATA?.REPORTS_SITE ? REPORTSDATA?.REPORTS_SITE : SITE_FLOOR?.SITE_DDL[0]?.value )
	const [floorIdFx, setFloorIdFx]       = useState<any>(0)
	const [dateRange, setDateRange] 	= useState<any>(REPORTSDATA?.REPORTS_DATE?.length>0 ? REPORTSDATA?.REPORTS_DATE : [moment(new Date(),'YYYY-MM-DD').subtract(7,'day'),moment(new Date(),'YYYY-MM-DD')])
	const [timeRange, setTimeRange] 	= useState<any>([])

	const filterSearch = () =>{
		dispatch(UPDATE_REPORTS_LOADER())
        // console.log("REPORTS=>",REPORTSDATA,SITE_FLOOR?.FLOORLEVEL_DDL,SITE_FLOOR?.SITE_DDL)
        let postData = {
			DataType	  :	  measure,
          SiteID      :   siteIdFx,
          Todate      :   dayjs(dateRange?dateRange[1]:'').format('YYYY-MM-DD'),
          FromDate    :   dayjs(dateRange?dateRange[0]:'').format('YYYY-MM-DD'),
          FloorID     :   floorIdFx,
          FromTime    :   timeRange && moment(timeRange[0],['HH:mm:ss','HH:mm a','hh:mm a'],true).isValid() ? moment(timeRange[0],['HH:mm:ss','HH:mm a','hh:mm a'],true).format('HH:mm:ss'):'00:00:00',
          ToTime      :   timeRange && moment(timeRange[1],['HH:mm:ss','HH:mm a','hh:mm a'],true).isValid() ? moment(timeRange[1],['HH:mm:ss','HH:mm a','hh:mm a'],true).format('HH:mm:ss') :  '23:59:00',
		  UnknownVehicle : isUnRecVeh
      }
	  if(tabVal===0){
		fetch_report_turnover_summary_weekday(postData, measure, chartType , isUnRecVeh, dispatch)
		fetch_report_turnover_summary_bayType(postData, measure, chartType , isUnRecVeh, dispatch)
		fetch_report_turnover_summary_zone(postData, measure, chartType , isUnRecVeh, dispatch)
	  }
	  else if(tabVal===1){
		fetch_report_turnover_daily_bayType(postData, measure, chartType , isUnRecVeh, dispatch)
		fetch_report_turnover_daily_overall(postData, measure, chartType , isUnRecVeh, dispatch)
		fetch_report_turnover_daily_zone(postData, measure, chartType , isUnRecVeh, dispatch)
	  }
	  else{
		fetch_report_turnover_heatmap(postData, measure, chartType , isUnRecVeh, dispatch)
	  }
	  dispatch(UPDATE_REPORTS_FILTER({
		dates:	dateRange,
		times:  timeRange,
		// floor:  floorIdFx, 
		site:   siteIdFx,
		unrecveh : isUnRecVeh
	  }))
	}

  return (
    <div className="white-card data-clean mb-30">
			<div className="search-filter-row mb-0">
			<ul className='justify-space-between'>
							<li>
								<a  className="active pointerDefaultSet"><i className="fa-solid fa-magnifying-glass"></i>  Quick Search</a>
							</li>
							{	dateRange && timeRange &&
								moment(dateRange[0]).isValid() && moment(dateRange[1]).isValid() &&
								moment(timeRange[0]).isValid() && moment(timeRange[1]).isValid() &&
							<li className='reportsAndDashUserHelp'>
								Showing turn-over reports of vehicles entered and exited between {moment(timeRange[0]).format('hh:mm a')} and {moment(timeRange[1]).format('hh:mm a')} on dates
								from {moment(dateRange[0]).format('DD-MMM-YYYY')} to {moment(dateRange[1]).format('DD-MMM-YYYY')}
							</li>
							}
						</ul>

				<div className="filter-block" id="quick-search">
						<div className="row">
							<div className="col-3">
								<div className="form-group">
									<RangePicker
										picker="date"
										allowClear={false}
										allowEmpty={[false,false]}
										showNow
										className='form-control'
										placeholder={['Start Date',' End Date']}
										inputReadOnly
										disabledDate={disabledDate}
										value={dateRange}
										format={'DD-MMM-YYYY'}
										onChange={(dates:any) =>{
											setDateRange(dates)
										}}
									/>
									
								</div>
							</div>
							<div className="col-3">
								<div className="form-group">
									<RangePicker
										picker="time"
										allowClear={false}
										allowEmpty={[false,false]}
										showNow
										className='form-control'
										placeholder={['Start Time',' End Time']}
										inputReadOnly
										value={timeRange}
										format={'hh:mm a'}
										onChange={(dates:any) =>{
											setTimeRange(dates)
										}}
									/>
									
								</div>
							</div>
							
							{/* <div className="col-4">
								<Site_floorSelectPop
									selectedFloor={floorIdFx}
									setselectedFloor={(id:any)=>{setFloorIdFx(id)}}
									selectedSite={siteIdFx}
									setselectedSite={(id:any)=>{setSiteIdFx(id)}}
								/>
							</div> */}
							
							<div className='col-4'>
								<div className="form-group">
									<ReactSelect
										options={SITE_FLOOR.SITE_DDL}
										className={'common-select'}
										placeholder={'Site'}
										defaultValue={SITE_FLOOR?.SITE_DDL && SITE_FLOOR?.SITE_DDL[0]?.value}
										isMulti={false}
										isClearable={false}
                        	    		value={SITE_FLOOR.SITE_DDL.find((c: { value: number; }) => c.value === parseInt(siteIdFx))}
                            			onChange={(val) =>{ setSiteIdFx(val?.value); }}
									/>
								</div>
							</div>
							

							<div className="col-2">
								<div className="form-group" onClick={()=>{
									filterSearch()
								}}>
									<button className="form-control blue-btn">Generate Report</button>
								</div>
							</div>
						 
						</div>
				</div>

			</div>

		</div>
  )
}

export default FilterBar