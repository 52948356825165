export const DEBUG = true;

// export const BASEURL                                        =   process.env.REACT_APP_BASE_URL
export const BASEURL                                        =   ''

// export const BASEURL                                        =   'http://10.106.5.114:3000/'
// export const BASEURL                                        =   'http://103.104.45.252:3002/'


// export const BASEURL                                        =   process.env.REACT_APP_BASE_URL_PRODUCTION

export const VERSION                                        =   'api/';


export const REFRESH_AUTHENTICATION                         =   BASEURL + 'auth/renew-access/';
export const LOGIN_AUTH                                     =   BASEURL + 'auth/authorize/';
export const LOGIN_FORGOT                                   =   BASEURL + 'auth/forgot-password'
export const LOGIN_RESET                                    =   BASEURL + VERSION + 'security/webusers/password-reset/action'

export const APPLICATION_LOGS_URL                           =   BASEURL + VERSION + 'log/application-log/'
export const AUDIT_LOGS_URL                                 =   BASEURL + VERSION + 'log/audit-log/'



export const PARKING_POLICY                                 =   BASEURL + VERSION + 'policies/parking-policy/'
export const VIOLATION_POLICY                               =   BASEURL + VERSION + 'policies/violation-type/'
export const POLICY_ASSIGNMENT                              =   BASEURL + VERSION + 'policies/assign-policy/'
export const POLICIES_ASSIGNED_ZONES                        =   BASEURL + VERSION + 'policies/assign-policy-zonelist/'



export const PARKING_ZONE_COUNT                             =   BASEURL + VERSION + 'ParkingZone/bay-type-count'
export const PARKING_ZONE_REQUESTED_ZONES                   =   BASEURL + VERSION + 'ParkingZone/requested-zones'





export const USERS                                          =   BASEURL + VERSION + 'user/users/';
export const USERS_ACTION                                   =   BASEURL + VERSION + 'user/users/action/';
export const USERS_VEHICLE                                  =   BASEURL + VERSION + 'user/vehicle/';
export const USERS_VEHICLE_ACTION                           =   BASEURL + VERSION + 'user/vehicle/action/';
export const VEHICLE_LIST_SEARCH                            =   BASEURL + VERSION + 'user/vehicleSearch/'

export const VISITOR                                        =   BASEURL + VERSION + 'visitors/visitors/';
export const VISITOR_ACTION                                 =   BASEURL + VERSION + 'visitors/visitors/action/';
export const VISITOR_VEHICLE                                =   BASEURL + VERSION + 'visitors/vehicle/';
export const VISITOR_VEHICLE_ACTION                         =   BASEURL + VERSION + 'visitors/vehicle/action/';



export const SECURITY_ROLES                                 =   BASEURL + VERSION + 'security/roles/';
export const SECURITY_ROLES_ACTION                          =   BASEURL + VERSION + 'security/roles/action/';
export const SECURITY_WEBUSERS                              =   BASEURL + VERSION + 'security/webusers/';
export const SECURITY_WEBUSERS_ACTION                       =   BASEURL + VERSION + 'security/webusers/action/'
export const SECURITY_WEBUSERS_PASSWORD_RESET               =   BASEURL + VERSION + 'security/webusers/password-reset/action/'


export const SECURITY_WEBUSER_REQUEST                       =   BASEURL + VERSION + 'security/webusers/requests/';
export const SECURITY_WEBUSER_REQUEST_ACTION                =   BASEURL + VERSION + 'security/webusers/requests/action/';
export const SECURITY_WEBUSER_REQUEST_STATUS                =   BASEURL + VERSION + 'security/webusers/requests/status/';

export const SECURITY_WEBUSER_BY_ZONE                       =   BASEURL + VERSION + 'security/webusersbyzone/';
export const SECURITY_ROLES_PSWRD                           =   BASEURL + VERSION + 'security/password-policy/';
export const SECURITY_SYS_USER_TYPE                         =   BASEURL + VERSION + 'definition/sysusertype';





export const DEFINITION_URL                                 =   BASEURL + VERSION + 'definition/';
export const DEFINITION_LOCATION_TREE                       =   BASEURL + VERSION + 'definition/location-tree/'
export const DEFINITION_LOCATION_TREE_WITHOUT_PERMISSION    =   BASEURL + VERSION + 'definition/location-tree-without-permission/'


export const PARKING_VIOLATIONS                             =   BASEURL + VERSION + 'violations/parking-violation/'
export const VIOLATIONS_BLACKLISTED                         =   BASEURL + VERSION + 'violations/blacklisted-vehicle/'
export const VIOLATIONS_GET_BLACKLISTED                     =   BASEURL + VERSION + 'violations/blacklisted-vehicles/'
export const REPORT_VIOLATIONS                              =   BASEURL + VERSION + 'violations/report-violation/'
export const VIOLATIONS_COUNT                               =   BASEURL + VERSION + 'violations/violation-count/'
export const VIOLATIONS_COUNT_BYTYPE                        =   BASEURL + VERSION + 'violations/violation-count-by-type/'
export const VIOLATIONS_SEARCH                              =   BASEURL + VERSION + 'violations/search-vehicle-by-user/visitor/'
export const GET_VIOATION_TYPE_ZONE                         =   BASEURL + VERSION + 'policies/violation-type/zone/'



export const NOTIFICATION_TEMPLATE                          =   BASEURL + VERSION + 'notification/email-template/'
export const NOTIFICATION_TEMPLATE_ACTION                   =   BASEURL + VERSION + 'notification/email-template/action/'
export const NOTIFICATION_TEMPLATE_PARAMETERS               =   BASEURL + VERSION + 'notification/email-parameters/'
export const NOTIFICATION_SETTINGS                          =   BASEURL + VERSION + 'notification/settings/'
export const NOTIFICATION_SETTINGS_SMTP                     =   BASEURL + VERSION + 'notification/settings/smtp/'
export const NOTIFICATION_SETTINGS_CONFIGURATION            =   BASEURL + VERSION + 'notification/settings/configuration/'
export const NOTIFICATION_SETTINGS_CONFIGURATION_ACTION     =   BASEURL + VERSION + 'notification/settings/configuration/action/'
export const NOTIFICATION_EMAIL_TEST                        =   BASEURL + VERSION + 'notification/settings/smtp/test'


export const NOTIFICATION_HISTORY                           =   BASEURL + VERSION + 'notification/history/'
export const NOTIFICATION_EXCEPTION                         =   BASEURL + VERSION + 'notification/exceptions/'
export const NOTIFICATION_EXCEPTION_ACTION                  =   BASEURL + VERSION + 'notification/exceptions/action/'

export const NOTIFICATION_WEB                               =   BASEURL + VERSION + 'notification/web-notification/'
export const NOTIFICATION_WEB_ACTION                        =   BASEURL + VERSION + 'notification/web-notification/action/'
export const NOTIFICATION_WEB_SINGLE_DELETE                 =   BASEURL + VERSION + 'notification/web-notification/delete'
export const NOTIFICATION_MARK_ALLREAD                      =   BASEURL + VERSION + 'notification/web-notification/mark-all-read'
export const NOTIFICATION_SAVE_IMAGE                        =   BASEURL + VERSION + 'notification/save-image'



export const APPLICATION_SETTINGS                           =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/'
export const APPLICATION_SETTINGS_LOG_CLEAN_UP              =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/log-cleanup-settings'
export const APPLICATION_SETTINGS_GENERAL                   =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/general_settings'
export const APPLICATION_SETTINGS_DATA_CLEAN_UP             =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/data_cleanup_settings'

export const APPLICATION_SETTINGS_LOG_CLEAN_UP_ACTION       =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/action/log-cleanup-settings'
export const APPLICATION_SETTINGS_GENERAL_ACTION            =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/action/general_settings'
export const APPLICATION_SETTINGS_DATA_CLEAN_UP_ACTION      =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/action/data_cleanup_settings'

export const APPLICATION_SETTINGS_DATA_CLEAR_LOG            =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/clear-log'
export const APPLICATION_SETTINGS_DATA_ARCHIVE              =   BASEURL + VERSION + 'ApplicationSettings/applicationsettings/archive'



export const COMPANY_PROFILE                                =   BASEURL + VERSION + 'ApplicationSettings/companyprofile/'
export const COMPANY_PROFILE_ACTION                         =   BASEURL + VERSION + 'ApplicationSettings/companyprofile/action/'

export const LICENSE                                        =   BASEURL + VERSION + 'ApplicationSettings/license/'
export const APPLICATION_SETTINGS_GPK                       =   BASEURL + VERSION + 'ApplicationSettings/gpk/'
export const APPLICATION_SETTINGS_CPK                       =   BASEURL + VERSION + 'ApplicationSettings/cpk/'

export const APPLICATION_SETTINGS_DATA_SYNC                 =   BASEURL + VERSION + 'ApplicationSettings/data-synchronization'
export const APPLICATION_SETTINGS_DATA_SYNC_NOW             =   BASEURL + VERSION + 'ApplicationSettings/sync-now'
export const APPLICATION_SETTINGS_DATA_AUTO_SYNC            =   BASEURL + VERSION + 'ApplicationSettings/enable-autosync'
export const APPLICATION_SETTINGS_DATA_SYNC_STATUS          =   BASEURL + VERSION + 'ApplicationSettings/data-synchronization-status'
export const APPLICATION_SETTINGS_UPLOAD_TEMPLATE           =   BASEURL + VERSION + 'ApplicationSettings/upload-template'
export const APPLICATION_SETTINGS_DOWNLOAD_TEMPLATE         =   BASEURL + VERSION + 'ApplicationSettings/download-template'






export const PARKING_REQUEST                                =   BASEURL + VERSION + 'parkingrequest/requests/'
export const NEW_URL_PARKING_REQUEST                                =   BASEURL + VERSION + 'parkingrequest/requests/status/'

export const PARKING_REQUEST_ACTION                         =   BASEURL + VERSION + 'parkingrequest/requests/action'
export const PARKING_REQUEST_STATUS                         =   BASEURL + VERSION + 'parkingrequest/request-Status/update'
export const PARKING_REQUEST_COUNT                          =   BASEURL + VERSION + 'parkingrequest/request-count/'
export const ISALLOCATION_REQUEST                           =   BASEURL + VERSION + 'parkingrequest/is-already-exist'
export const ISVEHICLE_HAS_REQUESTS                         =   BASEURL + VERSION + 'parkingrequest/pending-request-details?RequestID='

export const PARKING_REQUEST_EMPLOYEE                       =   BASEURL + VERSION + 'parkingrequest/employee-requests/action'
export const PARKING_OVERTIME_REQUEST_EMPLOYEE              =   BASEURL + VERSION + 'parkingrequest/employee-overnight-requests/action'
export const PARKING_REQUEST_VISITOR                        =   BASEURL + VERSION + 'parkingrequest/visitor-requests/action'
export const PARKING_OVERTIME_REQUEST_VISITOR               =   BASEURL + VERSION + 'parkingrequest/visitor-overnight-requests/action'

export const GET_EVERY_USERS                                =   BASEURL + VERSION + 'parkingrequest/search-all-users-visitors/'

export const PREVIOS_ALLOCATION_DETAILS_PR_EDITON           =   BASEURL + VERSION + 'parkingrequest/allocation-details/' //id
export const NEW_PREVIOS_ALLOCATION_DETAILS_PR_EDITON           =   BASEURL + VERSION + 'parkingrequest/Overtime-request-allocation-details/' //id

export const PREVIOS_ALLOCATION_DETAILS_USER_EDITON         =   BASEURL + VERSION + 'parkingrequest/allocation-details/useruniqueid' //postdata=> isvisitor, useruniqueid
export const RESERVED_BAYLIST                               =   BASEURL + VERSION + 'parkingrequest/reserved-bay-list'
export const ALLOCATION_AVAILABILITY_GRAPH                  =   BASEURL + VERSION + 'parkingrequest/allocation-availability-graph'

export const ALLOCATED_USERS_PARKING_URL                    =   BASEURL + VERSION + 'findvehicles/approved-allocated-parking'
export const ALLOCATED_USERS_PARKING_URL_ACTION             =   BASEURL + VERSION + 'findvehicles/allocated-parking/action'
export const ALLOCATED_USERS_PARKING_URL_COUNT              =   BASEURL + VERSION + 'findvehicles/approved-request-count'

export const ALLOCATED_VISITORS_PARKING_URL                 =   BASEURL + VERSION + 'findvehicles/visitor-allocated-parking'
export const ALLOCATED_VISITORS_PARKING_URL_COUNT           =   BASEURL + VERSION + 'findvehicles/visitor-allocated-parking-count'

export const ALLOCATED_USERS_PARKING_REVOKE                 =   BASEURL + VERSION + 'findvehicles/revoke-allocated-Parking'

export const FIND_MY_CAR_URL                                =   BASEURL + VERSION + 'FindVehicles/find-vehicle'
export const FIND_MY_CAR_DETAILS                            =   BASEURL + VERSION + 'findvehicles/find-vehicle-details/'
export const FIND_MY_CAR_TODAYS_COUNT                       =   BASEURL + VERSION + 'findvehicles/today-parking-count/'



export const REALTIME_MONITOR                               =   BASEURL + VERSION + 'realtime/realtimefloordata/'
export const REALTIME_MONITOR_FINDVEHICLE                   =   BASEURL + VERSION + 'realtime/find-vehicle/'
export const REALTIME_MONITOR_VEHICLE_DETAILS               =   BASEURL + VERSION + 'realtime/parked-vehicle-user-details/'
export const REALTIME_MONITOR_AVAILABILITY_CHECK            =   BASEURL + VERSION + 'realtime/availability-check/'
export const REALTIME_MONITOR_TRANSACTION                   =   BASEURL + VERSION + 'realtime/transaction/parking-transaction/'
export const REALTIME_MONITOR_TRANSACTION_HISTORY           =   BASEURL + VERSION + 'realtime/transaction/parking-transaction-history/'
export const REALTIME_MONITOR_TRANSACTION_EXPORT            =   BASEURL + VERSION + 'realtime/transaction/export'
export const REALTIME_MONITOR_TRANSACTION_EXPORTEXCEL       =   BASEURL + VERSION + 'realtime/transaction/export-excel'

export const REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED              =   BASEURL + VERSION + 'realtime/transaction/unknown-parking-transaction-history/'
export const REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED_EXPORT       =   BASEURL + VERSION + 'realtime/transaction-list-unknown/export'
export const REALTIME_MONITOR_TRANSACTION_UNRECOGNIZED_EXPORTEXCEL  =   BASEURL + VERSION + 'realtime/transaction-list-unknown/export-excel'

export const REALTIME_MONITOR_HISTORY_EXPORT                =   BASEURL + VERSION + 'realtime/transaction-list/export'
export const REALTIME_MONITOR_HISTORY_EXPORTEXCEL           =   BASEURL + VERSION + 'realtime/transaction-list/export-excel'
export const REALTIME_MONITOR_IMAGE                         =   BASEURL + VERSION + 'realtime/realtime-bay-image'
export const REALTIME_MONITOR_BAYSEARCH                     =   BASEURL + VERSION + 'realtime/realtime-bay-search'



export const REPORTS_AND_DASHBOARD_GRAPH                    =   BASEURL + VERSION + 'reportanddashboard/'

// dashboard

export const DASHBOARD_PARKING_AVAILABILITY                 =   BASEURL + VERSION + 'dashboard/current-parking-availability'
export const DASHBOARD_24_HOUR                              =   BASEURL + VERSION + 'dashboard/parked-more-than-oneday'
export const DASHBOARD_OCCUPANCY_LEVELMAP                   =   BASEURL + VERSION + 'dashboard/level-map'
export const DASHBOARD_OCCUPANCY_HEATMAP                    =   BASEURL + VERSION + 'dashboard/occupancy-heatmap'
export const DASHBOARD_TOTAL_IN_OUT                         =   BASEURL + VERSION + 'dashboard/total-in-out'
export const DASHBOARD_PARKING_REQUEST_COUNT                =   BASEURL + VERSION + 'dashboard/parking-request-count'
export const DASHBOARD_VIOLATIONS_CHART                     =   BASEURL + VERSION + 'dashboard/violation-chart'
export const DASHBOARD_AVERAGE_OCCUPANCY                    =   BASEURL + VERSION + 'dashboard/average-occupancy'
export const DASHBOARD_ENTERED_VEHICLE_COUNT                =   BASEURL + VERSION + 'dashboard/entered-vehicle-count'
export const DASHBOARD_TURNOVER                             =   BASEURL + VERSION + 'dashboard/turn-over'
export const DASHBOARD_NO_OF_VISIT_COUNT                    =   BASEURL + VERSION + 'dashboard/number-of-visit-count'
export const DASHBOARD_NO_OF_OPERATORS                      =   BASEURL + VERSION + 'dashboard/operators-by-zone'
export const DASHBOARD_TOTAL_BAY_COUNT                      =   BASEURL + VERSION + 'dashboard/total-bay-count'
export const DASHBOARD_SYSTEM_USER_COUNT                    =   BASEURL + VERSION + 'dashboard/systemuser-count'
export const DASHBOARD_AVERAGE_APPROVE_REQUEST              =   BASEURL + VERSION + 'dashboard/average-approve-sysuser-request'
export const DASHBOARD_TOTAL_MANAGER_COUNT                  =   BASEURL + VERSION + 'dashboard/total-manager-by-zone'
export const DASHBOARD_TURNOVER_OVERALL_COUNT               =   BASEURL + VERSION + 'dashboard/turnover-overall-count-per-day'
export const DASHBOARD_SECURITY_MANUAL_VIOLATIONS           =   BASEURL + VERSION + 'dashboard/manual-violation-chart'
export const DASHBOARD_SECURITY_DWELL_AVERGAE_OVERALL       =   BASEURL + VERSION + 'dashboard/average-dwell-time-overall'
export const DASHBOARD_VIOLATIONS_BY_ZONE                   =   BASEURL + VERSION + 'dashboard/violations-by-zone'
export const DASHBOARD_24_HOUR_BY_ZONE                      =   BASEURL + VERSION + 'dashboard/parked-more-than-oneday-by-zone'



// dashboard customisation

export const DASHBOARD_SETTINGS                             =   BASEURL + VERSION + 'profile/dashboard-settings'
export const DASHBOARD_SETTINGS_ACTION                      =   BASEURL + VERSION + 'profile/dashboard-settings/action'

export const GENERAL_DASHBOARD_SETTINGS                     =   BASEURL + VERSION + 'profile/general-dashboard-settings'
export const GENERAL_DASHBOARD_SETTINGS_ACTION              =   BASEURL + VERSION + 'profile/general-dashboard-settings/action'


export const PROFILE_DETAILS                                =   BASEURL + VERSION + 'profile/userdetails/'
export const PROFILE_PERMISSIONS                            =   BASEURL + VERSION + 'profile/permission/'
export const PROFILE_PASSWORD_RESET                         =   BASEURL + VERSION + 'profile/updatepassword'



























//======DDL-LIST=======================>        


export const DEPARTMENT_DDL_URL                             =   BASEURL + VERSION + 'definition/department/ddl';
export const DESIGNATION_DDL_URL                            =   BASEURL + VERSION + 'definition/designation/ddl';
export const SITE_DDL_URL                                   =   BASEURL + VERSION + 'definition/site/ddl';
export const PRIORITY_LEVEL_DDL_URL                         =   BASEURL + VERSION + 'definition/prioritylevel/ddl';
export const ZONE_ASSIGNED_DDL_URL                          =   BASEURL + VERSION + 'definition/zone/assigned/ddl';
export const ZONE_DDL_URL                                   =   BASEURL + VERSION + 'definition/zone/ddl'
export const VEHICLEPLATE_DDL_URL                           =   BASEURL + VERSION + 'definition/vehicleplate/ddl';
export const BAY_DDL_URL                                    =   BASEURL + VERSION + 'definition/parkingbay/ddl'
export const INVALID_REASONS_DDL_URL                        =   BASEURL + VERSION + 'definition/violationreason/ddl'
export const PARKING_POLICY_DDL_URL                         =   BASEURL + VERSION + 'policies/parking-policy/ddl';
export const VIOLATIONTYPE_DDL_URL                          =   BASEURL + VERSION + 'policies/violation-type/ddl';   
export const BAYTYPE_DDL_URL                                =   BASEURL + VERSION + 'definition/baytype/ddl';
export const FLOORLEVEL_DDL_URL                             =   BASEURL + VERSION + 'definition/floorlevel/ddl';   
export const REJECTION_REASON_DDL_URL                       =   BASEURL + VERSION + 'definition/rejectionreason/ddl'    
export const BUILDING_LIST_DDL                              =   BASEURL + VERSION + 'definition/building/'
export const GROUP_COMPANY_DDL                              =   BASEURL + VERSION + 'definition/groupcompany/ddl'


export const VACANT_BAYLIST                                 =   BASEURL + VERSION + 'definition/vacant-baylist?ZoneID='
export const RUNVEHICLE_SERVICE                             =   BASEURL + VERSION + 'definition/process-transactions'
export const PLACE_OF_REG_LIST                              =   BASEURL + VERSION + 'definition/place-of-reg'