let data = require('../../app/config/event.json');

export const getAllEventsList = () => {
	let eventsArr = data.map((el: { events: any }) => el.events).flat(1);
	return eventsArr.map((el: { frontend_key: any; event_key: any }) => {
		return { 'label': el.frontend_key, 'value': el.event_key };
	});
};

export const findTheEvent = (val:string) => {
	let eventsArr = data.map((el: { events: any }) => el.events).flat(1);
	return eventsArr.find((el:any)=> el.event_key===val)?.frontend_key??'-'

}

export const getAllEventsOfModule = (id = null) => {
	if (id !== null) {
		let eventsArr = data.filter((el: { module_id: any }) => el.module_id === id);
		return eventsArr
			.map((el: { events: any }) => el.events)
			.flat(1)
			.map((el: { frontend_key: any; event_key: any }) => {
				return { 'label': el.frontend_key, 'value': el.event_key };
			});
	} else {
		let eventsArr = data.map((el: { events: any }) => el.events).flat(1);
		console.log('EventArr', eventsArr);
		return eventsArr.map((el: { frontend_key: any; event_key: any }) => {
			return { 'label': el.frontend_key, 'value': el.event_key };
		});
	}
};

export const getAllEventsOfSubModule = (id = null) => {
	let allEventsArr = data.map((el: { events: any }) => el.events).flat(1);
	if (id !== null) {
		return allEventsArr
			.filter((el: { sub_module_id: any }) => el.sub_module_id === id)
			.map((el: { frontend_key: any; event_key: any }) => {
				return { 'label': el.frontend_key, 'value': el.event_key };
			});
	} else {
		return allEventsArr.map((el: { frontend_key: any; event_key: any }) => {
			return { 'label': el.frontend_key, 'value': el.event_key };
		});
	}
	
};

export const getAllModules = (id = null) => {
	let arr = data.map((el: { module_key: any; module_id: string }) => {
		return { 'label': el.module_key?.toUpperCase(), 'value': parseInt(el.module_id) };
	});
	arr = arr.filter(
		(value: { label: any; value: any }, index: any, self: any[]) =>
			index === self.findIndex((t: { label: any; value: any }) => t.label === value.label && t.value === value.value)
	);
	//console.log('ALLMODULES', arr);
	return arr;
};

export const getAllSubModules = (id: any) => {
	if (typeof id !== 'undefined' && id!=='') {
		let arr = data
			.filter((el: { module_id: any }) => el.module_id === id)
			.map((el: { sub_module_key: any; sub_module_id: any; module_id: any; module_key: any }) => {
				return { 'label': el.sub_module_key?.toUpperCase(), 'value': el.sub_module_id, 'module_id': el.module_id, 'module_key': el.module_key };
			});
		arr = arr.filter(
			(value: { label: any; value: any }, index: any, self: any[]) =>
				index === self.findIndex((t: { label: any; value: any }) => t.label === value.label && t.value === value.value)
		);
		//console.log('ALLMODULES', arr);
		return arr;
	} else {
		let arr = data.map((el: { sub_module_key: any; sub_module_id: any; module_id: any; module_key: any }) => {
			return { 'label': el.sub_module_key?.toUpperCase(), 'value': el.sub_module_id, 'module_id': el.module_id, 'module_key': el.module_key };
		});
		arr = arr.filter(
			(value: { label: any; value: any }, index: any, self: any[]) =>
				index === self.findIndex((t: { label: any; value: any }) => t.label === value.label && t.value === value.value)
		);
		//console.log('ALLMODULES', arr);
		return arr;
	}
};
