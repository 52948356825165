/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PermissionWrapper from '../../../../../common/components/PermissionWrapper';
import { useAppDispatch } from '../../../../../config/hooks';
import { subModuleList } from '../../../../../config/subModuleList';
import { fetch_violation_policy } from '../../../store/actions';
import AddModal from './addModal'
 
type Props = {};

const FilterBar = (props: Props) => {
	const [addModal, setaddModal] = useState(false);
	const [val, setval] = useState('')

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => setaddModal(false);
	const controller = new AbortController();
	const signal = controller.signal;
	const filterSearch = (val: string) => {
		setval(val)
		fetch_violation_policy(navigate, dispatch, {
			'filters': {
				'query': val !== '' ? !isNaN(parseInt(val)) ? `VTypeID LIKE '${val}%'` : `ViolationName LIKE '%${val}%' OR VDescriptions LIKE '%${val}%'` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 10,
			},
		}, signal)

	}

	return (
		<div className="search-row-button">
			<div className="row">
				<div className="col-5">
					<div className="form-group">
						<div className="pos-rel">
							<input className="form-control grey-input" name="" placeholder="Search Here"
								value={val}
								onChange={(e) => {
									let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
									filterSearch(v)
								}
								}
							/>
							<i className="fa-solid fa-magnifying-glass"></i>
						</div>
					</div>
				</div>
				<div className="col-7 text-right">
					<PermissionWrapper subModuleID={subModuleList['Violation Policies']} type='isCreate'>
						<a onClick={addModalOpen}  className="btn btn-secondary modal-button">Add New Violation</a>
					</PermissionWrapper>

				</div>
			</div>
			{addModal && <AddModal open={addModal} handleClose={addModalClose} />}
		</div>

	);
};

export default FilterBar;
