import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../config/hooks';
import { profilePasswordReset } from '../../modules/auth/store/action';
import { password_policy } from '../../modules/security/store/action';
import { FailedToast, SuccesToast, WarnToast } from '../../utils/utilFunctions';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import ModalLayout from './../layouts/ModalLayout';
import dp from '../../assets/images/common/profile-pic.png';

import PasswordValidation from './PasswordValidation';
import ButtonLoader from './ButtonLoader';
import ErrorComponent from './ErrorComponent';

type Props = {
	open: boolean;
	handleClose: () => void;
};

const ProfileUpdateModal = (props: Props) => {
	const UserDetails: any = useAppSelector((state) => state?.login?.USER_PROFILE);
	const [passwordSettings, setpasswordSettings] = useState<any>();
	const [loading, setloading] = useState(false);
	const [valid, setvalid] = useState(false);



	const [showPass, setshowPass] = useState(false);
	const [showPass2, setshowPass2] = useState(false);
	const [showPass3, setshowPass3] = useState(false);


	



	const formSchema = yup.object().shape({

		newpassword: yup
			.string()
			.required('Password is required') ,
		oldpassword: yup.string().required('Please provide a valid new password'),
		confirmPassword: yup
		.string()
		.required()
		.oneOf([yup.ref("newpassword")], "Passwords do not match"),

	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		control,
		setValue,
		clearErrors,
	} = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(formSchema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	useEffect(() => {
		const getDefaultVal = (res: any) => {
			let Data = res?.response?.data ? res?.response?.data[0] : null;
			setpasswordSettings(Data);
		};
		password_policy('get', null, getDefaultVal, () => { });
	}, []);

	console.log('errors', errors);

	const onSubmitHandler = (data: any) => {

		if(!valid) return WarnToast("Password not valid")
		setloading(true);
		const sucess = (res: any) => {
			SuccesToast(res?.response?.data);
			setloading(false);
			props?.handleClose()
		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
			props?.handleClose()
			setloading(false);
		};

		let postData = {
			'UserUniqueID': UserDetails?.userUniqueID,
			'NewPassword': data?.newpassword,
			'OldPassword': data?.oldpassword,
		};
		profilePasswordReset(postData, sucess, failed);
	};

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title='Profile Details' subtitle='change password'>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className=' form-w-label  dflex col-dir'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<div className='profile-pic'>
									<img src={dp} alt='profile-pic' title='John Deo - Business Excutives' />
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<div className='form-group opacity-half'>
									<label htmlFor=''> User Name</label>
									<input
										readOnly={true}
										type='text'
										placeholder=''
										className='form-control grey-input'
										value={UserDetails?.firstName ?? ''}
									/>
								</div>
							</div>
							<div className='col-6'>
								<div className='form-group opacity-half'>
									<label htmlFor=''> Email ID</label>
									<input
										readOnly={true}
										type='text'
										placeholder=''
										className='form-control grey-input'
										value={UserDetails?.emailID ?? ''}
									/>
								</div>
							</div>
						</div>
						{/* <div className='row'>
							<div className='col-12'>
								<div className='form-group opacity-half'>
									<label htmlFor=''> Email ID</label>
									<input
										readOnly={true}
										type='text'
										placeholder=''
										className='form-control grey-input'
										value={UserDetails?.emailID ?? ''}
									/>
								</div>
							</div>
						</div> */}
						<div className='row'>
							<div className='col-6'>
								<div className='form-group opacity-half'>
									<label htmlFor=''> Designation Name</label>
									<input
										readOnly={true}
										type='text'
										placeholder=''
										className='form-control grey-input'
										value={UserDetails?.desName ?? ''}
									/>
								</div>
							</div>
							<div className='col-6'>
								<div className='form-group opacity-half'>
									<label htmlFor=''> Department Name</label>
									<input
										readOnly={true}
										type='text'
										placeholder=''
										className='form-control grey-input'
										value={UserDetails?.depName ?? ''}
									/>
								</div>
							</div>
						</div>
						{/* <div className='row'>
							<div className='col-12'>
								<div className='form-group opacity-half'>
									<label htmlFor=''> Department Name</label>
									<input
										readOnly={true}
										type='text'
										placeholder=''
										className='form-control grey-input'
										value={UserDetails?.depName ?? ''}
									/>
								</div>
							</div>
						</div> */}


						<div className='row'>
							<div className='col-12'>
								<div className='form-group'>
									<label htmlFor='' className='dflex justify-space-between' style={{ display: 'flex' }}>
										<p>Old Passoword</p>

										<i>
											{!showPass2 ? (
												<i className='fa fa-eye' aria-hidden='true' onClick={() => setshowPass2(true)}></i>
											) : (
												<i className='fa fa-eye-slash' aria-hidden='true' onClick={() => setshowPass2(false)}></i>
											)}
										</i>
									</label>



									<input
										{...register('oldpassword', {
											required: true,
											validate: (val: string) => {
												if (watch('password') === val) {
													return 'new password cannot be old password';
												}
											},
										})}
										type={showPass2 ? 'text' : 'password'}
										placeholder=''
										className='form-control grey-input'
									/>
									<ErrorComponent errors={errors} name='oldpassword' title='Old password' />
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group '>
									<label htmlFor='' className='dflex justify-space-between' style={{ display: 'flex' }}>
										<p>New password</p>

										<i>
											{!showPass ? (
												<i className='fa fa-eye' aria-hidden='true' onClick={() => setshowPass(true)}></i>
											) : (
												<i className='fa fa-eye-slash' aria-hidden='true' onClick={() => setshowPass(false)}></i>
											)}
										</i>
									</label>
									

									<Controller
										control={control}
										name='newpassword'
										render={({ field: { onChange , value , } }) => (
											<PasswordValidation
											type={showPass ? 'text' : 'password'}
											value={value}
											setValue={onChange}
											valid={valid}
											setvalid={setvalid}
											req_length={passwordSettings?.minimumPWdLength}
											grey={true}
											islowercase={passwordSettings?.requiredLowercaseLetter}
											isnumber={passwordSettings?.requeirdOneNumber}
											isuppercase={passwordSettings?.requirdUppercaseLetter}
											issymbol={passwordSettings?.requiredSymbolCharacter}
										/>
										)}
									/>
									<ErrorComponent errors={errors} name='newpassword' title='New password' />
								</div>
							</div>
						</div>
						<div className="row">
						<div className='col-12'>
								<div className='form-group'>
									<label htmlFor='' className='dflex justify-space-between' style={{ display: 'flex' }}>
										<p>Confirm Passoword</p>

										<i>
											{!showPass3 ? (
												<i className='fa fa-eye' aria-hidden='true' onClick={() => setshowPass3(true)}></i>
											) : (
												<i className='fa fa-eye-slash' aria-hidden='true' onClick={() => setshowPass3(false)}></i>
											)}
										</i>
									</label>



									<input
										{...register('confirmPassword')}
										type={showPass3 ? 'text' : 'password'}
										placeholder=''
										className='form-control grey-input'
									/>
									<ErrorComponent errors={errors} name='confirmPassword' title='confirmPassword' />
								</div>
							</div>
						</div>
		
					</div>
					<div className='modal-footer mt-30'>
						<button
							type='reset'
							className='btn btn-outline-blue close-modal-button'
							onClick={() => {
								reset();
								clearErrors();
								props.handleClose();
							}}>
							Close
						</button>
						<button type='submit' className='btn btn-secondary border-blue'>
							<ButtonLoader text={'Change Password'} loading={loading} />
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
	);
};

export default ProfileUpdateModal;
