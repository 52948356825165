import React, { Fragment, useEffect, useState } from 'react';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { fetch_rolelist_ddl, fetch_sys_userType, password_policy, search_user ,webusers_request_status } from '../../../store/action';
// import AsyncSelect from 'react-select/async';
import { FailedToast, SuccesToast, WarnToast } from '../../../../../utils/utilFunctions';
import FormSelect from '../../../../../common/components/FormSelect';
import PasswordValidation from '../../../../../common/components/PasswordValidation';
import { useAppDispatch, useAppSelector } from './../../../../../config/hooks';
import { fetch_webusers_request } from './../../../store/action';
import ButtonLoader from './../../../../../common/components/ButtonLoader';
import ErrorComponent from '../../../../../common/components/ErrorComponent';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any;
};



const schema = yup.object().shape({
	sRequestID: yup.number().required(),
	Status: yup.string().required(),
	LoginID: yup.string().email().required(),
	roleID: yup.string(),
	fK_WebUserType : yup.number().required()
});

const RequestModal = (props: Props) => {




	const { register, handleSubmit, formState : {errors}, reset, control, setValue, watch, clearErrors  ,} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	let dispatch = useAppDispatch();
	const SECURITY_DATA = useAppSelector((state) => state?.security);



	const [usersData, setusersData]               = useState<any>();
	const [passwordSettings, setpasswordSettings] = useState<any>();
	const [rolesList, setrolesList]               = useState<any>([]);
	const [password, setPassword]                 = useState<any>('');
	const [valid, setvalid]                       = useState(false);
	const [userTypes, setuserTypes] 			  = useState([])


	useEffect(() => {
		if (props.Data) {
			reset({
				roleID: props?.Data?.roleID,
			});
			
		}
	clearErrors()
	}, [clearErrors, props.Data, reset])


	const FetchRolesList = () => {
		let postData = {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit':200,
			},
		};
		fetch_rolelist_ddl(postData).then((res) => {
			let data = res?.response?.data ?? [];
			let role_list = data.map((item: any) => ({ label: item?.roleName, value: item?.roleID }));
			setrolesList(role_list);
		});
	};

	useEffect(() => {
		const getDefaultVal = (res: any) => {
			let Data = res?.response?.data ? res?.response?.data[0] : null;
			setpasswordSettings(Data);
		};
		password_policy('get', null, getDefaultVal, () => { });
		FetchRolesList();
	}, []);

	const FetchUser = async (key?: string) => {
		let postData = {
			'filters': {
				'query': key ? `(useruniqueID = ${key})` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		let Response = await search_user(postData);
		let dataArray = Response?.response?.data ?? [];
		let data = dataArray[0]
		setusersData(data);
		setValue('LoginID', data?.emailID)
		setValue('sRequestID', props?.Data?.sRequestID)
		setValue('Status', 1)
		setValue('ApproverRemarks', "Approved")

	};

	useEffect(() => {

		if (props?.Data?.requestedFor) {
			FetchUser(props?.Data?.requestedFor)
		}
	}, [props?.Data?.requestedFor])

	useEffect(() => {
		fetch_sys_userType()?.then((res)=>{
			let data = res?.response?.data ?? []
			let formattedData = data.map((item:any)=>({label :item?.typeName ,value : item?.sUserTypeID}))
			setuserTypes(formattedData)
		})
	
	}, [])



	console.log('Error', errors);

	const [loader, setloader] = useState(false)

	const onSubmitHandler = (data: any) => {
		console.log('SUBMITTED DATA', data);

	

		if (isCred === '1' && !valid && !props?.Data?.roleID) return WarnToast('Password not valid');
		setloader(true)

		let postData = {
			"sRequestID": data?.sRequestID,
			"Status": 1,
			"ApproverRemarks": "Approved",
			"LoginID": data?.LoginID,
			"LoginPassword":props?.Data?.roleID? "" : password,
			"roleID": Number(data?.roleID),
			"fK_WebUserType" : Number(data?.fK_WebUserType)
		};

		const Success = (res: any) => {
			setloader(false)

			SuccesToast(res?.response?.data);
			fetch_webusers_request(dispatch, {
				'filters': {
					'query': SECURITY_DATA?.WEBUSER_REQUEST_FILTER?.query,
				},
				'pagination': {
					'pageNo': SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.pageNo,
					'limit': SECURITY_DATA?.WEBUSER_REQUEST_PAGINATION?.limit,
				},
			});
			reset();
			props.handleClose();
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setloader(false)

		};

		if (props?.Data) {
			webusers_request_status('post', postData, Success, Failed);
		}
	};

	let isCred = watch('authenticationType') ?? '1';

	console.log(props?.Data?.roleID ,"props?.Data?.roleID ")


	return (
		<ModalLayout open={props.open} handleClose={()=>{ reset();	clearErrors();	props.handleClose(); }} title='Web user request' subtitle='User Management'>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className='row'>
					<div className='col-6'>
						<div className='form-group select-input full-label'>
							<label>App User ID</label>
							<input className="form-control" defaultValue={usersData?.userUniqueID} readOnly />
						</div>
					</div>
					<div className='col-6'>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group inline-input full-label'>
									<label>Name</label>
									<input className='form-control' readOnly defaultValue={usersData?.firstName} />
								</div>
							</div>
						</div>
					</div>

					<div className={'col-12' }>
						
						<div className={ props?.Data?.roleID ? 'form-group select-input opacity-half' : 'form-group select-input'}>
							<label>User Roles</label>
							<FormSelect
								control={control}
								name='roleID'
								options={rolesList}
								grey={true}
								isMulti={false}
								placeholder='select role'
							/>
						</div>
					</div>

					<div className='col-12 mt-10'>
						<div className='form-group inline-input w-100'>
							<div className='card-default' style={{ overflowY: 'auto', maxHeight: '30vh' }}>
								<div className='title'>
									<h3>Requested Zones</h3>
								</div>
								{/* <CheckboxTreeMap
									tree={tree}
									accessPreviledges={accessPreviledges}
									setAccessPreviledges={setAccessPreviledges}
								/> */}
								<div className='big-badge'>
									{/* <button className='big-badge-button'>Zone 1</button>
										<button className='big-badge-button'>Zone 2</button> */}
									{
										props?.Data?.zones && props?.Data?.zones.map((item: any, key: any) => {
											return (
												<button key={key} className='big-badge-button'>{item?.zoneName}</button>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>

					{/* <div className='col-12'>
						<div className='form-group inline-input'>
							<label>User Types</label>
							<input
								type=''
								className='form-control grey-input'
								defaultValue={usersData?.userType}
								disabled
								{...register('fK_WebUserType')}
							/>
						</div>
					</div> */}

					<div className='col-12'>
						<div className='form-group select-input'>
							<label>User Types</label>

							<FormSelect
								control={control}
								name='fK_WebUserType'
								options={userTypes}
								grey={true}
								isMulti={false}
								placeholder='user type'
							/>
						</div>
						<ErrorComponent errors={errors} name='fK_WebUserType' title='User type' />
					</div>

					<div className='col-12 mt-20'>
						<div className='form-group inline-input'>
							<label>Email Address</label>
							<input
								type=''
								className='form-control grey-input'
								defaultValue={usersData?.emailID}
								disabled
								{...register('emailID')}
							/>
						</div>
					</div>
{
props?.Data?.roleID  !== null? null : 
<>

					<div className='col-12'>
						<div className='form-group inline-input'>
							<label>Login Method</label>
							<div className='w-100'>
								<div className='row'>
									<div className='col-6'>
										<div className='borderbox bg-grey opacity-half'>
											<div className='radio'>
												<input type='radio' value='2' {...register('authenticationType')} disabled={true} />
												<label>
													<span>SSO</span>
												</label>
											</div>
										</div>
									</div>
									<div className='col-6'>
										<div className='borderbox bg-grey'>
											<div className='radio'>
												<input type='radio' value='1' {...register('authenticationType')} defaultChecked={true} />
												<label>
													<span>User Credential</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Fragment>
						{isCred === '1' && (
							<>
								<div className='col-12'>
									<div className='form-group inline-input'>
										<label>Password</label>
										<PasswordValidation
											value={password}
											setValue={setPassword}
											valid={valid}
											setvalid={setvalid}
											req_length={passwordSettings?.minimumPWdLength}
											grey={true}
											islowercase={passwordSettings?.requiredLowercaseLetter}
											isnumber={passwordSettings?.requeirdOneNumber}
											isuppercase={passwordSettings?.requirdUppercaseLetter}
											issymbol={passwordSettings?.requiredSymbolCharacter}
										/>
									</div>
								</div>
							</>
						)}
					</Fragment>
					</>
}
					<div className='col-12'>
						<div className='modal-footer'>
							<button type='reset' className='btn btn-outline-blue close-modal-button' onClick={()=>{ reset();	clearErrors();	props?.handleClose(); }}>
								Cancel
							</button>
							<button type='submit' className='btn btn-secondary'>
								<ButtonLoader text='Approve' loading={loader} />
							</button>
						</div>
					</div>
				</div>
			</form>
		</ModalLayout>
	);
};

export default RequestModal;
