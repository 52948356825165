import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import LinkTab from '../../common/components/LinkTab';
import NavTabs from '../../common/components/NavTabs';
import PermissionWrapper from '../../common/components/PermissionWrapper';
import { subModuleList } from '../../config/subModuleList';
import { useRedirect } from '../../utils/hooks/useRedirect';
import ApplicationSettings from './screens/application_settings/applicationSettings';
import CompanyProfile from './screens/company-profile/companyProfile';
import DataSync from './screens/data-sync/dataSync';
import License from './screens/license/license';
import { RESET_STATE } from './store/applicationSlice';

type Props = {};

const ApplicationSettingsIndex = (props: Props) => {
  const dispatch = useDispatch()
  useEffect(() => {
		return () => {
			dispatch(RESET_STATE())
		}
	}, [dispatch])
  const redirectedPath = useRedirect([
    {
      path: 'application-settings',
      submoduleID: subModuleList['Application Settings'],
    },
    {
      path: 'data-synchronization',
      submoduleID: subModuleList['Data Synchronization'],
    },
    {
      path: 'license',
      submoduleID: subModuleList.License,
    },
    {
      path: 'company-profile',
      submoduleID: subModuleList['Company Profile'],
    },
  ]);

  return (
    <div className='navtabs__container'>
      <NavTabs>
        <PermissionWrapper subModuleID={subModuleList?.['Application Settings']}>
          <LinkTab label='Application Settings' href='application-settings' />
        </PermissionWrapper>
        <PermissionWrapper subModuleID={subModuleList?.['Data Synchronization']}>
          <LinkTab label='Data Synchronization' href='data-synchronization' />
        </PermissionWrapper>
        <PermissionWrapper subModuleID={subModuleList?.License}>
          <LinkTab label='License' href='license' />
        </PermissionWrapper>
        <PermissionWrapper subModuleID={subModuleList?.['Company Profile']}>
          <LinkTab label='Company Profile' href='company-profile' />
        </PermissionWrapper>
      </NavTabs>
      <Routes>
        <Route
          path={'application-settings'}
          element={
            <PermissionWrapper subModuleID={subModuleList?.['Application Settings']}>
              <ApplicationSettings />
            </PermissionWrapper>
          }
        />
        <Route
          path={'data-synchronization'}
          element={
            <PermissionWrapper subModuleID={subModuleList?.['Data Synchronization']}>
              <DataSync />
            </PermissionWrapper>
          }
        />
        <Route
          path={'license'}
          element={
            <PermissionWrapper subModuleID={subModuleList?.License}>
              <License />
            </PermissionWrapper>
          }
        />
        <Route
          path={'company-profile'}
          element={
            <PermissionWrapper subModuleID={subModuleList?.['Company Profile']}>
              <CompanyProfile />
            </PermissionWrapper>
          }
        />

        <Route path={'/*'} element={<Navigate to={redirectedPath} />} />
      </Routes>
    </div>
  );
};

export default ApplicationSettingsIndex;
