import React, { useEffect } from 'react'
import PermissionWrapper from '../../common/components/PermissionWrapper';
import 'antd/dist/antd.min.css'
import Dashboard from './dashboard';
import { subModuleList } from '../../config/subModuleList';
import { useDispatch } from 'react-redux';
import { RESET_STATE } from './store/dashboardSlice';

const DashboardIndex = () => {

	const dispatch = useDispatch()
	useEffect(() => {
		  return () => {
			  dispatch(RESET_STATE())
		  }
	  }, [dispatch])
	
	return (
		<React.Fragment>
			<PermissionWrapper subModuleID={subModuleList.Dashboard}>
				<Dashboard />
			</PermissionWrapper>
		</React.Fragment>
	);
};
export default DashboardIndex;
