export const subModuleList = {
	'Find A Car': '1',
	'Allocated Parking': '2',
	'Real Time Monitoring': '3',
	'Availability Check': '4',
	'Parking History': '5',
	'Transactions': '6',
	'Parking Requests': '7',
	'Parking Violations': '8',
	'Blacklisted Vehicles': '9',
	'Report Violation': '10',
	'Users': '11',
	'Parking Policies': '12',
	'Violation Policies': '13',
	'Policy Assignment': '14',
	'Parking Zones': '15',
	'Notifications': '16',
	'Notification Exceptions': '17',
	'Notification Settings': '18',
	'Notification History': '19',
	'Application Logs': '20',
	'Audit Logs': '21',
	'Definition': '22', //changed
	'Application Settings': '23',//changed
	'Data Synchronization': '24',//changed
	'License': '25',//changed
	'Company Profile': '26',//changed
	'Roles': '27',//changed
	'Web User': '28',//changed
	'Web Users Request': '29',//changed
	'Password Policy': '30',//changed
	'Dashboard': '31',//changed
	'Reports & Dashboard': '32',//changed
	'Visitors': '33',//changed
	'Authenticaion': '34',//changed
	'Profile': '35',//changed
};
