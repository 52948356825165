import React from 'react'
import FilterBar from './components/FilterBar'
import MainLayout from './../../../../common/layouts/MainLayout';
import ListTable from './components/ListTable';

type Props = {}

const Transaction = (props: Props) => {
  return (
    <MainLayout>
        <FilterBar/>
        <ListTable />
    </MainLayout>
  )
}

export default Transaction