import React, { Fragment } from 'react'
import MainLayout from '../../../../common/layouts/MainLayout'
import FilterBar from './components/FilterBar'
import ListTable from './components/ListTable'
import DashIcons from './components/DashIcons';

type Props = {}

const ParkingViolations = (props: Props) => {
    return (
        <Fragment>
            <DashIcons />
            <MainLayout>
                <FilterBar />
                <ListTable />
            </MainLayout>
        </Fragment>

    )
}

export default ParkingViolations