import Request from '../../../config/api';
import {
	NOTIFICATION_EXCEPTION,
	NOTIFICATION_EXCEPTION_ACTION,
	NOTIFICATION_HISTORY,
	NOTIFICATION_SETTINGS,
	NOTIFICATION_SETTINGS_CONFIGURATION,
	NOTIFICATION_SETTINGS_SMTP,
	NOTIFICATION_TEMPLATE,
	NOTIFICATION_TEMPLATE_ACTION,
	NOTIFICATION_TEMPLATE_PARAMETERS,
	NOTIFICATION_SETTINGS_CONFIGURATION_ACTION,
	NOTIFICATION_SAVE_IMAGE,
	NOTIFICATION_EMAIL_TEST
} from '../../../config/endpoint';
import {
	UPDATE_NOTIFICATIONS_EXCEPTION_DATA,
	UPDATE_NOTIFICATIONS_EXCEPTION_ERROR,
	UPDATE_NOTIFICATIONS_EXCEPTION_LOADER,
	UPDATE_NOTIFICATIONS_HISTORY_DATA,
	UPDATE_NOTIFICATIONS_HISTORY_ERROR,
	UPDATE_NOTIFICATIONS_HISTORY_LOADER,
	UPDATE_NOTIFICATIONS_SETTINGS,
	UPDATE_NOTIFICATIONS_SETTINGS_CONFIGURATION,
	UPDATE_NOTIFICATIONS_TEMPLATE_DATA,
	UPDATE_NOTIFICATIONS_TEMPLATE_PARAMETERS,
} from './notificationSlice';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_notificationTemplate_list = (dispatch: any, postData: Object) => {
	let api_url = NOTIFICATION_TEMPLATE;
	Request('post', api_url, postData, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_NOTIFICATIONS_TEMPLATE_DATA({
					data: res?.response?.data ?? [],
				})
			);
		}
	});
};

export const fetch_emailParameters = (dispatch: any, id: number, suc: any) => {
	let api_url = NOTIFICATION_TEMPLATE_PARAMETERS + id + '/';
	return Request('get', api_url, '', undefined, suc)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			let data = res?.response?.data.map((item: any) => ({ value: item }));

			console.log('data', data);
			dispatch(
				UPDATE_NOTIFICATIONS_TEMPLATE_PARAMETERS({
					data: data ?? [],
				})
			);
		}
	});
};

export const fetch_notification_settings = (dispatch: any) => {
	let api_url = NOTIFICATION_SETTINGS;
	let postData = {
		'filters': {
			'query': '',
			'OrderBy': '',
		},
		'pagination': {
			'pageNo': 1,
			'limit': 10,
		},
	};
	Request('post', api_url, postData, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_NOTIFICATIONS_SETTINGS({
					data: res?.response?.data ?? {},
				})
			);
		}
	});
};


export const fetch_notification_settings_configuration = (dispatch: any) => {
	let api_url = NOTIFICATION_SETTINGS_CONFIGURATION;
	let postData = {
		'filters': {
		},

	};
	Request('post', api_url, postData, undefined)?.then((res) => {
		const { statusCode } = res;
		if (statusCode === 200) {
			dispatch(
				UPDATE_NOTIFICATIONS_SETTINGS_CONFIGURATION({
					data: res?.response?.data ?? [],
				})
			);
		}
	});
};

export const fetch_notification_templateDDL = async () => {
	let api_url = NOTIFICATION_SETTINGS_CONFIGURATION;
	let postData = {
		'filters': {
		},

	};
	return await Request('post', api_url, postData, undefined)
};


export const fetch_notification_history = (dispatch: any, postData: any, signal?: AbortSignal) => {
	dispatch(
		UPDATE_NOTIFICATIONS_HISTORY_LOADER({
			loader: true,
		})
	);
	let api_url = NOTIFICATION_HISTORY;
	Request('post', api_url, postData, signal)?.then((res) => {
		const { statusCode, response } = res;
		const { data, pagination, filters } = response;

		if (statusCode === 200) {
			dispatch(
				UPDATE_NOTIFICATIONS_HISTORY_DATA({
					data,
					pagination,
					filters,
				})
			);
		} else {
			dispatch(
				UPDATE_NOTIFICATIONS_HISTORY_ERROR({
					error: 'Some error occured',
				})
			)
			
		}
	});
};

export const fetch_notification_exception = (dispatch: any, postData: any, signal?: AbortSignal) => {
	dispatch(
		UPDATE_NOTIFICATIONS_EXCEPTION_LOADER({
			loader: true,
		})
	);
	let api_url = NOTIFICATION_EXCEPTION;
	Request('post', api_url, postData, signal)?.then((res) => {
		const { statusCode, response } = res;
		const { data, pagination, filters } = response;
		console.log('res',res)
		if (res?.statusCode && statusCode === 200) {
			dispatch(
				UPDATE_NOTIFICATIONS_EXCEPTION_DATA({
					data,
					pagination,
					filters,
				})
			);
		} else {
			dispatch(
				UPDATE_NOTIFICATIONS_EXCEPTION_ERROR({
					error: 'Some error occured',
				})
			);
		}
	});
};

export const update_notification_template = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = NOTIFICATION_TEMPLATE_ACTION;
	Request('put', api_url, postData, undefined, success, failed);
};

export const update_smtp_notification_settings = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = NOTIFICATION_SETTINGS_SMTP;
	Request('put', api_url, postData, undefined, success, failed);
};
export const update_general_notification_settings = (postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = NOTIFICATION_SETTINGS_CONFIGURATION_ACTION;
	Request('put', api_url, postData, undefined, success, failed);
};

export const update_notification_exception = (type: t, postData: any, success: (res: any) => void, failed: (res: any) => void) => {
	let api_url = NOTIFICATION_EXCEPTION_ACTION;
	Request(type, api_url, postData, undefined, success, failed);
};

export const delete_notification_exception_user = (type: t, postData: any, success?: (res: any) => void, failed?: (res: any) => void) => {
	let api_url = NOTIFICATION_EXCEPTION + "delete";
	Request(type, api_url, postData, undefined, success, failed);
};

export const notification_exception_detail = (id: number) => {
	let api_url = NOTIFICATION_EXCEPTION + id + '/';
	return Request('get', api_url, '', undefined)
};


export const save_image_texteditor = (postData: any) => {
	let api_url = NOTIFICATION_SAVE_IMAGE;
	return Request("post", api_url, postData);
};

export const test_email = (postData: any , sucess: ((res: any) => void) | undefined , failed: ((err: any) => void) | undefined) => {
	let api_url = NOTIFICATION_EMAIL_TEST;
	return Request("post", api_url, postData , undefined , sucess , failed);
};








