/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-querybuilder';
import QueryBModal from '../../../../../common/components/query_builder';

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { fetch_parking_violations_list, fetch_violation_count, fetch_violation_type_count } from '../../../store/action';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import ReactSelect from 'react-select';
import { fetch_siteDDL, fetch_violationTypeDDL } from '../../../../../common/general/action';
import { commonSearchQuery, FilterProps } from '../../../../../config/filterSearch';
import { DatePicker } from 'antd';
import useKeyboard from '../../../../../utils/hooks/useKeyboard';
const { RangePicker } = DatePicker;

type Props = {};

const FilterBar = (props: Props) => {
	const [advanceModal, setAdvanceModal] = useState(false);

	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);

	const [dateRange, setDateRange] = useState<any>([]);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [siteID, setSiteID] = useState('');
	const [plateSearch, setPlateSearch] = useState('');

	const [violationType, setViolationType] = useState<any>('');
	const [vStatus, setVStatus] = useState<any>('')
	const [userType, setUserType] = useState<any>('');

	const vcatArr = [
		{ label: 'Vehicle', value: 1 },
		{ label: 'User', value: 2 },
	];

	useEffect(() => {
		const controller = new AbortController();
		fetch_violationTypeDDL(dispatch, controller.signal);
		fetch_siteDDL(dispatch, controller.signal);
	}, [dispatch]);

	const VIOLATION_TYPE_LIST = useAppSelector((state) => state?.general);
	const PARKING_VIOLATION = useAppSelector((state) => state?.violations);
	const deployQueryFilter = (query: string) => {
		console.log('Query', query);
		fetch_parking_violations_list(navigate, dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.PARKING_VIOLATION_PAGINATION.limit,
			},
		});
		let postData = {
			'query': query,
		};

		fetch_violation_type_count(dispatch, postData);
		fetch_violation_count(dispatch, postData);
		advancemodalClose();
	};

	const clearSearch = () => {
		fetch_parking_violations_list(navigate, dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.PARKING_VIOLATION_PAGINATION.limit,
			},
		});
		let postData = {
			'query': '',
		};

		fetch_violation_type_count(dispatch, postData);
		fetch_violation_count(dispatch, postData);
		setDateRange([]);
		setSiteID('');
		setPlateSearch('');
		setViolationType('');
		setVStatus('')
	};

	const filterSearch = () => {
		let QueryInput: FilterProps[] = [
			{
				field: 'CAST (VDate AS date)',
				value: dateRange && dateRange?.length > 0 ? `'${dayjs(dateRange[0]).format('YYYY-MM-DD')}'` : null,
				operator: '>=',
			},
			{
				field: 'CAST (VDate AS date)',
				value: dateRange && dateRange?.length > 1 ? `'${dayjs(dateRange[1]).format('YYYY-MM-DD')}'` : null,
				operator: '<=',
			},
			{
				field: 'PlateNumber',
				value: plateSearch !== '' ? plateSearch : null,
				operator: 'contains',
			},
			{
				field: 'VStatus',
				value: vStatus !== '' ? vStatus : null,
				operator: '=',
			},
			{
				field: 'FK_ViolationType',
				value: violationType !== '' ? violationType : null,
				operator: '=',
			},
			{
				field: 'FK_UserType',
				value: userType !== '' ? userType : null,
				operator: '=',
			},
		];
		let postDataObj: any = {
			'filters': {
				'query': commonSearchQuery(QueryInput, 'AND'),
			},
			'pagination': {
				'pageNo': 1,
				'limit': PARKING_VIOLATION.PARKING_VIOLATION_PAGINATION.limit,
			},
		};
		if (siteID !== '') {
			postDataObj = { ...postDataObj, 'SiteID': siteID };
		}
		fetch_parking_violations_list(navigate, dispatch, postDataObj);
		let postData = {
			'query': `${commonSearchQuery(QueryInput, 'AND')} ${siteID!=='' ? commonSearchQuery(QueryInput, 'AND')!=='' ? ` AND fk_SiteID=${siteID}` : ` fk_SiteID=${siteID}` : ''}`,
		};

		fetch_violation_type_count(dispatch, postData);
		fetch_violation_count(dispatch, postData);
	};

	const fields: any[] = [
		// { name: 'ViolationID', label: 'ID' },
		{ name: 'CAST (VDate AS date)', label: 'Date', inputType: 'date' },
		{ name: 'VTime', label: 'Time', inputType: 'time' },
		{ name: 'platenumber', label: 'Plate Number' },
		{ name: 'vehicleCategory', label: 'Vehicle Category' },
		{ name: 'placeOfRegistration', label: 'Place of Registration' },
		{ name: 'FK_ViolationType', label: 'Violation Type' , inputType: 'select', values:VIOLATION_TYPE_LIST.VIOLATIONTYPE_DDL.map((item)=>({"name":item?.vType , "label" : item?.label})) },
		{ name: 'tsp.FK_UserType', label: 'User Type', inputType:'select' ,	values: [
			{ name: "1", label: "Employee" },
			{ name: "2", label: "Visitor" }
		  ] },
		// { name: 'FK_ReportedBay', label: 'Reported Bay ID'  , inputType:"number"},
		{ name: 'PenaltyLevelID', label: 'Penalty Level', inputType:'select' ,	values: [
			{ name: "1", label: "Level 1" },
			{ name: "2", label: "Level 2" },
			{ name: "3", label: "Level 3" }
		  ] },
		// { name: 'reportedBay', label: 'Reported Bay Name' , },

		{ name: 'vud2.FullName', label: 'Violated User'},
		{ name: 'vud2.EmailID', label: 'Violated User Email'},
		{ name: 'vud2.DepName', label: 'Violated User Department'},
		{ name: 'vud2.DesName', label: 'Violated User Designation'},
		{ name: 'vud2.ContactNo', label: 'Violated User Contact Number'},
		{ name: 'FK_PriorityLevel', label: 'Violated User Personal Grade'},

	];
	console.log('VIOLATIONTYPE=>', violationType)

	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);

	return (
		<div className='search-filter-row' ref={ref}>

			<ul>
				<li>
					<a className='active pointerDefaultSet'>
						<i className='fa-solid fa-magnifying-glass'></i> Quick Search
					</a>
				</li>
				<li>
					<a onClick={() => advancemodalOpen()}>
						<i className='fa-solid fa-filter'></i>Advanced Filter
					</a>
				</li>
				{(PARKING_VIOLATION.PARKING_VIOLATION_FILTER?.query !== '' ||
					(PARKING_VIOLATION.PARKING_VIOLATION_SITE && PARKING_VIOLATION.PARKING_VIOLATION_SITE !== '')) && (
						<li>
							<a onClick={clearSearch}>
								<i className='fa-solid fa-times'></i>Clear All Filters
							</a>
						</li>
					)}
			</ul>

			<div className='filter-block' id='quick-search'>
				<div className='row'>
					<div className='col'>
						<div className='form-group'>
							<div className='pos-rel'>
								<i className='fa-solid fa-magnifying-glass'></i>

								<input
									type='text'
									name=''
									placeholder='Plate Number'
									className='form-control'
									value={plateSearch}
									onChange={(e) => {
										let v = e.target.value.replace(/[^a-zA-Z0-9\s@_.-]/g, '');
										setPlateSearch(v)
									}}
								/>
							</div>
						</div>
					</div>
					<div className='col'>
						<div className={`form-group`}>
							{/* <DatePicker
        							selected={startDate}
        							onChange={(dates:any) =>{
										const [start, end] = dates;
										setStartDate(start)
										setEndDate(end)
									}}
        							selectsStart
									dateFormat={'dd-MMM-yyyy'}
									className='form-control'
        							startDate={startDate}
        							endDate={endDate}
									selectsRange
									placeholderText="Violation Date Between"
									monthsShown={2}
									isClearable={startDate!==''}
      							/> */}
							<RangePicker
								picker='date'
								allowClear
								allowEmpty={[true, true]}
								className='form-control'
								placeholder={['Violated', ' Between']}
								separator
								value={dateRange}
								format={'DD-MMM-YYYY'}
								onChange={(dates: any) => {
									setDateRange(dates);
								}}
								inputReadOnly
							/>
						</div>
					</div>

					<div className='col'>
						<div className='form-group'>
							<ReactSelect
								options={[
									{ value: 1, label: 'Employee' },
									{ value: 2, label: 'Visitor' },
								]}
								className={'common-select'}
								placeholder={'User Type'}
								isMulti={false}
								isClearable
								value={userType ? [
									{ value: 1, label: 'Employee' },
									{ value: 2, label: 'Visitor' },
								].find((c) => c?.value === userType) : null}
								onChange={(val) => {
									setUserType(val?.value);
								}}
							/>
						</div>
					</div>
					{/* <div className='col'>
							<div className='form-group'>
								<input type='text' name='' placeholder='Vehicle User' className='form-control' value={userSearch} onChange={(e)=> setUserSearch(e.target.value) } />
							</div>
						</div> */}
					<div className='col'>
						<div className='form-group'>
							<ReactSelect
								options={VIOLATION_TYPE_LIST.SITE_DDL}
								className={'common-select'}
								placeholder={'Site'}
								isMulti={false}
								isClearable
								value={siteID ? VIOLATION_TYPE_LIST.SITE_DDL.find((c) => c?.value === siteID) : null}
								onChange={(val) => {
									setSiteID(val?.value);
								}}
							/>
						</div>
					</div>

					<div className='col'>
						<div className='form-group'>
							<ReactSelect
								options={VIOLATION_TYPE_LIST.VIOLATIONTYPE_DDL}
								className={'common-select'}
								placeholder={'Violation Type'}
								getOptionValue={(o: any) => o.vType}
								getOptionLabel={(o) => {
									return `${o.label} - ${o.value === "0" ? 'System' : o.value === "1" || o.value === 1 ? 'Vehicle' : 'User'}`;
								}}
								isMulti={false}
								isClearable
								value={violationType ? VIOLATION_TYPE_LIST.VIOLATIONTYPE_DDL.find((c) => c.vType === violationType) : null}
								onChange={(val) => {
									setViolationType(val?.vType);
								}}
							/>
						</div>
					</div>
					<div className='col'>
						<div className='form-group'>
							<ReactSelect
								options={[
									{label : 'Active', value : 1},
									{label : 'Inactive', value : 2}
								]}
								className={'common-select'}
								placeholder={'Violation Status'}
								
								isMulti={false}
								isClearable
								value={vStatus ? [
									{label : 'Active', value : 1},
									{label : 'Inactive', value : 2}
								].find((c) => c.value === vStatus) : null}
								onChange={(val) => {
									setVStatus(val?.value);
								}}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='form-group'>
							<button
								onClick={() => {
									filterSearch();
								}}
								className='form-control blue-btn'>
								Search
							</button>
						</div>
					</div>
				</div>
			</div>
			<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
		</div>
	);
};

export default FilterBar;
