import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../config/hooks';
import { COLORS } from '../../../../utils/Data';
import { fetch_totalInOut } from '../../store/action';
import PieChartIndex from '../PieChart';
import { DatePicker } from 'antd';
import DashboardFilterWrapper from '../DashboardFilterWrapper';
import Select from 'react-select';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

type Props = {
	name: string;
};

const { RangePicker } = DatePicker;


// let typeOption = [
// 	{
// 		label: 'Floor Wise',
// 		value: 1,
// 	},
// 	{
// 		label: 'Zone Wise',
// 		value: 2,
// 	},
// 	{
// 		label: 'Bay type Wise',
// 		value: 3,
// 	},
// ];

const style = {
	// fontSize: '12px',
	// fontWeight: 500,
	// fontStyle: 'italics',
	textDecoration: "underline"
};

const TotalInOut = (props: Props) => {
	const [data, setdata] = useState([]);
	const [data2, setdata2] = useState([]);
	// const [color, setcolor] = useState(COLORS1);

	const [type, settype] = useState(1);
	const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);
	const [date, setdate] = useState<any>([moment().subtract(1, 'day').startOf('day'), moment().endOf('day')]);

	const [floor, setfloor] = useState<any>(0);
	const [loading, setloading] = useState(false);

	const [totalIN, settotalIN] = useState(0);
	const [totalOUT, settotalOUT] = useState(0);

	const [switchPie, setSwitchPie] = useState(false)
	console.log("Floor" , floor)

	// useEffect(() => {
	// 	setfloor(DASHBOARD_DATA?.SELECTED_FLOOR?.flRefrenceID);
	// }, [DASHBOARD_DATA?.SELECTED_FLOOR]);

	const [drillData, setdrillData] = useState<any>();

	// let totalIN = 0;
	// let totalOUT = 0;
	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		settotalOUT(0)
		settotalIN(0)

		let postData = {
			Type: type,
			SiteID: DASHBOARD_DATA?.SELECTED_SITE?.value,
			FloorID: floor,
			FromDate: date[0].format('YYYY-MM-DD'),
			ToDate: date[1].format('YYYY-MM-DD'),
			FromTime: date[0].format('HH:mm:00'),
			ToTime: date[1].format('HH:mm:00'),

			// "Type":2,
			// "SiteID":1,
			// "ToDate":"2022-09-11",
			// "ToTime":"09:10:00",
			// "FloorID":1,
			// "FromDate":"2011-07-11",
			// "FromTime":"07:10:00"
		};
		// if (floor) {
			setloading(true);

			fetch_totalInOut(postData, signal)?.then((res) => {
				if (res?.statusCode < 400) {
					let data = res?.response?.data ?? [];
					let formattedData = data.map((item: { type: any; incount: any; outCount: any ;typeID:any }) => {

						// totalIN += item?.incount;
						settotalIN((prev) => prev += item?.incount)
						settotalOUT((prev) => prev += item?.outCount)

						// totalOUT += item?.outCount;
						return { name: item?.type, value: item?.incount , floorID : item?.typeID};
					});
					let formattedData2 = data.map((item: { type: any; incount: any; outCount: any ;typeID:any }) => {
						return { name: item?.type, value: item?.outCount , floorID : item?.typeID};
					});
					setdata(formattedData);
					setdata2(formattedData2)
					setTimeout(() => {
						setloading(false)
					}, 1500);

					console.log("pir", formattedData)
				}
			});



			let timer = setInterval(() => {
				fetch_totalInOut(postData, signal)?.then((res) => {
					if (res?.statusCode < 400) {
						let data = res?.response?.data ?? [];
						let formattedData = data.map((item: { type: any; incount: any; outCount: any ;typeID:any }) => {
	
							// totalIN += item?.incount;
							settotalIN((prev) => prev += item?.incount)
							settotalOUT((prev) => prev += item?.outCount)
	
							// totalOUT += item?.outCount;
							return { name: item?.type, value: item?.incount , floorID : item?.typeID};
						});
						let formattedData2 = data.map((item: { type: any; incount: any; outCount: any ;typeID:any }) => {
							return { name: item?.type, value: item?.outCount , floorID : item?.typeID};
						});
						setdata(formattedData);
						setdata2(formattedData2)
						setTimeout(() => {
							setloading(false)
						}, 1500);
	
						console.log("pir", formattedData)
					}
				});
			}, 120000);



		// }
		return () => {
			controller.abort();
			clearInterval(timer);
		};
	}, [DASHBOARD_DATA?.SELECTED_SITE?.value, type, floor, date]);

	const drill = (e: any) => {
		console.log('drill',e)
		if(type===2) return

		setfloor(e?.payload?.payload?.floorID)

		setdrillData((prev: any) => (prev ? type === 3 ? prev : prev + '/' + e.name : e.name));
		settype((prev) => (prev < 3 ? prev + 1 : prev));
	};


	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
		setfloor(0)
	};

	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>
						{props.name} by {type === 1 ? 'Floor' : type === 2 ? 'Zones' : 'Bay type'}
					</div>
					<div>
						{/* <div style={{ fontSize :"12px"}}>
							{
								date[0].format('DD MMM') } - { date[1].format('DD MMM ') 
							}
						</div> */}
						<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
							{/* <div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Type</label>
								<Select
									className='common-select-grey'
									options={typeOption}
									onChange={(_: any ,option:any) => settype(option)}
									value={type?.value}
									placeholder='type'
									style={{ width:"100%"}}
									size='large'

								/>
							</div> */}
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker size='large' showTime onChange={(date: any) => setdate(date)} value={date} allowClear={false} inputReadOnly />
							</div>
							{/* <div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Floor</label>
								<Select
									className='common-select-grey'
									options={DASHBOARD_DATA?.FLOOR_LIST}
									getOptionLabel={(o: any) => o.levelName}
									getOptionValue={(o: any) => o.levelID}
									onChange={(e: any) => setfloor(e)}
									value={DASHBOARD_DATA?.FLOOR_LIST.find((c: { levelID: number }) => c.levelID === floor?.levelID) ?? ''}
									placeholder='Floor'
								/>
							</div> */}
						</DashboardFilterWrapper>
					</div>
				</div>
				<div className='ItemLayout__Content'>
					<div className='dflex align-items-center justify-space-between'>

						<p className='label-style text-right text-hover' style={style}>{drillData}</p>
						{
							type !== 1 &&

							<p className='label-style text-right text-hover' style={style} onClick={() => {
								settype(1);
								setdrillData(undefined)
							}}>
								Reset
							</p>
						}
						

					</div>
					<div className='form-group inline-input pieChart-slider' style={{ justifyContent : 'flex-end' }}>
						<label className="switch" style={{ marginRight : 0 }}>
							<input type="checkbox" checked={switchPie}  onClick={()=>{ 
								setSwitchPie(!switchPie);								 
								}}/>
							<span className="slider"></span>
							<span className="enable-text">Total In</span>
						</label>
					</div>
					{
						((totalIN + totalOUT) === 0) ?

							<>
								<ResponsiveContainer width='100%' height='100%'>
									<PieChart>

										<Pie data={[{ name: 'NO DATA', value: 1 }]}
											fill='#eeeeee' dataKey='value' />
									</PieChart>

								</ResponsiveContainer>

							</>
							:
								switchPie ? 
								<PieChartIndex data={data2} COLORS={COLORS} func={drill}/>
								:
								<PieChartIndex data={data} COLORS={COLORS} func={drill} />

					}

					<div className='dashboard-details'>
						<ul className='parking-list'>
							<li>
								Total in{' '}
								<span className='text-right'>
									{totalIN}
									{/* {isNaN((totalIN / (totalIN + totalOUT)) * 100) ? 0 : Math.round((totalIN / (totalIN + totalOUT)) * 100)}% */}
								</span>
							</li>
							<li>
								Total Out{' '}
								<span className='text-right'>
									{
										totalOUT
									}
									{/* {isNaN((totalOUT / (totalIN + totalOUT)) * 100) ? 0 : (Math.round(totalOUT / (totalIN + totalOUT)) * 100)}% */}
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default TotalInOut;
