/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import AddModal from './AddModal';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { fetch_visitorlist } from '../../store/action';
import { Field } from 'react-querybuilder';
import ReactSelect from 'react-select';
import { fetch_departmentDDL, fetch_designationDDL, fetch_priorityLevelDDL } from '../../../../common/general/action';
import QueryBModal from '../../../../common/components/query_builder';
import { commonSearchQuery, FilterProps } from '../../../../config/filterSearch';
import PermissionWrapper from '../../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../../config/subModuleList';
import useKeyboard from '../../../../utils/hooks/useKeyboard';

const FilterBar = () => {
	const dispatch = useAppDispatch();
	const [addModal, setAddModal] = useState(false);
	const [advanceModal, setAdvanceModal] = useState(false);

	const [userType, setUserType] = useState<any>('');
	const [userSearch, setUserSearch] = useState<any>('');
	const [priority, setPriority] = useState<any>('');
	const [designation, setDesignation] = useState<any>('');
	const [department, setDepartment] = useState<any>('');

	const advancemodalOpen = () => setAdvanceModal(true);
	const advancemodalClose = () => setAdvanceModal(false);
	const addModalOpen = () => setAddModal(true);
	const addModalClose = () => setAddModal(false);
	const USERS_DATA = useAppSelector((state) => state?.visitor);
	const GENERAL_DDL = useAppSelector((state) => state?.general);

	useEffect(() => {
		const controller = new AbortController();
		// fetch_departmentDDL(dispatch, controller.signal);
		fetch_priorityLevelDDL(dispatch, controller.signal);
		// fetch_designationDDL(dispatch, controller.signal);
		return () => controller.abort();
	}, []);

	// const search = (key: string) => {

	// 	const controller = new AbortController();
	// 	const signal = controller.signal;
	// 	fetch_visitorlist('', dispatch, {
	// 		'filters': {
	// 			'query': key ? `(firstName like '%${key}%') or (emailID like '%${key}%')` : '',
	// 		},
	// 		'pagination': {
	// 			'pageNo': 1,
	// 			'limit': 10,
	// 		},
	// 	}, signal);
	// }

	const fields: Field[] = [
		// { name: 'VisitorID', label: 'Visitor ID' },
		{ name: 'FirstName', label: 'FIRST NAME' },
		{ name: 'EmailID', label: 'EMAIL' },
		{ name: 'ContactNo', label: 'CONTACT No' },
		// { name: 'DOB', label: 'DATE OF BIRTH', inputType: 'date' },
		// { name: 'CAST (CreatedOn as smalldatetime)', label: 'CREATED DATE', inputType: 'datetime' },
		{ name: 'CAST (CreatedOn as date)', label: 'CREATED DATE', inputType: 'datetime' },

	];

	const deployQueryFilter = (query: string) => {
		fetch_visitorlist('', dispatch, {
			'filters': {
				'query': query,
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_DATA.VISITOR_PAGINATION.limit,
			},
		});
		advancemodalClose();
		setUserType('');
		setUserSearch('');
		setPriority('');
		setDepartment('');
		setDesignation('');
	};

	const clearSearch = () => {
		fetch_visitorlist('', dispatch, {
			'filters': {
				'query': '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_DATA.VISITOR_PAGINATION.limit,
			},
		});
		setUserSearch('')
	};

	const filterSearch = () => {
		let QueryInput: FilterProps[] = [
			{
				field: 'FirstName',
				value: userSearch,
				operator: 'contains',
			},
			{
				field: 'companyName',
				value: userSearch,
				operator: 'contains',
			},
			{
				field: 'EmailID',
				value: userSearch,
				operator: 'like',
			},
		
		];

		fetch_visitorlist('', dispatch, {
			'filters': {
				'query': commonSearchQuery(QueryInput, 'OR'),
			},
			'pagination': {
				'pageNo': 1,
				'limit': USERS_DATA.VISITOR_PAGINATION.limit,
			},
		});
	};

	const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

	useKeyboard('Enter', filterSearch, ref);

	useKeyboard('Escape', clearSearch, ref);
	

	return (
		<>
						<div className='search-filter-row' ref={ref}>

				<div className='row'>
					<div className='col-8'>
						<ul>
							<li>
								<a className='active pointerDefaultSet'>
									<i className='fa-solid fa-magnifying-glass'></i> Quick Search
								</a>
							</li>
							<li>
								<a onClick={() => advancemodalOpen()}>
									<i className='fa-solid fa-filter'></i>Advanced Filter
								</a>
							</li>
							{USERS_DATA.VISITOR_FILTER?.query !== '' && (
								<li>
									<a onClick={clearSearch}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
						</ul>
					</div>
					<PermissionWrapper subModuleID={subModuleList.Visitors} type='isCreate' >
					<div className='col-4 text-right'>
						<a className='btn btn-secondary ' onClick={() => addModalOpen()}>
							Add New Visitor
						</a>
					</div>
					</PermissionWrapper>
				</div>

				<div className='filter-block' id='quick-search'>
					<div className='row'>
						<div className='col'>
							<div className='form-group'>
								<input
									type='text'
									name=''
									placeholder='Search'
									className='form-control'
									value={userSearch}
									onChange={(e) => setUserSearch(e.target.value)}
								/>
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<button
									onClick={() => {
										filterSearch();
									}}
									className='form-control blue-btn'>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
				<QueryBModal open={advanceModal} handleClose={advancemodalClose} tableFields={fields} setQuery={deployQueryFilter} />
			</div>
			<AddModal open={addModal} handleClose={addModalClose} />
		</>
		// <div className="search-row-button">
		// 	<div className="row">
		// 		<div className="col-3">
		// 				<div className="form-group">
		// 					<div className="pos-rel">
		// 						<input className="form-control grey-input" name="" placeholder="Search Here" onChange={(e) => search(e.target.value)} />
		// 						<i className="fa-solid fa-magnifying-glass"></i>
		// 					</div>
		// 				</div>
		// 		</div>
		// 		<div className="col-9 text-right">
		// 			<a className="btn btn-secondary btn-w-icon modal-button" onClick={() => addModalOpen()}>Add New User</a>
		// 		</div>
		// 	</div>
		// 	{addModal && <AddModal open={addModal} handleClose={addModalClose} />}

		// </div>
	);
};

export default FilterBar;
