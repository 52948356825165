import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom';
import { fetch_BayTypeDDL, fetch_floorLevelDDL, fetch_zoneDDL } from '../../../../../common/general/action';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { definiton_add_edit, fetch_definitionList } from '../../../store/action';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any
};

type ddl = {
    label: string;
    value: any;
};

const AddModal = (props: Props) => {

	const [DefId, setDefId] = useState('-')
	const [DefName, setDefName] = useState('')
	const [DefZone, setDefZone] = useState<ddl[]>([])
	const [DefBayTypeId, setDefBayTypeId] = useState('')
	const [DefSiteId, setSiteId] = useState('')
	const [DefLevelId, setLevelId] = useState('')
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState({
		name : false,
		zone : false,
		bay : false,
	})

	let navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		// fetch_zoneDDL(dispatch)
		fetch_BayTypeDDL(dispatch)
		
	  if(props.Data){
		setDefId(props.Data?.bayID??'-')
		setDefName(props.Data?.bayName??'')

		setSiteId(props.Data?.fK_SiteID??'')
		setLevelId(props.Data?.fK_LevelID??'')

		setDefBayTypeId(props.Data?.fK_BayTypeID??'')
		setDefZone([{'value' : props.Data?.fK_ZoneID??'','label':props.Data?.zoneName??''}])
	  }
	}, [props.Data])
	const SITELIST = useAppSelector((state) => state?.general)
	const DEFINITION_DATA = useAppSelector((state) => state?.definition)

	const setLevelSite = (val:any) => {
		if(val?.site){
			setSiteId(val?.site)
			
		}if(val?.level)
			setLevelId(val?.level)
	}

	const DefinitonActionFx = () =>{
		setLoading(true)

		let postdata:any = props.Data ? {
            name: DefName,
			ID : DefId,
			IsEnable : props.Data.isEnable,
			FK_ID : DefZone[0].value,
			FK_BayTypeID : DefBayTypeId,
			FK_SiteID : DefSiteId,
			FK_LevelID : DefLevelId,
			ReferenceID : props.Data ? props.Data.pbRefrenceID : 0
		} :
		{
			name: DefName,
			IsEnable : true,
			FK_ID : DefZone[0].value,
			FK_BayTypeID : DefBayTypeId,
			FK_SiteID : DefSiteId,
			FK_LevelID : DefLevelId,
			ReferenceID : 0
		}
		const Success = (res:any) => {
			setLoading(false)

			SuccesToast(res?.response?.data)
			setDefId('-')
			setDefName('')
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": ""
					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                'parkingbay'
			);
			props.handleClose()
		}
		const Failed = (err:any) => {
			setLoading(false)

			FailedToast(err?.response)		
		}

		if(props.Data){
			definiton_add_edit('put',postdata,'parkingbay',Success,Failed)
		}
		else{
			definiton_add_edit('post',postdata,'parkingbay',Success,Failed)
		}

	}

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title={`${props.Data ? 'Edit' : 'Add'} New Parking Bay`} subtitle={DefId!=='-' ? `#${DefId}` : 'Definitions'}>
				<div className="row">
					{/* {DefId!=='-' &&
					<div className="col-3">
						<div className="form-group inline-input full-label">
							<label>Parking Bay ID</label>
							<input type="text" name='' placeholder='Parking Bay Id Autogenerated' value={DefId} disabled className="form-control grey-input" />
						</div>
					</div>} */}
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Parking Bay Name</label>
							<input type="text" name='' value={DefName} onChange={(e) => setDefName(e.target.value)}
								className="form-control grey-input" />
								{	error.name &&
									<p className='hookform-error-message'>A valid Name is required</p>}
						</div>
					</div>
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Zone</label>
							<TreeSelectCustom	grey 
								selectedZones={DefZone}	setselectedZones={setDefZone}	
								classnames={`w-100`}
								styleSx={{ maxWidth:'unset' }}
								
								showAll={setLevelSite}	isMulti={false}
							/>
							{/* <select className="form-control grey-input" value={DefZone} onChange={(e)=> setDefZone(e.target.value)}>
								{
									SITELIST.ZONE_DDL.map((el:any) => {
										return(
											<option value={el.value}>{el.label}</option>
										)
									})
								}
							</select> */}
							{	error.zone &&
									<p className='hookform-error-message'>A Zone is required</p>}
						</div>
					</div>
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Bay Type</label>
							<ReactSelect
									options={SITELIST?.BAYTYPE_DDL}
									className={'common-select common-select-grey col-12'}
									placeholder={'Select Site'}
									isMulti={false}
									value={SITELIST?.BAYTYPE_DDL?.find((c: any) => c.value === DefBayTypeId)}
									onChange={(val: any) => {
										setDefBayTypeId(val?.value)
									}}
								/>
							
							{/* <select className="form-control grey-input" value={DefBayTypeId} onChange={(e)=> setDefBayTypeId(e.target.value)}>
								{
									SITELIST.BAYTYPE_DDL.map((el:any) => {
										return(
											<option value={el.value}>{el.label}</option>
										)
									})
								}
								<option value={''}>Select a Bay Type</option>
							</select> */}
							{	error.bay &&
									<p className='hookform-error-message'>A Bay is required</p>}
						</div>
					</div>
					
					<div className="col-12">
						<div className="modal-footer">
							<button type="button" className="btn btn-outline-blue close-modal-button" onClick={props.handleClose}>Cancel</button>
							<button type="submit" className="btn btn-secondary" onClick={()=>{
								if( DefName!=='' && DefBayTypeId!=='' && (DefZone && DefZone.length!==0)){
									setError({
										name : false, zone : false, bay : false
									})	
									DefinitonActionFx()
								}else{
									setError({
										name : DefName==='',
										zone : DefZone && DefZone.length===0,
										bay  : DefBayTypeId==='',
									})
								}
							}}>
								<ButtonLoader text={`${props.Data ? 'Edit' : 'Add'} Bay`} loading={loading}/>
							</button>

						</div>	
					</div>
				</div>
		</ModalLayout>
	);
};

export default AddModal;
