import React, { useCallback, useState } from 'react'
import CsvDownload from 'react-csv-downloader'
import { Bar, BarChart, CartesianGrid, Label, Legend, Line, LineChart,
    ResponsiveContainer, 
    Tooltip, XAxis, YAxis } from 'recharts'
import GraphLoader from '../../../common/components/GraphLoader'
import { ExportMultipleChartsToPdf } from './PdfExporter'
import { useAppSelector } from '../../../config/hooks'  
import { COLOR_FX } from '../../../utils/Data'
import { useCurrentPng } from 'recharts-to-png'
import PdfExporterModal from './pdfExporterModal'
import { ZoneColors } from '../../../utils/zoneColorCodes'
import { exportDataToExcel } from '../../../common/components/ExcelFileExport'


type Props = {
    title: string,
    data: any,
    line?: number,
    loader?:boolean,
    labelY?:string,
    labelX?:string,
    unitY?:string,
    unitX?:string,
    noDownload?: boolean,
    fileName?:string,
    extraFunc?:any,
    titleArr?:any,
    yTickFormaterFn?:any,
    csvColumns?:any
}

// const colorPallet = COLORS
// ['#1947ba', '#ea0029', '#00953b', '#f39c12', '#8e44ad', '#f368e0', '#222f3e', '#84817a', '#33d9b2', '#f9d71b'] 
const legendDiv = {
    fontWeight : 600,
    borderRadius : '5px',
    padding: '1%',
    backgroundColor: 'rgba(0,0,0,0.05)',
    left:0,
    width:'100%',
    alignItems:'center'
} 

const BarGraphCard = (props: Props) => {
    const [opacitySet, setOpacitySet] = useState(null)
    const [openExport, setOpenExport] = useState('')
    const [openModal, setOpenModal] = useState(false)

    const img = useAppSelector((state) => state?.applicationSettings?.COMPANY_PROFILE?.photo);
    const [getAreaPng, { ref: areaRef }] = useCurrentPng();

  const handleAreaDownload = () => {
    setOpenModal(true)
  }
  // useCallback(async () => {
  //   const png = await getAreaPng();
  //   if (png) {
  //     ExportMultipleChartsToPdf({ 
  //       val: `BarChartId-${props.title}`, 
  //       pgtitle: `${props.title} ${props?.fileName}`, 
  //       headerimg: img,
  //       pgTitleArr: props?.titleArr??[],
  //       chartImage : png
  //        })
  //   }
  // }, [getAreaPng]);



    const handleMouseEnter = (o:any) => {
        const { dataKey } = o    
        if(!openModal)
          setOpacitySet(dataKey);
      };
    
    const handleMouseLeave = () => {
      if(!openModal)
        setOpacitySet(null)
      };

    const CustomToolTip = ({ active, payload, label }:any) =>{

        if(active && payload && payload?.length){
          console.log('PROPS=>',active,'payload=>',payload,'label=>',label)
  
          const LabelFormatter = () =>{ 
            //console.log('labelpayload',payload); 
           let v:any = ''
           if(payload && payload?.length>0 ? payload[0].payload?.toolTipLabel && payload[0].payload?.toolTipLabel?.length>0 ? true : false : false)
              v = <p>{label} <br/>{payload[0].payload?.toolTipLabel?.map((el:any)=>{
                return <>{el}<br/></>
              })}</p>
            else
             v = label
           return v
          }
  
          const valueFormatter = (val?:any,obj?:any) =>{
            let v:any = ''
              console.log(payload,obj,obj  && obj?.payload[`${obj?.name}-toolTipLabel`])
              if(obj  && obj?.payload[`${obj?.name}-toolTipLabel`] ? obj?.payload[`${obj?.name}-toolTipLabel`] : false)
                v = <>{obj?.payload[`${obj.name}-toolTipLabel`]}<span
                style={{ whiteSpace : 'nowrap' }}>{` (${props?.extraFunc ? props?.extraFunc(val) : val} ${props?.unitY??''})`}</span></>
              else v = props?.extraFunc ? `${props?.extraFunc(val)} ${props?.unitY??''}` : `${val} ${props?.unitY??''}`
              console.log('VLA',v)
              return v
          }
  
  
          return (
            <div className='customToolTipBox'>
              <div className='customToolTipLabel'>
                {LabelFormatter()}
              </div>
              <div className='customToolTipContents'>
                {
                  payload && payload?.length>0 ?
                    payload?.map((el:any)=>{
                      if(!el?.dataKey?.includes('csvlabel'))
                      return (
                        <div
                        style={{ color: `${el?.color}`, fontSize:'5px !important'}}
                        >
                          <p>{`${el?.dataKey} : `}{valueFormatter(el?.value,el)}</p>
                        </div>
                      )
                    })
                    : null
                }
              </div>
            </div>
          )
        }else 
          return null
      }

      const ChartObject = () =>{
        let colV = 0;
        console.log('OCCUPANCYHISTORICAL',props)
        return (
          <>
            <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" padding={{ left: 20, right:20}} allowDataOverflow={true} 
                        interval={props.data && props.data.length > 10 ? 2 : 0}
                        
                        style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"9.5px" }}/>
                    <YAxis  padding={{ top:20 }} style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
                      unit={props?.unitY}  
                      tickFormatter={props?.yTickFormaterFn??props?.extraFunc??null}
                      className='customBarGraphLabelAdjust'
                      //label={{ value: props?.labelY, angle: -90, fontWeight:600 , fontSize:"11.5px", positon:'insideLeft'}}
                    >
                      <Label value={props?.labelY} angle={-90} fontWeight={600} fontSize={"11.5px"} position={'insideLeft'} offset={-5} />
                    </YAxis>
                    {
                      openModal ? null
                      :
                    <Tooltip isAnimationActive={false}  
                    wrapperClassName='toolTipCustomClass'
                        formatter={(label:any, _:any, payload:any) =>
                            {
                                // console.log('FORMATERPAYLOAD',payload,payload.payload[`${payload.name}-toolTipLabel`],label,props)
      
                                let v:any = ''
                                if(payload && payload.payload[`${payload.name}-toolTipLabel`] 
                                    ? payload.payload[`${payload.name}-toolTipLabel`] : false)
                                  v = <>{label + `${props?.unitY??''}`}<p>{payload.payload[`${payload.name}-toolTipLabel`]}</p></> 
                                else v = label + `${props?.unitY??''}`
                                return v
                                
                            }
                            
                        }
                        
                        labelFormatter={
                            (label:any, payload) =>{ 
                                //console.log('labelpayload',payload); 
                               let v:any = ''
                               if(payload && payload?.length>0 ? payload[0].payload?.toolTipLabel && payload[0].payload?.toolTipLabel?.length>0 : false)
                                  v = <p>{label} <br/>{payload[0].payload?.toolTipLabel?.map((el:any)=>{
                                    return <>{el}<br/></>
                                  })}</p>
                                else v = label
                               return v
                              }
                        }
                        content={<CustomToolTip/>}
                    />
                    
                    }
                    <Legend wrapperStyle={legendDiv} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ textTransform :"uppercase"}}/>
                    {
                        props.data && Object.keys(props.data[0]) ? Object.keys(props.data[0]).map(el =>{ 
                            if(el!=='name' && el!=='toolTipLabel' && !el?.includes('-toolTipLabel')&& !el?.includes('csvlabel')){
                            return (
                                <Bar dataKey={el} 
                                opacity={opacitySet ? opacitySet===el ? 1 : 0.1 : 1 }
                                fill={ZoneColors(el)??COLOR_FX(colV++)} />
                            )
                        }
                            else return null
                        }) 
                        :   null
                    }
          </>
        )
      }
      
    return(
        <div className={`col-6 ${!props?.loader&&props?.data&&props?.data?.length===0 ? 'disableAndOpacity2' : ''}`}>          
        <div className="card-second">
            <div className="card-header">
                <h3>{props.title}</h3>
                    {   props.noDownload ? null :

                        <div className='btn btn-sqaure btn-secondary btn-secondary-hover openMiniModalLine'
                        onClick={() =>{ setOpenExport(openExport===props.title ? '' : props.title) }}>
					        <i className='fa-solid fa-download' ></i>
					          	{	openExport===props.title ?
					          	<div className='miniModalOpenBox'>
					          		<div className='miniBoxModalContent'>
					          			<div onClick={handleAreaDownload} role="button">
                            <button>
                              <p>Export PDF</p>  
                            </button></div>

                            <div role="button">
									<button onClick={()=>{ 
										return exportDataToExcel(props?.data,`BarChart-${props.title} ${props?.fileName}`,
                    `${props.title} ${props?.fileName?.replaceAll('\n',' ')??''}`,
											Object.keys(props.data[0])?.map((el:any) => {
                        return { "id" : el,
                        "displayName" : props?.csvColumns ? props?.csvColumns[`${el.toUpperCase()}`]??el.toUpperCase() : el.toUpperCase(),
                        "fn" : props?.csvColumns[`${el.toUpperCase()}_CSVLABEL_fn`]??undefined  }
                      })
										) }} >
										<p>Export CSV</p>
									</button>
								</div>

					              			{/* <CsvDownload  
									          filename={`BarChart-${props.title} ${props?.fileName}`}
									          extension=".csv"
									          columns={
									          	Object.keys(props.data[0])?.map((el:any) => {
									          		return { "id" : el,
                                "displayName" : props?.csvColumns ? props?.csvColumns[`${el.toUpperCase()}`]??el.toUpperCase() : el.toUpperCase()  }
									          	})
									          }
									          datas={props.data}
                            title={`${props.title} ${props?.fileName?.replaceAll('\n',' ')??''}`}
								          >
                            <button>
										<p>Export CSV</p>	
									</button>
                          </CsvDownload>	 */}
							        </div>
						        </div>
						          :	null
						          }
					          </div>
                    // <a className="btn btn-sqaure btn-secondary"
                    // onClick={()=> ExportMultipleChartsToPdf({ val: `BarChartId-${props.title}`, pgtitle: props.title }) }>
                    //     <i className="fa-solid fa-download"></i>
                    // </a>
                    
                    }
            </div>
            <div className="card-body"  style={{ height: '55vh' }}>
                {
                    props.loader ?    <GraphLoader/>  :   null
                }
                {   
                !props.loader &&
                props.data && props.data?.length >0 ?
                <ResponsiveContainer  id={`BarChartId-${props.title}`} >
                <BarChart
                    data={props.data}
                    ref={areaRef}
                    margin={{
                        top: 5,
                        right: 20,
                        left: props?.extraFunc? 20 : -10,
                        bottom: 5,
                    }}
                >
                    {ChartObject()}
                </BarChart>
                </ResponsiveContainer>
                :   
                props.loader ? null :
                <div style={{ width:'100%', height:'100%', display:'grid', placeItems:'center' }}>
                    <p>No data</p>
                </div>
                }
            </div>
        </div>
        {
          openModal && 
          <PdfExporterModal
            handleClose={()=>setOpenModal(false)}
            children={ChartObject}
            data={props?.data}
            extraFunc={props?.extraFunc}
            title={props?.title}
            titleArr={props?.titleArr}
          />
        }
    </div>
    
    )
}

export default BarGraphCard