export const dl_layout = {
    "lg": [
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 3,
            "y": 0,
            "i": "TotalBay",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 6,
            "minH" : 6,
            "x": 3,
            "y": 15,
            "i": "Average Occupancy",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 9,
            "i": "Occupancy By Bay type",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 6,
            "minH" : 6,
            "x": 9,
            "y": 9,
            "i": "Parking Availability",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 6,
            "minW": 6,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 2,
            "i": "Average Violations",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 0,
            "i": "Turnover By Overall",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 15,
            "i": "Turnover By Zone",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 7,
            "minH" : 7,
            "x": 6,
            "y": 2,
            "i": "Total In/Out",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 7,
            "minH" : 7,
            "x": 9,
            "y": 2,
            "i": "Total Parking Request",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 2,
            "minH" : 2,
            "x": 6,
            "y": 0,
            "i": "EnteredVehicleCount",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 6,
            "minH" : 6,
            "x": 3,
            "y": 9,
            "i": "HeatMap",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 9,
            "y": 17,
            "i": "Total24hrPark",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 9,
            "y": 15,
            "i": "AvgDailyVisiit",
            "moved": false,
            "static": false
        }
    ],
    "md": [
        {
            "w": 4,
            "minW": 4,
            "h": 2,
            "minH" : 2,
            "x": 6,
            "y": 0,
            "i": "TotalBay",
            "moved": false,
            "static": false
        },
        {
            "w": 7,
            "minW": 7,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 15,
            "i": "Average Occupancy",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 6,
            "minH" : 6,
            "x": 7,
            "y": 16,
            "i": "Occupancy By Bay type",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 4,
            "minW": 4,
            "h": 5,
            "minH" : 5,
            "x": 6,
            "y": 4,
            "i": "Parking Availability",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 6,
            "minW": 6,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 2,
            "i": "Average Violations",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "minW": 4,
            "h": 2,
            "minH" : 2,
            "x": 6,
            "y": 2,
            "i": "Turnover By Overall",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 7,
            "minH" : 7,
            "x": 4,
            "y": 21,
            "i": "Turnover By Zone",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 4,
            "minW": 4,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 21,
            "i": "Total In/Out",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 7,
            "minH" : 7,
            "x": 7,
            "y": 9,
            "i": "Total Parking Request",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 0,
            "i": "EnteredVehicleCount",
            "moved": false,
            "static": false
        },
        {
            "w": 7,
            "minW": 7,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 9,
            "i": "HeatMap",

            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 7,
            "y": 22,
            "i": "Total24hrPark",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 7,
            "y": 24,
            "i": "AvgDailyVisiit",
            "moved": false,
            "static": false
        }
    ],
    "sm": [
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 3,
            "y": 0,
            "i": "TotalBay",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 17,
            "i": "Average Occupancy",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 4,
            "minH" : 4,
            "x": 3,
            "y": 23,
            "i": "Occupancy By Bay type",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 4,
            "minH" : 4,
            "x": 3,
            "y": 34,
            "i": "Parking Availability",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 6,
            "minW": 6,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 4,
            "i": "Average Violations",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 0,
            "i": "Turnover By Overall",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 23,
            "i": "Turnover By Zone",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 3,
            "minW": 3,
            "h": 7,
            "minH" : 7,
            "x": 3,
            "y": 27,
            "i": "Total In/Out",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 27,
            "i": "Total Parking Request",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 2,
            "i": "EnteredVehicleCount",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "minW": 6,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 11,
            "i": "HeatMap",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 34,
            "i": "Total24hrPark",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 36,
            "i": "AvgDailyVisiit",
            "moved": false,
            "static": false
        }
    ],
    "xs": [
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 0,
            "i": "TotalBay",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "minW": 4,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 11,
            "i": "Average Occupancy",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "minW": 4,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 17,
            "i": "Occupancy By Bay type",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 4,
            "minW": 4,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 21,
            "i": "Parking Availability",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 4,
            "minW": 4,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 4,
            "i": "Average Violations",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 2,
            "y": 0,
            "i": "Turnover By Overall",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 4,
            "minW": 4,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 25,
            "i": "Turnover By Zone",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 4,
            "minW": 4,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 29,
            "i": "Total In/Out",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "minW": 4,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 36,
            "i": "Total Parking Request",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "minW": 4,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 2,
            "i": "EnteredVehicleCount",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "minW": 4,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 43,
            "i": "HeatMap",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 49,
            "i": "Total24hrPark",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "minW": 3,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 51,
            "i": "AvgDailyVisiit",
            "moved": false,
            "static": false
        }
    ],
    "xxs": [
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 42,
            "i": "TotalBay",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 8,
            "i": "Average Occupancy",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 14,
            "i": "Occupancy By Bay type",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 2,
            "minW": 2,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 18,
            "i": "Parking Availability",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 2,
            "minW": 2,
            "h": 8,
            "minH" : 8,
            "x": 0,
            "y": 0,
            "i": "Average Violations",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 22,
            "i": "Turnover By Overall",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 2,
            "minW": 2,
            "h": 4,
            "minH" : 4,
            "x": 0,
            "y": 24,
            "i": "Turnover By Zone",
            "moved": false,
            "static": false,
            "isBounded": true
        },
        {
            "w": 2,
            "minW": 2,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 28,
            "i": "Total In/Out",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 7,
            "minH" : 7,
            "x": 0,
            "y": 35,
            "i": "Total Parking Request",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 44,
            "i": "EnteredVehicleCount",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 6,
            "minH" : 6,
            "x": 0,
            "y": 46,
            "i": "HeatMap",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 52,
            "i": "Total24hrPark",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "minW": 2,
            "h": 2,
            "minH" : 2,
            "x": 0,
            "y": 54,
            "i": "AvgDailyVisiit",
            "moved": false,
            "static": false
        }
    ]
}