import React from 'react';
import MainLayout from '../../../../common/layouts/MainLayout';
import ListTable from './components/AuditListTable';
import FilterBar from './components/FilterBar';

function AuditLogs() {
    return (
        <MainLayout>
            <FilterBar/>
            <ListTable />
        </MainLayout>
    );
}

export default AuditLogs;