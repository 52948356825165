import { useEffect } from 'react'
import { fetch_placeOfRegList, fetch_siteDDL } from '../../../common/general/action'
import { useAppDispatch } from '../../../config/hooks'
import DashIcons from './dashIcons'
import FilterBar from './FilterBar'
import ListTable from './ListTable'

type Props = {}

const ParkingRequest = (props: Props) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const controller = new AbortController();

        fetch_siteDDL(dispatch, controller.signal)
        fetch_placeOfRegList(dispatch, controller.signal)

        return () => controller.abort();
    }, [dispatch])
    
    return (
        <div className="main-body-content">
            <DashIcons/>
            <div className="white-card">
                <FilterBar/>
                <ListTable/>
            </div>
        </div>
    )
}

export default ParkingRequest