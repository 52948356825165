/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import ModalLayout from '../../../common/layouts/ModalLayout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../utils/utilFunctions';
import FormSelectMultiple from '../../../common/components/FormSelectMultiple';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { createWebuserRequest, fetchRequestedParkingZones } from '../store/action';
import { search_user, search_web_user_request } from '../../security/store/action';
import AsyncSelect from 'react-select/async';
import { fetch_zoneDDL } from './../../../common/general/action';
import profileDp from '../../../assets/images/common/profile-pic.png';
import ErrorComponent from '../../../common/components/ErrorComponent';
import ButtonLoader from './../../../common/components/ButtonLoader';
import TreeSelectCustom from './../../../common/components/tree_select/TreeSelectCustom';
import ReactDatePicker from 'react-datepicker';
import { DatePicker } from 'antd';
import moment from 'moment';
import FormInput from '../../../common/components/FormInput';
import CustomDatePicker from '../../../common/components/CustomDatePicker';

type Props = {
	open: boolean;
	handleClose: () => void;
	selectedZone?: {
		zoneID: number;
		zoneName: string;
	};
};

type ddl = {
	label: string;
	value: any;
};

const schema = yup.object().shape({
	requestedFor: yup.number().required(),
	startDate: yup.date().required().nullable().default(undefined).typeError('Invalid Date'),
	endDate: yup
		.date()
		.when('startDate', (StartDate, yup) => StartDate && yup.min(StartDate, 'End date cannot be before start date'))
		.required()
		.nullable()
		.default(undefined)
		.typeError('Invalid Date'),
	// ZoneIDs: yup.mixed().required(),
	userResponsibilities: yup.string(),
});

const AddModal = (props: Props) => {
	const [userOptions, setuserOptions] = useState([]);
	const [selectedUser, setselectedUser] = useState<any>();
	// const [expanded, setExpanded] = useState<boolean>(true);
	const [currentAssignedOrRequested, setcurrentAssignedOrRequested] = useState<any>([]);

	const [selectedZones, setselectedZones] = useState<any>([{label : props?.selectedZone?.zoneName ,value:props?.selectedZone?.zoneID}]);

	

	const [loader, setloader] = useState<boolean>(false);

	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
		watch, trigger
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	});

	const ZONE_DDL = useAppSelector((state) => state?.general?.ZONE_DDL);

	// useEffect(() => {
	// 	if (props?.selectedZone?.zoneID) {
	// 		console.log("props?.selectedZone?.zoneID",props?.selectedZone?.zoneID)
	// 		let a = [props?.selectedZone?.zoneID]
	// 		console.log('ZONE AAA',a)
	// 		setValue('ZoneIDs',[props?.selectedZone?.zoneID]);
	// 	}
	// }, [props?.selectedZone?.zoneID, setValue]);

	//console.log("WATCH ZONE", watch("ZoneIDs"))

	const onSubmitHandler = (e: any) => {
		console.log('DAta', e);
		let zones = filterByReference(selectedZones, currentAssignedOrRequested).map((item) => item?.value);
		// let zones = selectedZones.filter((item: any) => currentAssignedOrRequested.some((zone: any) => zone?.value !== item?.value)) ?? []
		setloader(true);
		let data = {
			requestedFor: e.requestedFor,
			startDate: e.startDate,
			endDate: e.endDate,
			ZoneIDs: zones.join(','),
			userResponsibilities: e.userResponsibilities,
		};

		const Success = (res: any) => {
			// fetch_violation_policy(navigate,dispatch, {
			// 	'filters': {
			// 		'query': '',
			// 	},
			// 	'pagination': {
			// 		'pageNo': 1,
			// 		'limit': 10,
			// 	}})
			setloader(false);

			SuccesToast(res?.response?.data);
			props.handleClose();
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setloader(false);
		};
		createWebuserRequest(data, undefined, Success, Failed);
	};

	// const handleChangeExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
	// 	setExpanded(!expanded);
	// };

	console.log(errors, 'errors');

	const handleChange = (val: any) => {
		setValue('requestedFor', val?.userUniqueID);

		fetchRequestedParkingZones(Number(val?.userUniqueID))?.then((res) => {
			let data = res?.response?.data ?? [];
			let fomratted = data.map((item: any) => ({ label: item?.zoneName, value: item?.zoneID }));
			setcurrentAssignedOrRequested(fomratted);
			setselectedZones((prev:any)=>[...prev ,...fomratted]);
		});
		setselectedUser(val);
		trigger('requestedFor')
	};

	const handleInputChange = async (key?: string) => {
		let postData = {
			'filters': {
				'query': key ? `((firstName like '%${key}%') or (emailID like '%${key}%'))` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		let Response = await search_user(postData);
		let data = Response?.response?.data ?? [];

		console.log('DATA USER', Response);
		console.log('DATA datadata', data);
		setuserOptions(data);
		return data;
	};

	const promiseOptions = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleInputChange(inputValue));
		});

	useEffect(() => {
		handleInputChange();
		fetch_zoneDDL(dispatch);
		reset();
		clearErrors();
	}, [clearErrors, dispatch, reset]);

	console.log('WATCH requestedFor', watch('requestedFor'));

	console.log('errpe', errors);

	const filterByReference = (arr1: ddl[], arr2?: ddl[]) => {
		if (!arr2) return arr1;

		let res: ddl[] = [];
		res = arr1.filter((el: { value: any }) => {
			return !arr2.find((element: { value: any }) => {
				return element.value === el.value;
			});
		});
		return res;
	};

	console.log("selectedZones",selectedZones)

	console.log("props?.selee",props?.selectedZone)


	//console.log(currentAssignedOrRequested, 'REQUESTED ZONES');

	return (
		<ModalLayout
			open={props.open}
			handleClose={() => {
				reset();
				clearErrors();
				props.handleClose();
			}}
			title='Add web user Request'
			subtitle='Add new user request or request access for new zones '
			big>
			<div className='row'>
				<div className='col-12'>
					<Accordion
						expanded={true}
						// onChange={handleChangeExpand('panel1')}
						sx={{ boxShadow: 'none', border: '1px solid var(--lightgrey-second)' }}>
						<AccordionSummary expandIcon={<i className='' />} aria-controls='panel1bh-content' id='panel1bh-header'>
							<div className='accordion-box-heading'>
								<h3>Request User Access</h3>
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<div className='accordion-box-body'>
								<div className='accordion-box-content'>
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className='row'>
											<div className='dflex col-12'>
												<div className='col-6'>
													<div className='col-12'>
														<div className='form-group select-input full-label'>
															<label>Search</label>
															<AsyncSelect
																className={'common-select common-select-grey'}
																cacheOptions
																defaultOptions={userOptions}
																loadOptions={promiseOptions}
																onChange={handleChange}
																getOptionLabel={(e) => e?.firstName + ' - ' + e?.emailID}
																getOptionValue={(e) => e}
																placeholder='Search user'
																// menuPortalTarget={document.body}
																// styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
															/>
															<ErrorComponent
																errors={errors}
																name='requestedFor'
																title=''
																customMessage='Select a user'
															/>
														</div>
													</div>
													<div className='col-12'>
														<div
															className={
																selectedUser
																	? 'form-group select-input full-label'
																	: 'form-group select-input full-label opacity-half'
															}>
															<label>Zone</label>
															{/* <FormSelectMultiple
																control={control}
																name='ZoneIDs'
																options={ZONE_DDL}
																grey={true}
																isMulti={true}
																placeholder='Select Zones'
																isOptionDisabled={(option) =>
																	currentAssignedOrRequested.some(
																		(item: any) => item?.fK_Zone === option.value
																	)
																}
															/> */}
															<TreeSelectCustom
																isMulti={true}
																selectedZones={selectedZones}
																setselectedZones={setselectedZones}
																grey={true}
																disableArray={currentAssignedOrRequested}
																// onchangeFx={(e: any) => {
																// 	setValue('ZoneIDs', e?.value)
																// }}
															/>
															{/* <TreeSelectCustom 
															grey
																selectedZones={currentAssignedOrRequested}
																setselectedZones={setcurrentAssignedOrRequested}
																isMulti={false}
															/> */}
															{filterByReference(selectedZones, currentAssignedOrRequested).length <=
																0 && (
																<p className='hookform-error-message'>Zone is a required field</p>
															)}
															{/* <ErrorComponent errors={errors} name='ZoneIDs' title='Zone' /> */}
														</div>
													</div>
												</div>

												<div className='col-5'>
													{selectedUser && (
														<div className='user-allocated-wrap'>
															<div className='user-allocated-profile dflex align-items-center'>
																<div className='user-allocated-img'>
																	<img src={profileDp} alt='' />
																</div>
																<div className='user-info'>
																	<strong>{selectedUser?.firstName}</strong>
																	<small>{selectedUser?.desName}</small>
																</div>
															</div>
															<div className='user-contact-info'>
																<p>{selectedUser?.contactNo}</p>
																<p>{selectedUser?.emailID}</p>
															</div>
														</div>
													)}
												</div>
												{/* <div className='col-6'></div> */}
											</div>
										</div>
										<div className='col-12 dflex'>
											<div className='col-3'>
												<div className='form-group inline-input full-label'>
													<label>Start Date</label>
													{/* <input className='form-control' type='date' {...register('startDate')} /> */}

													<Controller
														name='startDate'
														control={control}
														render={({ field: { value, onChange, onBlur } }) => (
															<>
																<CustomDatePicker
																className='form-control'
																size='large'
																onChange={onChange}
																value={value ? moment(value) : null}
																disabledDate={(current:any) => current < moment().subtract(1,'day')}
																allowClear={false}
																setValue={setValue}
																name={'startDate'}
																format={'YYYY-MM-DD'}
																/>
															</>
														)}
													/>

													<ErrorComponent errors={errors} name='startDate' title='Start date' />
												</div>
											</div>
											<div className='col-3'>
												<div className='form-group inline-input full-label'>
													<label>End Date</label>
													{/* <input className='form-control' type='date' {...register('endDate')} /> */}
													<Controller
														name='endDate'
														control={control}
														render={({ field: { value, onChange, onBlur } }) => (
															<>
																<CustomDatePicker
																	// dateFormat={'dd-MMM-yyyy'}
																className='form-control'
																size='large'
																onChange={onChange}
																value={value ? moment(value) : null}
																disabledDate={(current:any) => current < moment()}
																allowClear={false}
																	// placeholderText='dd-mm-yyyy'
																	// selected={value}
																
																	setValue={setValue}
																	name={'endDate'}
																	format={'YYYY-MM-DD'}
																/>
																
															</>
														)}
													/>
													<ErrorComponent errors={errors} name='endDate' title='End date' />
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group inline-input full-label'>
													<label>User Responsibility Details</label>

													<FormInput type='text' name="userResponsibilities" className='form-control' control={control} />

													{/* <input className='form-control' type='text' {...register('userResponsibilities')} /> */}
												</div>
											</div>
										</div>
										<div className='dflex note-message ml-5'>
											{currentAssignedOrRequested.length > 0 && (
												<>
													This user is already assigned or requested in zones ,
													{currentAssignedOrRequested.map((item: any, key: React.Key) => {
														return (
															<p className='note-message ml-5' key={key}>
																<strong>{item?.label}</strong>
															</p>
														);
													})}
													.
												</>
											)}
										</div>
										<div className='modal-footer'>
											<button className='btn btn-secondary border-blue' type='submit'>
												<ButtonLoader text='Save' loading={loader} />
											</button>
											<button
												type='reset'
												className='btn btn-outline-blue close-modal-button'
												onClick={() => {
													reset();
													clearErrors();
													props?.handleClose();
												}}>
												Close
											</button>
										</div>
									</form>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>

			{/* <ListTable /> */}
		</ModalLayout>
	);
};

export default AddModal;
