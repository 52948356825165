import React, { useState } from 'react'
import moment from 'moment'
import { FormatDate } from '../../utils/utilFunctions';
import { DatePicker } from 'antd';

export default function CustomDatePicker(props) {
	const [inputDateVal, setInputDateVal] = useState(moment(props.value,props?.format)?.isValid() ? moment(props?.value).format(props.format) : '')
	const [openDate, setOpenDate] = useState(false)
	const [errStatus, setErrStatus] = useState(false)
  return (
    <div className={`${props?.className} ${props?.className ? '' : 'defaultPickerBorder'}`} 
		style={{ position : 'relative', display:'flex', alignItems:'center' }} 
		onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setOpenDate(!openDate); }}
	>
        <input
			type='text'
			// className={props?.className}
			style={{ outline:'none', backgroundColor:'transparent', border:'none' }}
			value={inputDateVal}
			onClick={()=>setOpenDate(!openDate)}
			placeholder={props?.placeholder}
			onKeyDown={(e)=>{
				console.log('KEYCODE=>',e?.code)
			}}
			onChange={(e) => {
				e.preventDefault(); e.stopPropagation();
				setOpenDate(!openDate);
				console.log('CUSTOMDATEPICKER',e)
			
					let v = e.target.value;
					setInputDateVal(e?.target?.value)
					props?.onChange(e)
					if(FormatDate(v,props?.format)!=='' && props?.name && props?.setValue)
						props?.setValue(props?.name,FormatDate(v,props?.format))
				
			}}
		/>
		{
			props?.hideIcon ? null :
			<span onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); }} style={{
				position : 'absolute', right : '20px', width : '5%', border : 'none'
			}}>
				<DatePicker
            	{...props}
				defaultValue={props?.defaultValue??moment()}
				defaultPickerValue={props?.defaultPickerValue??moment()}
				open={openDate}
				status={errStatus?'error':''}
				className={`${props?.className} invisibleDateInk`}
				format={props?.format}
				onClick={(e)=>{ setOpenDate(!openDate) }}
				onOk={()=>{ setOpenDate(!openDate) }}
				onChange={(e) =>{ 	
					console.log('DATEPICKER-onchange=>',e,moment('2').isValid())
					if(e && moment(e).isValid()){
						setErrStatus(false)
						props?.onChange(e); 
					setOpenDate(false)
					setInputDateVal(moment(e).format(props.format??'YYYY-MM-DD'))
					}else{
						setErrStatus(true)
					}				
				}}
			/>
			</span>
			
		}
    </div>
  )
}
