import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';

type FilterObject = {
	query: string;
};

type PaginationObject = {
	pageNo: number;
	totalCount: number;
	limit: number;
};

const initialState = {
	ROLES_LIST_DATA: [],
	ROLES_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	ROLES_LOADER: true,
	ROLES_FILTER: { query: '' } as FilterObject,
	ROLES_ERROR: '',

	WEBUSERS_LIST_DATA: [],
	WEBUSERS_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	WEBUSERS_LOADER: true,
	WEBUSERS_FILTER: { query: '' } as FilterObject,
	WEBUSERS_ERROR: '',

	WEBUSER_REQUEST_LIST_DATA: [],
	WEBUSER_REQUEST_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	WEBUSER_REQUEST_LOADER: true,
	WEBUSER_REQUEST_FILTER: { query: '' } as FilterObject,
	WEBUSER_REQUEST_ERROR: '',

	GENERAL_DASHBOARD_LIST_DATA: [],
	GENERAL_DASHBOARD_PAGINATION: { pageNo: 1, limit: 10, totalCount: 0 } as PaginationObject,
	GENERAL_DASHBOARD_LOADER: true,
	GENERAL_DASHBOARD_FILTER: { query: '' } as FilterObject,
	GENERAL_DASHBOARD_ERROR: '',
};

export const SECURITYSLICE = createSlice({
	name: 'SECURITY',
	initialState: initialState,
	reducers: {
		UPDATE_ROLES_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;
			state.ROLES_LIST_DATA = data;
			state.ROLES_PAGINATION = pagination;
			state.ROLES_FILTER = filters;
			state.ROLES_LOADER = false;
			state.ROLES_ERROR = '';
		},
		UPDATE_ROLES_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.ROLES_LOADER = loader;
		},

		UPDATE_ROLES_ERROR: (state, action) => {
			const { error } = action.payload;
			state.ROLES_ERROR = error;
			state.ROLES_LOADER = false;
		},

		UPDATE_WEBUSERS_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;

			state.WEBUSERS_LIST_DATA = data;
			state.WEBUSERS_PAGINATION = pagination;
			state.WEBUSERS_FILTER = filters;
			state.WEBUSERS_LOADER = false;
			state.WEBUSERS_ERROR = '';
		},
		UPDATE_WEBUSERS_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.WEBUSERS_LOADER = loader;
		},

		UPDATE_WEBUSERS_ERROR: (state, action) => {
			const { error } = action.payload;
			state.WEBUSERS_ERROR = error;
			state.WEBUSERS_LOADER = false;
		},

		UPDATE_WEBUSER_REQUEST_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;

			state.WEBUSER_REQUEST_LIST_DATA = data;
			state.WEBUSER_REQUEST_PAGINATION = pagination;
			state.WEBUSER_REQUEST_FILTER = filters;
			state.WEBUSER_REQUEST_LOADER = false;
			state.WEBUSER_REQUEST_ERROR = '';
		},
		UPDATE_WEBUSER_REQUEST_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.WEBUSER_REQUEST_LOADER = loader;
		},

		UPDATE_WEBUSER_REQUEST_ERROR: (state, action) => {
			const { error } = action.payload;
			state.WEBUSER_REQUEST_ERROR = error;
			state.WEBUSER_REQUEST_LOADER = false;
		},

		UPDATE_GENERAL_DASHBOARD_DATA: (state, action) => {
			const { data, pagination, filters } = action.payload;

			state.GENERAL_DASHBOARD_LIST_DATA = data;
			state.GENERAL_DASHBOARD_PAGINATION = pagination;
			state.GENERAL_DASHBOARD_FILTER = filters;
			state.GENERAL_DASHBOARD_LOADER = false;
			state.GENERAL_DASHBOARD_ERROR = '';
		},
		UPDATE_GENERAL_DASHBOARD_LOADER: (state, action) => {
			const { loader } = action.payload;
			state.GENERAL_DASHBOARD_LOADER = loader;
		},

		UPDATE_GENERAL_DASHBOARD_ERROR: (state, action) => {
			const { error } = action.payload;
			state.GENERAL_DASHBOARD_ERROR = error;
			state.GENERAL_DASHBOARD_LOADER = false;
		},

		RESET_SECURITY_FILTERS_ONLY: (state) =>{
			state.ROLES_FILTER = initialState.ROLES_FILTER;
			state.WEBUSERS_FILTER = initialState.WEBUSERS_FILTER;
			state.WEBUSER_REQUEST_FILTER = initialState.WEBUSER_REQUEST_FILTER;
			state.GENERAL_DASHBOARD_FILTER = initialState.GENERAL_DASHBOARD_FILTER;
		},

		RESET_STATE: () => {
			return {...initialState}
		}
	},
});

export const {
	UPDATE_ROLES_DATA,
	UPDATE_ROLES_LOADER,
	UPDATE_ROLES_ERROR,
	UPDATE_WEBUSERS_DATA,
	UPDATE_WEBUSERS_LOADER,
	UPDATE_WEBUSERS_ERROR,
	UPDATE_WEBUSER_REQUEST_DATA,
	UPDATE_WEBUSER_REQUEST_LOADER,
	UPDATE_WEBUSER_REQUEST_ERROR,
	UPDATE_GENERAL_DASHBOARD_DATA,
	UPDATE_GENERAL_DASHBOARD_LOADER,
	UPDATE_GENERAL_DASHBOARD_ERROR,
	RESET_STATE,
	RESET_SECURITY_FILTERS_ONLY
} = SECURITYSLICE.actions;
export const SECURITYData = (state: RootState) => state;
export default SECURITYSLICE.reducer;
