/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_notification_exception, notification_exception_detail } from '../../../store/action';
import AddModal from './addModal';

type Props = {};

const ListTable = (props: Props) => {
	const dispatch = useAppDispatch();
	const NOTIFICATION_DATA = useAppSelector((state) => state?.notifications);

	const fetchIdRef = useRef(0);

	const [editData, seteditData] = useState();
	const [editModal, setEditModal] = useState(false);

	const editModalOpen = (data: any) => {
		notification_exception_detail(data?.nExceptionID)?.then((res) => {
			if (res?.statusCode === 200) {
				seteditData(res?.response?.data[0]);
				setEditModal(true);
			}
		});
	};

	const editModalClose = () => setEditModal(false);

	const columns = React.useMemo(
		() => [
			{ Header: 'NO', accessor: 'nExceptionID', orderByKey: 'nExceptionID' },
			{ Header: 'Start date', accessor: 'startDate', DateFormat: true, orderByKey: 'ExcU.StartDate' },
			{ Header: 'End Date', accessor: 'enddate', DateFormat: true, orderByKey: 'ExcU.EndDate' },
			{ Header: 'Description', accessor: 'descriptions' },

			{ Header: 'User Count', accessor: 'totaluser', orderByKey: 'totaluser' },
			{ Header: 'Exception Count', accessor: 'templateCount', 
				orderByKey: 
					'templateCount' 
			},

			{
				Header: 'status ',
				accessor: 'requestStatus',
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<label
								className={`${row?.original?.isTemporary === false
										? 'badge green'
										: row?.original?.isTemporary === true
											? 'badge dark-blue'
											: 'badge'
									} `}>
								{row?.original?.isTemporary === false ? 'Active' : row?.original?.isTemporary === true ? 'Inactive' : null}
							</label>
						</Fragment>
					);
				},
			},
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a
									onClick={() => {
										editModalOpen(row?.original);
									}}
									className='btn btn-sqaure btn-white modal-button'>
									<i className='fa-solid fa-pen'></i>
								</a>
							</div>
						</Fragment>
					);
				},
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: {query: string;}

	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy ,filters}: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_notification_exception(
					dispatch,
					{
						'filters': {
							'query':filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch]
	);

	return (
		<>
			<CommonTable
				columns={columns}
				data={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.totalCount ?? 0}
				loading={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_LOADER}
				page_no={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.pageNo ?? 1}
				limit={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_PAGINATION?.limit ?? 1}
				filters={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_FILTER}
				error={NOTIFICATION_DATA?.NOTIFICATION_EXCEPTION_ERROR}
			/>
			{editModal && <AddModal open={editModal} handleClose={editModalClose} Data={editData} />}
		</>
	);
};

export default ListTable;
