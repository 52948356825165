import React, { useEffect, useState } from 'react';
import uploadIcon from '../../../../assets/images/common/upload-icon.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from '../../../../config/hooks';
import ErrorComponent from '../../../../common/components/ErrorComponent';
import { useAppDispatch } from './../../../../config/hooks';
import { fetch_company_profile, update_company_profile } from './../../store/action';
import { FailedToast, SuccesToast } from '../../../../utils/utilFunctions';
import { message, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { fileToImage } from './../../../../utils/utilFunctions';
import ButtonLoader from '../../../../common/components/ButtonLoader';
type Props = {};
const { Dragger } = Upload;

const schema = yup.object().shape({
	companyName: yup.string().required(),
	companyShortName: yup.string().required(),
	companyDescription: yup.string().required(),
	cLocation: yup.string().required(),
	contactNo: yup.string().required(),
	emailID: yup.string().email().required(),
	photo: yup.mixed(),
});

const CompanyProfile = (props: Props) => {
	const CompanyProfile: any = useAppSelector((state) => state?.applicationSettings?.COMPANY_PROFILE);
	let dispatch = useAppDispatch();

	const [file, setfile] = useState<any>();
	const [loader, setloader] = useState(false);

	useEffect(() => {
		fetch_company_profile(dispatch);
	}, [dispatch]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		shouldFocusError: true,
	});

	useEffect(() => {
		if (CompanyProfile) {
			setValue('companyName', CompanyProfile?.companyName);
			setValue('companyShortName', CompanyProfile?.companyShortName);
			setValue('companyDescription', CompanyProfile?.companyDescription);
			setValue('cLocation', CompanyProfile?.cLocation);
			setValue('contactNo', CompanyProfile?.contactNo);
			setValue('emailID', CompanyProfile?.emailID);
			setValue('photo', CompanyProfile?.photo);
		}
	}, [CompanyProfile, setValue]);

	const onSubmitHandler = (data: any) => {
		let d = new FormData();
		d.append('companyName', data?.companyName);
		d.append('companyShortName', data?.companyShortName);
		d.append('companyDescription', data?.companyDescription);
		d.append('cLocation', data?.cLocation);
		d.append('contactNo', data?.contactNo);
		d.append('emailID', data?.emailID);
		d.append('photo', data?.photo);

		setloader(true);

		const Success = (res: any) => {
			fetch_company_profile(dispatch);
			setloader(false);

			SuccesToast(res?.response?.data);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response)		;
			setloader(false);
		};
		update_company_profile(d, Success, Failed);
	};

	return (
		<div className='main-body-content modal-body company-profile'>
			<div className='white-card data-clean mb-30'>
				<div className='heading'>
					<h2 className='border-title mb-0'>Company Profile</h2>
				</div>
				<form className='form-w-label modal-form' onSubmit={handleSubmit(onSubmitHandler)}>
					<div className='row'>
						<div id='userinfotab' className='col-6 plr-15'>
							<div className='form-group file-upload'>
								<Controller
									control={control}
									name='photo'
									render={({ field: { onChange } }) => (
										<input
											className='form-control'
											type='file'
											id='formFileMultiple'
											capture='environment'
											onChange={(v: any) => {
												onChange(v.target.files[0]);
												setfile(v.target.files[0]);
											}}
										/>
									)}
								/>

								{file ? (
									<div className='parking-lot-image '>
										<img src={fileToImage(file)} alt='' />
									</div>
								) : (
									<>
										{CompanyProfile?.photo ? (
											<>
												<div className='parking-lot-image '>
													<img src={CompanyProfile?.photo} alt=''  style={{ maxHeight:"60vh" ,objectFit:"contain"}}/>
												</div>
											</>
										) : (
											<>
												<img src={uploadIcon} alt='' className='upload-icon' />
												<label htmlFor='formFileMultiple' className='form-label'>
													Choose a file Or Drag it here.
												</label>
											</>
										)}
									</>
								)}
							</div>
						</div>

						<div className='col-6 plr-15'>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor=''>Company Name</label>
										<input type='text' {...register('companyName')} placeholder='' className='form-control' />
									</div>
									<ErrorComponent errors={errors} name='companyName' title='Name' />
								</div>
							</div>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor=''>Company Short Name</label>
										<input type='text' {...register('companyShortName')} placeholder='' className='form-control' />
									</div>
									<ErrorComponent errors={errors} name='companyShortName' title='Short Name' />
								</div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor=''> Email</label>
										<input type='text' {...register('emailID')} placeholder='' className='form-control' />
									</div>
									<ErrorComponent errors={errors} name='emailID' title='Email' />
								</div>
							</div>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor=''>Description</label>
										<input type='text' {...register('companyDescription')} placeholder='' className='form-control' />
									</div>
									<ErrorComponent errors={errors} name='companyDescription' title='Description' />
								</div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor=''>Contact Info</label>
										<input type='text' {...register('contactNo')} placeholder='' className='form-control' />
									</div>
									<ErrorComponent errors={errors} name='contactNo' title='Contact Info' />
								</div>
							</div>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor=''>Location</label>
										<input type='text' {...register('cLocation')} placeholder='' className='form-control' />
									</div>
									<ErrorComponent errors={errors} name='cLocation' title='Location' />
								</div>
							</div>
						</div>
					</div>
					<hr />

					<div className='text-right'>
						<button type='submit' className='btn btn-secondary' disabled={loader}>
							<ButtonLoader text='Update' loading={loader} />
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CompanyProfile;
