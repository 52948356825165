/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_find_a_vehicle } from '../../../store/action';
import ViewModal from './ViewModal';

function ListTable() {
	const fetchIdRef                    = useRef(0);
	const [viewModal, setViewModal] = useState(false)
	const [appDetail, setAppDetail] = useState(null)
    const ViewModalClose = () => setViewModal(false)
	
	const dispatch                      = useAppDispatch();
	const FINDACAR = useAppSelector((state) => state?.findacar)
	const ViewModalOpen = (data:any) =>{
		setAppDetail(data)
		setViewModal(true)
	}

	const columns = React.useMemo(
		()	=>	[
			{ Header: 'ID', accessor:'visit_ID' ,orderByKey : "visit_ID"},
			// { Header: 'START DATE', accessor:'startDate', DateFormat : true, FormatType : 'DD-MMM-YYYY' ,orderByKey : "startDate"},
			// { Header: 'END DATE', accessor:'endDate', DateFormat : true, FormatType : 'DD-MMM-YYYY' ,orderByKey : "endDate"},
			// { Header: 'USER ID', accessor:'userID',orderByKey : "RequestedFUID"},
			{ Header: 'Name', accessor:'fullName'
			//,orderByKey:'RequestedFUName'
				,	orderByKey:'fullName'
			},
			{ Header: 'Email', accessor:'emailID',
				orderByKey : 'emailID'
			},

            { Header: 'Plate Number', accessor:'plateNumber',
			orderByKey : 'plateNumber'
			},
			{ Header: 'Place Of Reg', accessor: 'placeOfRegistration'
		,	orderByKey : 'placeOfRegistration'
			},
            { Header: 'Vehicle Category', accessor:'vehicleCategory', 
			orderByKey : 'vehicleCategory'
			},
			{ Header: 'Bay ', accessor:'bayName', orderByKey : 'bayName'
		},

		{ Header: 'Site', accessor:'siteName', orderByKey : 'siteName'
	},
	

		
		
			{ Header: 'Entry', accessor:'inTIme', DateFormat : true, FormatType : 'DD-MMM-YYYY hh:mm A'},

            { Header: 'Type Of user', accessor:'isVisitor',
			Cell: ({ row }: any) => {
				return (
					<Fragment>
						<label className='badge green'> { row?.original?.isVisitor ? "Visitor" :"Employee"}</label>
					</Fragment>
				);
			},},
            {
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a  onClick={() => ViewModalOpen(row?.original)}  className="btn btn-sqaure btn-white modal-button"><i className="fa-solid fa-eye"></i></a>
							</div>
						</Fragment>
					);
				},
				show: true,
			} 	
		],
		[]
	);

	type IfetchData = {
		pageSize :number,
		pageIndex :number,
		signal :AbortSignal,
		OrderBy : string
		filters: {searchText: string;}

	}


	const fetchData = useCallback(({ pageSize, pageIndex ,signal ,OrderBy,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_find_a_vehicle( dispatch, {
				'filters': {
					'query': '',
					OrderBy
				},
				"SearchText": filters.searchText ?? '',
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			},signal);
		}
	}, [dispatch]);


    return (
		<>
			<CommonTable 
				data={FINDACAR?.FIND_A_VEHICLE_LIST_DATA??[]} 
				columns={columns} 
				pageCount={FINDACAR?.FIND_A_VEHICLE_PAGINATION?.totalCount ?? 0} 
				fetchData={fetchData} 
		  		limit={FINDACAR?.FIND_A_VEHICLE_PAGINATION?.limit ?? 1} 
				page_no={FINDACAR?.FIND_A_VEHICLE_PAGINATION?.pageNo ?? 1}
				error={FINDACAR?.FIND_A_VEHICLE_ERROR ?? ''}
				filters={FINDACAR?.FIND_A_VEHICLE_FILTER}		
				loading={FINDACAR?.FIND_A_VEHICLE_LOADER}	
			
			/>
			{
				viewModal && 
				<ViewModal  open={viewModal} handleClose={ViewModalClose} selectedCar={appDetail}/>

			}
		</>
    );
}

export default ListTable;