import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
// import ImageView from '../../../../../common/components/ImageView/ImageView';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, fileToImage, SuccesToast } from '../../../../../utils/utilFunctions';
import { definiton_add_edit, fetch_definitionList } from '../../../store/action';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any
};

const AddModal = (props: Props) => {

	const [DefId, setDefId] = useState('-')
	const [DefRefId, setDefRefId] = useState('-')
	const [DefName, setDefName] = useState('')
	const [AnprRef, setAnprRef] = useState('')
	const [Abbr, setAbbr] = useState('')
	// const [DefDesc, setDefDesc] = useState('')
	// const [DefImage, setDefImage] = useState('')
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState({
		name : false,
		refVal : false,
		anpr : false,
		abbr : false
	})
	
	let navigate = useNavigate()
	const dispatch = useAppDispatch()
	const DEFINITION_DATA = useAppSelector((state) => state?.definition)


	useEffect(() => {
	  if(props.Data){
		setDefId(props.Data?.placeOfRegID??'-')
		setDefRefId(props.Data?.askGsReference??'')
		setDefName(props.Data?.cityCountry??'')
		setAbbr(props?.Data?.abbreviation??'')
		setAnprRef(props?.Data?.anprRefPlaceOfRegID??'')
		// setDefDesc(props?.Data?.companyDescription??'')
		
		// setDefImage(props?.Data?.photo??'')
	  }
	  else{
		setDefId('-')
		setDefRefId('')
		setDefName('')
		setAbbr('')
		setAnprRef('')
		// setDefDesc('')	
		// setDefImage('')
	  }
	}, [props.Data])
	

	const DefinitonActionFx = () =>{
		setLoading(true)

		let postData = new FormData()
		postData.append('askGsReference',DefRefId)
		postData.append('cityCountry',DefName)
		// postData.append('CompanyDescription',DefDesc)
		postData.append('abbreviation',Abbr)
		postData.append('anprRefPlaceOfRegID',AnprRef)
		// postData.append('Photo',DefImage ?? '')
		if(props?.Data){
			postData.append('placeOfRegID',DefId)
		}

		// let postData:any = props.Data ? {
        //     CompanyName: DefName,
		// 	GroupCompanyID : DefId,
		// 	CompRefrenceID : DefRefId,
		// 	CompanyDescription : DefDesc,
		// 	ContactNo : Abbr,
		// 	EmailID : AnprRef
		// } :
		// {
		// 	CompanyName: DefName,
		// 	CompRefrenceID : DefRefId,
		// 	CompanyDescription : DefDesc,
		// 	ContactNo : Abbr,
		// 	EmailID : AnprRef
		// }
		

		const Success = (res:any) => {
			setLoading(false)

			SuccesToast(res?.response?.data)
			setDefId('')
			setDefName('')
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": ""
					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                'place-of-reg'
			);
			props.handleClose()
		}
		const Failed = (err:any) => {
			setLoading(false)

			FailedToast(err?.response)		
		}

		if(props.Data){
			definiton_add_edit('put',postData,'place-of-reg',Success,Failed)
		}
		else{
			definiton_add_edit('post',postData,'place-of-reg',Success,Failed)
		}

	}

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title={`${props.Data ? 'Edit' : 'Add'} New Place of Registration`} subtitle={DefId!=='-' ? `#${DefId}` : 'Definitions'}>
			<div className='modal-body-content row'>
				{DefId!=='-' &&
					<div className={`col-6 ${props.Data ? 'disableAndOpacity' : ''}`}>
						<div className="form-group inline-input full-label">
							<label>Place of Registration ID</label>
							<input type="text" name='' placeholder='Place of Registration ID' value={DefId} disabled className="form-control grey-input" 
								onChange={(e)=>{
									setDefId(e.target.value)
								}}
							/>
						</div>
					</div>
					}
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>ASKGS Reference ID</label>
							<input type="text" name='' placeholder='ASKGS Reference ID' value={DefRefId} className="form-control grey-input" onChange={(e)=> setDefRefId(e.target.value) } />
						</div>
					</div>
					<div className={`col-12  ${props.Data ? 'disableAndOpacity' : ''}`}>
						<div className="form-group inline-input full-label">
							<label>City / Country</label>
							<input type="text" name='' value={DefName} onChange={(e) => setDefName(e.target.value)}
								className="form-control grey-input" />
								{	error.name &&
									<p className='hookform-error-message'>A valid City / Country value is required</p>}
						</div>
					</div>
					<div className={`col-6  ${props.Data ? 'disableAndOpacity' : ''}`}>
						<div className="form-group inline-input full-label">
							<label>ANPR Ref. Place of Registration</label>
							<input type="text" name='' value={AnprRef} onChange={(e) => setAnprRef(e.target.value)}
								className="form-control grey-input" />
								{	error.anpr &&
									<p className='hookform-error-message'>A valid value is required</p>}
						</div>
					</div>
					<div className={`col-6  ${props.Data ? 'disableAndOpacity' : ''}`}>
						<div className="form-group inline-input full-label">
							<label>Abbreviation</label>
							<input type="text" name='' value={Abbr} onChange={(e) => setAbbr(e.target.value)}
								className="form-control grey-input" />
								{	error.abbr &&
									<p className='hookform-error-message'>A valid Contact is required</p>}
						</div>
					</div>
			</div>
				<div className="row">					
					<div className="col-12">
						<div className="modal-footer">
							<button type="button" className="btn btn-outline-blue close-modal-button" onClick={props.handleClose}>Cancel</button>
							<button type="submit" className="btn btn-secondary" onClick={()=>{
								if( DefName!==''){
									setError({
										name : false, anpr : false, abbr : false, refVal : false
									})	
									DefinitonActionFx()
								}else{
									setError({
										name : DefName==='', anpr : AnprRef==='', abbr : Abbr==='', refVal : DefRefId===''
									})
								}
							}}>
								<ButtonLoader text={`${props.Data ? 'Edit' : 'Add'} Place of Registration`} loading={loading}/>
							</button>

						</div>	
					</div>
				</div>
		</ModalLayout>
	);
};

export default AddModal;
