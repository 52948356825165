import React, { useCallback } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';

export default function DraggableComponent({ items, setItems, newColumnOrder }: any) {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = useCallback(
		(event: { active: any; over: any }) => {
			const { active, over } = event;
			if (active.id !== over.id) {
				setItems((items: any) => {
					const oldIndex = items.map((e: any) => e.id).indexOf(active.id);
					const newIndex = items.map((e: any) => e.id).indexOf(over.id);
					newColumnOrder(arrayMove(items, oldIndex, newIndex));
					return arrayMove(items, oldIndex, newIndex);
				});
			}
		},
		[newColumnOrder, setItems ]
	);

	
	let checkBxValArr = items?.filter((el:any) => !el?.hideHeader )

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			<SortableContext items={items} strategy={verticalListSortingStrategy}>
				{items.map((column: any) => {
					let checkBxVal	  = checkBxValArr?.filter((el:any) => el?.getToggleHiddenProps()?.checked)?.length === 1
					console.log('checkBxValArr',checkBxValArr,'ITEMS',items,'Arr',items?.filter((el:any) => el?.getToggleHiddenProps()?.checked),'disable=>',column?.getToggleHiddenProps()?.checked, checkBxVal)
					if (column?.hideHeader) return null;
					return <SortableItem key={column.id} id={column.id} column={column} 
								disableCheckBox={column?.getToggleHiddenProps()?.checked && checkBxVal} />;
				})}
			</SortableContext>
		</DndContext>
	);
}
