import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import ButtonLoader from '../../../../../common/components/ButtonLoader';
import TreeSelectCustom from '../../../../../common/components/tree_select/NewTreeSelectCustom';
import { fetch_zoneDDL } from '../../../../../common/general/action';
import ModalLayout from '../../../../../common/layouts/ModalLayout';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, SuccesToast } from '../../../../../utils/utilFunctions';
import { definiton_add_edit, fetch_definitionList, get_general_settings_list } from '../../../store/action';

type Props = {
	open: boolean;
	handleClose: () => void;
	Data?: any
};

const AddModal = (props: Props) => {

	const [DefId, setDefId] = useState('-')
	const [DefName, setDefName] = useState('')
	const [DefUsername, setDefUsername] = useState('')
	const [DefEmailID, setDefEmailID] = useState('')
	const [DefPhone, setDefPhone] = useState('')
	const [selZone, setSelZone] = useState<any>([])

	const [DefPasssword, setDefPassword] = useState('')
	const [DefSiteUsername, setDefSiteUsername] = useState('')
	const [DefUrl, setDefUrl] = useState('')
	const [loading, setLoading] = useState(false)
	const [bsUrl,setBSUrl] = useState('')
	const [zoneId, setZoneId] = useState<any>(null)
	const [siteRef, setSiteRef] = useState('')
	const [anprRef, setAnprRef] = useState('')

	const [siteEnable, setSiteEnable] = useState(true)

	const [error, setError] = useState({
		name : false,
		username : false,
		password : false,
		url : false,
		email : false,
		phone : false,
		siteUsername : false,
		siteRef : false,
		anprRef : false
	})
	
	let navigate = useNavigate()
	const dispatch = useAppDispatch()
    const DEFINITION_DATA = useAppSelector((state) => state?.definition)
	// const ZONELIST = useAppSelector((state) => state?.general)

	useEffect(() => {
		const suc = (res:any) => { setBSUrl(res?.response?.data?.inx_base_url?.settingsValue) }
		const fail = (err:any) => { console.log(err) }
		get_general_settings_list(suc,fail)
	  if(props.Data){
		fetch_zoneDDL(dispatch)
		setDefId(props.Data?.siteID??'-')
		setDefName(props.Data?.siteName??'')
		setDefUsername(props.Data?.apiUsername??'')
		setDefUrl(props.Data?.siteSlug)
		setDefEmailID(props.Data?.emailID??'')
		setDefSiteUsername(props.Data?.siteUserName??'')
		setZoneId(props.Data?.autoApprovedzoneID??null)
		setSiteRef(props.Data?.stRefrenceID??'')
		setDefPhone(props.Data?.contactNo??'')
		setSiteEnable(props.Data?.isEnable??true)
		setAnprRef(props.Data?.anprRefID??'')
		if(props.Data?.autoApprovedzoneID)
			setSelZone([{ value : props.Data?.autoApprovedzoneID, label : ''}])
	  }
	  else{
		setDefId('-')
			setDefName('')
			setDefPassword('')
			setDefUrl('')
			setDefUsername('')
			setDefSiteUsername('')
			setZoneId(null)
			setSiteRef('')
			setDefPhone('')
			setDefEmailID('')
			setSiteEnable(true)
			setAnprRef('')
	  }
	}, [props.Data])
	

	const DefinitonActionFx = () =>{
		setLoading(true)

		let postdata:any = props.Data ? {
            Name: DefName,
			ID : DefId,
			IsEnable : siteEnable,
			apiUsername : DefUsername,
			SiteSlug 	: DefUrl,
			// ReferenceID : props.Data ? props.Data.stRefrenceID	: 0,
			ReferenceID : siteRef,
			EmailId 	: DefEmailID,
			ContactNumber : DefPhone,
			SiteUserName : DefSiteUsername,
			AutoApprovedZoneId : zoneId,
			ANPRRefID : anprRef
		} :
		{
			Name: DefName,
			IsEnable : siteEnable,
			apiUsername : DefUsername,
			apiPassword : DefPasssword,
			SiteSlug 	: DefUrl,
			ReferenceID : siteRef,
			EmailId 	: DefEmailID,
			ContactNumber : DefPhone,
			SiteUserName : DefSiteUsername,
			ANPRRefID : anprRef
		}
		if(props.Data && DefPasssword!=='')
			postdata = { ...postdata,	apiPassword : DefPasssword }
		const Success = (res:any) => {
			setLoading(false)

			SuccesToast(res?.response?.data)
			setDefId('')
			setDefName('')
			setDefPassword('')
			setDefUrl('')
			setDefUsername('')
			setAnprRef('')
			fetch_definitionList(navigate, dispatch,
				{
					"filters": {
						"query": ""
					},
					"pagination": {
						"pageNo": DEFINITION_DATA.DEFINITION_PAGINATION.pageNo,
						"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
					}
				},
                'site'
			);
			props.handleClose()
		}
		const Failed = (err:any) => {
			setLoading(false)

			FailedToast(err?.response)		
		}

		if(props.Data){
			definiton_add_edit('put',postdata,'site',Success,Failed)
		}
		else{
			definiton_add_edit('post',postdata,'site',Success,Failed)
		}

	}

	return (
		<ModalLayout open={props.open} handleClose={props.handleClose} title={`${props.Data ? 'Edit' : 'Add New'} Site`} subtitle={DefId!=='-' ? `` : 'Definitions'}>
				<div className="row">
					{/* {DefId!=='-' &&
					<div className="col-3">
						<div className="form-group inline-input full-label">
							<label>Site ID</label>
							<input type="text" name='' placeholder='Type Id Autogenerated' value={DefId} disabled className="form-control grey-input" />
						</div>
					</div>} */}
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Site Name</label>
							<input type="text" name='' value={DefName} onChange={(e) => setDefName(e.target.value)}
								className="form-control grey-input" />
								{	error.name &&
									<p className='hookform-error-message'>A valid Name is required</p>}
						</div>
					</div>
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>Site Reference ID</label>
							<input type="text" name='' value={siteRef} onChange={(e) => setSiteRef(e.target.value)}
								className="form-control grey-input" />
								{	error.siteRef &&
									<p className='hookform-error-message'>A valid reference ID is required</p>}
						</div>
					</div>
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>ANPR Reference ID</label>
							<input type="text" name='' value={anprRef} onChange={(e) => setAnprRef(e.target.value)}
								className="form-control grey-input" />
								{	error.anprRef &&
									<p className='hookform-error-message'>A valid reference ID is required</p>}
						</div>
					</div>
					
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>Site-wise User Name</label>
							<input type="text" name='' value={DefSiteUsername} onChange={(e) => setDefSiteUsername(e.target.value)}
								className="form-control grey-input" />
								{	error.siteUsername &&
									<p className='hookform-error-message'>A valid Username is required</p>}
						</div>
					</div>

					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>Email ID</label>
							<input type="text" name='' value={DefEmailID} onChange={(e) => setDefEmailID(e.target.value)}
								className="form-control grey-input" />
								{	error.email &&
									<p className='hookform-error-message'>A valid Email is required</p>}
						</div>
					</div>
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>Contact Number</label>
							<input type="text" name='' value={DefPhone} onChange={(e) => setDefPhone(e.target.value)}
								className="form-control grey-input" />
								{	error.phone &&
									<p className='hookform-error-message'>A valid Phone Number is required</p>}
						</div>
					</div>
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>INX API Username</label>
							<input type="text" name='' value={DefUsername} onChange={(e) => setDefUsername(e.target.value)}
								className="form-control grey-input" />
								{	error.username &&
									<p className='hookform-error-message'>A valid Site Username is required</p>}
						</div>
					</div>
					
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>INX API Password</label>
							<input type="password" name='' value={DefPasssword} autoComplete="off" onChange={(e) => setDefPassword(e.target.value)} placeholder='*********'
								className="form-control grey-input" />
								{	error.password &&
									<p className='hookform-error-message'>A strong Password is required</p>}
						</div>
					</div>
					<div className="col-6">
						<div className="form-group inline-input full-label">
							<label>INX Slug </label>
							<input type="text" name='' value={DefUrl} onChange={(e) => setDefUrl(e.target.value)}
								className="form-control grey-input" />								
								{	error.url &&
									<p className='hookform-error-message'>A Slug URL is required</p>}
						</div>
					</div>
					<div>
					{
						DefUrl!=='' &&
							<p className='hookform-info-message text-primary col-12 p-10'>Resulting Site URL will be : https://{bsUrl}/{DefUrl}</p>
					}
					</div>
					{DefId!=='-' &&
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<label>Auto approve Zone</label>
							<TreeSelectCustom
								grey
								selectedZones={selZone}	setselectedZones={setSelZone}	
								classnames={`w-100`}
								fixSite={DefId}
								styleSx={{ maxWidth:'unset' }}
								onchangeFx={(e:any)=>{ 
									setZoneId(e?.value)
								}} 
									isMulti={false}
							/>
							{/* <ReactSelect
								options={ZONELIST.ZONE_DDL}
								className={'common-select common-select-grey w-100'}
								placeholder={'Zone'}
								isMulti={false}
								isClearable
                        	    value={ZONELIST.ZONE_DDL.find((c: { value: number; }) => c.value === parseInt(zoneId))}
                        		onChange={(val) =>{ setZoneId(val?.value); }}
							/> */}
						</div>
					</div>}
					
					
					<div className="col-12">
						<div className="form-group inline-input full-label">
							<div className="checkbox  zindexCorrection">
								<input type="checkbox" name="" checked={siteEnable} onChange={()=> setSiteEnable(!siteEnable) } />
								<label><span>Enable</span></label>
							</div>
						</div>
					</div>
					
					<div className="col-12">
						<div className="modal-footer">
							<button type="button" className="btn btn-outline-blue close-modal-button" onClick={props.handleClose}>Cancel</button>
							<button type="submit" className="btn btn-secondary" onClick={()=>{
								if( DefName!=='' && DefUsername!=='' && 
									(props.Data ? true : DefPasssword!=='') && 
									DefUrl!=='' && DefEmailID!=='' && DefPhone!=='' && DefSiteUsername!==''){
									setError({
										name : false, username : false, password : false, url : false, email : false, phone : false, siteUsername : false, siteRef : false, anprRef : false
									})	
									DefinitonActionFx()
								}else{
									setError({
										name : DefName==='', username : DefUsername==='', 
										password : props.Data ? false : DefPasssword==="", 
										url : DefUrl==='',
										email : DefEmailID==='', phone : DefPhone==='', siteUsername : DefSiteUsername==='', siteRef : siteRef==='',
										anprRef : anprRef==='' 
									})
								}
							}}>
								<ButtonLoader text={`${props.Data ? 'Edit' : 'Add'} Site`} loading={loading}/>
							</button>

						</div>	
					</div>
				</div>
		</ModalLayout>
	);
};

export default AddModal;
