import React from 'react'
import './graphStyles.css'

type Props = {}

const GraphLoader = (props: Props) => {
  return (
    <div id="graphBarLoader">
  <ul>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</div>
  )
}

export default GraphLoader