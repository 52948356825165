import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { addPluralIfMore } from "../../../../utils/utilFunctions";
import { useEffect, //useRef, 
	useState } from "react";
import { useAppSelector } from "../../../../config/hooks";
import { fetch_entryexitoccupancy_dash } from "../../store/action";
import { valConvertTo1Dec, valConvertToTime } from "../../../reports/store/action";
import moment from "moment";
import ReactSelect from "react-select";
// import DashboardFilterWrapper from "../DashboardFilterWrapper";
// import { DatePicker } from "antd";

// const { RangePicker } = DatePicker;
const legendDiv = {
    fontWeight : 600,
    borderRadius : '5px',
    padding: '1%',
    backgroundColor: 'rgba(0,0,0,0.05)'
}
const EntryExitOccupancyCard = () =>{

    const DASHBOARD_DATA = useAppSelector((state) => state?.dashboard);
    
    const [opacitySet, setOpacitySet] = useState(null)
    const [data, setData] = useState([])
	const [isUnRecVeh, setIsUnRecVeh] = useState('0')
    //const [date, setdate] = useState<any>([moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]);
    // const [loading, setloading] = useState(false)

    useEffect(() => {
        const controller = new AbortController();
		const signal = controller.signal;
		const date = [moment().startOf('day'), moment().endOf('day')]
        if(DASHBOARD_DATA?.SELECTED_SITE?.value){
        let postData = {
          SiteID      :   DASHBOARD_DATA?.SELECTED_SITE?.value,
          Todate      :   date[1].format('YYYY-MM-DD'),
          FromDate    :   date[0].format('YYYY-MM-DD'),
          FromTime    :   '00:00:00',
          ToTime      :   '23:59:59',
		  UnknownVehicle : isUnRecVeh
      	}
          fetch_entryexitoccupancy_dash(postData, signal)?.then((res:any)=>{
            if(res?.response?.data){
                let newArr = res?.response?.data?.map((el:any)=>{
                    return { name : valConvertToTime(el.times), 
                        'Vehicles Entered'  : valConvertTo1Dec(el.enryCount),
                        'Vehicles Exited'   : valConvertTo1Dec(el.exitCount),
                        'Occupancy'         : valConvertTo1Dec(el.occupancyPercentage),
                        'Occupancy-toolTipLabel' : ` ( ${el?.currentOccupancyCount??0} vehicle${addPluralIfMore(el?.currentOccupancyCount)})`    
                    }
                })
                setData(newArr)
            }
            else{
                setData([])
            }
          })
          .catch(()=>{
            setData([])
          })
        }
      }, [DASHBOARD_DATA?.SELECTED_SITE?.value, isUnRecVeh
		//, date
	])

    //   const childRef: any = useRef(null);

    //   const handleClose = () => {
    //       if (childRef.current) {
    //           childRef.current.childFunction1();
    //       }
    //   };

    const handleMouseEnter = (o:any) => {
        const { dataKey } = o  
        setOpacitySet(dataKey);
      };
    
    const handleMouseLeave = () => {
        setOpacitySet(null)
      };

	  const ChartObject = () =>{
		console.log('asdfg')
		return (
			<>
				<CartesianGrid stroke="#f5f5f5" />
        			<XAxis dataKey="name" padding={{ left: 20, right:20}} 
						allowDataOverflow={true} 
						// interval={REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA && REPORTSDATA?.REPORTS_GRAPH_FOUR_DATA?.length > 10 ? 2 : 0}
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
				  	/>
        			<YAxis  padding={{ top:20 }} 
					    yAxisId="left"
						allowDecimals={false}
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
						label={{ value: 'Vehicles', angle: -90, fontWeight:600 , fontSize:"12.5px", position: 'insideLeft'}}/>
							   
					<YAxis  padding={{ top:20 }} 
						yAxisId="right"
						unit={'%'}
						orientation="right"
						style={{textTransform :"capitalize" , fontWeight:600 , fontSize:"10.5px" }}
						label={{ value: 'Occupancy Percentage', angle: 90, fontWeight:600 , fontSize:"12.5px", position: 'insideRight'}}/>
					{
						<Tooltip isAnimationActive={false} 
					formatter={(value:any, _:any, payload:any)=>  
						{
			
						  let v:any = ''
						  if(payload  && payload.payload[`${payload.name}-toolTipLabel`] ? payload.payload[`${payload.name}-toolTipLabel`] : false)
							v = <>{value} %<p>{payload.payload[`${payload.name}-toolTipLabel`]}</p></>
						  else v = `${value} Vehicle${addPluralIfMore(!isNaN(parseInt(value)) ? parseInt(value) : 0)}`
						  return v
						  
						}
						}
						/>
        					  

					}
        			<Legend wrapperStyle={legendDiv} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
					<defs>
                    <linearGradient id={`colorLine-dash`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={'#d4421e'} stopOpacity={0.4}/>
                        <stop offset="95%" stopColor={'#d4421e'} stopOpacity={0}/>
                    </linearGradient>
                	</defs>
                <Area type='monotone' dataKey="Occupancy" 
                    legendType='none'
                    tooltipType='none'
					yAxisId="right"
                    strokeOpacity={opacitySet ? opacitySet==='Occupancy' ? 1 : 0.1 : 1 }
                    fillOpacity={opacitySet ? opacitySet==='Occupancy' ? 1 : 0.1 : 1}  fill={`url(#colorLine-dash)`}
                	stroke={'#d4421e'} 
				/>
        			<Bar dataKey="Vehicles Entered" fill="#8884d8"
						yAxisId="left"
						strokeWidth={opacitySet ? opacitySet==='Vehicles Entered' ? 1 : 0.1 : 1 }
						opacity={opacitySet ? opacitySet==='Vehicles Entered' ? 1 : 0.1 : 1 } />
        			<Bar dataKey="Vehicles Exited" fill="#82ca9d"
						yAxisId="left"
						strokeWidth={opacitySet ? opacitySet==='Vehicles Exited' ? 1 : 0.1 : 1 }
						opacity={opacitySet ? opacitySet==='Vehicles Exited' ? 1 : 0.1 : 1 } />
        			<Line type="monotone" dataKey="Occupancy" stroke="#d4421e"
						yAxisId="right"
						strokeWidth={opacitySet ? opacitySet==='Occupancy' ? 2 : 0.1 : 2 }
						strokeOpacity={opacitySet ? opacitySet==='Occupancy' ? 1 : 0.1 : 1 } />
			</>
		)
	  }
    
	return (
		<>
			<div className='ItemLayout__Wrapper'>
				<div className='ItemLayout__Title'>
					<div>
                        Occupancy Trend for last 24Hrs {isUnRecVeh==='2' ? '(Registered Vehicles Only)' : isUnRecVeh==='1' ? '(Unregistered Vehicles Only)' : ''}
					</div>
					<div className='form-group inline-input' style={{ maxWidth : '50%', marginBottom: '1px', justifyContent:'flex-end' }}>
							
						<label className='label-dnd-menuitem' style={{ marginRight:'0px' }}>Vehicle Type</label>
								<ReactSelect
										className='common-select-grey'
										options={[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]}
								onChange={(val:any) =>{ setIsUnRecVeh(val?.value); }}
										value={
											[
									{ value : '0', label : 'All Vehicles' },
									{ value : '1', label : 'Unregistered' },
									{ value : '2', label : 'Registered' }
								]?.find((el:any) => el.value===isUnRecVeh) ?? ''
										}
										placeholder={'Vehicle Type'}
								/>
						
					</div>
					{/* <div>
						<div style={{ fontSize: '12px' }}>
							{date[0].format('DD MMM YYYY hh:mm A')} - {date[1].format('DD MMM YYYY  hh:mm A')}
						</div>
						<DashboardFilterWrapper ref={childRef} loading={loading} handleClose={handleClose}>
							<div className='form-group select-input full-label'>
								<label className='label-dnd-menuitem'>Date Range</label>
								<RangePicker size='large' showTime inputReadOnly onChange={(date: any) => setdate(date)} value={date} allowClear={false} />
							</div>
						</DashboardFilterWrapper>
					</div> */}
				</div>

				<div className='ItemLayout__Content'>
                    <ResponsiveContainer  width="100%" height="100%"   id={`BarLineComposedChartId`} >
							<ComposedChart
        					  width={500}
        					  height={400}
        					  data={data}
        					  margin={{
        					    top: 20,
        					    right: 20,
        					    bottom: 20,
        					    left: 20,
        					  }}
						  
        					>
        					  {ChartObject()}
        					</ComposedChart>
					</ResponsiveContainer>
				</div>
			</div>
		</>
	);
}

export default EntryExitOccupancyCard