import Request from "../../../config/api";
import { APPLICATION_LOGS_URL, AUDIT_LOGS_URL } from "../../../config/endpoint"
import { UPDATE_APPLICATION_LOGS_DATA, UPDATE_APPLICATION_LOGS_ERROR, UPDATE_APPLICATION_LOGS_LOADER, UPDATE_AUDIT_LOGS_DATA, UPDATE_AUDIT_LOGS_ERROR, UPDATE_AUDIT_LOGS_LOADER } from "./logsSlice";
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';


export const fetch_application_logs = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = APPLICATION_LOGS_URL
    dispatch(UPDATE_APPLICATION_LOGS_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        console.log('AAPLIRESP',res)
        if (statusCode === 200) {
            dispatch(
                UPDATE_APPLICATION_LOGS_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
    dispatch(UPDATE_APPLICATION_LOGS_ERROR({ error : "some error occured" }))

        }
    })
}

export const fetch_audit_logs = (history: any, dispatch: any, postData: any, signal?: AbortSignal) =>{
    let api_url = AUDIT_LOGS_URL
    dispatch(UPDATE_AUDIT_LOGS_LOADER({ loader : true }))
    Request('post',api_url,postData,signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_AUDIT_LOGS_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
    dispatch(UPDATE_AUDIT_LOGS_ERROR({ error : "some error occured" }))

        }
    })
}

export const fetch_audit_log_detail = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) =>{
    let api_url = AUDIT_LOGS_URL+id+'/'
    Request('get',api_url,'',undefined,Sucess,Failed)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
        } else {
        }
    })
}
