/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StatusChipComponent from '../../../../common/components/statusChipComponent';
import CommonTable from '../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { fetch_Users_AllocatedParkingslist } from '../../store/action';
import ViewEditModal from './viewEditModal';
import dayjs from 'dayjs';

function ListTable() {
	const fetchIdRef                    = useRef(0);
	const [viewModal, setViewModal] = useState(false)
	const [appDetail, setAppDetail] = useState(null)
    const ViewModalClose = () => setViewModal(false)
	
	const navigate                      = useNavigate();
	const dispatch                      = useAppDispatch();
	const USERS_ALLOCATIONS = useAppSelector((state) => state?.findacar)
	const ViewModalOpen = (data:any) =>{
		setAppDetail(data)
		setViewModal(true)
	}

	const statusArr:any = [ 
		{ value : 'warning', text : 'Pending', n : 0 }, { value : 'success', text : 'Approved', n : 1 }, 
		{ value : 'warning', text : 'Expired', n : 11 },
		{ value : 'pending', text : 'Cancelled', n : 3 },
	 	{ value : 'error', text : 'Revoked', n : 4 },
		{ value : 'error', text : 'Blacklisted', n : 6 }  
	]

	const columns = React.useMemo(
		()	=>	[
			{ Header: 'ID', accessor:'parkingID' ,orderByKey : "parkingID"},
			{ Header: 'START DATE', accessor:'startDate', DateFormat : true, FormatType : 'DD-MMM-YYYY' ,orderByKey : "startDate"},
			{ Header: 'END DATE', accessor:'endDate', DateFormat : true, FormatType : 'DD-MMM-YYYY' ,orderByKey : "endDate"},
			// { Header: 'USER ID', accessor:'userID',orderByKey : "RequestedFUID"},
			{ Header: 'Name', accessor:'fullName'
			//,orderByKey:'RequestedFUName'
				,	orderByKey:'fullName'
			},
			{ Header: 'Email', accessor: 'emailID', orderByKey: 'emailID'},
			{ Header: 'Contact', accessor: 'contactNo', orderByKey: 'contactNo'},
			{ Header: 'Designation', accessor: 'desName', orderByKey: 'desName'},
			{ Header: 'Department', accessor: 'depName', orderByKey: 'depName'},
			{ Header: 'Personal Grade', accessor: 'fK_PriorityLevel', orderByKey: 'fK_PriorityLevel'},

            { Header: 'Plate Number', accessor:'plateNumber', orderByKey:'plateNumber'},
			{ Header: 'Vehicle Category', accessor:'vehicleCategory', orderByKey:'vehicleCategory'},
			{ Header: 'Place of Registration', accessor:'placeOfRegistration', orderByKey:'placeOfRegistration'},
			{ Header: 'Site', accessor: 'siteName', orderByKey: 'siteName'},
            { Header: 'Zone', accessor:'zoneName', orderByKey : 'zoneName'},
            { Header: 'Bay No.', accessor:'bayName', orderByKey : 'bayName'},
            { Header: 'Type', accessor:'requestTypeName'},
            { Header: 'Status', accessor:'requestStatus_str',
			Cell: ({ row }: any) => {

				// const checkIfExpired = () =>{
				// 	console.log('CHECKIFEXPIRED',dayjs(row?.original?.endDate,'YYYY-MM-DD'),'\nCHECKIF2=>',dayjs())
				// 	return dayjs(row?.original?.endDate,'YYYY-MM-DD').diff(dayjs(),'days') <= 0
				// }

				return (
					<Fragment>
						<StatusChipComponent
							 chipType={
								// checkIfExpired() ?
								// 'warning' :
								statusArr?.find((el:any) => el?.n === parseInt(row?.original?.currentStatus) )?.value??'warning'
							} 
							 classname={
								row?.original?.currentStatus===3 ? 'orange' : ''
							}
							 text={
								// checkIfExpired() ?
								// 'Expired' :
								row?.original?.currentStatus_str
							} 
						/>
					</Fragment>
				);
			},},
            {
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,

				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a  onClick={() => ViewModalOpen(row?.original)}  className="btn btn-sqaure btn-white modal-button"><i className="fa-solid fa-eye"></i></a>
							</div>
						</Fragment>
					);
				},
				show: true,
			} 	
		],
		[]
	);

	type IfetchData = {
		pageSize :number,
		pageIndex :number,
		signal :AbortSignal,
		OrderBy : string
		filters: {query: string;}

	}


	const fetchData = useCallback(({ pageSize, pageIndex ,signal ,OrderBy,filters}:IfetchData) => {
		const fetchId = ++fetchIdRef.current;
		if (fetchId === fetchIdRef.current) {
			fetch_Users_AllocatedParkingslist(navigate, dispatch, {
				'filters': {
					'query':filters.query || '',
					OrderBy
				}, 
				'pagination': {
					'pageNo': pageIndex,
					'limit': pageSize,
				},
			},signal);
		}
	}, [dispatch, navigate]);


    return (
		<>
			<CommonTable 
				data={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_LIST_DATA??[]} 
				columns={columns} 
				pageCount={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_PAGINATION?.totalCount ?? 0} 
				fetchData={fetchData} 
		  		limit={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_PAGINATION?.limit ?? 1} 
				page_no={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_PAGINATION?.pageNo ?? 1}
				error={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_ERROR ?? ''}
				filters={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_FILTER}		
				loading={USERS_ALLOCATIONS?.USER_ALLOCATED_PARKING_LOADER}			

			
			/>
			{
				viewModal && 
				<ViewEditModal  open={viewModal} handleClose={ViewModalClose} Data={appDetail} />

			}
		</>
    );
}

export default ListTable;