import React from 'react';
import ModalLayout from '../../../../common/layouts/ModalLayout';
import { MenuItem } from '@mui/material';
import { widgets } from './AllDashboardWidgets';

interface Iprops {
	onRemoveItem: (id: number) => void;
	onAddItem: (id: number) => void;
	items: any[];
	// setItems: ()=>void;
	open: boolean;
	handleClose: () => void;
}
const SelectDashboardItems: React.FC<Iprops> = ({ open, handleClose, onRemoveItem, onAddItem ,items=[] }) => {
	return (
		<ModalLayout title='Customize Widgets' subtitle='Choose from avialable widgets' open={open} handleClose={handleClose}>
			{widgets.map((item, key) => {
				return (
					<MenuItem sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }} key={key}>
						<label className='label-dnd-menuitem'>{item?.name}</label>
						<input
							type='checkbox'
							style={{ border: '1px solid red' }}
							checked={items.some((ele)=> ele.id === item?.id)}
							onChange={(e) => {
								if (e.target.checked) {
									onAddItem(item?.id);
								} else {
									onRemoveItem(item?.id);
								}
							}}
						/>
					</MenuItem>
				);
			})}
		</ModalLayout>
	);
};

export default SelectDashboardItems;
