import React, { useEffect, useState } from 'react'
import { fetch_todays_parking_count,  } from '../../../store/action'

type Props = {}

const DashIcons = (props: Props) => {

	const [data, setdata] = useState<any>()
	useEffect(() => {
		fetch_todays_parking_count()?.then((res)=>{
			setdata(res?.response?.data)
		})
	}, [ ])
	
	

  return (
    <div className="w-100 mb-20">
			<div className="row mts-20">
			<div className="col-3">
				<div className="icon-box white-bg">
					<div className="icon-wrap">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Today's Parking   <span>{data?.totalParkingCount ?? 0}</span></h3>
				</div>
			</div>
			<div className="col-3">
				<div className="icon-box white-bg">
					<div className="icon-wrap green">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Active Parking <span className="text-green">{data?.activeParkingCount ?? 0}</span></h3>
				</div>
			</div>
            <div className="col-3">
				<div className="icon-box white-bg">
					<div className="icon-wrap red">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Active  Vehicle Violations <span className="text-red">{data?.activeViolationCount ?? 0}</span></h3>
				</div>
			</div>
			<div className="col-3">
				<div className="icon-box white-bg">
					<div className="icon-wrap orange">
						<i className="fa-solid fa-car"></i>
					</div>
					<h3>Active Unregistered Parking <span className="text-orange">{data?.activeUnknownParkingCount ?? 0}</span></h3>
				</div>
			</div>
		
		
		</div>
		</div>
  )
}

export default DashIcons