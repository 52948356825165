// Color Codes	Parking Zone 
//  RGB (127,127,127)	Zone 1 (Platinum)
//  RGB (255,192,0)	Zone 2 (Gold)
//  RGB ( 192,0,0)	Zone3 (Dark Red)
//  RGB(51,189,189)	Zone 4 (Tiffany)
//  RGB (128,96,0)	Zone 5 (Bronze)
//  RGB ( 47,117,181)	Zone 6 (Blue)
//  RGB ( 237,125,49)	Zone 7 (Orange)

export default function ZoneColorCodes(n:number){
    let arr = [
        'rgba(  127,    127,    127,    1)',
        'rgba(  255,    192,    0,      1)',
        'rgba(  192,    0,      0,      1)',
        'rgba(  51,     189,    189,    1)',
        'rgba(  128,    96,     0,      1)',
        'rgba(  47,     117,    181,    1)',
        'rgba(  237,    125,    49,     1)'
    ]
    return arr[n-1]
}

export function ZoneColors(el:any,op?:any){
    let arr = [
    {   
        zone    :   1,
        name    :   'zone1(platinum)', 
        col     :   `rgba(  127,    127,    127,    ${op??'1'})`
    },
    {   
        zone    :   2,
        name    :   'zone2(gold)', 
        col     :   `rgba(  255,    192,    0,      ${op??'1'})`
    },
    {   
        zone    :   3,
        name    :   'zone3(darkred)', 
        col     :   `rgba(  192,    0,      0,      ${op??'1'})`
    },
    {   
        zone    :   4,
        name    :   'zone4(tiffany)', 
        col     :   `rgba(  51,     189,    189,    ${op??'1'})`
    },
    {   
        zone    :   5,
        name    :   'zone5(bronze)', 
        col     :   `rgba(  128,    96,     0,      ${op??'1'})`
    },
    {   
        zone    :   6,
        name    :   'zone6(blue)', 
        col     :   `rgba(  47,     117,    181,    ${op??'1'})`
    },
    {   
        zone    :   7,
        name    :   'zone7(orange)', 
        col     :   `rgba(  237,    125,    49,     ${op??'1'})`
    }
    ]
    return arr?.find(e => {
        let n = el?.trim()?.toLowerCase()?.replace(/\s/g,'')
        console.log('ZONENEMAE, n',n)
        return e?.name === n
    })?.col??null
}