import React, { Fragment, useEffect, useRef, useState } from 'react';
import { fetch_treeMap_withPermission, fetch_webusers_by_zone } from '../../security/store/action';
import AddModal from './addModal';
import dummyDp from '../../../assets/images/common/profile-pic.png';
import nodatafound from '../../../assets/images/common/nodatafound.png';
import noimage from '../../../assets/images/common/noimage.jpg';

import { useAppDispatch } from '../../../config/hooks';
import { UPDATE_WEBUSER_REQUEST_DATA } from '../../security/store/securitySlice';
import { fetchParkingZonesBayCount } from './../store/action';
import ProgressBar from '../../../common/components/graph_components/ProgressBar';
import NestedSelect from '../../../common/components/nested_select/NestedSelect';
import PopUp from './../../../common/components/popUp';
import PermissionWrapper from '../../../common/components/PermissionWrapper';
import { subModuleList } from '../../../config/subModuleList';
import { COLORS, COLOR_FX } from '../../../utils/Data';

type Props = {};

type T_Zone = {
	zoneID: number;
	zoneName: string;
};

const ParkingZone = (props: Props) => {
	const [addModal, setaddModal] = useState(false);
	const [tree, setTree] = useState<any>([]);
	const [selectedZone, setselectedZone] = useState<T_Zone>();
	const [selectedFloor, setselectedFloor] = useState<any>();
	const [selectedSite, setselectedSite] = useState<any>();

	const [BayData, setBayData] = useState<any>();

	const [treeLoading, settreeLoading] = useState<any>(false);

	const [usersLoading, setusersLoading] = useState<any>(false);

	let dispatch = useAppDispatch();

	const addModalOpen = () => setaddModal(true);
	const addModalClose = () => {
		setaddModal(false);
		dispatch(
			UPDATE_WEBUSER_REQUEST_DATA({
				data: [],
				filters: '',
				pagination: {
					'pageNo': 1,
					'limit': 10,
				},
			})
		);
	};

	useEffect(() => {
		settreeLoading(true);
		const success = (res: any) => {
			setTree(res?.response ?? []);

			setTimeout(() => {
				settreeLoading(false);
			}, 1500);
		};
		const failed = (err: any) => {
			setTimeout(() => {
				settreeLoading(false);
			}, 1500);
		};
		fetch_treeMap_withPermission(success, failed);
	}, []);

	const [usersInZoneList, setusersInZoneList] = useState([]);

	const fetchUsersInZone = (zoneID: number) => {
		setusersLoading(true);
		fetch_webusers_by_zone(zoneID)?.then((res) => {
			setTimeout(() => {
				setusersLoading(false);
			}, 1500);

			setusersInZoneList(res?.response?.data ?? []);
		});
	};

	const fetchBayData = (zoneID: number) => {
		fetchParkingZonesBayCount(zoneID)?.then((res) => {
			setBayData(res?.response);
		});
	};

	useEffect(() => {
		if (selectedZone) {
			fetchUsersInZone(selectedZone?.zoneID);
			fetchBayData(selectedZone?.zoneID);
		}
	}, [selectedZone]);

	useEffect(() => {
		if (tree) {
			let site = tree[0];
			let floor = tree[0]?.siteChild[0];
			let zone = tree[0]?.siteChild[0]?.levelChild[0];
			setselectedFloor(site)
			setselectedFloor(floor)
			setselectedZone(zone);
		}
	}, [tree]);

	const newLocal = (
		<div className='input-like-div pos-rel' style={{ overflow: 'hidden' }}>
			{selectedZone?.zoneName}
			<div
				style={{
					position: 'absolute',
					right: '10px',
					borderLeft: '1px solid var(--lightgrey-second)',
					height: '30px',
					padding: '8px 0px 10px 10px',
				}}>
				<i className='fa fa-chevron-down' style={{ color: 'var(--lightgrey-second)' }} />
			</div>
		</div>
	);

	const parentRef: any = useRef(null);

	const childRef: any = useRef(null);

	const handleClose = () => {
		if (childRef.current) {
			childRef.current.childFunction1();
		}
	};

	React.useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu then close the menu
			let targt: any = parentRef.current;
			if (targt && !targt.contains(e.target)) {
				console.log('targer');
				handleClose();
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, []);

	return (
		<div className='main-body-content'>
			<div className='w-full'>
				{/* <button onClick={handleClose}>yoo</button> */}
				<div className='row'>
					{/* <div className='col-3'>
						<div className='white-card'>
							<form>
								<div className='form-group'>
									<div className='pos-rel'>
										<input className='form-control grey-input' name='' placeholder='Search Here' />
										<i className='fa-solid fa-magnifying-glass'></i>
									</div>
								</div>
							</form>

							<div className='zone-list capitalize'>
								{tree.map((site: any, key: any) => {
									return (
										<Fragment key={key}>
											{site?.siteChild.map((floor: any, key1: React.Key | null | undefined) => {
												return (
													<Fragment key={key1}>
														<h2  style={{ textTransform :"capitalize"}}>
															{site?.siteName} - {floor?.levelName}
														</h2>
														<ul>
															{floor?.levelChild.map((zone: any, key2: number) => {
																return (
																	<Fragment key={key2}>
																		<li onClick={() => setselectedZone(zone)}>
																			<div
																				className={
																					selectedZone?.zoneID === zone?.zoneID
																						? 'ml-5 pointer pointer-active'
																						: 'ml-5 pointer'
																				}>
																				{zone?.zoneName}
																			</div>
																		</li>
																	</Fragment>
																);
															})}
														</ul>
													</Fragment>
												);
											})}
										</Fragment>
									);
								})}
							</div>
						</div>	
					</div> */}
					<div className='col-12'>
						<div className='zone-detail-block'>
							<div className='row dflex justify-space-between '>
								<div className='col-4'>
									<PopUp icon={newLocal} placement='bottom-start' style={{ paddingTop: '5px' }} ref={childRef}>
										<NestedSelect
											selectedZone={selectedZone}
											setselectedZone={setselectedZone}
											tree={tree}
											ref={parentRef}
											setselectedFloor={setselectedFloor}
											setselectedSite={setselectedSite}
											style={{ width: "30vw" }}

										/>
									</PopUp>
								</div>
								<div className='col-8 dflex gap-10 ' style={{ justifyContent: 'flex-end' }}>
									<PermissionWrapper subModuleID={subModuleList['Parking Zones']} type='isCreate'>
										<div className=''>
											<a  className='btn btn-secondary modal-button' onClick={addModalOpen}>
												Add New Request{' '}
											</a>
										</div>
									</PermissionWrapper>
								</div>
							</div>
							<div className='row'>
								<div className='col-5'>
									<div className='white-card new-scrollbar mb-20' style={{ maxHeight: '35vh', overflow: 'auto' }}>
										<div className='bay-heading white-card-heading dflex align-items-center justify-space-between '>
											<h2 className='border-title'>Total Bay</h2>
											<h2 className='border-title'>{BayData?.bayCount}</h2>
										</div>
										<div
											className='bay-stats progressbar-box new-scrollbar '
											style={{ maxHeight: '19vh', overflow: 'auto' }}>
											{BayData?.data &&
												BayData?.data.map((item: any, key3: number) => {
													let value = (Number(item?.occupaidCount) / Number(item?.totalCount)) * 100;
													let perc = isNaN(value) ? 0 : value;
													return (
														<Fragment key={key3}>
															<ProgressBar
																value={perc}
																label={item?.bTypeName}
																endLabel={`${item?.occupaidCount}/${item?.totalCount}`}
																// color={COLORS[key3]}
																color={COLOR_FX(key3)}

															/>
														</Fragment>
													);
												})}
										</div>
									</div>
									<div className='white-card new-scrollbar' style={{ maxHeight: '40vh' }}>
										<div className='white-card-heading dflex align-items-center justify-space-between'>
											<h2 className='border-title'>Sketch Diagram</h2>
											<h2 className='border-title'>
												<span>Site : {selectedSite?.siteName}</span>
												<span>{selectedFloor?.levelName}</span>
											</h2>
										</div>
										<div className='sketch-img' style={{}}>
											<img
												src={selectedFloor?.sketchImageUrl || noimage}
												alt=''
												srcSet=''
												style={{
													maxHeight: '30vh',
													display: 'block',
													marginLeft: 'auto',
													marginRight: 'auto',
												}}
											/>
										</div>
									</div>
								</div>
								<div className='col-7'>
									<div className='white-card zone-bay-info new-scrollbar'>
										<div className='bay-heading white-card-heading dflex align-items-center justify-space-between '>
											<h2 className='border-title'>Total User Allocation</h2>
											<h2 className='border-title'>{usersInZoneList.length ?? 0}</h2>
										</div>
										<div className='user-allocated-box'>
											<div
												className='userslist-grid-layout pos-rel new-scrollbar'
												style={{ maxHeight: '60vh', overflow: 'auto' }}>
												{/* <div className='row pos-sticky'>
													<div className='col-12 ' onClick={addModalOpen}>
														<a
															href='#user-request'
															className='modal-button user-allocated-wrap add-user-block bg-blue'>
															<i className='fa fa-plus'></i>
															<p>Add New User</p>
														</a>
													</div>
												</div> */}

												{usersLoading ? (
													<>
														{[...Array(9)].map((item) => {
															return (
																<Fragment key={item}>
																	<div className='user-allocated' style={{ minHeight: '150px' }}>
																		<div className='user-allocated-wrap'>
																			<div className='user-allocated-profile dflex align-items-center'>
																				<div className='user-allocated-img'>
																					<img src={dummyDp} alt='' />
																				</div>
																				<div className='user-info skeleton'>
																					<strong className='skeleton'></strong>
																					<small></small>
																				</div>
																				{/* <div className='remove-alloc-user ml-auto'>
																			<a href='#'>
																				<i className='fa fa-close'></i>
																			</a>
																		</div> */}
																			</div>
																			<div className='user-contact-info'>
																				<p className='skeleton'></p>
																				{/* <p className='skeleton'></p> */}
																			</div>
																		</div>
																	</div>
																</Fragment>
															);
														})}
													</>
												) : (
													usersInZoneList.map((item: any, key) => {
														return (
															<Fragment key={key}>
																<div className='user-allocated ' style={{ minHeight: '150px' }}>
																	<div className='user-allocated-wrap'>
																		<div className='user-allocated-profile dflex align-items-center'>
																			<div className='user-allocated-img'>
																				<img src={dummyDp} alt='' />
																			</div>
																			<div className='user-info capitalize'>
																				<strong className='capitalize' style={{ textTransform: "capitalize" }}>
																					{item?.fullName}
																				</strong>
																				<small className='capitalize' style={{ textTransform: "capitalize" }}>
																					{item?.designation}
																				</small>
																			</div>
																			{/* <div className='remove-alloc-user ml-auto'>
																				<a href='#'>
																					<i className='fa fa-close'></i>
																				</a>
																			</div> */}
																		</div>
																		<div className='user-contact-info'>
																			<p>{item?.contactNo}</p>
																			<p>{item?.emailID}</p>
																		</div>
																	</div>
																</div>
															</Fragment>
														);
													})
												)}

												{!usersLoading && usersInZoneList.length === 0 && (
													<>
														<div
															className='user-allocated borderbox justify-content-center'
															style={{ minHeight: '150px' }}>
															<img
																style={{
																	maxHeight: '150px',
																	display: 'block',
																	marginLeft: 'auto',
																	marginRight: 'auto',
																	//   width: '50%',
																}}
																alt='no data'
																src={nodatafound}
															/>
														</div>
													</>
												)}
											</div>
										</div>
									</div>
								</div>
								{/* 
								<div className='col-5'>
									<div className='white-card'>
										<div className='white-card-heading dflex align-items-center justify-space-between'>
											<h3>Sketch Diagram</h3>
											<h3>
												<span>Site : Site 1</span>
												<span>Floor 1</span>
											</h3>
										</div>
										<div className='sketch-img'>
											<img src='assets/images/common/placeholder-img.png' alt='' srcSet='' />
										</div>
									</div>
								</div> */}
							</div>
							{/* <div className='row'>
								<div className='col-12'>
									<div className='white-card'>
										<div className='white-card-heading dflex align-items-center justify-space-between'>
											<h3>Sketch Diagram</h3>
											<h3>
												<span>Site : Site 1</span>
												<span>Floor 1</span>
											</h3>
										</div>
										<div className='sketch-img'>
											<img src='assets/images/common/placeholder-img.png' alt='' srcSet='' />
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* {viewModal ? (
				<ViewModal open={viewModal} handleClose={viewModalClose} />
			) : (
				<AddModal open={addModal} handleClose={addModalClose} viewModalOpen={viewModalOpen} />
			)} */}

			{addModal && <AddModal open={addModal} handleClose={addModalClose} selectedZone={selectedZone} />}
		</div>
	);
};

export default ParkingZone;
