import React from 'react'
import './commonStyle.css'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  loading: boolean,
  text?: string
}

const ButtonLoader = ({ loading, text }: Props) => {
  return (
    <div className='pos-rel'>
      {
        loading ? <>
          <div style={{ position:"absolute" , display:'flex' , justifyContent:"center" , alignItems:"center" , width:'100%', top:"-8px"}}>
          <CircularProgress size={30} color="inherit"/>
          </div>
          <div style={{ visibility:'hidden'}}>
          loading

          </div>
        </> : text
      }
    </div>
  )
}

export default ButtonLoader