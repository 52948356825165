import React, { useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import ProgressBar from '../../../../../common/components/graph_components/ProgressBar'
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks'
import { COLORS, COLOR_FX } from '../../../../../utils/Data'
import { fetch_violation_count, fetch_violation_type_count } from '../../../store/action'

type Props = {}

const DashIcons = (props: Props) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        let postData = {
            "query" : ''
        }
        fetch_violation_type_count(dispatch, postData)
        fetch_violation_count(dispatch, postData)
    }, [])
    
    const COUNTS = useAppSelector((state) => state?.violations)

    return (
        <div className="dash-icon-boxes violations-icon-boxes">
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-6">
                            <div className="icon-box bg-white">
                                <div className="icon-wrap bg-red">
                                    {/* <i className="fa-solid fa-user"></i> */}
                                    <i className="fa-regular fa-circle-xmark"></i>
                                </div>
                                <h3>Total Violation <span className='text-red'><strong>{COUNTS.VIOLATION_COUNT?.totlaViolations}</strong></span></h3>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="icon-box bg-white">
                                <div className="icon-wrap ">
                                    <i className="fa-solid fa-user"></i>
                                </div>
                                <h3>First Penalty <span className=""><strong>{COUNTS.VIOLATION_COUNT?.firstPenalty}</strong></span></h3>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="icon-box bg-white">
                                <div className="icon-wrap bg-darkgreen">
                                <i className="fa-solid fa-user-shield"></i>
                                    {/* <i className="fa-solid fa-envelope"></i> */}
                                </div>
                                <h3>Second Penalty <span className="text-darkgreen"><strong>{COUNTS.VIOLATION_COUNT?.secondPenalty}</strong></span></h3>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="icon-box bg-white">
                                <div className="icon-wrap bg-yellow">
                                    {/* <i className="fa-solid fa-phone"></i> */}
                                    <i className="fa-solid fa-toolbox"></i>
                                </div>
                                <h3>Third Penalty & above <span className="text-yellow"><strong>{COUNTS.VIOLATION_COUNT?.thirdPenalty}</strong></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="bay-stats progressbar-box white-card">
                            {
                                COUNTS?.VIOLATION_COUNT_BYTYPE ? 
                                COUNTS.VIOLATION_COUNT_BYTYPE.slice(0,7).reverse().map((el:any , index:number)=>{

                                    let color = ['red','yellow','primary']
                                    let violationPerc = ((isNaN( parseInt(el.violationCount) / COUNTS.VIOLATION_COUNT?.totlaViolations) ? 
                                        0 : 
                                        (parseFloat(el.violationCount) / COUNTS.VIOLATION_COUNT?.totlaViolations)
                                        ) * 100)
                                    let showVal = Math.round((violationPerc+Number.EPSILON)*100)/100

                                    if(isNaN(violationPerc)){
                                        violationPerc = 0
                                    }

                                    return (
                                     
                                        	<ProgressBar
											value={violationPerc}
											label={el?.violationName}
											endLabel={`${showVal}%`}
                                            className={color[index]}
                                            // textClass={`${col[mathDummyNo]}`}
											tootTipText={` Violations : ${el.violationCount} /  ${COUNTS.VIOLATION_COUNT?.totlaViolations}`}
											// color={COLORS[index]}
											color={COLOR_FX(index)}


										/>
                                    )
                                })
                                : null
                            }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashIcons