/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useRef, useCallback } from 'react';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { fetch_parking_unrecognized } from '../../../store/action';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';

type Props = {};

function time_convert(num:number)
 { 
	var days = ((num / 60) / 24) >= 0 ? Math.floor((num / 60) / 24) : null
    var d = (num / 60) % 24
    var hours = days && d >= 0 ? Math.floor(d) : Math.floor(num / 60) >= 0 ? Math.floor(num / 60) : null;          
	var minutes = hours ? Math.ceil((d-hours) * 60) : Math.ceil(d * 60);
//   console.log('MIN',num,'dyz',days,'d',d,'hrs',hours,'min',minutes)
  return `${days ? `${days} Days` : ''} ${hours ? `${hours} hr ${minutes} min` : `${minutes} min`}`;         
}

const ListTable = (props: Props) => {
	const fetchIdRef = useRef(0);

	const dispatch = useAppDispatch();

	const REALTIME_DATA = useAppSelector((state) => state?.realtime);

	const columns = React.useMemo(
		() => [
			
			{ Header: 'Plate Number', accessor: 'plateNumber'},

			{ Header: 'date', id:"datetype" ,accessor: 'parkingDate'  , DateFormat: true ,FormatType: 'DD-MMM-YYYY', orderByKey:  'parkingDate' , tdClass : 'resetWhiteSpaceWrap'},
			{ Header: 'IN time' ,accessor: 'inTime', orderByKey :  'inTime', tdClass : 'resetWhiteSpaceWrap'},
			{ Header: 'Out time' ,accessor: 'outTime', orderByKey :  'outTime', tdClass : 'resetWhiteSpaceWrap'},

			{ Header: 'Dwell Time', accessor: 'duration' , tdClass : 'resetWhiteSpaceWrap', orderByKey:'duration',

			Cell: ({ row }: any) => {
				return (
					<Fragment>
				

				   {time_convert(row?.original?.duration ?? 0)}
					</Fragment>
				);
			},
		
		},


	

			{ Header: 'zone', accessor: 'zoneName', orderByKey:'zoneName' },
			{ Header: 'Bay', accessor: 'bayName', orderByKey: 'bayName' },
			{ Header: 'Bay type', accessor: 'bTypeName', orderByKey: 'bTypeName' },
			{ Header: 'Visit Status', orderByKey :  'outTime',
				Cell: ({ row }: any) => {
					if(row?.original?.outTime===null)
						return <StatusChipComponent chipType='pending' text='Visit On-going'  />
					else
						return <StatusChipComponent text='Visit Over'  /> 
				}
			},
			{
				Header: '',
				accessor: 'actionBar',
				hideHeader: true,
			},
		],
		[]
	);

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters }: any) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parking_unrecognized(
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
					},
					signal
				);
			}
		},
		[dispatch]
	);

	return (
		<>
			<CommonTable
				columns={columns}
				data={REALTIME_DATA?.UNRECOGNIZED_LIST_DATA ?? []}
				fetchData={fetchData}
				pageCount={REALTIME_DATA?.UNRECOGNIZED_PAGINATION?.totalCount ?? 0}
				loading={REALTIME_DATA?.UNRECOGNIZED_LOADER}
				page_no={REALTIME_DATA?.UNRECOGNIZED_PAGINATION?.pageNo ?? 1}
				limit={REALTIME_DATA?.UNRECOGNIZED_PAGINATION?.limit ?? 1}
				filters={REALTIME_DATA?.UNRECOGNIZED_FILTER}
				error={REALTIME_DATA?.UNRECOGNIZED_ERROR}
				adjustSpacing={true}
			/>
		</>
	);
};

export default ListTable;
