import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../../../common/components/FormSelect';
import { fetch_floorLevelDDL, fetch_zoneDDL } from '../../../../../common/general/action';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FetchAvailabilityCheck } from '../../../store/action';

type Props = {};

const FilterBar = (props: Props) => {
	const GENERAL_DDL = useAppSelector((state) => state?.general);
	let dispatch = useAppDispatch();
	const [filterData, setfilterData] = useState<any>(null)

	const { control, setValue, getValues , watch , resetField} = useForm({
		mode: 'onBlur',
	});

	const ParikignAvlFilter = useAppSelector((state) => state?.realtime?.AVAILABILITY_CHECK_FILTER);


	useEffect(() => {
		const controller = new AbortController();
		if(watch('SiteID')){
			fetch_floorLevelDDL(dispatch, { "query" : ` FK_SiteID=${watch('SiteID')}` })

		}
		return () => controller.abort();
    }, [dispatch, watch('SiteID')]);



	useEffect(() => {
		const controller = new AbortController();

		if(watch('FloorID')){
			fetch_zoneDDL(dispatch, { 'query' : `fK_FLevelID=${watch('FloorID')}` });
		}
		return () => controller.abort();
    }, [dispatch, watch('FloorID')]);

	useEffect(() => {


		if(getValues('SiteID')) return
		if (ParikignAvlFilter && ParikignAvlFilter?.SiteID && ParikignAvlFilter?.FloorID && ParikignAvlFilter?.ZoneID) {
			setValue('SiteID', ParikignAvlFilter?.SiteID);
		}else if(GENERAL_DDL?.SITE_DDL && GENERAL_DDL?.SITE_DDL?.length>0 ){
			setValue('SiteID', GENERAL_DDL?.SITE_DDL[0]?.value);

			
			// setValue('FloorID', GENERAL_DDL?.FLOORLEVEL_DDL[0]?.value	);
			// setValue('ZoneID', GENERAL_DDL?.ZONE_DDL[0]?.value);
		}
	}, [ParikignAvlFilter, setValue, GENERAL_DDL]);


	useEffect(() => {

	  if(getValues('SiteID')){
		resetField("FloorID")
		resetField("ZoneID")

		let postData = {
			'SiteID': getValues('SiteID'),
		};
		FetchAvailabilityCheck(dispatch, postData);
	  }
	
	 
	}, [getValues('SiteID')])
	

	const filterSearch = () => {
		let postData = {
			'SiteID': getValues('SiteID'),
			'FloorID': getValues('FloorID'),
			'ZoneID': getValues('ZoneID'),
		};
		if(postData?.SiteID && ((postData?.FloorID)||(postData?.ZoneID))){
			setfilterData(postData)
			FetchAvailabilityCheck(dispatch, postData);
		}
		
	}

	const clearSearch = () => { 
		let postData = {
			'SiteID': getValues('SiteID'),
		
		};
		setValue('SiteID', GENERAL_DDL?.SITE_DDL[0]?.value);
		resetField("FloorID")
		resetField("ZoneID")
		setfilterData(null)

		FetchAvailabilityCheck(dispatch, postData);
		

	 }


	return (
		<div className='search-filter-row mb-30'>
			<div className='row'>
				<div className='col-5'>
					<ul>
						<li>
							<a  className='active pointerDefaultSet'>
								<i className='fa-solid fa-magnifying-glass'></i> Quick Search
							</a>
						</li>
						{filterData && (
								<li>
									<a onClick={clearSearch}>
										<i className='fa-solid fa-times'></i>Clear All Filters
									</a>
								</li>
							)}
				
					</ul>
				</div>
			</div>

			<div className='filter-block mb-30' id='quick-search'>
					<div className='row'>
						<div className='col'>
							<div className='form-group'>
								<FormSelect
									control={control}
									name='SiteID'
									options={GENERAL_DDL?.SITE_DDL || []}
									// grey={true}
									isMulti={false}
									placeholder='site'
								/>
							</div>
						</div>
						<div className='col'>
							<div className='form-group'>
								{/* <select className='form-control'>
									<option>Level</option>
								</select> */}
								<FormSelect
									control={control}
									name='FloorID'
									options={GENERAL_DDL?.FLOORLEVEL_DDL || []}
									// grey={true}
									isMulti={false}
									placeholder='level'
									onchangeFx={()=>{
										resetField('ZoneID')
									}}
								/>
							</div>
						</div>

						<div className='col'>
							<div className='form-group'>
								{/* <select className='form-control'>
									<option>Zone 1</option>
								</select> */}
								<FormSelect
									control={control}
									name='ZoneID'
									options={GENERAL_DDL?.ZONE_DDL || []}
									// grey={true}
									isMulti={false}
									placeholder='zone'
								/>
							</div>
						</div>

						<div className='col-2'>
							<div className='form-group'>
								<button onClick={()=> filterSearch()} className='form-control blue-btn'>Search</button>
							</div>
						</div>
					</div>
			</div>
		</div>
	);
};

export default FilterBar;
