import React from 'react';
// import ItemLayout from './ItemLayout';

interface iprops {
    id: number;
    component: any;
    name: string
    NoLayout?: boolean
}



const Widget = ({ id, component: Item, name, NoLayout }: iprops) => {
    return (
        <>
            {
                // NoLayout ? <Item key={name}/> :
                //     <ItemLayout key={id} title={name} >
                //         <Item />
                //     </ItemLayout>
                <Item key={name} name={name}  id={id}/> 
            }

        </>


    );
};

export default Widget;
