/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StatusChipComponent from '../../../../../common/components/statusChipComponent';
import CommonTable from '../../../../../common/components/Table/CommonTable';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { FailedToast, FormatTime } from '../../../../../utils/utilFunctions';
import { fetch_parking_violations_detail, fetch_parking_violations_list } from '../../../store/action';
import ViewModal from './viewModal';

type Props = {};

const ListTable = (props: Props) => {
	const [viewModal, setViewModal] = useState(false);
	const ViewModalOpen = () => setViewModal(true);
	const ViewModalClose = () => setViewModal(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const fetchIdRef = useRef(0);
	const [detail, setDetail] = useState(null);
	//   const [ID, setID]                   = useState(0);

	const handleModalOpen = (data: any) => {
		// setID(id)
		// setDetail(data)
		// ViewModalOpen()
		const success = (res: any) => {
			console.log(res);
			setDetail(res?.response?.data);
			ViewModalOpen();
		};
		const failed = (err: any) => {
			FailedToast(err?.response)		;
		};
		fetch_parking_violations_detail(data?.violationID, success, failed);
	};

	const PARKING_VIOLATION = useAppSelector((state) => state?.violations);

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'violationID', orderByKey: 'violationID' },

			{
				Header: 'Name',
				accessor: 'violatedUser',
				// orderByKey: 'gustName',
				Cell: ({ row }: any) => {
					return <>{row?.original?.violatedUser || row?.original?.gustName}</>;
				},
			},

			{ Header: 'Date', accessor: 'date', DateFormat: true, orderByKey: 'VDate', tdClass : 'resetWhiteSpaceWrap' },
			{ Header: 'Time', accessor: 'time', orderByKey: 'VTime', tdClass : 'resetWhiteSpaceWrap',
			Cell:({ row }:any) => {
				return <>{FormatTime(row?.original?.time, 'hh:mm a')}</>
			} },
			{ Header: 'Plate Number', accessor: 'plateNo', orderByKey: 'platenumber' },
			{ Header: 'Vehicle Category', accessor: 'vehicleCategory', orderByKey: 'vehicleCategory' },
			{ Header: 'Place of Registration', accessor: 'placeOfRegistration', orderByKey: 'placeOfRegistration' },
			{
				Header: 'Reported BY',
				accessor: 'reportedUser',
				Cell: ({ row }: any) => {
					return <>{row?.original?.reportedUser ?? 'System'}</>;
				},
			},
			{ Header: 'Violation Type', accessor: 'violationName' },
			// { Header: 'Reported By', accessor: 'reportedUser' },
			{ Header: 'User Type', accessor: 'userTypeName'},
			{ Header: 'Zone', accessor: 'zoneName'},
			{
				Header: 'Violation Status',
				accessor: 'vStatus',
				Cell: ({ row }: any) => {
					return (
						<>
							{row?.original?.vStatus === 0 ? (
								'- '
							) : row?.original?.vStatus === 2 ? (
								'Inactive'
							) : (
								<label className='badge red'>Active</label>
							)}
						</>
					);
				},
			},
			// {
			// 	Header: 'Validation Status',
			// 	accessor: 'validateStatus',
			// 	Cell: ({ row }: any) => {
			// 		return (
			// 			// <StatusChipComponent
			// 			// 	text={}
			// 			// 	chipType={row?.values?.validateStatus === 2 ? 'error' : 'success'}
			// 			// />
			// 			<>
			// 				{row?.values?.validateStatus === 2 ? (
			// 					<label htmlFor='' className='badge yellow'>
			// 						Invalid
			// 					</label>
			// 				) : (
			// 					'Valid '
			// 				)}
			// 			</>
			// 		);
			// 	},
			// },
			{
				Header: 'actionBar',
				accessor: 'actionBar',
				hideHeader: true,
				Cell: ({ row }: any) => {
					return (
						<Fragment>
							<div className='button-group'>
								<a className='btn btn-sqaure btn-white modal-button' onClick={() => handleModalOpen(row?.original)}>
									<i className='fa-solid fa-eye'></i>
								</a>
							</div>
						</Fragment>
					);
				},
				show: true,
			},
		],
		[]
	);

	type IfetchData = {
		pageSize: number;
		pageIndex: number;
		signal: AbortSignal;
		OrderBy: string;
		filters: { query: string };
		extraFilter: any;
	};

	const fetchData = useCallback(
		({ pageSize, pageIndex, signal, OrderBy, filters, extraFilter }: IfetchData) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				fetch_parking_violations_list(
					navigate,
					dispatch,
					{
						'filters': {
							'query': filters.query || '',
							OrderBy,
						},
						'pagination': {
							'pageNo': pageIndex,
							'limit': pageSize,
						},
						
						'SiteID' : extraFilter || null
					},
					signal
				);
			}
		},
		[dispatch, navigate]
	);

	return (
		<>
			<CommonTable
				columns={columns}
				data={PARKING_VIOLATION?.PARKING_VIOLATION_DATA ?? []}
				fetchData={fetchData}
				pageCount={PARKING_VIOLATION?.PARKING_VIOLATION_PAGINATION?.totalCount ?? 0}
				loading={PARKING_VIOLATION?.PARKING_VIOLATION_LOADER}
				page_no={PARKING_VIOLATION?.PARKING_VIOLATION_PAGINATION?.pageNo ?? 1}
				limit={PARKING_VIOLATION?.PARKING_VIOLATION_PAGINATION?.limit ?? 1}
				error={PARKING_VIOLATION?.PARKING_VIOLATION_ERROR}
				filters={PARKING_VIOLATION?.PARKING_VIOLATION_FILTER}
				extraFilter={PARKING_VIOLATION?.PARKING_VIOLATION_SITE ?? ''}
			/>
			{viewModal && <ViewModal open={viewModal} handleClose={ViewModalClose} Data={detail} />}
		</>
	);
};

export default ListTable;
