// import React, { useState } from 'react';
// import { useNavigate } from 'react-router';
// import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
// import { fetch_definitionList } from '../../../store/action';
// import { UPDATE_DEFINITION_FILTER_ONLY } from '../../../store/definitionSlice';
// import AddModal from './AddModal';

function FilterBar() {
    // const [addModal, setaddModal] = useState(false);

	// const addModalOpen = () => setaddModal(true);
	// const addModalClose = () => setaddModal(false);
    // let navigate = useNavigate()
	// const dispatch = useAppDispatch()
	// const DEFINITION_DATA = useAppSelector((state) => state?.definition)

	// const DefinitionSearch = (searchK:string) => {
	// 	// return dispatch(UPDATE_DEFINITION_FILTER_ONLY({
	// 	// 	filters : {
	// 	// 		"query": searchK!=='' ? 
	// 	// 		!isNaN(parseInt(searchK)) ?	`PlaceOfRegID = ${searchK}` :
	// 	// 		`CityCountry LIKE '${searchK}%'` : ''
	// 	// 	}
	// 	// }))
	// 	return fetch_definitionList(navigate, dispatch,
	// 		{
	// 			"filters": {
	// 				"query": searchK!=='' ? 
	// 				!isNaN(parseInt(searchK)) ?	`PlaceOfRegID LIKE '${searchK}%'` :
	// 				`CityCountry LIKE '${searchK}%'` : ''
	// 			},
	// 			"pagination": {
	// 				"pageNo": 1,
	// 				"limit": DEFINITION_DATA.DEFINITION_PAGINATION.limit
	// 			}
	// 		},
	// 		'place-of-reg'
	// 	);
	// }
    return (
        <div className='search-row-button'>
		<div className='row'>
				{/* <div className='col-5'>
					<div className='form-group'>
						<div className='pos-rel'>
							<input type='text' placeholder='Search'  className="form-control grey-input"
								 onChange={(v)=>{ DefinitionSearch(v.target.value) }} />
							<i className='fa-solid fa-magnifying-glass'></i>
						</div>
					</div>
				</div> */}
				{/* <div className='col-7 text-right'>
					<a onClick={addModalOpen}  href='#add-new-policy' className='btn btn-secondary modal-button'>
						Add New Place of Registration
					</a>
				</div> */}
		</div>
            {/* {addModal&&
			<AddModal open={addModal} handleClose={addModalClose}/>		}	 */}
        </div>
    );
}

export default FilterBar;

