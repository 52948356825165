import React, { useEffect, useState } from 'react'
import { fetch_BayTypeDDL, fetch_siteDDL } from '../../../../common/general/action'
import { useAppDispatch } from '../../../../config/hooks'
import FilterBar from './components/FilterBoxBar'
import Reports from './components/reportTable'

type Props = {}

const VisitReport = (props:Props) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const controller = new AbortController();

    fetch_siteDDL(dispatch, controller?.signal)
    // fetch_BayTypeDDL(dispatch)
  }, [])
  const [tabVal, setTabVal] = useState(1)
  
  return (
    <div className="main-body-content license">
      <FilterBar tabVal={tabVal}/>
      <Reports tabVal={tabVal} setTabVal={setTabVal}/>
    </div>
  )
}

export default VisitReport
