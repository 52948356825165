import Request from '../../../config/api';
import { USERS, USERS_ACTION, USERS_VEHICLE_ACTION } from '../../../config/endpoint';
import { UPDATE_USERS_DATA, UPDATE_USERS_LOADER ,UPDATE_USERS_ERROR} from './userSlice';
type t = 'get' | 'post' | 'put' | 'delete' | 'file-download';

export const fetch_userslist = (history: any, dispatch: any, postData: any, signal?: AbortSignal) => {
    let api_url = USERS;

    dispatch(UPDATE_USERS_LOADER({
        loader: true,
    }));
    Request('post', api_url, postData, signal)?.then((res) => {
        const { response, statusCode } = res;
        const { data, pagination, filters } = response;
        if (statusCode === 200) {
            dispatch(
                UPDATE_USERS_DATA({
                    data,
                    pagination,
                    filters,
                })
            );
        } else {
            dispatch(UPDATE_USERS_ERROR({
                error: "Some error occured",
            }));
        }
    });
};


export const user_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = USERS_ACTION;
    Request(type, api_url, postData, undefined, Sucess, Failed)?.then((res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
            // SuccesToast(response?.data)
        } else {
        }
    });
};


export const fetch_user_detail = (id: number, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = USERS + id + '/';
    return Request('get', api_url, '', undefined, Sucess, Failed)
};

export const delete_user = (id: number, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = USERS_ACTION + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)
};


export const vehice_action = (type: t, postData: Object, Sucess: (res: any) => void, Failed: (err: any) => void) => {
    let api_url = USERS_VEHICLE_ACTION;
    Request(type, api_url, postData, undefined, Sucess, Failed)
};

export const delete_user_vehicle = (id: number, Sucess?: (res: any) => void, Failed?: (err: any) => void) => {
    let api_url = USERS_VEHICLE_ACTION + id + '/';
    Request('delete', api_url, '', undefined, Sucess, Failed)
};