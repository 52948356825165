import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import './style.css'
import { border } from '@mui/system';

type Props = {
    selectedZone: any;
    selectedFloor?: any;
    setselectedZone: React.Dispatch<any>;
    setselectedFloor: React.Dispatch<any>;
    setselectedSite: React.Dispatch<any>;
    tree : any[]
    style?:React.CSSProperties 
};


const NestedSelect = forwardRef(({ selectedZone, selectedFloor, setselectedZone ,tree=[],setselectedFloor,setselectedSite ,style}: Props ,ref:any,) => {
    const [selected, setselected] = useState<any>();

    const handleChangeExpand = (site: any) => {
        setselected((prev: any) => (site?.levelID === prev?.levelID ? undefined : site));
    };


    useEffect(() => {
        if(tree)
        setselected(tree[0]?.siteChild[0])
    }, [tree])

 
    

    return (
        <div className='white-card' style={style} ref={ref}>
             <div className='bay-heading white-card-heading dflex align-items-center justify-space-between '>
                <h2 className='border-title'>Zone List</h2>
            </div>
        <div className=' new-scrollbar  capitalize' style={{ maxHeight: '30vh', overflow: 'scroll', overflowX: 'hidden' ,padding:'5px'}}>
           
            {tree.map((site: any, key: any) => {
                return (
                    <Fragment key={key}>
                        {site?.siteChild.map((floor: any, key1: React.Key | null | undefined) => {
                            return (
                                <Fragment key={key1}>
                                    <Accordion
                                        expanded={selected?.levelID === floor?.levelID}
                                        // expanded={false}

                                        onChange={() => handleChangeExpand(floor)}
                                        sx={{ boxShadow: 'none', background: 'transparent',
                                         borderLeft: '1px solid var(--lightgrey)' ,
                                         borderRight: '1px solid var(--lightgrey)' ,
                                         borderTop: selected?.levelID === floor?.levelID || key1 === 0 ?'1px solid var(--lightgrey)' : '',
                                         borderBottom: selected?.levelID === floor?.levelID || key1 === site?.siteChild.length -1 ?'1px solid var(--lightgrey)' : ''
                                         }}>
                                        <AccordionSummary
                                            expandIcon={<i className='fa fa-chevron-down' />}
                                            aria-controls='panel1bh-content'
                                            id='panel1bh-header'
                                            sx={{
                                                margin: 0,
                                                height: '20px',
                                                borderBottom: selected?.levelID === floor?.levelID ? '1px solid var(--lightgrey)' : "",
                                                borderTop: 'none',
                                            }}>
                                            <h2 className='border-title' style={{ textTransform: 'capitalize' }}>
                                                {site?.siteName} - {floor?.levelName}
                                            </h2>{' '}
                                        </AccordionSummary>

                                        <div>
                                            {floor?.levelChild.map((zone: any, key2: number) => {
                                                return (
                                                    <Fragment key={key2}>
                                                        <AccordionDetails sx={{ margin: 0, padding: 0 }}>
                                                            <div className='accordion-zone-list'
                                                                style={{
                                                                    padding: '5px 20px',
                                                                    fontSize:"14px",
                                                                    textTransform:"capitalize",
                                                                    cursor:"pointer",
                                                                    border:
                                                                        selectedZone?.zoneID === zone?.zoneID && selectedFloor?.levelID === floor?.levelID
                                                                            ? '1px solid var(--lightgrey-second)'
                                                                            : '',
                                                                    background:
                                                                        selectedZone?.zoneID === zone?.zoneID && selectedFloor?.levelID === floor?.levelID
                                                                            ? 'var(--lightgrey-second)'
                                                                            : '',
                                                                }}
                                                                onClick={() =>{ setselectedZone(zone);
                                                                    setselectedFloor(floor)
                                                                    setselectedSite(site)

                                                                }}>
                                                                {zone?.zoneName}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                        
                                    </Accordion>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                );
            })}
        </div>
        </div>

    );
});

export default NestedSelect;
