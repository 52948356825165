import React, { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormSelect from '../../../../common/components/FormSelect';
import { FailedToast, FormatDate, SuccesToast } from '../../../../utils/utilFunctions';
import { fetch_userslist, user_action } from '../../store/action';
import { useAppDispatch, useAppSelector } from './../../../../config/hooks';
import AsyncSelect from 'react-select/async';
import { search_user, search_web_user } from '../../../security/store/action';
import ErrorComponent from '../../../../common/components/ErrorComponent';
// import uploadIcon from '../../../../assets/images/common/upload-icon.svg';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import ButtonLoader from '../../../../common/components/ButtonLoader';
import moment from 'moment';
import FormInput from '../../../../common/components/FormInput';
import { fetch_departmentDDL, fetch_designationDDL, fetch_groupCompanyDDL } from '../../../../common/general/action';
import { flushSync } from 'react-dom';
import CustomDatePicker from '../../../../common/components/CustomDatePicker';
type Props = {
	handleClose: () => void;
	Data?: any;
};

const schema = yup.object().shape({
	firstName: yup.string().typeError('Please enter a valid value').matches(/^[a-zA-Z_\s]+$/,'Name cannot have any characters other than alphabets or underscore').required(),
	contactNo: yup.string().typeError('Please enter a valid value').nullable().notRequired().when('contactNo', {
		is: (value: any) => value?.length,
		then: rule => rule.matches(
			///^[0-9]+$/,
			/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
		 'Enter valid contact'),
	}).test('len','Minimum 7 and maximum 12 digits can be in contact',(value:any)=>(value?.length > 0 && value?.match(/\d/g)?.length > 7 && value?.match(/\d/g)?.length < 13 )),
	emailID: yup.string().typeError('Please enter a valid value').email().required(),
	fK_UserStatus: yup.number().typeError('Please enter a valid value').required(),
	fK_DesignationID: yup.number().typeError('Please enter a valid value'),
	fK_DepartmentID: yup.number().typeError('Please enter a valid value'),
	fK_PriorityLevel: yup.number().typeError('Please enter a valid value').required('Personal Grade must be entered'),
	fK_SiteID: yup.number().required(),
	escalationLevel1: yup.number().typeError('Please enter a valid value').required(),
	fK_UserType: yup.number().typeError('Please enter a valid value').required(),
	gender: yup.number().typeError('Please enter a valid value'),
	dob: yup.date().nullable()
    .default(undefined),
	fK_GroupCompanyID: yup.number().typeError('Please enter a valid value').required(),
}, [
	['contactNo', "contactNo"],
]
);
const schema2 = yup.object().shape({
	firstName: yup.string().required(),
	// contactNo: yup.string().matches(/^[0-9]+$/, 'Must be only digits')
	// .min(5, 'mininmum 5 digits needed')
	// .max(16, 'maximum  of 16 digits possible'),
	contactNo: yup.string().nullable().notRequired().when('contactNo', {
		is: (value: any) => value?.length,
		then: rule => rule.matches(
			///^[0-9]+$/,
			/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
		 'Enter valid contact'),
	}).test('len','Minimum 7 and maximum 12 digits can be in contact',(value:any)=>(value?.length > 0 && value?.match(/\d/g)?.length > 7 && value?.match(/\d/g)?.length < 13 )),
	emailID: yup.string().typeError('Please enter a valid value').email().required(),
	fK_UserStatus: yup.number().typeError('Please enter a valid value'),
	fK_DesignationID: yup.number().typeError('Please enter a valid value'),
	fK_DepartmentID: yup.number().typeError('Please enter a valid value'),
	fK_PriorityLevel: yup.number().typeError('Please enter a valid value'),
	fK_SiteID: yup.number().typeError('Please enter a valid value'),
	escalationLevel1: yup.number().typeError('Please enter a valid value').required(),
	fK_UserType: yup.number().typeError('Please enter a valid value'),
	gender: yup.number().typeError('Please enter a valid value'),
	dob: yup.date().nullable()
    .default(undefined),
	fK_GroupCompanyID: yup.number().typeError('Please enter a valid value').required(),
}, [
	['contactNo', "contactNo"],
]);

const AddUser = (props: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
		clearErrors,
		watch,
	} = useForm({
		mode: 'onBlur',
		resolver: props?.Data ? yupResolver(schema2) : yupResolver(schema),
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});
	let dispatch = useAppDispatch();
	const [userOptions, setuserOptions] = useState<any>([]);

	const [loading, setloading] = useState<any>(false);
	const GENERAL_DDL = useAppSelector((state) => state?.general);
	const USERS_DATA = useAppSelector((state) => state?.user);

	useEffect(() => {
		if(props?.Data){
			handleInputChangeDepartment(props?.Data?.depName)
			handleInputChangeDesignation(props?.Data?.desName)
			handleInputChangeGroupCompany(props?.Data?.gCompanyName)
		}else{

			handleInputChangeDepartment(' ')
			handleInputChangeDesignation(' ')
			handleInputChangeGroupCompany(' ')

		}
	}, [props?.Data])
	


	useEffect(() => {
		clearErrors();
		if (props.Data) {
			setValue('firstName', props?.Data?.firstName);
			setValue('contactNo', props?.Data?.contactNo);
			setValue('emailID', props?.Data?.emailID);
			setValue('fK_CompanyID', props?.Data?.fK_CompanyID);
			setValue('fK_UserStatus', props?.Data?.fK_UserStatus);
			setValue('fK_DesignationID', props?.Data?.fK_DesignationID);
			setValue('fK_DepartmentID', props?.Data?.fK_DepartmentID);

			setValue('fK_PriorityLevel', props?.Data?.fK_PriorityLevel);
			setValue('fK_SiteID', props?.Data?.fK_SiteID);

			// if(!getValues('escalationLevel1')){
			setValue('escalationLevel1', props?.Data?.escalationLevel1 === 0 ? props?.Data?.userUniqueID : props?.Data?.escalationLevel1);
			// }
			setValue('fK_UserType', props?.Data?.fK_UserType);
			setValue('userUniqueID', props?.Data?.userUniqueID);
			setValue('gender', props?.Data?.gender);
			if (
				GENERAL_DDL?.GROUPCOMPANY_DDL &&
				GENERAL_DDL?.GROUPCOMPANY_DDL?.length > 0 &&
				GENERAL_DDL?.GROUPCOMPANY_DDL?.map((el: any) => el?.value)?.includes(props?.Data?.fK_GroupCompanyID)
			)
				setValue('fK_GroupCompanyID', props?.Data?.fK_GroupCompanyID);
			setValue('dob', props?.Data?.dob ? dayjs(props?.Data?.dob).format('YYYY-MM-DD') : null);
		}
	}, [clearErrors, props.Data, setValue]);

	const onSubmitHandler = (data: any) => {
		setloading(true);

		data['dob'] = data.dob ? moment(data.dob).format('YYYY-MM-DD') : null;

		const Success = (res: any) => {
			SuccesToast(res?.response?.data);
			fetch_userslist('', dispatch, {
				'filters': {
					'query': USERS_DATA?.USER_FILTER?.query || '',
				},
				'pagination': {
					'pageNo': USERS_DATA?.USER_PAGINATION?.pageNo,
					'limit': USERS_DATA?.USER_PAGINATION?.limit,
				},
			});
			reset();
			props.handleClose();
			setloading(false);
		};
		const Failed = (err: any) => {
			FailedToast(err?.response);
			setloading(false);
		};

		if (props?.Data) {
			user_action('put', data, Success, Failed);
		} else {
			user_action('post', data, Success, Failed);
		}
	};

	const handleInputChange = async (key?: string) => {
		let postData = {
			'filters': {
				'query': key ? `(firstName like '%${key}%')` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		let Response = await search_user(postData);

		let data = Response?.response?.data ?? [];

		// let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: item?.firstName + ' - ' + item?.emailID }));
		setuserOptions(data || []);
		return data;
	};

	const FetchEscalationUser = async (key?: string) => {
		let postData = {
			'filters': {
				'query': key ? `(userUniqueID = ${key})` : '',
			},
			'pagination': {
				'pageNo': 1,
				'limit': 20,
			},
		};
		let Response = await search_user(postData);

		let data = Response?.response?.data ?? [];

		// let Options = data.map((item: any) => ({ value: item?.userUniqueID, label: item?.firstName + ' - ' + item?.emailID }));
		// setusersList(data);
		setuserOptions(data || []);
		return data;
	};

	const promiseOptions = (inputValue: string) =>
		new Promise<any[]>((resolve) => {
			resolve(handleInputChange(inputValue));
		});
	// useEffect(() => {
	// 	handleInputChange();
	// }, []);

	useEffect(() => {
		if (props?.Data) {
			FetchEscalationUser(props?.Data?.escalationLevel1 === 0 ? props?.Data?.userUniqueID : props?.Data?.escalationLevel1);
			setValue('escalationLevel1', props?.Data?.escalationLevel1 === 0 ? props?.Data?.userUniqueID : props?.Data?.escalationLevel1);
		}
	}, [props?.Data, setValue]);

	const handleInputChangeDepartment = (inputValue: string) => {
		if (inputValue) fetch_departmentDDL(dispatch, undefined, inputValue);
	};

	const handleInputChangeDesignation = (inputValue: string) => {
		// console.log("inputValue", inputValue)
		if (inputValue) fetch_designationDDL(dispatch, undefined, inputValue);
	};

	const handleInputChangeGroupCompany = (inputValue: string) => {
		if (inputValue) fetch_groupCompanyDDL(dispatch, undefined, inputValue);
	};

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onSubmitHandler)}>
				<div className='modal-body-content'>
					<div className='form-tabs-block active-tab mb-5' id='userinfotab'>
						<div className='row'>
							<div className='col-12'>
								<div className='user-detail-form'>
									<div className='form-w-label modal-form'>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>User Name</label>
													{/* <input
														type='text'
														{...register('firstName')}
														placeholder=''
														className='form-control'
													/> */}

													<FormInput type='text' name='firstName' className='form-control' control={control} />

													<ErrorComponent errors={errors} name='firstName' title='Username' />
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group'>
													<label>Email</label>
													{/* <input
														type='text'
														{...register('emailID')}
														placeholder=''
														className='form-control'
													/> */}
													{/* <FormInput type='text' name="emailID" className='form-control' control={control} /> */}

													{props?.Data ? (
														<>
															<input
																type='text'
																value={watch('emailID') ?? ''}
																placeholder=''
																className='form-control opacity-half'
															/>
														</>
													) : (
														<>
															<FormInput
																type='text'
																name='emailID'
																className='form-control'
																control={control}
															/>
														</>
													)}
													<ErrorComponent errors={errors} name='emailID' title='Email' />
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Contact Number</label>
													{/* <input
														type='text'
														{...register('contactNo')}
														placeholder=''
														className='form-control'
													/> */}
													<FormInput
														type='text'
														name='contactNo'
														className='form-control'
														placeholder='+971-XX-1234567'
														control={control}
														onChange={(e, formOnChange) => {
															let v = e.target.value.replace(/[^0-9+-]/g, '');
															formOnChange(v);
														}}
													/>

													<ErrorComponent errors={errors} name='contactNo' title='Contact number' />
												</div>
											</div>

											{/* <div className='col-6'>
												<div className='form-group' style={{ position : 'relative' }}>
													<label> Date of Birth</label>
													<Controller
														name='dob'
														control={control}
														render={({ field: { value, onChange, onBlur } }) => (
															<>
																<CustomDatePicker
																	className='form-control'
																	style={{
																		position : 'absolute', right : '20px', width : '5%'
																	}}
																	size='large'
																	hideDisabledOptions={true}
																	defaultPickerValue={moment().add(-15, 'years')}
																	onChange={onChange}
																	value={value ? moment(value) : null}
																	disabledDate={(current:any) => current > moment('31-12','DD-MM').add(-15, 'years')}
																	allowClear={false}
																	setValue={setValue}
																	name={'dob'}
																	format={'YYYY-MM-DD'}
																/>
															</>
														)}
													/>
													
													<ErrorComponent errors={errors} name='dob' title='Date of birth' />
												</div>
											</div> */}
										</div>

										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Department </label>
													<FormSelect
														control={control}
														name='fK_DepartmentID'
														options={GENERAL_DDL?.DEPARTMENT_DDL || []}
														grey={true}
														onInputChange={handleInputChangeDepartment}	
														isMulti={false}
														placeholder=''
													/>
													<ErrorComponent errors={errors} name='fK_DepartmentID' title='Department' />
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group'>
													<label>Designation </label>
													<FormSelect
														control={control}
														name='fK_DesignationID'
														options={GENERAL_DDL?.DESIGNATION_DDL || []}
														onInputChange={handleInputChangeDesignation}
														grey={true}
														isMulti={false}
														placeholder=''
													/>
													<ErrorComponent errors={errors} name='fK_DesignationID' title='Designation' />
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Priority Level</label>

													<input
														type='number'
														min='1'
														placeholder=''
														className='form-control'
														{...register('fK_PriorityLevel')}
													/>

													<ErrorComponent errors={errors} name='fK_PriorityLevel' title='Personal Grade' />
												</div>
											</div>

											<div className='col-6'>
												<div className='form-group'>
													<label>Site</label>
													<FormSelect
														control={control}
														name='fK_SiteID'
														options={GENERAL_DDL?.SITE_DDL || []}
														grey={true}
														isMulti={false}
														placeholder=''
													/>
													<ErrorComponent errors={errors} name='fK_SiteID' title='Site' />
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Gender</label>
													<FormSelect
														control={control}
														name='gender'
														options={[
															{ label: 'Male', value: 1 },
															{ label: 'Female', value: 2 },
														]}
														grey={true}
														isMulti={false}
														placeholder=''
													/>
													<ErrorComponent errors={errors} name='gender' title='Gender' />
												</div>
											</div>

											<div className='col-6'>
												<div className='form-group'>
													<label>User Type</label>
													<FormSelect
														control={control}
														name='fK_UserType'
														options={GENERAL_DDL?.USERTYPE_DDL}
														grey={true}
														isMulti={false}
														placeholder=''
													/>
													<ErrorComponent errors={errors} name='fK_UserType' title='User Type' />
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-6'>
												<div className='form-group'>
													<label>Escalation 1</label>

													<AsyncSelect
														className={'common-select common-select-grey'}
														cacheOptions
														// defaultInputValue={props?.Data ? props?.Data?.emailID : ''}
														getOptionLabel={(e) => `${e.firstName} - ${e.emailID}`}
														getOptionValue={(e) => e.userUniqueID}
														loadOptions={promiseOptions}
														onChange={(e) => setValue('escalationLevel1', e?.userUniqueID)}
														defaultOptions={userOptions}
														options={userOptions}
														menuPlacement='top'
														// value={userOptions.filter}
														value={
															userOptions.find(
																(c: any) => c.userUniqueID === watch('escalationLevel1')
															) ?? ''
														}
													/>
													<ErrorComponent errors={errors} name='escalationLevel1' title='Escalation 1' />
												</div>
											</div>
											<div className='col-6'>
												<div className='form-group'>
													<label>Group Company </label>

													<FormSelect
														control={control}
														name='fK_GroupCompanyID'
														options={GENERAL_DDL?.GROUPCOMPANY_DDL}
														onInputChange={handleInputChangeGroupCompany}
														grey={true}
														isMulti={false}
														placeholder=''
														menuPlacement='top'
													/>
													<ErrorComponent errors={errors} name='fK_GroupCompanyID' title='Group company' />
												</div>
											</div>

											<Fragment>
												{/**@hardcoded  hidden hardcoded input fields with default value */}
												<input
													type='number'
													{...register('fK_UserStatus')}
													placeholder=''
													className='none'
													defaultValue={1}
												/>
											</Fragment>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='modal-footer mt-30'>
					<button type='submit' className='btn btn-secondary border-blue' disabled={loading}>
						<ButtonLoader text={props?.Data ? 'Update User' : 'Add User'} loading={loading} />
					</button>

					<button
						type='reset'
						className='btn btn-outline-blue close-modal-button'
						onClick={() => {
							reset();
							clearErrors();
							props.handleClose();
						}}>
						Close
					</button>
				</div>
			</form>
		</Fragment>
	);
};

export default AddUser;
